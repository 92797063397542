import React, { useRef, useLayoutEffect, useState } from 'react';
import { object, string } from 'prop-types';
import { useSelector } from 'react-redux';

import { PersonSelect, PremiumTotal } from '@src/components';
import { StyledWrapper } from './StickyHeader.style';
import { isInternalProcess } from '@src/util/process';

const StickyHeader = ({ selectedTab, personSelectProps, premiumTotalProps }) => {
    const currentProducts = useSelector(state => state.apiData?.currentProducts);
    const wrapperElement = useRef(null);
    const [origOffset, setOrigOffset] = useState(0);
    const [isSticky, setIsSticky] = useState(0);
    const shouldRenderPersonSelect = selectedTab === 'product';
    const shouldRenderBorderTop =
        selectedTab === 'product' && !isInternalProcess(personSelectProps.persons, currentProducts);

    const calcOffset = () => {
        const el = wrapperElement.current;
        const rect = el.getBoundingClientRect();
        const win = el.ownerDocument.defaultView;

        return rect.top + win.pageYOffset;
    };

    const handleScroll = () => {
        const offset = calcOffset();

        if (origOffset === 0) {
            setOrigOffset(offset);
            return;
        }

        if (!!isSticky && origOffset > window.scrollY) {
            setIsSticky(0);
        }
        if (!isSticky && origOffset <= window.scrollY) {
            setIsSticky(1);
        }
    };

    const handleResize = () => {
        const origIsSticky = isSticky;
        setIsSticky(0);
        setOrigOffset(0);
        handleScroll();
        setIsSticky(origIsSticky);
    };

    useLayoutEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isSticky, origOffset]);

    useLayoutEffect(() => {
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [isSticky, origOffset]);

    return (
        <StyledWrapper ref={wrapperElement} isSticky={isSticky} borderTop={shouldRenderBorderTop ? 1 : 0}>
            {shouldRenderPersonSelect ? (
                <PersonSelect {...personSelectProps} />
            ) : (
                <PremiumTotal {...premiumTotalProps} />
            )}
        </StyledWrapper>
    );
};

StickyHeader.defaultProps = {
    selectedTab: '',
    personSelectProps: null,
    premiumTotalProps: null,
};

StickyHeader.propTypes = {
    selectedTab: string,
    personSelectProps: object,
    premiumTotalProps: object,
};

export default StickyHeader;
