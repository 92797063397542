import createDOMPurify from 'dompurify';

const DOMPurify = createDOMPurify(window);
DOMPurify.addHook('afterSanitizeAttributes', function (node) {
    if ('target' in node) {
        node.setAttribute('rel', 'noopener noreferrer');
    }
});

const PHONE_MAX_LENGTH = 15;

/**
 * sanitize library
 */

const ESCAPE_TEXT_REGEXP = /[<>]/g;
const ESCAPE_TEXT_REPLACEMENTS = {
    //width lesser-than character
    '<': '\uff1c',
    //width greater-than character
    '>': '\uff1e',
};

const sanitizeText = str =>
    str == null
        ? str
        : typeof str === 'string'
        ? str.replace(ESCAPE_TEXT_REGEXP, ch => ESCAPE_TEXT_REPLACEMENTS[ch])
        : '';

export const sanitizeSubmission = entity => {
    const { attributes, ...restEntity } = entity;
    const { privateData, ...restAttributes } = attributes || {};

    const sanitizeLocation = location => {
        const { address, building } = location || {};
        return { address: sanitizeText(address), building: sanitizeText(building) };
    };

    const sanitizeprivateData = privateData => {
        const { location, rules, ...restprivateData } = privateData || {};
        const locationMaybe = location ? { location: sanitizeLocation(location) } : {};
        const rulesMaybe = rules ? { rules: sanitizeText(rules) } : {};

        return privateData ? { privateData: { ...locationMaybe, ...rulesMaybe, ...restprivateData } } : {};
    };

    const attributesMaybe = attributes
        ? {
              attributes: {
                  ...sanitizeprivateData(privateData),
                  ...restAttributes,
              },
          }
        : {};

    return { ...attributesMaybe, ...restEntity };
};

export const sanitizeEntity = entity => {
    const { type } = entity;
    if (type === 'submission') {
        return sanitizeSubmission(entity);
    } else {
        return entity;
    }
};

export const sanitizePhoneNumber = (value, maxLength = PHONE_MAX_LENGTH) => {
    const pattern = `(^\\+\\s*(?:\\d\\s*){0,${maxLength - 1}})|(^\\s*(?:\\d\\s*){0,${maxLength}})`;
    const reg = new RegExp(pattern);
    let matches = reg.exec(value);
    return matches?.[0] || '';
};

export const sanitizeDOM = text => {
    return DOMPurify.sanitize(text, { ADD_ATTR: ['target'] });
};
