import React from 'react';
import { object, string, array } from 'prop-types';
import { Tooltip } from 'atupri-component-library/lib/components';
import { AnswerGroup } from '@src/components';
import { getTreeLevel } from '@src/util/array';
import { conditionalRender } from './util';
import { StyledQuestion, StyledLabel, StyledIcon } from './Question.style';

const Question = ({ healthQuestions, block, question, prefix = '' }) => {
    const render = () => {
        const treeLevel = getTreeLevel(healthQuestions, block);

        return (
            <StyledQuestion id={`QUESTION_${question.id}`} className={`QUESTION LEVEL-${treeLevel}`} level={treeLevel}>
                {question.answerGroups ? (
                    <div className={`ANSWERS LEVEL-${treeLevel}`}>
                        {question.label ? (
                            <StyledLabel level={treeLevel}>
                                <span>{question.label}</span>&nbsp;
                                {question.infotext ? (
                                    <Tooltip tooltip={question.infotext} placement="top-start">
                                        <StyledIcon iconName="question_outlined" />
                                    </Tooltip>
                                ) : null}
                            </StyledLabel>
                        ) : null}

                        {question.answerGroups.map((answerGroup, it) => {
                            return (
                                <AnswerGroup
                                    key={question.id + '_answergroup' + it}
                                    index={it}
                                    healthQuestions={healthQuestions}
                                    block={block}
                                    group={answerGroup}
                                    question={question}
                                    prefix={prefix}
                                />
                            );
                        })}
                    </div>
                ) : null}
            </StyledQuestion>
        );
    };

    return conditionalRender(question, render);
};

Question.propTypes = {
    healthQuestions: array.isRequired,
    block: object.isRequired,
    question: object.isRequired,
    prefix: string,
};

export default Question;
