import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import i18next from 'i18next';
import GlobalStyles from 'atupri-component-library/lib/core/styles/GlobalStyles';
import FloatingFocusStyles from 'atupri-component-library/lib/core/styles/FloatingFocusStyles';
import classNames from 'classnames';
import routeConfiguration from '@src/routeConfiguration';
import config from '@src/config';
import { canonicalRoutePath } from '@src/util/routes';
import { localizeUrl } from '@src/util/staticUrl';

import './Page.scss';

const preventDefault = e => {
    e.preventDefault();
};

const Page = ({ children = null, className = null, rootClassName = null }) => {
    const contentDiv = useRef(null);
    const classes = classNames(rootClassName || 'page', className);

    const location = useLocation();

    useEffect(() => {
        document.addEventListener('dragover', preventDefault);
        document.addEventListener('drop', preventDefault);

        return () => {
            document.removeEventListener('dragover', preventDefault);
            document.removeEventListener('drop', preventDefault);
        };
    }, []);

    const canonicalRootURL = config.canonicalRootURL;
    const canonicalPath = canonicalRoutePath(routeConfiguration(), location);
    const canonicalUrl = `${canonicalRootURL}${canonicalPath}`;

    const siteTitle = i18next.t('siteTitle');
    const siteDescription = i18next.t('siteDescription');
    const siteFocusKeyword = i18next.t('siteFocusKeyword');

    return (
        <div className={classes}>
            <Helmet
                htmlAttributes={{
                    lang: i18next.language,
                }}
            >
                <title>{siteTitle}</title>
                <meta name="description" content={siteDescription} />
                <link rel="canonical" href={canonicalUrl} />
                <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
                <meta httpEquiv="Content-Language" content={i18next.language} />
                <meta property="og:url" content={window.location.origin} />
                <meta property="og:title" content={siteTitle} />
                <meta property="og:description" content={siteDescription} />
                <meta property="og:site_name" content="Atupri" />
                <meta
                    property="og:image"
                    content={`${window.location.origin}/app/images/homepage-hero-compressor.jpg`}
                />
                <meta property="og:type" content="website" />
                <link rel="alternate" hrefLang="de" href={localizeUrl('de')} />
                <link rel="alternate" hrefLang="fr" href={localizeUrl('fr')} />
                <link rel="alternate" hrefLang="it" href={localizeUrl('it')} />
            </Helmet>
            <GlobalStyles />
            <FloatingFocusStyles />
            <div className={'page__content'} ref={contentDiv}>
                {siteFocusKeyword && <h1 className="visuallyhidden">{siteFocusKeyword}</h1>}
                {children}
            </div>
        </div>
    );
};

const { any, string } = PropTypes;

Page.propTypes = {
    children: any,
    className: string,
    rootClassName: string,
};

export default Page;
