import styled from '@emotion/styled';
import { rem } from 'polished';
import { colors, mixins, spaces } from 'atupri-component-library/lib/core/styles/theme/default';
import { Icon, P1, L1, StatusTag } from 'atupri-component-library/lib/primitives';
import { Box } from 'atupri-component-library/lib/helpers';

export const StyledCockpitItem = styled(Box)`
    ${mixins.responsiveStyles({
        'margin-bottom': { zero: spaces.epsilon, medium: spaces.eta, large: spaces.iota },
    })}
`;

export const StyledPersonWrap = styled.div`
    display: flex;
    flex: 1 1 auto;
    height: 100%;
    box-sizing: border-box;

    ${mixins.responsiveStyles({
        'padding-top': { zero: spaces.zeta, medium: 0 },
        'padding-bottom': { zero: spaces.zeta, medium: 0 },
        'padding-left': { zero: spaces.delta, medium: spaces.epsilon },
        'padding-right': { zero: spaces.delta, medium: spaces.theta },
        'border-right': { small: `1px solid ${colors.blue300}` },
        'border-bottom': { zero: `1px solid ${colors.blue300}`, small: 'none' },
        'flex-direction': { zero: 'column', medium: 'row' },
        'align-items': { zero: 'flex-start', medium: 'center' },
        width: { zero: '100%', medium: '60%', large: 'auto' },
    })}
`;

export const StyledBreakpointWrap = styled.div`
    display: flex;
    flex: 1 1 auto;
    justify-content: space-between;

    ${mixins.responsiveStyles({
        'flex-direction': { zero: 'column', large: 'row' },
        'align-items': { zero: 'flex-start', large: 'center' },
    })}
`;

export const StyledPersonal = styled.div`
    display: flex;
    align-items: center;
    flex: 1 1 auto;

    ${mixins.responsiveStyles({
        'margin-right': { medium: rem(spaces.epsilon) },
    })}
`;

export const StyledUserIcon = styled(Icon)`
    color: ${colors.primary};
    margin-right: ${rem(spaces.gamma)};
    font-size: ${rem(38)};
`;

export const StyledUserInfo = styled.div``;

export const StyledUserName = styled(P1)`
    word-break: break-word;
`;

export const StyledStatusWrap = styled.div`
    ${mixins.responsiveStyles({
        'margin-top': { zero: rem(spaces.epsilon), large: 0 },
    })}
`;

export const StyledStatusTag = styled(StatusTag)`
    white-space: nowrap;
`;

export const StyledCTAWrap = styled.div`
    white-space: nowrap;
    ${mixins.responsiveStyles({
        'margin-top': { zero: rem(spaces.epsilon), large: 0 },
        'margin-left': { large: spaces.theta },
    })}
`;

export const StyledPriceWrap = styled.div`
    display: flex;
    height: 100%;
    box-sizing: border-box;
    flex: 0 0 auto;

    ${mixins.responsiveStyles({
        'flex-direction': { zero: 'column', medium: 'row' },
        'align-items': { zero: 'flex-start', medium: 'center' },
        'justify-content': { medium: 'space-between' },
        width: { zero: '100%', small: 'auto' },
        padding: {
            zero: `${rem(spaces.eta)} ${rem(spaces.delta)}`,
            medium: `0 ${rem(spaces.epsilon)} 0 ${rem(spaces.theta)}`,
        },
    })}
`;

export const StyledPriceInner = styled.div`
    display: flex;
    align-items: center;

    ${mixins.responsiveStyles({
        'flex-direction': { zero: 'column', wide: 'row' },
        'align-items': { zero: 'flex-start', wide: 'center' },
    })}
`;

export const StyledPrice = styled.div`
    padding-right: ${rem(spaces.theta)};

    ${mixins.responsiveStyles({
        'margin-bottom': { zero: spaces.gamma, wide: 0 },
    })}
`;

export const StyledInfoBox = styled.div`
    color: ${colors.grey700};
`;

export const StyledInfoBoxLabel = styled(L1)`
    display: inline-block;
    color: ${colors.grey700};

    ${mixins.responsiveStyles({
        'padding-bottom': { zero: rem(spaces.beta), wide: rem(spaces.delta) },
    })}
`;

export const StyledInfoBoxValue = styled(P1)`
    color: ${colors.grey900};

    ${mixins.responsiveStyles({
        'font-size': { zero: rem(20) },
    })}
`;

export const StyledOverview = styled.div`
    display: flex;
    align-items: center;
    align-self: flex-end;
    line-height: 2;
`;

export const StyledOverviewIcon = styled(Icon)`
    font-size: ${rem(20)};
    margin-right: ${rem(spaces.gamma)};
`;

export const StyledNamedLinkInner = styled.span`
    display: flex;
    align-items: center;
    color: ${colors.primary};
`;

export const StyledHiddenCheckbox = styled.span`
    display: none;
`;
