import styled from '@emotion/styled';
import { rem } from 'polished';
import { mixins, text, colors, spaces, spacingCurves } from 'atupri-component-library/lib/core/styles/theme/default';
import { H1 } from 'atupri-component-library/lib/primitives';

export const StyledH1 = styled(H1)`
    padding-bottom: ${rem(32)};
    word-break: break-word;
`;

export const StyledTotalBar = styled.div`
    text-align: right;
    width: 100%;
    display: flex;
    box-sizing: border-box;
    word-break: break-word;

    ${mixins.responsiveStyles({
        'flex-direction': { zero: 'column', medium: 'row' },
        'justify-content': { medium: 'flex-end' },
        'align-items': { medium: 'baseline' },
        ...spacingCurves.component.regular('padding-right'),
    })}
`;

export const StyledTotalPrice = styled.p`
    ${text.h3};
    white-space: nowrap;

    ${mixins.responsiveStyles({
        'margin-top': { zero: spaces.epsilon, medium: 0 },
        'margin-left': { zero: 0, medium: spaces.epsilon },
    })}
`;

export const StyledInfobar = styled.div`
    display: flex;
    flex-wrap: wrap;

    ${mixins.responsiveStyles({
        'flex-direction': { zero: 'column', medium: 'row' },
    })}
`;

export const StyledPerson = styled.dl`
    display: flex;
    flex-direction: row;
    flex: 0 0 auto;
    margin: 0;

    div + div {
        ${mixins.responsiveStyles({
            ...spacingCurves.component.giga('margin-left'),
        })}
    }
`;

export const StyledDT = styled.dt`
    ${text.p4};
    color: ${colors.grey700};
`;

export const StyledDD = styled.dd`
    ${text.p1};
    margin-top: ${rem(spaces.beta)};
`;

export const StyledCTA = styled.div`
    display: flex;
    align-items: flex-end;
    line-height: 1.8;

    ${mixins.responsiveStyles({
        width: { zero: '100%', medium: 'auto' },
        'margin-top': { zero: spaces.zeta, medium: 0 },
        'margin-left': { medium: spaces.kappa },
    })}

    a {
        display: flex;
        align-items: center;
        text-decoration: none;
        color: ${colors.primary};
    }

    i {
        font-size: ${rem(20)};
        margin-right: ${rem(spaces.gamma)};
    }
`;
