import styled from '@emotion/styled';
import { rem } from 'polished';
import { text, spaces, mixins, fontWeights } from 'atupri-component-library/lib/core/styles/theme/default';

export const StyledLabel = styled.h3`
    ${text.p3};
    font-weight: ${fontWeights.regular};
    ${mixins.responsiveStyles({
        'margin-bottom': {
            zero: `${rem(spaces.gamma)}`,
            medium: `${rem(spaces.epsilon)}`,
            wide: `${rem(spaces.epsilon)}`,
        },
    })}
`;

export const StyledAnswer = styled.div(({ answerType }) => {
    let styles = [];

    if (answerType === 'TOGGLE') {
        styles.push(`
        height: 100%;
      `);
    }

    return styles;
});
