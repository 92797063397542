import i18next from 'i18next';
import {
    EXTERNAL_PERSON,
    GENDER_MALE,
    getBasket,
    getGender,
    getPreviousInsurer,
    getTitle,
    needsToAnswerHealthQuestions,
    TITLE_FEMALE,
    TITLE_MALE,
} from '@src/util/person';
import { isVvgProcess } from '@src/util/process';
import { log } from '@src/middleware';
import { deepMerge } from '@src/util/data';
import getAntragTemplate from '@src/data/templates/antrag';
import { API_GET_HEALTHQUESTIONS } from '@src/api/healthQuestions.api';
import { API_UNIQUE_KEY } from '@src/api/uniqueKeyGeneration.api';

export const prepareOrderJson = async data => {
    const privateData = data.attributes.privateData;
    const hasExternalContactPerson = privateData.paymentContactperson === EXTERNAL_PERSON;

    let nextKey;
    await API_UNIQUE_KEY().then(({ key }) => {
        nextKey = key;
    });

    let json = {
        key: nextKey,
        source: prepareSource(data),
        sprache: prepareLanguage(i18next.language),
        ext_timestamp_cd: !!(privateData.onlineSubmission && isVvgProcess(data.relationships.basket)),
        strasse: hasExternalContactPerson
            ? privateData.paymentContactAdditionalPersonStreet
            : privateData.paymentStreet,
        zusatz: hasExternalContactPerson
            ? privateData.paymentContactAdditionalPersonAddressExtension
            : privateData.paymentAddressExtension,
        plz: hasExternalContactPerson ? privateData.paymentContactAdditionalPersonZip : privateData.paymentZip,
        ort: hasExternalContactPerson
            ? privateData.paymentContactAdditionalPersonLocation
            : privateData.paymentLocation,
        telefon_prv: hasExternalContactPerson
            ? privateData.paymentContactAdditionalPersonPhone
            : privateData.paymentContactpersonPhone,
        multi_year_contract: false,
        portal: !!privateData.confirmationMyAtupri,
        policy_email: false,
        email: hasExternalContactPerson
            ? privateData.paymentContactAdditionalPersonEmail
            : privateData.paymentContactpersonEmail,
        gemeinde: data.relationships.persons[0].municipalityId,
        zahlungsintervall: preparePaymentInterval(privateData.paymentInterval),
        zahlungsauswahl: preparePaymentMethod(privateData.paymentMethod),
        iban: privateData.paymentRefund,
        promotion: data.attributes.promotion,
        all_confirmed_cd: isMembershipConfirmed(privateData),
        personen: await preparePersons(data, hasExternalContactPerson, privateData),
        vertrag: data.attributes.collectiveAgreementNumber,
    };

    if (hasExternalContactPerson) {
        json.personen.push({
            correspondence: true,
            anrede: privateData.paymentContactAdditionalPersonGender === GENDER_MALE ? TITLE_MALE : TITLE_FEMALE,
            vorname: privateData.paymentContactAdditionalPersonFirstname,
            name: privateData.paymentContactAdditionalPersonLastname,
            geschlecht: privateData.paymentContactAdditionalPersonGender.toUpperCase(),
            geburtsdatum: privateData.paymentContactAdditionalPersonBirthdate,
            tarifbeginn: json.personen[0].tarifbeginn, // Hack, because for the api it's a required field, we don't have this info though.
        });
    }

    json.offerid = data.attributes.offerId;
    json.familie = data.attributes.familyNr;

    return deepMerge([getAntragTemplate.offerte, json]);
};

function preparePersons(data, hasExternalContactPerson, privateData) {
    return Promise.all(
        data.relationships.persons.map(async person => {
            const isCorrespondant = hasExternalContactPerson ? false : privateData.paymentContactperson === person.id;

            const personBasket = getBasket(data.relationships.basket, person.id);
            const personProducts = prepareProducts(personBasket);

            let offerPerson = {
                correspondence: isCorrespondant,
                anrede: getTitle(person),
                vorname: person.firstname,
                name: person.lastname,
                geschlecht: getGender(person, true),
                geburtsdatum: person.birthdate,
            };

            // set cardinal and personId if available
            if ('cardinal' in person) {
                offerPerson['cardinal'] = person.cardinal;
            }
            if ('personId' in person) {
                offerPerson['personid'] = person.personId;
            }
            if ('insuranceNumber' in person) {
                offerPerson['versicherter'] = person.insuranceNumber;
            }

            // add products
            if (personProducts.length > 0) {
                offerPerson['tarifbeginn'] = person.insuranceStart;
                offerPerson['nationalitaet'] = person.nationality;
                offerPerson['previous_insurer'] = getPreviousInsurer(person);

                if (person.permitType) {
                    offerPerson['aufenthaltsart'] = person.permitType;
                }
                if (person.dateOfEntry) {
                    offerPerson['einreisedatum'] = person.dateOfEntry;
                }

                offerPerson['produkte'] = personProducts;
            }

            // add health questions if person has vvg products
            if (needsToAnswerHealthQuestions(person.id, data)) {
                if (
                    typeof data.relationships.healthQuestions[person.id] !== 'undefined' &&
                    typeof data.relationships.healthQuestions[person.id].answers != 'undefined' &&
                    Object.keys(data.relationships.healthQuestions[person.id].answers).length > 0 &&
                    Object.getPrototypeOf(data.relationships.healthQuestions[person.id].answers) === Object.prototype
                ) {
                    let healthQuestions = await prepareHealthQuestions(data, person);

                    // if person has health questions, add the answers to the response
                    if (healthQuestions) {
                        offerPerson.questions = healthQuestions;
                    }
                } else {
                    throw new Error('Healthquestion answers are missing for person ' + person.id);
                }
            }

            return offerPerson;
        })
    );
}

function prepareProducts(personBasket) {
    const okpProducts = personBasket.okp.map(product => {
        let productProperties = {
            gesetz: 'KVG',
            tariff_objekt: product.tariff,
        };

        if (product.zsr) {
            productProperties.zsr = product.zsr;
        }

        return productProperties;
    });
    const vvgProducts = personBasket.vvg.map(product => {
        return {
            gesetz: 'VVG',
            tariff_objekt: product.tariff,
        };
    });

    return [...okpProducts, ...vvgProducts];
}

function getProductIdsForPersonsBasket(person, basket) {
    const personBasket = {
        okp: basket[person.id]?.okp || [],
        vvg: basket[person.id]?.vvg || [],
    };

    const okpProductIds = personBasket.okp.map(product => product.tariff);
    const vvgProductIds = personBasket.vvg.map(product => product.tariff);

    return [...okpProductIds, ...vvgProductIds].join(';');
}

async function prepareHealthQuestions(data, person) {
    return new Promise((resolve, reject) => {
        const { basket } = data.relationships;
        const answers = Object.entries(data.relationships.healthQuestions[person.id].answers);

        API_GET_HEALTHQUESTIONS(
            person.id,
            data,
            person.birthdate,
            person.gender,
            getProductIdsForPersonsBasket(person, basket),
            person.insuranceStart
        )
            .then(result => {
                const questions = result.data.questions;
                let preparedQuestionAnswers = [];

                for (const [answerId, value] of answers) {
                    preparedQuestionAnswers = prepareHealthQuestionAnswer(
                        preparedQuestionAnswers,
                        answerId,
                        value,
                        questions,
                        data.relationships.healthQuestions[person.id].answers
                    );
                }

                resolve(preparedQuestionAnswers);
            })
            .catch(e => {
                log.error('error', e);
                reject(e);
            });
    });
}

function prepareHealthQuestionAnswer(preparedQuestionAnswers, answerId, value, questions, answers) {
    const questionAnswerIdParts = answerId.split('_');
    const blockId = questionAnswerIdParts[0];
    const questionId = questionAnswerIdParts[0] + '_' + questionAnswerIdParts[1];

    if (questionId !== '_tmp') {
        const block = questions.find(block => block.id === blockId);
        if (block) {
            if (validateIfReference(block, answers)) {
                preparedQuestionAnswers = prepareHealthQuestionAnswerBlock(
                    preparedQuestionAnswers,
                    block,
                    answerId,
                    value,
                    questionId,
                    questions
                );
            }
        } else {
            log.error('invalid block for answer', blockId);
        }
    }

    return preparedQuestionAnswers;
}

function validateIfReference(block, answers) {
    return 'if' in block ? String(answers[block.if.reference]) === String(block.if.value) : true;
}

function prepareHealthQuestionAnswerBlock(preparedQuestionAnswers, block, answerId, value, questionId, questions) {
    const question = block.questions.find(q => q.id === questionId);
    let questionLabel = (question || {}).label;
    if (typeof questionLabel === 'undefined') {
        questionLabel = getQuestionLabelFromAnswerGroupsOfQuestion(question, answerId);
    }

    // flatten out multi valued answers
    if (Array.isArray(value)) {
        preparedQuestionAnswers.push({
            questionid: answerId,
            question: questionLabel || '-',
            answer: value.length.toString(),
        });

        preparedQuestionAnswers = prepareHealthQuestionAnswerSubAnswers(
            preparedQuestionAnswers,
            value,
            questionLabel,
            questions
        );
    } else if (typeof value !== 'undefined') {
        // boolean has to be sent as text true/false according to https://atupri.atlassian.net/browse/PP-1444
        if (typeof value === 'boolean') {
            value = value ? 'true' : 'false';
        }
        preparedQuestionAnswers.push({
            questionid: answerId,
            question: questionLabel || '-',
            answer: value,
        });
    }
    return preparedQuestionAnswers;
}

function prepareHealthQuestionAnswerSubAnswers(preparedQuestionAnswers, multiValue, questionLabel, questions) {
    for (const subAnswer of multiValue) {
        preparedQuestionAnswers = prepareHealthQuestionAnswerSubAnswer(
            preparedQuestionAnswers,
            subAnswer,
            questionLabel,
            questions
        );
    }
    return preparedQuestionAnswers;
}

function prepareHealthQuestionAnswerSubAnswer(preparedQuestionAnswers, subAnswer, questionLabel, questions) {
    for (const [subAnswerId, subValue] of Object.entries(subAnswer)) {
        const subQuestionAnswerIdParts = subAnswerId.split('_');
        const subBlockId = subQuestionAnswerIdParts[0];
        const subQuestionId = subQuestionAnswerIdParts[0] + '_' + subQuestionAnswerIdParts[1];

        let subQuestionLabel = questionLabel;

        const subBlock = questions.find(block => block.id === subBlockId);
        if (subBlock) {
            subQuestionLabel = getQuestionLabelFromSubBlock(subBlock, subQuestionId, subAnswerId);
        }

        if (typeof subValue !== 'undefined') {
            // boolean has to be sent as text true/false according to https://atupri.atlassian.net/browse/PP-1444
            let subValueString = subValue;
            if (typeof subValue === 'boolean') {
                subValueString = subValue ? 'true' : 'false';
            }
            preparedQuestionAnswers.push({
                questionid: subAnswerId,
                question: subQuestionLabel || '-',
                answer: subValueString,
            });
        }
    }

    return preparedQuestionAnswers;
}

function getQuestionLabelFromAnswerGroupsOfQuestion(question, answerId) {
    let questionLabel = '';

    for (const answerGroup of question.answerGroups) {
        const answer = answerGroup.answers.find(answerGroupItem => answerGroupItem.id === answerId);
        if (answer) {
            questionLabel = answer.label;
            break;
        }
    }
    return questionLabel;
}

function getQuestionLabelFromSubBlock(subBlock, subQuestionId, subAnswerId) {
    const subQuestion = subBlock.questions.find(q => q.id === subQuestionId);
    let questionLabel = (subQuestion || {}).label;

    if (typeof subQuestion !== 'undefined' && typeof questionLabel === 'undefined') {
        for (const subAnswerGroup of subQuestion.answerGroups) {
            const subAnswer = subAnswerGroup.answers.find(subAnswerGroupItem => subAnswerGroupItem.id === subAnswerId);
            if (subAnswer) {
                questionLabel = subAnswer.label;
            }
        }
    }
    return questionLabel;
}

function preparePaymentInterval(strPaymentinteval) {
    let paymentInterval = 1;
    switch (strPaymentinteval) {
        case '3months':
            paymentInterval = 3;
            break;
        case '6months':
            paymentInterval = 6;
            break;
        case 'annual':
            paymentInterval = 12;
            break;
    }
    return paymentInterval;
}

function preparePaymentMethod(strPaymentMethod) {
    let paymentMethod = null;
    switch (strPaymentMethod) {
        case 'ebill':
            paymentMethod = 'ESR';
            break;
        case 'lsv':
            paymentMethod = 'LSV';
            break;
        case 'debit':
            paymentMethod = 'DD';
            break;
        case 'bill':
            paymentMethod = 'ACCT';
            break;
        case 'crypto':
            paymentMethod = 'K';
            break;
    }
    return paymentMethod;
}

function prepareLanguage(locale) {
    let lang = 'D';
    if (locale === 'fr') {
        lang = 'F';
    } else if (locale === 'it') {
        lang = 'I';
    }

    return lang;
}

function prepareSource(data) {
    let source = 'U';
    if (data.attributes.source === 'KO') {
        source = data.attributes.source;
    } else if (data.attributes.privateData.onlineSubmission) {
        source = 'UO';
    }
    return source;
}

function isMembershipConfirmed(data) {
    return (
        'confirmationMembership' in data &&
        Array.isArray(data.confirmationMembership) &&
        data.confirmationMembership.length > 0 &&
        data.confirmationMembership[0] === 'membership'
    );
}
