import axios from 'axios';
import i18next from 'i18next';
import queryString from 'query-string';
import sortBy from 'lodash/sortBy';
import config from '@src/config';
import { log } from '@src/middleware';
import { sortCategories, sortProducts } from '@src/util/tariff/okp';
import { findPersonById } from '@src/util/person';
import { removeUndefinedProperties } from '@src/util/object';

const OKP_TARIF_API = `${config.apiEsbBase}/tariffs/v1/okp`;
const VVG_TARIF_API = `${config.apiEsbBase}/tariffs/v1/vvg`;
const UTI_TARIF_API = `${config.apiSessionBase}/tariffs/uti`;

let okpRequest = null;
let vvgRequest = null;
let utiRequest = null;

export const API_GET_OKP_TARIFFS = (personId, persons, collectiveAgreementNumber = undefined, apiBaseUrl = null) =>
    new Promise((resolve, reject) => {
        const person = findPersonById(personId, persons);

        const {
            // Required
            birthDate = person.birthdate,
            postalCode = person.zip,
            city = person.place,
            communityId = person.municipalityId,
            coverBegin = person.insuranceStart,
            language = i18next.language,
            gender,
            residencePermit,
        } = person;

        let payload = {
            birthDate,
            postalCode,
            city,
            communityId,
            gender,
            coverBegin,
            language,
            collectiveAgreementNumber,
            residencePermit,
            mode: config.dev ? 1 : 0,
        };

        // Remove undefined properties..
        payload = removeUndefinedProperties(payload);

        let apiUrl = apiBaseUrl ? `${apiBaseUrl}/tariffs/v1/okp` : OKP_TARIF_API;
        apiUrl += '?' + queryString.stringify(payload);

        log.debug('=== API_GET_OKP_TARIFFS ===', { apiUrl, payload });

        if (okpRequest) {
            okpRequest.cancel();
        }

        okpRequest = axios.CancelToken.source();

        axios
            .get(apiUrl, {
                timeout: 30000,
                cancelToken: okpRequest.token,
            })
            .then(response => {
                let data = response.data;
                let sortedData = {};

                if (typeof response.data === 'string') {
                    try {
                        data = JSON.parse(data);
                    } catch (e) {
                        reject({ data: `Error.API: ${e}`, code: '71' });
                        data = null;
                    }
                }

                if (data && typeof data.error !== 'undefined') {
                    log.error({ error: data.error });
                    reject({ data: `Error.API: ${data.error}`, code: '72' });
                }

                if (data) {
                    sortedData = {
                        productGroups: data.productGroups,
                        categories: sortCategories(
                            { ...data },
                            {
                                product: data.products.find(product => product.recommended === true)?.id,
                                variant: data.variants.find(variant => variant.recommended === true)?.id,
                            }
                        ),
                        products: sortProducts(
                            { ...data },
                            {
                                category: data.categories.find(category => category.recommended === true)?.id,
                                variant: data.variants.find(variant => variant.recommended === true)?.id,
                            }
                        ),
                        options: data.options,
                        variants: data.variants,
                        rebates: data.rebates,
                        tariffs: data.tariffs,
                    };
                } else {
                    // data exception in prod
                    reject({ data: 'Error.code', code: '70' });
                }

                resolve({ okp: sortedData });
            })
            .catch(err => {
                log.error({ code: err.code, error: err.message });
            });
    });

export const API_GET_VVG_TARIFFS = (
    personId,
    persons,
    okpProduct = 'NONE',
    collectiveAgreementNumber = undefined,
    apiBaseUrl = null
) =>
    new Promise((resolve, reject) => {
        const person = findPersonById(personId, persons);

        const {
            birthDate = person.birthdate,
            postalCode = person.zip,
            city = person.place,
            communityId = person.municipalityId,
            coverBegin = person.insuranceStart,
            language = i18next.language,
            gender,
            permitType,
        } = person;

        let payload = {
            birthDate,
            postalCode,
            city,
            communityId,
            gender,
            coverBegin,
            language,
            collectiveAgreementNumber,
            residencePermit: permitType,
            okp: okpProduct,
            mode: config.dev ? 1 : 0,
        };

        // Remove undefined properties..
        payload = removeUndefinedProperties(payload);

        let apiUrl = apiBaseUrl ? `${apiBaseUrl}/tariffs/v1/vvg` : VVG_TARIF_API;
        apiUrl += '?' + queryString.stringify(payload);

        log.debug('=== API_GET_VVG_TARIFFS ===', { apiUrl, payload });

        if (vvgRequest) {
            vvgRequest.cancel();
        }

        vvgRequest = axios.CancelToken.source();

        axios
            .get(apiUrl, {
                timeout: 30000,
                cancelToken: vvgRequest.token,
            })
            .then(response => {
                let sortedData = {};
                let data = response.data;

                if (typeof response.data === 'string') {
                    try {
                        log.debug({ data });
                        data = JSON.parse(data);
                    } catch (e) {
                        reject({ data: `Error.API: ${e}`, code: '76' });
                        data = null;
                    }
                }

                if (data && typeof data.error !== 'undefined') {
                    log.error({ error: data.error });
                    reject({ data: `Error.API: ${data.error}`, code: '77' });
                }

                if (data) {
                    sortedData = {
                        options: sortBy(data.options, ['sorting']),
                        productGroups: sortBy(data.productGroups, ['sorting']),
                        products: sortBy(data.products, ['sorting']),
                        variants: sortBy(data.variants, ['sorting']),
                        rebates: data.rebates,
                        tariffs: data.tariffs,
                    };
                } else {
                    // data exception in prod
                    reject({ data: 'Error.code', code: '75' });
                }

                resolve({ vvg: sortedData });
            })
            .catch(err => {
                log.error({ code: err.code, error: err.message });
            });
    });

export const API_GET_UTI_TARIFFS = (personId, persons, collectiveAgreementNumber = undefined, apiBaseUrl = null) =>
    new Promise((resolve, reject) => {
        const person = findPersonById(personId, persons);
        let payload = {
            birthDate: person.birthdate,
            postalCode: person.zip,
            city: person.place,
            communityId: person.municipalityId,
            coverBegin: person.insuranceStart,
            language: i18next.language,
            gender: person.gender,
            residencePermit: person.permitType,
            collectiveAgreementNumber,
        };

        // Remove undefined properties..
        payload = removeUndefinedProperties(payload);

        let apiUrl = apiBaseUrl ? `${apiBaseUrl}/tariffs/uti` : UTI_TARIF_API;
        apiUrl += '?' + queryString.stringify(payload);

        log.debug('=== API_GET_UTI_TARIFFS ===', { apiUrl, payload });

        if (utiRequest) {
            utiRequest.cancel();
        }

        utiRequest = axios.CancelToken.source();

        axios
            .get(apiUrl, {
                timeout: 30000,
                cancelToken: utiRequest.token,
            })
            .then(response => {
                let data = response.data;

                if (typeof response.data === 'string') {
                    try {
                        log.debug({ data });
                        data = JSON.parse(data);
                    } catch (e) {
                        reject({ data: `Error.API: ${e}`, code: '78' });
                        data = null;
                    }
                }

                if (data && typeof data.error !== 'undefined') {
                    log.error({ error: data.error });
                    reject({ data: `Error.API: ${data.error}`, code: '79' });
                }

                resolve({ uti: data });
            })
            .catch(err => {
                log.error({ code: err.code, error: err.message });
            });
    });
