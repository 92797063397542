export const getAvailableModels = practice => {
    return Object.keys(practice).filter(key => key !== 'zsrNr');
};

export const getModelToShow = (practice, selectedModel) => {
    return practice[selectedModel]
        ? selectedModel
        : getAvailableModels(practice).find(model => model !== selectedModel);
};

export const getPracticeToShow = (practice, selectedModel) => {
    return practice[getModelToShow(practice, selectedModel)];
};
