import React from 'react';
import { arrayOf, bool, func, node, shape, string } from 'prop-types';
import classNames from 'classnames';
import { Field } from 'react-final-form';
import { EmphasizedToggle, RadioGroup } from 'atupri-component-library/lib/primitives';
import { Stack } from 'atupri-component-library/lib/helpers';

const FieldRadioButtonGroup = ({ id, name, items, disabled, isListing, validate }) => {
    const classes = classNames(isListing ? 'is-listing' : null);

    return (
        <RadioGroup id={id} name={name} floatingFocusTargetId="radioGroup" classname={classes} disabled={disabled}>
            <Stack direction="row">
                {items?.map((item, index) => (
                    <Field key={id + index} value={item.value} validate={validate} name={item.id}>
                        {props => (
                            <EmphasizedToggle
                                {...props.input}
                                value={item.value}
                                selectedValue={props.input.value}
                                onChange={event => props.input.onChange(event.target.value)}
                                controlType="radio"
                                errorText={props.meta.touched && props.meta.error}
                            >
                                {item.label}
                            </EmphasizedToggle>
                        )}
                    </Field>
                ))}
            </Stack>
        </RadioGroup>
    );
};

FieldRadioButtonGroup.propTypes = {
    id: string.isRequired,
    name: string.isRequired,
    label: node,
    items: arrayOf(
        shape({
            value: string.isRequired,
            label: node.isRequired,
        })
    ).isRequired,
    disabled: bool,
    isListing: bool,
    validate: func,
};

export default FieldRadioButtonGroup;
