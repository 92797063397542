import i18next from 'i18next';
import logoSrc from '@assets/img/logo.svg';

const headerData = () => ({
    logo: {
        link: {
            href: i18next.t('Header.Logo.Link.href'),
            title: i18next.t('Header.Logo.Link.title'),
            target: '_blank',
        },
        image: {
            src: logoSrc,
        },
    },
});

export default headerData;
