import styled from '@emotion/styled';
import { rem } from 'polished';
import { colors, fontWeights, mixins, spaces, text } from 'atupri-component-library/lib/core/styles/theme/default';
import { Box, Container, GridItem } from 'atupri-component-library/lib/helpers';
import { AccordionItemContent, ShowDetails, UtiWidget } from 'atupri-component-library/lib/components';
import { Card, Icon, listStyle } from 'atupri-component-library/lib/primitives';

export const StyledFullWidthContainer = styled.div(({ bgColor, twoColBackground }) => {
    const styles = [
        `
      width: 100vw;
      position: relative;
      left: 50%;
      right: 50%;
      margin-left: -50vw;
      margin-right: -50vw;

      ${mixins.responsiveStyles({
          'padding-top': { zero: spaces.eta, medium: 0 },
          'padding-bottom': { zero: spaces.eta, medium: 0 },
      })}
    `,
    ];

    const backgroundStyles = `
      &::after {
        content: '';
        z-index: 0;
        position: absolute;
        top: 0;
        right: 0;
        width: 50%;
        height: 100%;
        display: block;
        background-color: ${bgColor || colors.blue100};
      }
    `;

    const bgColorStyle = `background-color: ${bgColor};`;

    if (twoColBackground) {
        styles.push(backgroundStyles);
    } else if (bgColorStyle) {
        styles.push(bgColorStyle);
    }

    return styles;
});

export const StyledContainer = styled(Container)`
    position: relative;
    z-index: 1;
    word-break: break-word;

    ${mixins.responsiveStyles({
        'padding-top': { zero: 133, medium: 172 },
        'padding-bottom': { zero: 0, medium: 128 },
    })}

    b, strong {
        font-weight: ${fontWeights.semibold};
        color: ${colors.black};
    }
`;

export const StyledGridItem = styled(GridItem)`
    ${mixins.responsiveStyles({
        'padding-left': { medium: 40, wide: 90 },
        'padding-right': { medium: 40, wide: 90 },
    })}
`;

export const StyledCard = styled(Card)`
    word-break: break-word;
    background-color: ${colors.white};
`;

export const StyledUtiWidget = styled(UtiWidget)`
    word-break: break-word;
    background-color: ${colors.white};
`;

export const StyledOverviewIcon = styled(Icon)`
    font-size: ${rem(20)};
    margin-right: ${rem(spaces.gamma)};
`;

export const StyledNamedLinkInner = styled.span`
    display: flex;
    align-items: center;
    color: ${colors.primary};
`;

export const StyledBoxWide = styled(Box)`
    width: 100%;
    ${mixins.responsiveStyles({
        'margin-top': { zero: spaces.gamma * -1, medium: spaces.delta * -1 },
        'margin-bottom': { zero: 0, medium: spaces.epsilon * -1 },
        'padding-bottom': { zero: spaces.beta, medium: 0 },
    })}
`;

export const StyledAccordionItemContent = styled(AccordionItemContent)`
    padding-left: ${rem(40)};
`;

export const StyledListContainer = styled.div`
    color: ${colors.grey700};

    ul {
        ${listStyle('delta', 'disc', 'primary')}
    }
`;

export const StyledAccordionContentText = styled(Box)`
    width: 100%;
    color: ${colors.grey700};

    p {
        ${text.p3};
        margin: 0;
    }

    ul {
        ${listStyle(undefined, 'disc', 'grey700')}
    }

    > :not(:last-child) {
        margin-bottom: ${rem(spaces.beta)};
    }
`;

export const StyledIcon = styled(Icon)`
    text-align: center;
    width: ${rem(16)};
`;

export const StyledShowDetails = styled(ShowDetails)`
    width: 100%;
    ${mixins.responsiveStyles({
        'margin-bottom': { zero: spaces.zeta * -1, medium: 0 },
    })}
`;
