import styled from '@emotion/styled';
import { rem } from 'polished';
import { css } from '@emotion/react';
import { text, fontWeights, colors, mixins, spaces } from 'atupri-component-library/lib/core/styles/theme/default';
import { Divider } from 'atupri-component-library/lib/helpers';
import { FieldInput } from '@src/components';

export const StyledSaveSession = styled.div`
    ${text.p4}

    user-select: none;
    color: ${colors.grey300};
`;

export const StyledHeader = styled.h3`
    font-size: ${rem(20)};
    line-height: ${32 / 20};
    font-weight: ${fontWeights.regular};
    margin: 0;
`;

export const StyledDivider = styled(Divider)`
    ${mixins.responsiveStyles({
        margin: {
            zero: `${rem(4)} ${rem(-30)} ${rem(16)}`,
            medium: `${rem(26)} ${rem(-40)} ${rem(38)}`,
            wide: `${rem(52)} ${rem(-40)} ${rem(64)}`,
        },
    })}
`;

export const StyledCustomSpacer = styled.div(({ space }) => {
    const styles = [``];

    switch (space) {
        case 'pika':
            styles.push(`
        ${mixins.responsiveStyles({
            'margin-top': { zero: `${rem(16)}`, medium: `${rem(32)}`, wide: `${rem(32)}` },
        })}
      `);
            break;
        case 'bulba':
            styles.push(`
        ${mixins.responsiveStyles({
            'margin-top': { zero: `0`, medium: `${rem(16)}`, wide: `${rem(16)}` },
        })}
      `);
            break;
    }

    return styles;
});

export const StyledButtonGroup = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    ${mixins.responsiveStyles({
        'margin-bottom': { zero: `${rem(-10)}`, medium: `${rem(-12)}`, wide: `${rem(-12)}` },
    })}

    button,
  a {
        ${mixins.responsiveStyles({
            'margin-right': {
                zero: `${rem(spaces.delta)}`,
                medium: `${rem(spaces.epsilon)}`,
                wide: `${rem(spaces.epsilon)}`,
            },
            'margin-bottom': {
                zero: `${rem(spaces.delta)}`,
                medium: `${rem(spaces.epsilon)}`,
                wide: `${rem(spaces.epsilon)}`,
            },
        })}
    }

    a {
        color: ${colors.primary};
        text-decoration: none;
    }
`;

export const StyledFieldTextInput = styled(FieldInput)`
    input {
        width: 100%;
    }
`;

export const ModalStyles = () => css`
    .modal__modal--custom {
        max-width: ${rem(880)};
    }
`;
