import React from 'react';
import { arrayOf, func, string, shape, node } from 'prop-types';
import { Field } from 'react-final-form';
import { EmphasizedToggle, RadioGroup } from 'atupri-component-library/lib/primitives';
import { Stack } from 'atupri-component-library/lib/helpers';

const FieldBool = ({ id, name, items, validate }) => {
    return (
        <RadioGroup id={id} name={name} floatingFocusTargetId="radioGroup">
            <Stack direction="row">
                {items?.map((item, index) => (
                    <Field key={id + index} value={item.value} validate={validate} name={item.id}>
                        {props => (
                            <EmphasizedToggle
                                {...props.input}
                                value={item.value}
                                selectedValue={props.input.value}
                                onChange={event => props.input.onChange(event.target.value)}
                                controlType="radio"
                                hasControl={false}
                                icon={item.value === '1' || item.value === 'yes' ? 'check' : 'cross'}
                                iconSize={16}
                                errorText={props.meta.touched && props.meta.error}
                            >
                                {item.label}
                            </EmphasizedToggle>
                        )}
                    </Field>
                ))}
            </Stack>
        </RadioGroup>
    );
};

FieldBool.propTypes = {
    id: string.isRequired,
    name: string.isRequired,
    items: arrayOf(
        shape({
            value: string.isRequired,
            label: node.isRequired,
        })
    ),
    validate: func,
};

export default FieldBool;
