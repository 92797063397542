import i18next from 'i18next';

const permitTypes = () => [
    {
        label: i18next.t('PersonInfoForm.permitType_A'),
        value: 'A',
    },
    {
        label: i18next.t('PersonInfoForm.permitType_B'),
        value: 'B',
    },
    {
        label: i18next.t('PersonInfoForm.permitType_C'),
        value: 'C',
    },
    {
        label: i18next.t('PersonInfoForm.permitType_F'),
        value: 'F',
    },
    {
        label: i18next.t('PersonInfoForm.permitType_G'),
        value: 'G',
    },
    {
        label: i18next.t('PersonInfoForm.permitType_L'),
        value: 'L',
    },
    {
        label: i18next.t('PersonInfoForm.permitType_N'),
        value: 'N',
    },
];

export default permitTypes;
