import React, { useMemo, useState } from 'react';
import { bool, func, shape, string } from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import i18next from 'i18next';
import { Field, Form as FinalForm } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import arrayMutators from 'final-form-arrays';
import InputMask from 'react-input-mask';

// components
import { Form, AutoSave, EditSubmissionCTA } from '@src/components';

import { Divider, Grid, Spacer, Stack } from 'atupri-component-library/lib/helpers';
import { EmphasizedToggle, Icon, Input, P1, P3 } from 'atupri-component-library/lib/primitives';

import { StyledBox, StyledGridItem } from './EditSubmissionPaymentForm.style';

// util
import { propTypes } from '@src/util/types';
import { getAgeFromDate, convertDateToPresentationFormat, finalFormFieldDateFormatAttributes } from '@src/util/date';
import { deepMerge } from '@src/util/data';
import createScrollToFirstErrorDecorator from '@src/util/finalForm/decorators/scrollToFirstError';

import { sanitizePhoneNumber, sanitizeDOM } from '@src/util/sanitize';

import {
    maxLength,
    required,
    isSeconAcceptedIban,
    composeValidators,
    requiredFieldArrayCheckbox,
    emailFormatValid,
    validDate,
    minDate,
    maxDate,
    ageAtLeast,
    validPhoneNumber,
} from '@src/util/validators';
import { getAutosaved, setAutosaved } from '@src/util/autosave';

import { EXTERNAL_PERSON } from '@src/util/person';

const TITLE_MAX_LENGTH = 120;
const ADDRESS_EXTENSION_MAX_LENGTH = 25;

const scrollToFirstErrorDecorator = createScrollToFirstErrorDecorator();

const mutators = {
    ...arrayMutators,
    setValue: ([field, value], state, { changeValue }) => {
        changeValue(state, field, () => value);
    },
};

const EditSubmissionPaymentForm = props => {
    const { submission } = props;

    const initialValues = useMemo(() => {
        let defaultValues = props.initialValues;

        // check for autosaved values
        const autosaved = getAutosaved(submission.id.uuid, 'paymentForm');
        if (autosaved?.values) {
            if (defaultValues) {
                defaultValues = deepMerge([defaultValues, autosaved.values]);
            } else {
                defaultValues = autosaved.values;
            }
        }

        return defaultValues;
    }, []);

    const [isExternalPaymentContact, setIsExternalPaymentContact] = useState(
        initialValues?.paymentContactperson === EXTERNAL_PERSON
    );

    const getPersonOptions = persons => {
        let optionsArr = [];

        for (const person of persons) {
            const birthdatePresentation = convertDateToPresentationFormat(person.birthdate);
            let age = getAgeFromDate(person.birthdate);
            if (age >= 18) {
                optionsArr.push({
                    value: person.id,
                    label: `${person.firstname} ${person.lastname} (${birthdatePresentation})`,
                });
            }
        }

        optionsArr.push({
            value: EXTERNAL_PERSON,
            label: i18next.t('EditSubmissionPaymentForm.paymentContactAdditionalPerson.option.label'),
        });

        return optionsArr;
    };

    const renderPersonOption = (option, index) => {
        return (
            <StyledGridItem key={`person${index}`} shrink={{ zero: 0, medium: 1 }}>
                <Field
                    name={`paymentContactperson`}
                    validate={requiredFieldArrayCheckbox(
                        i18next.t('EditSubmissionPaymentForm.paymentContactPersonRequired')
                    )}
                >
                    {toggleProps => (
                        <EmphasizedToggle
                            controlType="radio"
                            value={option.value}
                            name={toggleProps.input.name}
                            selectedValue={toggleProps.input.value}
                            onChange={event => toggleProps.input.onChange(event.target.value)}
                            errorText={index === 0 && toggleProps.meta.touched && toggleProps.meta.error}
                        >
                            {option.label}
                        </EmphasizedToggle>
                    )}
                </Field>
            </StyledGridItem>
        );
    };

    const renderExternalPaymentContact = isExternalPaymentContact => {
        return (
            isExternalPaymentContact && (
                <>
                    <Grid columns={{ zero: 1, medium: 3 }}>
                        <StyledGridItem size={{ zero: 'full' }} verticalGutter={0}>
                            <Grid>
                                <StyledGridItem shrink={1}>
                                    <Field
                                        name={`paymentContactAdditionalPersonGender`}
                                        validate={requiredFieldArrayCheckbox(
                                            i18next.t(
                                                'EditSubmissionPaymentForm.paymentContactAdditionalPersonGenderRequired'
                                            )
                                        )}
                                    >
                                        {props => (
                                            <EmphasizedToggle
                                                controlType="radio"
                                                icon="woman"
                                                value="f"
                                                name={props.input.name}
                                                selectedValue={props.input.value}
                                                onChange={event => props.input.onChange(event.target.value)}
                                                errorText={props.meta.touched && props.meta.error}
                                            >
                                                {i18next.t(
                                                    'EditSubmissionPaymentForm.paymentContactAdditionalPersonGenderOptions.f'
                                                )}
                                            </EmphasizedToggle>
                                        )}
                                    </Field>
                                </StyledGridItem>
                                <StyledGridItem shrink={1}>
                                    <Field name={`paymentContactAdditionalPersonGender`}>
                                        {props => (
                                            <EmphasizedToggle
                                                controlType="radio"
                                                icon="man"
                                                value="m"
                                                name={props.input.name}
                                                selectedValue={props.input.value}
                                                onChange={event => props.input.onChange(event.target.value)}
                                            >
                                                {i18next.t(
                                                    'EditSubmissionPaymentForm.paymentContactAdditionalPersonGenderOptions.m'
                                                )}
                                            </EmphasizedToggle>
                                        )}
                                    </Field>
                                </StyledGridItem>
                            </Grid>
                        </StyledGridItem>
                    </Grid>
                    <Grid columns={{ zero: 3 }}>
                        <StyledGridItem size={{ zero: 3 }} verticalGutter={0}>
                            <Grid columns={{ zero: 1, medium: 3 }}>
                                <StyledGridItem size={1}>
                                    <Field
                                        id="paymentContactAdditionalPersonFirstname"
                                        name={`paymentContactAdditionalPersonFirstname`}
                                        label={i18next.t(
                                            'EditSubmissionPaymentForm.paymentContactAdditionalPersonFirstname'
                                        )}
                                        validate={required(
                                            i18next.t(
                                                'EditSubmissionPaymentForm.paymentContactAdditionalPersonFirstnameRequired'
                                            )
                                        )}
                                    >
                                        {props => (
                                            <Input
                                                name={props.input.name}
                                                value={props.input.value}
                                                onChange={props.input.onChange}
                                                label={props.label}
                                                isRequired
                                                errorText={props.meta.touched && props.meta.error}
                                            />
                                        )}
                                    </Field>
                                </StyledGridItem>
                                <StyledGridItem size={1}>
                                    <Field
                                        id="paymentContactAdditionalPersonLastname"
                                        name={`paymentContactAdditionalPersonLastname`}
                                        label={i18next.t(
                                            'EditSubmissionPaymentForm.paymentContactAdditionalPersonLastname'
                                        )}
                                        validate={required(
                                            i18next.t(
                                                'EditSubmissionPaymentForm.paymentContactAdditionalPersonLastnameRequired'
                                            )
                                        )}
                                    >
                                        {props => (
                                            <Input
                                                name={props.input.name}
                                                value={props.input.value}
                                                onChange={props.input.onChange}
                                                label={props.label}
                                                isRequired
                                                errorText={props.meta.touched && props.meta.error}
                                            />
                                        )}
                                    </Field>
                                </StyledGridItem>
                                <StyledGridItem size={1}>
                                    <Field
                                        id="paymentContactAdditionalPersonBirthdate"
                                        name={'paymentContactAdditionalPersonBirthdate'}
                                        label={i18next.t(
                                            'EditSubmissionPaymentForm.paymentContactAdditionalPersonBirthdate'
                                        )}
                                        validate={composeValidators(
                                            required(
                                                i18next.t(
                                                    'EditSubmissionPaymentForm.paymentContactAdditionalPersonBirthdateRequired'
                                                )
                                            ),
                                            validDate(
                                                i18next.t(
                                                    'EditSubmissionPaymentForm.paymentContactAdditionalPersonBirthdateRequired'
                                                )
                                            ),
                                            minDate(
                                                i18next.t(
                                                    'EditSubmissionPaymentForm.paymentContactAdditionalPersonBirthdateTooSmall'
                                                ),
                                                '1900-01-01'
                                            ),
                                            maxDate(
                                                i18next.t(
                                                    'EditSubmissionPaymentForm.paymentContactAdditionalPersonBirthdateTooBig'
                                                ),
                                                moment()
                                            ),
                                            ageAtLeast(
                                                i18next.t(
                                                    'EditSubmissionPaymentForm.paymentContactAdditionalPersonBirthdateAgeAtLeast18'
                                                ),
                                                18
                                            )
                                        )}
                                        {...finalFormFieldDateFormatAttributes}
                                    >
                                        {props => (
                                            <InputMask
                                                label={props.label}
                                                name={props.input.name}
                                                value={props.input.value}
                                                onChange={props.input.onChange}
                                                errorText={props.meta.touched && props.meta.error}
                                                isRequired
                                                mask="99.99.9999"
                                            >
                                                {inputProps => <Input {...inputProps} />}
                                            </InputMask>
                                        )}
                                    </Field>
                                </StyledGridItem>
                            </Grid>
                        </StyledGridItem>
                    </Grid>
                </>
            )
        );
    };

    const renderPaymentContactPhone = (isExternalPaymentContact, form) => {
        return (
            <>
                <Field
                    id={isExternalPaymentContact ? 'paymentContactAdditionalPersonPhone' : 'paymentContactpersonPhone'}
                    name={
                        isExternalPaymentContact ? 'paymentContactAdditionalPersonPhone' : 'paymentContactpersonPhone'
                    }
                    label={i18next.t('EditSubmissionPaymentForm.paymentContactPersonPhone')}
                    validate={composeValidators(
                        required(i18next.t('EditSubmissionPaymentForm.paymentContactPersonPhoneRequired')),
                        validPhoneNumber(
                            i18next.t('EditSubmissionPaymentForm.paymentContactPersonPhoneValidPhoneNumber')
                        )
                    )}
                >
                    {props => (
                        <Input
                            name={props.input.name}
                            value={props.input.value}
                            onChange={props.input.onChange}
                            label={props.label}
                            isRequired
                            errorText={props.meta.touched && props.meta.error}
                            type="tel"
                        />
                    )}
                </Field>
                <OnChange name="paymentContactpersonPhone">
                    {(current, previous) => {
                        if (current !== previous) {
                            form.mutators.setValue('paymentContactpersonPhone', sanitizePhoneNumber(current));
                        }
                    }}
                </OnChange>
                <OnChange name="paymentContactAdditionalPersonPhone">
                    {(current, previous) => {
                        if (current !== previous) {
                            form.mutators.setValue('paymentContactAdditionalPersonPhone', sanitizePhoneNumber(current));
                        }
                    }}
                </OnChange>
            </>
        );
    };

    const renderContactPerson = (persons, isExternalPaymentContact, form, values) => {
        return (
            <StyledBox key="contactPerson" borderRadius={8} shadow="ctaBlueHover">
                <P1>{i18next.t('EditSubmissionPaymentForm.paymentContactPersonTitle')}</P1>
                <Spacer space="epsilon" />
                <P3
                    dangerouslySetInnerHTML={{
                        __html: sanitizeDOM(i18next.t('EditSubmissionPaymentForm.paymentContactPersonText')),
                    }}
                />
                <Divider />
                <Grid>{getPersonOptions(persons).map((option, index) => renderPersonOption(option, index))}</Grid>
                <Spacer space="epsilon" />
                {renderExternalPaymentContact(isExternalPaymentContact)}
                <Grid columns={{ zero: 3 }}>
                    <StyledGridItem size={{ zero: 3, wide: 3 }} verticalGutter={0}>
                        <Grid columns={{ zero: 1, medium: 3 }}>
                            <StyledGridItem size={1}>
                                <Field
                                    id={
                                        isExternalPaymentContact
                                            ? 'paymentContactAdditionalPersonEmail'
                                            : 'paymentContactpersonEmail'
                                    }
                                    name={
                                        isExternalPaymentContact
                                            ? 'paymentContactAdditionalPersonEmail'
                                            : 'paymentContactpersonEmail'
                                    }
                                    label={i18next.t('EditSubmissionPaymentForm.paymentContactPersonEmail')}
                                    validate={composeValidators(
                                        required(
                                            i18next.t('EditSubmissionPaymentForm.paymentContactPersonEmailRequired')
                                        ),
                                        emailFormatValid(i18next.t('EditSubmissionPaymentForm.emailInvalid'))
                                    )}
                                >
                                    {props => (
                                        <Input
                                            name={props.input.name}
                                            value={props.input.value}
                                            onChange={props.input.onChange}
                                            label={props.label}
                                            isRequired
                                            errorText={props.meta.touched && props.meta.error}
                                        />
                                    )}
                                </Field>
                            </StyledGridItem>

                            <StyledGridItem size={1}>
                                {renderPaymentContactPhone(isExternalPaymentContact, form)}
                            </StyledGridItem>

                            <StyledGridItem size={1}></StyledGridItem>

                            <StyledGridItem size={1}>
                                <Field
                                    id={
                                        isExternalPaymentContact
                                            ? 'paymentContactAdditionalPersonStreet'
                                            : 'paymentStreet'
                                    }
                                    name={
                                        isExternalPaymentContact
                                            ? 'paymentContactAdditionalPersonStreet'
                                            : 'paymentStreet'
                                    }
                                    label={i18next.t('EditSubmissionPaymentForm.paymentStreet')}
                                    validate={composeValidators(
                                        required(i18next.t('EditSubmissionPaymentForm.paymentStreetRequired')),
                                        maxLength(
                                            i18next.t('EditSubmissionPaymentForm.maxLength', {
                                                maxLength: TITLE_MAX_LENGTH,
                                            }),
                                            TITLE_MAX_LENGTH
                                        )
                                    )}
                                >
                                    {props => (
                                        <Input
                                            name={props.input.name}
                                            value={props.input.value}
                                            onChange={props.input.onChange}
                                            label={props.label}
                                            isRequired
                                            errorText={props.meta.touched && props.meta.error}
                                        />
                                    )}
                                </Field>
                            </StyledGridItem>

                            <StyledGridItem size={1}>
                                <Field
                                    id={
                                        isExternalPaymentContact
                                            ? 'paymentContactAdditionalPersonAddressExtension'
                                            : 'paymentAddressExtension'
                                    }
                                    name={
                                        isExternalPaymentContact
                                            ? 'paymentContactAdditionalPersonAddressExtension'
                                            : 'paymentAddressExtension'
                                    }
                                    label={i18next.t('EditSubmissionPaymentForm.paymentAddressExtension')}
                                    validate={composeValidators(
                                        maxLength(
                                            i18next.t('EditSubmissionPaymentForm.maxLength', {
                                                maxLength: ADDRESS_EXTENSION_MAX_LENGTH,
                                            }),
                                            ADDRESS_EXTENSION_MAX_LENGTH
                                        )
                                    )}
                                >
                                    {props => (
                                        <Input
                                            name={props.input.name}
                                            value={props.input.value}
                                            onChange={props.input.onChange}
                                            label={props.label}
                                            errorText={props.meta.touched && props.meta.error}
                                            autoComplete="off"
                                        />
                                    )}
                                </Field>
                            </StyledGridItem>

                            <StyledGridItem size={1}>
                                <Field
                                    id={
                                        isExternalPaymentContact
                                            ? 'paymentContactAdditionalPersonZipText'
                                            : 'paymentZipText'
                                    }
                                    name={
                                        isExternalPaymentContact
                                            ? 'paymentContactAdditionalPersonZipText'
                                            : 'paymentZipText'
                                    }
                                    label={i18next.t('EditSubmissionPaymentForm.paymentZip')}
                                    initialValue={values.paymentZipText}
                                    validate={required(i18next.t('EditSubmissionPaymentForm.paymentZipRequired'))}
                                >
                                    {props => (
                                        <Input
                                            name={props.input.name}
                                            value={props.input.value}
                                            onChange={props.input.onChange}
                                            label={props.label}
                                            isRequired
                                            inputRightIcon={<Icon iconName="lock" />}
                                            disabled
                                            errorText={props.meta.touched && props.meta.error}
                                        />
                                    )}
                                </Field>
                                <Field
                                    id={isExternalPaymentContact ? 'paymentContactAdditionalPersonZip' : 'paymentZip'}
                                    name={isExternalPaymentContact ? 'paymentContactAdditionalPersonZip' : 'paymentZip'}
                                    label={i18next.t('EditSubmissionPaymentForm.paymentZip')}
                                    initialValue={values.paymentZip}
                                    validate={required(i18next.t('EditSubmissionPaymentForm.paymentZipRequired'))}
                                >
                                    {props => (
                                        <input
                                            type="hidden"
                                            name={props.input.name}
                                            value={props.input.value}
                                            onChange={props.input.onChange}
                                        />
                                    )}
                                </Field>
                                <Field
                                    id={
                                        isExternalPaymentContact
                                            ? 'paymentContactAdditionalPersonLocation'
                                            : 'paymentLocation'
                                    }
                                    name={
                                        isExternalPaymentContact
                                            ? 'paymentContactAdditionalPersonLocation'
                                            : 'paymentLocation'
                                    }
                                    label={i18next.t('EditSubmissionPaymentForm.paymentLocation')}
                                    initialValue={values.paymentLocation}
                                    validate={required(i18next.t('EditSubmissionPaymentForm.paymentLocationRequired'))}
                                >
                                    {props => (
                                        <input
                                            type="hidden"
                                            name={props.input.name}
                                            value={props.input.value}
                                            onChange={props.input.onChange}
                                        />
                                    )}
                                </Field>
                            </StyledGridItem>
                        </Grid>
                    </StyledGridItem>
                </Grid>
            </StyledBox>
        );
    };

    const renderPaymentInterval = () => {
        // payment interval options
        const paymentIntervalOptions = [
            {
                value: 'monthly',
                label: i18next.t('EditSubmissionPaymentForm.formOptionsPaymentInterval.monthly'),
            },
            {
                value: '3months',
                label: i18next.t('EditSubmissionPaymentForm.formOptionsPaymentInterval.3months'),
            },
            {
                value: '6months',
                label: i18next.t('EditSubmissionPaymentForm.formOptionsPaymentInterval.6months'),
            },
            {
                value: 'annual',
                label: i18next.t('EditSubmissionPaymentForm.formOptionsPaymentInterval.annual'),
            },
        ];

        return (
            <StyledBox key="paymentInterval" borderRadius={8} shadow="ctaBlueHover">
                <P1>{i18next.t('EditSubmissionPaymentForm.paymentIntervalTitle')}</P1>
                <Spacer space="epsilon" />
                <P3>{i18next.t('EditSubmissionPaymentForm.paymentIntervalText')}</P3>
                <Divider />
                <Grid columns={{ zero: 1, medium: 3 }}>
                    <StyledGridItem size={{ zero: 'full' }} verticalGutter={0}>
                        <Grid>
                            {paymentIntervalOptions.map((option, index) => (
                                <StyledGridItem key={`paymentInterval${option.value}`} shrink={1}>
                                    <Field
                                        id="paymentInterval"
                                        name={`paymentInterval`}
                                        validate={requiredFieldArrayCheckbox(
                                            i18next.t('EditSubmissionPaymentForm.paymentIntervalRequired')
                                        )}
                                    >
                                        {props => (
                                            <EmphasizedToggle
                                                controlType="radio"
                                                value={option.value}
                                                name={props.input.name}
                                                selectedValue={props.input.value}
                                                onChange={event => props.input.onChange(event.target.value)}
                                                errorText={index === 0 && props.meta.touched && props.meta.error}
                                            >
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: sanitizeDOM(option.label),
                                                    }}
                                                />
                                            </EmphasizedToggle>
                                        )}
                                    </Field>
                                </StyledGridItem>
                            ))}
                        </Grid>
                    </StyledGridItem>
                </Grid>
            </StyledBox>
        );
    };

    const renderPaymentRefund = () => {
        return (
            <StyledBox key="paymentRefund" borderRadius={8} shadow="ctaBlueHover">
                <P1>{i18next.t('EditSubmissionPaymentForm.paymentRefundTitle')}</P1>
                <Spacer space="epsilon" />
                <P3>{i18next.t('EditSubmissionPaymentForm.paymentRefundText')}</P3>
                <Divider />
                <Grid>
                    <StyledGridItem size={{ zero: 'full', medium: 8, large: 6 }}>
                        <Field
                            id="paymentRefund"
                            name={`paymentRefund`}
                            label={i18next.t('EditSubmissionPaymentForm.paymentRefundPlaceholder')}
                            validate={composeValidators(
                                required(i18next.t('EditSubmissionPaymentForm.paymentRefundRequired')),
                                isSeconAcceptedIban(i18next.t('EditSubmissionPaymentForm.paymentRefundIsSeconIban'))
                            )}
                        >
                            {props => (
                                <Input
                                    name={props.input.name}
                                    value={props.input.value}
                                    onChange={props.input.onChange}
                                    label={props.label}
                                    errorText={props.meta.touched && props.meta.error}
                                />
                            )}
                        </Field>
                    </StyledGridItem>
                </Grid>
            </StyledBox>
        );
    };

    return (
        <FinalForm
            {...props}
            mutators={mutators}
            decorators={[scrollToFirstErrorDecorator]}
            initialValues={initialValues}
            render={formRenderProps => {
                let {
                    form,
                    className,
                    disabled,
                    ready,
                    handleSubmit,
                    values,
                    invalid,
                    pristine,
                    saveActionMsg,
                    backActionMsg,
                    updated,
                    updateInProgress,
                    fetchErrors,
                } = formRenderProps;

                const classes = classNames('rootSubmissionPaymentForm', className);
                const submitReady = (updated && pristine) || ready;
                const submitInProgress = updateInProgress;
                const submitDisabled = disabled || submitInProgress;
                const { updateSubmissionError, createSubmissionOfferError, showSubmissionError } = fetchErrors || {};
                const persons = submission.relationships.persons;

                /**
                 * form options
                 * */
                // payment options
                const paymentMethodOptions = [
                    { value: 'ebill', label: i18next.t('EditSubmissionPaymentForm.formOptionsPaymentMethod.ebill') },
                    { value: 'lsv', label: i18next.t('EditSubmissionPaymentForm.formOptionsPaymentMethod.lsv') },
                    { value: 'debit', label: i18next.t('EditSubmissionPaymentForm.formOptionsPaymentMethod.debit') },
                    { value: 'bill', label: i18next.t('EditSubmissionPaymentForm.formOptionsPaymentMethod.bill') },
                    {
                        value: 'crypto',
                        label: i18next.t('EditSubmissionPaymentForm.formOptionsPaymentMethod.crypto'),
                    },
                ];

                return (
                    <Form className={classes} onSubmit={handleSubmit}>
                        {createSubmissionOfferError && (
                            <p>{i18next.t('EditSubmissionPaymentForm.createSubmissionOfferError')}</p>
                        )}

                        {updateSubmissionError && <p>{i18next.t('EditSubmissionPaymentForm.updateFailed')}</p>}

                        {showSubmissionError && <p>{i18next.t('EditSubmissionPaymentForm.showSubmissionFailed')}</p>}

                        <AutoSave
                            onSave={values => {
                                setAutosaved(submission.id.uuid, 'paymentForm', !invalid, values);
                            }}
                            isFormValid={!invalid}
                            debounce={300}
                        />

                        <OnChange name="paymentContactperson">
                            {(current, previous) => {
                                if (current !== previous) {
                                    setIsExternalPaymentContact(values.paymentContactperson === EXTERNAL_PERSON);
                                }
                            }}
                        </OnChange>

                        <Stack space="giga">
                            {renderContactPerson(persons, isExternalPaymentContact, form, values)}

                            <StyledBox key="paymentMethod" borderRadius={8} shadow="ctaBlueHover">
                                <P1>{i18next.t('EditSubmissionPaymentForm.paymentMethodTitle')}</P1>
                                <Spacer space="epsilon" />
                                <P3>{i18next.t('EditSubmissionPaymentForm.paymentMethodText')}</P3>
                                <Divider />
                                <Grid columns={{ zero: 1, medium: 3 }}>
                                    <StyledGridItem size={{ zero: 'full' }} verticalGutter={0}>
                                        <Grid>
                                            {paymentMethodOptions.map((option, index) => (
                                                <StyledGridItem key={`person${option.value}`} shrink={1}>
                                                    <Field
                                                        name={`paymentMethod`}
                                                        validate={requiredFieldArrayCheckbox(
                                                            i18next.t('EditSubmissionPaymentForm.paymentMethodRequired')
                                                        )}
                                                    >
                                                        {props => (
                                                            <EmphasizedToggle
                                                                controlType="radio"
                                                                value={option.value}
                                                                name={props.input.name}
                                                                selectedValue={props.input.value}
                                                                onChange={event =>
                                                                    props.input.onChange(event.target.value)
                                                                }
                                                                errorText={
                                                                    index === 0 &&
                                                                    props.meta.touched &&
                                                                    props.meta.error
                                                                }
                                                            >
                                                                {option.label}
                                                            </EmphasizedToggle>
                                                        )}
                                                    </Field>
                                                </StyledGridItem>
                                            ))}
                                        </Grid>
                                    </StyledGridItem>
                                </Grid>
                                <Spacer space="kilo" />
                                {values.paymentMethod && (
                                    <P3
                                        dangerouslySetInnerHTML={{
                                            __html: sanitizeDOM(
                                                i18next.t(
                                                    `EditSubmissionPaymentForm.paymentMethodHint.${values.paymentMethod}`
                                                )
                                            ),
                                        }}
                                    />
                                )}
                            </StyledBox>

                            {renderPaymentRefund()}

                            {renderPaymentInterval()}

                            <Spacer space="hecto" />

                            <EditSubmissionCTA
                                submitInProgress={submitInProgress}
                                submitDisabled={submitDisabled}
                                submitReady={submitReady}
                                currentSubmissionId={submission.id.uuid}
                                backActionMsg={backActionMsg}
                                saveActionMsg={saveActionMsg}
                            />
                        </Stack>
                    </Form>
                );
            }}
        />
    );
};

EditSubmissionPaymentForm.defaultProps = { className: null, fetchErrors: null, updateError: null };

EditSubmissionPaymentForm.propTypes = {
    className: string,
    onSubmit: func.isRequired,
    saveActionMsg: string.isRequired,
    backActionMsg: string.isRequired,
    disabled: bool.isRequired,
    ready: bool.isRequired,
    updated: bool.isRequired,
    updateError: propTypes.error,
    updateInProgress: bool.isRequired,
    fetchErrors: shape({
        createSubmissionOfferError: propTypes.error,
        showSubmissionError: propTypes.error,
        updateSubmissionError: propTypes.error,
    }),
};

export default EditSubmissionPaymentForm;
