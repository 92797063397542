// ================ Action types ================ //

export const UPDATE_DEFAULTS = 'app/UI/UPDATE_DEFAULTS';
export const INITIAL_DATA_LOADED = 'app/UI/INITIAL_DATA_LOADED';

// ================ Reducer ================ //

const initialState = {
    defaults: {
        okpEnabled: false,
        vvgEnabled: true,
    },
    initialDataLoaded: false,
};

export default function reducer(state = initialState, action = {}) {
    const { type, payload } = action;
    switch (type) {
        case UPDATE_DEFAULTS: {
            const defaults = getDefaults(payload, state);

            return {
                ...state,
                defaults,
            };
        }
        case INITIAL_DATA_LOADED: {
            return {
                ...state,
                initialDataLoaded: true,
            };
        }
        default:
            return state;
    }
}

export const getDefaults = (payload, state) => {
    let { defaults } = state;
    const { urlQueryString } = payload;
    const { okpEnabled, vvgEnabled } = urlQueryString;

    if (typeof okpEnabled !== 'undefined' || typeof vvgEnabled !== 'undefined') {
        defaults.okpEnabled = typeof okpEnabled !== 'undefined' ? okpEnabled === '1' : defaults.okpEnabled;
        defaults.vvgEnabled = typeof vvgEnabled !== 'undefined' ? vvgEnabled === '1' : defaults.vvgEnabled;
    }
    return defaults;
};

// ================ Action creators ================ //

export const manageUpdateDefaults = urlQueryString => ({
    type: UPDATE_DEFAULTS,
    payload: { urlQueryString },
});

export const setInitialDataLoaded = () => ({
    type: INITIAL_DATA_LOADED,
});
