import React from 'react';
import { useParams } from 'react-router-dom';
import EditSubmissionProductSubPanelDefault from './partials/EditSubmissionProductSubPanelDefault';
import EditSubmissionProductSubPanelRecommend from './partials/EditSubmissionProductSubPanelRecommend';

const EditSubmissionProductPanel = props => {
    const SUBPANELS = {
        default: EditSubmissionProductSubPanelDefault,
        recommend: EditSubmissionProductSubPanelRecommend,
    };
    const { subpanel } = useParams();
    const name = subpanel || 'default';
    const SubPanelToRender = SUBPANELS[name];

    return <SubPanelToRender {...props} />;
};

export default EditSubmissionProductPanel;
