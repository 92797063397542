import styled from '@emotion/styled';
import { rem } from 'polished';
import { ExternalLink } from '@src/components';
import { spaces, mixins, colors } from 'atupri-component-library/lib/core/styles/theme/default';
import { Icon } from 'atupri-component-library/lib/primitives';

export const StyledLinkIcon = styled(Icon)`
    font-size: ${rem(20)};
    margin-right: ${rem(10)};
`;

export const StyledButtonGroup = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    button,
    a {
        ${mixins.responsiveStyles({
            'margin-right': {
                zero: `${rem(spaces.delta)}`,
                medium: `${rem(spaces.epsilon)}`,
                wide: `${rem(spaces.epsilon)}`,
            },
            'margin-bottom': {
                zero: `${rem(spaces.delta)}`,
                medium: `${rem(spaces.epsilon)}`,
                wide: `${rem(spaces.epsilon)}`,
            },
        })}
    }

    a {
        color: ${colors.primary};
        text-decoration: none;
    }
`;

export const StyledExternalLink = styled(ExternalLink)`
    text-decoration: none;
    display: flex;
    align-items: center;
`;
