import React from 'react';
import { node, string } from 'prop-types';

import { StyledBox, StyledIcon } from './RebateTeaser.style';

const RebateTeaser = ({ iconName = 'piggy_bank', background = 'blue300', children, ...rest }) => (
    <StyledBox background={background} {...rest}>
        <StyledIcon iconName={iconName} />
        <div>{children}</div>
    </StyledBox>
);

RebateTeaser.propTypes = {
    iconName: string,
    background: string,
    children: node,
    className: string,
};

export default RebateTeaser;
