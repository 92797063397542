import styled from '@emotion/styled';
import { colors } from 'atupri-component-library/lib/core/styles/theme/default';

import { Button } from 'atupri-component-library/lib/primitives';

export const StyledFieldMultiple = styled.div`
    .paymentContactAdditionalPerson & {
        margin-bottom: 0;
    }
`;

export const StyledButton = styled(Button)(() => {
    return `
      color: ${colors.primary};

      &[disabled] {
        color: ${colors.grey600};
      }
    `;
});
