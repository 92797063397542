import createDecorator from 'final-form-calculate';
import { checkMivitaQuestionRule } from '@src/util/person';

export default function () {
    return createDecorator({
        field: 'persons', // when persons changes...
        updates: {
            mivitaQuestion: (value, allValues) => {
                /**
                 * check against mivitaQuestion rule
                 * if mivitaQuestion requirements are not met - reset field (null)
                 */
                if (checkMivitaQuestionRule(allValues.persons)) {
                    return allValues['mivitaQuestion'] || 'yes';
                } else {
                    return null;
                }
            },
        },
    });
}
