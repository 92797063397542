import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Components
import { NamedLink } from '@src/components';
import { Button } from 'atupri-component-library/lib/primitives';

// Styles
import { StyledCtaBar, StyledCtaBarCol } from './EditSubmissionCTA.style';

class EditSubmissionCTA extends Component {
    constructor(props) {
        super(props);
        this.state = { mounted: false };
    }

    componentDidMount() {
        this.setState({ mounted: true }); // eslint-disable-line react/no-did-mount-set-state
    }

    render() {
        const {
            submitInProgress,
            submitDisabled,
            submitOnClick,
            saveActionMsg,
            backActionMsg,
            currentSubmissionId,
            tab,
            backTracking,
        } = this.props;

        return (
            <StyledCtaBar>
                <StyledCtaBarCol>
                    {backActionMsg && currentSubmissionId && (
                        <NamedLink
                            name="EditSubmissionPage"
                            params={{
                                tab: tab,
                            }}
                        >
                            <Button
                                buttonText={backActionMsg}
                                outlined
                                variant="primary"
                                iconBefore="arrow_left"
                                tracking={backTracking}
                            />
                        </NamedLink>
                    )}
                </StyledCtaBarCol>
                {saveActionMsg && (
                    <StyledCtaBarCol>
                        <Button
                            className={`submitBtn`}
                            type="submit"
                            disabled={submitDisabled || submitInProgress}
                            onClick={submitOnClick}
                            buttonText={saveActionMsg}
                        />
                    </StyledCtaBarCol>
                )}
            </StyledCtaBar>
        );
    }
}

const { string, bool, func } = PropTypes;

EditSubmissionCTA.defaultProps = {
    rootClassName: null,
    className: null,
    submitInProgress: false,
    submitDisabled: false,
    submitOnClick: null,
    saveActionMsg: '',
    backActionMsg: '',
    tab: 'cockpit',
    backTracking: {},
};

EditSubmissionCTA.propTypes = {
    rootClassName: string,
    className: string,
    submitInProgress: bool,
    submitDisabled: bool,
    submitOnClick: func,
    saveActionMsg: string,
    backActionMsg: string,
    tab: string,
    backTracking: PropTypes.object,
};

export default EditSubmissionCTA;
