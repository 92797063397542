import styled from '@emotion/styled';
import { rem } from 'polished';
import { mixins } from 'atupri-component-library/lib/core/styles/theme/default';
import { H1, P1 } from 'atupri-component-library/lib/primitives';

export const StyledContainer = styled.div`
    ${mixins.responsiveStyles({
        'padding-top': { zero: rem(144), medium: rem(144) },
        'padding-bottom': { zero: rem(80), medium: rem(128) },
    })}
`;

export const StyledH1 = styled(H1)`
    padding-bottom: ${rem(32)};
`;

export const StyledP1 = styled(P1)`
    max-width: ${rem(880)};
    padding-bottom: ${rem(64)};
`;
