'use strict';

import { getIn } from 'final-form';

/**
 * Predicate to identify inputs that can have focus() called on them
 */
const isFocusableInput = function isFocusableInput(wtf) {
    return !!(wtf && (typeof wtf.focus === 'function' || wtf.dataset.focusable === 'true'));
};

/**
 * Gets all the inputs inside all forms on the page
 */
const getAllInputs = function getAllInputs() {
    if (typeof document === 'undefined') {
        return [];
    }

    return Array.prototype.slice.call(document.forms).reduce(function (accumulator, form) {
        return accumulator.concat(Array.prototype.slice.call(form).filter(isFocusableInput));
    }, []);
};

/**
 * Finds the input by looking if the name attribute path is existing in the errors object
 */
const findInput = function findInput(inputs, errors) {
    return inputs.find(function (input) {
        return input.name && getIn(errors, input.name);
    });
};

const noop = function noop() {
    // This is intentionally empty
};

const focusOnFirstError = function focusOnFirstError(errors, getInputs, findInput$$1) {
    if (!getInputs) {
        getInputs = getAllInputs;
    }

    if (!findInput$$1) {
        findInput$$1 = findInput;
    }

    const firstInput = findInput$$1(getInputs(), errors);
    if (firstInput) {
        if (firstInput.dataset.focusable === 'true') {
            // scrollIntoView apparently won't work on hidden element, so scroll on parent
            firstInput.parentNode.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            });
        } else {
            firstInput.focus({ preventScroll: true });
            firstInput.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            });
        }
    }
}; // Save original submit function

const createScrollToFirstErrorDecorator = function createDecorator(getInputs, findInput$$1) {
    return function (form) {
        const originalSubmit = form.submit; // Subscribe to errors, and keep a local copy of them

        let state = {};
        const unsubscribe = form.subscribe(
            function (nextState) {
                state = nextState;
            },
            {
                errors: true,
                submitErrors: true,
            }
        ); // What to do after submit

        const afterSubmit = function afterSubmit() {
            const _state = state,
                errors = _state.errors,
                submitErrors = _state.submitErrors;

            if (errors && Object.keys(errors).length) {
                focusOnFirstError(errors, getInputs, findInput$$1);
            } else if (submitErrors && Object.keys(submitErrors).length) {
                focusOnFirstError(submitErrors, getInputs, findInput$$1);
            }
        }; // Rewrite submit function

        form.submit = function () {
            const result = originalSubmit.call(form);

            if (result && typeof result.then === 'function') {
                // async
                result.then(afterSubmit, noop);
            } else {
                // sync
                afterSubmit();
            }

            return result;
        };

        return function () {
            unsubscribe();
            form.submit = originalSubmit;
        };
    };
};

/**
 * Generates a function to get all the inputs in a form with the specified name
 */
const getFormInputs = function getFormInputs(name) {
    return function () {
        if (typeof document === 'undefined') {
            return [];
        } // $FlowFixMe

        const form = document.forms[name];
        return form?.length ? Array.prototype.slice.call(form).filter(isFocusableInput) : []; // cast cheat to get from HTMLFormElement children to FocusableInput
    };
};

export default createScrollToFirstErrorDecorator;
export { getFormInputs };
