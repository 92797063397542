import i18next from 'i18next';

import { environmentConfiguration, environments } from '@src/config/env';
import { localizationConfiguration } from '@src/config/localization';
import { getSubmissionId } from './submission';

const { env } = environmentConfiguration;
const { hosts } = localizationConfiguration;

let protocol = 'https';

if (env === environments.local) {
    protocol = 'http';
}

const staticUrls = host => {
    return {
        apiSessionBase: `${protocol}://${host}/api`,
        apiEsbBase: `${protocol}://${host}/api-esb`,
        atupriPage: {
            de: `https://www.atupri.ch/de`,
            fr: `https://www.atupri.ch/fr`,
            it: `https://www.atupri.ch/it`,
        },
        infoPhone: {
            de: `tel:+41800200888`,
            fr: `tel:+41800200888`,
            it: `tel:+41800200888`,
        },
        infoMail: {
            de: `mailto:info@atupri.ch`,
            fr: `mailto:info@atupri.ch`,
            it: `mailto:info@atupri.ch`,
        },
        privacyPage: {
            de: 'https://www.atupri.ch/docs/datenschutzbestimmungen/',
            fr: 'https://www.atupri.ch/docs/fr/dispositions/',
            it: 'https://www.atupri.ch/docs/it/disposizioni/',
        },
        footerLegalPage: {
            de: `https://www.atupri.ch/de/rechtliches/`,
            fr: `https://www.atupri.ch/fr/mentions-legales/`,
            it: `https://www.atupri.ch/it/note-legali`,
        },
        footerImpressumPage: {
            de: `https://www.atupri.ch/de/impressum`,
            fr: `https://www.atupri.ch/fr/impressum`,
            it: `https://www.atupri.ch/it/impressum`,
        },
    };
};

export const getHost = (locale = i18next.language, host = window.location.host, _env = env) => {
    const match = hosts[_env].find(langHosts => Object.values(langHosts).includes(host));
    return match ? match[locale] : null;
};

export const setLanguageParameter = (language = i18next.language, href = window.location.href) => {
    const url = new URL(href);
    url.searchParams.set('lng', language);
    return url.href;
};

export const appendSubmissionIdParameter = (submissionId = getSubmissionId(), href = window.location.href) => {
    const url = new URL(href);
    if (submissionId) {
        url.searchParams.set('id', submissionId.uuid);
    }
    return url.href;
};

export const getLocalizedStaticUrl = (key, locale = i18next.language, _env = env) => {
    const host = getHost(locale, _env);
    const urls = staticUrls(host);
    return urls[key][locale];
};

export const getBackendApiUrl = key => {
    let host = window.location?.host;
    const urls = staticUrls(host);
    return urls[key]; // API Urls do not differ in language
};

export const localizeUrl = (locale, href = window.location.href) => {
    let url;

    if (href) {
        try {
            url = new URL(href);
            url.hostname = getHost(locale);
            url.port = '';
            url.searchParams.delete('lng');
            return url.href;
        } catch (e) {
            return href;
        }
    }

    return null;
};
