import styled from '@emotion/styled';
import { rem } from 'polished';
import { text, colors, mixins } from 'atupri-component-library/lib/core/styles/theme/default';
import { Container } from 'atupri-component-library/lib/helpers';

export const StyledPremiumTotal = styled.div`
    ${text.p4}

    user-select: none;
    color: ${colors.grey300};

    ${mixins.responsiveStyles({
        'background-color': { zero: colors.primary, medium: 'transparent' },
    })}
`;

export const StyledContainer = styled(Container)`
    ${mixins.responsiveStyles({
        height: { medium: 0 },
    })}
`;

export const StyledFlex = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: ${rem(12)} 0;

    ${mixins.responsiveStyles({
        'flex-direction': { zero: 'row', medium: 'column' },
        'align-items': { zero: 'center', medium: 'flex-start' },
        width: { medium: rem(224) },
        'margin-left': { medium: 'auto' },
        padding: {
            zero: rem(15) + ' 0 ' + rem(14),
            medium: rem(16) + ' ' + rem(16) + ' ' + rem(12),
        },
        'border-radius': { medium: '0 0 ' + rem(8) + ' ' + rem(8) },
        'background-color': { zero: 'transparent', medium: colors.primary },
    })}
`;

export const StyledLabel = styled.span`
    ${text.p4}

    padding-right: ${rem(14)};

    ${mixins.responsiveStyles({
        'margin-bottom': { medium: rem(6) },
    })}
`;

export const StyledAmount = styled.span`
    white-space: nowrap;

    ${mixins.responsiveStyles({
        'margin-left': { zero: 'auto', medium: '0' },
        'font-size': { zero: rem(14), medium: rem(20) },
        'line-height': { zero: '1.4', medium: '1.6' },
    })}
`;
