import styled from '@emotion/styled';
import { rem } from 'polished';
import { mixins } from 'atupri-component-library/lib/core/styles/theme/default';

export const StyledInputRoot = styled.div`
    ${mixins.responsiveStyles({
        'padding-bottom': {
            zero: `${rem(16)}`,
            medium: `${rem(24)}`,
        },
    })}

    label {
        display: inline-block;
        ${mixins.responsiveStyles({
            'margin-bottom': {
                zero: `${rem(16)}`,
                medium: `${rem(24)}`,
            },
        })}
    }

    .react-date-picker__calendar--open {
        top: 100% !important;
        bottom: unset !important;
        max-width: none !important;
    }
`;
