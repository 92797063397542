import styled from '@emotion/styled';
import { rem } from 'polished';

export const StyledLoader = styled.div(({ variant, show }) => {
    const variantDefaultStyles = `
  width: 100%;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  visibility: ${show ? 'visible' : 'hidden'};
  transition: ${show ? 'visibility 0s linear' : 'visibility 0s linear 0.5s'};
  `;

    const variantOverlayStyles = `
    z-index: 100;
    top: 0;
    left: 0;
    position: fixed;
    display: flex;
    height: 100vh;
    width: 100vw;
    background-color: ${show ? 'rgba(0, 0, 0, 0.3)' : 'transparent'};
    visibility: ${show ? 'visible' : 'hidden'};
    transition: ${
        show
            ? 'visibility 0s linear, background-color 0.3s ease'
            : 'visibility 0s linear 0.5s, background-color 0.3s ease 0.5s'
    };
  `;

    let styles = [];

    if (variant === 'overlay') {
        styles.push(variantOverlayStyles);
    } else {
        styles.push(variantDefaultStyles);
    }

    return styles;
});

export const StyledLoaderAnimation = styled.div`
    width: ${rem(135)};
    margin: auto;
`;

export const StyledLoaderIndicator = styled.div(({ delay }) => {
    let styles = [
        `
    background-color: rgb(228,231,245);
    float: left;
    height: ${rem(29)};
    margin-left: ${rem(15)};
    width: ${rem(29)};
    animation-name: anim_atupri_circle;
    animation-duration: 1.95s;
    animation-iteration-count:infinite;
    animation-direction:normal;
    border-radius: ${rem(19)};

    @keyframes anim_atupri_circle{
      0%{}

      50%{
        background-color:rgb(50,74,181);
      }

      100%{}
    }
  `,
    ];

    if (delay) {
        styles.push(`
      animation-delay: ${delay}s;
    `);
    }

    return styles;
});
