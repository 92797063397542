import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import i18next from 'i18next';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';

// components
import { EditSubmissionWizard, Main, NamedRedirect, Page, SaveSession } from '@src/components';
import { PRODUCT, RECOMMEND } from '@src/components/EditSubmissionWizard/EditSubmissionWizardTab';
import { Footer, Header, MessageBox } from 'atupri-component-library/lib/components';

// data
import { headerData, footerData } from '@src/data';

// ducks
import { getPremcalcEntities } from '@src/ducks/premcalcData.duck';
import {
    requestPublishSubmissionOffer,
    requestExtendSubmission,
    requestUpdateSubmission,
    requestClearErrors,
    loadData,
    clearUpdatedTab,
    requestBasketUpdate,
} from '@src/ducks/submission.duck';
import { requestFetchTariff } from '@src/ducks/apiData.duck';
import { ERROR_CATEGORY_SUBMISSION } from '@src/ducks/errorQueue.duck';

// hooks
import { useErrorQueue } from '@src/hooks/errorQueue';

// util
import { getExpiredSessionErrorTrackingData } from '@src/util/analytics';
import { ensureOwnSubmission } from '@src/util/data';
import { getSubmissionId, clearSubmissionId, getPerson } from '@src/util/submission';
import { isInternalProcess } from '@src/util/process';
import { SUBMISSION_STATE_OFFER } from '@src/util/types';
import { isMobileApp } from '@src/util/mobileAppDetection';

// style
import { StyledContainer, StyledMainError } from './EditSubmissionPage.style';

export const EditSubmissionPage = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const navigate = useNavigate();
    const sendDataToGTM = useGTMDispatch();
    const { addError } = useErrorQueue();

    const submissionId = getSubmissionId();

    const { page, healthQuestionsPage, productBasketPage, apiData, initialDataIsLoaded, fetchInProgress, submission } =
        useSelector(state => {
            let submission = null;
            if (typeof submissionId !== 'undefined') {
                const submissions = getPremcalcEntities(state, [{ id: submissionId, type: 'ownSubmission' }]);
                submission = submissions.length === 1 ? ensureOwnSubmission(submissions[0]) : null;
            }

            return {
                page: state.EditSubmissionPage,
                healthQuestionsPage: state.HealthQuestionsPage,
                productBasketPage: state.ProductBasketPage,
                apiData: state.apiData,
                initialDataIsLoaded: state.UI.initialDataLoaded,
                fetchInProgress:
                    state.apiData?.fetchInProgress ||
                    state.apiData?.fetchTariffInProgress ||
                    state.apiData?.fetchCurrentProductsInProgress ||
                    false,
                submission,
            };
        });

    const { state: currentSubmissionState } = submission?.attributes || {};
    const { persons } = submission?.relationships || {};

    const firstPerson = Array.isArray(persons) && persons[0];
    const personId = params.personId || firstPerson?.id;

    const getTariffs = (submission, personId) => {
        const tariffs = {
            okp: apiData.okpTariffs,
            vvg: apiData.vvgTariffs,
            uti: apiData.utiTariffs,
        };

        const person = personId && getPerson(submission, personId);

        if (apiData.currentProducts && person.insuranceNumber) {
            tariffs.currentProducts = apiData.currentProducts.find(
                product => product.insuredNr === person.insuranceNumber
            )?.products;
        }

        return tariffs;
    };
    const tariffs = getTariffs(submission, personId);

    const isPastOffer = currentSubmissionState && currentSubmissionState !== SUBMISSION_STATE_OFFER;
    const showConfirmation = submissionId && isPastOffer;

    const hasAtupriOnboardingDataIfNeeded = true;

    const {
        createSubmissionOfferError = null,
        publishSubmissionError = null,
        updateSubmissionError = null,
        showSubmissionError = null,
    } = page;

    const { blockInterface } = apiData;

    const errors = {
        createSubmissionOfferError,
        publishSubmissionError,
        updateSubmissionError,
        showSubmissionError,
    };
    const newSubmissionPublished = submission && currentSubmissionState !== SUBMISSION_STATE_OFFER;

    const getErrorText = () => {
        let key = 'Error.default';
        let code = '';
        let text = '';

        if (healthQuestionsPage.showSubmissionError) {
            code = healthQuestionsPage.showSubmissionError.code;

            if (healthQuestionsPage.showSubmissionError.data) {
                key = healthQuestionsPage.showSubmissionError.data;
            }
        } else if (healthQuestionsPage.showHealthQuestionsError) {
            code = healthQuestionsPage.showHealthQuestionsError.code;

            if (healthQuestionsPage.showHealthQuestionsError.data) {
                key = healthQuestionsPage.showHealthQuestionsError.data;
            }
        } else if (healthQuestionsPage.updateSubmissionError) {
            code = healthQuestionsPage.updateSubmissionError.code;

            if (healthQuestionsPage.updateSubmissionError.data) {
                key = healthQuestionsPage.updateSubmissionError.data;
            }
        } else if (productBasketPage.showSubmissionError) {
            code = productBasketPage.showSubmissionError.code;

            if (productBasketPage.showSubmissionError.data) {
                key = productBasketPage.showSubmissionError.data;
            }
        } else {
            key = false;
        }

        if (key) {
            text = i18next.t(key, { code: code });
        }

        return { text: text };
    };

    const getUpdateError = () => {
        let key = 'Error.default';
        let code = '';
        let text = null;

        if (updateSubmissionError) {
            code = updateSubmissionError.code;

            if (updateSubmissionError.data) {
                key = updateSubmissionError.data;
            }
        } else if (publishSubmissionError) {
            code = publishSubmissionError.code;

            if (publishSubmissionError.data) {
                key = publishSubmissionError.data;
            }
        } else {
            key = false;
        }

        if (key) {
            text = i18next.t(key, { code: code });
        }

        return text;
    };

    const isDatasetComplete = () => {
        return (
            !page.updateInProgress &&
            !page.createSubmissionOfferInProgress &&
            submission &&
            !fetchInProgress &&
            hasAtupriOnboardingDataIfNeeded &&
            !!submission.id &&
            !!submission.id.uuid
        );
    };

    const showSaveSession = () => {
        return (
            !(params.tab === PRODUCT && params.subpanel === RECOMMEND) &&
            !isInternalProcess(persons, apiData?.currentProducts)
        );
    };

    const render = () => {
        const disableForm = page.redirectToSubmission && !showSubmissionError;
        const datasetComplete = isDatasetComplete();

        const errorText = getErrorText();

        // redirect non existing id to start page
        if (showSubmissionError || !submissionId || (initialDataIsLoaded && !submission.id)) {
            dispatch(requestClearErrors());
            clearSubmissionId();
            sendDataToGTM(getExpiredSessionErrorTrackingData());

            addError(
                i18next.t('Error.expired', 'The session link has expired. Please restart the process'),
                null,
                ERROR_CATEGORY_SUBMISSION
            );

            // use react router navigate and return null to prevent unnecessary rerenderings
            navigate('/');
            return null;
        }

        const updateError = getUpdateError();

        if (updateError) {
            dispatch(requestClearErrors());
            clearSubmissionId();
            addError(updateError, null, ERROR_CATEGORY_SUBMISSION);
            navigate('/');
            return null;
        }

        return (
            <Page>
                <SaveSession
                    submission={submission}
                    onExtendSubmission={data => dispatch(requestExtendSubmission(data))}
                >
                    {openModal => {
                        return (
                            !isMobileApp() && (
                                <Header
                                    {...headerData()}
                                    sessionButton={
                                        showSaveSession() && {
                                            buttonText: i18next.t('SaveSession.header.buttonSave'),
                                            onClick: openModal,
                                            variant: 'primary',
                                            outlined: true,
                                            iconBefore: 'clock',
                                        }
                                    }
                                />
                            )
                        );
                    }}
                </SaveSession>
                <Main
                    complete={datasetComplete}
                    blockInterface={blockInterface}
                    hasError={!!(updateSubmissionError || publishSubmissionError)}
                >
                    {datasetComplete && !updateSubmissionError && !publishSubmissionError ? (
                        <StyledContainer>
                            <EditSubmissionWizard
                                id="EditSubmissionWizard"
                                className={'wizard'}
                                disabled={disableForm}
                                errors={errors}
                                fetchInProgress={fetchInProgress}
                                newSubmissionPublished={newSubmissionPublished}
                                submission={submission}
                                onUpdateSubmission={(tab, values) => dispatch(requestUpdateSubmission(tab, values))}
                                onPublishSubmissionOffer={(submissionId, values) =>
                                    dispatch(requestPublishSubmissionOffer(submissionId, values))
                                }
                                onChange={() => dispatch(clearUpdatedTab())}
                                onProductSelectionChange={(submissionId, personId, values) =>
                                    dispatch(requestBasketUpdate(submissionId, personId, values))
                                }
                                onProductTypeChange={payload => dispatch(requestFetchTariff(payload))}
                                updatedTab={page.updatedTab}
                                updateInProgress={page.updateInProgress || page.createSubmissionOfferInProgress}
                                tariffs={tariffs}
                                effectiveDates={apiData?.effectiveDates || []}
                                currentProducts={apiData?.currentProducts || []}
                                params={params} // remove after migrating wizard to functional comp
                            />
                        </StyledContainer>
                    ) : null}
                </Main>

                {/* Show Healthquestion errors when redirected back from HQ */}
                {healthQuestionsPage.showSubmissionError ||
                healthQuestionsPage.showHealthQuestionsError ||
                healthQuestionsPage.updateSubmissionError ||
                productBasketPage.showSubmissionError ? (
                    <StyledMainError>
                        <MessageBox {...errorText} variant="warning" />
                    </StyledMainError>
                ) : null}

                {!isMobileApp() && <Footer {...footerData()} />}
            </Page>
        );
    };

    if (showConfirmation) {
        const redirectProps = {
            name: 'ConfirmationPage',
        };

        return <NamedRedirect {...redirectProps} />;
    } else {
        return render();
    }
};

EditSubmissionPage.loadData = loadData;

export default EditSubmissionPage;
