import React from 'react';
import i18next from 'i18next';
import { formatAmount } from '@src/util/currency';
import { calcTotalPrice } from '@src/util/basket';

import { StyledPremiumTotal, StyledContainer, StyledFlex, StyledLabel, StyledAmount } from './PremiumTotal.style';

const PremiumTotal = ({ hide, basket }) =>
    !hide && (
        <StyledPremiumTotal>
            <StyledContainer>
                <StyledFlex>
                    <StyledLabel>{i18next.t('premiumtotal.label')}</StyledLabel>
                    <StyledAmount>{formatAmount(calcTotalPrice(basket))}</StyledAmount>
                </StyledFlex>
            </StyledContainer>
        </StyledPremiumTotal>
    );

export default PremiumTotal;
