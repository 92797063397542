const personTemplate = {
    id: null,
    firstname: null,
    lastname: null,
    gender: 'm',
    birthdate: null,
    zip: null,
    place: null,
    municipalityId: null,
    municipalityInfo: null,
    insuranceStart: null,
    street: null,
    addressExtension: null,
    email: null,
    phonePrivate: null,
    previousInsurer: null,
    nationality: '',
    okpEnabled: false,
    vvgEnabled: true,
    dateOfEntry: '',
    permitType: '',
    moved: 'no',
    practices: {},
};

export { personTemplate };
