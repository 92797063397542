import React from 'react';
import { Field } from 'react-final-form';

export const FormSpyFieldValues = ({ children, fieldNames }) => {
    return fieldNames.reduce(
        // eslint-disable-next-line react/display-name
        (acc, fieldName) => values => (
            <Field name={fieldName} subscription={{ value: true }}>
                {({ input: { value } }) => acc({ ...values, [fieldName]: value })}
            </Field>
        ),
        children
    )({});
};
