import React, { Component } from 'react';
import { array, bool, func, object, shape, string } from 'prop-types';
import classNames from 'classnames';
import i18next from 'i18next';

// components
import { NamedRedirect, Tabs } from '@src/components';

// util
import { ensureSubmission } from '@src/util/data';
import { pathByRouteName } from '@src/util/routes';
import { isPersonDatasetComplete, EXTERNAL_PERSON } from '@src/util/person';
import { isInternalProcess } from '@src/util/process';

// tab
import EditSubmissionWizardTab, { PRODUCT, COCKPIT, PAYMENT, CONFIRMATION } from './EditSubmissionWizardTab';

// reorder these panels
export const TABS = [PRODUCT, COCKPIT, PAYMENT, CONFIRMATION];

const checkForValidPerson = (submission, currentProducts) => {
    let cockpitReadyFlag = true;

    submission.relationships.persons.map(person => {
        if (!isPersonDatasetComplete(person.id, submission, currentProducts)) {
            cockpitReadyFlag = false;
        }
    });

    return cockpitReadyFlag;
};

const tabLabel = tab => {
    let key = null;
    if (tab === PRODUCT) {
        key = 'EditSubmissionWizard.tabLabelProduct';
    } else if (tab === COCKPIT) {
        key = 'EditSubmissionWizard.tabLabelDescription';
    } else if (tab === PAYMENT) {
        key = 'EditSubmissionWizard.toLabelPayment';
    } else if (tab === CONFIRMATION) {
        key = 'EditSubmissionWizard.tabLabelConfirmation';
    }

    return i18next.t(key);
};

// get true if step is complete
const tabCompleted = (tab, submission, currentProducts) => {
    const {
        paymentContactperson,
        paymentContactAdditionalPersonGender,
        paymentContactAdditionalPersonFirstname,
        paymentContactAdditionalPersonLastname,
        paymentContactAdditionalPersonBirthdate,
        paymentContactAdditionalPersonEmail,
        paymentContactAdditionalPersonPhone,
        paymentContactAdditionalPersonStreet,
        paymentContactAdditionalPersonZip,
        paymentContactAdditionalPersonLocation,
        paymentStreet,
        paymentZip,
        paymentLocation,
        paymentMethod,
        paymentRefund,
        paymentInterval,
    } = submission.attributes.privateData;
    const isInternal = isInternalProcess(submission.relationships.persons, currentProducts);

    switch (tab) {
        case PRODUCT:
            return true;
        case COCKPIT:
            // if the user comes from myAtupri we skip the validation of the person info.
            // Siddharta ignores those values anyway if an insured number is submitted
            return isInternal ? true : checkForValidPerson(submission, currentProducts);
        case PAYMENT:
            // if the user comes from myAtupri we skip the validation of the payment info.
            // Siddharta ignores those values anyway if an insured number is submitted
            if (isInternal) {
                return true;
            }
            if (checkForValidPerson(submission, currentProducts)) {
                if (paymentContactperson === EXTERNAL_PERSON) {
                    return !!(
                        paymentContactperson &&
                        paymentContactAdditionalPersonGender &&
                        paymentContactAdditionalPersonFirstname &&
                        paymentContactAdditionalPersonLastname &&
                        paymentContactAdditionalPersonBirthdate &&
                        paymentContactAdditionalPersonEmail &&
                        paymentContactAdditionalPersonPhone &&
                        paymentContactAdditionalPersonStreet &&
                        paymentContactAdditionalPersonZip &&
                        paymentContactAdditionalPersonLocation &&
                        paymentMethod &&
                        paymentRefund &&
                        paymentInterval
                    );
                } else {
                    return !!(
                        paymentContactperson &&
                        paymentStreet &&
                        paymentZip &&
                        paymentLocation &&
                        paymentMethod &&
                        paymentRefund &&
                        paymentInterval
                    );
                }
            }

            return false;
        case CONFIRMATION:
            return false;
        default:
            return false;
    }
};

// Tab is active if previous tab is complete
const tabsActive = (submission, currentProducts) => {
    return TABS.reduce((acc, tab) => ({ ...acc, [tab]: tabCompleted(tab, submission, currentProducts) }), {});
};

// create a new submission or edit submission
class EditSubmissionWizard extends Component {
    constructor(props) {
        super(props);

        this.handlePublishSubmission = this.handlePublishSubmission.bind(this);

        this.state = {
            offerId: null,
            portalRoot: null,
        };
    }

    handlePublishSubmission(id, data) {
        const { onPublishSubmissionOffer } = this.props;

        const requirementsMissing = false; // ToDo

        if (!requirementsMissing) {
            onPublishSubmissionOffer(id, data);
        } else {
            this.setState({
                offerId: id,
                showPayoutDetails: true,
            });
        }
    }

    render() {
        const {
            id,
            className,
            rootClassName,
            params,
            submission,
            viewport,
            errors,
            fetchInProgress,
            onProductSelectionChange,
            onProductTypeChange,
            tariffs,
            effectiveDates,
            currentProducts,
            ...rest
        } = this.props;

        const selectedTab = params.tab;

        const rootClasses = rootClassName || 'wizard__root';
        const classes = classNames(rootClasses, className);
        const currentSubmission = ensureSubmission(submission);
        const tabsStatus = tabsActive(currentSubmission, currentProducts);

        // If selectedTab is not active redirect to start tab
        if (!tabsStatus[selectedTab]) {
            const currentTabIndex = TABS.indexOf(selectedTab);

            // check if previous tab is validated
            if (!tabsStatus[TABS[currentTabIndex - 1]]) {
                const nextActiveTab = TABS.slice(0, currentTabIndex)
                    .reverse()
                    .find(t => tabsStatus[t]);

                return <NamedRedirect name="EditSubmissionPage" params={{ ...params, tab: nextActiveTab }} />;
            }
        }

        const tabLink = tab => {
            return { to: pathByRouteName('EditSubmissionPage', { ...params, tab }) };
        };

        const setPortalRootAfterInitialRender = () => {
            if (!this.state.portalRoot) {
                this.setState({ portalRoot: document.getElementById('portal-root') });
            }
        };

        return (
            <div className={classes} ref={setPortalRootAfterInitialRender}>
                <Tabs
                    rootClassName={'tabs__container'}
                    navRootClassName={'tabs__nav'}
                    tabRootClassName={'tabs__tab'}
                    submission={currentSubmission}
                    selectedTab={selectedTab}
                    tariffs={tariffs}
                >
                    {TABS.map(tab => {
                        return (
                            <EditSubmissionWizardTab
                                {...rest}
                                key={tab}
                                tabId={`${id}_${tab}`}
                                tabLabel={tabLabel(tab)}
                                tabLinkProps={tabLink(tab)}
                                selected={selectedTab === tab}
                                disabled={!tabsStatus[tab]}
                                tab={tab}
                                params={params}
                                submission={submission}
                                premcalcTabs={TABS}
                                errors={errors}
                                handlePublishSubmission={this.handlePublishSubmission}
                                fetchInProgress={fetchInProgress}
                                onProductSelectionChange={onProductSelectionChange}
                                onProductTypeChange={onProductTypeChange}
                                tariffs={tariffs}
                                effectiveDates={effectiveDates}
                                currentProducts={currentProducts}
                            />
                        );
                    })}
                </Tabs>
            </div>
        );
    }
}

EditSubmissionWizard.defaultProps = {
    className: null,
    rootClassName: null,
    submission: null,
    updateInProgress: false,
};

EditSubmissionWizard.propTypes = {
    id: string.isRequired,
    className: string,
    rootClassName: string,

    currentProducts: array,
    errors: shape({
        createSubmissionOfferError: object,
        updateSubmissionError: object,
        publishSubmissionError: object,
        showSubmissionError: object,
    }).isRequired,
    effectiveDates: array,
    fetchInProgress: bool.isRequired,
    onProductSelectionChange: func.isRequired,
    onProductTypeChange: func.isRequired,
    onPublishSubmissionOffer: func.isRequired,
    params: object.isRequired,
    // We cannot use propTypes.submission since the submission might be an offer.
    submission: shape({
        attributes: shape({
            privateData: object,
        }),
    }),
    tariffs: object,
    updateInProgress: bool,
    viewport: string.isRequired,
};

export default EditSubmissionWizard;
