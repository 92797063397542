import React, { useState } from 'react';
import i18next from 'i18next';
import { Field } from 'react-final-form';
import { useSelector } from 'react-redux';
import config from '@src/config';

// components
import { H2, H3, P1 } from 'atupri-component-library/lib/primitives';
import { Spacer } from 'atupri-component-library/lib/helpers';

// style
import { StyledBox } from '@src/forms/ProductForm/ProductForm.style';
import renderAdditionalProductGroup from './RenderAdditionalProductGroup';
import {
    findProductsOfProductGroups,
    PRODUCT_CODE_MIVITA,
    PRODUCT_CODE_OKP,
    PRODUCT_CODE_SPITAL,
    PRODUCT_CODE_SPITAL_DIVERSA,
    PRODUCT_CODE_SPITAL_EXTRA,
    PRODUCT_CODE_SPITAL_HOTEL,
    PRODUCT_CODE_SPITAL_KOMBI,
    PRODUCT_CODE_UTI,
    PRODUCT_GROUP_IDENTIFIER_UTI,
    PRODUCT_IDENTIFIER_BASIC,
    PRODUCT_IDENTIFIER_SPITAL_ALLGEMEIN,
} from '@src/util/tariff/vvg';
import { StyledInnerBox } from '../ProductForm.style';

export const MyAtupriFlowVvgAdditionalProductForm = props => {
    const { form, values, currentPerson, submission, onProductImpressionChange, isInternal } = props;
    const currentProducts = values?.apiData?.currentProducts;
    const availableVvgTariffs = useSelector(state => state.apiData?.vvgTariffs);
    const [showProductSelectionInfo, setShowProductSelectionInfo] = useState(false);

    // with this template we control which products will be displayed
    const additionalProductGroupsTemplate = [
        {
            id: 2,
            recommend: false,
            sorting: 1,
            standalone: true,
            value: 'Mivita',
        },
        {
            id: 6,
            recommend: false,
            sorting: 4,
            standalone: true,
            value: 'Spital',
        },
        {
            id: 5,
            recommend: false,
            sorting: 5,
            standalone: true,
            value: 'UTI',
        },
    ];

    // This function represents the display logic for the products based on this requirement:
    // https://atupri.atlassian.net/wiki/spaces/MVP/pages/20189937698/Anforderungsanalyse+zu+ATP-2989#Regelwerk-f%C3%BCr-VVG-Produktpush-im-OKP-Szenario
    const generateAdditionalProductGroups = () => {
        const additionalProductGroups = [];
        const closedProducts = [
            PRODUCT_CODE_SPITAL_KOMBI,
            PRODUCT_CODE_SPITAL_EXTRA,
            PRODUCT_CODE_SPITAL_DIVERSA,
            PRODUCT_CODE_SPITAL_HOTEL,
        ];

        const hasVvg = currentProducts.find(product => product.code !== PRODUCT_CODE_OKP);
        const hasMivita = currentProducts.find(product => product.code === PRODUCT_CODE_MIVITA);
        const hasSpital = currentProducts.find(product => product.code === PRODUCT_CODE_SPITAL);
        const hasUti = currentProducts.find(product => product.code === PRODUCT_CODE_UTI);
        const hasClosedProduct = currentProducts.some(product => closedProducts.includes(product.code));

        if (!hasVvg) {
            // user does not have any VVG. We return Mivita Basic, Spital AA and UTI
            return additionalProductGroupsTemplate;
        } else if (hasMivita && hasUti) {
            //with and without Comforta
            // user does have Mivita with UTI. We do not return any product
            return additionalProductGroups;
        } else if (hasMivita) {
            //with and without Comforta
            // user does have Mivita. We return UTI
            additionalProductGroups.push(additionalProductGroupsTemplate[2]);
            return additionalProductGroups;
        } else if (hasSpital && hasUti) {
            // user does have Spital and UTI. We do not return any product
            return additionalProductGroups;
        } else if (hasSpital) {
            // user does have Spital. We return UTI
            additionalProductGroups.push(additionalProductGroupsTemplate[2]);
            return additionalProductGroups;
        } else if (hasUti && !hasClosedProduct) {
            // user does have UTI, but no closed product. We return Spital and Mivita Basic
            additionalProductGroups.push(additionalProductGroupsTemplate[1]);
            additionalProductGroups.push(additionalProductGroupsTemplate[0]);
            return additionalProductGroups;
        } else if (hasClosedProduct && !hasUti) {
            // user does have a closed product and no UTI. We return UTI
            additionalProductGroups.push(additionalProductGroupsTemplate[2]);
            return additionalProductGroups;
        } else {
            return additionalProductGroups;
        }
    };

    const generatedAdditionalProductGroups = generateAdditionalProductGroups();

    if (values && generatedAdditionalProductGroups.length > 0) {
        // certain products are not available for all people (e.g. age). Here we check if any additional product is available.
        const productPrefixes = [PRODUCT_IDENTIFIER_BASIC, PRODUCT_IDENTIFIER_SPITAL_ALLGEMEIN, PRODUCT_CODE_UTI];
        const isVvgProductAvailable =
            availableVvgTariffs.products.length > 0 &&
            availableVvgTariffs.products.some(product => productPrefixes.some(prefix => product.id.startsWith(prefix)));

        // if only UTI is displayed, we show a different text and subtitle as in the other cases
        const isOnlyUtiDisplayed =
            generatedAdditionalProductGroups.length === 1 &&
            generatedAdditionalProductGroups[0].id === PRODUCT_GROUP_IDENTIFIER_UTI;

        return (
            isVvgProductAvailable && (
                <StyledBox borderRadius={8} shadow="ctaBlueHover">
                    <StyledInnerBox>
                        <H2>
                            {isOnlyUtiDisplayed
                                ? i18next.t('myAtupriFlowVvgAdditionalProductForm.titleOnlyUti')
                                : i18next.t('myAtupriFlowVvgAdditionalProductForm.title')}
                        </H2>
                        {isOnlyUtiDisplayed ? (
                            <P1>{i18next.t('myAtupriFlowVvgAdditionalProductForm.subtitleOnlyUti')}</P1>
                        ) : null}
                        <Spacer space="epsilon" />
                        {generatedAdditionalProductGroups.map((productGroup, index) => {
                            const products = findProductsOfProductGroups(values?.apiData?.vvg?.products, [
                                productGroup.id,
                            ]);

                            // for some products (e.g. Spital), there could be other variants (Privat, Halbprivat), which we don't
                            // display in the myAtupri flow. So we check for the specific product variant.
                            const isSpecificProductAvailable = products.some(product =>
                                productPrefixes.some(prefix => product.id.startsWith(prefix))
                            );

                            return (
                                isSpecificProductAvailable &&
                                renderAdditionalProductGroup(
                                    productGroup,
                                    products,
                                    values?.apiData?.vvg,
                                    values?.vvg,
                                    currentPerson,
                                    submission,
                                    form,
                                    onProductImpressionChange,
                                    setShowProductSelectionInfo,
                                    isInternal,
                                    index === 0,
                                    showProductSelectionInfo
                                )
                            );
                        })}
                    </StyledInnerBox>
                    {config.env !== 'prod' && (
                        <StyledBox
                            display={['test', 'int', 'int2'].includes(config.env) ? 'none' : 'block'}
                            shadow="ctaBlueHover"
                        >
                            <H3>myAtupri-Flow - Ausgewählte VVG-Tariffe:</H3>
                            <Field name={'tariff.vvg'}>
                                {fieldProps => (
                                    <pre style={{ overflowX: 'scroll' }}>
                                        {JSON.stringify(fieldProps.input.value, 0, 2)}
                                    </pre>
                                )}
                            </Field>
                        </StyledBox>
                    )}
                </StyledBox>
            )
        );
    }
};

export default MyAtupriFlowVvgAdditionalProductForm;
