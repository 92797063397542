// api
import { API_GET_HEALTHQUESTIONS } from '@src/api/healthQuestions.api';
import { API_UPDATE_SUBMISSION } from '@src/api/submission.api';

// duck
import { markTabUpdated, requestShowSubmission } from '@src/ducks/submission.duck';
import { addAppEntities } from '@src/ducks/premcalcData.duck';

// util
import { needsToAnswerHealthQuestions } from '@src/util/person';
import { getPerson, getSubmissionId } from '@src/util/submission';
import log from '@src/middleware/loglevel';

const requestAction = actionType => params => ({ type: actionType, payload: { params } });

const successAction = actionType => result => ({ type: actionType, payload: result.data });

const errorAction = actionType => error => ({ type: actionType, payload: error, error: true });

// ================ Action types ================ //

export const UPDATE_SUBMISSION_REQUEST = 'app/HealthQuestionsPage/UPDATE_SUBMISSION_REQUEST';
export const UPDATE_SUBMISSION_SUCCESS = 'app/HealthQuestionsPage/UPDATE_SUBMISSION_SUCCESS';
export const UPDATE_SUBMISSION_ERROR = 'app/HealthQuestionsPage/UPDATE_SUBMISSION_ERROR';

export const UPDATE_HEALTHQUESTIONS_REQUEST = 'app/HealthQuestionsPage/UPDATE_HEALTHQUESTIONS_REQUEST';
export const UPDATE_HEALTHQUESTIONS_SUCCESS = 'app/HealthQuestionsPage/UPDATE_HEALTHQUESTIONS_SUCCESS';
export const UPDATE_HEALTHQUESTIONS_ERROR = 'app/HealthQuestionsPage/UPDATE_HEALTHQUESTIONS_ERROR';

export const SHOW_HEALTHQUESTIONS_REQUEST = 'app/HealthQuestionsPage/SHOW_HEALTHQUESTIONS_REQUEST';
export const SHOW_HEALTHQUESTIONS_SUCCESS = 'app/HealthQuestionsPage/SHOW_HEALTHQUESTIONS_SUCCESS';
export const SHOW_HEALTHQUESTIONS_ERROR = 'app/HealthQuestionsPage/SHOW_HEALTHQUESTIONS_ERROR';

export const CLEAR_REDIRECT = 'app/HealthQuestionsPage/CLEAR_REDIRECT';

// ================ Reducer ================ //

const initialState = {
    updateSubmissionError: null,
    showHealthQuestionsError: null,
    createSubmissionOfferInProgress: false,
    currentSubmissionId: null,
    updateInProgress: false,
    healthQuestionsReady: false,
    updateSubmissionSuccess: false,
    healthQuestions: null,
};

export default function reducer(state = initialState, action = {}) {
    const { type, payload } = action;
    switch (type) {
        case UPDATE_SUBMISSION_REQUEST:
            return { ...state, updateInProgress: true, updateSubmissionError: null, updateSubmissionSuccess: null };
        case UPDATE_SUBMISSION_SUCCESS:
            return { ...state, updateInProgress: false, updateSubmissionSuccess: true };
        case UPDATE_SUBMISSION_ERROR:
            return { ...state, updateInProgress: false, updateSubmissionError: payload, updateSubmissionSuccess: null };

        case SHOW_HEALTHQUESTIONS_REQUEST:
            return { ...state, showHealthQuestionsError: null, healthQuestionsReady: false, healthQuestions: null };
        case SHOW_HEALTHQUESTIONS_SUCCESS:
            return {
                ...state,
                healthQuestions: payload.questions,
                healthQuestionsReady: true,
                currentSubmissionId: payload.id,
            };
        case SHOW_HEALTHQUESTIONS_ERROR:
            return { ...state, showHealthQuestionsError: payload, redirectToSubmission: false };

        case CLEAR_REDIRECT:
            return { ...state, updateSubmissionSuccess: null };
        default:
            return state;
    }
}

// ================ Action creators ================ //

export const showHealthQuestions = requestAction(SHOW_HEALTHQUESTIONS_REQUEST);
export const showHealthQuestionsSuccess = successAction(SHOW_HEALTHQUESTIONS_SUCCESS);
export const showHealthQuestionsError = errorAction(SHOW_HEALTHQUESTIONS_ERROR);

export const updateSubmission = requestAction(UPDATE_SUBMISSION_REQUEST);
export const updateSubmissionSuccess = successAction(UPDATE_SUBMISSION_SUCCESS);
export const updateSubmissionError = errorAction(UPDATE_SUBMISSION_ERROR);

export const clearRedirect = () => ({
    type: CLEAR_REDIRECT,
});

// ================ Thunk ================ //

export function requestShowHealthQuestions(actionPayload) {
    return dispatch => {
        dispatch(showHealthQuestions(actionPayload));

        if (actionPayload.submission) {
            const relationships = actionPayload.submission.relationships;
            const person = relationships.persons.filter(el => el.id === actionPayload.userId)[0];
            const basket = relationships.basket;
            const personBasket = {
                okp: basket[person.id]?.okp || [],
                vvg: basket[person.id]?.vvg || [],
            };

            if (person) {
                const okpProductIds = personBasket.okp.map(product => product.tariff);
                const vvgProductIds = personBasket.vvg.map(product => product.tariff);
                const productIds = [...okpProductIds, ...vvgProductIds];

                return API_GET_HEALTHQUESTIONS(
                    actionPayload.userId,
                    actionPayload.submission,
                    person.birthdate,
                    person.gender,
                    productIds.join(';'),
                    person.insuranceStart
                )
                    .then(result => {
                        // clear state.HealthQuestionsPage
                        dispatch(showHealthQuestionsSuccess({ data: { id: actionPayload.id.uuid, ...result.data } }));
                    })
                    .catch(e => {
                        log.error('error', e);
                        dispatch(showHealthQuestionsError(e));
                    });
            }
        }
    };
}

export function requestUpdateSubmission(tab, data) {
    return (dispatch, getState) => {
        dispatch(updateSubmission(data));
        const { id } = data;

        return API_UPDATE_SUBMISSION(id.uuid, data, getState().premcalcData.entities.ownSubmission[id.uuid])
            .then(response => {
                dispatch(addAppEntities(response));
                return response;
            })
            .then(response => {
                if (tab) {
                    dispatch(markTabUpdated(tab));
                }
                dispatch(updateSubmissionSuccess(response));
                return response;
            })
            .catch(e => {
                dispatch(updateSubmissionError(e));
                throw e;
            });
    };
}

export const loadData = params => dispatch => {
    const { userId } = params;
    let payload = {
        userId: userId,
    };

    payload.id = getSubmissionId();

    return dispatch(requestShowSubmission(payload))
        .then(response => {
            const submission = response.data;
            const person = getPerson(submission, payload.userId);

            if (person && needsToAnswerHealthQuestions(person.id, submission))
                dispatch(
                    requestShowHealthQuestions({
                        submission: submission,
                        ...payload,
                    })
                );
        })
        .catch(e => {
            throw e;
        });
};
