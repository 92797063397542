import React from 'react';
import { func, string } from 'prop-types';
import { Field } from 'react-final-form';
import { EmphasizedToggle } from 'atupri-component-library/lib/primitives';

const FieldEmphasizedToggle = ({ id, name, label, icon, value, onChange, validate }) => {
    return (
        <Field id={id} name={name} validate={validate}>
            {fieldProps => (
                <EmphasizedToggle
                    controlType="radio"
                    icon={icon}
                    value={value}
                    name={fieldProps.input.name}
                    selectedValue={fieldProps.input.value}
                    onChange={event => {
                        onChange?.(event);
                        fieldProps.input.onChange(event.target.value);
                    }}
                    errorText={validate && fieldProps.meta.touched && fieldProps.meta.error}
                >
                    {label}
                </EmphasizedToggle>
            )}
        </Field>
    );
};

FieldEmphasizedToggle.propTypes = {
    id: string.isRequired,
    name: string.isRequired,
    label: string,
    icon: string,
    value: string,
    onChange: func,
    validate: func,
};

export default FieldEmphasizedToggle;
