/**
 * Returns a unique identifier for a product
 *
 * e.g. with 'firstSegmentOnly=true': id='HMO_34' => identifier="hmo"
 * e.g. with 'firstSegmentOnly=false': id='MIX_26_236' => identifier="mix_26_236"
 *
 * @param id - The string identifier of the product
 * @param firstSegmentOnly - Flag indicating whether to return only the first segment
 * @returns The product identifier string or null if the input is invalid
 */
export const getProductIdentifier = function (id, firstSegmentOnly = true) {
    if (typeof id !== 'string' || id === '') {
        return null;
    }

    if (firstSegmentOnly) {
        const matches = id.split('_');
        return matches[0].toLowerCase();
    } else {
        return id.toLowerCase();
    }
};

/**
 * Returns the attribute for a product
 *
 * e.g. id='HAS_56_1_6_1_1_1_0_0_0_0', attribute='identifier' => identifier="HAS"
 * e.g. id='HAS_56_1_6_1_1_1_0_0_0_0', attribute='variant' => variant="6"
 *
 * @param id - The string identifier of the product
 * @param attribute - one of: identifier, category, variant or option
 * @returns The product category string or null if the input is invalid
 */
export const getProductAttribute = function (id, attribute) {
    if (typeof id !== 'string' || id === '') {
        return null;
    }

    let splitId;
    switch (attribute) {
        case 'identifier':
            splitId = 0;
            break;
        case 'category':
            splitId = 2;
            break;
        case 'variant':
            splitId = 3;
            break;
        case 'option':
            splitId = 4;
            break;
        default:
            splitId = 0;
    }

    const matches = id.split('_');
    return matches[splitId].toUpperCase();
};
