import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { array, object } from 'prop-types';
import { useSelector } from 'react-redux';

// Hooks
import { useWindowSize } from 'atupri-component-library/lib/core/utils/Hooks';

// Helpers
import { Container } from 'atupri-component-library/lib/helpers';

// Components
import DesktopView from '@src/components/PersonSelect/renderings/DesktopView';
import DropdownView from '@src/components/PersonSelect/renderings/DropdownView';

// Util
import { isUp } from 'atupri-component-library/lib/core/utils/Breakpoints';
import { calcPersonPrice } from '@src/util/basket';
import { isInternalProcess } from '@src/util/process';

const PersonSelect = ({ submission, persons, basket, tariffs: { currentProducts } }) => {
    const [personsArr, setPersonsArr] = useState([]);
    const { personId = persons[0]?.id } = useParams();
    const { width: windowWidth } = useWindowSize();
    const {
        id: { uuid },
    } = submission;
    const currentProductsFromApi = useSelector(state => state.apiData?.currentProducts);

    const isInternal = isInternalProcess(persons, currentProductsFromApi);
    const currentOkpProduct = currentProducts?.find(product => product.code === 'OKP');

    const populatePersonArr = () => {
        if (persons && persons.length > 0) {
            const newPersonArr = [];

            persons.map((person, i) => {
                let p = { ...person };
                p.counter = i + 1;
                p.totalPrice = calcPersonPrice(basket[p.id]);
                p.isActive = personId ? p.id === personId : i === 0;

                newPersonArr.push(p);
            });

            setPersonsArr(newPersonArr);
        }
    };

    useEffect(() => {
        populatePersonArr();
    }, [personId, persons, basket[personId]]);

    if (personsArr.length > 0 && windowWidth) {
        return isUp('medium', windowWidth) ? (
            <Container noOffsets>
                <DesktopView
                    persons={personsArr}
                    submissionId={uuid}
                    isInternalProcess={isInternal}
                    currentOkpProduct={currentOkpProduct}
                />
            </Container>
        ) : (
            <Container noOffsets>
                <DropdownView
                    persons={personsArr}
                    submissionId={uuid}
                    isInternalProcess={isInternal}
                    currentOkpProduct={currentOkpProduct}
                />
            </Container>
        );
    }
    return null;
};

PersonSelect.defaultProps = {
    persons: [],
    basket: [],
};

PersonSelect.propTypes = {
    submission: object.isRequired,
    persons: array,
    basket: object,
};

export default PersonSelect;
