import { environmentConfiguration } from './env';
import { useEffect } from 'react';

export const FUNCTIONAL_COOKIE_GROUP = 'C0004';

const { env, environments } = environmentConfiguration;

export const useOneTrust = () => {
    useEffect(() => {
        let url = 'https://cdn.cookielaw.org/consent/1f5fee79-2e4c-4db8-8ed0-667de8a7b427/otSDKStub.js';
        let integrity = '1f5fee79-2e4c-4db8-8ed0-667de8a7b427';

        if (env !== environments.prod) {
            url = 'https://cdn.cookielaw.org/consent/1f5fee79-2e4c-4db8-8ed0-667de8a7b427-test/otSDKStub.js';
            integrity = '1f5fee79-2e4c-4db8-8ed0-667de8a7b427-test';
        }

        const head = document.querySelector('head');
        const script = document.createElement('script');
        script.src = url;
        script.async = true;
        script.type = 'text/javascript';
        script.setAttribute('data-document-language', 'true');
        script.setAttribute('charset', 'UTF-8');
        script.setAttribute('data-domain-script', integrity);
        script.onload = 'function OptanonWrapper() {};';
        script.crossOrigin = 'anonymous';
        head?.appendChild(script);
    }, []);
};
