import React from 'react';
import i18next from 'i18next';

// api
import { API_UPDATE_SUBMISSION } from '@src/api/submission.api';

// components
import { NamedLink } from '@src/components';
import { getPersonIndex } from '@src/util/submission';
import { Button, Icon } from 'atupri-component-library/lib/primitives';

// style
import { StyledToggleIcon, StyledToggleIconWrapper, StyledToggleText } from '../ProductForm.style';
import { animations } from 'atupri-component-library/lib/core/styles/theme/default';
import { isLastPerson } from '@src/util/person';

export const getBackLinkButton = (persons, currentPerson, currentSubmission, backActionMsg, onClick) => {
    if (Array.isArray(persons) && persons.length === 0) {
        return;
    }

    const currentPersonIndex = getPersonIndex(currentSubmission, currentPerson.id);
    const BackButton = () => <Button buttonText={backActionMsg} outlined variant="primary" iconBefore="arrow_left" />;

    if (persons.length === 1 || currentPersonIndex === 0) {
        return (
            <NamedLink name="PersonPage">
                <BackButton />
            </NamedLink>
        );
    } else {
        const prevPerson = persons[currentPersonIndex - 1];
        return (
            <NamedLink
                name="EditSubmissionPage"
                params={{
                    tab: 'product',
                    personId: prevPerson.id,
                }}
            >
                <BackButton />
            </NamedLink>
        );
    }
};

export const getNextLinkButton = (
    persons,
    currentPerson,
    currentSubmission,
    submitButtonText,
    handleOnClick,
    beforeClick
) => {
    if (Array.isArray(persons) && persons.length === 0) {
        return;
    }

    const currentPersonIndex = getPersonIndex(currentSubmission, currentPerson.id);
    const onClickLast = () => {
        return document
            .getElementById('productForm')
            .dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
    };

    const SubmitButton = () => (
        <Button
            onClick={ev => {
                if (beforeClick) {
                    return beforeClick(ev, onClickLast);
                } else {
                    return onClickLast();
                }
            }}
            buttonText={submitButtonText}
            outlined={false}
            type="button"
            variant={'primary'}
        />
    );

    if (isLastPerson(persons, currentPersonIndex)) {
        return <SubmitButton />;
    } else {
        const nextPerson = persons[currentPersonIndex + 1];
        const onClickNotLast = () => {
            return API_UPDATE_SUBMISSION(currentSubmission.id.uuid, {}, currentSubmission).then(() => {
                handleOnClick(nextPerson);
            });
        };

        return (
            <Button
                onClick={ev => {
                    if (beforeClick) {
                        return beforeClick(ev, onClickNotLast);
                    } else {
                        return onClickNotLast();
                    }
                }}
                buttonText={submitButtonText}
                outlined={false}
                type="button"
                variant={'primary'}
            />
        );
    }
};

export const ToggleIcon = ({ isExpanded, ...rest }) => {
    const openText = i18next.t('ProductForm.toggle.open');
    const closeText = i18next.t('ProductForm.toggle.close');

    return (
        <StyledToggleIconWrapper {...rest}>
            <StyledToggleIcon
                animate={{ rotate: isExpanded ? 180 : 0 }}
                transition={{ duration: animations.durationDefault }}
            >
                <Icon iconName="arrow_down" title={isExpanded ? closeText : openText} />
            </StyledToggleIcon>
            <StyledToggleText above="medium">{isExpanded ? closeText : openText}</StyledToggleText>
        </StyledToggleIconWrapper>
    );
};
