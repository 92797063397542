import styled from '@emotion/styled';
import { rem } from 'polished';
import { mixins } from 'atupri-component-library/lib/core/styles/theme/default';

export const StyledContainer = styled.div`
    ${mixins.responsiveStyles({
        'padding-top': { zero: rem(144), medium: rem(144) },
        'padding-bottom': { zero: rem(80), medium: rem(128) },
    })}
`;
