import React from 'react';
import { bool, func, string } from 'prop-types';
import { Field } from 'react-final-form';
import InputMask from 'react-input-mask';
import { Input } from 'atupri-component-library/lib/primitives';

const FieldInput = ({
    id,
    name,
    label,
    placeholder,
    defaultValue,
    validate,
    onChange,
    hideLabel = false,
    disabled = false,
    mask = undefined,
    parse = undefined,
    format = undefined,
    hideLockIcon = false,
}) => {
    const onFieldChange = (event, fieldOnChange) => {
        fieldOnChange(event.target.value);
        onChange?.(event);
    };
    const renderInput = props => {
        return (
            <Input
                {...props}
                value={props.value}
                errorText={props.meta.touched && props.meta.error}
                inputRightIcon={!hideLockIcon && disabled && { iconName: 'lock' }}
            />
        );
    };

    const renderInputMask = props => {
        return (
            <InputMask
                {...props}
                onChange={event => onFieldChange(event, props.input.onChange)}
                onBlur={props.input.onBlur}
                onFocus={props.input.onFocus}
                value={props.input.value}
                disabled={props.disabled}
                mask={mask}
            >
                {inputProps => renderInput(inputProps)}
            </InputMask>
        );
    };
    return (
        <Field
            id={id}
            name={name}
            label={!hideLabel && label}
            placeholder={placeholder}
            value={defaultValue}
            validate={validate}
            disabled={disabled}
            onChange={onChange}
            parse={parse}
            format={format}
        >
            {props =>
                mask && !disabled
                    ? renderInputMask(props)
                    : renderInput({
                          value: props.input.value,
                          name: props.input.name,
                          id: props.id,
                          label: props.label,
                          meta: props.meta,
                          disabled: props.disabled,
                          placeholder: props.placeholder,
                          onChange: event => {
                              onFieldChange(event, props.input.onChange);
                          },
                      })
            }
        </Field>
    );
};

FieldInput.propTypes = {
    id: string.isRequired,
    name: string.isRequired,
    label: string,
    placeholder: string,
    defaultValue: string,
    validate: func,
    onChange: func,
    hideLabel: bool,
    disabled: bool,
    mask: string,
    parse: func,
    format: func,
    hideLockIcon: bool,
};

export default FieldInput;
