import React from 'react';
import { array, bool, func, string } from 'prop-types';
import i18next from 'i18next';
import { RadioGroup, P4 } from 'atupri-component-library/lib/primitives';
import { Loader } from 'atupri-component-library/lib/components';
import PracticeItem from '@src/components/PracticeSearch/partials/PracticeItem';

import { getAvailableModels, getPracticeToShow } from '@src/util/practice';

import { StyledPracticeList, StyledLoaderWrap, StyledNoResults } from './PracticeList.styles';

const PracticeList = props => {
    const {
        tempPractice,
        setTempPractice,
        selectedModel,
        practiceListLoading,
        filteredPracticeList,
        preselectedPractice,
    } = props;

    const onChangeHandler = e => {
        setTempPractice(e.target.value);
    };

    return !practiceListLoading ? (
        <RadioGroup name="practice-item" onChange={onChangeHandler} selectedValue={tempPractice}>
            <StyledPracticeList component="ul" space="gamma">
                {filteredPracticeList.length > 0 ? (
                    filteredPracticeList.map(practice => (
                        <PracticeItem
                            key={`${practice.zsrNr}_${practice.model}`}
                            {...getPracticeToShow(practice, selectedModel)}
                            availableModels={getAvailableModels(practice)}
                            selectedModel={selectedModel}
                            tempPractice={tempPractice}
                            setTempPractice={setTempPractice}
                            preselectedPractice={preselectedPractice}
                        />
                    ))
                ) : (
                    <StyledNoResults>
                        <P4>{i18next.t('PracticeSearch.list.noresults')}</P4>
                    </StyledNoResults>
                )}
            </StyledPracticeList>
        </RadioGroup>
    ) : (
        <StyledLoaderWrap>
            <Loader color="primary" size={32} strokeWidth={3} />
        </StyledLoaderWrap>
    );
};

PracticeList.defaultProps = {
    tempPractice: '',
    practiceListLoading: true,
};

PracticeList.propTypes = {
    selectedModel: string.isRequired,
    tempPractice: string,
    setTempPractice: func.isRequired,
    practiceListLoading: bool,
    filteredPracticeList: array.isRequired,
    preselectedPractice: string,
};

export default PracticeList;
