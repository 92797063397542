import '@src/styles/main.scss';

import 'core-js/features/map';
import 'core-js/features/set';
import 'raf/polyfill';

import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { ClientApp, renderApp } from '@src/App';
import configureStore from '@src/store';
import { matchPathname } from '@src/util/routes';
import config from '@src/config';
import routeConfiguration from '@src/routeConfiguration';
import { LoadingIndicator } from '@src/components';

const render = (store, shouldHydrate, persistor) => {
    const container = document.getElementById('root');
    const root = createRoot(container);

    if (shouldHydrate) {
        root.hydrate(
            <Suspense fallback={<LoadingIndicator />}>
                <ClientApp store={store} persistor={persistor} />
            </Suspense>
        );
    } else {
        root.render(
            <Suspense fallback={<LoadingIndicator />}>
                <ClientApp store={store} persistor={persistor} />
            </Suspense>
        );
    }
};

// If we're in a browser already, render the client application.
if (typeof window !== 'undefined') {
    // eslint-disable-next-line no-underscore-dangle
    const preloadedState = window.__PRELOADED_STATE__ || '{}';
    const initialState = JSON.parse(preloadedState);
    const storage = configureStore(initialState);

    render(storage.store, !!window.__PRELOADED_STATE__, storage.persistor);

    if (config.dev) {
        // Expose for browser
        window.app = {
            config,
            store: storage.store,
            routeConfiguration: routeConfiguration(),
        };
    }
}

export default renderApp;

export { matchPathname, configureStore, routeConfiguration, config };
