import styled from '@emotion/styled';
import { rem } from 'polished';
import { mixins, spaces } from 'atupri-component-library/lib/core/styles/theme/default';
import { Stack } from 'atupri-component-library/lib/helpers';

export const StyledPracticeList = styled(Stack)`
    position: relative;
    height: 100%;
    ${mixins.listReset};
    overflow: auto;
    -webkit-overflow-scrolling: touch;

    li {
        ${mixins.responsiveStyles({
            width: { small: rem(304), medium: '100%' },
            'min-width': { small: rem(300), medium: 'auto' },
        })}
    }

    ${mixins.responsiveStyles({
        'max-width': { medium: '100%' },
        width: { zero: '100%', medium: rem(402) },
        'align-items': { small: 'center', medium: 'initial' },
    })}
`;

export const StyledLoaderWrap = styled.div`
    width: 25.125rem;
    max-width: 25.125rem;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const StyledNoResults = styled.div`
    ${mixins.responsiveStyles({
        padding: { zero: spaces.epsilon, medium: 0 },
    })}
`;
