// util
import { isEmpty } from '@src/util/object';
import { PRODUCT_GROUP_IDENTIFIER_MIVITA } from '@src/util/tariff/vvg';
import { getAgeFromDate } from '@src/util/date';

export const preselectedOkpProduct = (okpBasketProduct, okpApiData) => {
    const { products } = okpApiData;

    let value = okpBasketProduct?.product && products.find(product => product.id === okpBasketProduct.product)?.id;

    if (!value) {
        value = products.find(product => product.recommended === true)?.id;
    }

    return String(value);
};

export const preselectedOkpCategory = (okpBasketProduct, okpApiData) => {
    const { categories } = okpApiData;

    let value =
        okpBasketProduct?.category &&
        categories.find(category => category.id === parseInt(okpBasketProduct.category))?.id;

    if (!value) {
        value = categories.find(category => category.recommended === true)?.id;
    }

    return String(value);
};

export const preselectedOkpVariant = (okpBasketProduct, okpApiData) => {
    const { variants } = okpApiData;

    let value =
        okpBasketProduct?.variant && variants.find(variant => variant.id === parseInt(okpBasketProduct.variant))?.id;

    if (!value) {
        value = variants.find(variant => variant.recommended === true)?.id;
    }

    return String(value);
};

export const preselectedOkpOption = (okpBasketProduct, okpApiData) => {
    const { options } = okpApiData;

    let value = okpBasketProduct?.option && options.find(option => option.id === parseInt(okpBasketProduct.option))?.id;

    if (!value) {
        value = options.find(option => option.recommended === true)?.id;
    }

    return String(value);
};

export const preselectedOkpTariff = (okpBasketProduct, okpApiData) => {
    const { tariffs } = okpApiData;

    let value = okpBasketProduct?.tariff && tariffs.find(tariff => tariff.id === okpBasketProduct.tariff)?.id;

    if (!value) {
        value = tariffs.find(tariff => tariff.recommended === true)?.id;
    }

    return String(value);
};

export const preselectedVvgSection = (selectedSection, vvgApiData, recommendEnabled = true) => {
    const { sections } = vvgApiData;
    // Check if selected section exists in apiData...
    let value = findItemByProperty(selectedSection, 'id', sections)?.id;

    if (recommendEnabled && value === undefined) {
        // ...if not, fallback to recommended section...check if mivita product group is recommended
        const mivitaProductGroup = findItemByProperty(PRODUCT_GROUP_IDENTIFIER_MIVITA, 'id', vvgApiData?.productGroups);
        value = mivitaProductGroup?.recommended ? 'withMivita' : 'withoutMivita';
    }

    return value;
};

export const preselectedVvgProductGroups = (vvgBasket, vvgApiData, recommendEnabled = true) => {
    // Get selected productGroups...
    let values = selectedVvgProductGroups(vvgBasket, vvgApiData);
    if (recommendEnabled && isEmpty(values)) {
        // ... fallback to recommended productGroups if no selected found.
        values = recommendedVvgProductGroups(vvgApiData);
    }

    return values;
};

export const preselectedVvgProducts = (vvgBasket, vvgApiData, recommendEnabled = true) => {
    // Get selected products...
    let values = selectedVvgProducts(vvgBasket, vvgApiData);

    if (recommendEnabled && isEmpty(values)) {
        // ... fallback to recommended products if no selected found.
        values = recommendedVvgProducts(vvgApiData);
    }

    return values;
};

/**
 * Returns selected vvg productGroups
 *
 * @param {*} vvgBasket
 * @param {*} vvgApiData
 * @returns
 */
export const selectedVvgProductGroups = (vvgBasket, vvgApiData) => {
    const { sections, productGroups } = vvgApiData;
    let values = {};

    if (Array.isArray(vvgBasket)) {
        // Use 'Set' to create an array with unique values...
        [
            ...new Set(
                vvgBasket
                    .filter(item => findItemByProperty(item.section, 'id', sections)) // Check if item's section exists in apiData
                    .filter(item => findItemByProperty(item.productGroup, 'id', productGroups)) // Check if item's productGroup exists in apiData
                    .map(item => `${item.section}_${item.productGroup}`)
            ),
        ].forEach(uniqueProductGroup => {
            // Write values only for relevant productGroups...
            values[uniqueProductGroup] = true;
        });
    }

    return values;
};

/**
 * Returns recommended vvg productGroups
 *
 * @param {*} vvgApiData
 * @returns
 */
export const recommendedVvgProductGroups = vvgApiData => {
    let values = {};
    const { sections, productGroups } = vvgApiData;

    // Iterate through all sections...
    sections.forEach(section => {
        productGroups
            .filter(pg => pg.recommended === true && section.productGroups.includes(pg.id)) // Check if productGroup is recommended and available in section
            .forEach(pg => {
                // Write values only for relevant productGroups
                values[`${section.id}_${pg.id}`] = true;
            });
    });

    return values;
};

/**
 * Returns selected vvg products
 *
 * @param {*} vvgBasket
 * @param {*} vvgApiData
 * @returns
 */
export const selectedVvgProducts = (vvgBasket, vvgApiData) => {
    const { sections, productGroups } = vvgApiData;
    let values = {};

    if (Array.isArray(vvgBasket)) {
        vvgBasket
            .filter(item => findItemByProperty(item.section, 'id', sections)) // Check if item's section exists in apiData
            .filter(item => findItemByProperty(item.productGroup, 'id', productGroups)) // Check if item's productGroup exists in apiData
            .forEach(item => {
                // Write values only for relevant products
                values[`${item.section}_${item.productGroup}`] = item.product;
            });
    }

    return values;
};

/**
 * Returns recommended vvg products
 *
 * @param {*} vvgApiData
 * @returns
 */
export const recommendedVvgProducts = vvgApiData => {
    let recommended = {};
    const { sections, productGroups, products } = vvgApiData;

    // Iterate through all sections...
    sections.forEach(section => {
        // Get productGroups belonging to this section...
        let productGroupsOfSection = [...section.productGroups];

        // Add productsOfGroup, if any...
        if (section.productsOfGroup) {
            productGroupsOfSection.push(section.productsOfGroup);
        }

        productGroups.forEach(pg => {
            if (pg.id in section.productGroups) {
                products
                    .filter(p => p.recommended === true && p.productGroup === pg.id) // Check if product is recommended and belongs to this productGroup and section...
                    .forEach(p => {
                        // Write values only for relevant products
                        recommended[`${section.id}_${pg.id}`] = p.id;
                    });
            }
        });
    });

    return recommended;
};

export const recommendedVvgProductForProductGroup = (productGroupId, vvgApiData) => {
    let recommended = null;
    const { products } = vvgApiData;

    products
        .filter(p => p.recommended === true && p.productGroup === productGroupId)
        .forEach(p => {
            if (!recommended || (recommended && recommended.price > p.price)) {
                recommended = p;
            }
        });

    return recommended;
};

/**
 * Generic method to find item by given property and value inside of given data-array
 *
 * @param {*} value
 * @param {*} property
 * @param {array} data
 */
const findItemByProperty = (value, property, data) => {
    let item;

    if (data && property) {
        item = data.find(item => item[property] === value);
    }
    return item;
};

/**
 * Scroll given Accordion Element into view
 *
 * @param ref
 * @param element
 * @param timeout
 */
export const scrollAccordionIntoView = (ref, element, timeout = 500) => {
    setTimeout(() => {
        if (ref.current) {
            ref.current[element].scrollIntoView({ behavior: 'smooth', block: 'nearest' });
        }
    }, timeout);
};

/**
 * Scroll given Element into view with a defined offset
 *
 * @param id
 * @param offset
 */
export const scrollIntoViewWithOffset = (id, offset) => {
    window.scrollTo({
        behavior: 'smooth',
        top:
            document.getElementById(id).getBoundingClientRect().top -
            document.body.getBoundingClientRect().top -
            offset,
    });
};

/**
 * Determines whether it is an age group change
 *
 * @param isInternal
 * @param currentPerson
 *
 * @return boolean
 */
export const checkForAgeGroupChange = (isInternal, currentPerson) => {
    return isInternal && getAgeFromDate(currentPerson.birthdate) === 18;
};
