import React from 'react';
import i18next from 'i18next';
import { string, number, bool } from 'prop-types';
import { formatAmount } from '@src/util/currency';
import { convertDateToPresentationFormat } from '@src/util/date';
import { P2, P3 } from 'atupri-component-library/lib/primitives';

import { StyledPersonContainer, StyledIconPlaceholder, StyledPersonIcon } from '../PersonSelect.style';
import { getGenderIconName } from '@src/util/person';

const getGenderIcon = (gender, isInDropdown) => {
    const iconName = getGenderIconName(gender, !isInDropdown);
    const iconColor = isInDropdown ? 'grey700' : 'primary';

    return (
        <StyledIconPlaceholder>
            <StyledPersonIcon iconName={iconName} iconColor={iconColor} isInDropdown={isInDropdown} />
        </StyledIconPlaceholder>
    );
};

const Person = props => {
    const { counter, gender, firstname, lastname, birthdate, totalPrice, isInDropdown, hasMultiplePersons } = props;

    const birthdatePresentation = convertDateToPresentationFormat(birthdate);

    return (
        <StyledPersonContainer isInDropdown={isInDropdown} hasMultiplePersons={hasMultiplePersons}>
            {getGenderIcon(gender, isInDropdown)}
            <div>
                <P3>
                    {!firstname && !lastname ? (
                        <>
                            {i18next.t('PersonSelect.person')} {counter} ({birthdatePresentation})
                        </>
                    ) : (
                        <>
                            {firstname} {lastname} ({birthdatePresentation})
                        </>
                    )}
                </P3>
                {totalPrice !== null && (
                    <P2>
                        {i18next.t('PersonSelect.personTotal')} {formatAmount(totalPrice)}
                    </P2>
                )}
            </div>
        </StyledPersonContainer>
    );
};

Person.defaultProps = {
    firstname: '',
    lastname: '',
    isInDropdown: false,
    hasMultiplePersons: false,
    totalPrice: null,
};

Person.propTypes = {
    gender: string.isRequired,
    firstname: string,
    lastname: string,
    birthdate: string.isRequired,
    totalPrice: number,
    isInDropdown: bool,
    hasMultiplePersons: bool,
    showPersonTotal: bool,
};

export default Person;
