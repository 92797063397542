import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { pathByRouteName } from '@src/util/routes';

import { StyledLink } from './NamedLink.style';

export const NamedLink = ({ name, params = {}, children = null, className = '' }) => {
    const pathname = pathByRouteName(name, params);

    return (
        <StyledLink>
            <NavLink to={{ pathname }} className={className}>
                {children}
            </NavLink>
        </StyledLink>
    );
};

const { object, string, shape, any } = PropTypes;

NamedLink.propTypes = {
    name: string.isRequired,
    params: object,
    children: any,
    className: string,
};

export default NamedLink;
