import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { rem } from 'polished';
import { text, colors } from 'atupri-component-library/lib/core/styles/theme/default';
import { H3 } from 'atupri-component-library/lib/primitives';

export const StyledTextWrap = styled.div`
    display: inline-flex;
    flex-direction: column;
    padding-left: ${rem(16)};
    flex: 1;

    p {
        margin: 0 0 ${rem(10)};
    }
`;

export const StyledCheckboxRoot = styled.div(({ isListing }) => {
    let styles = [
        `
    display: flex;
    position: relative;

    input {
      position: absolute;
      width: ${rem(24)};
      height: ${rem(24)};
      z-index: 1;
      box-sizing: border-box;
      appearance: none;
      opacity: 0;
      top: 1px;
      left: 1px;

      &:checked + .checkboxWrap .checkboxIcon {
        background: #324AB5;

        svg {
          display: inline-flex;
          top: ${rem(2)};
          position: relative;
          left: 1px;
        }
      }
    }
  `,
    ];

    if (isListing) {
        styles.push(`
        align-items: center;
        padding-bottom: 0;
      `);
    }
    return styles;
});

export const StyledCheckboxRootNested = styled.div(({ isListing }) => {
    let styles = [
        `
    display: flex;
    padding-bottom: ${rem(12)};
    position: relative;
    margin-top: ${rem(12)};

    input {
      position: absolute;
      width: ${rem(24)};
      height: ${rem(24)};
      z-index: 1;
      box-sizing: border-box;
      appearance: none;
      opacity: 0;
      top: 1px;
      left: 1px;

      &:checked + .checkboxWrap .checkboxIcon {
        background: #324AB5;

        svg {
          display: inline-flex;
          top: ${rem(2)};
          position: relative;
          left: 1px;
        }
      }
    }
  `,
    ];

    if (isListing) {
        styles.push(`
        align-items: center;
        padding-bottom: 0;
      `);
    }
    return styles;
});

export const StyledLabelElement = styled.div(({ isListing }) => {
    let styles = [
        `
    display: flex;
  `,
    ];

    if (isListing) {
        styles.push(`
        align-items: flex-start;
      `);
    }
    return styles;
});

export const StyledLabel = styled.label(
    ({ bold }) => css`
        ${text.p3};
        font-weight: ${bold && '700'};
    `
);

export const StyledLabelBig = styled(H3)`
    color: #324ab5;
    user-select: none;

    label {
        padding: ${rem(14)} ${rem(14)} ${rem(14)} 0;
    }
`;

export const StyledAdditionalText = styled.div`
    ${text.p3};

    a {
        color: ${colors.primary};
    }
`;

export const StyledCheckbox = styled.div(({ isListing }) => {
    let styles = [
        `
    width: ${rem(24)};
    height: ${rem(24)};
    /* Atupri/Blue/300 */
    background: #EBEDF8;
    /* Atupri/Primary Blue */
    border: 1px solid #324AB5;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 1 auto;

    svg {
      display: none;
    }
  `,
    ];

    if (isListing) {
        styles.push(`
        margin-top: 2px;
      `);
    }

    return styles;
});
