import React, { useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import i18next from 'i18next';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import { useParams } from 'react-router-dom';

// components
import { Main, NamedRedirect, Page } from '@src/components';
import { Footer, Header, HeroTeaser, HeroTitle, HeroTitleLine } from 'atupri-component-library/lib/components';

// data
import { footerData, headerData } from '@src/data';

// ducks
import { getPremcalcEntities } from '@src/ducks/premcalcData.duck';
import {
    loadData,
    requestClearErrors,
    requestCreateSubmissionOffer,
    requestUpdateSubmission,
} from '@src/ducks/submission.duck.js';
import { ERROR_CATEGORY_SUBMISSION } from '@src/ducks/errorQueue.duck';

// forms
import { PersonForm } from '@src/forms';

// hooks
import { useErrorQueue } from '@src/hooks/errorQueue';

// util
import { getNextClickTrackingData, getExpiredSessionErrorTrackingData } from '@src/util/analytics';
import { getAutosaved, setAutosaved } from '@src/util/autosave';
import { deepMerge } from '@src/util/data';
import { getInsuranceStartDate } from '@src/util/date';
import { uuidv4 } from '@src/util/uuid';
import { getSubmissionId, clearSubmissionId } from '@src/util/submission';
import { isMobileApp } from '@src/util/mobileAppDetection';

// style
import { StyledContainer, StyledCustomSpacer, StyledTitle, StyledMessageBox } from './PersonsPage.style';
import ImgHero from '@assets/img/homepage-hero-compressor.jpg';

export const PersonPage = () => {
    const dispatch = useDispatch();
    const sendDataToGTM = useGTMDispatch();
    const { errors, addError, clearErrors } = useErrorQueue();
    const { id } = useParams();

    const submissionId = getSubmissionId() || (id ? { uuid: id } : null);

    const { page, submission } = useSelector(state => {
        let submission = null;
        if (typeof submissionId !== 'undefined') {
            const submissions = getPremcalcEntities(state, [{ id: submissionId, type: 'ownSubmission' }]);
            submission = submissions.length === 1 ? submissions[0] : null;
        }

        return {
            page: state.EditSubmissionPage,
            submission,
        };
    });

    const insuranceStartDefault = getInsuranceStartDate();
    const { showSubmissionError = null } = page;
    const persons = submission?.relationships?.persons;
    const mivitaQuestion = submission?.attributes?.mivitaQuestion;
    const datasetComplete = !page.updateInProgress && (!submissionId || submission);
    const ready = true;
    const disabled = false;

    const getInitialValues = () => {
        // default field values - new session
        let defaultValues = {
            persons: [
                {
                    id: uuidv4(),
                    insuranceStart: insuranceStartDefault,
                    birthdate: '',
                    newPerson: true,
                },
            ],
        };

        // if persons exist - use saved information
        if (persons && persons.length > 0) {
            defaultValues = {
                persons: persons.map(person => {
                    return {
                        insuranceStart: insuranceStartDefault,
                        birthdate: '',
                        ...person,
                    };
                }),
                mivitaQuestion: mivitaQuestion,
            };
        }

        // check for autosaved values
        const autosaved = getAutosaved(submissionId ? submissionId.uuid : '_NEW', 'personForm');

        if (autosaved?.values) {
            if (defaultValues) {
                defaultValues = deepMerge([defaultValues, autosaved.values]);
            } else {
                defaultValues = autosaved.values;
            }
        }

        return defaultValues;
    };

    const initialValues = useMemo(() => getInitialValues(), [persons]);

    const renderError = (error, index) => {
        return (
            <StyledMessageBox key={index} text={error.message} variant="info" onClose={clearErrors} staticPosition />
        );
    };

    // redirect non existing id to start page
    useEffect(() => {
        if (showSubmissionError) {
            dispatch(requestClearErrors());
            clearSubmissionId();

            sendDataToGTM(getExpiredSessionErrorTrackingData());
            addError(
                i18next.t('Error.expired', 'The session link has expired. Please restart the process'),
                null,
                ERROR_CATEGORY_SUBMISSION
            );
        }
    });

    if (showSubmissionError) {
        return <NamedRedirect name="PersonPage" />;
    }

    if (page.redirectToProducts && submissionId?.uuid) {
        clearErrors();

        return (
            <NamedRedirect
                name="EditSubmissionPage"
                params={{
                    tab: 'product',
                    personId: persons?.length > 0 ? persons[0].id : null,
                }}
            />
        );
    }

    const render = () => {
        return (
            <Page>
                {!isMobileApp() && <Header {...headerData()} />}
                <Main complete={datasetComplete}>
                    {datasetComplete ? (
                        <StyledContainer>
                            <HeroTeaser image={ImgHero}>
                                <HeroTitle>
                                    <HeroTitleLine>{i18next.t('PersonPage.hero1')}</HeroTitleLine>
                                    <HeroTitleLine>{i18next.t('PersonPage.hero2')}</HeroTitleLine>
                                </HeroTitle>
                            </HeroTeaser>
                            {errors?.map(renderError)}
                            <StyledTitle>{i18next.t('PersonPage.title')}</StyledTitle>
                            <StyledCustomSpacer space="limbo" />
                            <PersonForm
                                className={'form'}
                                initialValues={initialValues}
                                onSubmit={values => {
                                    sendDataToGTM(getNextClickTrackingData());
                                    if (
                                        typeof submissionId !== 'undefined' &&
                                        submissionId !== null &&
                                        submissionId.uuid !== null
                                    ) {
                                        dispatch(requestUpdateSubmission(null, { ...values, id: submissionId }, true));
                                    } else {
                                        dispatch(requestCreateSubmissionOffer(values));
                                    }
                                }}
                                autosave={(values, isFormInvalid) => {
                                    setAutosaved(
                                        submissionId ? submissionId.uuid : '_NEW',
                                        'personForm',
                                        isFormInvalid,
                                        values
                                    );
                                }}
                                disabled={disabled}
                                ready={ready}
                                submission={submission}
                            />
                        </StyledContainer>
                    ) : (
                        <p>{i18next.t('EditSubmissionCockpitForm.tempId')}</p>
                    )}
                </Main>
                {!isMobileApp() && <Footer {...footerData()} />}
            </Page>
        );
    };

    return render();
};

PersonPage.propTypes = {};

PersonPage.loadData = loadData;

export default PersonPage;
