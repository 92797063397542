import { log } from '@src/middleware';

// config
import { mappingData as getTariffMapping } from '@src/config/getTariff';

// util
import { getProductTrackingTitle } from '@src/util/basket';

export const PRODUCT_IDENTIFIER_STANDARD = 'std';
export const PRODUCT_IDENTIFIER_HMO = 'hmo';
export const PRODUCT_IDENTIFIER_CAREMED = 'has';
export const PRODUCT_IDENTIFIER_TELFIRST = 'hmo';
export const PRODUCT_IDENTIFIER_FLEXCARE = 'flc';
export const PRODUCT_IDENTIFIER_SMARTCARE = 'smc';

export const findTariff = function (apiData, values) {
    let match = null;
    const { tariffs: apiTariffs, products: apiProducts } = apiData;
    const { product, category, variant, practices } = values;
    const type = findType(practices, product, apiProducts);

    if (apiTariffs && typeof apiTariffs !== 'undefined') {
        match = apiTariffs.find(tariff => {
            if (
                tariff.parent == product &&
                tariff.type == type &&
                tariff.category == category &&
                tariff.variant == variant
            ) {
                return tariff;
            }
        });
        match = { ...match };
    }

    return match;
};

export const findCategory = function (categories, categoryId) {
    let match = null;
    if (categories && typeof categories !== 'undefined') {
        match = categories.find(category => {
            if (category.id == categoryId) {
                return category;
            }
        });
        match = { ...match };
    }
    return match;
};

export const findProduct = (products, productId) => products.find(product => product.id === productId);

/**
 * if practice is selected, use practice darId as type, otherwise pick type from first tariff in list
 *
 * @param practices
 * @param productId
 * @param products
 * @returns {*}
 */
export const findType = (practices, productId, products) => {
    if (practices?.[productId]) {
        return practices[productId].darId;
    }

    return products.find(apiProduct => apiProduct.id === productId)?.type[0];
};

export const sortProducts = (apiData, values) => {
    const { products } = apiData;
    const { category, variant } = values;

    products.sort((a, b) => {
        const aTariff = findTariff(apiData, {
            product: a.id,
            category,
            variant,
        })?.price;

        const bTariff = findTariff(apiData, {
            product: b.id,
            category,
            variant,
        })?.price;

        if (aTariff < bTariff) {
            return -1;
        }
        if (bTariff < aTariff) {
            return 1;
        }
        return 0;
    });

    return products;
};

export const sortCategories = (apiData, values) => {
    const { categories } = apiData;
    const { product, variant } = values;

    categories.sort((a, b) => {
        const aTariff = findTariff(apiData, {
            product,
            category: a.id,
            variant,
        })?.price;

        const bTariff = findTariff(apiData, {
            product,
            category: b.id,
            variant,
        })?.price;

        if (aTariff < bTariff) {
            return -1;
        }
        if (bTariff < aTariff) {
            return 1;
        }
        return 0;
    });

    return categories;
};

export const calculatePriceWithTax = tariff => {
    let price = tariff?.price || 0.0;

    if (tariff?.bafu) {
        price += tariff.bafu;
    }

    return price;
};

export const getLowestTariffYear = apiData => {
    const { tariffs } = apiData;
    const years = tariffs.map(tariff => tariff.year);
    return Math.min(...years);
};

export const getOkpProductsTrackingData = (apiData, values, products, selectedProduct) => {
    const { category, variant } = values;
    return products.map(product => {
        const price = calculatePriceWithTax(
            findTariff(apiData, {
                product,
                category,
                variant,
            })
        );
        return {
            title: getProductTrackingTitle({ type: 'okp', product }),
            selected: selectedProduct === product,
            price,
        };
    });
};

/**
 * Filters final-form `values` and returns active products only
 *
 * @param {*} values
 * @returns array of objects
 */
export const findSelectedProducts = values => {
    const { enabled, product } = values;
    const selectedProducts = [];

    if (enabled) {
        selectedProducts.push(product);
    }

    return selectedProducts;
};

/**
 * Create a OKP basket entry by given tariffId
 *
 * @param {string} tariffId
 * @param optionId
 * @param categoryId
 * @param variantId
 * @param {object} apiOkpTariffs
 * @returns {object|null}
 */
export const createBasketEntryByTariffId = (tariffId, optionId, categoryId, variantId, apiOkpTariffs) => {
    if (!tariffId || !apiOkpTariffs) {
        return;
    }

    const { tariffs: apiTariffs, products: apiProducts, categories: apiCategories } = apiOkpTariffs;
    const apiTariff = apiTariffs.find(apiTariff => apiTariff.id === tariffId);

    if (!apiTariff) {
        log.error('Could not find tariff with id', tariffId);
        return;
    }
    const { parent: productId, ...otherTariffData } = apiTariff;
    const apiProduct = apiProducts.find(apiProduct => apiProduct.id === productId);

    if (!apiProduct) {
        log.error('Could not find product with id', productId);
        return;
    }

    return {
        option: optionId,
        franchise: findCategory(apiCategories, categoryId)?.value,
        accidentIncluded: variantId == getTariffMapping.okp.variants.accidentIncluded ? '1' : '0',
        product: productId,
        gatekeeper: findProduct(apiProducts, productId)?.gatekeeper,
        tariff: tariffId,
        type: 'OKP',
        ...otherTariffData,
    };
};
