import React, { useState, useEffect } from 'react';
import { string, number, func, object, bool } from 'prop-types';
import i18next from 'i18next';
import { useStore } from 'react-redux';
import moment from 'moment';
import { log } from '@src/middleware';

// api
import { API_GET_PRACTICES } from '@src/api/practiceSearch.api';
import { API_PUBLISH_SUBMISSION } from '@src/api/submission.api';

// components
import { Alert, Modal } from 'atupri-component-library/lib/components';
import { Box, Spacer } from 'atupri-component-library/lib/helpers';
import { Button, Icon, P2 } from 'atupri-component-library/lib/primitives';
import { isUp } from 'atupri-component-library/lib/core/utils/Breakpoints';
import { useWindowSize } from 'atupri-component-library/lib/core/utils/Hooks';
import SearchFields from '@src/components/PracticeSearch/partials/SearchFields';
import MapView from '@src/components/PracticeSearch/partials/MapView';
import PracticeList from '@src/components/PracticeSearch/partials/PracticeList';
import SelectionRow from '@src/components/PracticeSearch/partials/SelectionRow';

// util
import { PRESENTATION_DATE_FORMAT } from '@src/util/date';
import { FormStateEnum } from '@src/util/finalForm/formState';
import { checkCookieConsent, setCookieConsentChangedCallback, openCookieConsentWindow } from '@src/util/oneTrust';
import { getPracticeToShow } from '@src/util/practice';
import { UserFlowEnum } from '@src/util/process';
import { sanitizeDOM } from '@src/util/sanitize';
import { getSubmissionId } from '@src/util/submission';

// style
import {
    StyledTitle,
    StyledTooltip,
    StyledTooltipContent,
    StyledMapWrapper,
    StyledButtonWrapper,
    StyledButtonRow,
    StyledButtonRowFixed,
    StyledButtonRowFixedPlaceholder,
    StyledConsentBox,
    StyledConsentText,
    modalStyles,
} from './PracticeSearch.style';

const PracticeSearch = ({
    model,
    selectedPractice,
    selectedPractices,
    setSelectedPractices,
    identifier,
    zip,
    municipalityId,
    place,
    insuranceStart,
    initialRadiusVal = 10,
    hasActiveStyles = false,
    userFlow,
}) => {
    const preselectedPractice =
        Object.keys(selectedPractice).length !== 0 ? `${selectedPractice.zsrNr}_${selectedPractice.model}` : undefined;
    const selectedModel = model.split('_')[0];
    const { width: windowWidth } = useWindowSize();
    const isDoctorChangeOnly = userFlow === UserFlowEnum.doctorChangeOnly;
    const [isDocModalOpen, setIsDocModalOpen] = useState(isDoctorChangeOnly);
    const [practiceList, setPracticeList] = useState([]);
    const [filteredPracticeList, setFilteredPracticeList] = useState([]);
    const [practiceListLoading, setPracticeListLoading] = useState(false);
    const [tempPractice, setTempPractice] = useState(preselectedPractice);
    const [savedPractice, setSavedPractice] = useState(selectedPractice || {});
    const [zipValue, setZipValue] = useState(zip);
    const [requestCity, setRequestCity] = useState(place);
    const [nameValue, setNameValue] = useState('');
    const [radiusValue, setRadiusValue] = useState(initialRadiusVal);
    const [formState, setFormState] = useState(FormStateEnum.start);
    const [hasCookieConsent, setHasCookieConsent] = useState(false);
    const submissionId = getSubmissionId().uuid;
    const submission = useStore().getState().premcalcData.entities.ownSubmission[submissionId];

    useEffect(() => {
        // check for active consent group in Onetrust. Only set to true here not to trigger a rerender
        if (checkCookieConsent()) {
            setHasCookieConsent(true);
        }
    }, []);

    const getFilteredPracticeList = () => {
        const filteredList = [];

        practiceList.forEach(item => {
            if (
                nameValue.length === 0 ||
                getPracticeToShow(item, selectedModel).provider.toLowerCase().indexOf(nameValue.toLowerCase()) > -1
            ) {
                filteredList.push(item);
            }
        });

        setFilteredPracticeList(filteredList);
    };

    useEffect(() => {
        if (Object.keys(selectedPractice).length === 0) {
            setTempPractice(undefined);
        }
    }, [selectedPractice]);

    useEffect(() => {
        setPracticeListLoading(true);
        setTimeout(() => {
            getFilteredPracticeList();
            setPracticeListLoading(false);
        }, 0);
    }, [practiceList, nameValue]);

    useEffect(() => {
        setPracticeListLoading(true);
        if (zipValue.length === 4 && requestCity) {
            API_GET_PRACTICES(radiusValue, zipValue, requestCity, insuranceStart)
                .then(result => {
                    let structuredResult = [];
                    result.data.forEach(item => {
                        const existingIndex = structuredResult.findIndex(item2 => item2.zsrNr === item.zsrNr);
                        if (existingIndex === -1) {
                            structuredResult.push({
                                zsrNr: item.zsrNr,
                                [item.model]: item,
                            });
                        } else {
                            structuredResult[existingIndex][item.model] = item;
                        }
                    });

                    setPracticeList(structuredResult);
                })
                .catch(e => log.error(e))
                .finally(() => {
                    setPracticeListLoading(false);
                });
        }
    }, [zipValue, requestCity, radiusValue]);

    useEffect(() => {
        setCookieConsentChangedCallback(cookieConsentChanged);
    }, []);

    const cookieConsentChanged = () => {
        setHasCookieConsent(checkCookieConsent());
    };

    const toggleDocModal = () => {
        if (isDoctorChangeOnly) {
            // The myAtupri sessions will always run on a myAtupri domain. For this reason, the root "/"
            // is the myAtupri dashboard and not the premium calculator person page.
            window.location.href = '/';
        } else {
            setIsDocModalOpen(!isDocModalOpen);
        }
    };

    const handleSaveClick = async () => {
        let selection = practiceList.find(
            practice =>
                practice.zsrNr === tempPractice.replace('_HMO', '').replace('_HAS', '') && practice[selectedModel]
        );
        if (selection) {
            selection = getPracticeToShow(selection, selectedModel);
            let newSelectedPractices = { ...selectedPractices };
            newSelectedPractices[identifier] = selection;

            setSelectedPractices(newSelectedPractices);
            setSavedPractice(selection);

            if (isDoctorChangeOnly) {
                setFormState(FormStateEnum.start);
                setPracticeListLoading(true);
                const attributes = submission?.attributes;
                API_PUBLISH_SUBMISSION(submissionId, attributes, submission)
                    .then(response => {
                        setFormState(FormStateEnum.success);
                        setPracticeListLoading(false);
                    })
                    .catch(error => {
                        setFormState(FormStateEnum.error);
                        log.error('error', error);
                        setPracticeListLoading(false);
                    });
            } else {
                setIsDocModalOpen(false);
            }
        }
    };

    return (
        <>
            {preselectedPractice ? (
                <SelectionRow
                    identifier={identifier}
                    practice={savedPractice}
                    selectedPractices={selectedPractices}
                    setSelectedPractices={setSelectedPractices}
                    toggleDocModal={toggleDocModal}
                    isActive={hasActiveStyles}
                />
            ) : (
                <StyledButtonWrapper>
                    <P2>{i18next.t('ProductForm.okp.practiceSearch')}</P2>
                    <Spacer space="epsilon" />
                    <Button
                        id={`PracticeSearchButton_${identifier}`}
                        type="button"
                        onClick={toggleDocModal}
                        iconBefore="plus_outlined"
                        buttonText={i18next.t('PracticeSearch.button.search')}
                        outlined
                    />
                </StyledButtonWrapper>
            )}
            {windowWidth && (
                <Modal
                    isOpen={isDocModalOpen}
                    onCloseRequest={toggleDocModal}
                    variant={isUp('medium', windowWidth) ? 'center' : 'full'}
                    customStyles={() => modalStyles(formState)}
                    closeOnOverlayClick={!isDoctorChangeOnly}
                    closeOnEsc={!isDoctorChangeOnly}
                >
                    {formState === FormStateEnum.start && (
                        <>
                            <StyledTitle>
                                {selectedModel === 'HMO'
                                    ? i18next.t('PracticeSearch.title.hmo')
                                    : i18next.t('PracticeSearch.title.has')}
                            </StyledTitle>
                            <SearchFields
                                zipValue={zipValue}
                                setZipValue={setZipValue}
                                requestCity={requestCity}
                                setRequestCity={setRequestCity}
                                nameValue={nameValue}
                                setNameValue={setNameValue}
                                radiusValue={radiusValue}
                                setRadiusValue={setRadiusValue}
                            />
                            <Spacer space="hecto" />

                            <StyledTooltip
                                placement="bottom"
                                tooltip={
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: sanitizeDOM(i18next.t('PracticeSearch.tooltip.text')),
                                        }}
                                    />
                                }
                                style={{ display: 'block' }}
                            >
                                <StyledTooltipContent>
                                    <Icon iconName="info_bubble" />
                                    <>{i18next.t('PracticeSearch.tooltip.title')}</>
                                </StyledTooltipContent>
                            </StyledTooltip>

                            <StyledMapWrapper>
                                {isUp('medium', windowWidth) &&
                                    (hasCookieConsent ? (
                                        <MapView
                                            selectedModel={selectedModel}
                                            setTempPractice={setTempPractice}
                                            filteredPracticeList={filteredPracticeList}
                                            zip={zipValue}
                                            city={requestCity}
                                        />
                                    ) : (
                                        <StyledConsentBox
                                            background="white"
                                            display="flex"
                                            flexDirection="column"
                                            justifyContent="center"
                                            padding="zeta"
                                        >
                                            <StyledConsentText>
                                                {i18next.t('PracticeSearch.map.consent.text')}
                                            </StyledConsentText>
                                            <Spacer space="zeta" />
                                            <Box alignItems="center" textAlign="center">
                                                <Button onClick={openCookieConsentWindow}>
                                                    {i18next.t('PracticeSearch.map.consent.button')}
                                                </Button>
                                            </Box>
                                        </StyledConsentBox>
                                    ))}
                                <PracticeList
                                    selectedModel={selectedModel}
                                    tempPractice={tempPractice}
                                    setTempPractice={setTempPractice}
                                    practiceListLoading={practiceListLoading}
                                    filteredPracticeList={filteredPracticeList}
                                    preselectedPractice={preselectedPractice}
                                />
                            </StyledMapWrapper>

                            <StyledButtonRowFixed>
                                <Button
                                    onClick={toggleDocModal}
                                    buttonText={i18next.t('PracticeSearch.cancel')}
                                    outlined
                                />
                                <Button
                                    onClick={handleSaveClick}
                                    buttonText={i18next.t('PracticeSearch.save')}
                                    disabled={!tempPractice || tempPractice === preselectedPractice}
                                />
                            </StyledButtonRowFixed>
                            <StyledButtonRowFixedPlaceholder />
                        </>
                    )}
                    {formState === FormStateEnum.success && (
                        <>
                            <Spacer space="tera" />
                            <Alert
                                variant="modal"
                                icon={{
                                    name: 'check_outlined',
                                    color: 'success',
                                }}
                                text={i18next.t(
                                    `PracticeSearch.doctorChangeOnly.success.text`,
                                    'Ihr Arzt wurde erfolgreich geändert zu:',
                                    { date: moment(insuranceStart).format(PRESENTATION_DATE_FORMAT) }
                                )}
                            >
                                <P2>{savedPractice.provider}</P2>
                                <P2>{savedPractice.strasse}</P2>
                                <P2>
                                    {savedPractice.plz} {savedPractice.ort}
                                </P2>
                                <Spacer space="kilo" />
                                <StyledButtonRow>
                                    <Button
                                        onClick={() => toggleDocModal()}
                                        buttonText={i18next.t(
                                            `PracticeSearch.doctorChangeOnly.success.button`,
                                            'Schliessen'
                                        )}
                                    />
                                </StyledButtonRow>
                            </Alert>
                        </>
                    )}
                    {formState === FormStateEnum.error && (
                        <Alert
                            variant="modal"
                            icon={{
                                name: 'cross_outlined',
                                color: 'error',
                            }}
                            title={i18next.t(
                                `PracticeSearch.doctorChangeOnly.error.title`,
                                'Ein Fehler ist aufgetreten'
                            )}
                        >
                            <StyledButtonRow>
                                <Button
                                    outlined
                                    onClick={() => toggleDocModal()}
                                    buttonText={i18next.t(
                                        `PracticeSearch.doctorChangeOnly.error.button.cancel`,
                                        'Abbrechen'
                                    )}
                                />
                                <Button
                                    onClick={() => handleSaveClick()}
                                    buttonText={i18next.t(
                                        `PracticeSearch.doctorChangeOnly.error.button.retry`,
                                        'Erneut versuchen'
                                    )}
                                />
                            </StyledButtonRow>
                        </Alert>
                    )}
                </Modal>
            )}
        </>
    );
};

PracticeSearch.defaultProps = {
    hasActiveStyles: false,
    initialRadiusVal: 10,
};

PracticeSearch.propTypes = {
    hasActiveStyles: bool,
    identifier: string.isRequired,
    initialRadiusVal: number,
    insuranceStart: string.isRequired,
    model: string.isRequired,
    municipalityId: number.isRequired,
    place: string.isRequired,
    selectedPractice: object.isRequired,
    selectedPractices: object.isRequired,
    setSelectedPractices: func.isRequired,
    userFlow: string.isRequired,
    zip: string.isRequired,
};

export default PracticeSearch;
