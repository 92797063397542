import styled from '@emotion/styled';
import { colors, shadows } from 'atupri-component-library/lib/core/styles/theme/default';

export const StyledWrapper = styled.div(({ isSticky, borderTop = 1, borderBottom = 1 }) => {
    let styles = [
        `
    z-index: 11;
    position: absolute;
    width: 100vw;
    background-color: ${colors.white};
    box-shadow: ${shadows.ctaBlueHover};
  `,
    ];

    const stickyStyles = `
    position: fixed;
    top: 0;
    border: 0;
  `;

    const borderTopStyles = `
    border-top: 1px solid ${colors.grey300};
  `;

    const borderBottomStyles = `
    border-bottom: 1px solid ${colors.grey300};
  `;

    if (borderTop) {
        styles.push(borderTopStyles);
    }

    if (borderBottom) {
        styles.push(borderBottomStyles);
    }

    if (isSticky) {
        styles.push(stickyStyles);
    }

    return styles;
});
