import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styled from '@emotion/styled';
import { rem } from 'polished';
import { text } from 'atupri-component-library/lib/core/styles/theme/default';

const StyledP3 = styled.p`
    ${text.p3};
    color: red; // ToDo
    font-size: ${rem(12)};
    font-weight: 300;
    padding-top: ${rem(4)};
`;

const ValidationError = props => {
    const { rootClassName, className, fieldMeta } = props;
    const { touched, error } = fieldMeta;
    const classes = classNames(rootClassName || 'validationRoot', className);
    return touched && error ? <StyledP3 className={classes}>{error}</StyledP3> : null;
};

ValidationError.defaultProps = { rootClassName: null, className: null };

const { shape, bool, string } = PropTypes;

ValidationError.propTypes = {
    rootClassName: string,
    className: string,
    fieldMeta: shape({
        touched: bool.isRequired,
        error: string,
    }).isRequired,
};

export default ValidationError;
