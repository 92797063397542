import React, { useEffect } from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Routes as AppRoutes, Route, useLocation, useParams } from 'react-router-dom';
import { NotFoundPage } from './containers';
import { locationChanged } from '@src/ducks/Routing.duck';
import { propTypes } from '@src/util/types';
import { canonicalRoutePath } from '@src/util/routes';
import routeConfiguration from '@src/routeConfiguration';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import { localizeUrl } from '@src/util/staticUrl';
import { log } from '@src/middleware';

const { arrayOf } = PropTypes;

const callLoadData = ({ params, location, route, dispatch }) => {
    const { loadData } = route;
    const shouldLoadData = typeof loadData === 'function';
    if (shouldLoadData) {
        dispatch(loadData(params, location.search)).catch(e => {
            log.error('error loading data', e);
        });
    }
};

const setPageScrollPosition = location => {
    if (!location.hash) {
        // No hash, scroll to top
        window.scroll({
            top: 0,
            left: 0,
        });
    } else {
        const el = document.querySelector(location.hash);
        if (el) {
            el.scrollIntoView({
                block: 'start',
                behavior: 'smooth',
            });
        }
    }
};

const RouteComponentRenderer = ({ route }) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const params = useParams();
    const sendDataToGTM = useGTMDispatch();

    useEffect(() => {
        callLoadData({
            params,
            location,
            route,
            dispatch,
        });
        handleLocationChanged(location, sendDataToGTM);
    });

    const handleLocationChanged = (location, sendDataToGTM) => {
        setPageScrollPosition(location);
        const url = canonicalRoutePath(routeConfiguration(), location, true);
        const langInvariantURL = localizeUrl('de', url);
        sendDataToGTM({
            event: 'virtual_pageview',
            virtualPagePath: url,
            langInvariantURL: langInvariantURL,
            pageLanguage: i18next.language,
            productCategory: 'praemienrechner',
        });
        dispatch(locationChanged(location, url));
    };

    const { component: RouteComponent } = route;

    return <RouteComponent params={params} location={location} />;
};

RouteComponentRenderer.propTypes = {
    route: propTypes.route.isRequired,
};

const Routes = ({ routes }) => {
    const toRouteComponent = route => {
        // By default, our routes are exact.
        // https://reacttraining.com/react-router/web/api/Route/exact-bool
        const isExact = route.exact != null ? route.exact : true;
        return (
            <Route
                key={route.name}
                path={route.path}
                exact={isExact}
                element={<RouteComponentRenderer route={route} />}
            />
        );
    };

    return (
        <AppRoutes>
            {routes.map(toRouteComponent)}
            <Route path="*" element={<NotFoundPage />} />
        </AppRoutes>
    );
};

Routes.propTypes = {
    routes: arrayOf(propTypes.route).isRequired,
};

export default Routes;
