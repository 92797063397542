import React from 'react';
import { bool, object } from 'prop-types';
import i18next from 'i18next';
import moment from 'moment/moment';

import { Field } from 'react-final-form';

// components
import { FieldZipAutocomplete } from '@src/components';
import { DropdownMenu, Input } from '@atupri/atupri-component-library';

// utils
import { finalFormFieldDateFormatAttributes } from '@src/util/date';
import { composeValidators, maxDate, maxLength, minDate, minLength, required, validDate } from '@src/util/validators';

const BaseForm = ({ inverted = false }) => {
    return (
        <div className="flex flex-col gap-3 lg:gap-5">
            <div>
                <Field
                    id="quickCalculatorFormGender"
                    name="gender"
                    label={i18next.t('QuickCalculatorForm.gender')}
                    options={[
                        {
                            label: i18next.t('QuickCalculatorForm.genderOptions.f'),
                            value: 'f',
                        },
                        {
                            label: i18next.t('QuickCalculatorForm.genderOptions.m'),
                            value: 'm',
                        },
                    ]}
                >
                    {fieldProps => (
                        <DropdownMenu
                            id={fieldProps.input.id}
                            name={fieldProps.input.name}
                            value={fieldProps.input.value}
                            label={fieldProps.label}
                            options={fieldProps.options}
                            variant={inverted ? 'inverted' : 'default'}
                            onChange={value => fieldProps.input.onChange(value)}
                            onBlur={e => fieldProps.input.onBlur(e)}
                            errorText={fieldProps.meta.touched && fieldProps.meta.error}
                        />
                    )}
                </Field>
            </div>
            <div>
                <Field
                    id="quickCalculatorFormBirthdate"
                    name="birthdate"
                    label={i18next.t('QuickCalculatorForm.birthdate')}
                    validate={composeValidators(
                        required(i18next.t('QuickCalculatorForm.birthdateRequired')),
                        validDate(i18next.t('QuickCalculatorForm.birthdateRequired')),
                        minDate(i18next.t('QuickCalculatorForm.birthdateTooSmall'), '1900-01-01'),
                        maxDate(i18next.t('QuickCalculatorForm.birthdateTooBig'), moment())
                    )}
                    {...finalFormFieldDateFormatAttributes}
                >
                    {fieldProps => {
                        return (
                            <Input
                                id={fieldProps.input.id}
                                name={fieldProps.input.name}
                                value={fieldProps.input.value}
                                label={fieldProps.label}
                                variant={inverted ? 'inverted' : 'default'}
                                maskObject={{
                                    mask: '__.__.____',
                                    replacement: {
                                        _: /\d/,
                                    },
                                    showMask: true,
                                }}
                                onChange={value => fieldProps.input.onChange(value)}
                                onBlur={e => fieldProps.input.onBlur(e)}
                                errorText={fieldProps.meta.touched && fieldProps.meta.error}
                            />
                        );
                    }}
                </Field>
            </div>
            <div>
                <FieldZipAutocomplete
                    name="zip"
                    label={i18next.t('QuickCalculatorForm.zip')}
                    validate={{
                        zip: composeValidators(
                            required(i18next.t('QuickCalculatorForm.zipPlaceRequired')),
                            minLength(i18next.t('QuickCalculatorForm.zipRequired'), 4),
                            maxLength(i18next.t('QuickCalculatorForm.zipRequired'), 4)
                        ),
                        place: required(i18next.t('QuickCalculatorForm.zipPlaceRequired')),
                    }}
                    isRequired={false}
                    variant="atrium"
                    inverted={inverted}
                />
            </div>
        </div>
    );
};

BaseForm.propTypes = {
    initialValues: object,
    inverted: bool,
};
export default BaseForm;
