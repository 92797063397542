import React, { useState } from 'react';
import { bool, node, string } from 'prop-types';
import classNames from 'classnames';
import { useForm, Field } from 'react-final-form';
import { sanitizeDOM } from '@src/util/sanitize';

import {
    StyledTextWrap,
    StyledCheckboxRoot,
    StyledLabelElement,
    StyledLabel,
    StyledLabelBig,
    StyledAdditionalText,
    StyledCheckbox,
    StyledCheckboxRootNested,
} from './FieldCheckbox.style';

const IconCheckbox = props => {
    const { className, checkedClassName, boxClassName, isListing, disabled } = props;
    return (
        <StyledCheckbox className={boxClassName} isListing={isListing} style={{ opacity: disabled ? '0.2' : '1' }}>
            <svg className={className} width="15" height="15" xmlns="http://www.w3.org/2000/svg">
                <path
                    className={checkedClassName || 'checked'}
                    d="M4.707 8.293L1.414 5L0 6.414L4.707 11.121L14.414 1.414L13 0L4.707 8.293Z"
                    fill="white"
                />
            </svg>
        </StyledCheckbox>
    );
};

IconCheckbox.defaultProps = { className: null, checkedClassName: null, boxClassName: null, isListing: null };

IconCheckbox.propTypes = { className: string, checkedClassName: string, boxClassName: string, isListing: bool };

const FieldCheckbox = props => {
    const {
        rootClassName,
        className,
        svgClassName,
        id,
        name,
        label,
        useSuccessColor,
        text,
        nested,
        isListing,
        disabled,
        value,
    } = props;

    const classes = classNames(rootClassName || 'checkboxRoot', className);
    const form = useForm();
    const values = form.getState().values;
    const [nestedAccept, setNestedAccept] = useState(false);

    const checkboxProps = {
        id,
        name,
        className: 'inputField',
        component: 'input',
        type: 'checkbox',
        disabled: nested ? disabled || !nestedAccept : disabled,
        value: nested ? (nestedAccept ? value : false) : value,
    };

    const onNestedCheckboxChange = e => {
        const oldValue = values[name];
        setNestedAccept(!nestedAccept);

        if (e.target.checked && !values[name].includes(value)) {
            form.mutators.setValue(name, [...oldValue, value]);
        } else if (!e.target.checked && values[name].includes(value)) {
            form.mutators.setValue(
                name,
                oldValue.filter(v => v !== value)
            );
        }
    };

    const checkboxPropsNested = {
        id: `${id}-nested`,
        className: 'inputField',
        type: 'checkbox',
        disabled,
        value,
        name: `${id}-nested`,
        onChange: e => onNestedCheckboxChange(e),
    };

    const idNested = `${id}-nested`;

    const successColorVariantMaybe = useSuccessColor
        ? {
              checkedClassName: 'checkedSuccess',
              boxClassName: 'cssboxSuccess',
          }
        : {};

    return (
        <StyledCheckboxRoot className={classes} isListing={isListing}>
            <Field {...checkboxProps} />
            <StyledLabelElement isListing={isListing} className={'checkboxWrap'}>
                <IconCheckbox
                    className={svgClassName}
                    boxClassName={'checkboxIcon'}
                    disabled={nested ? disabled || !nestedAccept : disabled}
                    isListing={isListing}
                    {...successColorVariantMaybe}
                />
                <StyledTextWrap className={'checkboxText'}>
                    {isListing ? (
                        <StyledLabelBig>
                            <label htmlFor={id}>{label}</label>
                        </StyledLabelBig>
                    ) : (
                        <StyledLabel htmlFor={id} bold={!!text}>
                            {label}
                        </StyledLabel>
                    )}
                    {text && !nested ? (
                        <StyledAdditionalText>
                            <div dangerouslySetInnerHTML={{ __html: sanitizeDOM(text) }} />
                        </StyledAdditionalText>
                    ) : null}
                    {nested ? (
                        <StyledAdditionalText>
                            <StyledCheckboxRootNested>
                                <input {...checkboxPropsNested} />
                                <StyledLabelElement isListing={isListing} className={'checkboxWrap'}>
                                    <IconCheckbox
                                        className={svgClassName}
                                        boxClassName={'checkboxIcon'}
                                        disabled={disabled}
                                        isListing={isListing}
                                        {...successColorVariantMaybe}
                                    />
                                    <StyledTextWrap className={'checkboxText'}>
                                        <StyledLabel
                                            htmlFor={idNested}
                                            bold={false}
                                            dangerouslySetInnerHTML={{ __html: sanitizeDOM(text) }}
                                        />
                                    </StyledTextWrap>
                                </StyledLabelElement>
                            </StyledCheckboxRootNested>
                        </StyledAdditionalText>
                    ) : null}
                </StyledTextWrap>
            </StyledLabelElement>
        </StyledCheckboxRoot>
    );
};

FieldCheckbox.defaultProps = {
    className: null,
    rootClassName: null,
    svgClassName: null,
    textClassName: null,
    label: null,
    nested: false,
};

FieldCheckbox.propTypes = {
    className: string,
    rootClassName: string,
    svgClassName: string,
    textClassName: string,
    id: string.isRequired,
    label: node,
    name: string.isRequired,
    value: string.isRequired,
    nested: bool,
};

export default FieldCheckbox;
