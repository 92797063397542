import React from 'react';
import PropTypes from 'prop-types';

const ExternalLink = props => {
    const { children, target, ...rest } = props;
    return (
        <a {...rest} target={target || '_blank'} rel="noopener noreferrer">
            {children}
        </a>
    );
};

ExternalLink.defaultProps = { children: null };

const { node } = PropTypes;

ExternalLink.propTypes = { children: node };

export default ExternalLink;
