import styled from '@emotion/styled';
import { rem } from 'polished';
import { mixins } from 'atupri-component-library/lib/core/styles/theme/default';
import { H2 } from 'atupri-component-library/lib/primitives';
import { Container, GridItem } from 'atupri-component-library/lib/helpers';

export const StyledContainer = styled(Container)`
    width: 100%;
`;

export const StyledH1 = styled.h1`
    margin-bottom: 0;
    text-align: center;

    ${mixins.responsiveStyles({
        'font-size': { medium: rem(72), large: rem(120) },
        'padding-bottom': { medium: rem(32), large: rem(32) },
        'padding-top': { zero: rem(42), medium: rem(0) },
    })}
`;

export const StyledTitle = styled(H2)`
    padding-bottom: ${rem(16)};
    text-align: center;
`;

export const StyledText = styled.p`
    padding-bottom: ${rem(64)};
    text-align: center;
`;

export const StyledGridItem = styled(GridItem)`
    padding-bottom: ${rem(24)};
`;
