import React from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import { pathByRouteName } from '@src/util/routes';

const NamedRedirect = ({ name, params = {} }) => {
    const pathname = pathByRouteName(name, params);
    return <Navigate to={{ pathname }} replace />;
};

const { object, string } = PropTypes;

NamedRedirect.propTypes = {
    name: string.isRequired,
    params: object,
};

export default NamedRedirect;
