import log from '@src/middleware/loglevel';
import { getLoggedInUserId } from '@src/config/myAtupriApi';
import { API_GET_OKP_TARIFFS, API_GET_UTI_TARIFFS, API_GET_VVG_TARIFFS } from '@src/api/tariff.api';
import userService from '@src/api/myAtupriUserService.api';
import { getPerson } from '@src/util/submission';
import { deepMerge } from '@src/util/data';
import { showSubmissionError, requestUpdateSubmission } from '@src/ducks/submission.duck';

const requestAction = actionType => params => ({ type: actionType, payload: { params } });
const successAction = actionType => result => ({ type: actionType, payload: result });
const errorAction = actionType => error => ({ type: actionType, payload: error, error: true });

const TAB_PRODUCT = 'product';
const TAB_CONFIRMATION = 'confirmation';

// ================ Action types ================ //

export const FETCH_OKP_TARIFF_REQUEST = 'app/FETCH_OKP_TARIFF_REQUEST';
export const FETCH_OKP_TARIFF_SUCCESS = 'app/FETCH_OKP_TARIFF_SUCCESS';
export const FETCH_OKP_TARIFF_ERROR = 'app/FETCH_OKP_TARIFF_ERROR';

export const FETCH_VVG_TARIFF_REQUEST = 'app/FETCH_VVG_TARIFF_REQUEST';
export const FETCH_VVG_TARIFF_SUCCESS = 'app/FETCH_VVG_TARIFF_SUCCESS';
export const FETCH_VVG_TARIFF_ERROR = 'app/FETCH_VVG_TARIFF_ERROR';

export const FETCH_UTI_TARIFF_REQUEST = 'app/FETCH_UTI_TARIFF_REQUEST';
export const FETCH_UTI_TARIFF_SUCCESS = 'app/FETCH_UTI_TARIFF_SUCCESS';
export const FETCH_UTI_TARIFF_ERROR = 'app/FETCH_UTI_TARIFF_ERROR';

export const FETCH_CURRENT_PRODUCTS_REQUEST = 'app/myAtupri/FETCH_CURRENT_PRODUCTS_REQUEST';
export const FETCH_CURRENT_PRODUCTS_SUCCESS = 'app/myAtupri/FETCH_CURRENT_PRODUCTS_SUCCESS';
export const FETCH_CURRENT_PRODUCTS_ERROR = 'app/myAtupri/FETCH_CURRENT_PRODUCTS_ERROR';

export const FETCH_EFFECTIVE_DATES_REQUEST = 'app/myAtupri/FETCH_EFFECTIVE_DATE_REQUEST';
export const FETCH_EFFECTIVE_DATES_SUCCESS = 'app/myAtupri/FETCH_EFFECTIVE_DATE_SUCCESS';
export const FETCH_EFFECTIVE_DATES_ERROR = 'app/myAtupri/FETCH_EFFECTIVE_DATE_ERROR';

// ================ Reducer ================ //

const initialState = {
    blockInterface: false,
    fetchTariffInProgress: false,
    fetchOkpTariffInProgress: false,
    fetchVvgTariffInProgress: false,
    fetchUtiTariffInProgress: false,
    fetchCurrentProductsInProgress: false,
    fetchOkpTariffError: null,
    fetchVvgTariffError: null,
    fetchUtiTariffError: null,
    okpTariffs: null,
    vvgTariffs: null,
    utiTariffs: null,
    loggedInUser: getLoggedInUserId(),
    currentProducts: null,
};

export default function apiDataReducer(state = initialState, action = {}) {
    const { type, payload } = action;

    switch (type) {
        case FETCH_OKP_TARIFF_REQUEST:
            return {
                ...state,
                blockInterface: !!payload.params.blockInterface,
                fetchOkpTariffInProgress: true,
                fetchOkpTariffError: null,
                fetchTariffInProgress: !!payload.params.showLoader,
            };
        case FETCH_OKP_TARIFF_SUCCESS:
            return {
                ...state,
                blockInterface:
                    !!payload.blockInterface && (state.fetchVvgTariffInProgress || state.fetchUtiTariffInProgress),
                fetchOkpTariffInProgress: false,
                fetchOkpTariffError: null,
                fetchTariffInProgress:
                    !!payload.showLoader && (state.fetchVvgTariffInProgress || state.fetchUtiTariffInProgress),
                okpTariffs: payload.okpTariffs,
            };
        case FETCH_OKP_TARIFF_ERROR:
            return {
                ...state,
                blockInterface: false,
                fetchOkpTariffInProgress: true,
                fetchOkpTariffError: payload,
                fetchTariffInProgress: !!payload.showLoader,
            };

        case FETCH_VVG_TARIFF_REQUEST:
            return {
                ...state,
                blockInterface: !!payload.params.blockInterface,
                fetchVvgTariffInProgress: true,
                fetchVvgTariffError: null,
                fetchTariffInProgress: !!payload.params.showLoader,
            };
        case FETCH_VVG_TARIFF_SUCCESS:
            return {
                ...state,
                blockInterface:
                    !!payload.blockInterface && (state.fetchOkpTariffInProgress || state.fetchUtiTariffInProgress),
                fetchVvgTariffInProgress: false,
                fetchVvgTariffError: null,
                fetchTariffInProgress:
                    !!payload.showLoader && (state.fetchOkpTariffInProgress || state.fetchUtiTariffInProgress),
                vvgTariffs: payload.vvgTariffs,
            };
        case FETCH_VVG_TARIFF_ERROR:
            return {
                ...state,
                blockInterface: false,
                fetchVvgTariffInProgress: true,
                fetchVvgTariffError: payload,
                fetchTariffInProgress: !!payload.showLoader,
            };

        case FETCH_UTI_TARIFF_REQUEST:
            return {
                ...state,
                blockInterface: !!payload.params.blockInterface,
                fetchUtiTariffInProgress: true,
                fetchUtiTariffError: null,
                fetchTariffInProgress: !!payload.params.showLoader,
            };
        case FETCH_UTI_TARIFF_SUCCESS:
            return {
                ...state,
                blockInterface:
                    !!payload.blockInterface && (state.fetchOkpTariffInProgress || state.fetchVvgTariffInProgress),
                fetchUtiTariffInProgress: false,
                fetchUtiTariffError: null,
                fetchTariffInProgress:
                    !!payload.showLoader && (state.fetchOkpTariffInProgress || state.fetchVvgTariffInProgress),
                utiTariffs: payload.utiTariffs,
            };
        case FETCH_UTI_TARIFF_ERROR:
            return {
                ...state,
                blockInterface: false,
                fetchUtiTariffInProgress: true,
                fetchUtiTariffError: payload,
                fetchTariffInProgress: !!payload.showLoader,
            };
        case FETCH_CURRENT_PRODUCTS_REQUEST:
            return {
                ...state,
                fetchCurrentProductsInProgress: true,
            };
        case FETCH_CURRENT_PRODUCTS_SUCCESS:
            return {
                ...state,
                fetchCurrentProductsInProgress: false,
                currentProducts: payload,
            };
        case FETCH_EFFECTIVE_DATES_REQUEST:
            return {
                ...state,
            };
        case FETCH_EFFECTIVE_DATES_SUCCESS:
            return {
                ...state,
                effectiveDates: payload,
            };
        case FETCH_EFFECTIVE_DATES_ERROR:
            return {
                ...state,
                fetchEffectiveDatesError: payload,
            };
        default:
            return state;
    }
}

// ================ Action creators ================ //

export const fetchOkpTariff = requestAction(FETCH_OKP_TARIFF_REQUEST);
export const fetchOkpTariffSuccess = successAction(FETCH_OKP_TARIFF_SUCCESS);
export const fetchOkpTariffError = errorAction(FETCH_OKP_TARIFF_ERROR);

export const fetchVvgTariff = requestAction(FETCH_VVG_TARIFF_REQUEST);
export const fetchVvgTariffSuccess = successAction(FETCH_VVG_TARIFF_SUCCESS);
export const fetchVvgTariffError = errorAction(FETCH_VVG_TARIFF_ERROR);

export const fetchUtiTariff = requestAction(FETCH_UTI_TARIFF_REQUEST);
export const fetchUtiTariffSuccess = successAction(FETCH_UTI_TARIFF_SUCCESS);
export const fetchUtiTariffError = errorAction(FETCH_UTI_TARIFF_ERROR);

export const fetchCurrentProducts = requestAction(FETCH_CURRENT_PRODUCTS_REQUEST);
export const fetchCurrentProductsSuccess = successAction(FETCH_CURRENT_PRODUCTS_SUCCESS);
export const fetchCurrentProductsError = errorAction(FETCH_CURRENT_PRODUCTS_ERROR);

export const fetchEffectiveDates = requestAction(FETCH_EFFECTIVE_DATES_REQUEST);
export const fetchEffectiveDatesSuccess = successAction(FETCH_EFFECTIVE_DATES_SUCCESS);
export const fetchEffectiveDatesError = errorAction(FETCH_EFFECTIVE_DATES_ERROR);

// ================ Thunk ================ //

export function requestFetchTariff(actionPayload) {
    return async (dispatch, getState) => {
        let promises = [];
        const { submissionId, personId, okp = false, vvg = false, uti = false } = actionPayload;
        let submission = getState().premcalcData.entities.ownSubmission[submissionId];
        const persons = submission.relationships.persons;

        if (okp) {
            dispatch(fetchOkpTariff(actionPayload));
            promises.push(
                API_GET_OKP_TARIFFS(personId, persons, submission.attributes?.collectiveAgreementNumber)
                    .then(result => {
                        return result;
                    })
                    .catch(e => {
                        dispatch(fetchOkpTariffError({ ...e, ...actionPayload }));
                    })
            );
        }

        if (vvg) {
            dispatch(fetchVvgTariff(actionPayload));

            const collectiveAgreementNumber = submission.attributes?.collectiveAgreementNumber;
            const personBasket = submission.relationships.basket[personId];
            let okpProduct = Array.isArray(personBasket.okp) && personBasket.okp[0];
            if (!okpProduct && submission?.relationships?.persons?.[personId]?.okpPreselectedProduct) {
                okpProduct = submission?.relationships?.persons?.[personId]?.okpPreselectedProduct;
            }
            promises.push(
                API_GET_VVG_TARIFFS(personId, persons, okpProduct?.product, collectiveAgreementNumber)
                    .then(async result => {
                        return result;
                    })
                    .catch(e => {
                        dispatch(fetchVvgTariffError({ ...e, ...actionPayload }));
                    })
            );
        }

        if (uti) {
            dispatch(fetchUtiTariff(actionPayload));

            promises.push(
                API_GET_UTI_TARIFFS(personId, persons, submission.attributes?.collectiveAgreementNumber)
                    .then(result => {
                        return result;
                    })
                    .catch(e => {
                        dispatch(fetchUtiTariffError({ ...e, ...actionPayload }));
                    })
            );
        }

        return Promise.all(promises).then(data => {
            submission = getState().premcalcData.entities.ownSubmission[submissionId];
            data = deepMerge(data);

            if (data.okp) {
                dispatch(fetchOkpTariffSuccess({ okpTariffs: data.okp, ...actionPayload }));
            }
            if (data.vvg) {
                dispatch(fetchVvgTariffSuccess({ vvgTariffs: data.vvg, ...actionPayload }));
            }
            if (data.uti) {
                dispatch(fetchUtiTariffSuccess({ utiTariffs: data.uti, ...actionPayload }));
            }
        });
    };
}

const handleTabProduct = (dispatch, submission, params) => {
    const firstPerson = Array.isArray(submission?.relationships?.persons) && submission.relationships.persons[0];
    const personId = params.personId || firstPerson?.id;
    if (!personId) return false;

    const person = getPerson(submission, personId);
    if (!person) {
        dispatch(showSubmissionError({ message: 'personId is not valid' }));
        return false;
    }

    const fetchTarifPayload = {
        submissionId: submission.id.uuid,
        personId: person.id,
        okp: true,
        vvg: true,
        uti: params.subpanel === 'recommend' || getLoggedInUserId(),
        showLoader: true,
    };
    dispatch(requestFetchTariff(fetchTarifPayload));
    return true;
};

const handleTabConfirmation = (dispatch, submissionId) => {
    if (getLoggedInUserId()) {
        dispatch(requestEffectiveDates(submissionId));
    }
};

export const afterInitialDataLoaded = (dispatch, response, params) => {
    const submission = response?.[1]?.data;
    if (submission) {
        switch (params.tab) {
            case TAB_PRODUCT:
                handleTabProduct(dispatch, submission, params);
                break;
            case TAB_CONFIRMATION:
                handleTabConfirmation(dispatch, submission.id.uuid);
                break;
            default:
                break;
        }

        if (getLoggedInUserId()) {
            dispatch(requestCurrentProducts());
        }
    }

    return response;
};

export function requestCurrentProducts() {
    return (dispatch, getState) => {
        dispatch(fetchCurrentProducts({}));
        if (getState().apiData.loggedInUser) {
            // get subscribed products for all users
            userService
                .loadSubscribedProducts()
                .then(response => {
                    dispatch(fetchCurrentProductsSuccess(response.data));
                })
                .catch(e => {
                    dispatch(fetchCurrentProductsError(e));
                    log.debug('=== MYATUPRI_API_GET_SUBSCRIBED_PRODUCTS ===', {
                        e,
                    });
                });
        }
    };
}

export function requestEffectiveDates(submissionId) {
    return (dispatch, getState) => {
        dispatch(fetchEffectiveDates({}));
        let submission = getState().premcalcData.entities.ownSubmission[submissionId];
        let promises = [];

        submission?.relationships?.persons?.forEach(person => {
            const personBasket = submission.relationships.basket[person.id];
            const currentProduct = getState().apiData.currentProducts?.find(
                currentProduct => currentProduct.insuredNr === person.insuranceNumber
            );
            const currentObjectIdOkp = currentProduct?.products.find(product => product.code === 'OKP')?.tariffId;

            personBasket.okp?.forEach(product => {
                promises.push(
                    userService
                        .loadEffectiveDates(person.insuranceNumber, product.tariff, currentObjectIdOkp)
                        .then(response => {
                            // update the insuranceStart in the Redux state within the person object
                            submission.relationships.persons.find(
                                personObject => personObject.id === person.id
                            ).insuranceStart = response.data.validFrom;
                            requestUpdateSubmission(undefined, submission);

                            return {
                                personId: person.id,
                                insuranceNumber: person.insuranceNumber,
                                currentObjectId: currentObjectIdOkp,
                                newObjectId: product.tariff,
                                validFrom: response.data.validFrom,
                            };
                        })
                );
            });
            personBasket.vvg?.forEach(product => {
                promises.push(
                    userService.loadEffectiveDates(person.insuranceNumber, product.tariff).then(response => {
                        return {
                            personId: person.id,
                            insuranceNumber: person.insuranceNumber,
                            newObjectId: product.tariff,
                            validFrom: response.data.validFrom,
                        };
                    })
                );
            });
        });

        return Promise.all(promises)
            .then(effectiveDates => {
                dispatch(fetchEffectiveDatesSuccess(effectiveDates));
            })
            .catch(e => {
                dispatch(fetchEffectiveDatesError(e));
            });
    };
}
