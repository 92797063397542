import React, { useEffect, useRef } from 'react';
import { arrayOf, func, string } from 'prop-types';
import i18next from 'i18next';
import { Spacer } from 'atupri-component-library/lib/helpers';
import { Badge, P2 } from 'atupri-component-library/lib/primitives';

import {
    StyledEmphasizedToggle,
    StyledPracticeItemWrapper,
    StyledPracticeItem,
    StyledAddress,
    StyledBadgeDesc,
    StyledNoRegistration,
    StyledBadgeWrap,
    StyledPracticeItemPromotionBadge,
} from './PracticeItem.styles';

const getBadgeInfo = model => {
    const data = {
        icon: '',
        text: '',
    };

    switch (model) {
        case 'HMO':
            data.text = i18next.t('PracticeSearch.item.hmo');
            data.icon = 'home_health';
            return data;
        case 'HAS':
            data.text = i18next.t('PracticeSearch.item.has');
            data.icon = 'stethoscope';
            return data;
        default:
            break;
    }
};

const PracticeItem = props => {
    const {
        zsrNr,
        provider,
        strasse,
        plz,
        ort,
        phone,
        model,
        acceptCd,
        selectedModel,
        tempPractice,
        setTempPractice,
        availableModels,
        preselectedPractice,
    } = props;

    const itemRef = useRef();
    const acceptsNewPatients = acceptCd === 'ALL';
    const isPracticeActive = `${zsrNr}_${model}` === tempPractice;
    const isPreselectedPractice = `${zsrNr}_${model}` === preselectedPractice;

    /* Scroll PracticeItem into view if practice gets selected via google-maps marker */
    useEffect(() => {
        if (tempPractice && isPracticeActive && itemRef.current) {
            itemRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [tempPractice]);

    return (
        <StyledPracticeItemWrapper ref={itemRef}>
            {isPreselectedPractice && <Spacer space="zeta" />}
            <StyledEmphasizedToggle
                controlType="radio"
                value={`${zsrNr}_${model}`}
                alignItems="flex-start"
                flexDirection="column"
                paddingX="gamma"
                paddingY="gamma"
                controlSize={16}
                disabled={model !== selectedModel}
                onClick={e => {
                    setTempPractice(e.target.value);
                }}
                toggleTitleText={provider}
                ToggleTitleComponent={P2}
                {...props}
            >
                {isPreselectedPractice && (
                    <StyledPracticeItemPromotionBadge isCategory={true} className="promotion-badge">
                        {i18next.t(`ProductForm.currentSelection`)}
                    </StyledPracticeItemPromotionBadge>
                )}
                <Spacer space="beta" />
                <StyledPracticeItem>
                    <div>
                        <StyledAddress>
                            {strasse}
                            <br />
                            {plz} {ort}
                            <br />
                            {phone} <br />
                        </StyledAddress>
                        {!acceptsNewPatients && (
                            <StyledNoRegistration>
                                {i18next.t('PracticeSearch.item.noregistration')}
                            </StyledNoRegistration>
                        )}
                    </div>
                    <StyledBadgeWrap>
                        {availableModels.map(currentModel => {
                            const badgeInfo = getBadgeInfo(currentModel);
                            return (
                                <div key={currentModel}>
                                    <Badge icon={badgeInfo.icon} circleSize={36} contentSize={22} />
                                    <StyledBadgeDesc multiple={availableModels.length > 1}>
                                        {badgeInfo.text}
                                    </StyledBadgeDesc>
                                </div>
                            );
                        })}
                    </StyledBadgeWrap>
                </StyledPracticeItem>
            </StyledEmphasizedToggle>
        </StyledPracticeItemWrapper>
    );
};

PracticeItem.defaultProps = {
    phone: '',
    acceptCd: 'ALL',
    tempPractice: '',
};

PracticeItem.propTypes = {
    provider: string.isRequired,
    strasse: string.isRequired,
    plz: string.isRequired,
    ort: string.isRequired,
    phone: string,
    model: string.isRequired,
    acceptCd: string,
    selectedModel: string.isRequired,
    tempPractice: string,
    setTempPractice: func.isRequired,
    availableModels: arrayOf(string).isRequired,
    preselectedPractice: string,
};

export default PracticeItem;
