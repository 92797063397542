import styled from '@emotion/styled';
import { AccordionItemTrigger } from 'atupri-component-library/lib/components';
import { colors, mixins, text } from 'atupri-component-library/lib/core/styles/theme/default';
import { rem } from 'polished';
import { Icon, P3 } from 'atupri-component-library/lib/primitives';

export const StyledAccordionItemTrigger = styled(AccordionItemTrigger)(({ disabled }) => {
    let styles = [];

    if (disabled) {
        styles.push(`
      cursor: default;

      div > i {
        display: none;
      }
    `);
    }

    return styles;
});

export const StyledShowcase = styled.div`
    display: flex;

    ${mixins.responsiveStyles({
        'flex-direction': { zero: 'column', medium: 'row' },
    })}
`;

export const StyledShowcaseCol = styled.div`
    .img-showcase {
        ${mixins.responsiveStyles({
            width: { zero: '100%', medium: rem(280), large: rem(420), wide: rem(520) },
            'margin-top': { zero: `${rem(32)}`, medium: '0' },
            'padding-left': { zero: `0`, medium: `${rem(17)}` },
        })}
    }

    .not-mobile {
        ${mixins.responsiveStyles({
            display: { zero: 'none', medium: 'flex' },
        })}
    }

    .only-mobile {
        ${mixins.responsiveStyles({
            display: { zero: 'flex', medium: 'none' },
        })}
    }
`;

export const StyledBirthdate = styled.span`
    font-size: ${rem(16)};
`;

export const StyledShowcaseLabel = styled.div`
    ${text.p3};
    padding-bottom: ${rem(33)};
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: ${rem(48)};
`;

export const StyledH1LookedTitle = styled.span`
    line-height: 1.4;

    ${mixins.responsiveStyles({
        'font-size': { zero: rem(26), medium: rem(40) },
    })}
`;

export const StyledFinalSubmit = styled.div`
    display: flex;

    ${mixins.responsiveStyles({
        'flex-direction': { zero: 'column', medium: 'row' },
    })}
`;

export const StyledTextInfoBox = styled.div`
    padding-bottom: 28px;
    padding-top: 32px;
`;

export const StyledText = styled(P3)`
    a {
        color: ${colors.primary};
    }
`;

export const StyledTextStrong = styled.strong`
    ${text.p3};
`;

export const StyledFinalSubmitCol = styled.div(({ center }) => {
    let styles = [
        `
    display: flex;
    flex-direction: column;

    &:first-of-type {
      flex: 1;
      padding-right: ${rem(24)}
    }
  `,
    ];

    if (center) {
        styles.push(`
        align-items: center;
        flex: 0 1 auto;
        flex-direction: row;

        ${mixins.responsiveStyles({
            'justify-content': { zero: 'flex-start', medium: 'flex-end' },
            'padding-top': { zero: `${rem(32)}`, medium: '0' },
        })}
      `);
    }

    return styles;
});

export const StyledInfobox = styled.div`
    display: flex;
    align-items: center;
    background-color: #e4e7f5;

    ${mixins.responsiveStyles({
        padding: { zero: rem(12), medium: rem(48) },
    })}
`;

export const StyledInfoboxIcon = styled.div`
    ${mixins.responsiveStyles({
        display: { zero: 'none', medium: 'block' },
    })}
`;

export const StyledInfoboxContent = styled.div`
    ${text.p3};
`;

export const StyledInfoIcon = styled(Icon)`
    font-size: ${rem(48)};
    color: ${colors.black};
    margin-right: ${rem(35)};
`;
