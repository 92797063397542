import styled from '@emotion/styled';
import { rem } from 'polished';
import { mixins } from 'atupri-component-library/lib/core/styles/theme/default';

import { Container } from 'atupri-component-library/lib/helpers';

export const StyledContainer = styled(Container)`
    width: 100%;
`;

export const StyledMain = styled.main(({ complete, center }) => {
    const defaultStyles = [
        `
    flex: 1 0 auto;
    display: flex;
    position: relative;
  `,
    ];

    const centerStyles = [`align-items: center;`];

    let styles = [defaultStyles];

    if (!complete || center) {
        styles.push(centerStyles);
    }

    return styles;
});

export const StyledMainError = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    ${mixins.responsiveStyles({
        'padding-top': { zero: rem(81), medium: rem(102) },
    })}
`;
