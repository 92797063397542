import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import createReducer from './reducers';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import config from './config';

/**
 Add middleware and enhancers.
 */
export default function store(preloadedState = {}, endpoint = null) {
    const middlewares = [thunk.withExtraArgument(endpoint)];

    const persistConfig = {
        key: 'root',
        storage,
        whitelist: ['EditSubmissionPage', 'PersonPage'],
    };

    const reducer = persistReducer(persistConfig, createReducer());

    // enable Redux Devtools
    const composeEnhancers =
        (config.dev &&
            typeof window === 'object' &&
            window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__?.({ trace: true, traceLimit: 25 })) ||
        compose;

    const enhancers = composeEnhancers(applyMiddleware(...middlewares));

    const store = createStore(reducer, preloadedState, enhancers);
    let persistor = persistStore(store);

    return { store, persistor };
}
