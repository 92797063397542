import React from 'react';
import { func, string } from 'prop-types';
import i18next from 'i18next';
import classNames from 'classnames';
import { Form as FinalForm } from 'react-final-form';
import setFieldData from 'final-form-set-field-data';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import moment from 'moment';

// components
import {
    AutoSave,
    FieldBool,
    FieldEmphasizedToggle,
    FieldInput,
    FieldSelect,
    FieldZipAutocomplete,
    Form,
    RebateTeaser,
} from '@src/components';
import { Tooltip } from 'atupri-component-library/lib/components';
import { Button, H3, Icon, Input, P1 } from 'atupri-component-library/lib/primitives';
import { Grid, Spacer, Stack } from 'atupri-component-library/lib/helpers';

// util
import {
    BACKEND_DATE_FORMAT,
    convertDateToComputedFormat,
    getInsuranceStartDate,
    finalFormFieldDateFormatAttributes,
    isValidDate,
} from '@src/util/date';
import {
    checkMivitaQuestionRule,
    GENDER_UNBORN,
    GENDER_MALE,
    GENDER_FEMALE,
    GENDER_NONE_SELECTED,
} from '@src/util/person';
import createScrollToFirstErrorDecorator from '@src/util/finalForm/decorators/scrollToFirstError';
import createMivitaQuestionDecorator from '@src/util/finalForm/decorators/mivitaQuestion';
import { uuidv4 } from '@src/util/uuid';
import {
    composeValidators,
    maxDate,
    minLength,
    maxLength,
    minDate,
    onlyLetters,
    required,
    requiredFieldArrayCheckbox,
    validDate,
    validGender,
} from '@src/util/validators';

// style
import {
    StyledBox,
    StyledCtaBar,
    StyledCtaBarCol,
    StyledGridItem,
    StyledTooltipWrapper,
    StyledGenderFieldWrap,
} from './PersonForm.style';

const NAME_MAX_LENGTH = 20;

const scrollToFirstErrorDecorator = createScrollToFirstErrorDecorator();
const mivitaQuestionDecorator = createMivitaQuestionDecorator();

const insuranceStartDefault = getInsuranceStartDate();

const PersonForm = props => {
    const { initialValues, ...rest } = props;

    const renderUnbornGenderField = (gender, name) => {
        return (
            gender === GENDER_UNBORN && (
                <StyledGridItem shrink={1}>
                    <StyledGenderFieldWrap>
                        <FieldSelect
                            id="personFormExpectedGender"
                            name={`${name}.expectedGender`}
                            label={i18next.t('PersonForm.expectedGender')}
                            options={[
                                {
                                    label: i18next.t('PersonForm.expectedGenderOptions.f'),
                                    value: 'f',
                                },
                                {
                                    label: i18next.t('PersonForm.expectedGenderOptions.m'),
                                    value: 'm',
                                },
                            ]}
                            validate={composeValidators(required(i18next.t('PersonForm.expectedGenderRequired')))}
                        />
                    </StyledGenderFieldWrap>
                </StyledGridItem>
            )
        );
    };

    const renderGenderFields = (form, values, name, index) => {
        return (
            <Grid>
                <StyledGridItem shrink={1}>
                    <StyledGenderFieldWrap>
                        <FieldEmphasizedToggle
                            id={`${name}.gender_${GENDER_FEMALE}`}
                            name={`${name}.gender`}
                            label={i18next.t('PersonForm.genderOptions.f')}
                            icon="woman"
                            value={GENDER_FEMALE}
                            validate={composeValidators(
                                requiredFieldArrayCheckbox(i18next.t('PersonForm.genderRequired')),
                                validGender(i18next.t('PersonForm.genderRequired'))
                            )}
                        />
                    </StyledGenderFieldWrap>
                </StyledGridItem>
                <StyledGridItem shrink={1}>
                    <StyledGenderFieldWrap>
                        <FieldEmphasizedToggle
                            id={`${name}.gender_${GENDER_MALE}`}
                            name={`${name}.gender`}
                            label={i18next.t('PersonForm.genderOptions.m')}
                            icon="man"
                            value={GENDER_MALE}
                        />
                    </StyledGenderFieldWrap>
                </StyledGridItem>
                <StyledGridItem shrink={1}>
                    <StyledGenderFieldWrap>
                        <FieldEmphasizedToggle
                            id={`${name}.gender_${GENDER_UNBORN}`}
                            name={`${name}.gender`}
                            label={i18next.t('PersonForm.genderOptions.n')}
                            value={GENDER_UNBORN}
                            icon="embryo"
                            onChange={event => {
                                if (event.target.value === GENDER_UNBORN) {
                                    // check current date and sync insurance start
                                    let birthdateFormatted = '';
                                    const birthdate = values.persons[index].birthdate;
                                    const tomorrow = moment().add(1, 'days');

                                    // don't allow dates in the past if its a unborn
                                    if (birthdate && moment(birthdate, BACKEND_DATE_FORMAT, true).isBefore(tomorrow)) {
                                        birthdateFormatted = tomorrow.format(BACKEND_DATE_FORMAT);
                                    }

                                    // workaround because of validation issues. <OnChange> didn't help as well
                                    setTimeout(() => {
                                        form.mutators.setValue(`${name}.insuranceStart`, birthdateFormatted);
                                        form.mutators.setValue(`${name}.birthdate`, birthdateFormatted);
                                    }, 100);
                                }
                            }}
                        />
                    </StyledGenderFieldWrap>
                </StyledGridItem>

                {renderUnbornGenderField(values.persons[index].gender, name)}
            </Grid>
        );
    };

    const renderBirthdateField = (form, values, name, index) => {
        return (
            <FieldInput
                id="personFormBirthdate"
                name={`${name}.birthdate`}
                key={`${name}.birthdate.${values.persons[index].gender}`}
                label={i18next.t(
                    values.persons[index].gender === GENDER_UNBORN
                        ? 'PersonForm.expectedBirthdate'
                        : 'PersonForm.birthdate'
                )}
                validate={
                    values.persons[index].gender === GENDER_UNBORN
                        ? composeValidators(
                              required(i18next.t('PersonForm.expectedBirthdateRequired')),
                              validDate(i18next.t('PersonForm.expectedBirthdateRequired')),
                              minDate(i18next.t('PersonForm.expectedBirthdateTooSmall'), moment()),
                              maxDate(i18next.t('PersonForm.expectedBirthdateTooBig'), moment().add(36, 'weeks'))
                          )
                        : composeValidators(
                              required(i18next.t('PersonForm.birthdateRequired')),
                              validDate(i18next.t('PersonForm.birthdateRequired')),
                              minDate(i18next.t('PersonForm.birthdateTooSmall'), '1900-01-01'),
                              maxDate(i18next.t('PersonForm.birthdateTooBig'), moment())
                          )
                }
                mask="99.99.9999"
                onChange={event => {
                    if (values.persons[index].gender === GENDER_UNBORN) {
                        if (isValidDate(event.target.value)) {
                            form.mutators.setValue(
                                `${name}.insuranceStart`,
                                convertDateToComputedFormat(event.target.value)
                            );
                        }
                    }
                }}
                {...finalFormFieldDateFormatAttributes}
            />
        );
    };

    const renderInsuranceStartField = (values, name, index) => {
        const disabled = values.persons[index].gender === GENDER_UNBORN;
        return (
            <FieldInput
                id="personFormInsuranceStart"
                name={`${name}.insuranceStart`}
                label={i18next.t('PersonForm.insuranceStart')}
                defaultValue={insuranceStartDefault}
                validate={composeValidators(
                    required(i18next.t('PersonForm.insuranceStartRequired')),
                    validDate(i18next.t('PersonForm.insuranceStartRequired')),
                    minDate(i18next.t('PersonForm.insuranceStartTooSmall'), moment().subtract(3, 'months')),
                    maxDate(i18next.t('PersonForm.insuranceStartTooBig'), moment().add(1, 'years').endOf('year'))
                )}
                disabled={disabled}
                mask="99.99.9999"
                {...finalFormFieldDateFormatAttributes}
            />
        );
    };

    const renderPerson = (form, values, name, index, personCount) => {
        return (
            <StyledBox key={values?.persons[index]?.id} borderRadius={8} shadow="ctaBlueHover">
                <Grid columns={{ zero: 1, medium: values.persons[index].gender === GENDER_UNBORN ? 4 : 3 }}>
                    <StyledGridItem size={{ zero: 'full' }} verticalGutter={0}>
                        {renderGenderFields(form, values, name, index)}
                    </StyledGridItem>
                </Grid>
                <Grid columns={{ zero: 1, medium: 3 }}>
                    <StyledGridItem>
                        <FieldInput
                            id="personFormFirstname"
                            name={`${name}.firstname`}
                            label={i18next.t('PersonForm.firstname')}
                            validate={composeValidators(
                                maxLength(
                                    i18next.t('PersonForm.maxLength', {
                                        maxLength: NAME_MAX_LENGTH,
                                    }),
                                    NAME_MAX_LENGTH
                                ),
                                onlyLetters(i18next.t('PersonForm.onlyLetters'))
                            )}
                        />
                    </StyledGridItem>
                    <StyledGridItem>
                        <FieldInput
                            id="personFormLastname"
                            name={`${name}.lastname`}
                            label={i18next.t('PersonForm.lastname')}
                            validate={composeValidators(
                                maxLength(
                                    i18next.t('PersonForm.maxLength', {
                                        maxLength: NAME_MAX_LENGTH,
                                    }),
                                    NAME_MAX_LENGTH
                                ),
                                onlyLetters(i18next.t('PersonForm.onlyLetters'))
                            )}
                        />
                    </StyledGridItem>
                    <StyledGridItem>{renderBirthdateField(form, values, name, index)}</StyledGridItem>
                    <StyledGridItem>
                        {index === 0 ? (
                            <FieldZipAutocomplete
                                name={`${name}`}
                                label={i18next.t('PersonForm.zip')}
                                disabled={index > 0}
                                defaultValue={values?.persons[0]?.zipText || ''}
                                validate={{
                                    zip: composeValidators(
                                        minLength(i18next.t('PersonForm.zipRequired'), 4),
                                        maxLength(i18next.t('PersonForm.zipRequired'), 4),
                                        required(i18next.t('PersonForm.zipPlaceRequired'))
                                    ),
                                    place: required(i18next.t('PersonForm.zipPlaceRequired')),
                                }}
                            />
                        ) : (
                            <Input
                                id={`${name}.zipText`}
                                name={`${name}.zipText`}
                                label={i18next.t('PersonForm.zip')}
                                value={values?.persons[0]?.zipText || ''}
                                isRequired
                                disabled
                                inputRightIcon={<Icon iconName="lock" />}
                            />
                        )}
                    </StyledGridItem>
                    <StyledGridItem>{renderInsuranceStartField(values, name, index)}</StyledGridItem>
                    <StyledGridItem />
                </Grid>

                {personCount > 1 && (
                    <>
                        <Spacer space="deci" />
                        <Button
                            buttonText={i18next.t('PersonForm.removePerson')}
                            type="button"
                            iconBefore="trash"
                            isIncognito
                            onClick={() => {
                                form.mutators.remove('persons', index);
                            }}
                            outlined
                        />
                    </>
                )}
            </StyledBox>
        );
    };

    const renderRebateTeaser = persons => {
        return (
            checkMivitaQuestionRule(persons) && (
                <RebateTeaser>
                    <Stack space="regular">
                        <H3>{i18next.t('MivitaQuestion.teaser.title')}</H3>
                        <P1>{i18next.t('MivitaQuestion.teaser.text')}</P1>
                        <FieldBool
                            id="mivitaQuestion"
                            name="mivitaQuestion"
                            items={[
                                { value: 'yes', label: i18next.t('Form.yes') },
                                { value: 'no', label: i18next.t('Form.no') },
                            ]}
                        />
                        <StyledTooltipWrapper>
                            {i18next.t('MivitaQuestion.teaser.info')}
                            <span>
                                <Tooltip tooltip={i18next.t('MivitaQuestion.teaser.tooltip')} placement="top">
                                    <Icon iconName="info_bubble" iconColor="primary" title="Info" />
                                </Tooltip>
                            </span>
                        </StyledTooltipWrapper>
                    </Stack>
                </RebateTeaser>
            )
        );
    };

    return (
        <FinalForm
            {...rest}
            mutators={{
                ...arrayMutators,
                setFieldData,
                setValue: ([field, value], state, { changeValue }) => {
                    changeValue(state, field, () => value);
                },
                setValueArray: ([field, value], state, { changeValue }) => {
                    const fieldArrayParts = field.split('[0]');

                    state.formState.values[fieldArrayParts[0]].map((_p, index) => {
                        changeValue(state, fieldArrayParts[0] + '[' + index + ']' + fieldArrayParts[1], () => value);
                    });
                },
            }}
            decorators={[scrollToFirstErrorDecorator, mivitaQuestionDecorator]}
            initialValues={initialValues}
            render={({ form, ...formRenderProps }) => {
                let {
                    className,
                    handleSubmit,
                    values,
                    autosave,
                    disabled,
                    ready,
                    invalid,
                    pristine,
                    updated,
                    updateInProgress,
                } = formRenderProps;

                const classes = classNames('personsForm', className);

                const submitReady = (updated && pristine) || ready;
                const submitDisabled = disabled || updateInProgress;

                return (
                    <Form className={classes} onSubmit={handleSubmit}>
                        <AutoSave onSave={autosave} isFormValid={!invalid} />

                        <Stack space="giga">
                            <FieldArray name="persons">
                                {({ fields: persons }) => (
                                    <Stack space="giga">
                                        {persons.map((name, index) =>
                                            renderPerson(form, values, name, index, persons.length)
                                        )}

                                        {renderRebateTeaser(values.persons)}

                                        <Button
                                            buttonText={i18next.t('PersonForm.addPerson')}
                                            type="button"
                                            iconBefore="plus_outlined"
                                            onClick={() => {
                                                form.mutators.push('persons', {
                                                    id: uuidv4(),
                                                    gender: GENDER_NONE_SELECTED,
                                                    insuranceStart: insuranceStartDefault,
                                                    zip: values?.persons[0]?.zip,
                                                    zipText: values?.persons[0]?.zipText,
                                                    place: values?.persons[0]?.place,
                                                    municipalityId: values?.persons[0]?.municipalityId,
                                                    municipalityInfo: values?.persons[0]?.municipalityInfo,
                                                    newPerson: true,
                                                });
                                            }}
                                            disabled={persons.length > 11}
                                            outlined
                                        />
                                    </Stack>
                                )}
                            </FieldArray>

                            <StyledCtaBar>
                                <StyledCtaBarCol />
                                <StyledCtaBarCol>
                                    <Button
                                        className={`submitBtn`}
                                        type="submit"
                                        name="submit"
                                        inProgress={false}
                                        disabled={submitDisabled}
                                        ready={submitReady}
                                    >
                                        {i18next.t(
                                            updateInProgress
                                                ? 'PersonForm.saveButton.saving'
                                                : 'PersonForm.saveButton.text'
                                        )}
                                    </Button>
                                </StyledCtaBarCol>
                            </StyledCtaBar>
                        </Stack>
                    </Form>
                );
            }}
        />
    );
};

PersonForm.defaultProps = { className: null };

PersonForm.propTypes = {
    className: string,
    onSubmit: func,
    autosave: func,
    currentSubmissionId: string,
};

export default PersonForm;
