import React from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

// data
import { ensureOwnSubmission } from '@src/util/data';

// forms
import { ProductForm } from '@src/forms';

// util
import { findPersonById } from '@src/util/person';
import { isInternalProcess } from '@src/util/process';

// styles
import { StyledContainer } from './EditSubmissionProductSubPanelDefault.style';

const EditSubmissionProductSubPanelDefault = ({
    className,
    rootClassName,
    submission,
    fetchInProgress,
    onSubmit,
    onProductSelectionChange,
    onProductTypeChange,
    backActionMsg,
    submitButtonText,
    tariffs,
    panelUpdated,
}) => {
    const classes = classNames(rootClassName || 'panel--product', className);
    const currentSubmission = ensureOwnSubmission(submission);
    const { persons } = currentSubmission.relationships;
    const { personId } = useParams();

    const currentPerson = findPersonById(personId, persons) || persons[0];
    const currentProducts = useSelector(state => state.apiData?.currentProducts);

    const apiOkpTariffs = tariffs.okp || {};
    const apiVvgTariffs = tariffs.vvg || {};
    const apiCurrentProducts = tariffs.currentProducts || [];

    const isInternal = isInternalProcess(persons, currentProducts);

    return (
        <StyledContainer className={classes} isInternal={isInternal}>
            {!fetchInProgress && apiOkpTariffs?.products && apiVvgTariffs?.products && (
                <ProductForm
                    onSubmit={onSubmit}
                    submission={currentSubmission}
                    currentPerson={currentPerson}
                    apiOkpTariffs={apiOkpTariffs}
                    apiVvgTariffs={apiVvgTariffs}
                    apiCurrentProducts={apiCurrentProducts}
                    onProductSelectionChange={onProductSelectionChange}
                    onProductTypeChange={onProductTypeChange}
                    backActionMsg={backActionMsg}
                    submitButtonText={submitButtonText}
                    updated={panelUpdated}
                />
            )}
        </StyledContainer>
    );
};

EditSubmissionProductSubPanelDefault.propTypes = {
    submission: object.isRequired,
    fetchInProgress: bool,
    onSubmit: func.isRequired,
    onProductSelectionChange: func,
    onProductTypeChange: func,
    backActionMsg: string.isRequired,
    submitButtonText: string.isRequired,
    tariffs: object.isRequired,
    className: string,
    rootClassName: string,
    panelUpdated: bool.isRequired,
};

export default EditSubmissionProductSubPanelDefault;
