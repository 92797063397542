/**
 * Determines, wheater a given element is empty
 *
 * @param {} obj
 * @return bool
 */
export const isEmpty = obj => {
    return obj && Object.keys(obj).length === 0;
};

/**
 * Removes elements which have a undefined value
 *
 * @param object object
 * @return object
 */
export function removeUndefinedProperties(object) {
    Object.keys(object).forEach(key => {
        if (object[key] === undefined) {
            delete object[key];
        }
    });
    return object;
}
