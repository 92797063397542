import find from 'lodash/find';
import { matchPath } from 'react-router-dom';
import { compile } from 'path-to-regexp';
import { stringify } from './urlHelpers';
import routeConfiguration from '@src/routeConfiguration';

const findRouteByName = (nameToFind, routes) => find(routes, route => route.name === nameToFind);

/**
 * E.g. ```const toSubmissionPath = toPathByRouteName('SubmissionPage', routes);```
 * Then we can generate submission paths with given params (```toSubmissionPath({ id: uuidX })```)
 */
const toPathByRouteName = (nameToFind, routes) => {
    const route = findRouteByName(nameToFind, routes);
    if (!route) {
        throw new Error(`Path "${nameToFind}" was not found.`);
    }
    return compile(route.path);
};

/**
 * Shorthand for single path call. (```pathByRouteName('SubmissionPage', { id: uuidX });```)
 */
export const pathByRouteName = (nameToFind, params = {}, routes = null) => {
    if (!routes) {
        routes = routeConfiguration();
    }

    return toPathByRouteName(nameToFind, routes)(params);
};

/**
 * Find the matching routes and their params for the given pathname
 *
 * @param {String} pathname - Full URL path from root with possible
 * search params and hash included
 *
 * @param routeConfiguration
 * @return {Array<{ route, params }>} - All matches as { route, params } objects if matches has
 * exact flag set to false. If not, an array containing just the first matched exact route is returned.
 */
export const matchPathname = (pathname, routeConfiguration) => {
    const matchedRoutes = routeConfiguration.reduce((matches, route) => {
        const { path, exact = true } = route;
        const match = matchPath({ path, exact }, pathname);
        if (match) {
            matches.push({
                route,
                params: match.params || {},
            });
        }
        return matches;
    }, []);

    const matchedExactRoute = matchedRoutes.find(r => {
        return r.exact === true || r.exact == null;
    });

    // We return matched 'exact' path route only if such exists
    // and all matches if no exact flag exists.
    return matchedExactRoute ? [matchedExactRoute] : matchedRoutes;
};

/**
 * ResourceLocatorString is used to direct webapp to correct page.
 * In contrast to Universal Resource Locator (URL), this doesn't contain protocol, host, or port.
 */
export const createResourceLocatorString = (routeName, routes, pathParams = {}, searchParams = {}, hash = '') => {
    const searchQuery = stringify(searchParams);
    const includeSearchQuery = searchQuery.length > 0 ? `?${searchQuery}` : '';
    const path = pathByRouteName(routeName, pathParams, routes);
    return `${path}${includeSearchQuery}${hash}`;
};

/**
 * Find component related to route name
 * E.g. `const PageComponent = findComponentByRouteName('CheckoutPage', routes);`
 * Then we can call static methods of given component:
 * `dispatch(PageComponent.setInitialValues({ submission, bookingDates }));`
 *
 * @param {String} nameToFind - Route name
 * @param {Array<{route}>} routes - Route configuration as flat array.
 *
 * @return {route} route - Route that matches the given route name.
 */
export const findRouteByRouteName = (nameToFind, routes) => {
    const route = findRouteByName(nameToFind, routes);
    if (!route) {
        throw new Error(`Component "${nameToFind}" was not found.`);
    }
    return route;
};

/**
 * Get the canonical URL from the given location
 *
 * @param {Array<{ route }>} routes - Route configuration as flat array
 * @param {Object} location - location object from React Router
 * @param {Boolean} pathOnly - return only the path of the url
 * @param {Array<string>} dynamicSlugsToRemove - parameter that should be removed from the url
 *
 * @return {String} Canonical URL of the given location
 *
 */
export const canonicalRoutePath = (
    routes,
    location,
    pathOnly = false,
    dynamicSlugsToRemove = ['id', 'userId', 'personId']
) => {
    const { pathname, search, hash } = location;
    const matches = matchPathname(pathname, routes);
    let canonicalPathName = pathname;

    for (const slug of dynamicSlugsToRemove) {
        const match = matches.length === 1 && matches[0]?.params[slug];
        if (match) {
            const pattern = `/${match}`;
            canonicalPathName = canonicalPathName.replace(new RegExp(pattern, 'gi'), '');
        }
    }

    return pathOnly ? canonicalPathName : `${canonicalPathName}${search}${hash}`;
};
