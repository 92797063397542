import styled from '@emotion/styled';
import { rem } from 'polished';
import { mixins } from 'atupri-component-library/lib/core/styles/theme/default';

export const StyledFieldset = styled.fieldset(({ questionStyle }) => {
    let styles = [
        `
    width: 100%;
    box-sizing: border-box;
    background-color: #ffffff;
    box-shadow: 0 ${rem(24)} ${rem(48)} rgba(50, 74, 181, 0.08);
    border-radius: ${rem(8)};
    border: 0;
    margin: 0;

    &:not(.rootRadioButtonGroup) {
      ${mixins.responsiveStyles({
          padding: {
              zero: `${rem(30)} ${rem(12)}`,
              medium: `${rem(48)} ${rem(37)}`,
              wide: `${rem(48)} ${rem(37)}`,
          },
      })}
    }

    fieldset {
      box-sizing: border-box;

      ${mixins.responsiveStyles({
          'margin-left': {
              zero: `${rem(-12)}`,
              medium: `${rem(-37)}`,
              wide: `${rem(-37)}`,
          },
          padding: {
              zero: `0 ${rem(12)} ${rem(16)}`,
              medium: `0 ${rem(37)} ${rem(16)}`,
              wide: `0 ${rem(37)} ${rem(16)}`,
          },
          width: {
              zero: `calc(100% + ${rem(2 * 12)})`,
              medium: `calc(100% + ${rem(2 * 37)})`,
              wide: `calc(100% + ${rem(2 * 37)})`,
          },
      })}

       fieldset {
        margin-left: 0;
        width: 100%;
        box-sizing: border-box;
        padding-left: 0;
        padding-right: 0;
      }
    }

    fieldset {
      background-color: transparent;
      box-shadow: none;
      border-radius: 0;
      border: 0;

      legend {
        position:absolute;
        left: -5000px;
      }

      + fieldset {
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        padding-top: ${rem(32)};
      }
    }

    &.is-listing {
      padding-left: ${rem(44)};
    }
  `,
    ];

    if (questionStyle) {
        styles.push(`
        padding: 0;
        background-color: transparent;
        box-shadow: none;

        fieldset {
          padding: ${rem(48)} ${rem(37)};
          box-sizing: border-box;

          fieldset {
            padding: 0;
          }
        }
      `);
    }

    return styles;
});
