import React from 'react';
import { useSelector } from 'react-redux';
import { array, object, string } from 'prop-types';
import DropdownView from '@src/components/PersonSelect/renderings/DropdownView';
import PersonItem from '@src/components/PersonSelect/partials/PersonItem';
import { isInternalProcess } from '@src/util/process';

const DesktopView = ({ submissionId, persons, currentOkpProduct }) => {
    const hasMultiplePersons = persons.length > 1;
    const currentProducts = useSelector(state => state.apiData?.currentProducts);
    const isInternal = isInternalProcess(persons, currentProducts);

    return hasMultiplePersons ? (
        <DropdownView
            hasMultiplePersons={hasMultiplePersons}
            isInternalProcess={isInternal}
            persons={persons}
            submissionId={submissionId}
            currentOkpProduct={currentOkpProduct}
        />
    ) : (
        <PersonItem
            hasMultiplePersons={hasMultiplePersons}
            isInternalProcess={isInternal}
            {...persons[0]}
            submissionId={submissionId}
            currentOkpProduct={currentOkpProduct}
        />
    );
};

DesktopView.propTypes = {
    currentOkpProduct: object,
    persons: array.isRequired,
    submissionId: string.isRequired,
};

export default DesktopView;
