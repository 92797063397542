import styled from '@emotion/styled';
import { rem } from 'polished';
import { mixins, spaces } from 'atupri-component-library/lib/core/styles/theme/default';
import { Box, GridItem } from 'atupri-component-library/lib/helpers';
import { H1, EmphasizedToggle } from 'atupri-component-library/lib/primitives';

export const StyledBox = styled(Box)`
    ${mixins.responsiveStyles({
        padding: { zero: `${rem(spaces.theta)} ${rem(spaces.gamma)}`, medium: `${rem(spaces.theta)}` },
    })};
`;

export const StyledGridItem = styled(GridItem)(({ gutters, verticalGutter = 1, shrink = 0, equalDimensions = 0 }) => {
    let styles = [];
    let halfGutters = {};

    const shrinkStyles = 'flex: 0 0 auto!important';

    const equalDimensionsStyles = `
      > div {
        display: flex;
        height: 100%;
      }
    `;

    for (const [key, value] of Object.entries(gutters)) {
        halfGutters[key] = parseInt(value) / 2;
    }
    const verticalGutterStyles = `
      ${mixins.responsiveStyles({
          'margin-top': halfGutters,
          'margin-bottom': halfGutters,
      })};
    `;

    if (verticalGutter) {
        styles.push(verticalGutterStyles);
    }

    if (shrink) {
        styles.push(shrinkStyles);
    }

    if (equalDimensions) {
        styles.push(equalDimensionsStyles);
    }

    return styles;
});

export const StyledHeader = styled(H1)`
    ${mixins.responsiveStyles({
        'margin-top': { zero: spaces.zeta, medium: spaces.lambda },
        'padding-left': { zero: spaces.beta, medium: spaces.beta, wide: spaces.zeta },
        'padding-right': { zero: spaces.beta, medium: spaces.beta, wide: spaces.zeta },
    })}
`;

export const StyledEmphasizedToggle = styled(EmphasizedToggle)`
    > input + div {
        height: 100%;
    }
`;
