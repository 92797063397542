import React from 'react';

import {
    PersonPage,
    EditSubmissionPage,
    HealthQuestionsPage,
    ProductBasketPage,
    ConfirmationPage,
    QuickCalculatorPage,
    NotFoundPage,
} from '@src/containers/index';

const routeConfiguration = () => {
    return [
        {
            path: '/',
            name: 'PersonPage',
            component: props => <PersonPage {...props} />,
            loadData: PersonPage.loadData,
        },
        {
            path: '/dashboard/:tab/:personId?/:subpanel?',
            name: 'EditSubmissionPage',
            component: props => <EditSubmissionPage {...props} />,
            loadData: EditSubmissionPage.loadData,
        },
        {
            path: '/personinfo/:userId',
            name: 'HealthQuestionPage',
            component: props => <HealthQuestionsPage {...props} />,
            loadData: HealthQuestionsPage.loadData,
        },
        {
            path: '/danke',
            name: 'ConfirmationPage',
            component: props => <ConfirmationPage {...props} />,
        },
        {
            path: '/produkte/:userId',
            name: 'ProductBasketPage',
            component: props => <ProductBasketPage {...props} />,
            loadData: ProductBasketPage.loadData,
        },
        {
            path: '/quickcalculator',
            name: 'QuickCalculatorPage',
            component: props => <QuickCalculatorPage {...props} />,
        },
        {
            path: '/notfound',
            name: 'NotFoundPage',
            component: props => <NotFoundPage {...props} />,
        },
    ];
};

export default routeConfiguration;
