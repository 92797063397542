import React from 'react';
import { colors } from 'atupri-component-library/lib/core/styles/theme/default';
import { array, object, string } from 'prop-types';
import { Question } from '@src/components';

import { getTreeLevel, isEmpty, filterElementsByIdentifier } from '@src/util/array';
import { conditionalRender } from './util';

import { StyledBlock, StyledH2, StyledIcon, StyledSubtitle, StyledQuestions } from './Block.style';

const Block = ({ healthQuestions, block, prefix = '' }) => {
    const render = () => {
        const childBlocks = filterElementsByIdentifier(healthQuestions, 'parent', block.id);
        const treeLevel = getTreeLevel(healthQuestions, block);

        const shouldRenderLabelOutside = block.label && treeLevel === 1;
        const shouldRenderLabelInside = block.label && treeLevel > 1;

        return (
            <>
                {shouldRenderLabelOutside ? (
                    <StyledSubtitle>
                        <StyledIcon iconColor={colors.primary} iconName="heartbeat" />
                        <StyledH2>{block.label}</StyledH2>
                    </StyledSubtitle>
                ) : (
                    ''
                )}
                {!isEmpty(block.questions) ? (
                    <StyledBlock id={`BLOCK_${block.id}`} className={`BLOCK LEVEL-${treeLevel}`} level={treeLevel}>
                        {shouldRenderLabelInside ? <StyledH2>{block.label}</StyledH2> : ''}

                        <StyledQuestions className={`QUESTIONS LEVEL-${treeLevel}`} level={treeLevel}>
                            {block.questions.map(question => {
                                return (
                                    <Question
                                        key={question.id}
                                        healthQuestions={healthQuestions}
                                        block={block}
                                        question={question}
                                        prefix={prefix}
                                    />
                                );
                            })}
                        </StyledQuestions>

                        {childBlocks
                            ? childBlocks.map(block => {
                                  return (
                                      <Block
                                          key={block.id}
                                          healthQuestions={healthQuestions}
                                          block={block}
                                          prefix={prefix}
                                      />
                                  );
                              })
                            : ''}
                    </StyledBlock>
                ) : (
                    ''
                )}
            </>
        );
    };

    return conditionalRender(block, render);
};

Block.propTypes = {
    healthQuestions: array.isRequired,
    block: object.isRequired,
    prefix: string,
};

export default Block;
