import styled from '@emotion/styled';

import { mixins, spaces } from 'atupri-component-library/lib/core/styles/theme/default';
import { Box } from 'atupri-component-library/lib/helpers';
import { Icon } from 'atupri-component-library/lib/primitives';

export const StyledBox = styled(Box)`
    display: flex;

    ${mixins.responsiveStyles({
        'padding-top': { zero: spaces.epsilon, medium: spaces.eta },
        'padding-right': { zero: spaces.delta, medium: 38 },
        'padding-bottom': { zero: spaces.epsilon, medium: spaces.eta },
        'padding-left': { zero: spaces.delta, medium: 38 },
        'flex-direction': { zero: 'column', medium: 'row' },
    })};
`;

export const StyledIcon = styled(Icon)`
    ${mixins.responsiveStyles({
        'font-size': { zero: 40 },
        'margin-right': { zero: 0, medium: 13 },
        'margin-bottom': { zero: 17, medium: 0 },
    })};
`;
