import { environmentConfiguration } from '@src/config/env';

const { environments } = environmentConfiguration;

const hosts = {
    [environments.local]: [
        {
            de: 'praemienrechner-local.atuprinet.ch',
            fr: 'calculateur-de-primes-local.atuprinet.ch',
            it: 'calcolatore-dei-premi-local.atuprinet.ch',
        },
        {
            de: 'myatupri-local.atuprinet.ch',
            fr: 'myatupri-local.atuprinet.ch',
            it: 'myatupri-local.atuprinet.ch',
        },
    ],
    [environments.dev]: [
        {
            de: 'praemienrechner-dev.atuprinet.ch',
            fr: 'calculateur-de-primes-dev.atuprinet.ch',
            it: 'calcolatore-dei-premi-dev.atuprinet.ch',
        },
        {
            de: 'myatupri-dev.atuprinet.ch',
            fr: 'myatupri-dev.atuprinet.ch',
            it: 'myatupri-dev.atuprinet.ch',
        },
    ],
    [environments.test]: [
        {
            de: 'praemienrechner-test.atuprinet.ch',
            fr: 'calculateur-de-primes-test.atuprinet.ch',
            it: 'calcolatore-dei-premi-test.atuprinet.ch',
        },
        {
            de: 'myatupri-test.atuprinet.ch',
            fr: 'myatupri-test.atuprinet.ch',
            it: 'myatupri-test.atuprinet.ch',
        },
        {
            de: 'akupo-test-login.atupri.ch',
            fr: 'akupo-test-login.atupri.ch',
            it: 'akupo-test-login.atupri.ch',
        },
    ],
    [environments.int]: [
        {
            de: 'praemienrechner-int.atupri.ch',
            fr: 'calculateur-de-primes-int.atupri.ch',
            it: 'calcolatore-dei-premi-int.atupri.ch',
        },
        {
            de: 'praemienrechner-int.atuprinet.ch',
            fr: 'calculateur-de-primes-int.atuprinet.ch',
            it: 'calcolatore-dei-premi-int.atuprinet.ch',
        },
        {
            de: 'myatupri-int.atuprinet.ch',
            fr: 'myatupri-int.atuprinet.ch',
            it: 'myatupri-int.atuprinet.ch',
        },
        {
            de: 'akupo-int-login.atupri.ch',
            fr: 'akupo-int-login.atupri.ch',
            it: 'akupo-int-login.atupri.ch',
        },
    ],
    [environments.int2]: [
        {
            de: 'praemienrechner-int2.atupri.ch',
            fr: 'calculateur-de-primes-int2.atupri.ch',
            it: 'calcolatore-dei-premi-int2.atupri.ch',
        },
        {
            de: 'praemienrechner-int2.atuprinet.ch',
            fr: 'calculateur-de-primes-int2.atuprinet.ch',
            it: 'calcolatore-dei-premi-int2.atuprinet.ch',
        },
        {
            de: 'myatupri-int2.atuprinet.ch',
            fr: 'myatupri-int2.atuprinet.ch',
            it: 'myatupri-int2.atuprinet.ch',
        },
        {
            de: 'akupo-int2-login.atupri.ch',
            fr: 'akupo-int2-login.atupri.ch',
            it: 'akupo-int2-login.atupri.ch',
        },
    ],
    [environments.prod]: [
        {
            de: 'praemienrechner.atupri.ch',
            fr: 'calculateur-de-primes.atupri.ch',
            it: 'calcolatore-dei-premi.atupri.ch',
        },
        {
            de: 'praemienrechner.atuprinet.ch',
            fr: 'calculateur-de-primes.atuprinet.ch',
            it: 'calcolatore-dei-premi.atuprinet.ch',
        },
        {
            de: 'myatupri-prod.atuprinet.ch',
            fr: 'myatupri-prod.atuprinet.ch',
            it: 'myatupri-prod.atuprinet.ch',
        },
        {
            de: 'myatupri.atupri.ch',
            fr: 'myatupri.atupri.ch',
            it: 'myatupri.atupri.ch',
        },
        {
            de: 'praemienrechner-bypass.atuprinet.ch',
            fr: 'praemienrechner-bypass.atuprinet.ch',
            it: 'praemienrechner-bypass.atuprinet.ch',
        },
    ],
};

const languages = {
    de: 'de',
    fr: 'fr',
    it: 'it',
};

const locale_countries = {
    de: 'de-CH',
    fr: 'fr-CH',
    it: 'it-CH',
};

export const localizationConfiguration = {
    hosts,
    languages,
    locale_countries,
};
