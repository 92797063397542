import React from 'react';
import PropTypes, { object } from 'prop-types';
import { useSelector } from 'react-redux';

// components
import { ProgressBar } from 'atupri-component-library/lib/components';
import { StickyHeader } from '@src/components';

// utils
import { isInternalProcess } from '@src/util/process';

// styles
import { StyledProgressBar } from './Tabs.styles';

const Tabs = props => {
    const {
        children,
        submission,
        submission: {
            relationships: { persons, basket },
        },
        selectedTab,
        tariffs,
    } = props;

    const steps = React.Children.map(children, child => {
        return {
            text: child.props.tabLabel,
            link: child.props.tabLinkProps,
            isValidated: !child.props.disabled && !child.props.selected,
            isActive: child.props.selected,
        };
    });

    const childArray = React.Children.toArray(children);
    const selectedTabPanel = childArray.find(c => c.props.selected);
    const currentProducts = useSelector(state => state.apiData?.currentProducts);

    // error if nothing selected
    if (!selectedTabPanel) {
        throw new Error(`Tabs component: minimum one child must have 'selected'`);
    }

    const stickyHeaderProps = {
        selectedTab,
        personSelectProps: {
            submission,
            persons,
            basket,
            tariffs,
        },
        premiumTotalProps: {
            basket,
        },
    };

    return (
        <React.Fragment>
            <div>
                <StyledProgressBar>
                    {!isInternalProcess(persons, currentProducts) && <ProgressBar steps={steps} />}
                    <StickyHeader {...stickyHeaderProps} />
                </StyledProgressBar>
            </div>
            {selectedTabPanel}
        </React.Fragment>
    );
};

const { node, string } = PropTypes;

Tabs.defaultProps = {
    navRootClassName: null,
    tabRootClassName: null,
    tariffs: null,
};

Tabs.propTypes = {
    children: node.isRequired,
    navRootClassName: string,
    tabRootClassName: string,
    tariffs: object,
};

export default Tabs;
