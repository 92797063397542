import styled from '@emotion/styled';
import { rem } from 'polished';
import { css } from '@emotion/react';

import { colors, fontWeights, spaces } from 'atupri-component-library/lib/core/styles/theme/default';
import { Box } from 'atupri-component-library/lib/helpers';
import { Checkbox, Icon, P2, P3, Link } from 'atupri-component-library/lib/primitives';

export const StyledCard = styled(Box)(({ isActive }) => {
    return css`
        display: flex;
        flex-direction: column;
        width: 100%;
        min-width: ${rem(300)};
        max-width: ${rem(400)};
        padding: ${rem(spaces.epsilon)};
        background-color: ${isActive ? colors.blue100 : colors.white};
    `;
});

export const StyledCheckboxWrapper = styled.div`
    display: flex;

    span > span {
        &:before,
        &:after {
            width: ${rem(40)};
            height: ${rem(40)};
        }
    }
`;

export const StyledControls = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: ${rem(spaces.epsilon)};
`;

export const StyledPrice = styled.div`
    color: ${colors.grey900};
    font-size: ${rem(20)};
    font-weight: ${fontWeights.semibold};
`;

export const StyledCheckbox = styled(Checkbox)``;

export const StyledIcon = styled(Icon)(({ isActive }) => {
    return css`
        font-size: ${rem(32)};
        padding: ${rem(spaces.delta)} ${rem(spaces.epsilon)};
        margin-right: -${rem(spaces.epsilon)};
        border-radius: ${rem(8)} 0 0 ${rem(8)};
        color: ${colors.primary};
        background: ${isActive ? colors.white : colors.blue300};
    `;
});

export const StyledTitle = styled.div`
    font-size: ${rem(28)};
    color: ${colors.primary};
    font-weight: ${fontWeights.semibold};
    line-height: ${rem(40)};
    padding: ${rem(spaces.epsilon)} 0;
`;

export const StyledSubtitle = styled.div`
    font-size: ${rem(16)};
    color: ${colors.black};
    font-weight: ${fontWeights.semibold};
    padding-bottom: ${rem(spaces.delta)};
`;

export const StyledServices = styled.div``;

export const StyledServicesTitle = styled.div`
    font-size: ${rem(16)};
    color: ${colors.grey700};
    padding-bottom: ${rem(spaces.beta)};
`;

export const StyledServicesList = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
`;

export const StyledServicesListItem = styled.li(({ isLast = false }) => {
    const styles = [
        css`
            font-size: ${rem(16)};
            color: ${colors.grey900};
            font-weight: ${fontWeights.regular};
            line-height: ${rem(24)};
            padding: ${rem(spaces.delta)} 0 ${rem(spaces.beta)} 0;
        `,
    ];

    if (isLast) {
        styles.push(css`
            padding-bottom: ${rem(spaces.zeta)};
        `);
    } else {
        styles.push(css`
            border-bottom: 1px solid ${colors.grey600};
        `);
    }
    return styles;
});

export const StyledServicesListItemTitle = styled(P2)(({ isLast = false }) => {
    return css`
        font-weight: ${isLast ? fontWeights.semibold : fontWeights.regular};
    `;
});

export const StyledServicesListItemDescription = styled(P3)`
    color: ${colors.grey700};
`;

export const StyledIconWrapper = styled.div(({ isLast = false }) => {
    return css`
        i {
            font-weight: ${isLast ? fontWeights.bold : fontWeights.regular};
        }
    `;
});

export const StyledLink = styled(Link)`
    font-weight: ${fontWeights.semibold};
    margin-top: auto;
`;
