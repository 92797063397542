import React from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import i18next from 'i18next';
import { useSelector } from 'react-redux';

// components
import { WizardHeader } from '@src/components';

// utils
import { ensureOwnSubmission } from '@src/util/data';
import { isPersonDatasetComplete } from '@src/util/person';
import { EditSubmissionCockpitForm } from '@src/forms';

// style
import { StyledContainer } from './EditSubmissionCockpitPanel.style';

const EditSubmissionCockpitPanel = props => {
    const {
        className,
        rootClassName,
        submission,
        disabled,
        ready,
        onSubmit,
        onChange,
        submitButtonText,
        panelUpdated,
        updateInProgress,
        errors,
    } = props;

    const classes = classNames(rootClassName || 'panel--cockpit', className);
    const currentSubmission = ensureOwnSubmission(submission);
    const { persons, basket } = currentSubmission.relationships;
    const currentProducts = useSelector(state => state.apiData?.currentProducts);

    const isProcessWithHQ = !!currentSubmission?.attributes?.healthQuestions || false;
    const processHQLangAppendix = isProcessWithHQ ? 'WithHQ' : 'WithoutHQ';

    const panelTitle = i18next.t(`EditSubmissionCockpitPanel.panelTitle${processHQLangAppendix}`);
    const panelText = i18next.t(`EditSubmissionCockpitPanel.panelText${processHQLangAppendix}`);

    let personDatasetComplete = {};
    let allPersonDatasetComplete = true;

    persons.map(person => {
        const datasetComplete = isPersonDatasetComplete(person.id, submission, currentProducts);
        personDatasetComplete[person.id] = datasetComplete;
        if (!datasetComplete) {
            allPersonDatasetComplete = false;
        }
    });

    // prepare init form values
    let initValues = {};

    if (allPersonDatasetComplete) {
        initValues['complete'] = allPersonDatasetComplete;
    }

    return (
        <StyledContainer className={classes}>
            <WizardHeader panelTitle={panelTitle} panelText={panelText} />
            <EditSubmissionCockpitForm
                className={'form'}
                initialValues={initValues}
                saveActionMsg={submitButtonText}
                onSubmit={onSubmit}
                onChange={onChange}
                disabled={disabled}
                ready={ready}
                updated={panelUpdated}
                updateError={errors.updateSubmissionError}
                updateInProgress={updateInProgress}
                fetchErrors={errors}
                submission={submission}
                persons={persons}
                personDatasetComplete={personDatasetComplete}
                basket={basket}
            />
        </StyledContainer>
    );
};

EditSubmissionCockpitPanel.propTypes = {
    className: string,
    rootClassName: string,

    // We cannot use propTypes.submission since the submission might be an offer.
    submission: object,

    disabled: bool.isRequired,
    ready: bool.isRequired,
    onSubmit: func.isRequired,
    onChange: func.isRequired,
    submitButtonText: string.isRequired,
    panelUpdated: bool.isRequired,
    updateInProgress: bool.isRequired,
    errors: object.isRequired,
};

export default EditSubmissionCockpitPanel;
