import React, { useState } from 'react';
import { func, number, string } from 'prop-types';
import i18next from 'i18next';

// components
import { Input, Select } from 'atupri-component-library/lib/primitives';
import { GridItem, Hidden, Spacer } from 'atupri-component-library/lib/helpers';

import { ZipAutocomplete } from '@src/components';

// styles
import { StyledWrapper } from './SearchFields.styles';

let nameValueTimeout;

const SearchFields = props => {
    const { zipValue, setZipValue, requestCity, setRequestCity, nameValue, setNameValue, radiusValue, setRadiusValue } =
        props;

    const [innerNameValue, setInnerNameValue] = useState(nameValue);

    const radiusOptions = [1, 2, 3, 5, 10, 15, 20, 30].map(radius => ({
        label: i18next.t('PracticeSearch.search.distance', { distance: radius }),
        value: radius,
    }));

    const onZipChange = zipData => {
        setZipValue(zipData.zip);
        setRequestCity(zipData.place);
    };

    const onRadiusChange = e => {
        setRadiusValue(e.target.value);
    };

    const onNameChange = e => {
        setInnerNameValue(e.target.value);

        // debounce nameValue to avoid unnecessary requests
        clearTimeout(nameValueTimeout);
        nameValueTimeout = setTimeout(() => {
            setNameValue(e.target.value);
        }, 500);
    };

    return (
        <StyledWrapper columns={6}>
            <GridItem size={{ zero: 2, medium: 2 }}>
                <ZipAutocomplete
                    label={i18next.t('PracticeSearch.search.zip')}
                    name="practice-plz"
                    defaultValue={zipValue && requestCity ? `${zipValue} ${requestCity}` : ''}
                    onChangeValue={onZipChange}
                    onChangeSelection={zipData => {
                        onZipChange(zipData);
                        document.activeElement.blur();
                    }}
                />
            </GridItem>
            <GridItem size={{ zero: 2, medium: 1 }}>
                <Select
                    label={i18next.t('PracticeSearch.search.radius')}
                    name="practice-radius"
                    value={Number(radiusValue)} // cast radiusValue to int to match options value
                    options={radiusOptions}
                    onChange={onRadiusChange}
                />
            </GridItem>
            <GridItem size={{ zero: 6, medium: 3 }}>
                <Hidden above="medium">
                    <Spacer space="delta" />
                </Hidden>
                <Input
                    label={i18next.t('PracticeSearch.search.name')}
                    name="practice-name"
                    value={innerNameValue}
                    onChange={onNameChange}
                />
            </GridItem>
        </StyledWrapper>
    );
};

SearchFields.propTypes = {
    zipValue: string.isRequired,
    setZipValue: func.isRequired,
    nameValue: string.isRequired,
    setNameValue: func.isRequired,
    setRadiusValue: func.isRequired,
    radiusValue: number.isRequired,
    requestCity: string.isRequired,
    setRequestCity: func.isRequired,
};

export default SearchFields;
