import React from 'react';
import moment from 'moment';

import { Field } from 'react-final-form';
import { bool, func, object, string } from 'prop-types';
import i18next from 'i18next';
import config from '@src/config';
import { log } from '@src/middleware';
import { StyledInputRoot } from '@src/components/FieldDatePicker/FieldDatePicker.style';
import { Datepicker } from 'atupri-component-library/lib/primitives';
import { BACKEND_DATE_FORMAT } from '@src/util/date';

const displayDateFormat = 'dd.MM.yyyy';
const displayMonthDateFormat = 'MM.yyyy';
const backendDateFormat = BACKEND_DATE_FORMAT;

const FieldDatePicker = ({
    id,
    name,
    validate,
    placeholder,
    disabled,
    allowedValues,
    type = 'datepicker',
    dayPlaceholder = i18next.t('Datepicker.dayPlaceholder'),
    monthPlaceholder = i18next.t('Datepicker.monthPlaceholder'),
    yearPlaceholder = i18next.t('Datepicker.yearPlaceholder'),
}) => {
    const isMonthPicker = type === 'monthpicker';
    const dateFormat = isMonthPicker ? displayMonthDateFormat : displayDateFormat;

    const getDateValue = (disabled, value) => {
        let dateValue;
        try {
            if (value) {
                dateValue = moment(value, backendDateFormat).toDate();
            }
        } catch (e) {
            log.error('invalid date value', value);
        }
        return dateValue;
    };

    // showLeadingZero is currently broken in v8.0.5 and allows for 3 digit entries. (https://github.com/wojtekmaj/react-date-picker/issues/156)
    return (
        <Field id={id} name={name} disabled={disabled} validate={!disabled && validate}>
            {props => (
                <StyledInputRoot>
                    <Datepicker
                        name={props.input.name}
                        label={isMonthPicker ? placeholder : null}
                        onChange={value => {
                            props.input.onChange(value ? moment(value).format(backendDateFormat) : value);
                        }}
                        value={getDateValue(disabled, props.input.value)}
                        format={dateFormat}
                        disabled={disabled}
                        locale={config.locale_countries[i18next.language]}
                        placeholderText={placeholder}
                        showLeadingZeros={false}
                        dayPlaceholder={dayPlaceholder}
                        monthPlaceholder={monthPlaceholder}
                        yearPlaceholder={yearPlaceholder}
                        minDate={
                            allowedValues?.minDate ? moment(allowedValues.minDate, backendDateFormat).toDate() : null
                        }
                        maxDate={
                            allowedValues?.maxDate ? moment(allowedValues.maxDate, backendDateFormat).toDate() : null
                        }
                        minDetail={'decade'}
                        maxDetail={isMonthPicker ? 'year' : 'month'}
                        errorText={!disabled && props.meta.touched && props.meta.error}
                    />
                </StyledInputRoot>
            )}
        </Field>
    );
};

FieldDatePicker.propTypes = {
    id: string.isRequired,
    name: string.isRequired,
    validate: func,
    placeholder: string,
    disabled: bool,
    allowedValues: object,
    type: string,
    dayPlaceholder: string,
    monthPlaceholder: string,
    yearPlaceholder: string,
};

export default FieldDatePicker;
