import React from 'react';
import { node } from 'prop-types';
import { Page } from '@src/components';

const StaticPage = props => {
    const { children, ...pageProps } = props;
    return <Page {...pageProps}>{children}</Page>;
};

StaticPage.propTypes = {
    children: node,
};

export default StaticPage;
