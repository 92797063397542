import React from 'react';
import { bool, func, object } from 'prop-types';
import i18next from 'i18next';
import { Button } from 'atupri-component-library/lib/primitives';

import {
    StyledSelectionRowContainer,
    StyledSelectionRow,
    StyledSelectionRowLabels,
    StyledSelectionRowControls,
    StyledPrimaryButton,
} from './SelectionRow.styles';

const SelectionRow = ({ identifier, practice, selectedPractices, setSelectedPractices, toggleDocModal, isActive }) => {
    const onDeleteClick = () => {
        let newSelectedPractices = { ...selectedPractices };
        newSelectedPractices[identifier] = null;
        setSelectedPractices(newSelectedPractices);
    };

    const onChangeClick = () => {
        toggleDocModal(true);
    };

    return (
        <StyledSelectionRowContainer>
            <StyledSelectionRow isActive={isActive}>
                <StyledSelectionRowLabels>
                    <div>{practice.provider}</div>
                    <div>
                        {practice.strasse}, {practice.plz} {practice.ort}
                    </div>
                </StyledSelectionRowLabels>
                <StyledSelectionRowControls>
                    <Button
                        type="button"
                        buttonText={i18next.t('PracticeSearch.button.delete')}
                        iconBefore="trash"
                        isIncognito
                        onClick={onDeleteClick}
                    />
                    <StyledPrimaryButton
                        type="button"
                        buttonText={i18next.t('PracticeSearch.button.edit')}
                        iconBefore="pen"
                        isIncognito
                        onClick={onChangeClick}
                    />
                </StyledSelectionRowControls>
            </StyledSelectionRow>
        </StyledSelectionRowContainer>
    );
};

SelectionRow.defaultProps = {
    isActive: false,
};

SelectionRow.propTypes = {
    practice: object.isRequired,
    selectedPractices: object.isRequired,
    setSelectedPractices: func.isRequired,
    toggleDocModal: func.isRequired,
    isActive: bool,
};

export default SelectionRow;
