import React, { useEffect, useState } from 'react';
import { bool, func, number, shape, string } from 'prop-types';
import i18next from 'i18next';
import { Field } from 'react-final-form';

import { Stack } from 'atupri-component-library/lib/helpers';
import { Icon } from 'atupri-component-library/lib/primitives';

import { formatAmount } from '@src/util/currency';
import { sanitizeDOM } from '@src/util/sanitize';

import {
    StyledCard,
    StyledCheckbox,
    StyledCheckboxWrapper,
    StyledIcon,
    StyledIconWrapper,
    StyledControls,
    StyledPrice,
    StyledTitle,
    StyledSubtitle,
    StyledServices,
    StyledServicesTitle,
    StyledServicesList,
    StyledServicesListItem,
    StyledServicesListItemTitle,
    StyledServicesListItemDescription,
    StyledLink,
} from './VvgProductCard.style';

const VvgProductCard = ({
    name,
    value,
    price,
    title,
    subtitle,
    selected = false,
    iconName = 'umbrella_plus',
    detailText,
    detailUrl,
    listingTexts = null,
    onChange = value => {},
    ...rest
}) => {
    const [isSelected, setIsSelected] = useState(selected);

    useEffect(() => {
        setIsSelected(selected);
    }, [selected]);

    return (
        <StyledCard border={1} borderColor="primary" borderRadius={4} isActive={isSelected} {...rest}>
            <StyledControls>
                <StyledCheckboxWrapper>
                    <Field name={name} defaultValue={isSelected} type="checkbox">
                        {props => (
                            <StyledCheckbox
                                name={props.input.name}
                                checked={isSelected}
                                value={value}
                                selectedValue={props.input.value}
                                onChange={event => {
                                    props.input.onChange(value);
                                    onChange(event.target.checked && value);
                                    setIsSelected(event.target.checked);
                                }}
                            />
                        )}
                    </Field>
                </StyledCheckboxWrapper>
                <StyledIcon iconName={iconName} isActive={isSelected} />
            </StyledControls>
            <StyledPrice>{formatAmount(price)}</StyledPrice>
            <StyledTitle>{title}</StyledTitle>
            <StyledSubtitle>{subtitle}</StyledSubtitle>
            <StyledServices>
                <StyledServicesTitle>{i18next.t('ProductForm.vvg.productCard.services.title')}</StyledServicesTitle>
                <StyledServicesList>
                    {listingTexts &&
                        Object.entries(listingTexts).map(([key, text], i, arr) => (
                            <StyledServicesListItem key={key} isLast={arr.length - 1 === i}>
                                <Stack direction="row" space="beta">
                                    <StyledIconWrapper isLast={arr.length - 1 === i}>
                                        <Icon
                                            iconName={i18next.t(`ProductForm.vvg.productCard.services.${key}.icon`)}
                                        />
                                    </StyledIconWrapper>
                                    <Stack space="alpha">
                                        <StyledServicesListItemTitle isLast={arr.length - 1 === i}>
                                            {i18next.t(`ProductForm.vvg.productCard.services.${key}.title`)}
                                        </StyledServicesListItemTitle>
                                        <StyledServicesListItemDescription
                                            dangerouslySetInnerHTML={{
                                                __html: sanitizeDOM(text),
                                            }}
                                        />
                                    </Stack>
                                </Stack>
                            </StyledServicesListItem>
                        ))}
                </StyledServicesList>
            </StyledServices>
            <StyledLink
                linkText={detailText}
                link={{
                    href: detailUrl,
                    target: '_blank',
                }}
                color="primary"
            />
        </StyledCard>
    );
};

VvgProductCard.propTypes = {
    name: string.isRequired,
    value: string.isRequired,
    title: string.isRequired,
    subtitle: string.isRequired,
    price: number.isRequired,
    selected: bool,
    iconName: string,
    background: string,
    detailText: string.isRequired,
    detailUrl: string.isRequired,
    listingTexts: shape({
        hospital: string,
        sport: string,
        visualAid: string,
        alternativeMedicine: string,
        highlight: string,
    }),
    onChange: func,
    className: string,
};

export default VvgProductCard;
