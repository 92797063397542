import styled from '@emotion/styled';
import { rem } from 'polished';
import { mixins, spaces, text, colors } from 'atupri-component-library/lib/core/styles/theme/default';

export const StyledInlineListRow = styled.div`
    display: flex;
    background-color: ${colors.blue300};
    border-radius: ${rem(spaces.beta)};

    ${mixins.responsiveStyles({
        'flex-direction': {
            zero: `column`,
            medium: `row`,
        },
        'align-items': {
            zero: `flex-start`,
            medium: `center`,
        },
        padding: {
            zero: `${rem(spaces.gamma)}`,
            medium: `${rem(spaces.zeta)} ${rem(spaces.eta)} ${rem(spaces.zeta)} ${rem(spaces.zeta)}`,
        },
        margin: {
            zero: `0 0 ${rem(spaces.delta)}`,
            medium: `0 0 ${rem(spaces.zeta)}`,
        },
    })}

    &:last-of-type {
        margin-bottom: ${rem(spaces.epsilon)};
    }
`;

export const StyledInlineListLabels = styled.div`
    ${text.p4}
    flex: 1 0 auto;

    > div:nth-of-type(1) {
        ${text.p1}

        margin-bottom: ${rem(5)};
    }

    > div > span:not(:last-child):after {
        content: ', ';
    }
`;

export const StyledInlineListControls = styled.div`
    display: flex;
    color: ${colors.grey800};

    ${mixins.responsiveStyles({
        'flex-direction': {
            zero: `column`,
            medium: `row`,
        },
    })}

    button:not(:last-of-type) {
        margin: 0 ${rem(spaces.epsilon)} 0 0;
    }

    button {
        ${mixins.responsiveStyles({
            'font-size': {
                zero: `${rem(14)}`,
                medium: `${rem(16)}`,
            },
            'line-height': {
                zero: `${20 / 14}`,
                medium: `${24 / 16}`,
            },
        })}
    }
`;

export const StyledInlinePrimary = styled.div`
    color: ${colors.primary};

    ${mixins.responsiveStyles({
        padding: {
            zero: `${rem(6)} 0 0`,
            medium: `${rem(spaces.gamma)}`,
        },
    })}

    i {
        ${mixins.responsiveStyles({
            'padding-right': {
                zero: `${rem(9)}`,
            },
        })}
    }
`;

export const StyledInlineSecondary = styled.div`
    ${mixins.responsiveStyles({
        padding: {
            zero: `${rem(6)} 0`,
            medium: `${rem(spaces.gamma)}`,
        },
    })}
`;
