import { environmentConfiguration } from '@src/config/env';
import { currencyConfiguration } from '@src/config/currency';
import { localizationConfiguration } from '@src/config/localization';
import { loglevelConfiguration } from '@src/config/loglevel';
import { getBackendApiUrl } from '@src/util/staticUrl';

const { env, dev } = environmentConfiguration;
const { hosts, languages, locale_countries } = localizationConfiguration;
const { loglevel } = loglevelConfiguration;

const i18n = {};

const currency = 'CHF';
const currencyConfig = currencyConfiguration(currency); // https://github.com/yahoo/react-intl/wiki/API#formatnumber
const canonicalRootURL = 'superlocal.dev';
const isMyAtupriPath = window.location.pathname.startsWith('/premiumcalculator');

const apiSessionBase = getBackendApiUrl('apiSessionBase');
const apiEsbBase = getBackendApiUrl('apiEsbBase');

const basename = isMyAtupriPath ? '/premiumcalculator' : '';

const config = {
    env,
    dev,
    hosts,
    languages,
    loglevel,
    locale_countries,
    i18n,
    currency,
    currencyConfig,
    canonicalRootURL,
    apiSessionBase,
    apiEsbBase,
    basename,
};

export default config;
