import React, { Component, useEffect, useMemo } from 'react';
import { array, bool, func, shape, string } from 'prop-types';
import { Field, Form as FinalForm } from 'react-final-form';
import i18next from 'i18next';
import classNames from 'classnames';
import arrayMutators from 'final-form-arrays';
import moment from 'moment/moment';

// components
import {
    Form,
    AutoSave,
    FieldCheckboxGroup,
    PrimaryFieldset,
    ProductBasketPanel,
    EditSubmissionCTA,
} from '@src/components';
import { Button, Checkbox, P1, List, ListItem } from 'atupri-component-library/lib/primitives';
import { Spacer } from 'atupri-component-library/lib/helpers';
import { Accordion, AccordionItem, AccordionItemContent } from 'atupri-component-library/lib/components';

// util
import { getAutosaved, setAutosaved } from '@src/util/autosave';
import { calcPersonPrice, checkForProductsInBasket, getLowestTariffYearFromBasket } from '@src/util/basket';
import { lazyLoadWithDimensions } from '@src/util/contextHelpers';
import { formatAmount } from '@src/util/currency';
import { deepMerge } from '@src/util/data';
import { getAgeFromDate, convertDateToPresentationFormat } from '@src/util/date';
import createScrollToFirstErrorDecorator from '@src/util/finalForm/decorators/scrollToFirstError';
import { propTypes } from '@src/util/types';
import {
    getProcessLangAppendix,
    getProcessWithHQLangAppendix,
    isInternalProcess,
    isProcessWithHQ,
    isVvgProcess,
} from '@src/util/process';
import { PRODUCT_IDENTIFIER_SMARTCARE } from '@src/util/tariff/okp';
import { findPaying } from '@src/util/person';
import { sanitizeDOM } from '@src/util/sanitize';
import { requiredFieldArrayCheckbox, requiredAllFieldArrayCheckbox } from '@src/util/validators';

// style
import { StyledInfoBox, StyledInfoBoxIcon } from '@src/forms/ProductForm/ProductForm.style';
import ImgShowCase from '@assets/img/atupri_showcase.png';
import {
    StyledAccordionItemTrigger,
    StyledShowcase,
    StyledShowcaseCol,
    StyledBirthdate,
    StyledShowcaseLabel,
    StyledH1LookedTitle,
    StyledFinalSubmit,
    StyledTextInfoBox,
    StyledText,
    StyledTextStrong,
    StyledFinalSubmitCol,
    StyledInfobox,
    StyledInfoboxIcon,
    StyledInfoboxContent,
    StyledInfoIcon,
} from './EditSubmissionConfirmationForm.style';

class LocationImage extends Component {
    render() {
        const { alt, ...rest } = this.props;
        return <img alt={alt} {...rest} />;
    }
}

const LazyImage = lazyLoadWithDimensions(LocationImage);

const scrollToFirstErrorDecorator = createScrollToFirstErrorDecorator();

const EditSubmissionConfirmationForm = props => {
    const { submission, effectiveDates, currentProducts, updateInProgress } = props;
    let forceDisabled = false;

    // Resetting forceDisabled after the publishing process is finished (success or error)
    useEffect(() => {
        if (!updateInProgress) {
            forceDisabled = false;
        }
    }, [updateInProgress]);

    const initialValues = useMemo(() => {
        let defaultValues = props.initialValues;

        // check for autosaved values
        const autosaved = getAutosaved(submission.id.uuid, 'confirmationForm');
        if (autosaved?.values) {
            if (defaultValues) {
                defaultValues = deepMerge([defaultValues, autosaved.values]);
            } else {
                defaultValues = autosaved.values;
            }
        }

        return defaultValues;
    }, []);

    const mutators = {
        ...arrayMutators,
        setValue: ([field, value], state, { changeValue }) => {
            changeValue(state, field, () => value);
        },
    };

    const createAgreementOptions = () => {
        const persons = submission.relationships.persons;
        const basket = submission.relationships.basket;
        let optionsArr = [];
        const personPaying = findPaying(submission);

        for (const person of persons) {
            let obj = {};
            let age = getAgeFromDate(person.birthdate);
            const birthdatePresentation = convertDateToPresentationFormat(person.birthdate);
            const personBasket = {
                okp: basket[person.id]?.okp || [],
                vvg: basket[person.id]?.vvg || [],
            };
            const hasProducts = personBasket.okp.length > 0 || personBasket.vvg.length > 0;
            if (hasProducts) {
                obj['key'] = person.id;
                obj['label'] = `${person.firstname} ${person.lastname} (${birthdatePresentation})`;
                if (age < 18) {
                    obj['text'] = i18next.t('EditSubmissionConfirmationForm.underageAgreement', {
                        firstName: person.firstname,
                        lastName: person.lastname,
                        payingFirstName: personPaying.firstname,
                        payingLastName: personPaying.lastname,
                    });
                    obj['nested'] = true;
                }
                optionsArr.push(obj);
            }
        }

        return optionsArr;
    };

    const renderPersonBasket = (person, index) => {
        let personBasket = submission.relationships.basket[person.id];
        let birthdatePresentation = convertDateToPresentationFormat(person.birthdate);
        const basketSummarySumLabel = i18next.t('EditSubmissionConfirmationForm.basketSummarySumLabel');
        const basketSummaryAccordionClose = i18next.t('EditSubmissionConfirmationForm.basketSummaryAccordionClose');
        const basketSummaryAccordionOpen = i18next.t('EditSubmissionConfirmationForm.basketSummaryAccordionOpen');

        return personBasket ? (
            <AccordionItem key={index}>
                <StyledAccordionItemTrigger
                    icon={person.gender === 'm' ? 'man' : 'woman'}
                    description={
                        personBasket && `${basketSummarySumLabel} ${formatAmount(calcPersonPrice(personBasket))}`
                    }
                    textCollapsed={personBasket && basketSummaryAccordionOpen}
                    textExpanded={personBasket && basketSummaryAccordionClose}
                    disabled={!personBasket}
                    type="button"
                >
                    {person.firstname} {person.lastname} <StyledBirthdate>({birthdatePresentation})</StyledBirthdate>
                </StyledAccordionItemTrigger>
                {personBasket && (
                    <AccordionItemContent>
                        <ProductBasketPanel
                            productUser={person}
                            basket={personBasket || {}}
                            totalPrice={personBasket ? formatAmount(calcPersonPrice(personBasket)) : {}}
                            submissionId={submission.id.uuid}
                            backActionMsg={'Zurück'}
                            header={false}
                            footer={false}
                            effectiveDates={effectiveDates?.filter(
                                effectiveDate => effectiveDate.personId === person.id
                            )}
                            currentProducts={
                                currentProducts.find(
                                    currentProduct => currentProduct.insuredNr === person.insuranceNumber
                                )?.products
                            }
                        />
                    </AccordionItemContent>
                )}
            </AccordionItem>
        ) : null;
    };

    return (
        <FinalForm
            {...props}
            decorators={[scrollToFirstErrorDecorator]}
            mutators={mutators}
            initialValues={initialValues}
            render={({ form, ...formRenderProps }) => {
                let { className, disabled, values, ready, handleSubmit, invalid, pristine, updated, fetchErrors } =
                    formRenderProps;

                const basket = submission.relationships.basket;
                const smartcareProductInBasket = checkForProductsInBasket(
                    basket,
                    [PRODUCT_IDENTIFIER_SMARTCARE],
                    'product'
                );
                const processLangAppendix = getProcessLangAppendix(basket);
                const processWithHQLangAppendix = getProcessWithHQLangAppendix(submission);

                const confirmationMyAtupriValue = values?.confirmationMyAtupri;
                // set myAtupri to checked by default - after change use value
                const isConfirmationMyAtupriChecked =
                    confirmationMyAtupriValue === undefined ? true : confirmationMyAtupriValue;

                const confirmationMyAtupriMessageTitle = i18next.t(
                    'EditSubmissionConfirmationForm.confirmationMyAtupriTitle'
                );
                const confirmationMyAtupriMessageText = i18next.t(
                    'EditSubmissionConfirmationForm.confirmationMyAtupriText'
                );
                const confirmationMyAtupriMessageLabel = `${i18next.t(
                    'EditSubmissionConfirmationForm.confirmationMyAtupriLabel'
                )} ${i18next.t('EditSubmissionConfirmationForm.confirmationMembershipMyAtupri')}`;

                const confirmationLegalTitle = i18next.t('EditSubmissionConfirmationForm.confirmationLegalTitle');

                const confirmationAcceptanceTitle = i18next.t(
                    'EditSubmissionConfirmationForm.confirmationAcceptanceTitle'
                );

                const confirmationConfidentialityMessage = i18next.t(
                    'EditSubmissionConfirmationForm.confirmationConfidentiality'
                );
                const confirmationConfidentialityRequiredMessage = i18next.t(
                    'EditSubmissionConfirmationForm.confirmationConfidentialityRequired'
                );

                const confirmationBvvMessage = i18next.t('EditSubmissionConfirmationForm.confirmationBvv');
                const confirmationBvvRequiredMessage = i18next.t(
                    'EditSubmissionConfirmationForm.confirmationBvvRequired'
                );

                const confirmationAccuracyMessage = i18next.t('EditSubmissionConfirmationForm.confirmationAccuracy');
                const confirmationAccuracyRequiredMessage = i18next.t(
                    'EditSubmissionConfirmationForm.confirmationAccuracyRequired'
                );

                const confirmationDossierMessage = i18next.t('EditSubmissionConfirmationForm.confirmationDossier');
                const confirmationDossierRequiredMessage = i18next.t(
                    'EditSubmissionConfirmationForm.confirmationDossierRequired'
                );

                const confirmationAgreementMessage = i18next.t('EditSubmissionConfirmationForm.confirmationAgreement');
                const confirmationAgreementMessageTitle = i18next.t(
                    'EditSubmissionConfirmationForm.confirmationAgreementTitle'
                );

                const confirmationAgreementRequiredMessage = i18next.t(
                    'EditSubmissionConfirmationForm.confirmationAgreementRequired'
                );

                const confirmationMembershipMessage = i18next.t(
                    `EditSubmissionConfirmationForm.confirmationMembership${processLangAppendix}`
                );
                const confirmationMembershipRequiredMessage = i18next.t(
                    'EditSubmissionConfirmationForm.confirmationMembershipRequired'
                );

                const confirmationInfoboxTitle = i18next.t('EditSubmissionConfirmationForm.confirmationInfoboxTitle'); // Vom Versicherungsantrag zum Versicherungsvertrag
                const confirmationInfoboxText = i18next.t('EditSubmissionConfirmationForm.confirmationInfoboxText'); // Wenn wir keine Rückfragen zu Ihren Angaben haben...

                const confirmationSubmitTitleMessage = i18next.t('EditSubmissionConfirmationForm.titleSubmit'); // Versicherungsantrag einreichen
                const confirmationInfoTextMessage = i18next.t('EditSubmissionConfirmationForm.infoText'); // Sie haben nun zwei Möglichkeiten Ihren Antrag einzureichen

                const confirmationSubmitHeadline1 = i18next.t('EditSubmissionConfirmationForm.submitHeadline1'); // Schnell, direkt, online
                const confirmationSubmitHeadline2 = i18next.t('EditSubmissionConfirmationForm.submitHeadline2'); // Ausdrucken und per Post senden
                const confirmationSubmitSubline1 = i18next.t('EditSubmissionConfirmationForm.submitSubline1'); // Reichen Sie Ihren Antrag mit einem Klick online ein. Das PDF des übermittelten Antrags können Sie anschliessend downloaden.
                const confirmationSubmitSubline2 = i18next.t('EditSubmissionConfirmationForm.submitSubline2'); // Laden Sie sich Ihren Antrag als PDF herunter und schicken Sie ihn per Post an Atupri.
                const confirmationSubmitButton1 = i18next.t('EditSubmissionConfirmationForm.submitButton1'); // Antrag online einreichen
                const confirmationSubmitButton2 = i18next.t('EditSubmissionConfirmationForm.submitButton2'); // PDF Download

                const confirmationIsInternalButtonSaveActionMsg = i18next.t(
                    'EditSubmissionConfirmationForm.isInternal.saveActionMsg'
                ); // Änderung abschliessen
                const confirmationIsInternalButtonBackActionMsg = i18next.t(
                    'EditSubmissionConfirmationForm.isInternal.backActionMsg'
                ); // Zurück zur Produktauswahl

                const formOptionsConfirmationMembership = [
                    {
                        key: 'membership',
                        label: i18next.t(`EditSubmissionConfirmationForm.confirmationMembership${processLangAppendix}`),
                        text: i18next.t(
                            `EditSubmissionConfirmationForm.confirmationMembershipInfo${processLangAppendix}`
                        ),
                    },
                ];

                const formOptionsBvv = [
                    {
                        key: 'bvv',
                        label: confirmationBvvMessage,
                        text: i18next.t('EditSubmissionConfirmationForm.confirmationBvvInfo'),
                    },
                ];

                const formOptionsConfirmationConfidentiality = [
                    {
                        key: 'confidentiality',
                        label: confirmationConfidentialityMessage,
                        text: i18next.t('EditSubmissionConfirmationForm.confirmationConfidentialityInfo'),
                    },
                ];

                const formOptionsConfirmationAccuracy = [
                    {
                        key: 'accuracy',
                        label: confirmationAccuracyMessage,
                        text: i18next.t('EditSubmissionConfirmationForm.confirmationAccuracyInfo'),
                    },
                ];

                const formOptionsConfirmationDossier = [
                    {
                        key: 'dossier',
                        label: confirmationDossierMessage,
                        text: i18next.t('EditSubmissionConfirmationForm.confirmationDossierInfo'),
                    },
                ];

                const { updateSubmissionError, showSubmissionError } = fetchErrors || {};
                const errorMessageUpdateSubmission = updateSubmissionError ? (
                    <p className={'error'}>{i18next.t('EditSubmissionConfirmationForm.updateFailed')}</p>
                ) : null;

                const errorMessageShowSubmission = showSubmissionError ? (
                    <p className={'error'}>{i18next.t('EditSubmissionConfirmationForm.showSubmissionFailed')}</p>
                ) : null;

                const classes = classNames('rootConfirmationForm', className);
                const submitReady = (updated && pristine) || ready;
                const submitInProgress = updateInProgress;
                const submitDisabled = disabled || submitInProgress;

                const agreementOptions = createAgreementOptions();

                const persons = submission.relationships.persons;
                const requestYear = moment().add(1, 'years').year();
                const tariffYear = getLowestTariffYearFromBasket(basket[persons[0].id]);
                const isInternal = isInternalProcess(persons, currentProducts);

                // expand all accordions in internal flow
                const preExpanded = persons ? [...persons.keys()].map(item => item + 1) : [];

                return (
                    <Form
                        className={classes}
                        onSubmit={ev => {
                            ev.preventDefault();
                            // Ensure that the form is just submitted once
                            if (forceDisabled) {
                                return;
                            }
                            forceDisabled = true;
                            handleSubmit(ev);
                        }}
                    >
                        {errorMessageUpdateSubmission}
                        {errorMessageShowSubmission}

                        <AutoSave
                            onSave={autosaveValues => {
                                setAutosaved(submission.id.uuid, 'confirmationForm', !invalid, autosaveValues);
                            }}
                            isFormValid={!invalid}
                            debounce={300}
                        />

                        <PrimaryFieldset>
                            <Accordion preExpanded={preExpanded} allowMultipleExpanded allowItemCollapse>
                                {persons.map((person, i) => renderPersonBasket(person, i))}
                            </Accordion>
                        </PrimaryFieldset>

                        <Spacer space="giga" />

                        {!isInternal && (
                            <>
                                <PrimaryFieldset>
                                    <StyledShowcase>
                                        <StyledShowcaseCol>
                                            <P1>{confirmationMyAtupriMessageTitle}</P1>
                                            <StyledShowcaseLabel>
                                                {confirmationMyAtupriMessageText}
                                                <LazyImage
                                                    src={ImgShowCase}
                                                    alt={'Atupri Showcase x'}
                                                    className={'img-showcase only-mobile'}
                                                />
                                            </StyledShowcaseLabel>
                                            <Field name="confirmationMyAtupri">
                                                {fieldProps => (
                                                    <Checkbox
                                                        name={fieldProps.input.name}
                                                        checked={
                                                            smartcareProductInBasket || isConfirmationMyAtupriChecked
                                                        }
                                                        disabled={smartcareProductInBasket}
                                                        onChange={event =>
                                                            fieldProps.input.onChange(event.target.checked)
                                                        }
                                                    >
                                                        <StyledText
                                                            dangerouslySetInnerHTML={{
                                                                __html: sanitizeDOM(confirmationMyAtupriMessageLabel),
                                                            }}
                                                        />
                                                    </Checkbox>
                                                )}
                                            </Field>
                                        </StyledShowcaseCol>
                                        <StyledShowcaseCol>
                                            <LazyImage
                                                src={ImgShowCase}
                                                alt={'Atupri Showcase'}
                                                className={'img-showcase not-mobile'}
                                            />
                                        </StyledShowcaseCol>
                                    </StyledShowcase>
                                </PrimaryFieldset>
                                <Spacer space="giga" />
                            </>
                        )}

                        <PrimaryFieldset>
                            <P1>{confirmationLegalTitle}</P1>

                            <FieldCheckboxGroup
                                label={confirmationMembershipMessage}
                                className={'checkboxes'}
                                id="confirmationMembership"
                                name="confirmationMembership"
                                options={formOptionsConfirmationMembership}
                                validate={requiredFieldArrayCheckbox(confirmationMembershipRequiredMessage)}
                            />

                            {(!isInternal || isVvgProcess(basket)) && (
                                <FieldCheckboxGroup
                                    label={confirmationBvvMessage}
                                    className={'checkboxes'}
                                    id="confirmationBvv"
                                    name="confirmationBvv"
                                    options={formOptionsBvv}
                                    validate={requiredFieldArrayCheckbox(confirmationBvvRequiredMessage)}
                                />
                            )}

                            {isProcessWithHQ(submission) ? (
                                <>
                                    <FieldCheckboxGroup
                                        title={confirmationAcceptanceTitle}
                                        label={confirmationConfidentialityMessage}
                                        className={'checkboxes'}
                                        id="confirmationConfidentiality"
                                        name="confirmationConfidentiality"
                                        options={formOptionsConfirmationConfidentiality}
                                        validate={requiredFieldArrayCheckbox(
                                            confirmationConfidentialityRequiredMessage
                                        )}
                                    />

                                    <FieldCheckboxGroup
                                        label={confirmationAccuracyMessage}
                                        className={'checkboxes'}
                                        id="confirmationAccuracy"
                                        name="confirmationAccuracy"
                                        options={formOptionsConfirmationAccuracy}
                                        validate={requiredFieldArrayCheckbox(confirmationAccuracyRequiredMessage)}
                                    />

                                    <FieldCheckboxGroup
                                        label={confirmationDossierMessage}
                                        className={'checkboxes'}
                                        id="confirmationDossier"
                                        name="confirmationDossier"
                                        options={formOptionsConfirmationDossier}
                                        validate={requiredFieldArrayCheckbox(confirmationDossierRequiredMessage)}
                                    />
                                </>
                            ) : null}

                            {!isInternal && (
                                <FieldCheckboxGroup
                                    label={confirmationAgreementMessage}
                                    title={confirmationAgreementMessageTitle}
                                    text={
                                        <List>
                                            <ListItem>
                                                {i18next.t(
                                                    `EditSubmissionConfirmationForm.confirmationAgreementText${processWithHQLangAppendix}`
                                                )}
                                            </ListItem>
                                            <ListItem>
                                                {i18next.t(
                                                    'EditSubmissionConfirmationForm.confirmationMembershipAppendix'
                                                )}
                                            </ListItem>
                                        </List>
                                    }
                                    className={'checkboxes'}
                                    id="confirmationAgreement"
                                    name="confirmationAgreement"
                                    options={agreementOptions}
                                    validate={requiredAllFieldArrayCheckbox(
                                        confirmationAgreementRequiredMessage,
                                        agreementOptions.length
                                    )}
                                    isListing={true}
                                />
                            )}
                            {((isInternal && requestYear > tariffYear && moment().month() < 8) ||
                                window.localStorage.getItem('mockInfoBox') === 'true') && (
                                <StyledInfoBox>
                                    <StyledInfoBoxIcon iconName="info_bubble" />
                                    <>
                                        {i18next.t('ProductForm.tarifInfoBox', {
                                            currentYear: tariffYear,
                                            requestYear: requestYear,
                                        })}
                                    </>
                                </StyledInfoBox>
                            )}
                        </PrimaryFieldset>

                        <Spacer space="mega" />

                        {isInternal ? (
                            <>
                                <Spacer space="giga" />
                                <EditSubmissionCTA
                                    submitInProgress={submitInProgress}
                                    submitDisabled={submitDisabled}
                                    submitReady={submitReady}
                                    currentSubmissionId={submission.id.uuid}
                                    backActionMsg={confirmationIsInternalButtonBackActionMsg}
                                    saveActionMsg={confirmationIsInternalButtonSaveActionMsg}
                                    tab={'product'}
                                    name="onlineSubmit"
                                    submitOnClick={() => {
                                        form.change('onlineSubmission', true);
                                    }}
                                />
                            </>
                        ) : (
                            <>
                                <StyledInfobox className={'infobox'}>
                                    <StyledInfoboxIcon className={'infobox__icon'}>
                                        <StyledInfoIcon iconName={'info_bubble'} />
                                    </StyledInfoboxIcon>
                                    <StyledInfoboxContent className={'infobox__content'}>
                                        <strong>
                                            <span>{confirmationInfoboxTitle}</span>
                                        </strong>
                                        <p>{confirmationInfoboxText}</p>
                                    </StyledInfoboxContent>
                                </StyledInfobox>

                                <Spacer space="giga" />
                                <Spacer space="giga" />

                                <StyledH1LookedTitle>{confirmationSubmitTitleMessage}</StyledH1LookedTitle>
                                <Spacer space="regular" />
                                <StyledTextInfoBox>
                                    {!smartcareProductInBasket && (
                                        <StyledText>{confirmationInfoTextMessage}</StyledText>
                                    )}
                                </StyledTextInfoBox>

                                <PrimaryFieldset>
                                    <StyledFinalSubmit>
                                        <StyledFinalSubmitCol>
                                            <StyledTextStrong>{confirmationSubmitHeadline1}</StyledTextStrong>
                                            <StyledText>{confirmationSubmitSubline1}</StyledText>
                                        </StyledFinalSubmitCol>
                                        <StyledFinalSubmitCol center={true}>
                                            <Button
                                                className={`submitBtn`}
                                                type="submit"
                                                disabled={submitDisabled || submitInProgress}
                                                name="onlineSubmit"
                                                onClick={() => {
                                                    form.change('onlineSubmission', true);
                                                }}
                                                buttonText={confirmationSubmitButton1}
                                            />
                                        </StyledFinalSubmitCol>
                                    </StyledFinalSubmit>
                                </PrimaryFieldset>
                                {!smartcareProductInBasket && (
                                    <>
                                        <Spacer space="regular" />
                                        <PrimaryFieldset>
                                            <StyledFinalSubmit>
                                                <StyledFinalSubmitCol>
                                                    <StyledTextStrong>{confirmationSubmitHeadline2}</StyledTextStrong>
                                                    <StyledText>{confirmationSubmitSubline2}</StyledText>
                                                </StyledFinalSubmitCol>
                                                <StyledFinalSubmitCol center={true}>
                                                    <Button
                                                        className={`submitBtn`}
                                                        type="submit"
                                                        disabled={submitDisabled || submitInProgress}
                                                        outlined={true}
                                                        name="offlineSubmit"
                                                        onClick={() => {
                                                            form.change('onlineSubmission', false);
                                                        }}
                                                        buttonText={confirmationSubmitButton2}
                                                    />
                                                </StyledFinalSubmitCol>
                                            </StyledFinalSubmit>
                                        </PrimaryFieldset>
                                    </>
                                )}
                            </>
                        )}
                    </Form>
                );
            }}
        />
    );
};

EditSubmissionConfirmationForm.defaultProps = { className: null, fetchErrors: null, updateError: null };

EditSubmissionConfirmationForm.propTypes = {
    className: string,
    onSubmit: func.isRequired,
    disabled: bool.isRequired,
    ready: bool.isRequired,
    updated: bool.isRequired,
    updateError: propTypes.error,
    updateInProgress: bool.isRequired,
    fetchErrors: shape({
        createSubmissionOfferError: propTypes.error,
        showSubmissionError: propTypes.error,
        updateSubmissionError: propTypes.error,
    }),
    effectiveDates: array,
    currentProducts: array,
};

export default EditSubmissionConfirmationForm;
