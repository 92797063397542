import React from 'react';
import i18next from 'i18next';
import { string, object, number, bool } from 'prop-types';
import Person from '@src/components/PersonSelect/partials/Person';
import Total from '@src/components/PersonSelect/partials/Total';
import PersonEdit from '@src/components/PersonSelect/partials/PersonEdit';

import { StyledPersonItemContainer } from '../PersonSelect.style';

const PersonItem = props => {
    const { totalPrice, submissionId, isInternalProcess, currentOkpProduct, ...rest } = props;

    return (
        <StyledPersonItemContainer>
            {isInternalProcess ? (
                <React.Fragment>
                    <Person {...rest} totalPrice={null} />
                    <Total
                        totalPrice={currentOkpProduct?.monthlyNetPremium?.value || 0}
                        label={i18next.t('PersonSelect.totalOld')}
                    />
                    <Total totalPrice={totalPrice} label={i18next.t('PersonSelect.totalNew')} layout="primary" />
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <Person {...rest} totalPrice={totalPrice} />
                    <PersonEdit />
                    <Total totalPrice={totalPrice} label={i18next.t('PersonSelect.total')} layout="primary" />
                </React.Fragment>
            )}
        </StyledPersonItemContainer>
    );
};

PersonItem.defaultProps = {
    currentOkpProduct: null,
    firstname: '',
    hasMultiplePersons: false,
    lastname: '',
};

PersonItem.propTypes = {
    birthdate: string.isRequired,
    currentOkpProduct: object,
    counter: number.isRequired,
    firstname: string,
    gender: string.isRequired,
    hasMultiplePersons: bool,
    isInternalProcess: bool.isRequired,
    lastname: string,
    submissionId: string.isRequired,
    totalPrice: number.isRequired,
};

export default PersonItem;
