import React from 'react';
import { array, bool, func, number, object, string } from 'prop-types';
import classNames from 'classnames';

import { AutocompleteWrapper, AutocompleteMenu, AutocompleteMenuItem, Input } from '@atupri/atupri-component-library';
import { getLocationLabel } from '@src/util/zip';

const AtriumAutocomplete = ({
    name,
    label,
    entries,
    disabled,
    onBlur,
    isOpen,
    comboboxProps,
    inputProps,
    menuProps,
    labelProps,
    getItemProps,
    highlightedIndex,
    errorText = '',
    inverted = false,
}) => {
    return (
        <AutocompleteWrapper classes={'relative'}>
            <div {...comboboxProps}>
                <Input
                    id={`${name}.zipPlaceholder`}
                    name={`${name}.zipPlaceholder`}
                    label={label}
                    labelProps={{ ...labelProps }}
                    {...inputProps}
                    disabled={disabled}
                    maxLength={4}
                    onBlur={onBlur}
                    /**
                     * prevent autocomplete in modern browsers
                     * https://developer.mozilla.org/en-US/docs/Web/Security/Securing_your_site/Turning_off_form_autocompletion#preventing_autofilling_with_autocompletenew-password
                     */
                    autoComplete="off"
                    errorText={errorText}
                    variant={inverted ? 'inverted' : 'default'}
                />
            </div>
            <AutocompleteMenu
                classes={classNames('absolute', 'z-50', 'w-full', 'bg-background-default', {
                    hidden: !isOpen || entries.length === 0,
                })}
                {...menuProps}
            >
                {entries.map((item, index) => (
                    <AutocompleteMenuItem
                        key={`${item.ZIP}_${index}`}
                        {...getItemProps({ item, index })}
                        isHighlighted={highlightedIndex === index}
                    >
                        {getLocationLabel(item)}
                    </AutocompleteMenuItem>
                ))}
            </AutocompleteMenu>
        </AutocompleteWrapper>
    );
};

AtriumAutocomplete.propTypes = {
    name: string,
    label: string,
    entries: array,
    disabled: bool,
    onBlur: func,
    isOpen: bool,
    comboboxProps: object,
    inputProps: object,
    menuProps: object,
    getItemProps: func,
    highlightedIndex: number,
    errorText: string,
    inverted: bool,
};

export default AtriumAutocomplete;
