import styled from '@emotion/styled';
import { rem } from 'polished';
import { mixins } from 'atupri-component-library/lib/core/styles/theme/default';

export const StyledAnswerItem = styled.div(({ answerType }) => {
    let styles = [];

    if (answerType === 'toggle') {
        styles.push(`
        height: 100%;
      `);
    }

    styles.push(
        mixins.responsiveStyles({
            'padding-bottom': {
                zero: `${rem(16)}`,
                medium: `${rem(24)}`,
            },
        })
    );

    return styles;
});
