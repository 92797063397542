import React from 'react';
import i18next from 'i18next';

// containers
import { StaticPage } from '@src/containers';

// components
import { Main } from '@src/components';
import { Footer, Header, HeroTeaser, HeroTitle, HeroTitleLine } from 'atupri-component-library/lib/components';
import { Card, H2 } from 'atupri-component-library/lib/primitives';
import { Spacer, Grid } from 'atupri-component-library/lib/helpers';

// data
import { footerData, headerData } from '@src/data';

//util
import { isMobileApp } from '@src/util/mobileAppDetection';

// style
import { StyledContainer, StyledGridItem } from './NotFoundPage.style';
import ImgHero from '@assets/img/homepage-hero-compressor_404.jpg';

export const NotFoundPage = () => {
    return (
        <StaticPage>
            {!isMobileApp() && <Header {...headerData()} />}
            <Main>
                <StyledContainer>
                    <HeroTeaser image={ImgHero}>
                        <HeroTitle>
                            <HeroTitleLine>{i18next.t('NotFoundPage.hero1', 'Hmm...')}</HeroTitleLine>
                            <HeroTitleLine>{i18next.t('NotFoundPage.hero2', 'Diese Seite')}</HeroTitleLine>
                            <HeroTitleLine>{i18next.t('NotFoundPage.hero3', 'ist unauffindbar')}</HeroTitleLine>
                        </HeroTitle>
                    </HeroTeaser>
                    <Spacer space="kappa" />
                    <H2>{i18next.t('NotFoundPage.title', 'Halten Sie stattdessen hier Ausschau:')}</H2>
                    <Spacer space="zeta" />
                    <Grid columns={{ zero: 1, medium: 3 }}>
                        <StyledGridItem>
                            <Card
                                title={i18next.t('NotFoundPage.card1.title', 'Atupri kontaktieren')}
                                text={i18next.t('NotFoundPage.card1.text', 'Wir sind gerne für Sie da.')}
                                link={{
                                    target: '_self',
                                    title: i18next.t('NotFoundPage.card1.title', 'Atupri kontaktieren'),
                                    href: i18next.t('NotFoundPage.card1.href', 'https://www.atupri.ch/de/kontakt'),
                                }}
                                icons={[
                                    {
                                        title: i18next.t('NotFoundPage.card1.title', 'Atupri kontaktieren'),
                                        iconName: i18next.t('NotFoundPage.card1.iconIdentifier', 'message'),
                                    },
                                ]}
                                variant="emphasized"
                            />
                        </StyledGridItem>
                        <StyledGridItem>
                            <Card
                                title={i18next.t('NotFoundPage.card2.title', 'Produktübersicht')}
                                text={i18next.t('NotFoundPage.card2.text', 'Unsere Lösungen genau für Sie.')}
                                link={{
                                    target: '_self',
                                    title: i18next.t('NotFoundPage.card2.title', 'Produktübersicht'),
                                    href: i18next.t(
                                        'NotFoundPage.card2.href',
                                        'https://www.atupri.ch/de/versicherungen'
                                    ),
                                }}
                                icons={[
                                    {
                                        title: i18next.t('NotFoundPage.card2.title', 'Produktübersicht'),
                                        iconName: i18next.t('NotFoundPage.card2.iconIdentifier', 'logo'),
                                    },
                                ]}
                                variant="emphasized"
                            />
                        </StyledGridItem>
                        <StyledGridItem>
                            <Card
                                title={i18next.t('NotFoundPage.card3.title', 'Prämie berechnen')}
                                text={i18next.t(
                                    'NotFoundPage.card3.text',
                                    'Jetzt herausfinden, wie viel Sie sparen können.'
                                )}
                                link={{
                                    target: '_self',
                                    title: i18next.t('NotFoundPage.card3.title', 'Prämie berechnen'),
                                    href: i18next.t('NotFoundPage.card3.href', 'https://praemienrechner.atupri.ch'),
                                }}
                                icons={[
                                    {
                                        title: i18next.t('NotFoundPage.card3.title', 'Prämie berechnen'),
                                        iconName: i18next.t('NotFoundPage.card3.iconIdentifier', 'piggy_bank'),
                                    },
                                ]}
                                variant="emphasized"
                            />
                        </StyledGridItem>
                    </Grid>
                    <Spacer space="lambda" />
                </StyledContainer>
            </Main>
            {!isMobileApp() && <Footer {...footerData()} />}
        </StaticPage>
    );
};

NotFoundPage.propTypes = {};

export default NotFoundPage;
