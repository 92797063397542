import axios from 'axios';
import { Configuration } from '@src/generated';
import { environments, getEnvironment, isMyAtupriOrAkupoPath } from './env';
import { log } from '@src/middleware';

const ATUPRI_DOMAIN = 'atupri.ch';
const ATUPRINET_DOMAIN = 'atuprinet.ch';
const origin = (window.__ENV__ ? window.__ENV__.HOST : ATUPRINET_DOMAIN).toLowerCase();

const getMyAtupriApiDomain = () => {
    return origin?.endsWith(ATUPRINET_DOMAIN) ? ATUPRINET_DOMAIN : ATUPRI_DOMAIN;
};

const getMyAtupriApiSubdomainPostfix = () => {
    const internal = origin?.endsWith(ATUPRINET_DOMAIN);

    // atuprinet has always the environment as postfix
    switch (getEnvironment()) {
        case environments.local: {
            return '-dev';
        }
        case environments.prod: {
            return internal ? `-${getEnvironment()}` : '';
        }
        default: {
            return `-${getEnvironment()}`;
        }
    }
};

export const getApiBasePath = () => {
    return `https://myatupri-api${getMyAtupriApiSubdomainPostfix()}.${getMyAtupriApiDomain()}`;
};

export const getLoggedInUserId = () => {
    let userId = NaN;

    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development' || process.env.JEST) {
        userId = parseInt(process.env.REACT_APP_USER_ID ?? '');
    } else if (!!window.__ENV__ && !!window.__ENV__.USER_ID) {
        userId = parseInt(window.__ENV__.USER_ID);
    }

    if (isNaN(userId)) {
        userId = null;

        if (isMyAtupriOrAkupoPath) {
            log.debug('=== No logged in user ===');
        }
    }

    return userId;
};

// The base configuration for all services
const myAtupriConfig = new Configuration({
    basePath: getApiBasePath(),
    baseOptions: {
        withCredentials: 'include',
        // Explicitly set the x-userid header
        // Otherwise, the openapi generated code will set the header as
        // String(JSON.stringify(userId))
        // resulting in "\"XXXX\""
        headers: {
            'x-userid': getLoggedInUserId(),
            accept: 'application/vnd.myatupri.api.v3+json',
        },
    },
});

export default myAtupriConfig;

// API response handling - https://github.com/axios/axios#interceptors
axios.interceptors.response.use(
    function (response) {
        // Any status code that lies within the range of 2xx triggers this function

        return response;
    },
    function (error) {
        // Any status code that falls outside the range of 2xx triggers this function

        //  If the API returns a 401 the session is no longer valid. The logout is forced
        if (error?.response?.status === 401) {
            window.location.href = '/login/sls/auth?cmd=logoutmain';
        }
        return Promise.reject(error);
    }
);
