import { environmentConfiguration, environments } from './env';

export const getGoogleMapsApiKey = () => {
    let apiKey = '';

    if (environmentConfiguration.env === environments.local) {
        apiKey = process?.env?.GOOGLE_MAPS_APIKEY ?? '';
    } else if (!!window.__ENV__ && !!window.__ENV__.GOOGLE_MAPS_APIKEY) {
        apiKey = window.__ENV__.GOOGLE_MAPS_APIKEY;
    }

    return apiKey;
};
