import { log } from '@src/middleware';

const autosavePostfix = '_AUTOSAVED';

export const setAutosaved = (submissionId, formName, isFormValid, values, personId = null) => {
    let autosaved = getAutosaved(submissionId) || {};

    if (personId) {
        if (autosaved[formName]) {
            autosaved[formName][personId] = {
                isFormValid: isFormValid,
                values: values,
            };
        } else {
            autosaved[formName] = {
                [personId]: {
                    isFormValid: isFormValid,
                    values: values,
                },
            };
        }
    } else {
        autosaved[formName] = {
            isFormValid: isFormValid,
            values: values,
        };
    }

    window.localStorage.setItem(submissionId + autosavePostfix, JSON.stringify(autosaved));
};

export const getAutosaved = (submissionId, formName = null, personId = null) => {
    const autosaved = window.localStorage.getItem(submissionId + autosavePostfix);
    let autosavedJson = null;

    if (autosaved) {
        autosavedJson = JSON.parse(autosaved);

        if (autosavedJson && formName) {
            autosavedJson = formName in autosavedJson && autosavedJson[formName];
        }

        if (autosavedJson && personId) {
            autosavedJson = personId in autosavedJson && autosavedJson[personId];
        }
    }

    return autosavedJson;
};

export const getAutosavedForPerson = (submissionId, formName, personId) => {
    const autosaved = getAutosaved(submissionId, formName);

    return autosaved && personId in autosaved ? autosaved[personId] : null;
};

export const getAutosavedValues = (submissionId, formName, personId) => {
    const autosaved = getAutosavedForPerson(submissionId, formName, personId);

    return autosaved?.values || null;
};

export const isAutosavedFormInvalid = (submissionId, formName, personId = null) => {
    let isFormInvalid = false;

    if (!submissionId || !formName) {
        log.error('No `submissionId` or `formName` provided.', { submissionId, formName });
        return true;
    }

    if (personId) {
        const autosaved = getAutosavedForPerson(submissionId, formName, personId);
        if (autosaved) {
            isFormInvalid = !autosaved.isFormValid;
        }
    } else {
        const autosaved = getAutosaved(submissionId, formName);

        for (const autosavedPerson in autosaved) {
            isFormInvalid = isFormInvalid || !autosaved[autosavedPerson].isFormValid;
        }
    }

    return isFormInvalid;
};

export const clearAutosaved = submissionId => {
    window.localStorage.removeItem(submissionId + autosavePostfix);
};
