import styled from '@emotion/styled';
import { rem } from 'polished';
import { mixins } from 'atupri-component-library/lib/core/styles/theme/default';
import { Container } from 'atupri-component-library/lib/helpers';

export const StyledContainer = styled(Container)`
    width: 100%;

    ${mixins.responsiveStyles({
        'padding-top': { zero: rem(48), medium: rem(64) },
        'padding-bottom': { zero: rem(80), medium: rem(128) },
    })}
`;
