import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { rem } from 'polished';
import { mixins, spaces, text, colors } from 'atupri-component-library/lib/core/styles/theme/default';
import { Button } from 'atupri-component-library/lib/primitives';

export const StyledSelectionRowContainer = styled.div`
    width: 100%;
`;

export const StyledSelectionRow = styled.div(
    ({ isActive }) => css`
        display: flex;
        background-color: ${isActive ? colors.blue300 : colors.white};
        border: ${!isActive && `${rem(1)} solid ${colors.blue500}`};
        border-radius: ${rem(spaces.beta)};

        ${mixins.responsiveStyles({
            'flex-wrap': { zero: 'wrap', large: 'nowrap' },
            'flex-direction': {
                zero: 'column',
                medium: 'row',
            },
            'align-items': {
                zero: 'flex-start',
                medium: 'center',
            },
            'padding-top': { zero: spaces.gamma, medium: spaces.delta, large: spaces.zeta },
            'padding-left': { zero: spaces.gamma, medium: spaces.epsilon, large: spaces.eta },
            'padding-bottom': { zero: spaces.gamma, medium: spaces.delta, large: spaces.zeta },
            'padding-right': { zero: spaces.gamma, medium: spaces.epsilon, large: spaces.eta },
        })}
    `
);

export const StyledSelectionRowLabels = styled.div`
    ${text.p4}
    flex: 1;

    ${mixins.responsiveStyles({
        'flex-basis': { zero: '100%', large: 'auto' },
    })}

    > div:nth-of-type(1) {
        ${text.p1}
        margin-bottom: ${rem(5)};
    }

    > div > span:not(:last-child):after {
        content: ', ';
    }
`;

export const StyledSelectionRowControls = styled.div`
    display: flex;
    color: ${colors.grey800};

    ${mixins.responsiveStyles({
        'margin-top': { zero: spaces.epsilon, medium: spaces.delta, large: 0 },
        'flex-direction': { zero: 'column', medium: 'row' },
    })}

    button {
        white-space: nowrap;

        ${mixins.responsiveStyles({
            'font-size': { zero: 14, large: 16 },
            'line-height': { zero: 20 / 14, large: 24 / 16 },
        })}

        + button {
            ${mixins.responsiveStyles({
                'margin-top': { zero: spaces.delta, medium: 0 },
                'margin-left': { medium: spaces.epsilon, wide: spaces.theta },
            })}
        }
    }
`;

export const StyledPrimaryButton = styled(Button)`
    color: ${colors.primary};
`;
