import React from 'react';
import { arrayOf, bool, func, node, shape, string } from 'prop-types';
import { Field } from 'react-final-form';
import { Select } from 'atupri-component-library/lib/primitives';

const FieldSelect = ({
    id,
    name,
    label,
    options,
    validate,
    onChange,
    castValueToNumber = false,
    isRequired = true,
    disabled = false,
}) => {
    return (
        <Field id={id} name={name} label={label} options={options} validate={validate}>
            {fieldProps => (
                <Select
                    name={fieldProps.input.name}
                    label={fieldProps.label}
                    options={fieldProps.options}
                    value={castValueToNumber ? Number(fieldProps.input.value) : fieldProps.input.value}
                    onChange={event => {
                        onChange?.(event);
                        fieldProps.input.onChange(event.target.value);
                    }}
                    errorText={fieldProps.meta.touched && fieldProps.meta.error}
                    isRequired={isRequired}
                    disabled={disabled}
                    key={`${name}__${fieldProps.input.value}`}
                />
            )}
        </Field>
    );
};

FieldSelect.propTypes = {
    id: string.isRequired,
    name: string.isRequired,
    label: string,
    options: arrayOf(
        shape({
            value: string.isRequired,
            label: node.isRequired,
        })
    ),
    validate: func,
    onChange: func,
    castValueToNumber: bool,
    isRequired: bool,
    disabled: bool,
};

export default FieldSelect;
