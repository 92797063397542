import React from 'react';
import { bool, object } from 'prop-types';
import i18next from 'i18next';

// components
import { NamedLink } from '@src/components';
import { P3, Button } from 'atupri-component-library/lib/primitives';
import { useWindowSize } from 'atupri-component-library/lib/core/utils/Hooks';
import { isUp } from 'atupri-component-library/lib/core/utils/Breakpoints';

// util
import { calcPersonPrice } from '@src/util/basket';
import { formatAmount } from '@src/util/currency';
import { convertDateToPresentationFormat } from '@src/util/date';
import { getGenderIconName } from '@src/util/person';
import { getPersonIndex } from '@src/util/submission';

// style
import {
    StyledCockpitItem,
    StyledPersonWrap,
    StyledPersonal,
    StyledUserIcon,
    StyledUserInfo,
    StyledUserName,
    StyledStatusWrap,
    StyledStatusTag,
    StyledCTAWrap,
    StyledBreakpointWrap,
    StyledPriceWrap,
    StyledPriceInner,
    StyledPrice,
    StyledInfoBox,
    StyledInfoBoxLabel,
    StyledInfoBoxValue,
    StyledOverview,
    StyledOverviewIcon,
    StyledNamedLinkInner,
} from './CockpitItem.style';

const CockpitItem = props => {
    const { submission, person, basket, personDatasetComplete = false } = props;

    const msgTitleMonthlyPrice = i18next.t('CockpitItem.msgTitleMonthlyPrice');
    const msgLinkOverview = i18next.t('CockpitItem.msgLinkOverview');
    const msgCtaEnter = i18next.t('CockpitItem.ctaEnter');
    const msgCtaChange = i18next.t('CockpitItem.ctaChange');
    const statusInProgress = i18next.t('status.inProgress');
    const statusDone = i18next.t('status.done');
    const personIndex = getPersonIndex(submission, person.id);
    const birthdatePresentation = convertDateToPresentationFormat(person.birthdate);
    const { width: windowWidth } = useWindowSize();

    return (
        <StyledCockpitItem
            shadow="ctaBlueHover"
            display="flex"
            flexDirection={isUp('small', windowWidth) ? 'row' : 'column'}
            alignItems="center"
            minHeight={isUp('medium', windowWidth) ? 164 : 250}
        >
            <StyledPersonWrap>
                <StyledBreakpointWrap>
                    <StyledPersonal>
                        <StyledUserIcon iconName={getGenderIconName(person.gender)} />
                        <StyledUserInfo>
                            <StyledUserName>
                                {person.firstname || person.lastname ? (
                                    <>
                                        {person.firstname} {person.lastname}
                                    </>
                                ) : (
                                    <>
                                        {i18next.t('PersonSelect.person')} {personIndex + 1}
                                    </>
                                )}
                            </StyledUserName>
                            <P3>({birthdatePresentation})</P3>
                        </StyledUserInfo>
                    </StyledPersonal>

                    <StyledStatusWrap>
                        {personDatasetComplete ? (
                            <StyledStatusTag text={statusDone} variant="DONE" />
                        ) : (
                            <StyledStatusTag text={statusInProgress} variant="IN_PROGRESS" />
                        )}
                    </StyledStatusWrap>
                </StyledBreakpointWrap>

                <StyledCTAWrap>
                    <NamedLink name="HealthQuestionPage" params={{ userId: person.id }}>
                        {personDatasetComplete ? (
                            <Button iconBefore="pen" outlined buttonText={msgCtaChange} />
                        ) : (
                            <Button iconBefore="pen" buttonText={msgCtaEnter} />
                        )}
                    </NamedLink>
                </StyledCTAWrap>
            </StyledPersonWrap>

            <StyledPriceWrap>
                <StyledPriceInner>
                    <StyledPrice>
                        <StyledInfoBox>
                            <StyledInfoBoxLabel>{msgTitleMonthlyPrice}</StyledInfoBoxLabel>
                            <StyledInfoBoxValue>
                                <span>{formatAmount(calcPersonPrice(basket))}</span>
                            </StyledInfoBoxValue>
                        </StyledInfoBox>
                    </StyledPrice>
                    <StyledOverview>
                        <NamedLink name="ProductBasketPage" params={{ userId: person.id }}>
                            <StyledNamedLinkInner>
                                <StyledOverviewIcon iconName="link_arrow" />
                                <span>{msgLinkOverview}</span>
                            </StyledNamedLinkInner>
                        </NamedLink>
                    </StyledOverview>
                </StyledPriceInner>
            </StyledPriceWrap>
        </StyledCockpitItem>
    );
};

CockpitItem.propTypes = {
    submission: object,
    person: object,
    basket: object,
    personDatasetComplete: bool,
};

export default CockpitItem;
