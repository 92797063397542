import React, { useRef } from 'react';
import { array, object } from 'prop-types';
import i18next from 'i18next';
import { Field } from 'react-final-form';

// components
import { Alert, Loader } from 'atupri-component-library/lib/components';
import { EmphasizedToggle, P2, P3 } from 'atupri-component-library/lib/primitives';
import { Spacer } from 'atupri-component-library/lib/helpers';

// util
import { formatAmount } from '@src/util/currency';
import { sanitizeDOM } from '@src/util/sanitize';

// style
import { StyledEmphasizedToggle, StyledGrid, StyledGridItem } from '../QuickCalculatorForm.style';

const QuickCalculatorOkpForm = props => {
    const { apiData, tariffs, products } = props;

    if (tariffs) {
        if (apiData?.tariffs?.length === 0) {
            return (
                <Alert
                    variant="infobox"
                    version="attention"
                    border="round"
                    text={i18next.t('QuickCalculatorForm.noTariffs')}
                />
            );
        }
    }

    return (
        <StyledGrid>
            {products.map((product, index) => {
                return (
                    <StyledGridItem
                        key={'product-' + product}
                        size={{
                            zero: 'full',
                            medium: 12 / products.length,
                        }}
                    >
                        <StyledEmphasizedToggle>
                            <Field name="okpTariffId">
                                {p => (
                                    <EmphasizedToggle
                                        {...p.input}
                                        value={tariffs?.[index]?.id ?? ''}
                                        selectedValue={p.input.value}
                                        onChange={event => p.input.onChange(event.target.value)}
                                        controlType="radio"
                                        flexDirection="column"
                                        alignItems="flex-start"
                                        ToggleTitleComponent={P2}
                                        toggleTitleText={
                                            tariffs?.[index]
                                                ? i18next.t(
                                                      `QuickCalculatorForm.product.${tariffs[
                                                          index
                                                      ].parent.toLowerCase()}`,
                                                      tariffs[index].parent,
                                                      product
                                                  )
                                                : ' '
                                        }
                                        hasControl={!!tariffs?.[index]}
                                        disabled={!tariffs?.[index]}
                                        floatingFocusTargetId="radio2"
                                    >
                                        <Spacer space="delta" />
                                        {tariffs?.[index] ? (
                                            <P3
                                                dangerouslySetInnerHTML={{
                                                    __html: sanitizeDOM(
                                                        tariffs?.[index]
                                                            ? i18next.t('QuickCalculatorForm.price', {
                                                                  price: formatAmount(tariffs[index].price, false),
                                                              })
                                                            : i18next.t('QuickCalculatorForm.noTariffForProduct')
                                                    ),
                                                }}
                                            />
                                        ) : (
                                            <>
                                                <Loader color="blue800" size={32} strokeWidth={3} />
                                                <Spacer space="beta" />
                                            </>
                                        )}
                                    </EmphasizedToggle>
                                )}
                            </Field>
                        </StyledEmphasizedToggle>
                    </StyledGridItem>
                );
            })}
        </StyledGrid>
    );
};

QuickCalculatorOkpForm.propTypes = {
    apiData: object,
    initialValues: object.isRequired,
    tariffs: array,
    products: array.isRequired,
};

export default QuickCalculatorOkpForm;
