import axios from 'axios';
import i18next from 'i18next';
import { environmentConfiguration } from '@src/config/env';
import queryString from 'query-string';

import {
    healthQuestionsDataChild as staticQuestionsChild,
    healthQuestionsDataAdult as staticQuestionsAdult,
} from '@src/data';

import config from '@src/config';
import { log } from '@src/middleware';
import { findPersonById, isChild } from '@src/util/person';

const QUESTION_API = `${config.apiEsbBase}/health/v1/healthquestions`;

const { dev } = environmentConfiguration;

export const API_GET_HEALTHQUESTIONS = (personId, ownSubmission, birthdate, gender, productlist, insuranceStart) =>
    new Promise((resolve, reject) => {
        log.debug('=== API_GET_HEALTHQUESTIONS ===', {
            personId,
            ownSubmission,
            birthdate,
            gender,
            productlist,
            insuranceStart,
        });

        let res = {
            status: 200,
            statusText: '',
            data: {},
        };
        let resObj = JSON.parse(JSON.stringify(res));
        let lang = 'D';

        if (i18next.language === 'fr') {
            lang = 'F';
        } else if (i18next.language === 'it') {
            lang = 'I';
        }

        const payload = {
            geschlecht: gender.toUpperCase(),
            geburtsdatum: birthdate,
            beginndatum: insuranceStart,
            tarifliste: productlist,
            sprache: lang,
        };

        const { persons } = ownSubmission.relationships;
        const person = findPersonById(personId, persons);
        const staticQuestions = isChild(person) ? staticQuestionsChild : staticQuestionsAdult;

        axios
            .get(QUESTION_API + '?' + queryString.stringify(payload), {
                timeout: 30000,
            })
            .then(response => {
                let data = response.data;

                if (typeof response.data === 'string') {
                    try {
                        data = JSON.parse(data);
                    } catch (e) {
                        log.error({ code: e.code, error: e.message });
                        reject({ data: 'Error.API', code: '49' });
                        data = null;
                    }
                }
                if (data) {
                    resObj.data.questions = response.data;
                } else if (dev) {
                    // fallback json for dev
                    log.info('HealthQuestions format error. Using fallback json file');
                    resObj.data.questions = staticQuestions;
                } else {
                    // data exception in prod
                    reject({ data: 'Error.code', code: '50' });
                }

                resolve(resObj);
            })
            .catch(err => {
                log.error({ code: err.code, error: err.message });

                // temporary fallback for development
                if (dev) {
                    log.info('HealthQuestions API error or timeout. Using fallback json file');
                    resObj.data.questions = staticQuestions;
                    resolve(resObj);
                } else {
                    reject({ data: 'Error.API', code: '51' });
                }
            });
    });
