import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { rem } from 'polished';
import { text, colors } from 'atupri-component-library/lib/core/styles/theme/default';

export const StyledCheckboxList = styled.ul(({ isListing }) => {
    let styles = [
        `
    list-style: none;
    padding-left: 0;
    margin: 0;
  `,
    ];

    if (isListing) {
        styles.push(`
        margin-top: ${rem(30)};
      `);
    }
    return styles;
});

export const StyledParagraph = styled.div(
    ({ bold }) => css`
        ${text.p3};
        font-weight: ${bold && '700'};

        a {
            color: ${colors.primary};
        }
    `
);

export const StyledCheckboxListItemisListing = styled.li(({ isListing }) => {
    let styles = [``];

    if (isListing) {
        styles.push(`
        min-height: ${rem(80)};
        display: flex;
        align-items: center;
        border-bottom: 1px solid ${colors.primary};
        padding: 16px 0;
        box-sizing: border-box;

        &:first-of-type {
          border-top: 1px solid ${colors.primary};
        }
      `);
    }
    return styles;
});
