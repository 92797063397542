import React from 'react';
import { array, bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import i18next from 'i18next';
import { ensureOwnSubmission } from '@src/util/data';
import { isInternalProcess } from '@src/util/process';
import { EditSubmissionConfirmationForm } from '@src/forms';
import { WizardHeader } from '@src/components';
import { StyledContainer } from './EditSubmissionConfirmationPanel.style';

const EditSubmissionConfirmationPanel = props => {
    const {
        className,
        rootClassName,
        submission,
        disabled,
        ready,
        onSubmit,
        onChange,
        panelUpdated,
        updateInProgress,
        errors,
        effectiveDates,
        currentProducts,
    } = props;

    const classes = classNames(rootClassName || 'rootTabPanel', className);
    const currentSubmission = ensureOwnSubmission(submission);
    const {
        confirmationMyAtupri = true,
        confirmationConfidentiality = [],
        confirmationMembership = [],
        confirmationAgreement = [],
        confirmationAccuracy = [],
        confirmationDossier = [],
        confirmationBvv = [],
    } = currentSubmission.attributes.privateData;
    const isInternal = isInternalProcess(submission.relationships.persons, currentProducts);

    const panelTitle = i18next.t('EditSubmissionConfirmationPanel.panelTitle');
    const panelText = i18next.t(
        isInternal
            ? 'EditSubmissionConfirmationPanel.panelTextExistingCustomer'
            : 'EditSubmissionConfirmationPanel.panelText'
    );

    return (
        <StyledContainer className={classes}>
            <WizardHeader panelTitle={panelTitle} panelText={panelText} />
            <EditSubmissionConfirmationForm
                className={'form'}
                initialValues={{
                    confirmationMyAtupri,
                    confirmationConfidentiality,
                    confirmationMembership,
                    confirmationAgreement,
                    confirmationAccuracy,
                    confirmationDossier,
                    confirmationBvv,
                }}
                onSubmit={values => {
                    let {
                        confirmationMyAtupri,
                        confirmationConfidentiality,
                        confirmationMembership,
                        confirmationAgreement,
                        confirmationAccuracy,
                        confirmationDossier,
                        confirmationBvv,
                        onlineSubmission,
                    } = values;

                    const updateValues = {
                        privateData: {
                            confirmationMyAtupri,
                            confirmationConfidentiality,
                            confirmationMembership,
                            confirmationAgreement,
                            confirmationAccuracy,
                            confirmationDossier,
                            confirmationBvv,
                            onlineSubmission,
                        },
                    };

                    onSubmit(updateValues);
                }}
                onChange={onChange}
                disabled={disabled}
                ready={ready}
                updated={panelUpdated}
                updateError={errors.updateSubmissionError}
                updateInProgress={updateInProgress}
                fetchErrors={errors}
                submission={submission}
                effectiveDates={effectiveDates}
                currentProducts={currentProducts}
            />
        </StyledContainer>
    );
};

EditSubmissionConfirmationPanel.defaultProps = {
    className: null,
    rootClassName: null,
    errors: null,
    submission: null,
};

EditSubmissionConfirmationPanel.propTypes = {
    className: string,
    rootClassName: string,

    // We cannot use propTypes.submission since the submission might be an offer.
    submission: object,
    disabled: bool.isRequired,
    ready: bool.isRequired,
    onSubmit: func.isRequired,
    onChange: func.isRequired,
    panelUpdated: bool.isRequired,
    updateInProgress: bool.isRequired,
    errors: object,
    effectiveDates: array,
    currentProducts: array,
};

export default EditSubmissionConfirmationPanel;
