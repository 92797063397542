import styled from '@emotion/styled';
import { mixins, spaces } from 'atupri-component-library/lib/core/styles/theme/default';
import { Box, Grid, GridItem } from 'atupri-component-library/lib/helpers';

export const StyledBox = styled(Box)`
    ${mixins.responsiveStyles({
        'padding-top': { zero: spaces.epsilon, medium: spaces.theta },
        'padding-left': { zero: spaces.gamma, medium: spaces.theta },
        'padding-bottom': { zero: spaces.epsilon, medium: spaces.theta },
        'padding-right': { zero: spaces.gamma, medium: spaces.theta },
    })};
`;

export const StyledGrid = styled(Grid)`
    ${mixins.responsiveStyles({
        'margin-top': { zero: -spaces.delta, medium: -spaces.epsilon },
    })}
`;

export const StyledGridItem = styled(GridItem)`
    ${mixins.responsiveStyles({
        'padding-top': { zero: spaces.delta, medium: spaces.epsilon },
    })}
`;
