import moment from 'moment';

// date format: e.g. 1984-12-17
export const BACKEND_DATE_FORMAT = 'YYYY-MM-DD';
export const PRESENTATION_DATE_FORMAT = 'DD.MM.YYYY';

export const getAgeFromDate = date => {
    return moment().diff(date, 'years');
};

export const isGivenDateInFuture = date => {
    return moment().diff(moment(date).endOf('day'), 'days') < 0;
};

export const getInsuranceStartDate = () => {
    const now = moment();
    let defaultDateBasedOnYearTime = null;

    // if the current month is september or later, then the insurance start date is the 1st january of the next year
    if (now.month() + 1 >= 9) {
        // set the minimum date as beginning of next year
        defaultDateBasedOnYearTime = moment().add(1, 'year').startOf('year');
    } else {
        // set the minimum date as beginning of next month
        defaultDateBasedOnYearTime = moment().add(1, 'month').startOf('month');
    }

    return defaultDateBasedOnYearTime.format(BACKEND_DATE_FORMAT);
};

/**
 * Return year from given date - using momentJs Api
 *
 * @param date
 * @returns {number}
 */
export const getYearFromDate = date => {
    return moment(date).year();
};

export const finalFormFieldDateFormatAttributes = {
    format: value =>
        moment(value, BACKEND_DATE_FORMAT, true).isValid() ? moment(value).format(PRESENTATION_DATE_FORMAT) : value,
    parse: value =>
        moment(value, PRESENTATION_DATE_FORMAT, true).isValid()
            ? moment(value, PRESENTATION_DATE_FORMAT).format(BACKEND_DATE_FORMAT)
            : value,
};

export const convertDateToPresentationFormat = value => {
    return moment(value, BACKEND_DATE_FORMAT, true).format(PRESENTATION_DATE_FORMAT);
};

export const convertDateToComputedFormat = value => {
    return moment(value, PRESENTATION_DATE_FORMAT, true).format(BACKEND_DATE_FORMAT);
};

export const isValidDate = value => {
    return moment(value, PRESENTATION_DATE_FORMAT, true).isValid();
};
