import React, { Component } from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { Hidden, Spacer } from 'atupri-component-library/lib/helpers';

// util
import { formatAmount } from '@src/util/currency';
import { getProductTitle, getProductDescription } from '@src/util/basket';
import { sanitizeDOM } from '@src/util/sanitize';
import { getProductIdentifier } from '@src/util/tariff';

import {
    StyledTable,
    StyledTableTH,
    StyledTableTD,
    StyledTableBox,
    StyledTableBoxIcon,
    StyledTableBoxIconPanel,
    StyledDL,
    StyledDT,
    StyledDD,
    StyledTableBoxPrice,
    StyledTableBoxPriceDL,
    StyledIcon,
    StyledTax,
    StyledTaxRow,
    StyledTaxLabel,
    StyledTaxPrice,
    StyledTaxTotal,
    StyledTaxPriceTotal,
} from './TableList.style';

class TableList extends Component {
    constructor(props) {
        super(props);
        this.state = { mounted: false };
    }

    render() {
        const { title, rows, mode } = this.props;
        const msgTableTotalKvg = i18next.t('ProductBasketPage.tableTotalKvg');
        const msgTableTotalVvg = i18next.t('ProductBasketPage.tableTotalVvg');

        function getTotalPrice(rows) {
            let total = 0;
            rows.forEach(function (row) {
                total += row.price;
            });
            return <BasketTableRow key="add-total" mode={mode} totalPrice={total} priceLabel={msgTableTotalVvg} />;
        }

        function getIconName(row) {
            if (!row) return '';

            const { type, productGroup, product } = row;
            const namePrefix = type ? type.toLowerCase() : 'okp';
            const vvgProductIdentifier = getProductIdentifier(product, false);

            return i18next.exists(`ProductForm.${namePrefix}.product.${vvgProductIdentifier}.icon`)
                ? i18next.t(`ProductForm.${namePrefix}.product.${vvgProductIdentifier}.icon`)
                : i18next.t(`ProductForm.${namePrefix}.productGroup.${productGroup}.icon`);
        }

        function BasketTablePrice(props) {
            const { price, priceLabel, environmentalTax } = props;
            return environmentalTax ? (
                <BasketTablePriceWithTax price={price} priceLabel={priceLabel} environmentalTax={environmentalTax} />
            ) : (
                <BasketTablePriceSimple price={price} priceLabel={priceLabel} />
            );
        }

        function BasketTablePriceSimple(props) {
            const { price, priceLabel } = props;
            return priceLabel ? (
                <StyledTableBoxPrice priceLabel={true}>
                    <StyledTableBoxPriceDL>
                        <dt>{priceLabel}</dt>
                        <dd>{formatAmount(price)}</dd>
                    </StyledTableBoxPriceDL>
                </StyledTableBoxPrice>
            ) : (
                <StyledTableBoxPrice>{formatAmount(price)}</StyledTableBoxPrice>
            );
        }

        function BasketTablePriceWithTax(props) {
            const { price, environmentalTax } = props;
            return (
                <StyledTax>
                    <StyledTaxRow>
                        <StyledTaxLabel>{i18next.t('ProductBasketPage.tableWithTax.brutto')}</StyledTaxLabel>
                        <StyledTaxPrice>{formatAmount(price, false)}</StyledTaxPrice>
                    </StyledTaxRow>
                    <StyledTaxRow>
                        <StyledTaxLabel>{i18next.t('ProductBasketPage.tableWithTax.tax')}</StyledTaxLabel>
                        <StyledTaxPrice>{formatAmount(environmentalTax, false)}</StyledTaxPrice>
                    </StyledTaxRow>
                    <Spacer space="beta" />
                    <StyledTaxTotal>
                        <StyledTaxLabel>{i18next.t('ProductBasketPage.tableWithTax.price')}</StyledTaxLabel>
                        <StyledTaxPriceTotal>{formatAmount(price + environmentalTax)}</StyledTaxPriceTotal>
                    </StyledTaxTotal>
                </StyledTax>
            );
        }

        function BasketTableRow(props) {
            const { row, mode, totalPrice, priceLabel } = props;
            const description = getProductDescription(row);
            const price = totalPrice || row?.price;

            return (
                <tr>
                    <StyledTableTD>
                        <StyledTableBox>
                            {row && (
                                <>
                                    <Hidden below="medium">
                                        <StyledTableBoxIcon>
                                            <StyledTableBoxIconPanel mode={mode}>
                                                <StyledIcon iconName={getIconName(row)} iconColor="primary" />
                                            </StyledTableBoxIconPanel>
                                        </StyledTableBoxIcon>
                                    </Hidden>
                                    <StyledDL>
                                        <StyledDT
                                            dangerouslySetInnerHTML={{ __html: sanitizeDOM(getProductTitle(row)) }}
                                        />
                                        {description && (
                                            <StyledDD dangerouslySetInnerHTML={{ __html: sanitizeDOM(description) }} />
                                        )}
                                    </StyledDL>
                                    <BasketTablePrice
                                        price={price}
                                        priceLabel={priceLabel}
                                        environmentalTax={row.bafu}
                                    />
                                </>
                            )}
                        </StyledTableBox>
                    </StyledTableTD>
                </tr>
            );
        }

        return (
            <StyledTable>
                <thead>
                    <tr>
                        <StyledTableTH>{title}</StyledTableTH>
                    </tr>
                </thead>
                <tbody>
                    {rows.map((row, i) => {
                        return (
                            <BasketTableRow
                                key={`row${i}-${mode}`}
                                row={row}
                                mode={mode}
                                priceLabel={rows.length <= 1 ? msgTableTotalKvg : null}
                            />
                        );
                    })}
                    {rows.length > 1 ? getTotalPrice(rows) : null}
                </tbody>
            </StyledTable>
        );
    }
}

const { node, string, bool } = PropTypes;

TableList.defaultProps = {
    rootClassName: null,
    className: null,
};

TableList.propTypes = {
    rootClassName: string,
    className: string,
};

export default TableList;
