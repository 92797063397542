import React from 'react';
import { array, bool, func, number, object, string } from 'prop-types';
import {
    AutocompleteWrapper,
    AutocompleteMenu,
    AutocompleteMenuItem,
    Input,
} from 'atupri-component-library/lib/primitives';
import { getLocationLabel } from '@src/util/zip';

const StyledAutocomplete = ({
    name,
    label,
    entries,
    disabled,
    isRequired,
    onBlur,
    isOpen,
    comboboxProps,
    labelProps,
    inputProps,
    menuProps,
    getItemProps,
    highlightedIndex,
}) => {
    return (
        <AutocompleteWrapper>
            <div {...comboboxProps}>
                <Input
                    id={`${name}.zipPlaceholder`}
                    name={`${name}.zipPlaceholder`}
                    label={label}
                    labelProps={{ ...labelProps }}
                    {...inputProps}
                    disabled={disabled}
                    isRequired={isRequired}
                    maxLength={4}
                    onBlur={onBlur}
                    /**
                     * prevent autocomplete in modern browsers
                     * https://developer.mozilla.org/en-US/docs/Web/Security/Securing_your_site/Turning_off_form_autocompletion#preventing_autofilling_with_autocompletenew-password
                     */
                    autoComplete="off"
                />
            </div>
            <AutocompleteMenu {...menuProps}>
                {isOpen &&
                    entries.map((item, index) => (
                        <AutocompleteMenuItem
                            key={`${item.ZIP}_${index}`}
                            {...getItemProps({ item, index })}
                            isHighlighted={highlightedIndex === index}
                        >
                            {getLocationLabel(item)}
                        </AutocompleteMenuItem>
                    ))}
            </AutocompleteMenu>
        </AutocompleteWrapper>
    );
};

StyledAutocomplete.propTypes = {
    name: string,
    label: string,
    entries: array,
    disabled: bool,
    onBlur: func,
    isOpen: bool,
    comboboxProps: object,
    inputProps: object,
    menuProps: object,
    getItemProps: func,
    highlightedIndex: number,
};

export default StyledAutocomplete;
