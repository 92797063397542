import React from 'react';
import { bool, node, string } from 'prop-types';
import { Field } from 'react-final-form';
import { Toggle } from 'atupri-component-library/lib/primitives';
import { StyledToggleRoot, StyledLabelTop, StyledLabel } from './FieldToggle.style';

// util
import { sanitizeDOM } from '@src/util/sanitize';

const FieldToggle = ({ id, name, label, labelTop = '&nbsp;', hideLabelTop = false }) => {
    return (
        <StyledToggleRoot>
            {!hideLabelTop ? <StyledLabelTop dangerouslySetInnerHTML={{ __html: sanitizeDOM(labelTop) }} /> : null}
            <StyledLabel htmlFor={id}>
                <Field id={id} type="checkbox" name={name}>
                    {props => (
                        <Toggle {...props.input} controlType="checkbox" floatingFocusTargetId="toggle" isSwitch>
                            {label}
                        </Toggle>
                    )}
                </Field>
            </StyledLabel>
        </StyledToggleRoot>
    );
};

FieldToggle.propTypes = {
    id: string.isRequired,
    name: string.isRequired,
    label: node,
    labelTop: string,
    hideLabelTop: bool,
};

export default FieldToggle;
