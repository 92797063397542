import React from 'react';
import styled from '@emotion/styled';
import i18next from 'i18next';
import { Button, P1 } from 'atupri-component-library/lib/primitives';
import { Spacer } from 'atupri-component-library/lib/helpers';
import { mixins, spaces } from 'atupri-component-library/lib/core/styles/theme/default';

const StyledButtonWrapper = styled.div`
    display: flex;

    ${mixins.responsiveStyles({
        'flex-direction': { zero: 'column', medium: 'row-reverse' },
        'justify-content': { zero: 'flex-start' },
    })};

    button + button {
        ${mixins.responsiveStyles({
            'margin-top': { zero: spaces.delta, medium: 0 },
            'margin-right': { zero: 0, medium: spaces.epsilon },
        })}
    }
`;

const AbortModalContent = props => {
    const returnToMyAtupri = () => {
        // The myAtupri sessions will always run on a myAtupri domain. For this reason, the root "/"
        // is the myAtupri dashboard and not the premium calculator person page.
        window.location.href = '/';
    };

    return (
        <>
            <P1>{i18next.t('AbortModal.title')}</P1>
            <Spacer space="eta" />
            <P1>{i18next.t('AbortModal.text')}</P1>
            <Spacer space="kappa" />
            <StyledButtonWrapper>
                <Button onClick={returnToMyAtupri}>{i18next.t('AbortModal.button.confirm')}</Button>
                <Button outlined onClick={props.toggleModal}>
                    {i18next.t('AbortModal.button.cancel')}
                </Button>
            </StyledButtonWrapper>
        </>
    );
};

export default AbortModalContent;
