import { rem } from 'polished';
import styled from '@emotion/styled';
import { text, spaces, colors, fontWeights, mixins } from 'atupri-component-library/lib/core/styles/theme/default';
import { Icon } from 'atupri-component-library/lib/primitives';

export const StyledQuestion = styled.div(({ level }) => {
    let styles = [];

    if (level === 2) {
        styles.push(`
      ${mixins.responsiveStyles({
          'margin-bottom': {
              zero: `${rem(spaces.gamma)}`,
              medium: `${rem(spaces.epsilon)}`,
              wide: `${rem(spaces.epsilon)}`,
          },
      })}

      .QUESTIONS.LEVEL-2:last-of-type &:last-of-type,
      .react-responsive-modal-overlay .BLOCK.LEVEL-2:last-of-type > .QUESTIONS.LEVEL-2:last-of-type &:last-of-type {
        margin: 0;
      }
    `);
    }

    return styles;
});

export const StyledLabel = styled.label(({ level }) => {
    let styles = [
        `
    display: block;
  `,
    ];

    switch (level) {
        case 1:
            styles.push(`
        ${text.p1};
        ${mixins.responsiveStyles({
            'margin-bottom': {
                zero: `${rem(spaces.epsilon)}`,
                medium: `${rem(spaces.zeta)}`,
                wide: `${rem(spaces.zeta)}`,
            },
        })}
      `);
            break;
        case 2:
        case 3:
        case 4:
            styles.push(`
        ${text.p3};
        ${mixins.responsiveStyles({
            'margin-bottom': {
                zero: `${rem(spaces.gamma)}`,
                medium: `${rem(spaces.delta)}`,
                wide: `${rem(spaces.delta)}`,
            },
        })}
    `);
            break;
    }

    styles.push(`
    .react-responsive-modal-overlay & {
      font-weight: ${fontWeights.semibold};
    }
  `);

    return styles;
});

export const StyledIcon = styled(Icon)`
    color: ${colors.primary};
    display: inline-block;
    vertical-align: middle;

    ${mixins.responsiveStyles({
        'margin-left': {
            zero: `${rem(spaces.beta)}`,
            medium: `${rem(spaces.delta)}`,
            wide: `${rem(spaces.delta)}`,
        },
    })}
`;
