import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { rem } from 'polished';
import { Tooltip } from 'atupri-component-library/lib/components';
import { colors, mixins, spaces, spacingCurves } from 'atupri-component-library/lib/core/styles/theme/default';
import { Box } from 'atupri-component-library/lib/helpers';
import { P4 } from 'atupri-component-library/lib/primitives';

import { FormStateEnum } from '@src/util/finalForm/formState';

export const StyledTitle = styled.div`
    font-size: ${rem(20)};
    line-height: 1.6;

    ${mixins.responsiveStyles({
        'margin-bottom': { zero: spaces.epsilon, medium: spaces.theta },
    })}
`;

export const StyledTooltip = styled(Tooltip)`
    display: inline-block;

    ${mixins.responsiveStyles({
        'margin-bottom': { zero: spaces.epsilon, medium: spaces.zeta },
    })}
`;

export const StyledTooltipContent = styled.div`
    position: relative;
    color: ${colors.primary};
    padding-left: ${rem(spaces.eta)};

    i {
        position: absolute;
        top: 0;
        left: 0;
        font-size: ${rem(24)};
    }
`;

export const StyledMapWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    gap: ${rem(spaces.delta)};
    background-color: ${colors.blue400};
    border-top: ${rem(1)} solid ${colors.grey400};
    border-bottom: ${rem(1)} solid ${colors.grey400};

    ${mixins.responsiveStyles({
        'max-height': { medium: rem(600) },
        'margin-left': { zero: 0, medium: spaces.zeta * -1, large: spaces.kappa * -1 },
        'margin-right': { zero: 0, medium: spaces.zeta * -1, large: spaces.kappa * -1 },
        'padding-top': { zero: spaces.delta, medium: spaces.zeta },
        'padding-bottom': { zero: spaces.delta, medium: spaces.zeta },
        'padding-left': { zero: spaces.gamma, medium: spaces.zeta, large: spaces.kappa },
        'padding-right': { zero: spaces.gamma, medium: spaces.zeta, large: spaces.kappa },
    })}
`;

export const StyledButtonRow = styled.div`
    display: flex;
    justify-content: flex-end;

    button + button {
        ${mixins.responsiveStyles({
            'margin-left': { small: spaces.epsilon },
            'margin-top': { zero: spaces.delta, small: 0 },
        })}
    }

    ${mixins.responsiveStyles({
        'flex-direction': { zero: 'column', small: 'row' },
        ...spacingCurves.component.deci('margin-top'),
    })}
`;

export const StyledButtonRowFixed = styled.div`
    display: flex;
    justify-content: flex-end;

    button:first-child {
        ${mixins.responsiveStyles({
            'margin-top': { zero: spaces.gamma, small: 0 },
        })}
    }

    button + button {
        ${mixins.responsiveStyles({
            'margin-left': { small: spaces.epsilon },
        })}
    }

    ${mixins.responsiveStyles({
        'z-index': { zero: '10', medium: 'initial' },
        position: { zero: 'fixed', medium: 'static' },
        bottom: { zero: '0', medium: 'initial' },
        width: { zero: '100%', medium: 'auto' },
        left: { zero: '0', medium: 'auto' },
        'background-color': { zero: colors.white, medium: 'transparent' },
        padding: { zero: spaces.gamma, medium: '0' },
        'flex-direction': { zero: 'column-reverse', small: 'row' },
        ...spacingCurves.component.deci('margin-top'),
    })}
`;

export const StyledButtonRowFixedPlaceholder = styled.div`
    ${mixins.responsiveStyles({
        display: { zero: 'block', medium: 'none' },
        height: { zero: rem(112), small: rem(42) },
        width: { zero: '100%' },
    })}
`;

export const StyledButtonWrapper = styled.div`
    ${mixins.responsiveStyles({
        'padding-bottom': { zero: spaces.epsilon, medium: 0 },
    })}
`;

export const modalStyles = formState => css`
    &.modal__overlay--custom {
        padding-top: 0;
        padding-bottom: 0;
    }

    .modal__container {
        overflow-y: auto;
        overflow-x: hidden;
    }

    .modal__modal--custom {
        ${formState === FormStateEnum.start
            ? css`
                  width: ${rem(955)};
                  max-width: 100%;
              `
            : css`
                  width: auto;
              `}

        ${mixins.responsiveStyles({
            'padding-left': { zero: spaces.gamma, medium: spaces.zeta, large: spaces.kappa },
            'padding-right': { zero: spaces.gamma, medium: spaces.zeta, large: spaces.kappa },
            height: { zero: 'auto', medium: '100%' },
            'overflow-x': { zero: 'initial', medium: 'hidden' },
            'overflow-y': { zero: 'initial', medium: 'auto' },
        })}
    }

    .modal__closeButton--custom {
        color: ${colors.primary};
    }
`;

export const StyledConsentBox = styled(Box)`
    margin-right: ${rem(spaces.epsilon)};
`;

export const StyledConsentText = styled(P4)`
    text-align: center;
`;
