import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { rem } from 'polished';
import { Icon, P3 } from 'atupri-component-library/lib/primitives';
import { DropdownMenuTrigger, DropdownMenuContent } from 'atupri-component-library/lib/components';
import { up } from 'atupri-component-library/lib/core/utils/Breakpoints';
import {
    animations,
    colors,
    mixins,
    spaces,
    spacingCurves,
} from 'atupri-component-library/lib/core/styles/theme/default';

export const StyledPersonContainer = styled.div(({ isInDropdown, hasMultiplePersons }) => {
    let styles = [
        `
    display: flex;
    align-items: center;

    ${mixins.responsiveStyles({
        padding: {
            zero: `${rem(spaces.delta)}`,
            medium: `${rem(spaces.zeta)} ${rem(spaces.theta)} ${rem(spaces.zeta)} 0`,
        },
    })}
  `,
    ];

    if (isInDropdown || !hasMultiplePersons) {
        styles.push(`
      ${mixins.responsiveStyles({
          'padding-left': { medium: spaces.epsilon, large: spaces.theta },
      })}
    `);
    }

    if (isInDropdown) {
        styles.push(`
      color: ${colors.grey700};
    `);
    }

    return styles;
});

export const StyledIconPlaceholder = styled.div`
    position: relative;
    width: ${rem(50)};
    height: ${rem(50)};

    ${mixins.responsiveStyles({
        'margin-right': { zero: spaces.delta, medium: spaces.epsilon },
    })}
`;

export const StyledPersonIcon = styled(Icon)(({ isInDropdown }) => {
    let styles = [
        `
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: ${rem(48)};
  `,
    ];

    if (isInDropdown) {
        styles.push(`
      font-size: ${rem(42)};
    `);
    }

    return styles;
});

export const StyledPersonEditContainer = styled.div(({ isInDropdown }) => {
    let styles = [
        `
    ${mixins.responsiveStyles({
        ...spacingCurves.component.tera('margin-right'),
    })}

    flex-direction: column;
    justify-content: center;
    margin-left: auto;
    cursor: pointer;
  `,
    ];

    if (!isInDropdown) {
        styles.push(`
       ${mixins.responsiveStyles({
           display: { medium: 'flex' },
       })}
    `);
    }

    return styles;
});

export const StyledPersonItemContainer = styled.div`
    display: flex;
    flex-direction: row;
`;

export const StyledTotal = styled.div(({ layout }) => {
    let styles = [
        `display: flex;
    padding-left: ${rem(spaces.epsilon)};

    ${mixins.responsiveStyles({
        display: { zero: 'flex', medium: 'inline-flex' },
        'align-self': { medium: 'stretch' },
        'flex-direction': { medium: 'column' },
        'justify-content': { zero: 'space-between', medium: 'center' },
        'align-items': { zero: 'center', medium: 'normal' },
        'min-width': { medium: rem(160), large: rem(252) },
        'min-height': { zero: rem(48), medium: 0 },
        'padding-right': { zero: rem(spaces.epsilon), medium: 0 },
    })}`,
    ];

    if (layout === 'primary') {
        styles.push(`background: ${colors.primary}; color: ${colors.white};`);
    } else {
        styles.push(`background: ${colors.white}; color: ${colors.primary}; margin-left: auto;`);
    }

    return styles;
});

export const StyledDropdownMenuTrigger = styled(DropdownMenuTrigger)(
    ({ hasMultiplePersons }) => css`
        display: flex;
        align-items: center;
        width: 100%;
        border-bottom: 1px solid ${colors.grey300};
        cursor: pointer;

        &[aria-expanded='false'] {
            > div:not(:first-of-type) i {
                transform: rotate(180deg);
            }
        }

        ${hasMultiplePersons &&
        css`
            ${mixins.responsiveStyles({
                'padding-left': { medium: spaces.epsilon, large: spaces.theta },
            })}
        `}
    `
);

export const StyledDropdownMenuContent = styled(DropdownMenuContent)(({ hasMultiplePersons }) => {
    const multiplePersonStyles = css`
        overflow-y: scroll;

        // limit dropdown content to available screen height with header part subtracted
        ${mixins.responsiveStyles({
            'max-height': { zero: 'calc(100vh - 236px)', medium: 'calc(100vh - 276px)' },
        })}
    `;

    return hasMultiplePersons ? multiplePersonStyles : null;
});

export const StyledIconWrap = styled.div`
    display: flex;
    margin-left: auto;
`;

export const StyledP3 = styled(P3)`
    margin-right: ${rem(spaces.delta)};
    color: ${colors.primary};
`;

export const StyledAccordionIcon = styled(Icon)`
    font-size: ${rem(20)};
    color: ${colors.primary};
    transition: all ${animations.default};

    ${mixins.responsiveStyles({
        'margin-right': { zero: spaces.delta, medium: spaces.eta, large: spaces.kappa },
    })}
`;

export const StyledEditWrap = styled.div`
    border-top: 1px solid ${colors.grey300};

    ${mixins.responsiveStyles({
        ...spacingCurves.component.hecto('padding-top'),
        ...spacingCurves.component.hecto('padding-bottom'),
        'padding-left': { zero: spaces.delta, medium: spaces.epsilon, large: spaces.theta },
        'padding-right': { zero: spaces.delta, medium: spaces.epsilon, large: spaces.theta },
    })}
`;

export const StyledPersonList = styled.div`
    overflow-y: scroll;
    // calculate max-height from PersonListItem height
    max-height: ${rem(3 * 82)};

    // special breakpoint to target wider phones
    ${up(375)} {
        max-height: ${rem(4 * 82)};
    }

    // on landscape tablets show reduced number of items
    ${up('medium', 'landscape')} {
        max-height: ${rem(3 * 114)};
    }

    ${up('medium', 'portrait')} {
        max-height: ${rem(4 * 114)};
    }

    ${up('wide')} {
        max-height: ${rem(4 * 114)};
    }
`;

export const StyledPersonInfoContainer = styled.div`
    display: flex;
    flex-direction: row;
`;
