import { arrayOf, bool, func, number, object, oneOf, oneOfType, shape, string } from 'prop-types';

const propTypes = {};

// fixed value
propTypes.value = val => oneOf([val]);

propTypes.uuid = string;
propTypes.money = string;

propTypes.currencyConfig = shape({
    style: string.isRequired,
    currency: string.isRequired,
    currencyDisplay: string,
    useGrouping: bool,
    minimumFractionDigits: number,
    maximumFractionDigits: number,
});

// single route type
propTypes.route = shape({
    name: string.isRequired,
    path: string.isRequired,
    exact: bool,
    strict: bool,
    component: func.isRequired,
    loadData: func,
});

/* ******
 SUBMISSION
 *********/

export const SUBMISSION_STATE_OFFER = 'offer';
export const SUBMISSION_STATE_PUBLISHED = 'published';
export const SUBMISSION_STATE_CLOSED = 'closed';

const SUBMISSION_STATES = [SUBMISSION_STATE_OFFER, SUBMISSION_STATE_PUBLISHED, SUBMISSION_STATE_CLOSED];

const submissionAttributes = shape({
    state: oneOf(SUBMISSION_STATES),
    price: propTypes.money,
    privateData: object,
});

const ownSubmissionAttributes = shape({
    state: oneOf(SUBMISSION_STATES).isRequired,
    price: propTypes.money,
    privateData: object.isRequired,
});

// Denormalised submission object
propTypes.submission = shape({
    id: propTypes.uuid.isRequired,
    type: propTypes.value('submission').isRequired,
    attributes: oneOfType([submissionAttributes]).isRequired,
});

// Denormalised ownSubmission object
propTypes.ownSubmission = shape({
    id: propTypes.uuid.isRequired,
    type: propTypes.value('ownSubmission').isRequired,
    attributes: oneOfType([ownSubmissionAttributes]).isRequired,
});

/* ***************
 HEALTH QUESTIONS
 **************** */

export const HEALTHQUESTIONS_STATE_PRISTINE = 'pristine';
export const HEALTHQUESTIONS_STATE_DIRTY = 'dirty';
export const HEALTHQUESTIONS_STATE_READY = 'ready';
export const HEALTHQUESTIONS_STATE_CLOSED = 'closed';

const HEALTHQUESTIONS_STATES = [
    HEALTHQUESTIONS_STATE_PRISTINE,
    HEALTHQUESTIONS_STATE_DIRTY,
    HEALTHQUESTIONS_STATE_READY,
    HEALTHQUESTIONS_STATE_CLOSED,
];

const healthquestionsAttributes = shape({
    deleted: propTypes.value(false),
    state: oneOf(HEALTHQUESTIONS_STATES),
    privateData: object,
});

const ownHealthquestionsAttributes = shape({
    state: oneOf(SUBMISSION_STATES).isRequired,
    privateData: object.isRequired,
});

// Denormalised healthquestions object
propTypes.healthquestions = shape({
    id: propTypes.uuid.isRequired,
    type: propTypes.value('healthquestions').isRequired,
    attributes: oneOfType([healthquestionsAttributes]).isRequired,
});

// Denormalised ownHealthquestions object
propTypes.ownHealthquestions = shape({
    id: propTypes.uuid.isRequired,
    type: propTypes.value('ownHealthquestions').isRequired,
    attributes: oneOfType([ownHealthquestionsAttributes]).isRequired,
});

export const ERROR_CODE_SUBMISSION_NOT_FOUND = 'transaction-submission-not-found';
export const ERROR_CODE_HEALTHQUESTIONS_NOT_FOUND = 'transaction-healthquestions-not-found';

const ERROR_CODES = [ERROR_CODE_SUBMISSION_NOT_FOUND, ERROR_CODE_HEALTHQUESTIONS_NOT_FOUND];

// API error
propTypes.apiError = shape({
    id: propTypes.uuid.isRequired,
    status: number.isRequired,
    code: oneOf(ERROR_CODES).isRequired,
    meta: object,
});

// Storable error prop type. (Error object should not be stored as it is.)
propTypes.error = shape({
    type: propTypes.value('error').isRequired,
    name: string.isRequired,
    message: string,
    status: number,
    statusText: string,
    apiErrors: arrayOf(propTypes.apiError),
});

export { propTypes };
