import React from 'react';
import { arrayOf, bool, node, shape, string } from 'prop-types';
import classNames from 'classnames';
import { FieldArray } from 'react-final-form-arrays';

// Components
import { FieldCheckbox, ValidationError, PrimaryFieldset } from '@src/components';
import { Spacer } from 'atupri-component-library/lib/helpers';

// Styles
import { StyledCheckboxList, StyledParagraph, StyledCheckboxListItemisListing } from './FieldCheckboxGroup.style';

const FieldCheckboxRenderer = props => {
    const { className, rootClassName, label, twoColumns, id, fields, options, meta, disabled, text, title, isListing } =
        props;

    const classes = classNames(rootClassName || 'rootCheckboxGroup', className, isListing ? 'is-listing' : null);
    const listClasses = twoColumns ? classNames('list', 'twoCol') : 'list';

    return (
        <PrimaryFieldset className={classes}>
            {label ? <legend>{label}</legend> : null}
            {title ? (
                <>
                    <StyledParagraph bold={true}>{title}</StyledParagraph>
                    <Spacer space="epsilon" />
                </>
            ) : null}
            {text ? <StyledParagraph>{text}</StyledParagraph> : null}
            <StyledCheckboxList className={listClasses} isListing={isListing}>
                {options.map(option => {
                    const fieldId = `${id}.${option.key}`;
                    return (
                        <StyledCheckboxListItemisListing key={fieldId} className={'item'} isListing={isListing}>
                            <FieldCheckbox
                                id={fieldId}
                                name={fields.name}
                                label={option.label}
                                value={option.key}
                                text={option.text}
                                disabled={disabled}
                                isListing={isListing}
                                nested={!!option.nested}
                            />
                        </StyledCheckboxListItemisListing>
                    );
                })}
            </StyledCheckboxList>
            <ValidationError fieldMeta={{ ...meta }} />
        </PrimaryFieldset>
    );
};

FieldCheckboxRenderer.defaultProps = {
    rootClassName: null,
    className: null,
    label: null,
    twoColumns: false,
    title: null,
    text: null,
    isListing: false,
};

FieldCheckboxRenderer.propTypes = {
    rootClassName: string,
    className: string,
    id: string.isRequired,
    label: node,
    options: arrayOf(
        shape({
            key: string.isRequired,
            label: node.isRequired,
        })
    ).isRequired,
    twoColumns: bool,
    title: string,
    text: string,
    isListing: bool,
};

const FieldCheckboxGroup = props => <FieldArray component={FieldCheckboxRenderer} {...props} />;

FieldCheckboxGroup.propTypes = {
    name: string.isRequired,
};

export default FieldCheckboxGroup;
