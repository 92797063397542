import React from 'react';
import { array, func, object, string } from 'prop-types';
import i18next from 'i18next';
import { Field } from 'react-final-form';

// config
import config from '@src/config';
import { staticData as getTariffStatic } from '@src/config/getTariff';

// components
import { VvgProductCard } from '@src/components';
import { BlueBarTitle, Tooltip } from 'atupri-component-library/lib/components';
import { H2, H3 } from 'atupri-component-library/lib/primitives';
import { Box, Spacer, Stack } from 'atupri-component-library/lib/helpers';

// util
import { findTariff, findProductsOfProductGroups } from '@src/util/tariff/vvg';
import { sanitizeDOM } from '@src/util/sanitize';
import { getProductIdentifier } from '@src/util/tariff';

import {
    StyledInfoIcon,
    StyledBox,
    StyledInnerBox,
    StyledBoxTitle,
    StyledVvgIntroText,
    StyledGrid,
    StyledVvgGridItem,
    StyledToolTipWrapper,
} from '../ProductForm.style';
import renderAdditionalProductGroup from './RenderAdditionalProductGroup';

const VvgProductForm = ({ form, values, apiData, onProductImpressionChange, submission, currentPerson }) => {
    const sections = getTariffStatic.vvg.sections;
    const mainProductSection = sections.find(section => section.id === 'withMivita');
    const mainProductGroup = apiData.productGroups.find(pg => mainProductSection.productsOfGroup === pg.id);
    const currentSection = sections.find(section => section.id === values.section);
    const additionalProductGroups = apiData.productGroups.filter(pg => currentSection.productGroups.includes(pg.id));
    const { products } = apiData;

    const vvgEnabled = products && values.enabled;

    const onMainProductChange = product => {
        form.mutators.setValue('vvg.section', product ? 'withMivita' : 'withoutMivita');
        form.mutators.setValue(`vvg.productGroups.withMivita_${mainProductGroup.id}`, !!product);
    };

    const renderMainProductGroup = (group, products) => {
        return (
            <StyledGrid>
                {products
                    .sort((a, b) => a.sorting - b.sorting)
                    .map(product => {
                        const vvgProductIdentifier = getProductIdentifier(product.id, false);
                        const labelPrefix = `ProductForm.vvg.product.card.${vvgProductIdentifier}`;
                        const { price } = findTariff(product.id, apiData, {
                            personId: currentPerson.id,
                            persons: submission.relationships.persons,
                            submission,
                        });

                        return (
                            <StyledVvgGridItem key={`vvg.${group.id}.${product.id}`}>
                                <VvgProductCard
                                    name={`vvg.products.withMivita_${group.id}`}
                                    value={product.id}
                                    selected={
                                        values.section === 'withMivita' &&
                                        values.products[`withMivita_${group.id}`] === product.id
                                    }
                                    title={i18next.t(`${labelPrefix}.title`)}
                                    subtitle={i18next.t(`${labelPrefix}.subtitle`)}
                                    price={price}
                                    iconName={i18next.t(`${labelPrefix}.icon`)}
                                    listingTexts={{
                                        hospital: i18next.t(`${labelPrefix}.hospital`),
                                        sport: i18next.t(`${labelPrefix}.sport`),
                                        visualAid: i18next.t(`${labelPrefix}.visualAid`),
                                        alternativeMedicine: i18next.t(`${labelPrefix}.alternativeMedicine`),
                                        highlight: i18next.t(`${labelPrefix}.highlight`),
                                    }}
                                    detailText={i18next.t(`${labelPrefix}.detailLink.label`)}
                                    detailUrl={i18next.t(`${labelPrefix}.detailLink.url`)}
                                    onChange={onMainProductChange}
                                />
                            </StyledVvgGridItem>
                        );
                    })}
            </StyledGrid>
        );
    };

    return (
        vvgEnabled && (
            <Stack space="kilo">
                <Box shadow="ctaBlueHover">
                    <StyledBoxTitle>
                        <BlueBarTitle title={i18next.t('ProductForm.title.vvg')} />
                    </StyledBoxTitle>
                    <StyledBox>
                        <StyledInnerBox>
                            <StyledVvgIntroText
                                dangerouslySetInnerHTML={{
                                    __html: sanitizeDOM(i18next.t('ProductForm.vvg.packet.description')),
                                }}
                            />
                            <H3>
                                {i18next.t('ProductForm.vvg.packet.title')}
                                {i18next.exists('ProductForm.vvg.packet.tooltip') && (
                                    <Tooltip
                                        tooltip={
                                            <StyledToolTipWrapper>
                                                {i18next.t('ProductForm.vvg.packet.tooltip')}
                                            </StyledToolTipWrapper>
                                        }
                                        placement="top"
                                    >
                                        <StyledInfoIcon iconName="info_bubble" title="Info" />
                                    </Tooltip>
                                )}
                            </H3>
                            <Spacer space="theta" />
                            {mainProductGroup &&
                                renderMainProductGroup(
                                    mainProductGroup,
                                    findProductsOfProductGroups(products, [mainProductGroup.id])
                                )}
                            <Spacer space="peta" />
                            <H2>
                                {i18next.t('ProductForm.vvg.additionalProducts.title')}
                                {i18next.exists('ProductForm.vvg.additionalProducts.tooltip') && (
                                    <Tooltip
                                        tooltip={
                                            <StyledToolTipWrapper>
                                                {i18next.t('ProductForm.vvg.additionalProducts.tooltip')}
                                            </StyledToolTipWrapper>
                                        }
                                        placement="top"
                                    >
                                        <StyledInfoIcon iconName="info_bubble" title="Info" />
                                    </Tooltip>
                                )}
                            </H2>
                            <Stack space="">
                                {additionalProductGroups.map(productGroup =>
                                    renderAdditionalProductGroup(
                                        productGroup,
                                        findProductsOfProductGroups(products, [productGroup.id]),
                                        apiData,
                                        values,
                                        currentPerson,
                                        submission,
                                        form,
                                        onProductImpressionChange,
                                        undefined,
                                        false,
                                        false,
                                        false
                                    )
                                )}
                            </Stack>
                            <Spacer space="hecto" />
                        </StyledInnerBox>
                    </StyledBox>
                </Box>
                {config.env !== 'prod' && (
                    <StyledBox
                        display={['test', 'int', 'int2'].includes(config.env) ? 'none' : 'block'}
                        shadow="ctaBlueHover"
                    >
                        <H3>Ausgewählte VVG-Tariffe:</H3>
                        <Field name={'tariff.vvg'}>
                            {fieldProps => (
                                <pre style={{ overflowX: 'scroll' }}>
                                    {JSON.stringify(fieldProps.input.value, 0, 2)}
                                </pre>
                            )}
                        </Field>
                    </StyledBox>
                )}
            </Stack>
        )
    );
};

VvgProductForm.propTypes = {
    form: object.isRequired,
    values: object.isRequired,
    apiData: object.isRequired,
    onProductImpressionChange: func,
    submission: object.isRequired,
    persons: array.isRequired,
    currentPerson: object.isRequired,
    className: string,
};

export default VvgProductForm;
