import { useForm } from 'react-final-form';

export default ({ conditions, operator, children }) => {
    const form = useForm();
    const { values } = form.getState();
    let show;

    if (operator === 'and') {
        show = conditions.every(({ reference, value }) => checkCondition(values, reference, value));
    } else {
        show = conditions.some(({ reference, value }) => checkCondition(values, reference, value));
    }

    return show ? children : null;
};

const checkCondition = (values, referenceId, referenceValue) =>
    values.healthQuestions &&
    (referenceId in values.healthQuestions ? values.healthQuestions[referenceId] == referenceValue : !referenceValue);
