import React from 'react';
import { number, string } from 'prop-types';
import { formatAmount } from '@src/util/currency';
import { P1, P4 } from 'atupri-component-library/lib/primitives';

import { StyledTotal } from '../PersonSelect.style';

const Total = props => {
    const { label, totalPrice, layout } = props;

    return (
        <StyledTotal layout={layout}>
            <P4>{label}</P4>
            <P1>{formatAmount(totalPrice)}</P1>
        </StyledTotal>
    );
};

Total.defaultProps = {
    layout: null,
};

Total.propTypes = {
    label: string.isRequired,
    layout: string,
    totalPrice: number.isRequired,
};

export default Total;
