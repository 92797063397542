import React, { useEffect, useRef, useState } from 'react';
import i18next from 'i18next';
import { useParams } from 'react-router-dom';
import { func, object } from 'prop-types';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';

// middleware
import { log } from '@src/middleware';

// data
import { ensureOwnSubmission } from '@src/util/data';

// theme
import { colors } from 'atupri-component-library/lib/core/styles/theme/default';

// components
import { Box, Container, Grid, Hidden, Spacer, Stack } from 'atupri-component-library/lib/helpers';
import { H2, Link, P3 } from 'atupri-component-library/lib/primitives';

import { NamedLink, NamedRedirect } from '@src/components';

// util
import { getPersonProductTrackingData, getProductImpressionTrackingData } from '@src/util/analytics';
import { sanitizeDOM } from '@src/util/sanitize';
import { formatAmount } from '@src/util/currency';
import { findPersonById } from '@src/util/person';
import { validatePersonBasket } from '@src/util/basket';
import { getProductIdentifier } from '@src/util/tariff';
import { calculatePriceWithTax, getOkpProductsTrackingData } from '@src/util/tariff/okp';
import {
    PRODUCT_IDENTIFIER_BASIC,
    PRODUCT_IDENTIFIER_SPITAL_ALLGEMEIN,
    PRODUCT_CODE_UTI,
    createBasketEntryByTariffId,
    getVvgProductsTrackingData,
    findTariff as findVvgTariff,
    findTariffById as findVvgTariffById,
    findProductById as findVvgProductById,
} from '@src/util/tariff/vvg';

// styles
import {
    StyledFullWidthContainer,
    StyledContainer,
    StyledGridItem,
    StyledCard,
    StyledOverviewIcon,
    StyledNamedLinkInner,
    StyledBoxWide,
    StyledAccordionItemContent,
    StyledListContainer,
    StyledAccordionContentText,
    StyledIcon,
    StyledShowDetails,
    StyledUtiWidget,
} from './EditSubmissionProductSubPanelRecommend.style';
import {
    Accordion,
    AccordionItem,
    AccordionItemTrigger,
    ShowDetailsContent,
    ShowDetailsTrigger,
} from 'atupri-component-library/lib/components';

const MAX_ACCORDION_ITEMS = 10;
const BASIC = 'basic';
const UTI = 'uti';
const HOSPITAL = 'hospital';

const EditSubmissionProductSubPanelRecommend = props => {
    const { personId } = useParams();
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const sendDataToGTM = useGTMDispatch();
    const { onProductSelectionChange, submission, tariffs } = props;
    const currentSubmission = ensureOwnSubmission(submission);
    const { basket, persons } = currentSubmission.relationships;
    const currentPerson = findPersonById(personId, persons) || persons[0];
    const currentBasket = basket[currentPerson.id] || [];
    const apiVvgTariffs = tariffs.vvg || null;
    const apiUtiTariffs = tariffs.uti || null;
    const okpTariff = currentBasket.okp[0];

    const vvgProducts = {
        [BASIC]: {
            productId: PRODUCT_IDENTIFIER_BASIC,
            sectionId: 'withMivita',
        },
        [UTI]: {
            productId: PRODUCT_CODE_UTI,
            sectionId: 'withoutMivita',
        },
        [HOSPITAL]: {
            productId: PRODUCT_IDENTIFIER_SPITAL_ALLGEMEIN,
            sectionId: 'withoutMivita',
        },
    };
    const productRecommendation = currentSubmission.attributes.productRecommendation || BASIC;

    const trackedOkpProducts = useRef([]);
    const trackedVvgProducts = useRef([]);

    const trackProductImpressions = () => {
        let okpProductsToTrack = [];
        let vvgProductsToTrack = [];

        if (okpTariff && tariffs?.okp) {
            // Get tracking data for okp product
            okpProductsToTrack = getOkpProductsTrackingData(
                tariffs?.okp,
                {
                    variant: okpTariff.variant,
                    category: okpTariff.category,
                },
                [okpTariff.product],
                okpTariff.product
            ).filter(item => !trackedOkpProducts.current.some(cur => cur.title === item.title));
        }

        if (vvgTariff) {
            // Get tracking data for vvg product (we need to first get the parent product from api data)
            vvgProductsToTrack = getVvgProductsTrackingData(
                apiVvgTariffs,
                [findVvgProductById(vvgTariff.parent, apiVvgTariffs.products)],
                undefined,
                personId,
                persons,
                submission
            ).filter(item => !trackedVvgProducts.current.some(cur => cur.title === item.title));
        }

        if (okpProductsToTrack.length > 0 || vvgProductsToTrack.length > 0) {
            // We are using refs to track the products, as we want to ensure that we only track each product impression once
            trackedOkpProducts.current = trackedOkpProducts.current.concat(okpProductsToTrack);
            trackedVvgProducts.current = trackedVvgProducts.current.concat(vvgProductsToTrack);

            // Getting the product impression tracking data for all products
            const dataLayerEventData = getProductImpressionTrackingData(okpProductsToTrack, vvgProductsToTrack);
            // Sending the product impression data to GTM
            sendDataToGTM(dataLayerEventData);
        }
    };

    const [vvgTariff, setVvgTariff] = useState(null);

    useEffect(() => {
        trackProductImpressions();
    }, [okpTariff, vvgTariff]);

    if (!apiVvgTariffs || (productRecommendation === UTI && !apiUtiTariffs)) {
        return null;
    }

    if (!vvgTariff) {
        const predefinedVvgTariff =
            productRecommendation !== UTI &&
            findVvgTariff(vvgProducts[productRecommendation]['productId'], apiVvgTariffs, {
                personId,
                persons,
                submission: currentSubmission,
            });

        if (predefinedVvgTariff) {
            setVvgTariff(predefinedVvgTariff);
        }
    }

    const getRedirect = () => {
        let redirectTo = null;

        const redirectParamsToProductPage = {
            slug: 'dashboard',
            id: currentSubmission.id.uuid,
            type: 'offer',
            tab: 'product',
            personId: personId,
        };
        const redirectParamsToDashboard = {
            slug: 'dashboard',
            id: currentSubmission.id.uuid,
            type: 'offer',
            tab: 'cockpit',
        };
        if (isSubmitted) {
            const basketErrors = validatePersonBasket(basket, personId);

            if (basketErrors.length === 0) {
                // If everything is fine, we can redirect to the dashboard
                redirectTo = redirectParamsToDashboard;
            } else {
                // If the basket entries are not valid, we need to redirect to the product page
                log.info('Redirecting to product overview, as some errors were found in the basket.', basketErrors);
                redirectTo = redirectParamsToProductPage;
            }
        } else if (!isSubmitting && currentBasket.vvg.length > 0) {
            // Special case: Redirect to product overview, if there already is a vvg product in basket
            log.info('Redirecting to product overview, because a vvg product already is in basket');
            redirectTo = redirectParamsToProductPage;
        }

        return redirectTo;
    };

    const redirectTo = getRedirect();
    if (redirectTo) {
        return <NamedRedirect name="EditSubmissionPage" params={redirectTo} />;
    }

    const okpProductIdentifier = getProductIdentifier(okpTariff?.product);
    const vvgProductIdentifier = getProductIdentifier(vvgProducts[productRecommendation]['productId'], false);

    /**
     * This method is executed when the user selects a product
     * It's creating a new basket entry for the selected product
     *
     * @param {*} mode 'okp' or 'combo'
     */
    const onSelection = async mode => {
        if (!vvgTariff) {
            return;
        }
        const okp = { enabled: true };
        const vvg = {
            enabled: false,
            section: vvgProducts[productRecommendation]['sectionId'],
            productGroups: {},
        };
        let tariff = { okp: currentBasket.okp, vvg: [] };

        if (mode === 'combo') {
            const basketTariff = createBasketEntryByTariffId(
                vvgTariff.id,
                apiVvgTariffs,
                vvgProducts[productRecommendation]['sectionId']
            );
            tariff.vvg = [basketTariff];
            vvg.enabled = true;
            vvg.productGroups = {
                [`${vvgProducts[productRecommendation]['sectionId']}_${basketTariff.productGroup}`]: true,
            };
            vvg.products = {
                [`${vvgProducts[productRecommendation]['sectionId']}_${basketTariff.productGroup}`]:
                    basketTariff.product,
            };
        }
        setIsSubmitting(true);

        await onProductSelectionChange(currentSubmission.id.uuid, personId, {
            tariff,
            okp,
            vvg,
        });

        // get user / product tracking data
        const dataLayerEventData = getPersonProductTrackingData(
            'praemienRechnerProduktwahl',
            currentPerson,
            currentSubmission
        );

        // submit dataLayer to gtm
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(dataLayerEventData);

        setIsSubmitted(true);
        setIsSubmitting(false);
    };

    const renderShowDetails = content => (
        <>
            <Hidden above="small">
                <StyledShowDetails>
                    <ShowDetailsContent>
                        {content}
                        <Spacer space="epsilon" />
                    </ShowDetailsContent>
                    <ShowDetailsTrigger
                        textExpanded={i18next.t('EditSubmissionProductSubPanelRecommend.showDetails.textExpanded')}
                        textCollapsed={i18next.t('EditSubmissionProductSubPanelRecommend.showDetails.textCollapsed')}
                    />
                </StyledShowDetails>
            </Hidden>
            <Hidden below="small">{content}</Hidden>
        </>
    );

    const data = {
        okp: {
            header: {
                title: i18next.t(`EditSubmissionProductSubPanelRecommend.okp.${okpProductIdentifier}.header.title`),
            },
            links: [
                {
                    linkText: i18next.t(
                        `EditSubmissionProductSubPanelRecommend.okp.${okpProductIdentifier}.link1.label`
                    ),
                    link: {
                        href: i18next.t(
                            `EditSubmissionProductSubPanelRecommend.okp.${okpProductIdentifier}.link1.href`
                        ),
                        target: '_blank',
                    },
                    color: 'primary',
                },
            ],
            card: {
                variant: 'product',
                icons: [
                    {
                        title: i18next.getFixedT('de')(
                            `EditSubmissionProductSubPanelRecommend.${okpProductIdentifier}.card.iconTitle`
                        ),
                        iconName: i18next.getFixedT('de')(
                            `EditSubmissionProductSubPanelRecommend.${okpProductIdentifier}.card.iconName`
                        ),
                    },
                ],
                iconStyle: { isInside: false },
                tagline: formatAmount(calculatePriceWithTax(okpTariff)),
                title: i18next.t(`EditSubmissionProductSubPanelRecommend.okp.${okpProductIdentifier}.card.title`),
                subtitle: i18next.t(`EditSubmissionProductSubPanelRecommend.okp.${okpProductIdentifier}.card.subtitle`),
                button: {
                    buttonText: i18next.t(
                        `EditSubmissionProductSubPanelRecommend.okp.${okpProductIdentifier}.card.buttonText`
                    ),
                    onClick: () => onSelection('okp'),
                    disabled: isSubmitting,
                },
            },
        },
        combo: {
            header: {
                title: i18next.t(`EditSubmissionProductSubPanelRecommend.combo.header.title`),
            },
            links: [
                {
                    linkText: i18next.t(
                        `EditSubmissionProductSubPanelRecommend.combo.${vvgProductIdentifier}.link.label`
                    ),
                    link: {
                        href: i18next.t(
                            `EditSubmissionProductSubPanelRecommend.combo.${vvgProductIdentifier}.link.href`
                        ),
                        target: '_blank',
                    },
                    color: 'primary',
                },
            ],
            card: {
                variant: 'product',
                icons: [
                    {
                        title: i18next.getFixedT('de')(
                            `EditSubmissionProductSubPanelRecommend.${okpProductIdentifier}.card.iconTitle`
                        ),
                        iconName: i18next.getFixedT('de')(
                            `EditSubmissionProductSubPanelRecommend.${okpProductIdentifier}.card.iconName`
                        ),
                    },
                    {
                        title: i18next.getFixedT('de')(
                            `EditSubmissionProductSubPanelRecommend.${vvgProductIdentifier}.card.iconTitle`
                        ),
                        iconName: i18next.getFixedT('de')(
                            `EditSubmissionProductSubPanelRecommend.${vvgProductIdentifier}.card.iconName`
                        ),
                    },
                ],
                iconStyle: { isInside: false },
                iconSeparator: '+',
                tagline: `${formatAmount(calculatePriceWithTax(okpTariff))} + ${formatAmount(
                    calculatePriceWithTax(vvgTariff)
                )}`,
                title: i18next.t(
                    `EditSubmissionProductSubPanelRecommend.combo.${okpProductIdentifier}.${vvgProductIdentifier}.card.title`
                ),
                subtitle: i18next.t(
                    `EditSubmissionProductSubPanelRecommend.combo.${okpProductIdentifier}.${vvgProductIdentifier}.card.subtitle`
                ),
                button: {
                    buttonText: i18next.t(
                        `EditSubmissionProductSubPanelRecommend.combo.${okpProductIdentifier}.${vvgProductIdentifier}.card.buttonText`
                    ),
                    onClick: () => onSelection('combo'),
                    disabled: isSubmitting,
                },
            },
            allProductsLink: {
                label: i18next.t(`EditSubmissionProductSubPanelRecommend.combo.allProductsLink.label`),
                link: {
                    name: 'EditSubmissionPage',
                    params: {
                        tab: 'product',
                        personId: personId,
                    },
                },
            },
        },
    };

    const renderOkpCardContent = () =>
        renderShowDetails(
            <StyledListContainer
                dangerouslySetInnerHTML={{
                    __html: sanitizeDOM(
                        i18next.t(`EditSubmissionProductSubPanelRecommend.okp.${okpProductIdentifier}.card.content`, {
                            franchise: formatAmount(okpTariff.franchise),
                            accidentIncludedText: i18next.t(
                                `ProductBasketPage.franchiseText.accident.${okpTariff.accidentIncluded}`
                            ),
                        })
                    ),
                }}
            />
        );

    const renderComboCardContent = () => {
        switch (productRecommendation) {
            case BASIC:
                return <StyledCard {...data.combo.card}>{renderComboCardAccordionContent()}</StyledCard>;
            case HOSPITAL:
                return <StyledCard {...data.combo.card}>{renderComboCardTextContent()}</StyledCard>;
            case UTI:
                return renderComboCardUtiContent();
        }
    };

    const renderComboCardTextContent = () =>
        renderShowDetails(
            <StyledListContainer
                dangerouslySetInnerHTML={{
                    __html: sanitizeDOM(
                        i18next.t(`EditSubmissionProductSubPanelRecommend.combo.${vvgProductIdentifier}.card.content`)
                    ),
                }}
            />
        );

    const renderComboCardAccordionContent = () => {
        const renderContent = () => {
            return (
                <StyledBoxWide>
                    <Accordion allowItemCollapse allowMultipleExpanded>
                        {[...Array(MAX_ACCORDION_ITEMS).keys()].map(i => {
                            const accordionIndex = i + 1;
                            const accordionItemExists = i18next.exists(
                                `EditSubmissionProductSubPanelRecommend.combo.${vvgProductIdentifier}.card.content.accordion.${accordionIndex}.trigger.title`
                            );

                            if (!accordionItemExists) {
                                return null;
                            }

                            const accordionItemHasAtLeasOneContentItem = i18next.exists(
                                `EditSubmissionProductSubPanelRecommend.combo.${vvgProductIdentifier}.card.content.accordion.${accordionIndex}.contentItem.1.title`
                            );

                            return (
                                <AccordionItem key={`${vvgProductIdentifier}_${accordionIndex}`}>
                                    <AccordionItemTrigger
                                        icon={i18next.getFixedT('de')(
                                            `EditSubmissionProductSubPanelRecommend.combo.${vvgProductIdentifier}.card.content.accordion.${accordionIndex}.trigger.icon`
                                        )}
                                        iconSize={Number(
                                            i18next.getFixedT('de')(
                                                `EditSubmissionProductSubPanelRecommend.combo.${vvgProductIdentifier}.card.content.accordion.${accordionIndex}.trigger.iconSize`
                                            )
                                        )}
                                        iconWidth={24}
                                        titleStyle="p2"
                                        description={
                                            i18next.exists(
                                                `EditSubmissionProductSubPanelRecommend.combo.${vvgProductIdentifier}.card.content.accordion.${accordionIndex}.trigger.description`
                                            ) &&
                                            i18next.t(
                                                `EditSubmissionProductSubPanelRecommend.combo.${vvgProductIdentifier}.card.content.accordion.${accordionIndex}.trigger.description`
                                            )
                                        }
                                        disableToggle={!accordionItemHasAtLeasOneContentItem}
                                    >
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: sanitizeDOM(
                                                    i18next.t(
                                                        `EditSubmissionProductSubPanelRecommend.combo.${vvgProductIdentifier}.card.content.accordion.${accordionIndex}.trigger.title`
                                                    )
                                                ),
                                            }}
                                        />
                                    </AccordionItemTrigger>
                                    <StyledAccordionItemContent>
                                        <Stack space="epsilon">
                                            {[...Array(MAX_ACCORDION_ITEMS).keys()].map(j => {
                                                const contentItemIndex = j + 1;
                                                const contentItemExists = i18next.exists(
                                                    `EditSubmissionProductSubPanelRecommend.combo.${vvgProductIdentifier}.card.content.accordion.${accordionIndex}.contentItem.${contentItemIndex}.title`
                                                );

                                                if (!contentItemExists) {
                                                    return null;
                                                }

                                                return (
                                                    <Stack
                                                        key={`${vvgProductIdentifier}_${accordionIndex}_${contentItemIndex}`}
                                                        direction="row"
                                                        space="gamma"
                                                    >
                                                        <StyledIcon
                                                            iconName={i18next.getFixedT('de')(
                                                                `EditSubmissionProductSubPanelRecommend.combo.${vvgProductIdentifier}.card.content.accordion.${accordionIndex}.contentItem.${contentItemIndex}.icon`
                                                            )}
                                                        />
                                                        <Box>
                                                            <P3>
                                                                {i18next.t(
                                                                    `EditSubmissionProductSubPanelRecommend.combo.${vvgProductIdentifier}.card.content.accordion.${accordionIndex}.contentItem.${contentItemIndex}.title`
                                                                )}
                                                            </P3>
                                                            <StyledAccordionContentText
                                                                dangerouslySetInnerHTML={{
                                                                    __html: sanitizeDOM(
                                                                        i18next.t(
                                                                            `EditSubmissionProductSubPanelRecommend.combo.${vvgProductIdentifier}.card.content.accordion.${accordionIndex}.contentItem.${contentItemIndex}.content`
                                                                        )
                                                                    ),
                                                                }}
                                                            />
                                                        </Box>
                                                    </Stack>
                                                );
                                            })}
                                        </Stack>
                                    </StyledAccordionItemContent>
                                </AccordionItem>
                            );
                        })}
                    </Accordion>
                </StyledBoxWide>
            );
        };

        return renderShowDetails(renderContent());
    };

    const renderComboCardUtiContent = () => {
        const utiData = {
            apiUtiTariffs,
            apiVvgTariffs,
            okpPrice: calculatePriceWithTax(okpTariff),
            onTariffChange: utiTariff => {
                const tariff = findVvgTariffById(utiTariff.tariff, apiVvgTariffs.tariffs);
                if (tariff) {
                    setVvgTariff(tariff);
                }
            },
            sliderDisability: {
                name: 'sliderInv',
                label: i18next.t(`EditSubmissionProductSubPanelRecommend.combo.cardContent.sliderInv`),
            },
            sliderDeath: {
                name: 'sliderDeath',
                label: i18next.t(`EditSubmissionProductSubPanelRecommend.combo.cardContent.sliderDeath`),
            },
            card: {
                ...data.combo.card,
                priceTag: null,
            },
        };
        return <StyledUtiWidget {...utiData} />;
    };
    const renderMobile = () => (
        <StyledContainer>
            {/** OKP begin **/}
            <StyledFullWidthContainer fullWidth>
                <Container>
                    <H2 dangerouslySetInnerHTML={{ __html: sanitizeDOM(data.okp.header.title) }} />
                    <Spacer space="delta" />
                    <Stack space="beta">
                        {data.okp.links.map(link => (
                            <Link key={link.linkText} {...link} />
                        ))}
                    </Stack>
                    <Spacer space="epsilon" />
                    <StyledCard {...data.okp.card}>{renderOkpCardContent()}</StyledCard>
                </Container>
            </StyledFullWidthContainer>
            {/** OKP end **/}

            {/** COMBO begin **/}
            <StyledFullWidthContainer fullWidth bgColor={colors.blue100}>
                <Container>
                    <H2 dangerouslySetInnerHTML={{ __html: sanitizeDOM(data.combo.header.title) }} />
                    <Spacer space="delta" />
                    <Stack space="beta">
                        {data.combo.links.map(link => (
                            <Link key={link.linkText} {...link} />
                        ))}
                    </Stack>
                    <Spacer space="epsilon" />
                    {renderComboCardContent()}
                    <Spacer space="epsilon" />
                    <NamedLink {...data.combo.allProductsLink.link}>
                        <StyledNamedLinkInner>
                            <StyledOverviewIcon iconName="link_arrow" />
                            <span dangerouslySetInnerHTML={{ __html: sanitizeDOM(data.combo.allProductsLink.label) }} />
                        </StyledNamedLinkInner>
                    </NamedLink>
                </Container>
            </StyledFullWidthContainer>
            {/** COMBO end **/}
        </StyledContainer>
    );

    const renderDesktop = () => (
        <StyledFullWidthContainer fullWidth twoColBackground>
            <StyledContainer>
                {/** HEADER begin **/}
                <Grid>
                    <StyledGridItem>
                        <H2 dangerouslySetInnerHTML={{ __html: sanitizeDOM(data.okp.header.title) }} />
                    </StyledGridItem>
                    <StyledGridItem>
                        <H2 dangerouslySetInnerHTML={{ __html: sanitizeDOM(data.combo.header.title) }} />
                    </StyledGridItem>
                </Grid>
                {/** HEADER end **/}
                <Spacer space="delta" />
                {/** LINKS begin **/}
                <Grid>
                    <StyledGridItem>
                        <Stack space="beta">
                            {data.okp.links.map(link => (
                                <Link key={link.linkText} {...link} />
                            ))}
                        </Stack>
                    </StyledGridItem>
                    <StyledGridItem>
                        <Stack space="beta">
                            {data.combo.links.map(link => (
                                <Link key={link.linkText} {...link} />
                            ))}
                        </Stack>
                    </StyledGridItem>
                </Grid>
                {/** LINKS end **/}
                <Spacer space="epsilon" />
                {/** CARDS begin **/}
                <Grid>
                    <StyledGridItem>
                        <StyledCard {...data.okp.card}>{renderOkpCardContent()}</StyledCard>
                    </StyledGridItem>
                    <StyledGridItem>{renderComboCardContent()}</StyledGridItem>
                </Grid>
                {/** CARDS end **/}

                {/** LINK TO PRODUCT PAGE begin **/}
                <Spacer space="epsilon" />
                <Grid>
                    <StyledGridItem></StyledGridItem>
                    <StyledGridItem>
                        <NamedLink {...data.combo.allProductsLink.link}>
                            <StyledNamedLinkInner>
                                <StyledOverviewIcon iconName="link_arrow" />
                                <span
                                    dangerouslySetInnerHTML={{ __html: sanitizeDOM(data.combo.allProductsLink.label) }}
                                />
                            </StyledNamedLinkInner>
                        </NamedLink>
                    </StyledGridItem>
                </Grid>
                {/** LINK TO PRODUCT PAGE after **/}
            </StyledContainer>
        </StyledFullWidthContainer>
    );

    return (
        <>
            <Hidden above="medium">{renderMobile()}</Hidden>
            <Hidden below="medium">{renderDesktop()}</Hidden>
        </>
    );
};

EditSubmissionProductSubPanelRecommend.propTypes = {
    onProductSelectionChange: func.isRequired,
    submission: object.isRequired,
    tariffs: object.isRequired,
};

export default EditSubmissionProductSubPanelRecommend;
