import styled from '@emotion/styled';
import { rem } from 'polished';
import { mixins, colors, text, spaces, fontWeights } from 'atupri-component-library/lib/core/styles/theme/default';
import { Icon } from 'atupri-component-library/lib/primitives';
import { Container } from 'atupri-component-library/lib/helpers';
import { MessageBox } from 'atupri-component-library/lib/components';

export const StyledContainer = styled(Container)`
    width: 100%;

    ${mixins.responsiveStyles({
        'padding-bottom': { zero: rem(80), medium: rem(128) },
    })}
`;

export const StyledHeader = styled.div`
    display: flex;
    border-bottom: ${rem(1)} solid ${colors.grey300};

    ${mixins.responsiveStyles({
        'flex-direction': { zero: 'column-reverse', medium: 'row' },
        padding: {
            zero: `0 0 ${rem(spaces.theta)} ${rem(spaces.beta)}`,
            medium: `0 0 ${rem(spaces.kappa)} ${rem(spaces.epsilon)}`,
        },
    })}

    > *:last-child {
        margin-left: auto;
    }
`;

export const StyledStateInfo = styled.div(({ ready }) => {
    const styles = [
        `
        color: ${colors.grey900};
        white-space: nowrap;
    `,
    ];

    if (ready) {
        styles.push(`
        color: ${colors.success}
      `);
    }

    return styles;
});

export const StyledStateValue = styled.div(({ ready }) => {
    const styles = [
        `
        display: inline-block;
        color: ${colors.grey700};
    `,
    ];

    if (ready) {
        styles.push(`
        color: ${colors.success}
      `);
    }

    return styles;
});

export const StyledState = styled.div`
    ${mixins.responsiveStyles({
        padding: {
            zero: `${rem(spaces.epsilon)} 0 ${rem(70)}`,
            medium: `${rem(spaces.kappa)} 0 ${rem(spaces.theta)}`,
            wide: `${rem(spaces.kappa)} 0 ${rem(spaces.theta)}`,
        },
    })}
`;

export const StyledStateIcon = styled(Icon)`
    margin-left: ${rem(20)};
    margin-right: ${rem(10)};
`;

export const StyledPersonal = styled.div`
    display: flex;
    align-items: center;

    ${mixins.responsiveStyles({
        padding: {
            zero: `0`,
            medium: `${rem(144)} 0 0`,
        },
    })}
`;

export const StyledUserInfo = styled.div`
    font-size: ${rem(16)};
    line-height: ${24 / 16};
`;

export const StyledUserIcon = styled(Icon)`
    font-size: ${rem(36)};
    color: ${colors.primary};
    margin-right: ${rem(32)};

    ${mixins.responsiveStyles({
        'font-size': {
            zero: `${rem(26)}`,
            medium: `${rem(36)}`,
        },
        'margin-right': {
            zero: `${rem(16)}`,
            medium: `${rem(32)}`,
        },
    })}
`;

export const StyledUserName = styled.div`
    ${text.h1}
    font-weight: ${fontWeights.regular};
    word-break: break-word;
`;

export const StyledTitle = styled.div`
    font-weight: 400;

    ${mixins.responsiveStyles({
        'line-height': {
            zero: `${32 / 20}`,
            medium: `${40 / 28}`,
        },
        'font-size': {
            zero: `${rem(20)}`,
            medium: `${rem(28)}`,
        },
        padding: {
            zero: `${rem(spaces.theta)} 0 ${rem(spaces.delta)} ${rem(spaces.beta)}`,
            medium: `${rem(spaces.kappa)} 0 ${rem(spaces.delta)} ${rem(spaces.epsilon)}`,
        },
    })}
`;

export const StyledSubtitle = styled.div`
    color: ${colors.grey700};

    ${mixins.responsiveStyles({
        'line-height': {
            zero: `${24 / 16}`,
            medium: `${32 / 20}`,
        },
        'font-size': {
            zero: `${rem(16)}`,
            medium: `${rem(20)}`,
        },
        padding: {
            zero: `0 0 ${rem(spaces.theta)} ${rem(spaces.beta)}`,
            medium: `0 0 ${rem(spaces.theta)} ${rem(spaces.epsilon)}`,
        },
    })}
`;

export const StyledCustomSpacer = styled.div(({ space }) => {
    const styles = [``];

    if (space === 'limbo') {
        styles.push(`
        ${mixins.responsiveStyles({
            'margin-top': { zero: spaces.epsilon, medium: spaces.eta },
        })}
      `);
    }

    return styles;
});

export const StyledHero = styled.div`
    position: absolute;
    left: 3.33%;
    right: 3.33%;
    top: 6.13%;
    bottom: 67.07%;
`;

export const StyledMessageBox = styled(MessageBox)`
    ${mixins.responsiveStyles({
        'margin-top': { zero: spaces.epsilon, medium: spaces.eta },
    })}

    width: calc(100vw - 10px);
    margin-left: calc(-50vw + 5px);
    left: 50%;
`;
