import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { rem } from 'polished';
import {
    animations,
    colors,
    mixins,
    spaces,
    spacingCurves,
    fontWeights,
    text,
} from 'atupri-component-library/lib/core/styles/theme/default';
import { AccordionItem, BaseAccordionItemTrigger } from 'atupri-component-library/lib/components';
import { H3, EmphasizedToggle, Link, P3, Select } from 'atupri-component-library/lib/primitives';
import { Box, Divider } from 'atupri-component-library/lib/helpers';

export const StyledAccordionItem = styled(AccordionItem)(({ isActive = false, hasBadge = false }) => {
    const defaultStyles = css`
        position: relative;
        border: 1px solid ${colors.grey600};
        border-radius: ${rem(8)};
        transition: border-color ${animations.default};

        ${mixins.responsiveStyles({
            'padding-left': { zero: spaces.delta, medium: spaces.epsilon, large: spaces.zeta, wide: spaces.theta },
            'padding-right': { zero: spaces.delta, medium: spaces.epsilon, large: spaces.zeta, wide: spaces.theta },
        })}

        &:hover {
            border-color: ${colors.primary};
        }

        margin-top: ${rem(hasBadge ? spaces.theta : spaces.epsilon)};
    `;

    const activeStyles = css`
        background-color: ${colors.blue100};
        border-color: ${colors.primary};
    `;

    const styles = [defaultStyles];

    if (isActive) {
        styles.push(activeStyles);
    }

    return styles;
});

export const StyledAccordionHeading = styled.div`
    position: relative;

    ${mixins.responsiveStyles({
        // Header Height + EditSubmissionWizardTabs Height + Inner AccordionItem Space
        'scroll-margin-top': { zero: 65 + 170 + spaces.epsilon, medium: 97 + 178 + spaces.epsilon },
    })}
`;

export const StyledAccordionToggleWrapper = styled.div(
    ({ firstLevel }) => css`
        position: absolute;
        display: flex;
        align-items: center;
        /* H3 Line-Height to match Title Height */
        height: ${rem(32)};

        ${mixins.responsiveStyles({
            top: firstLevel
                ? { zero: spaces.epsilon, medium: spaces.zeta, large: spaces.theta }
                : { zero: spaces.epsilon, medium: spaces.zeta },
        })}
    `
);

export const StyledAccordionTrigger = styled(BaseAccordionItemTrigger)`
    display: flex;
    flex-direction: column;
    width: 100%;
    cursor: pointer;

    ${mixins.responsiveStyles({
        ...spacingCurves.component.hecto('padding-top'),
        ...spacingCurves.component.hecto('padding-bottom'),
        'padding-left': { zero: 0, medium: spaces.theta },
        'padding-right': { zero: 0, medium: spaces.theta },
    })}
`;

export const StyledAccordionTriggerTitle = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: top,
        ${mixins.responsiveStyles({
            'padding-left': { zero: spaces.eta, medium: 0 },
            'padding-bottom': { zero: spaces.beta, medium: spaces.delta },
        })};
`;

export const StyledAccordionItemPrice = styled(P3)`
    margin-left: auto;
    padding-top: ${rem(spaces.alpha)};
    padding-left: ${rem(spaces.gamma)};
    white-space: nowrap;

    ${mixins.responsiveStyles({
        'font-weight': { zero: fontWeights.regular, medium: fontWeights.semibold },
    })}
`;

export const StyledAccordionItemSummary = styled(P3)`
    color: ${colors.grey700};

    ${mixins.responsiveStyles({
        'margin-bottom': { zero: spaces.gamma, medium: 0 },
    })}
`;

export const StyledAccordionItemIconBox = styled(Box)`
    display: flex;
    width: ${rem(64)};
    min-width: ${rem(64)};
    height: ${rem(64)};
    font-size: ${rem(28)};
    justify-content: center;
    align-items: center;
    margin-right: ${rem(spaces.epsilon)};

    i {
        color: ${colors.primary};
    }
`;

export const StyledAccordionItemTitle = styled(H3)(
    ({ isActive = false }) => css`
        color: ${isActive && colors.primary};
        word-break: break-word;
        flex: 1;

        ${mixins.responsiveStyles({
            'font-size': { zero: 16, medium: 20 },
            'padding-left': { zero: spaces.eta, medium: 0 },
        })}
    `
);

export const StyledAccordionContentText = styled.div`
    ${text.p3};

    > * {
        margin: 0;

        + * {
            margin-top: ${rem(spaces.delta)};
        }
    }
`;

export const StyledDivider = styled(Divider)(({ isActive = false }) => {
    let styles = [];

    if (isActive) {
        styles.push(`background-color: ${colors.blue500};`);
    }

    return styles;
});

export const StyledSelect = styled(Select)`
    ${mixins.responsiveStyles({
        'max-width': { zero: '100%', medium: 384 },
    })}
`;

export const StyledProductToggleWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: ${rem(spaces.delta)};
    padding: ${rem(spaces.delta)} 0;
`;

export const StyledProductToggle = styled(EmphasizedToggle)`
    height: 100%;
    transition: border-color ${animations.default};

    &:hover {
        border-color: ${colors.primary};
    }

    p {
        white-space: nowrap;
    }
`;

export const StyledProductLink = styled(Link)`
    color: ${colors.primary};

    ::before {
        content: '→';
        margin-right: ${rem(spaces.beta)};
    }
`;
