import styled from '@emotion/styled';
import { rem } from 'polished';
import { text } from 'atupri-component-library/lib/core/styles/theme/default';

export const StyledToggleRoot = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;

    input {
        position: absolute;
        width: ${rem(24)};
        height: ${rem(24)};
        z-index: 1;
        box-sizing: border-box;
        appearance: none;
        opacity: 0;
        top: 1px;
        left: 1px;

        &:checked + .toggleWrap .toggleIcon {
            background: #324ab5;

            svg {
                display: inline-flex;
                top: ${rem(2)};
                position: relative;
                left: 1px;
            }
        }
    }
`;

export const StyledLabelTop = styled.span`
    display: inline-block;
    margin-bottom: ${rem(24)};
`;

export const StyledLabel = styled.label`
    ${text.p3};
    display: flex;
    flex: 1 0 auto;
    align-items: center;
    padding-bottom: ${rem(24)};
`;
