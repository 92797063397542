import React from 'react';
import { bool, func, object, string } from 'prop-types';
import { getFormFieldByAnswerType } from '@src/components/HealthQuestions/util';
import { conditionalRender } from './util';

import { StyledAnswerItem } from './AnswerItem.style';

const AnswerItem = ({
    healthQuestions,
    answer,
    validate,
    disabled,
    allowedValues,
    isMultiGroup,
    modalRefs,
    prefix = '',
}) => {
    const { FormField, type } = getFormFieldByAnswerType(answer.type);

    const fieldProps = (() => {
        const allProps = {
            id: answer.id,
            name: `${prefix}${answer.id}`,
            type: type,
            healthQuestions: healthQuestions,
            answer: answer,
            label: answer.label,
            hideLabel: type === 'text',
            items: answer.items,
            placeholder: answer.placeholder,
            validate: validate,
            disabled: disabled,
            value: answer.value,
            allowedValues: allowedValues,
            modalRefs: modalRefs,
            isMultiGroup: isMultiGroup,
        };

        return Object.entries(allProps).reduce((props, prop) => {
            const [name, value] = prop;
            return Object.assign(props, {
                [name]: value,
            });
        }, {});
    })();

    const render = () => {
        return (
            <StyledAnswerItem
                id={`ANSWER-ITEM_${answer.id}`}
                className={`ANSWER-ITEM TYPE-${type}`}
                key={`ANSWER-ITEM_${answer.id}`}
                answerType={type}
            >
                {FormField && <FormField {...fieldProps} />}
            </StyledAnswerItem>
        );
    };

    return conditionalRender(answer, render);
};

AnswerItem.propTypes = {
    data: object,
    answer: object,
    validate: func,
    disabled: bool,
    allowedValues: object,
    isMultiGroup: bool,
    modalRefs: object,
    prefix: string,
};

export default AnswerItem;
