import React from 'react';
import { rem } from 'polished';
import styled from '@emotion/styled';
import { bool, func, object, string } from 'prop-types';
import i18next from 'i18next';
import classNames from 'classnames';
import { WizardHeader } from '@src/components';
import { ensureOwnSubmission } from '@src/util/data';
import { isChildOnlyProcess } from '@src/util/process';
import { EXTERNAL_PERSON, findAdults } from '@src/util/person';

import { EditSubmissionPaymentForm } from '@src/forms';

import { mixins } from 'atupri-component-library/lib/core/styles/theme/default';

const StyledContainer = styled.div`
    ${mixins.responsiveStyles({
        'padding-top': { zero: rem(144), medium: rem(144) },
        'padding-bottom': { zero: rem(80), medium: rem(128) },
    })}
`;

const EditSubmissionPaymentPanel = props => {
    const {
        className,
        rootClassName,
        submission,
        disabled,
        ready,
        onSubmit,
        onChange,
        submitButtonText,
        backButtonText,
        panelUpdated,
        updateInProgress,
        errors,
    } = props;

    const classes = classNames(rootClassName || 'rootPaymentPanel', className);
    const currentSubmission = ensureOwnSubmission(submission);
    const {
        paymentContactperson,
        paymentContactpersonEmail,
        paymentContactpersonPhone,
        paymentStreet,
        paymentAddressExtension,
        paymentContactAdditionalPersonGender,
        paymentContactAdditionalPersonFirstname,
        paymentContactAdditionalPersonLastname,
        paymentContactAdditionalPersonBirthdate,
        paymentContactAdditionalPersonEmail,
        paymentContactAdditionalPersonPhone,
        paymentContactAdditionalPersonStreet,
        paymentContactAdditionalPersonAddressExtension,
        paymentMethod,
        paymentRefund,
        paymentInterval,
    } = currentSubmission.attributes.privateData;

    const panelTitle = i18next.t('EditSubmissionPaymentPanel.panelTitle');
    const panelText = i18next.t('EditSubmissionPaymentPanel.panelText');

    const initialPaymentContactperson = (() => {
        const adultPersons = findAdults(currentSubmission.relationships.persons);
        return isChildOnlyProcess(currentSubmission) || adultPersons.length === 0
            ? EXTERNAL_PERSON
            : adultPersons[0].id;
    })();

    return (
        <StyledContainer className={classes}>
            <WizardHeader panelTitle={panelTitle} panelText={panelText} />
            <EditSubmissionPaymentForm
                className={'form'}
                initialValues={{
                    paymentContactperson: paymentContactperson || initialPaymentContactperson,
                    paymentContactpersonEmail,
                    paymentContactpersonPhone,
                    paymentStreet: paymentStreet || currentSubmission.relationships.persons[0].street,
                    paymentAddressExtension:
                        paymentAddressExtension || currentSubmission.relationships.persons[0].addressExtension,
                    paymentLocation: currentSubmission.relationships.persons[0].place,
                    paymentZip: currentSubmission.relationships.persons[0].zip,
                    paymentZipText: currentSubmission.relationships.persons[0].zipText,
                    paymentContactAdditionalPersonGender,
                    paymentContactAdditionalPersonFirstname,
                    paymentContactAdditionalPersonLastname,
                    paymentContactAdditionalPersonBirthdate,
                    paymentContactAdditionalPersonEmail,
                    paymentContactAdditionalPersonPhone,
                    paymentContactAdditionalPersonStreet:
                        paymentContactAdditionalPersonStreet || currentSubmission.relationships.persons[0].street,
                    paymentContactAdditionalPersonAddressExtension:
                        paymentContactAdditionalPersonAddressExtension ||
                        currentSubmission.relationships.persons[0].addressExtension,
                    paymentContactAdditionalPersonLocation: currentSubmission.relationships.persons[0].place,
                    paymentContactAdditionalPersonZip: currentSubmission.relationships.persons[0].zip,
                    paymentContactAdditionalPersonZipText: currentSubmission.relationships.persons[0].zipText,
                    paymentMethod,
                    paymentRefund,
                    paymentInterval,
                }}
                saveActionMsg={submitButtonText}
                backActionMsg={backButtonText}
                onSubmit={values => {
                    let {
                        paymentContactperson,
                        paymentContactpersonEmail,
                        paymentContactpersonPhone,
                        paymentStreet,
                        paymentAddressExtension,
                        paymentLocation,
                        paymentZip,
                        paymentZipText,
                        paymentContactAdditionalPersonGender,
                        paymentContactAdditionalPersonFirstname,
                        paymentContactAdditionalPersonLastname,
                        paymentContactAdditionalPersonBirthdate,
                        paymentContactAdditionalPersonEmail,
                        paymentContactAdditionalPersonPhone,
                        paymentContactAdditionalPersonStreet,
                        paymentContactAdditionalPersonAddressExtension,
                        paymentContactAdditionalPersonLocation,
                        paymentContactAdditionalPersonZip,
                        paymentContactAdditionalPersonZipText,
                        paymentMethod,
                        paymentRefund,
                        paymentInterval,
                    } = values;

                    const updateValues = {
                        privateData: {
                            paymentContactperson,
                            paymentContactpersonEmail,
                            paymentContactpersonPhone,
                            paymentStreet,
                            paymentAddressExtension,
                            paymentLocation,
                            paymentZip,
                            paymentZipText,
                            paymentContactAdditionalPersonGender,
                            paymentContactAdditionalPersonFirstname,
                            paymentContactAdditionalPersonLastname,
                            paymentContactAdditionalPersonBirthdate,
                            paymentContactAdditionalPersonEmail,
                            paymentContactAdditionalPersonPhone,
                            paymentContactAdditionalPersonStreet,
                            paymentContactAdditionalPersonAddressExtension,
                            paymentContactAdditionalPersonLocation,
                            paymentContactAdditionalPersonZip,
                            paymentContactAdditionalPersonZipText,
                            paymentMethod,
                            paymentRefund,
                            paymentInterval,
                        },
                    };

                    onSubmit(updateValues);
                }}
                onChange={onChange}
                disabled={disabled}
                ready={ready}
                updated={panelUpdated}
                updateError={errors.updateSubmissionError}
                updateInProgress={updateInProgress}
                fetchErrors={errors}
                submission={submission}
            />
        </StyledContainer>
    );
};

EditSubmissionPaymentPanel.defaultProps = {
    className: null,
    rootClassName: null,
    errors: null,
    submission: null,
};

EditSubmissionPaymentPanel.propTypes = {
    className: string,
    rootClassName: string,

    // We cannot use propTypes.submission since the submission might be an offer.
    submission: object,
    disabled: bool.isRequired,
    ready: bool.isRequired,
    onSubmit: func.isRequired,
    onChange: func.isRequired,
    submitButtonText: string.isRequired,
    panelUpdated: bool.isRequired,
    updateInProgress: bool.isRequired,
    errors: object,
};

export default EditSubmissionPaymentPanel;
