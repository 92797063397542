import styled from '@emotion/styled';
import { mixins, colors, spaces } from 'atupri-component-library/lib/core/styles/theme/default';
import { Grid, GridItem } from 'atupri-component-library/lib/helpers';

export const StyledGrid = styled(Grid)`
    ${mixins.responsiveStyles({ 'margin-bottom': { zero: 0, medium: spaces.beta } })}
`;

export const StyledGridItem = styled(GridItem)`
    ${mixins.responsiveStyles({ 'margin-bottom': { zero: spaces.epsilon, medium: 0 } })}

    &:last-of-type {
        ${mixins.responsiveStyles({ 'margin-bottom': { zero: 0, medium: 0 } })}
    }
`;

export const StyledEmphasizedToggle = styled.div`
    width: 100%;

    > div {
        width: 100%;
    }

    label > div {
        border: 1px solid ${colors.blue800};

        div:first-of-type {
            div {
                margin-right: 0.5rem;
            }
            p {
                font-size: 0.75rem;
                font-weight: 600;
                line-height: 2;
                color: ${colors.primary};
            }
        }
        div {
            padding-left: 0;

            p {
                font-weight: 400;
                font-size: 0.875rem;

                b,
                strong {
                    font-weight: 600;
                    font-size: 1.25rem;
                }
            }
        }
    }
`;

export const StyledFormControls = styled.div`
    display: flex;
    ${mixins.responsiveStyles({
        'flex-direction': { zero: 'column', medium: 'row' },
        'padding-top': { zero: spaces.beta, medium: spaces.epsilon },
        gap: { zero: spaces.delta, medium: spaces.epsilon },
    })}
`;
