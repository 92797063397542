import React from 'react';
import { log } from '@src/middleware';
import {
    FieldBool,
    FieldInput,
    FieldRadioButtonGroup,
    FieldCheckbox,
    FieldToggle,
    AnswerMultiple,
    FieldDatePicker,
} from '@src/components';

import Condition from './Condition';
import { FormSpyFieldValues } from '@src/util/finalForm/FormSpyFieldValues';

/**
 * Returns an answer by given id, null if no answer exists
 *
 * @param {*} id
 */
export const getAnswerById = (answers, id) => {
    const answer = answers.filter(answer => answer.id === id);
    return answer[0] || {};
};

/**
 * Conditionally calls a given callback if element conditions are met
 *
 *
 * @param {*} element
 * @param {*} render
 * @returns
 */
export const conditionalRender = (element, render) => {
    const operator = element.ifAnd ? 'and' : 'or';
    const conditions = element.if ? [element.if] : element.ifOr || element.ifAnd;

    if (Array.isArray(conditions)) {
        const answerIds = conditions.map(condition => `healthQuestions.${condition.reference}`);
        return (
            <FormSpyFieldValues fieldNames={answerIds}>
                {() => (
                    <Condition conditions={conditions} operator={operator}>
                        {render()}
                    </Condition>
                )}
            </FormSpyFieldValues>
        );
    } else {
        return render();
    }
};

/**
 * Returns correct FormField and type properties by given answerType
 *
 * @param string answerType
 * @return {}
 */
export const getFormFieldByAnswerType = answerType => {
    let FormField = null;
    let type = '';

    switch (answerType) {
        case 'BOOL':
            FormField = FieldBool;
            type = 'bool';
            break;
        case 'RADIO':
            FormField = FieldRadioButtonGroup;
            type = 'radio-button';
            break;
        case 'NUMBER':
        case 'SHORTTEXT':
        case 'MEDIUMTEXT':
        case 'LONGTEXT':
        case 'INPUT':
        case 'TEXT':
            FormField = FieldInput;
            type = 'text';
            break;
        case 'DATEPICKER':
            FormField = FieldDatePicker;
            type = 'datepicker';
            break;
        case 'MONTHPICKER':
            FormField = FieldDatePicker;
            type = 'monthpicker';
            break;
        case 'TOGGLE':
            FormField = FieldToggle;
            type = 'toggle';
            break;
        case 'CHECKBOX':
            FormField = FieldCheckbox;
            type = 'checkbox';
            break;
        case 'MULTIPLE':
            FormField = AnswerMultiple;
            type = 'answer-multi';
            break;
        default:
            log.error(`Unknown FormField "${answerType}".`);
            break;
    }
    return { FormField, type };
};
