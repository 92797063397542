import React from 'react';
import { func, node, string } from 'prop-types';

const Form = ({ id = null, children = null, contentRef = null, className = '', onSubmit = undefined }) => {
    const formProps = {
        // mainly default values for SSR
        // -> form would submit potentially
        // sensitive data with the default GET method until client code loaded
        id: id,
        method: 'post',
        action: '/',

        // allow content ref function to be passed to the form
        ref: contentRef,
        onSubmit: onSubmit,
        className: className,
    };
    return <form {...formProps}>{children}</form>;
};

Form.propTypes = {
    id: string,
    children: node,
    contentRef: func,
    className: string,
    onSubmit: func,
};

export default Form;
