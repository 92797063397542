import styled from '@emotion/styled';
import { rem } from 'polished';
import { fontWeights, mixins, spaces, text, colors } from 'atupri-component-library/lib/core/styles/theme/default';

export const StyledInlineListContainer = styled.div`
    width: 100%;
`;

export const StyledInlineListEnumeration = styled.div`
    ${text.p3}

    ${mixins.responsiveStyles({
        margin: {
            zero: `0 0 ${rem(spaces.delta)}`,
            medium: `0 0 ${rem(spaces.epsilon)}`,
        },
    })}

  > span {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        background-color: ${colors.blue300};
        border-radius: 100%;
        font-size: ${rem(spaces.delta)};
        line-height: 1;
        font-weight: ${fontWeights.semibold};
        color: ${colors.primary};
        height: ${rem(spaces.zeta)};
        width: ${rem(spaces.zeta)};
        margin: 0 ${rem(14)} 0 0;
    }
`;
