import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { rem, rgba } from 'polished';
import { Icon } from 'atupri-component-library/lib/primitives';
import { mixins, text, colors, spaces, fontWeights } from 'atupri-component-library/lib/core/styles/theme/default';

export const StyledTable = styled.table`
    width: 100%;
    border-spacing: 0;

    thead:first-of-type {
        tr:first-of-type {
            th:first-of-type {
                border-radius: 4px 4px 0 0;
            }
        }
    }
    tbody:last-of-type {
        tr:last-of-type {
            td:last-of-type {
                border-radius: 0 0 4px 4px;
            }
        }
    }

    tbody:first-of-type {
        tr:not(:last-of-type) {
            td {
                border-bottom-color: ${colors.grey400};
            }
        }
    }
`;

export const StyledTableTH = styled.th`
    ${text.h3};
    font-size: ${rem(16)};
    font-weight: ${fontWeights.regular};
    border: ${rem(1)} solid ${colors.grey500};
    padding: ${rem(32)} 0;
    text-align: left;
    padding-left: ${rem(20)};
    border-bottom-color: ${colors.grey400};
`;

export const StyledTableTD = styled.td`
    border: ${rem(1)} solid ${colors.grey500};
    border-top: 0;
    padding: ${rem(24)};
`;

export const StyledTableBox = styled.div`
    display: flex;

    ${mixins.responsiveStyles({
        'flex-direction': { zero: 'column', medium: 'row' },
        'justify-content': { zero: 'flex-start', medium: 'flex-end' },
        'align-items': { medium: 'center' },
    })}
`;

export const StyledTableBoxIcon = styled.div`
    min-width: ${rem(64)};
    height: ${rem(64)};
    padding-right: ${rem(24)};
`;

export const StyledTableBoxIconPanel = styled.div`
    width: ${rem(64)};
    height: ${rem(64)};
    background-color: ${rgba(colors.primary, 0.1)};
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const StyledDL = styled.dl`
    flex: 1;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    ${mixins.responsiveStyles({
        'margin-bottom': { zero: spaces.delta, medium: 0 },
    })}
`;

export const StyledDT = styled.dt`
    ${text.p1};
    font-weight: ${fontWeights.semibold};
`;

export const StyledDD = styled.dd`
    ${text.p3};
    color: ${colors.grey700};
    margin: 0;

    ${mixins.responsiveStyles({
        'padding-top': { medium: spaces.beta },
    })}
`;

export const StyledTableBoxPrice = styled.div(
    ({ priceLabel }) => css`
        width: ${rem(200)};
        text-align: right;

        ${priceLabel
            ? css`
                  display: flex;
                  align-items: center;

                  ${mixins.responsiveStyles({
                      'justify-content': { zero: 'flex-start', medium: 'flex-end' },
                  })}
              `
            : css`
                  ${mixins.responsiveStyles({
                      'text-align': { zero: 'left', medium: 'right' },
                      'font-weight': { medium: fontWeights.semibold },
                  })}
              `}
    `
);

export const StyledTableBoxPriceDL = styled.dl`
    margin: 0;

    ${mixins.responsiveStyles({
        'text-align': { zero: 'left', medium: 'right' },
    })}

    dt {
        ${text.p4};
        color: ${colors.grey700};
    }

    dd {
        ${text.p1};
        color: ${colors.grey900};
        margin-top: ${rem(spaces.beta)};
    }
`;

export const StyledTax = styled.div`
    ${mixins.responsiveStyles({
        width: { zero: '100%', medium: rem(320) },
    })}
`;

export const StyledTaxRow = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const StyledTaxLabel = styled.div`
    ${text.p4};
    color: ${colors.grey700};
`;

export const StyledTaxPrice = styled.div`
    ${text.p4};
    color: ${colors.grey900};
`;

export const StyledTaxTotal = styled.div`
    display: flex;
    justify-content: space-between;
    border-top: 1px solid ${colors.grey400};
    padding-top: ${rem(spaces.beta)};
    align-items: center;
`;

export const StyledTaxPriceTotal = styled.div`
    ${text.p1};
    color: ${colors.grey900};
`;

export const StyledIcon = styled(Icon)`
    font-size: ${rem(26)};
`;
