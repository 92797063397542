import myAtupriConfig, { getLoggedInUserId } from '@src/config/myAtupriApi';
import { UsersApi } from '@src/generated';

import sampleSubscribedProducts from '@src/data/mock/myAtupri/subscribedProducts.json';
import sampleEffectiveDate from '@src/data/mock/myAtupri/effectiveDate.json';

class UserService {
    constructor() {
        this.api = new UsersApi(myAtupriConfig);
    }

    loadSubscribedProducts() {
        const userId = getLoggedInUserId();

        if (window.localStorage.getItem('mockLocalData') === 'true') {
            return Promise.resolve(sampleSubscribedProducts);
        } else {
            return this.api.getProductsForFamily(userId);
        }
    }

    loadEffectiveDates(insuranceNumber, newObjectId, currentObjectId = undefined) {
        const userId = getLoggedInUserId();

        if (window.localStorage.getItem('mockLocalData') === 'true') {
            return Promise.resolve(sampleEffectiveDate);
        } else {
            return this.api.getEffectiveDate(insuranceNumber, newObjectId, userId, currentObjectId);
        }
    }
}

export default new UserService();
