import React from 'react';
import { array } from 'prop-types';
import { Block } from '@src/components';
import { filterElementsByIdentifier } from '@src/util/array';

const HealthQuestionsFormPartial = props => {
    const { healthQuestions } = props;

    let blocks = healthQuestions ? filterElementsByIdentifier(healthQuestions, 'parent', undefined) : null;

    return (
        <div className="health-questions__blocks">
            {blocks?.map(block => (
                <Block key={block.id} healthQuestions={healthQuestions} block={block} prefix="healthQuestions." />
            ))}
        </div>
    );
};

HealthQuestionsFormPartial.propTypes = {
    healthQuestions: array.isRequired,
};

export default HealthQuestionsFormPartial;
