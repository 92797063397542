import styled from '@emotion/styled';
import { rem } from 'polished';
import { mixins, colors, spaces } from 'atupri-component-library/lib/core/styles/theme/default';
import { Container } from 'atupri-component-library/lib/helpers';

export const StyledCustomSpacer = styled.div(({ space }) => {
    const styles = [];

    if (space === 'limbo') {
        styles.push(`
        ${mixins.responsiveStyles({
            'margin-top': { zero: 0, medium: spaces.lambda, wide: spaces.lambda },
        })}
      `);
    }

    return styles;
});

export const StyledSubtitle = styled.div`
    color: ${colors.grey700};

    ${mixins.responsiveStyles({
        'line-height': {
            zero: `${24 / 16}`,
            medium: `${32 / 20}`,
        },
        'font-size': {
            zero: 16,
            medium: 20,
        },
        padding: {
            zero: `0 0 ${rem(spaces.theta)} ${rem(spaces.beta)}`,
            medium: `0 0 ${rem(spaces.theta)} ${rem(spaces.epsilon)}`,
        },
    })}
`;

export const StyledContainer = styled(Container)`
    width: 100%;

    ${mixins.responsiveStyles({
        'padding-bottom': { zero: 80, medium: 128 },
    })}
`;

export const StyledHeader = styled.div`
    display: flex;
    border-bottom: ${rem(1)} solid ${colors.grey300};

    ${mixins.responsiveStyles({
        'flex-direction': { zero: 'column-reverse', medium: 'row' },
        padding: {
            zero: `0 0 ${rem(spaces.theta)} ${rem(spaces.beta)}`,
            medium: `0 0 ${rem(spaces.kappa)} ${rem(spaces.epsilon)}`,
        },
    })}

    > *:last-child {
        margin-left: auto;
    }
`;

export const StyledTitle = styled.div`
    font-weight: 400;

    ${mixins.responsiveStyles({
        'line-height': {
            zero: `${32 / 20}`,
            medium: `${40 / 28}`,
        },
        'font-size': {
            zero: 20,
            medium: 28,
        },
        padding: {
            zero: `${rem(spaces.theta)} 0 ${rem(spaces.delta)} ${rem(spaces.beta)}`,
            medium: `${rem(spaces.kappa)} 0 ${rem(spaces.delta)} ${rem(spaces.epsilon)}`,
        },
    })}
`;

export const StyledCtaBar = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    ${mixins.responsiveStyles({
        'flex-direction': {
            zero: `column-reverse`,
            medium: `row`,
        },
        'padding-top': {
            zero: 48,
            medium: 64,
            wide: 80,
        },
        'margin-bottom': {
            zero: spaces.gamma * -1,
            small: 0,
        },
    })}
`;

export const StyledCtaBarCol = styled.div`
    ${mixins.responsiveStyles({
        'margin-bottom': {
            zero: spaces.gamma,
            small: 0,
        },
    })}
`;
