import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import i18next from 'i18next';
import { log } from '@src/middleware';

// components
import { Main, NamedRedirect, Page, ProductBasketPanel } from '@src/components';
import { Footer, Header } from 'atupri-component-library/lib/components';

// data
import { footerData, headerData } from '@src/data';

// ducks
import { getPremcalcEntities } from '@src/ducks/premcalcData.duck';
import { loadData } from './ProductBasketPage.duck';

// util
import { calcPersonPrice } from '@src/util/basket';
import { formatAmount } from '@src/util/currency';
import { ensureOwnSubmission } from '@src/util/data';
import { pathByRouteName } from '@src/util/routes';
import { getSubmissionId } from '@src/util/submission';
import { isMobileApp } from '@src/util/mobileAppDetection';

// style
import { StyledContainer } from './ProductBasketPage.style';

export const ProductBasketPage = () => {
    const params = useParams();
    const submissionId = getSubmissionId();

    const { showSubmissionError, submission, basket, productUser } = useSelector(state => {
        let submission = null;
        if (typeof submissionId !== 'undefined') {
            const submissions = getPremcalcEntities(state, [{ id: submissionId, type: 'ownSubmission' }]);
            submission = ensureOwnSubmission(submissions.length === 1 ? submissions[0] : null);
        }

        const basket = submission?.relationships?.basket?.[params.userId] || [];

        const productUser = submission?.relationships?.persons
            .map(person => {
                if (person.id === params.userId) {
                    return person;
                }
            })
            .filter(function (usr) {
                return typeof usr != 'undefined';
            })[0];

        return {
            showSubmissionError: state.ProductBasketPage.showSubmissionError,
            currentProducts: state.apiData?.currentProducts,
            submission,
            basket,
            productUser,
        };
    });

    if (!basket) {
        log.debug('no basket');
    }

    const datasetComplete = basket && submission && productUser;

    if (showSubmissionError) {
        return <NamedRedirect name="EditSubmissionPage" params={{ tab: 'cockpit' }} />;
    }

    const updateError = {
        text: showSubmissionError,
    };

    return (
        <Page>
            {!isMobileApp() && (
                <Header
                    {...headerData()}
                    sessionButton={{
                        buttonText: i18next.t('Header.back'),
                        link: {
                            to: pathByRouteName('EditSubmissionPage', { tab: 'cockpit' }),
                        },
                        variant: 'primary',
                        outlined: true,
                        iconBefore: 'arrow_left',
                    }}
                />
            )}
            <Main complete={datasetComplete} hasError={!!showSubmissionError} dataError={updateError}>
                <StyledContainer>
                    <ProductBasketPanel
                        productUser={productUser}
                        basket={basket}
                        totalPrice={formatAmount(calcPersonPrice(basket))}
                        submissionId={submissionId.uuid}
                        backActionMsg={'Zurück'}
                    />
                </StyledContainer>
            </Main>
            {!isMobileApp() && <Footer {...footerData()} />}
        </Page>
    );
};

ProductBasketPage.propTypes = {};

ProductBasketPage.loadData = loadData;

export default ProductBasketPage;
