import { healthServiceProviderList as mockHealthServiceProvider } from '@src/data';
import axios from 'axios';
import queryString from 'query-string';
import config from '@src/config';
import { log } from '@src/middleware';
import { environmentConfiguration } from '@src/config/env';

const HEALTH_SERVICE_PROVIDER_API = `${config.apiEsbBase}/health_service_providers/v2`;

const { dev } = environmentConfiguration;

export const API_GET_PRACTICES = (radius, zip, city, insuranceStart) =>
    new Promise((resolve, reject) => {
        log.debug('=== API_GET_PRACTICES ===', { radius, zip, city, insuranceStart });

        let res = {
            status: 200,
            statusText: '',
            data: null,
        };
        let resObj = JSON.parse(JSON.stringify(res));

        const payload = {
            Radius: radius,
            ZipCode: zip,
            Location: city,
            CoverBegin: insuranceStart,
        };

        axios
            .get(HEALTH_SERVICE_PROVIDER_API + '?' + queryString.stringify(payload), {
                timeout: 30000,
            })
            .then(response => {
                let data = response.data;

                if (data) {
                    resObj.data = response.data.healthServiceProvider;
                } else if (dev) {
                    // fallback json for dev
                    log.info('PracticeList error. Using fallback json file');
                    resObj.data = mockHealthServiceProvider;
                } else {
                    // data exception in prod
                    reject({ data: 'Error.code', code: '50' });
                }

                resolve(resObj);
            })
            .catch(err => {
                log.error({ code: err.code, error: err.message });

                // temporary fallback for development
                if (dev) {
                    log.info('PracticeList API error or timeout. Using fallback json file');
                    resObj.data = mockHealthServiceProvider;
                    resolve(resObj);
                } else {
                    reject({ data: 'Error.API', code: '51' });
                }
            });
    });
