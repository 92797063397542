import axios from 'axios';
import config from '@src/config';
import { log } from '@src/middleware';

const UNIQUE_KEY_API = `${config.apiSessionBase}/uniquekey`;

export const API_UNIQUE_KEY = () =>
    new Promise((resolve, reject) => {
        log.info('=== API_UNIQUE_KEY ===', UNIQUE_KEY_API);

        axios
            .get(UNIQUE_KEY_API, {
                timeout: 60000,
            })
            .then(response => {
                const data = response?.data;
                if (typeof data === 'number') {
                    let res = {
                        status: 200,
                        statusText: '',
                        key: data,
                    };

                    resolve(res);
                } else {
                    log.error('Error getting state from API: ', data);
                    // TODO 1281 Which error number should be used?
                    reject({ data: 'Error.code', code: '60' });
                }
            })
            .catch(e => {
                // TODO 1281 Which error number should be used?
                reject({ data: 'Error.API', code: '61' });
            });
    });
