import React from 'react';
import i18next from 'i18next';
import moment from 'moment';
import { array, bool, object, string } from 'prop-types';
import classNames from 'classnames';

// components
import { NamedLink, TableList } from '@src/components';
import { Box, Hidden, Spacer, Stack } from 'atupri-component-library/lib/helpers';
import { Button, Icon, P3 } from 'atupri-component-library/lib/primitives';

// utils
import { convertDateToPresentationFormat } from '@src/util/date';

// styles
import {
    StyledH1,
    StyledTotalBar,
    StyledTotalPrice,
    StyledInfobar,
    StyledPerson,
    StyledCTA,
    StyledDT,
    StyledDD,
} from './ProductBasketPanel.style';

function ProductBasketHeader(props) {
    const { productUser, showEditProducts = true } = props;

    const birthdate = convertDateToPresentationFormat(productUser.birthdate);
    const insuranceStart = convertDateToPresentationFormat(productUser.insuranceStart);

    const msgBirthdate = i18next.t('ProductBasketPage.birthdate');
    const msgStartCoverage = i18next.t('ProductBasketPage.startCoverage');
    const msgLinkProductSelection = i18next.t('ProductBasketPage.msgLinkProductSelection');

    return (
        <StyledInfobar>
            <StyledPerson>
                <div>
                    <StyledDT>{msgBirthdate}</StyledDT>
                    <StyledDD>{birthdate || '\u00A0'}</StyledDD>
                </div>
                <div>
                    <StyledDT reset={true}>{msgStartCoverage}</StyledDT>
                    <StyledDD>{insuranceStart || '\u00A0'}</StyledDD>
                </div>
            </StyledPerson>
            {showEditProducts && (
                <StyledCTA>
                    <NamedLink name="EditSubmissionPage" params={{ tab: 'product', personId: productUser.id }}>
                        <Icon iconName="link_arrow" />
                        {msgLinkProductSelection}
                    </NamedLink>
                </StyledCTA>
            )}
        </StyledInfobar>
    );
}

const ProductBasketPanel = props => {
    const {
        className,
        rootClassName,
        productUser,
        basket,
        totalPrice,
        submissionId,
        header = true,
        footer = true,
        effectiveDates = [],
        currentProducts = [],
    } = props;

    const classes = classNames(rootClassName || 'product-basket-panel', className);

    const firstName = productUser.firstname ? productUser.firstname : null;
    const lastName = productUser.lastname ? productUser.lastname : null;

    const isInternalFlow = currentProducts && currentProducts.length > 0;

    const msgPageTitle = i18next.t('ProductBasketPage.pageTitle');
    const msgTotal = i18next.t('ProductBasketPage.total');
    const msgBackButton = i18next.t('ProductBasketPage.backButton');
    let msgTableTitleKvg = i18next.t('ProductBasketPage.tableTitleKvg');
    let msgTableTitleVvg = i18next.t('ProductBasketPage.tableTitleVvg');

    let okpProducts = [];
    let changedOkpProducts = [];
    let vvgProducts = [];
    let changedVvgProducts = [];

    if (isInternalFlow) {
        msgTableTitleKvg = i18next.t('ProductBasketPage.tableTitleKvgCurrent');
        msgTableTitleVvg = i18next.t('ProductBasketPage.tableTitleVvgCurrent');
        let effectiveDateOkp;

        basket.okp.forEach(okpItem => {
            if (currentProducts?.find(p => p.tariffId === okpItem.tariff)) {
                okpProducts.push(okpItem);
            } else {
                effectiveDateOkp = effectiveDates.find(d => d.newObjectId === okpItem.tariff);

                const formattedEffectiveDate =
                    effectiveDateOkp && convertDateToPresentationFormat(effectiveDateOkp.validFrom);
                changedOkpProducts.push({
                    ...okpItem,
                    effectiveDateTitle: i18next.t('ProductBasketPage.tableTitleKvgNew', {
                        effectiveDate: formattedEffectiveDate,
                    }),
                });
            }
        });

        basket.vvg.forEach(vvgItem => {
            if (currentProducts?.find(p => p.tariffId === vvgItem.tariff)) {
                vvgProducts.push(vvgItem);
            } else {
                const effectiveDateVvg = effectiveDates.find(d => d.newObjectId === vvgItem.tariff);
                const effectiveDate =
                    moment(effectiveDateOkp?.validFrom).toDate() > moment(effectiveDateVvg?.validFrom).toDate()
                        ? effectiveDateOkp
                        : effectiveDateVvg;
                const formattedEffectiveDate =
                    effectiveDate && convertDateToPresentationFormat(effectiveDate.validFrom);

                changedVvgProducts.push({
                    ...vvgItem,
                    effectiveDateTitle: i18next.t('ProductBasketPage.tableTitleVvgNew', {
                        effectiveDate: formattedEffectiveDate,
                    }),
                });
            }
        });
    } else {
        okpProducts = basket.okp;
        vvgProducts = basket.vvg;
    }

    return (
        <div className={classes}>
            {header && (
                <>
                    <StyledH1>
                        {msgPageTitle} {productUser.firstname} {productUser.lastname}
                    </StyledH1>
                    <Spacer space="regular" />
                </>
            )}
            <ProductBasketHeader productUser={productUser} submissionId={submissionId} showEditProducts={header} />
            <Spacer space="tera" />
            <Stack space="mega">
                {changedOkpProducts.map(okpItem => (
                    <TableList key={okpItem.tariff} title={okpItem.effectiveDateTitle} rows={[okpItem]} />
                ))}
                {okpProducts.length > 0 && <TableList title={msgTableTitleKvg} rows={okpProducts} />}
                {changedVvgProducts.map(vvgItem => (
                    <TableList
                        key={vvgItem.tariff}
                        title={vvgItem.effectiveDateTitle}
                        rows={[vvgItem]}
                        mode={'additional'}
                    />
                ))}
                {vvgProducts.length > 0 && (
                    <TableList title={msgTableTitleVvg} rows={vvgProducts} mode={'additional'} />
                )}
            </Stack>
            <Hidden above="medium">
                <Spacer space="epsilon" />
            </Hidden>
            <Hidden below="medium">
                <Spacer space="iota" />
            </Hidden>
            <StyledTotalBar>
                <P3>
                    {msgTotal} {firstName} {lastName}
                </P3>
                <StyledTotalPrice>{totalPrice}</StyledTotalPrice>
            </StyledTotalBar>
            {footer ? (
                <>
                    <Spacer space="kilo" />
                    <Box textAlign="right">
                        <NamedLink name="EditSubmissionPage" params={{ tab: 'cockpit' }}>
                            <Button buttonText={msgBackButton} outlined variant="primary" />
                        </NamedLink>
                    </Box>
                </>
            ) : null}
        </div>
    );
};

ProductBasketPanel.propTypes = {
    className: string,
    rootClassName: string,
    basket: object.isRequired,
    productUser: object,
    totalPrice: string.isRequired,
    submissionId: string.isRequired,
    header: bool,
    footer: bool,
    effectiveDates: array,
    currentProducts: array,
};

export default ProductBasketPanel;
