import React from 'react';
import i18next from 'i18next';
import { array, object } from 'prop-types';
import { FormSpy } from 'react-final-form';
import { InlineList } from '@src/components';
import { MultipleGroupContainer, MultipleGroupEnumeration } from './MultipleGroup.style';

const MultipleGroup = ({ answers, values, modalRefs = null }) => {
    const render = () => {
        // get count
        let count = 0;
        answers?.map(
            answer =>
                (count += answer.id in (values?.healthQuestions || []) ? values?.healthQuestions[answer.id]?.length : 0)
        );

        return values?.healthQuestions && count > 0 ? (
            <MultipleGroupContainer>
                <MultipleGroupEnumeration>
                    <span>{count}</span> {i18next.t('InlineList.enumeration')}
                </MultipleGroupEnumeration>
                {answers.map(answer => (answer.id in values.healthQuestions ? renderInlineList(answer) : null))}
            </MultipleGroupContainer>
        ) : null;
    };

    const renderInlineList = answer => {
        return (
            <InlineList
                key={'MulipleGroupElement_' + answer.id}
                item={answer}
                value={values.healthQuestions[answer.id]}
                renderOnlyRow={true}
                onDeleteClick={index => {
                    const modalRefIndex = modalRefs?.current.findIndex(reference => reference.current.id == answer.id);
                    const modalRef = modalRefs?.current?.[modalRefIndex];

                    if (modalRef?.current) {
                        modalRef.current.delete(index);
                    }
                }}
                onEditClick={index => {
                    const modalRefIndex = modalRefs?.current.findIndex(reference => reference.current.id == answer.id);
                    const modalRef = modalRefs?.current?.[modalRefIndex];

                    if (modalRef?.current) {
                        modalRef.current.edit(index);
                    }
                }}
            />
        );
    };

    return render();
};

MultipleGroup.propTypes = {
    answers: array,
    values: object,
    modalRefs: object,
};

export default function multipleGroup(props) {
    return <FormSpy {...props} subscription={{ values: true }} component={MultipleGroup} />;
}
