export const subUnitDivisors = {
    CHF: 100,
    EUR: 100,
    USD: 100,
};

// https://github.com/yahoo/react-intl/wiki/API#formatnumber
export const currencyConfiguration = currency => {
    if (!subUnitDivisors[currency]) {
        throw new Error(`Configuration missing for currency: ${currency}.`);
    }

    return subUnitDivisors[currency] === 1
        ? {
              style: 'currency',
              currency,
              currencyDisplay: 'symbol',
              useGrouping: true,
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
          }
        : {
              style: 'currency',
              currency,
              currencyDisplay: 'symbol',
              useGrouping: true,
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
          };
};
