import '@src/styles/main.scss';

import React, { createContext, useEffect, Suspense, useMemo, useState } from 'react';
import { Provider } from 'react-redux';
import configureStore from '@src/store';
import { gtmParams } from './config/analytics';
import { PersistGate } from 'redux-persist/integration/react';
import { GTMProvider } from '@elgorditosalsero/react-gtm-hook';
import { changeLanguage } from './config/i18n';
import { LoadingIndicator } from '@src/components';
import { useI18nReady } from '@src/hooks/useI18nReady';
import { bool, node, string } from 'prop-types';
import { ErrorBoundary } from 'react-error-boundary';
import { log } from '@src/middleware';

export const ConfigContext = createContext({});

const PremiumcalculatorLibraryProvider = ({
    children,
    language = 'de',
    inverted = false,
    prBaseUrl = null,
    apiBaseUrl = null,
    esbBaseUrl = null,
}) => {
    const preloadedState = window.__PRELOADED_STATE__ || '{}';
    const initialState = JSON.parse(preloadedState);
    const storage = configureStore(initialState);
    const [isLanguageLoaded, setIsLanguageLoaded] = useState(false);
    const isI18nReady = useI18nReady();
    const libraryConfig = useMemo(
        () => ({
            prBaseUrl,
            apiBaseUrl,
            esbBaseUrl,
            inverted,
        }),
        [prBaseUrl, apiBaseUrl, esbBaseUrl, inverted]
    );

    useEffect(() => {
        log.info('useEffect: check i18n', { isI18nReady, language });
        if (isI18nReady) {
            log.info('i18n ready. set language', { language });
            changeLanguage(language, () => {
                log.info('i18n language changed. set isLanguageLoaded', { language });
                setIsLanguageLoaded(true);
            });
        }
    }, [language, isI18nReady]);

    if (!isI18nReady || !isLanguageLoaded) {
        log.info('waiting for i18n to be ready', { isI18nReady, isLanguageLoaded });
        return <LoadingIndicator />;
    }

    log.info('i18n ready. Render content');
    return (
        <ErrorBoundary
            onError={(error, info) => {
                log.error('Suspense error', error, info);
            }}
        >
            <Suspense fallback={<LoadingIndicator />}>
                <GTMProvider state={gtmParams}>
                    <Provider store={storage.store}>
                        <PersistGate loading={null} persistor={storage.persistor}>
                            <ConfigContext.Provider value={libraryConfig}>{children}</ConfigContext.Provider>
                        </PersistGate>
                    </Provider>
                </GTMProvider>
            </Suspense>
        </ErrorBoundary>
    );
};

PremiumcalculatorLibraryProvider.propTypes = {
    children: node.isRequired,
    language: string,
    inverted: bool,
    prBaseUrl: string,
    apiBaseUrl: string,
    esbBaseUrl: string,
};

export default PremiumcalculatorLibraryProvider;
