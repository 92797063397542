import styled from '@emotion/styled';
import { rem } from 'polished';

import { mixins, spaces } from 'atupri-component-library/lib/core/styles/theme/default';
import { Box, GridItem } from 'atupri-component-library/lib/helpers';
import { P1 } from 'atupri-component-library/lib/primitives';

export const StyledBox = styled(Box)`
    ${mixins.responsiveStyles({
        padding: { zero: spaces.gamma, medium: spaces.theta },
    })};
`;

export const StyledGridItem = styled(GridItem)(({ gutters, verticalGutter = 1, shrink = 0 }) => {
    let styles = [];
    let halfGutters = {};

    const shrinkStyles = 'flex: 0 0 auto!important';

    for (const [key, value] of Object.entries(gutters)) {
        halfGutters[key] = parseInt(value) / 2;
    }
    const verticalGutterStyles = `
    ${mixins.responsiveStyles({
        'margin-top': halfGutters,
        'margin-bottom': halfGutters,
    })};
  `;

    if (verticalGutter) {
        styles.push(verticalGutterStyles);
    }

    if (shrink) {
        styles.push(shrinkStyles);
    }

    return styles;
});

export const StyledTooltipWrapper = styled(P1)`
    > span {
        margin-left: ${rem(spaces.beta)};
    }
`;

export const StyledCtaBar = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    ${mixins.responsiveStyles({
        'flex-direction': {
            zero: 'column-reverse',
            medium: 'row',
        },
        'margin-bottom': {
            zero: spaces.gamma * -1,
            medium: 0,
        },
    })}
`;

export const StyledCtaBarCol = styled.div`
    ${mixins.responsiveStyles({
        'margin-bottom': {
            zero: spaces.gamma,
            small: spaces.epsilon,
        },
    })}
`;

export const StyledGenderFieldWrap = styled.div`
    min-width: ${rem(205)};

    > div {
        width: 100%;
    }
`;
