import React from 'react';
import i18next from 'i18next';
import { array, bool, func, number, string } from 'prop-types';
import classNames from 'classnames';
import { Grid, GridItem } from 'atupri-component-library/lib/helpers';
import { ExternalLink } from '@src/components';

// util
import { getLocalizedStaticUrl } from '@src/util/staticUrl';
import { sanitizeDOM } from '@src/util/sanitize';

import {
    StyledH1,
    StyledContainer,
    StyledContainerAside,
    StyledOrderedListbox,
    StyledOrderedList,
    StyledP1,
    StyledAddress,
    StyledContent,
    StyledListboxTitle,
    StyledButton,
    StyledList,
} from './ConfirmationPanel.style';

function MessageFinishDownloadAnalog(props) {
    const { downloadPdf } = props;
    const analogFinishContractTitle = i18next.t('ConfirmationPage.analog.finishContractTitle');
    const analogFinishContractDownloadPdfButton = i18next.t('ConfirmationPage.analog.finishContractDownloadPdfButton');

    const analogFinishContractText2 = i18next.t('ConfirmationPage.analog.finishContractText2');
    const analogFinishContractAddressField1 = i18next.t('ConfirmationPage.analog.finishContractAddressField1');
    const analogFinishContractAddressField2 = i18next.t('ConfirmationPage.analog.finishContractAddressField2');
    const analogFinishContractAddressField3 = i18next.t('ConfirmationPage.analog.finishContractAddressField3');

    const analogFinishContractAddressField4 = i18next.t('ConfirmationPage.analog.finishContractAddressField4');
    const analogFinishContractAddressField5 = i18next.t('ConfirmationPage.analog.finishContractAddressField5');
    const analogFinishContractDownloadText = i18next.t('ConfirmationPage.analog.finishContractDownloadText');
    const analogFinishContractDownloadAvbText = i18next.t('ConfirmationPage.analog.finishContractDownloadAvbText');
    const analogFinishContractDownloadAvbLink = i18next.t('ConfirmationPage.analog.finishContractDownloadAvbLink');
    const analogFinishContractDownloadProductinfoText = i18next.t(
        'ConfirmationPage.analog.finishContractDownloadProductinfoText'
    );
    const analogFinishContractDownloadProductinfoLink = i18next.t(
        'ConfirmationPage.analog.finishContractDownloadProductinfoLink'
    );
    const atupriHomepageButtonText = i18next.t('ConfirmationPage.atupriHomepageButtonText');

    return (
        <>
            <StyledH1>{analogFinishContractTitle}</StyledH1>
            <StyledContent>
                <StyledButton
                    buttonText={analogFinishContractDownloadPdfButton}
                    variant="primary"
                    iconBefore="document"
                    onClick={downloadPdf}
                    tracking={{
                        category: 'download',
                        action: 'pdf',
                        label: 'versicherungsantrag',
                    }}
                />
                <StyledP1>{analogFinishContractText2}</StyledP1>
                <StyledAddress bold>
                    {analogFinishContractAddressField1}
                    <br />
                    {analogFinishContractAddressField2}
                    <br />
                    {analogFinishContractAddressField3}
                    <br />
                    {analogFinishContractAddressField4}&nbsp;{analogFinishContractAddressField5}
                </StyledAddress>
                <StyledP1>{analogFinishContractDownloadText}</StyledP1>
                <StyledList>
                    <li>
                        <ExternalLink href={analogFinishContractDownloadAvbLink} className={'meta-link'}>
                            {analogFinishContractDownloadAvbText}
                        </ExternalLink>
                    </li>
                    <li>
                        <ExternalLink href={analogFinishContractDownloadProductinfoLink} className={'meta-link'}>
                            {analogFinishContractDownloadProductinfoText}
                        </ExternalLink>
                    </li>
                </StyledList>
                <StyledButton
                    buttonText={atupriHomepageButtonText}
                    outlined={true}
                    link={{
                        href: getLocalizedStaticUrl('atupriPage'),
                        title: atupriHomepageButtonText,
                        target: '_blank',
                    }}
                />
            </StyledContent>
        </>
    );
}

MessageFinishDownloadAnalog.propTypes = {
    downloadPdf: func,
};

function MessageFinishDownloadDigital(props) {
    const { downloadPdf, isInternal } = props;

    const kvgFinishContractTitle = i18next.t(
        isInternal ? 'ConfirmationPage.kvg.internal.finishContractTitle' : 'ConfirmationPage.kvg.finishContractTitle'
    );
    const kvgFinishContractText1 = i18next.t(
        isInternal ? 'ConfirmationPage.kvg.internal.finishContractText1' : 'ConfirmationPage.kvg.finishContractText1'
    );
    const kvgFinishContractDownloadPdfButton = i18next.t('ConfirmationPage.kvg.finishContractDownloadPdfButton');
    const kvgFinishContractDownloadText = i18next.t('ConfirmationPage.kvg.finishContractDownloadText');
    const kvgFinishContractDownloadAvbText = i18next.t('ConfirmationPage.kvg.finishContractDownloadAvbText');
    const kvgFinishContractDownloadAvbLink = i18next.t('ConfirmationPage.kvg.finishContractDownloadAvbLink');
    const kvgFinishContractDownloadProductinfoText = i18next.t(
        'ConfirmationPage.kvg.finishContractDownloadProductinfoText'
    );
    const kvgFinishContractDownloadProductinfoLink = i18next.t(
        'ConfirmationPage.kvg.finishContractDownloadProductinfoLink'
    );
    const atupriHomepageButtonText = i18next.t(
        isInternal ? 'ConfirmationPage.atupriPortalButtonText' : 'ConfirmationPage.atupriHomepageButtonText'
    );

    return (
        <>
            <StyledH1>{kvgFinishContractTitle}</StyledH1>
            <StyledContent>
                <StyledP1 dangerouslySetInnerHTML={{ __html: sanitizeDOM(kvgFinishContractText1) }} />
                {!isInternal && (
                    <>
                        <StyledButton
                            buttonText={kvgFinishContractDownloadPdfButton}
                            variant="primary"
                            iconBefore="document"
                            onClick={downloadPdf}
                            tracking={{
                                category: 'download',
                                action: 'pdf',
                                label: 'versicherungsantrag',
                            }}
                        />
                        <StyledP1>{kvgFinishContractDownloadText}</StyledP1>
                        <StyledList>
                            <li>
                                <ExternalLink href={kvgFinishContractDownloadAvbLink} className={'meta-link'}>
                                    {kvgFinishContractDownloadAvbText}
                                </ExternalLink>
                            </li>
                            <li>
                                <ExternalLink href={kvgFinishContractDownloadProductinfoLink} className={'meta-link'}>
                                    {kvgFinishContractDownloadProductinfoText}
                                </ExternalLink>
                            </li>
                        </StyledList>
                    </>
                )}
                <StyledButton
                    buttonText={atupriHomepageButtonText}
                    outlined={true}
                    link={{
                        href: isInternal ? '/' : getLocalizedStaticUrl('atupriPage'),
                        title: atupriHomepageButtonText,
                        target: isInternal ? '' : '_blank',
                    }}
                />
            </StyledContent>
        </>
    );
}
MessageFinishDownloadDigital.propTypes = {
    downloadPdf: func,
    isInternal: bool,
};

function InfoBox(props) {
    const { title, list } = props;

    return (
        <StyledOrderedListbox>
            <StyledListboxTitle>{title}</StyledListboxTitle>
            <StyledOrderedList>
                {list.map(item => {
                    return (
                        <li key={item.key}>
                            <span dangerouslySetInnerHTML={{ __html: sanitizeDOM(item) }}></span>
                        </li>
                    );
                })}
            </StyledOrderedList>
        </StyledOrderedListbox>
    );
}

const ConfirmationPanel = props => {
    const { className, rootClassName, variant, steps, steptitle, downloadPdf, isInternal } = props;

    const classes = classNames(rootClassName || 'panel--confirmation', className);
    let messagePanel;

    switch (variant) {
        case 1:
            messagePanel = <MessageFinishDownloadAnalog downloadPdf={downloadPdf} />;
            break;
        case 2:
            messagePanel = <MessageFinishDownloadDigital downloadPdf={downloadPdf} isInternal={isInternal} />;
            break;
    }

    return (
        <Grid>
            <GridItem size={{ zero: 'full', medium: 6, large: 8 }}>
                <StyledContainer className={classes}>{messagePanel}</StyledContainer>
            </GridItem>
            <GridItem size={{ small: 5, medium: 6, large: 4 }}>
                {!isInternal && steps ? (
                    <StyledContainerAside className={classes}>
                        <InfoBox title={steptitle} list={steps} />
                    </StyledContainerAside>
                ) : null}
            </GridItem>
        </Grid>
    );
};

ConfirmationPanel.defaultProps = {
    className: null,
    rootClassName: null,
    variant: 1,
    steps: null,
};

ConfirmationPanel.propTypes = {
    className: string,
    rootClassName: string,
    variant: number,
    steps: array,
    downloadPdf: func.isRequired,
    isInternal: bool.isRequired,
};

export default ConfirmationPanel;
