import { combineReducers } from 'redux';
import * as globalReducers from './ducks';
import * as pageReducers from './containers/reducers';

const RESET_STATE = 'RESET_STATE';

const appReducer = combineReducers({ ...globalReducers, ...pageReducers });

const createReducer = () => {
    return (state, action) => {
        let appState = state;

        if (action.type === RESET_STATE) {
            appState = action.payload;
        }

        return appReducer(appState, action);
    };
};

export const resetStoreState = () => ({
    type: RESET_STATE,
    payload: undefined,
});

export default createReducer;
