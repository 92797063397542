import React, { useEffect, useState } from 'react';
import log from 'loglevel';
import { Field } from 'react-final-form';
import i18next from 'i18next';
import { array, object } from 'prop-types';
import moment from 'moment';

// components
import { FieldInput, FieldSelect } from '@src/components';
import { Grid, GridItem, Spacer, Stack } from 'atupri-component-library/lib/helpers';
import { P3, EmphasizedToggle, Icon, Input, Radio, Link } from 'atupri-component-library/lib/primitives';

// data
import countriesDE from '@src/data/countries/de.json';
import countriesFR from '@src/data/countries/fr.json';
import countriesIT from '@src/data/countries/it.json';
import { permitTypes } from '@src/data';

// style
import { StyledBox, StyledGrid, StyledGridItem } from './PersonInfoForm.style';

// util
import { getGenderIconName, isNewbornWithoutPreviousInsurer, GENDER_UNBORN, NATIONALITY_CH } from '@src/util/person';
import { convertDateToPresentationFormat, finalFormFieldDateFormatAttributes } from '@src/util/date';
import { isOkpProcess } from '@src/util/process';
import { pathByRouteName } from '@src/util/routes';
import { composeValidators, maxDate, maxLength, minDate, onlyLetters, required, validDate } from '@src/util/validators';

const NAME_MAX_LENGTH = 20;
const prefix = 'person';

const PersonInfoForm = props => {
    const { formRenderProps, basket, insurerList } = props;

    const { currentPerson } = formRenderProps;

    const lang = i18next.language;
    const [countries, setCountries] = useState([]);

    useEffect(() => {
        switch (lang) {
            case 'de':
                setCountries(countriesDE);
                break;
            case 'fr':
                setCountries(countriesFR);
                break;
            case 'it':
                setCountries(countriesIT);
                break;
            default:
                log.error(`The language "${lang}" is not supported.`);
                break;
        }
    }, [lang]);

    return (
        <Stack space="giga">
            <StyledBox borderRadius={8} shadow="ctaBlueHover">
                <Grid columns={{ zero: 1, wide: 3 }}>
                    <GridItem size={{ zero: 'full', large: 2 }}>
                        <StyledGrid columns={{ zero: 1, medium: 2 }}>
                            <StyledGridItem size="full">
                                <EmphasizedToggle
                                    controlType="radio"
                                    icon={getGenderIconName(currentPerson.gender)}
                                    value={currentPerson.gender}
                                    selectedValue={currentPerson.gender}
                                    disabled
                                >
                                    {i18next.t(`PersonForm.genderOptions.${currentPerson.gender}`)}
                                </EmphasizedToggle>
                            </StyledGridItem>
                            <StyledGridItem size={1}>
                                <FieldInput
                                    id="personInfoFormFirstname"
                                    name={`${prefix}.firstname`}
                                    label={i18next.t('PersonForm.firstname')}
                                    validate={composeValidators(
                                        required(i18next.t('PersonForm.firstnameRequired')),
                                        maxLength(
                                            i18next.t('PersonForm.maxLength', {
                                                maxLength: NAME_MAX_LENGTH,
                                            }),
                                            NAME_MAX_LENGTH
                                        ),
                                        onlyLetters(i18next.t('PersonForm.onlyLetters'))
                                    )}
                                />
                            </StyledGridItem>
                            <StyledGridItem size={1}>
                                <FieldInput
                                    id="personInfoFormLastname"
                                    name={`${prefix}.lastname`}
                                    label={i18next.t('PersonForm.lastname')}
                                    validate={composeValidators(
                                        required(i18next.t('PersonForm.lastnameRequired')),
                                        maxLength(
                                            i18next.t('PersonForm.maxLength', {
                                                maxLength: NAME_MAX_LENGTH,
                                            }),
                                            NAME_MAX_LENGTH
                                        ),
                                        onlyLetters(i18next.t('PersonForm.onlyLetters'))
                                    )}
                                />
                            </StyledGridItem>
                            <StyledGridItem size={1}>
                                <Input
                                    id="personInfoFormZip"
                                    name="person.zipText"
                                    value={currentPerson.zipText}
                                    label={i18next.t('PersonForm.zip')}
                                    inputRightIcon={<Icon iconName="lock" />}
                                    isRequired
                                    disabled
                                />
                            </StyledGridItem>
                            <StyledGridItem size={1}>
                                <Input
                                    id="personInfoFormBirthdate"
                                    name="person.birthdate"
                                    value={convertDateToPresentationFormat(currentPerson.birthdate)}
                                    label={i18next.t('PersonForm.birthdate')}
                                    inputRightIcon={<Icon iconName="lock" />}
                                    isRequired
                                    disabled
                                />
                            </StyledGridItem>
                            <StyledGridItem size={1}>
                                <Input
                                    id="personInfoFormInsuranceStart"
                                    name={`${prefix}.insuranceStart`}
                                    label={i18next.t('PersonForm.insuranceStart')}
                                    value={convertDateToPresentationFormat(currentPerson.insuranceStart)}
                                    inputRightIcon={<Icon iconName="lock" />}
                                    isRequired
                                    disabled
                                />
                            </StyledGridItem>
                        </StyledGrid>
                    </GridItem>
                </Grid>
                <Spacer space="mega" />
                <Link
                    linkText={i18next.t('CockpitItem.msgLinkEditProfile')}
                    link={{
                        to: pathByRouteName('PersonPage'),
                    }}
                    iconBefore="link_arrow"
                    color="primary"
                />
            </StyledBox>

            {insurerList.length > 0 && (
                <StyledBox borderRadius={8} shadow="ctaBlueHover">
                    <Grid columns={{ zero: 1, wide: 3 }}>
                        <GridItem size={{ zero: 'full', medium: 2 }}>
                            <StyledGrid columns={{ zero: 1, medium: 2 }}>
                                {currentPerson.gender !== GENDER_UNBORN && (
                                    <StyledGridItem size="full">
                                        <P3>{i18next.t('PersonInfoForm.moving')}</P3>
                                        <Spacer space="delta" />
                                        <Stack direction="row">
                                            <Field name={`${prefix}.moved`}>
                                                {props => (
                                                    <Radio
                                                        value="yes"
                                                        name={props.input.name}
                                                        selectedValue={props.input.value}
                                                        onChange={event => props.input.onChange(event.target.value)}
                                                    >
                                                        {i18next.t('Form.yes')}
                                                    </Radio>
                                                )}
                                            </Field>
                                            <Field name={`${prefix}.moved`}>
                                                {props => (
                                                    <Radio
                                                        value="no"
                                                        name={props.input.name}
                                                        selectedValue={props.input.value}
                                                        onChange={event => props.input.onChange(event.target.value)}
                                                        checked
                                                    >
                                                        {i18next.t('Form.no')}
                                                    </Radio>
                                                )}
                                            </Field>
                                        </Stack>
                                    </StyledGridItem>
                                )}
                                <Field
                                    name={`${prefix}.moved`}
                                    subscription={{ value: true }}
                                    render={({ input: { value } }) =>
                                        value === 'yes' && (
                                            <StyledGridItem size={6}>
                                                <FieldInput
                                                    id="personInfoFormDateOfEntry"
                                                    name={`${prefix}.dateOfEntry`}
                                                    label={i18next.t('PersonInfoForm.dateOfEntry')}
                                                    validate={composeValidators(
                                                        required(i18next.t('PersonInfoForm.dateOfEntryRequired')),
                                                        validDate(i18next.t('PersonInfoForm.dateOfEntryRequired')),
                                                        minDate(
                                                            i18next.t('PersonInfoForm.dateOfEntryTooSmall'),
                                                            moment().subtract(3, 'months')
                                                        ),
                                                        maxDate(
                                                            i18next.t('PersonInfoForm.dateOfEntryTooBig'),
                                                            moment().add(1, 'years').endOf('year')
                                                        )
                                                    )}
                                                    mask="99.99.9999"
                                                    {...finalFormFieldDateFormatAttributes}
                                                />
                                            </StyledGridItem>
                                        )
                                    }
                                />
                                <Field
                                    name={`${prefix}.moved`}
                                    subscription={{ value: true }}
                                    render={({ input: { value } }) => {
                                        return (
                                            value === 'no' &&
                                            currentPerson.gender !== GENDER_UNBORN &&
                                            !isNewbornWithoutPreviousInsurer(currentPerson) &&
                                            isOkpProcess(basket, currentPerson.id) && (
                                                <StyledGridItem size={6}>
                                                    <FieldSelect
                                                        id="personInfoFormPreviousInsurer"
                                                        name={`${prefix}.previousInsurer`}
                                                        label={i18next.t('PersonInfoForm.previousInsurer')}
                                                        options={insurerList}
                                                        validate={composeValidators(
                                                            required(
                                                                i18next.t('PersonInfoForm.previousInsurerRequired')
                                                            )
                                                        )}
                                                        castValueToNumber={true}
                                                    />
                                                </StyledGridItem>
                                            )
                                        );
                                    }}
                                />
                            </StyledGrid>
                            <Spacer space="regular" />
                            <StyledGrid columns={{ zero: 1, medium: 2 }}>
                                <StyledGridItem size={1}>
                                    <FieldSelect
                                        id="personInfoFormNationality"
                                        name={`${prefix}.nationality`}
                                        label={i18next.t('PersonInfoForm.nationality')}
                                        options={countries}
                                        validate={composeValidators(
                                            required(i18next.t('PersonInfoForm.nationalityRequired'))
                                        )}
                                    />
                                </StyledGridItem>
                                <Field name={`${prefix}.nationality`} subscription={{ value: true }}>
                                    {({ input: { value } }) =>
                                        value &&
                                        value !== NATIONALITY_CH && (
                                            <StyledGridItem size={6}>
                                                <FieldSelect
                                                    id="personInfoFormPermitType"
                                                    name={`${prefix}.permitType`}
                                                    label={i18next.t('PersonInfoForm.permitType')}
                                                    options={permitTypes()}
                                                    validate={composeValidators(
                                                        required(i18next.t('PersonInfoForm.permitTypeRequired'))
                                                    )}
                                                />
                                            </StyledGridItem>
                                        )
                                    }
                                </Field>
                            </StyledGrid>
                        </GridItem>
                    </Grid>
                </StyledBox>
            )}
        </Stack>
    );
};

PersonInfoForm.propTypes = {
    formRenderProps: object,
    basket: object,
    insurerList: array,
};

export default PersonInfoForm;
