import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { FormSpy } from 'react-final-form';
import diff from 'object-diff';

function AutoSave({ values, onSave, isFormValid, debounce = 1000 }) {
    const [timeoutRef, setTimeoutRef] = useState(null);
    const [initialValues, setInitialValues] = useState(values);

    const save = useCallback(async () => {
        // Check to see if the values have changed.
        const difference = diff(initialValues, values);
        if (Object.keys(difference).length) {
            // Values have changed.
            setInitialValues(values);
            const saving = onSave(values, isFormValid);
            await saving;
        }
    }, [onSave, values, initialValues, isFormValid]);

    useEffect(() => {
        if (timeoutRef) {
            clearTimeout(timeoutRef);
        }
        const newTimeoutRef = setTimeout(() => {
            save();
        }, debounce);
        setTimeoutRef(newTimeoutRef);

        return () => clearTimeout(newTimeoutRef);
    }, [debounce, values, save]);

    // This component doesn't have to render anything
    return null;
}

AutoSave.propTypes = {
    values: PropTypes.object.isRequired,
    onSave: PropTypes.func.isRequired,
    isFormValid: PropTypes.bool,
    debounce: PropTypes.number,
};

export default function autoSave(props) {
    return (
        <FormSpy subscription={{ values: true }}>
            {({ values }) => (
                <AutoSave
                    values={values}
                    onSave={props.onSave}
                    isFormValid={props.isFormValid}
                    debounce={props.debounce}
                />
            )}
        </FormSpy>
    );
}
