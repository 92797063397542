import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { rem } from 'polished';
import { colors, spaces, mixins } from 'atupri-component-library/lib/core/styles/theme/default';

export const ModalStyles = () => css`
    .modal__modal--custom {
        max-width: ${rem(960)};

        ${mixins.responsiveStyles({
            padding: {
                zero: `${rem(48)} ${rem(16)} ${rem(32)} ${rem(16)}`,
                medium: `${rem(32)} ${rem(32)} ${rem(40)} ${rem(32)}`,
            },
        })}
    }
    .modal__content--custom {
        font-weight: 600;
    }
`;

export const StyledCtaBar = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    ${mixins.responsiveStyles({
        'flex-direction': {
            zero: `column`,
            medium: `row`,
        },
        'padding-top': {
            zero: `${rem(32)}`,
            medium: `${rem(60)}`,
            wide: `${rem(60)}`,
        },
        'margin-bottom': {
            zero: `${rem(spaces.gamma * -1)}`,
            medium: `${rem(spaces.epsilon * -1)}`,
        },
    })}
`;

export const StyledCtaBarCol = styled.div`
    color: ${colors.primary};

    ${mixins.responsiveStyles({
        margin: {
            zero: `0 ${rem(spaces.gamma)} ${rem(spaces.gamma)} 0`,
            medium: `0 ${rem(spaces.epsilon)} ${rem(spaces.epsilon)} 0`,
        },
    })}

    &:last-of-type {
        margin-right: 0;
    }
`;
