import axios from 'axios';
import i18next from 'i18next';
import moment from 'moment';
import config from '@src/config';
import { log } from '@src/middleware';
import { environmentConfiguration } from '@src/config/env';
import { insurerList as mockInsurerList } from '@src/data';

const INSURER_LIST_API = `${config.apiEsbBase}/insurers/v1`;

const { dev } = environmentConfiguration;

/**
 * @param string zip
 */
export const API_GET_INSURER_LIST = (language = i18next.language, date = moment().format('YYYY/MM/DD')) =>
    new Promise((resolve, reject) => {
        log.info('=== Zip:API_GET_INSURER_LIST ===');
        let langParam;

        switch (language) {
            case 'de':
                langParam = 'D';
                break;
            case 'fr':
                langParam = 'F';
                break;
            case 'it':
                langParam = 'I';
                break;
            default:
                log.error(`The language "${language}" is not supported by INSURER_LIST_API`);
                break;
        }

        const url = `${INSURER_LIST_API}?language=${langParam}&date=${date}`;

        let res = {
            status: 200,
            statusText: '',
            data: null,
        };
        let resObj = JSON.parse(JSON.stringify(res));

        axios
            .get(url, {
                timeout: 60000,
            })
            .then(response => {
                const data = response?.data;
                if (typeof data !== 'string') {
                    resObj.data = data;
                    resolve(resObj);
                } else {
                    log.error('Error getting state from API: ', data);

                    // temporary fallback for development
                    if (dev) {
                        log.info('InsurerList API error or timeout. Using fallback json file');
                        resObj.data = mockInsurerList;
                        resolve(resObj);
                    } else {
                        reject({ data: 'Error.code', code: '70' });
                    }
                }
            })
            .catch(err => {
                log.error({ code: err.code, error: err.message });

                // temporary fallback for development
                if (dev) {
                    log.info('InsurerList API error or timeout. Using fallback json file');
                    resObj.data = mockInsurerList;
                    resolve(resObj);
                } else {
                    reject({ data: 'Error.API', code: '71' });
                }
            });
    });
