export const mappingData = {
    okp: {
        variants: {
            accidentIncluded: '6',
            accidentNotIncluded: '5',
        },
    },
    vvg: {},
};

export const staticData = {
    vvg: {
        sections: [
            {
                id: 'withMivita',
                productsOfGroup: 2,
                productGroups: [3, 4, 5],
            },
            {
                id: 'withoutMivita',
                productGroups: [6, 5],
            },
        ],
    },
};

export const utiAddOnInitialValues = {
    vvg: {
        enabled: true,
        section: 'withoutMivita',
        productGroups: {
            withoutMivita_5: false,
        },
    },
};

export const utiAddOnFieldNames = {
    productGroup: 'vvg.productGroups.withoutMivita_5',
    product: 'vvg.products.withoutMivita_5',
    productName: 'withoutMivita_5',
};
