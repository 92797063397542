import moment from 'moment';

export default {
    offerte: {
        key: 3699900001,
        //offerid: 9581997,

        erstellt: moment().format(),
        pdf_response: 1,
        questions_response: 0,
        promotion: 'Praemienrechner',
        source: 'U',
        personalcode: '',
        sprache: 'D',
        zusatz: '',
        strasse: '@test',
        plz: 4001,
        ort: 'Basel',
        gemeinde: '2701',
        telefon_prv: '1234567890',
        email: 'test@test.com',
        zahlungsauswahl: 'ACCT',
        zahlungsintervall: 1,
        multi_year_contract: false,
        iban: 'CH650020220262553040N',
        portal: false,
        policy_email: false,
        familie: '',
        all_confirmed_cd: false,
        personen: {
            person: [],
        },
    },
    person: [
        {
            correspondence: true,
            anrede: 'Herr',
            vorname: '@test',
            name: '@test',
            geschlecht: 'M',
            geburtsdatum: '1970-01-01',
            tarifbeginn: '2020-01-01',
            nationalitaet: 'CH',
            previous_insurer: '1560',
            produkte: {
                produkt: [
                    {
                        gesetz: 'KVG',
                        tariff_objekt: 'STD_1_19_5_1_1_1_0_0_0_0',
                        zsr: '',
                    },
                ],
            },
        },
    ],
};
