import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useForm, Field } from 'react-final-form';

// components
import { ZipAutocomplete } from '@src/components';

const FieldZipAutocomplete = ({ validate, isRequired = true, variant = 'default', inverted = false, ...rest }) => {
    let firstErrorField = null;
    const { name } = rest;
    const {
        place: validatePlace,
        municipalityId: validateMunicipalityId,
        municipalityInfo: validateMunicipalityInfo,
        zip: validateZip,
        zipText: validateZipText,
    } = validate;

    const [error, setError] = useState(null);

    const form = useForm();

    const onChangeZipAutocomplete = ({ place, municipalityId, municipalityInfo, zip, zipText }) => {
        setError(null);

        form.mutators.setValueArray(`${name}.place`, place);
        form.mutators.setValueArray(`${name}.municipalityId`, municipalityId);
        form.mutators.setValueArray(`${name}.municipalityInfo`, municipalityInfo);
        form.mutators.setValueArray(`${name}.zip`, zip);
        form.mutators.setValueArray(`${name}.zipText`, zipText);
    };

    const renderField = fieldProps => {
        if (fieldProps.meta.error && !firstErrorField) {
            firstErrorField = fieldProps.input.name;
        }
        if (fieldProps.meta.touched && fieldProps.meta.error) {
            setError(fieldProps.meta.error);
        }
        return (
            <React.Fragment>
                <input
                    hidden
                    {...fieldProps.input}
                    onBlur={e => {
                        fieldProps.input.onBlur(e);
                    }}
                />
            </React.Fragment>
        );
    };

    return (
        <>
            <Field name={`${name}.zipText`}>
                {fieldProps => (
                    <ZipAutocomplete
                        {...rest}
                        defaultValue={fieldProps.input.value}
                        onChangeValue={onChangeZipAutocomplete}
                        onChangeSelection={onChangeZipAutocomplete}
                        onBlur={() => {
                            // blur zip field to trigger validation
                            setTimeout(() => {
                                form.blur(`${name}.zip`);
                            }, 100);
                        }}
                        isRequired={isRequired}
                        variant={variant}
                        errorText={error}
                        inverted={inverted}
                    />
                )}
            </Field>
            <Field name={`${name}.place`} validate={validatePlace}>
                {fieldProps => renderField(fieldProps)}
            </Field>
            <Field name={`${name}.municipalityId`} validate={validateMunicipalityId}>
                {fieldProps => renderField(fieldProps)}
            </Field>
            <Field name={`${name}.municipalityInfo`} validate={validateMunicipalityInfo}>
                {fieldProps => renderField(fieldProps)}
            </Field>
            <Field name={`${name}.zip`} validate={validateZip}>
                {fieldProps => renderField(fieldProps)}
            </Field>
            <Field name={`${name}.zipText`} validate={validateZipText}>
                {fieldProps => renderField(fieldProps)}
            </Field>
        </>
    );
};

const { bool, string, object } = PropTypes;

FieldZipAutocomplete.propTypes = {
    name: string.isRequired,
    label: string.isRequired,
    disabled: bool,
    validate: object,
    isRequired: bool,
    variant: string,
    inverted: bool,
};

export default FieldZipAutocomplete;
