import i18next from 'i18next';
import { environmentConfiguration } from '@src/config/env';
import { setLanguageParameter, getLocalizedStaticUrl, appendSubmissionIdParameter } from '@src/util/staticUrl';

const { dev } = environmentConfiguration;
let frontendVersion = '';
if (dev && window?.__ENV__?.PREMCALC_IMAGE_VERSION) {
    frontendVersion = '  v.' + window?.__ENV__?.PREMCALC_IMAGE_VERSION;
}

const footerData = () => ({
    variant: 'flat',
    title: i18next.t('footer.text') + frontendVersion, // Atupri Gesundheitsversicherung
    support: {
        phoneNumber: {
            linkText: i18next.t('footer.navigation.contact.info'), //'0 800 200 888'
            link: {
                href: getLocalizedStaticUrl('infoPhone'),
                title: i18next.t('footer.navigation.contact.info'),
            },
        },
        openingHours: i18next.t('footer.navigation.contact.openingHours'), //'(Mo-Fr, 8-17 Uhr)'
        links: [
            {
                linkText: i18next.t('footer.navigation.contact.email'), //'info@atupri.ch',
                link: {
                    href: getLocalizedStaticUrl('infoMail'),
                    title: i18next.t('footer.navigation.contact.email'),
                },
            },
        ],
    },
    languages: {
        title: i18next.t('footer.langnav.language'), // Sprache
        links: [
            {
                linkText: 'DE',
                link: {
                    href: setLanguageParameter('de', appendSubmissionIdParameter()),
                },
                isActive: i18next.language === 'de',
            },
            {
                linkText: 'FR',
                link: {
                    href: setLanguageParameter('fr', appendSubmissionIdParameter()),
                },
                isActive: i18next.language === 'fr',
            },
            {
                linkText: 'IT',
                link: {
                    href: setLanguageParameter('it', appendSubmissionIdParameter()),
                },
                isActive: i18next.language === 'it',
            },
        ],
    },
    footerLinks: [
        {
            links: [
                {
                    linkText: i18next.t('footer.navigation.legal.label'), //'Rechtliches',
                    link: {
                        href: getLocalizedStaticUrl('footerLegalPage'),
                        title: i18next.t('footer.navigation.legal.label'),
                        target: '_blank',
                    },
                },
            ],
        },
        {
            links: [
                {
                    linkText: i18next.t('footer.navigation.impressum.label'), //'Impressum',
                    link: {
                        href: getLocalizedStaticUrl('footerImpressumPage'),
                        title: i18next.t('footer.navigation.impressum.label'),
                        target: '_blank',
                    },
                },
            ],
        },
    ],
});

export default footerData;
