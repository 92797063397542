import React, { useState } from 'react';
import i18next from 'i18next';
import arrayMutators from 'final-form-arrays';
import { Form, FormSpy } from 'react-final-form';
import createScrollToFirstErrorDecorator from '@src/util/finalForm/decorators/scrollToFirstError';
import { Button } from 'atupri-component-library/lib/primitives';
import { Modal } from 'atupri-component-library/lib/components';
import { ModalStyles, StyledCtaBar, StyledCtaBarCol } from './FormModal.style';

const scrollToFirstErrorDecorator = createScrollToFirstErrorDecorator();

const FormModal = ({
    formKey,
    children,
    modalIsOpen = { modalIsOpen },
    initialValues = {},
    onCancel = () => {
        // This is intentionally empty
    },
    onSave = values => {
        // This is intentionally empty
    },
}) => {
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const onSubmit = values => {
        setButtonDisabled(true);
        onSave(values);
    };

    const saveMsg = i18next.t('FormModal.save');
    const cancelMsg = i18next.t('FormModal.cancel');

    return (
        <Modal customStyles={ModalStyles} isOpen={modalIsOpen} onCloseRequest={onCancel}>
            <Form
                key={formKey}
                mutators={{ ...arrayMutators }}
                decorators={[scrollToFirstErrorDecorator]}
                onSubmit={onSubmit}
                initialValues={initialValues}
                render={({ handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <div>{children}</div>
                        <FormSpy
                            subscription={{ values: true }}
                            onChange={() => {
                                setButtonDisabled(false);
                            }}
                        />
                        <StyledCtaBar>
                            <StyledCtaBarCol>
                                <Button type="submit" disabled={buttonDisabled} buttonText={saveMsg} />
                            </StyledCtaBarCol>
                            <StyledCtaBarCol>
                                <Button type="button" buttonText={cancelMsg} onClick={onCancel} isIncognito />
                            </StyledCtaBarCol>
                        </StyledCtaBar>
                    </form>
                )}
            />
        </Modal>
    );
};

export default FormModal;
