import styled from '@emotion/styled';
import { rem } from 'polished';
import { colors, spaces, mixins } from 'atupri-component-library/lib/core/styles/theme/default';

export const StyledAnswerGroup = styled.div`
    .ANSWERGROUP_ROW:last-of-type {
      .ANSWERGROUP_COL {
        margin-bottom: 0;

        &:last-of-type {
          margin-right: 0;
        }
      }
    }

    &:last-of-type {
      .ANSWERGROUP_ROW:last-of-type {
        .ANSWERGROUP_COL:last-of-type {
          border: none;
        }
      }
    }

    .ANSWERGROUP_COL.TYPE_MULTIPLE + .ANSWERGROUP_COL.TYPE_CHECKBOX {
          position: relative;

          ${mixins.responsiveStyles({
              'padding-top': {
                  zero: `${rem(spaces.delta)}`,
                  medium: `${rem(spaces.theta)}`,
                  wide: `${rem(spaces.theta)}`,
              },
              'margin-top': {
                  zero: `0`,
                  medium: `${rem(spaces.delta)}`,
                  wide: `${rem(spaces.delta)}`,
              },
          })}

          &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            height: 1px;
            width: calc(100% + ${rem(96)});
            background-color: ${colors.grey300};
          }
        }
      }
    }
  `;

export const StyledFormMaskRow = styled.div(({ flex = 1, responsive }) => {
    let styles = [
        `
      display: flex;
      flex-wrap: wrap;
      width: 100%;

      .inputRoot {
        @media screen and (max-width: 900px) {
          flex: 1;
        }
      }
    `,
    ];

    if (responsive) {
        styles.push(`
      ${mixins.responsiveStyles({
          display: { zero: 'flex', medium: 'inline-flex' },
      })}

      flex-direction: column;

      @media screen and (min-width: 600px) {
        flex-direction: row;
      }

      input {
        @media screen and (max-width: 900px) {
          width: 300px;
        }
        @media screen and (max-width: 756px) {
          width: auto;
        }
      }
    `);
    }

    return styles;
});

export const StyledFormMaskCol = styled.div(({ answerType }) => {
    let styles = [
        `
      flex: 0 1 auto;

      ${mixins.responsiveStyles({
          margin: {
              zero: `0 ${rem(spaces.delta)} ${rem(spaces.delta)} 0`,
              medium: `0 ${rem(spaces.epsilon)} ${rem(spaces.epsilon)} 0`,
          },
      })}
    `,
    ];

    switch (answerType) {
        case 'MULTIPLE':
            styles.push(`
        flex: 1 0 100%;
      `);
            break;
        case 'LONGTEXT':
            styles.push(`
          width: 100%;
          max-width: ${rem(742)};
        `);
            break;
        case 'TEXT':
        case 'MEDIUMTEXT':
            styles.push(`
        width: 100%;
        max-width: ${rem(392)};
      `);
            break;
        case 'NUMBER':
        case 'SHORTTEXT':
            styles.push(`
          width: 100%;
          max-width: ${rem(140)};
        `);
            break;
    }

    return styles;
});
