import merge from 'deepmerge';
import { sanitizeEntity } from './sanitize';

/**
 * Combine the given relationships objects http://jsonapi.org/format/#document-resource-object-relationships
 */
export const combinedRelationships = (oldRels, newRels) => {
    if (!oldRels && !newRels) {
        return null;
    }
    return { ...oldRels, ...newRels };
};

/**
 * Combine the given resource objects http://jsonapi.org/format/#document-resource-objects
 */
export const combinedResourceObjects = (oldRes, newRes) => {
    const { id, type } = oldRes;
    if (newRes.id.uuid !== id.uuid || newRes.type !== type) {
        throw new Error('Cannot merge resource objects with different ids or types');
    }
    const attributes = newRes.attributes || oldRes.attributes;
    const attrs = attributes ? { attributes: { ...attributes } } : null;
    const relationships = combinedRelationships(oldRes.relationships, newRes.relationships);
    const rels = relationships ? { relationships } : null;
    return { id, type, ...attrs, ...rels };
};

// combine resource obj from the api response with existing entities
export const updatedEntities = (oldEntities, apiResponse) => {
    const { data, included = [] } = apiResponse;
    const objects = (Array.isArray(data) ? data : [data]).concat(included);

    return objects.reduce((entities, curr) => {
        const { id, type } = curr;

        const current = sanitizeEntity(curr);

        entities[type] = entities[type] || {};
        const entity = entities[type][id.uuid];
        entities[type][id.uuid] = entity ? combinedResourceObjects({ ...entity }, current) : current;

        return entities;
    }, oldEntities);
};

// denormalise  entities with data from entities object (redux)
export const denormalisedEntities = (entities, resources, throwIfNotFound = true) => {
    const denormalised = resources.map(res => {
        const { id, type } = res;
        const entityFound = entities[type] && id && entities[type][id.uuid];
        if (!entityFound) {
            if (throwIfNotFound) {
                throw new Error(`Entity with type "${type}" and id "${id ? id.uuid : id}" not found`);
            }
            return null;
        }

        const entity = entities[type][id.uuid];

        // filter entities data by { something, ...entityData } = entity;
        const { ...entityData } = entity;

        return entityData;
    });
    return denormalised.filter(e => !!e);
};

// denormalise  data from api response
export const denormalisedResponseEntities = endpointResponse => {
    const apiResponse = endpointResponse.data;
    const data = apiResponse.data;
    const resources = Array.isArray(data) ? data : [data];

    if (!data || resources.length === 0) {
        return [];
    }

    const entities = updatedEntities({}, apiResponse);
    return denormalisedEntities(entities, resources);
};

// shell object to ensure attributes exist
export const ensureSubmission = submission => {
    const empty = {
        id: null,
        type: 'submission',
        attributes: { privateData: {} },
        relationships: { persons: [], healthQuestions: {} },
    };
    return { ...empty, ...submission };
};

// shell object to ensure attributes exist
export const ensureOwnSubmission = submission => {
    const empty = {
        id: null,
        type: 'ownSubmission',
        attributes: { privateData: {} },
        relationships: { persons: [], healthQuestions: {} },
    };
    return { ...empty, ...submission };
};

export const deepMerge = arraysToMerge => {
    return merge.all(arraysToMerge.filter(Boolean), {
        arrayMerge: (destinationArray, sourceArray, options) => sourceArray,
    });
};
