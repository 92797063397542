import { rem, rgba } from 'polished';
import styled from '@emotion/styled';
import { mixins, colors, spaces, text, fontWeights } from 'atupri-component-library/lib/core/styles/theme/default';
import { Icon } from 'atupri-component-library/lib/primitives';

export const StyledH2 = styled.h2`
    ${text.p1};
    font-weight: ${fontWeights.regular};
    display: inline-block;
    align-self: center;
    margin-bottom: 0;

    ${mixins.responsiveStyles({
        'padding-left': {
            zero: `${rem(spaces.gamma)}`,
            medium: `${rem(spaces.delta)}`,
        },
    })}
`;

export const StyledSubtitle = styled.div`
    display: flex;
    align-items: flex-start;

    ${mixins.responsiveStyles({
        'margin-bottom': {
            zero: `${rem(spaces.delta)}`,
            medium: `${rem(spaces.zeta)}`,
        },
    })}
`;

export const StyledBlock = styled.div(({ level }) => {
    let styles = [];

    if (level === 1) {
        styles.push(`
        box-shadow: 0 ${rem(spaces.epsilon)} ${rem(spaces.theta)} ${rgba(colors.primary, 0.08)};
        background-color: ${colors.white};
        border-radius: ${rem(spaces.beta)};

        ${mixins.responsiveStyles({
            margin: {
                zero: `0 0 ${rem(spaces.zeta)}`,
                medium: `0 0 ${rem(spaces.kappa)}`,
                wide: `0 0 ${rem(spaces.kappa)}`,
            },
            padding: {
                zero: `${rem(spaces.zeta)} ${rem(spaces.gamma)} ${rem(spaces.zeta)} ${rem(spaces.gamma)}`,
                medium: `${rem(spaces.theta)} ${rem(spaces.theta)} ${rem(spaces.eta)} ${rem(spaces.theta)}`,
                wide: `${rem(spaces.theta)} ${rem(spaces.theta)} ${rem(spaces.eta)} ${rem(spaces.theta)}`,
            },
        })}
      `);
    }
    return styles;
});

export const StyledQuestions = styled.div(({ level }) => {
    let styles = [
        `
    width: 100%;
    position: relative;
  `,
    ];

    if (level === 2) {
        styles.push(`
      &:before {
        content: '';
        position: absolute;
        top: 0;
        display: block;
        width: 100%;
        height: 1px;
        background-color: ${colors.grey300};

        ${mixins.responsiveStyles({
            left: {
                zero: rem(spaces.gamma),
                medium: rem(spaces.theta),
                wide: rem(spaces.theta),
            },
        })}
      }

      ${mixins.responsiveStyles({
          margin: {
              zero: `0 ${rem(spaces.gamma * -1)} 0`,
              medium: `${rem(spaces.eta)} ${rem(spaces.theta * -1)} 0`,
              wide: `${rem(spaces.eta)} ${rem(spaces.theta * -1)} 0`,
          },
          padding: {
              zero: `${rem(spaces.delta)} ${rem(spaces.gamma)} 0`,
              medium: `${rem(spaces.theta)} ${rem(spaces.theta)} 0`,
              wide: `${rem(spaces.theta)} ${rem(spaces.theta)} 0`,
          },
      })}

      .react-responsive-modal-overlay .BLOCK.LEVEL-2 > & {
        &:before {
          display: none;
        }
        margin: 0;
        padding: 0;
        width: 100%;
      }
    `);
    } else if (level === 3) {
        styles.push(`
      border-top: ${rem(1)} solid ${colors.grey300};

      ${mixins.responsiveStyles({
          margin: {
              zero: `0`,
              medium: `${rem(spaces.eta)} 0 0`,
              wide: `${rem(spaces.eta)} 0 0`,
          },
          padding: {
              zero: `${rem(spaces.delta)} 0 0`,
              medium: `${rem(spaces.theta)} 0 0`,
              wide: `${rem(spaces.theta)} 0 0`,
          },
      })}
    `);
    }
    return styles;
});

export const StyledIcon = styled(Icon)`
    color: ${colors.primary};
    font-size: ${rem(spaces.zeta)};
    align-self: center;
`;
