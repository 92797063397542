import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { StyledLoader, StyledLoaderAnimation, StyledLoaderIndicator } from './LoadingIndicator.style';

class LoadingIndicator extends Component {
    constructor(props) {
        super(props);
        this.state = { mounted: false };
    }

    componentDidMount() {
        this.setState({ mounted: true }); // eslint-disable-line react/no-did-mount-set-state
    }

    render() {
        const { show, variant } = this.props;

        return (
            <StyledLoader show={show} variant={variant}>
                <StyledLoaderAnimation>
                    <StyledLoaderIndicator delay={0.39}></StyledLoaderIndicator>
                    <StyledLoaderIndicator delay={0.91}></StyledLoaderIndicator>
                    <StyledLoaderIndicator delay={1.17}></StyledLoaderIndicator>
                </StyledLoaderAnimation>
            </StyledLoader>
        );
    }
}

const { node, string, bool } = PropTypes;

LoadingIndicator.defaultProps = {
    show: false,
    variant: 'default',
};

LoadingIndicator.propTypes = {
    show: bool,
    variant: string,
};

export default LoadingIndicator;
