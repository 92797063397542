import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import i18next from 'i18next';
import { array, func, object, string } from 'prop-types';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import setFieldData from 'final-form-set-field-data';
import { OnChange } from 'react-final-form-listeners';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';

// components
import { Form, AutoSave, NamedLink, NamedRedirect } from '@src/components';
import { Button } from 'atupri-component-library/lib/primitives';
import { Divider, Spacer } from 'atupri-component-library/lib/helpers';

// data
import { deepMerge } from '@src/util/data';
import { personTemplate } from '@src/data/templates/person';

// form
import PersonInfoForm from './partials/PersonInfoForm';
import HealthQuestionsForm from './partials/HealthQuestionsForm';

// util
import { getNextClickTrackingData, getPersonInformationErrorTrackingData } from '@src/util/analytics';
import { getAutosavedValues, setAutosaved } from '@src/util/autosave';
import { isOkpProcess, isInternalProcess } from '@src/util/process';
import createScrollToFirstErrorDecorator from '@src/util/finalForm/decorators/scrollToFirstError';
import {
    needsToAnswerHealthQuestions,
    isNewbornWithoutPreviousInsurer,
    NATIONALITY_CH,
    GENDER_UNBORN,
    PREVIOUS_INSURER_MOVED,
    PREVIOUS_INSURER_UNBORN,
} from '@src/util/person';
import { getPerson, getHealthQuestions } from '@src/util/submission';

// style
import {
    StyledCustomSpacer,
    StyledTitle,
    StyledSubtitle,
    StyledCtaBar,
    StyledCtaBarCol,
} from './PersonInformationForm.style';

const scrollToFirstErrorDecorator = createScrollToFirstErrorDecorator();

export const PersonInformationForm = props => {
    const { currentSubmissionId, ownSubmission, onUpdateSubmission, healthQuestions, insurerList, page, params } =
        props;
    const sendDataToGTM = useGTMDispatch();

    const prefix = 'person';

    const person = getPerson(ownSubmission, params.userId);

    const { basket } = ownSubmission.relationships;
    const personNeedsToAnswerHealthQuestions = needsToAnswerHealthQuestions(person.id, ownSubmission);
    const currentProductsFromApi = useSelector(state => state.apiData?.currentProducts);
    const isInternal = isInternalProcess(ownSubmission.relationships.persons, currentProductsFromApi);

    const initVal = useMemo(() => {
        let defaultValues = {
            person: {
                firstname: person.firstname,
                lastname: person.lastname,
                moved: person.moved ?? personTemplate.moved,
                nationality: person.nationality ?? personTemplate.nationality,
                dateOfEntry: person.dateOfEntry ?? personTemplate.dateOfEntry,
                permitType: person.permitType ?? personTemplate.permitType,
            },
        };

        if (person.previousInsurer !== '') {
            defaultValues.person.previousInsurer = person.previousInsurer;
        }

        if (personNeedsToAnswerHealthQuestions) {
            const savedHealthQuestions = getHealthQuestions(ownSubmission, person.id);

            if (savedHealthQuestions?.answers) {
                defaultValues.healthQuestions = savedHealthQuestions.answers;
            }
        }

        // check for autosaved values
        const autosavedValues = getAutosavedValues(currentSubmissionId, 'personInformationForm', person.id);

        if (autosavedValues) {
            defaultValues = deepMerge([defaultValues, autosavedValues]);
        }

        return defaultValues;
    }, []);

    const autosave = async (values, isFormInvalid) => {
        setAutosaved(ownSubmission.id.uuid, 'personInformationForm', isFormInvalid, values, person.id);
    };

    const handleOnSubmit = values => {
        let updateData = {
            id: ownSubmission.id,
            personId: person.id,
        };

        if ('person' in values) {
            if (isOkpProcess(basket, person.id)) {
                if (person.gender === GENDER_UNBORN || isNewbornWithoutPreviousInsurer(person)) {
                    values.person.previousInsurer = PREVIOUS_INSURER_UNBORN;
                } else if (values.person.moved === 'yes') {
                    values.person.previousInsurer = PREVIOUS_INSURER_MOVED;
                }
            }
            updateData['person'] = values.person;

            // we need to differentiate between autosaved and submitted to validate tabs
            updateData['person']['dataConfirmedByUser'] = true;
        }

        if ('healthQuestions' in values) {
            updateData['healthQuestions'] = {
                ready: Object.keys(values['healthQuestions']).length > 0,
                answers: values['healthQuestions'],
            };
        }

        sendDataToGTM(getNextClickTrackingData(personNeedsToAnswerHealthQuestions));

        onUpdateSubmission('cockpit', updateData);
    };

    const handleInvalidSubmitClick = errors => {
        const errorCount = errors ? Object.keys(errors).length : 0;

        sendDataToGTM(getPersonInformationErrorTrackingData(errorCount));
    };

    // If a user has selected a product that requires a health declaration, then deselects it and goes back
    // through the browser history, they may come across the health declaration where no questions are
    // displayed because they are not needed for the current product selection. In this case, we make a
    // redirect to the confirmation page.
    return isInternal && !personNeedsToAnswerHealthQuestions ? (
        <NamedRedirect name="EditSubmissionPage" params={{ tab: 'confirmation' }} />
    ) : (
        <FinalForm
            subscription={{ submitting: true }}
            mutators={{
                ...arrayMutators,
                setFieldData,
                setValue: ([field, value], state, { changeValue }) => {
                    changeValue(state, field, () => value);
                },
            }}
            decorators={[scrollToFirstErrorDecorator]}
            initialValues={initVal}
            disabled={false}
            questions={healthQuestions}
            autosave={autosave}
            currentSubmissionId={ownSubmission.id.uuid}
            currentPerson={person}
            updateInProgress={page.updateInProgress || page.createSubmissionOfferInProgress}
            onSubmit={handleOnSubmit}
            render={({ form, ...formRenderProps }) => {
                let { handleSubmit, autosave, currentSubmissionId, disabled, invalid, updateInProgress, errors } =
                    formRenderProps;

                const submitDisabled = disabled || updateInProgress;

                return (
                    <Form onSubmit={handleSubmit}>
                        <AutoSave onSave={autosave} isFormValid={!invalid} debounce={300} />

                        <OnChange name={`${prefix}.nationality`}>
                            {(value, previous) => {
                                if (value !== previous && value === NATIONALITY_CH) {
                                    form.mutators.setValue(`${prefix}.permitType`, null);
                                }
                            }}
                        </OnChange>
                        <OnChange name={`${prefix}.moved`}>
                            {(value, previous) => {
                                if (value !== previous && value === 'yes') {
                                    form.mutators.setValue(`${prefix}.previousInsurer`, null);
                                } else if (value !== previous && value === 'no') {
                                    form.mutators.setValue(`${prefix}.dateOfEntry`, null);
                                }
                            }}
                        </OnChange>

                        {!isInternal && (
                            <>
                                <StyledTitle>{i18next.t('PersonInfoForm.title')}</StyledTitle>
                                <StyledSubtitle>{i18next.t('PersonInfoForm.subTitle')}</StyledSubtitle>

                                <PersonInfoForm
                                    form={form}
                                    formRenderProps={formRenderProps}
                                    submissionId={currentSubmissionId}
                                    insurerList={insurerList}
                                    basket={basket}
                                />
                            </>
                        )}

                        {personNeedsToAnswerHealthQuestions ? (
                            <>
                                {!isInternal && (
                                    <>
                                        <Spacer space="kappa" />
                                        <Divider />
                                    </>
                                )}
                                <StyledTitle>{i18next.t('HealthQuestionsPage.title')}</StyledTitle>
                                <StyledSubtitle>{i18next.t('HealthQuestionsPage.subTitle')}</StyledSubtitle>
                                <StyledCustomSpacer space="limbo" />

                                <HealthQuestionsForm healthQuestions={healthQuestions} />
                            </>
                        ) : (
                            ''
                        )}
                        <StyledCtaBar>
                            <StyledCtaBarCol>
                                <NamedLink
                                    name="EditSubmissionPage"
                                    params={{ tab: isInternal ? 'product' : 'cockpit' }}
                                >
                                    <Button
                                        buttonText={i18next.t('HealthQuestionsForm.backButton')}
                                        outlined
                                        variant="primary"
                                        iconBefore="arrow_left"
                                    />
                                </NamedLink>
                            </StyledCtaBarCol>
                            <StyledCtaBarCol>
                                <Button
                                    className={`submitBtn`}
                                    buttonText={i18next.t('HealthQuestionsForm.saveButton.text')}
                                    type="submit"
                                    name="submit"
                                    disabled={submitDisabled}
                                    onClick={() => {
                                        if (errors?.healthQuestions) {
                                            handleInvalidSubmitClick(errors.healthQuestions);
                                        }
                                    }}
                                />
                            </StyledCtaBarCol>
                        </StyledCtaBar>
                    </Form>
                );
            }}
        />
    );
};

PersonInformationForm.propTypes = {
    currentSubmissionId: string.isRequired,
    ownSubmission: object,
    onUpdateSubmission: func,
    healthQuestions: array,
    insurerList: array,
    page: object,
    params: object,
};

export default PersonInformationForm;
