import { useSelector, useDispatch } from 'react-redux';
import { ADD_ERROR, CLEAR_ERRORS } from '@src/ducks/errorQueue.duck';

export function useErrorQueue() {
    const errors = useSelector(state => state.errorQueue?.errors);
    const dispatch = useDispatch();

    return {
        errors,
        getErrorsByCategory: (category = null) => {
            if (category) {
                return errors.filter(error => error.category === category);
            }
            return errors;
        },
        addError: (message, code = null, category = null) => {
            // Do not add error if it was already added before
            if (errors.some(error => error.message === message)) {
                return;
            }
            dispatch({
                type: ADD_ERROR,
                payload: {
                    message,
                    code,
                    category,
                },
            });
        },
        clearErrors: (category = null) => {
            dispatch({
                type: CLEAR_ERRORS,
                payload: {
                    category,
                },
            });
        },
    };
}
