/* tslint:disable */
/* eslint-disable */
/**
 * myAtupri REST API
 * This Java REST API delivers the data used by the customer tools such as the Atupri App or the customer portal.<br>For errors, inconsistencies or suggestions, please create a JIRA ticket in [ATP JIRA PROJECT](https://atupri.atlassian.net/browse/ATP).
 *
 * The version of the OpenAPI document: 4.0.24
 * Contact: digital@atupri.ch
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AccountResponse
 */
export interface AccountResponse {
    /**
     * A list with the open pending changes in the CRM that affect the whole family. Note that currently this list only indicates that there is a pending change open that needs to be processed by an SC employee. Once that CRM task is closed the entry for that change will vanish from the list. It doesn\'t matter if the change is active today or in one month. 
     * @type {Array<PendingFamilyChange>}
     * @memberof AccountResponse
     */
    'pendingChanges': Array<PendingFamilyChange>;
    /**
     * 
     * @type {PaymentDetailsResponse}
     * @memberof AccountResponse
     */
    'paymentDetails': PaymentDetailsResponse;
    /**
     * 
     * @type {Language}
     * @memberof AccountResponse
     */
    'correspondenceLanguage': Language;
    /**
     * 
     * @type {Address}
     * @memberof AccountResponse
     */
    'address': Address;
    /**
     * The e-mail that is used for the login. This field depends on the current logged in user. It will be the email that they use for the login
     * @type {string}
     * @memberof AccountResponse
     */
    'loginEmail': string;
    /**
     * The mobile phone number that is used for the 2FA login step. This field depends on the current logged in user. It will be the phone number that they use for the login.<br>The number will be in the E.164 format with the + replaced by 00, the dial out code used in Switzerland.
     * @type {string}
     * @memberof AccountResponse
     */
    'loginPhoneNumber': string;
}
/**
 * The new address for the family / user.
 * @export
 * @interface Address
 */
export interface Address {
    /**
     * Full street name and number
     * @type {string}
     * @memberof Address
     */
    'street'?: string;
    /**
     * Additional address information, if present
     * @type {string}
     * @memberof Address
     */
    'addressExtension'?: string | null;
    /**
     * City
     * @type {string}
     * @memberof Address
     */
    'city'?: string;
    /**
     * PLZ / postal code
     * @type {string}
     * @memberof Address
     */
    'postalCode'?: string;
    /**
     * Country
     * @type {string}
     * @memberof Address
     */
    'country'?: string | null;
    /**
     * Id of the community to where the address belongs to
     * @type {number}
     * @memberof Address
     */
    'communityId'?: number | null;
    /**
     * The tariff region for the community, used to calculate prices of the products
     * @type {string}
     * @memberof Address
     */
    'tariffRegion'?: string | null;
    /**
     * Geo information, normally not set, null
     * @type {number}
     * @memberof Address
     */
    'latitude'?: number | null;
    /**
     * Geo information, normally not set, null
     * @type {number}
     * @memberof Address
     */
    'longitude'?: number | null;
}
/**
 * Contact object that includes contact time, date and contact number, it is required if contactCustomer = true
 * @export
 * @interface AddressChangeContact
 */
export interface AddressChangeContact {
    /**
     * Free text message which the customer can transmit to SC in the contact form when changing the address
     * @type {string}
     * @memberof AddressChangeContact
     */
    'message'?: string;
    /**
     * Contact number by which SC contacts the customer about the change, this is different from phoneNr1 which we send to Siddhartha in customer Contact Details tab
     * @type {string}
     * @memberof AddressChangeContact
     */
    'contactNumber': string;
    /**
     * The date which the customer sets to be contacted by the SC
     * @type {string}
     * @memberof AddressChangeContact
     */
    'contactDate': string;
    /**
     * The time slot which the customer selects to be contacted by the SC
     * @type {string}
     * @memberof AddressChangeContact
     */
    'contactTime': string;
}
/**
 * Request payload to change address for both simple and complex changes.
 * @export
 * @interface AddressChangeRequest
 */
export interface AddressChangeRequest {
    /**
     * 
     * @type {Address}
     * @memberof AddressChangeRequest
     */
    'address': Address;
    /**
     * The date from which the new address is valid and active
     * @type {string}
     * @memberof AddressChangeRequest
     */
    'validFrom': string;
    /**
     * Family number of the logged in user
     * @type {number}
     * @memberof AddressChangeRequest
     */
    'familyNr': number;
    /**
     * The new Gemeinde ID / Municipality ID of the customer
     * @type {number}
     * @memberof AddressChangeRequest
     */
    'communityId': number;
    /**
     * Contact number to update in Contact Details in Siddhartha, this is different from contactNumber in complex change.
     * @type {string}
     * @memberof AddressChangeRequest
     */
    'phoneNr1': string;
}
/**
 * ACCEPTED: Complex change request accepted by CRMCHANGED: Simple change that is executed immediately,COMPLEX_CHANGE: is a complex change that an SC agent performs manually through CRM taskPROCESSED_LATER: Address change simple, but will be processed later (Read-Only mode)UNKNOWN: Unknown error or status, possible failure
 * @export
 * @interface AddressChangeResponse
 */
export interface AddressChangeResponse {
    /**
     * The result status for the change request.
     * @type {string}
     * @memberof AddressChangeResponse
     */
    'status': AddressChangeResponseStatusEnum;
}

export const AddressChangeResponseStatusEnum = {
    Accepted: 'ACCEPTED',
    Changed: 'CHANGED',
    ComplexChange: 'COMPLEX_CHANGE',
    ProcessedLater: 'PROCESSED_LATER',
    Unknown: 'UNKNOWN'
} as const;

export type AddressChangeResponseStatusEnum = typeof AddressChangeResponseStatusEnum[keyof typeof AddressChangeResponseStatusEnum];

/**
 * A Composition of values for the users franchise. First the franchise is used, when used up, the excess will be used.
 * @export
 * @interface AmountComposition
 */
export interface AmountComposition {
    /**
     * 
     * @type {CurrencyFormat}
     * @memberof AmountComposition
     */
    'balance': CurrencyFormat;
    /**
     * 
     * @type {CurrencyFormat}
     * @memberof AmountComposition
     */
    'remaining': CurrencyFormat;
    /**
     * 
     * @type {CurrencyFormat}
     * @memberof AmountComposition
     */
    'total': CurrencyFormat;
    /**
     * 
     * @type {boolean}
     * @memberof AmountComposition
     */
    'active'?: boolean;
}
/**
 * The involved backend system that threw the error
 * @export
 * @enum {string}
 */

export const BackendSystem = {
    Esb: 'ESB',
    Crm: 'CRM',
    Siddharta: 'SIDDHARTA',
    Dms: 'DMS',
    Ad: 'AD',
    MailServer: 'MAIL_SERVER',
    Mayoris: 'MAYORIS',
    GoogleFirebase: 'GOOGLE_FIREBASE',
    Sumex: 'SUMEX',
    AppwayDb: 'APPWAY_DB',
    Praemienrechner: 'PRAEMIENRECHNER',
    Akupo: 'AKUPO',
    Unknown: 'UNKNOWN'
} as const;

export type BackendSystem = typeof BackendSystem[keyof typeof BackendSystem];


/**
 * 
 * @export
 * @interface BankAccountChangeRequest
 */
export interface BankAccountChangeRequest {
    /**
     * The new IBAN for the account
     * @type {string}
     * @memberof BankAccountChangeRequest
     */
    'iban': string;
    /**
     * The name of the account holder of the given IBAN / bank account
     * @type {string}
     * @memberof BankAccountChangeRequest
     */
    'accountHolder': string;
}
/**
 * 
 * @export
 * @interface BenefitInvoice
 */
export interface BenefitInvoice {
    /**
     * 
     * @type {InvoiceCategory}
     * @memberof BenefitInvoice
     */
    'invoiceCategory': InvoiceCategory;
    /**
     * invoiceDate (Rechnungsdatum), the date the invoice was written. Not to be confused with the dueDate (Faelligkeitsdatum)
     * @type {string}
     * @memberof BenefitInvoice
     */
    'invoiceDate': string;
    /**
     * 
     * @type {InvoiceStatus}
     * @memberof BenefitInvoice
     */
    'invoiceStatus': InvoiceStatus;
    /**
     * Title of the document connected to this invoice
     * @type {string}
     * @memberof BenefitInvoice
     */
    'documentTitle'?: string | null;
    /**
     * InsuredNr of the user, the number has a range from 2000000 to 2999999
     * @type {number}
     * @memberof BenefitInvoice
     */
    'insuredNr'?: number;
    /**
     * An unique id for the invoice; normally one or two letters defining the kind and followed by hyphenated digits of various length.
     * @type {string}
     * @memberof BenefitInvoice
     */
    'invoiceNr': string;
    /**
     * Generated Task id for the invoice when it is registered by the CRM system
     * @type {string}
     * @memberof BenefitInvoice
     */
    'medicalTaskId': string;
    /**
     * Id for the tarmed documents created and processed by the Sumex system (external).
     * @type {string}
     * @memberof BenefitInvoice
     */
    'sumexOriginId'?: string | null;
    /**
     * Generated Task id by the CRM system
     * @type {string}
     * @memberof BenefitInvoice
     */
    'taskId'?: string | null;
    /**
     * The total amount of the billed invoice. Can be negative, a negative amount indicates that the insured receives a payback.
     * @type {number}
     * @memberof BenefitInvoice
     */
    'amount': number;
    /**
     * The amount left to pay
     * @type {number}
     * @memberof BenefitInvoice
     */
    'amountOpen': number;
    /**
     * The amount paid, it is a negative value if the insured receives a payback.
     * @type {number}
     * @memberof BenefitInvoice
     */
    'amountPayed': number;
    /**
     * The part of the benefit paid by Atupri. There is a difference between the paid value and the billed \'amount\', as depending on the pay type, InvoicePayType: PAYBACK, the \'amount\' matches \'payedByAtupri\' as negative value.
     * @type {number}
     * @memberof BenefitInvoice
     */
    'payedByAtupri': number;
    /**
     * The part of the benefit paid by the insured. There is a difference between the paid value and the billed \'amount\', as depending on the pay type, InvoicePayType: PAYMENT, the \'amount\' matches \'payedByInsured\'.
     * @type {number}
     * @memberof BenefitInvoice
     */
    'payedByInsured': number;
    /**
     * Faktura-Code generated from Siddhartha for the invoice
     * @type {number}
     * @memberof BenefitInvoice
     */
    'customInvoiceCode': number;
    /**
     * Due date of the invoice
     * @type {string}
     * @memberof BenefitInvoice
     */
    'dueDate': string;
    /**
     * Name of the medical provider (doctor, pharmacy, ...) if there is one for the invoice
     * @type {string}
     * @memberof BenefitInvoice
     */
    'medicalProviderName': string;
    /**
     * 
     * @type {TaskStatus}
     * @memberof BenefitInvoice
     */
    'benefitStatus': TaskStatus;
    /**
     * The id of a task (medicalTaskId) in the former KBM
     * @type {number}
     * @memberof BenefitInvoice
     */
    'deprecatedKbmId'?: number | null;
    /**
     * Total costs of the benefit case
     * @type {number}
     * @memberof BenefitInvoice
     */
    'totalCosts': number;
    /**
     * 
     * @type {InvoicePayType}
     * @memberof BenefitInvoice
     */
    'invoicePayType': InvoicePayType;
}
/**
 * Canton of the user\'s address
 * @export
 * @enum {string}
 */

export const Canton = {
    Ag: 'AG',
    Ai: 'AI',
    Ar: 'AR',
    Be: 'BE',
    Bl: 'BL',
    Bs: 'BS',
    De: 'DE',
    Fr: 'FR',
    Fl: 'FL',
    Ge: 'GE',
    Gl: 'GL',
    Gr: 'GR',
    It: 'IT',
    Ju: 'JU',
    Lu: 'LU',
    Ne: 'NE',
    Nw: 'NW',
    Ow: 'OW',
    Sg: 'SG',
    Sh: 'SH',
    So: 'SO',
    Sz: 'SZ',
    Tg: 'TG',
    Ti: 'TI',
    Ur: 'UR',
    Vd: 'VD',
    Vs: 'VS',
    Zg: 'ZG',
    Zh: 'ZH',
    None: 'NONE'
} as const;

export type Canton = typeof Canton[keyof typeof Canton];


/**
 * @type ChangeAddressSimple400Response
 * @export
 */
export type ChangeAddressSimple400Response = EsbExceptionResponse | ExceptionResponse | ValidationExceptionResponse;

/**
 * @type ChangeAddressSimple500Response
 * @export
 */
export type ChangeAddressSimple500Response = EsbExceptionResponse | ExceptionResponse;

/**
 * 
 * @export
 * @enum {string}
 */

export const ChangeCategory = {
    AddressChange: 'ADDRESS_CHANGE',
    PaymentFrequencyChange: 'PAYMENT_FREQUENCY_CHANGE'
} as const;

export type ChangeCategory = typeof ChangeCategory[keyof typeof ChangeCategory];


/**
 * 
 * @export
 * @interface CombinedTarmedResponse
 */
export interface CombinedTarmedResponse {
    /**
     * The pages as image of the original Tarmed pdf
     * @type {Array<PdfPageAsImage>}
     * @memberof CombinedTarmedResponse
     */
    'original': Array<PdfPageAsImage>;
    /**
     * The pages as image of the simplified Tarmed pdf
     * @type {Array<PdfPageAsImage>}
     * @memberof CombinedTarmedResponse
     */
    'simplified': Array<PdfPageAsImage>;
    /**
     * The number of pages the simplified Tarmed
     * @type {number}
     * @memberof CombinedTarmedResponse
     */
    'numberOfOriginalPages': number;
    /**
     * The number of pages the simplified Tarmed
     * @type {number}
     * @memberof CombinedTarmedResponse
     */
    'numberOfSimplifiedPages': number;
}
/**
 * 
 * @export
 * @interface CommunityInformationRequest
 */
export interface CommunityInformationRequest {
    /**
     * The year in which we are retrieving community information, community information changes from year to year
     * @type {number}
     * @memberof CommunityInformationRequest
     */
    'year': number;
    /**
     * The postal code for which community information we are retrieving
     * @type {number}
     * @memberof CommunityInformationRequest
     */
    'postalCode': number;
    /**
     * Mode, 1 or 2, always set 1
     * @type {number}
     * @memberof CommunityInformationRequest
     */
    'mode'?: number;
}
/**
 * 
 * @export
 * @interface CommunityInformationResponse
 */
export interface CommunityInformationResponse {
    /**
     * Community ID
     * @type {number}
     * @memberof CommunityInformationResponse
     */
    'communityId'?: number;
    /**
     * Name of a city, town or region, this is different from a community, a city has one or more communities.
     * @type {string}
     * @memberof CommunityInformationResponse
     */
    'city'?: string;
    /**
     * Name of the area in a town, city or region, this is different from the city name.
     * @type {string}
     * @memberof CommunityInformationResponse
     */
    'community'?: string;
    /**
     * Tariff region identifier
     * @type {string}
     * @memberof CommunityInformationResponse
     */
    'tariffRegion'?: string;
}
/**
 * Request payload for complex address change.
 * @export
 * @interface ComplexAddressChangeRequest
 */
export interface ComplexAddressChangeRequest {
    /**
     * 
     * @type {Address}
     * @memberof ComplexAddressChangeRequest
     */
    'address': Address;
    /**
     * The date from which the new address is valid and active
     * @type {string}
     * @memberof ComplexAddressChangeRequest
     */
    'validFrom': string;
    /**
     * Family number of the logged in user
     * @type {number}
     * @memberof ComplexAddressChangeRequest
     */
    'familyNr': number;
    /**
     * The new Gemeinde ID / Municipality ID of the customer
     * @type {number}
     * @memberof ComplexAddressChangeRequest
     */
    'communityId': number;
    /**
     * Contact number to update in Contact Details in Siddhartha, this is different from contactNumber in complex change.
     * @type {string}
     * @memberof ComplexAddressChangeRequest
     */
    'phoneNr1': string;
    /**
     * Contact method by which SC contacts the customer, it is one of PHONE, EMAIL, LETTER (Letter is no longer used).It is only required is customer contactCustomer = true
     * @type {string}
     * @memberof ComplexAddressChangeRequest
     */
    'contactType': ComplexAddressChangeRequestContactTypeEnum;
    /**
     * Optional free text message which the customer can transmit to SC in the contact form when changing the address
     * @type {string}
     * @memberof ComplexAddressChangeRequest
     */
    'comment'?: string;
    /**
     * 
     * @type {AddressChangeContact}
     * @memberof ComplexAddressChangeRequest
     */
    'contact': AddressChangeContact;
    /**
     * Customer requested to be contacted for address change
     * @type {boolean}
     * @memberof ComplexAddressChangeRequest
     */
    'contactCustomer': boolean;
}

export const ComplexAddressChangeRequestContactTypeEnum = {
    Phone: 'PHONE',
    Email: 'EMAIL',
    Letter: 'LETTER',
    NoContact: 'NO_CONTACT'
} as const;

export type ComplexAddressChangeRequestContactTypeEnum = typeof ComplexAddressChangeRequestContactTypeEnum[keyof typeof ComplexAddressChangeRequestContactTypeEnum];

/**
 * Contact for a user
 * @export
 * @interface Contact
 */
export interface Contact {
    /**
     * 
     * @type {ContactCategory}
     * @memberof Contact
     */
    'category': ContactCategory;
    /**
     * URGENCY has no name, is null
     * @type {string}
     * @memberof Contact
     */
    'name'?: string | null;
    /**
     * Email of the contact, URGENCY and MEDGATE have none, are null
     * @type {string}
     * @memberof Contact
     */
    'email'?: string | null;
    /**
     * phone of the contact, MEDGATE has none, is null
     * @type {string}
     * @memberof Contact
     */
    'phoneNumber'?: string | null;
    /**
     * web link of the contact, URGENCY, SERVICE_CENTER and MEDICAL_PROVIDER have none, are null
     * @type {string}
     * @memberof Contact
     */
    'url'?: string | null;
}
/**
 * The categories have an order priority: - FLC - priority: 1 - MEDICAL_PROVIDER - priority: 2 - TEL - priority: 3 - SERVICE_CENTER - priority: 4 - URGENCY - priority: 5 - MEDGATE - priority: 6 
 * @export
 * @enum {string}
 */

export const ContactCategory = {
    Flc: 'FLC',
    Medgate: 'MEDGATE',
    MedicalProvider: 'MEDICAL_PROVIDER',
    ServiceCenter: 'SERVICE_CENTER',
    Tel: 'TEL',
    Urgency: 'URGENCY'
} as const;

export type ContactCategory = typeof ContactCategory[keyof typeof ContactCategory];


/**
 * 
 * @export
 * @interface CorrespondenceLanguageRequest
 */
export interface CorrespondenceLanguageRequest {
    /**
     * 
     * @type {Language}
     * @memberof CorrespondenceLanguageRequest
     */
    'language': Language;
}
/**
 * 
 * @export
 * @interface CreateMessageRequest
 */
export interface CreateMessageRequest {
    /**
     * Value from the RequestType Enum
     * @type {string}
     * @memberof CreateMessageRequest
     */
    'requestType': CreateMessageRequestRequestTypeEnum;
    /**
     * Content of the message
     * @type {string}
     * @memberof CreateMessageRequest
     */
    'messageContent': string;
    /**
     * Reference to the task object in CRM
     * @type {string}
     * @memberof CreateMessageRequest
     */
    'taskId'?: string;
    /**
     * Reference to the process in CRM
     * @type {string}
     * @memberof CreateMessageRequest
     */
    'gevoId'?: string;
    /**
     * Optional field \'documents\', if present needs to contain valid values. See the Document model for more infos.
     * @type {Array<Document>}
     * @memberof CreateMessageRequest
     */
    'documents'?: Array<Document>;
}

export const CreateMessageRequestRequestTypeEnum = {
    AccidentChange: 'ACCIDENT_CHANGE',
    Accounts: 'ACCOUNTS',
    AddressChange: 'ADDRESS_CHANGE',
    Benefit: 'BENEFIT',
    BenefitMaost: 'BENEFIT_MAOST',
    Dunning: 'DUNNING',
    FranchiseChange: 'FRANCHISE_CHANGE',
    HealthQuestions: 'HEALTH_QUESTIONS',
    InsurantModelChange: 'INSURANT_MODEL_CHANGE',
    LetterIncludeAccident: 'LETTER_INCLUDE_ACCIDENT',
    OrderInsuranceCard: 'ORDER_INSURANCE_CARD',
    Other: 'OTHER',
    PaymentFrequencyChange: 'PAYMENT_FREQUENCY_CHANGE',
    Police: 'POLICE',
    Premium: 'PREMIUM',
    PremiumMaost: 'PREMIUM_MAOST',
    ProfileDataChange: 'PROFILE_DATA_CHANGE',
    TaxCertificate: 'TAX_CERTIFICATE',
    UploadedInvoice: 'UPLOADED_INVOICE',
    VvgChange: 'VVG_CHANGE',
    MisroutedCasemanagement: 'MISROUTED_CASEMANAGEMENT'
} as const;

export type CreateMessageRequestRequestTypeEnum = typeof CreateMessageRequestRequestTypeEnum[keyof typeof CreateMessageRequestRequestTypeEnum];

/**
 * The monthly net premium of this product
 * @export
 * @interface CurrencyFormat
 */
export interface CurrencyFormat {
    /**
     * Two decimal point number
     * @type {number}
     * @memberof CurrencyFormat
     */
    'value': number;
    /**
     * Currency code according to the ISO-4217 format
     * @type {string}
     * @memberof CurrencyFormat
     */
    'currency': string;
    /**
     * The format for interpreting the value and currency, ISO-4217
     * @type {string}
     * @memberof CurrencyFormat
     */
    'format': string;
}
/**
 * 
 * @export
 * @interface Deductibles
 */
export interface Deductibles {
    /**
     * Boolean value, true if the the user is insured with a product of the OKP models
     * @type {boolean}
     * @memberof Deductibles
     */
    'hasOkp': boolean;
    /**
     * 
     * @type {ProductModel}
     * @memberof Deductibles
     */
    'productModel': ProductModel;
    /**
     * 
     * @type {AmountComposition}
     * @memberof Deductibles
     */
    'excess': AmountComposition;
    /**
     * 
     * @type {AmountComposition}
     * @memberof Deductibles
     */
    'franchise': AmountComposition;
}
/**
 * 
 * @export
 * @interface DeductiblesResponse
 */
export interface DeductiblesResponse {
    /**
     * InsuredNr of the user, the number has a range from 2000000 to 2999999
     * @type {number}
     * @memberof DeductiblesResponse
     */
    'insuredNr': number;
    /**
     * 
     * @type {Deductibles}
     * @memberof DeductiblesResponse
     */
    'deductiblesBalance': Deductibles;
}
/**
 * 
 * @export
 * @interface Document
 */
export interface Document {
    /**
     * Name of the document, can contain the file ending
     * @type {string}
     * @memberof Document
     */
    'name': string;
    /**
     * Optional field. If the mimeType field is omitted the API guesses the type from the content. Allowed types: application/pdf, image/jpeg, image/png, image/tiff, image/pjpeg
     * @type {string}
     * @memberof Document
     */
    'mimeType'?: string;
    /**
     * Base64 encoded file, pdf or image
     * @type {string}
     * @memberof Document
     */
    'content': string;
}
/**
 * 
 * @export
 * @interface DocumentUploadRequest
 */
export interface DocumentUploadRequest {
    /**
     * The valid range of the insured number lies between 2000000 and 2999999
     * @type {number}
     * @memberof DocumentUploadRequest
     */
    'insuredNr': number;
    /**
     * The files to upload. Those files will be stored separately in the DMS
     * @type {Array<Document>}
     * @memberof DocumentUploadRequest
     */
    'documents': Array<Document>;
}
/**
 * 
 * @export
 * @interface EffectiveDate
 */
export interface EffectiveDate {
    /**
     * validFrom
     * @type {string}
     * @memberof EffectiveDate
     */
    'validFrom'?: string;
}
/**
 * 
 * @export
 * @interface EligibleProductsComposition
 */
export interface EligibleProductsComposition {
    /**
     * InsuredNr of the user, the number has a range from 2000000 to 2999999
     * @type {number}
     * @memberof EligibleProductsComposition
     */
    'insuredNr': number;
    /**
     * product
     * @type {Array<string>}
     * @memberof EligibleProductsComposition
     */
    'eligibleProducts'?: Array<string>;
}
/**
 * A response containing a error thrown by one of the backend systems or the ESB itself
 * @export
 * @interface EsbExceptionResponse
 */
export interface EsbExceptionResponse {
    /**
     * Error message
     * @type {string}
     * @memberof EsbExceptionResponse
     */
    'message': string;
    /**
     * Debug message, not available in production
     * @type {string}
     * @memberof EsbExceptionResponse
     */
    'debugMessage': string;
    /**
     * HTTP status code
     * @type {number}
     * @memberof EsbExceptionResponse
     */
    'status': number;
    /**
     * HTTP status text
     * @type {string}
     * @memberof EsbExceptionResponse
     */
    'code': string;
    /**
     * Request id issued by the SES, can be used to trace the request through the systems. It maybe an empty string, if the SES didn\'t provide the id or if the request didn\'t go through the SES i.g on the internal *.atuprinet.ch domains.
     * @type {string}
     * @memberof EsbExceptionResponse
     */
    'requestCorrelator': string;
    /**
     * Date and time when the error occurred
     * @type {string}
     * @memberof EsbExceptionResponse
     */
    'timestamp': string;
    /**
     * The app status error code from the ESB
     * @type {number}
     * @memberof EsbExceptionResponse
     */
    'appStatus': number;
    /**
     * 
     * @type {BackendSystem}
     * @memberof EsbExceptionResponse
     */
    'backendSystem': BackendSystem;
}
/**
 * General API error response for various exceptions
 * @export
 * @interface ExceptionResponse
 */
export interface ExceptionResponse {
    /**
     * Error message
     * @type {string}
     * @memberof ExceptionResponse
     */
    'message': string;
    /**
     * Debug message, not available in production
     * @type {string}
     * @memberof ExceptionResponse
     */
    'debugMessage': string;
    /**
     * HTTP status code
     * @type {number}
     * @memberof ExceptionResponse
     */
    'status': number;
    /**
     * HTTP status text
     * @type {string}
     * @memberof ExceptionResponse
     */
    'code': string;
    /**
     * Request id issued by the SES, can be used to trace the request through the systems. It maybe an empty string, if the SES didn\'t provide the id or if the request didn\'t go through the SES i.g on the internal *.atuprinet.ch domains.
     * @type {string}
     * @memberof ExceptionResponse
     */
    'requestCorrelator': string;
    /**
     * Date and time when the error occurred
     * @type {string}
     * @memberof ExceptionResponse
     */
    'timestamp': string;
}
/**
 * URL to the prepared session for a logged in myAtupri user
 * @export
 * @interface ExternalSessionResponse
 */
export interface ExternalSessionResponse {
    /**
     * URL to the prepared session for a logged in myAtupri user
     * @type {string}
     * @memberof ExternalSessionResponse
     */
    'redirectUrl': string;
}
/**
 * Gender
 * @export
 * @enum {string}
 */

export const Gender = {
    Female: 'FEMALE',
    Male: 'MALE'
} as const;

export type Gender = typeof Gender[keyof typeof Gender];


/**
 * 
 * @export
 * @interface GetInvoices200ResponseInner
 */
export interface GetInvoices200ResponseInner {
    /**
     * 
     * @type {InvoiceCategory}
     * @memberof GetInvoices200ResponseInner
     */
    'invoiceCategory': InvoiceCategory;
    /**
     * invoiceDate (Rechnungsdatum), the date the invoice was written. Not to be confused with the dueDate (Faelligkeitsdatum)
     * @type {string}
     * @memberof GetInvoices200ResponseInner
     */
    'invoiceDate': string;
    /**
     * 
     * @type {InvoiceStatus}
     * @memberof GetInvoices200ResponseInner
     */
    'invoiceStatus': InvoiceStatus;
    /**
     * Title of the document connected to this invoice
     * @type {string}
     * @memberof GetInvoices200ResponseInner
     */
    'documentTitle'?: string | null;
    /**
     * InsuredNr of the user, the number has a range from 2000000 to 2999999
     * @type {number}
     * @memberof GetInvoices200ResponseInner
     */
    'insuredNr'?: number;
    /**
     * An unique id for the invoice; normally one or two letters defining the kind and followed by hyphenated digits of various length.
     * @type {string}
     * @memberof GetInvoices200ResponseInner
     */
    'invoiceNr': string;
    /**
     * The total amount of the billed invoice. Can be negative, a negative amount indicates that the insured receives a payback.
     * @type {number}
     * @memberof GetInvoices200ResponseInner
     */
    'amount': number;
    /**
     * The amount left to pay
     * @type {number}
     * @memberof GetInvoices200ResponseInner
     */
    'amountOpen': number;
    /**
     * The amount paid, it is a negative value if the insured receives a payback.
     * @type {number}
     * @memberof GetInvoices200ResponseInner
     */
    'amountPayed': number;
    /**
     * Faktura-Code generated from Siddhartha for the invoice
     * @type {number}
     * @memberof GetInvoices200ResponseInner
     */
    'customInvoiceCode': number;
    /**
     * Due date of the invoice
     * @type {string}
     * @memberof GetInvoices200ResponseInner
     */
    'dueDate': string;
    /**
     * bank account number
     * @type {string}
     * @memberof GetInvoices200ResponseInner
     */
    'paySlipAccount': string;
    /**
     * payment slip reference number
     * @type {string}
     * @memberof GetInvoices200ResponseInner
     */
    'paySlipReference': string;
    /**
     * Beginning of the active period of the premium for this invoice
     * @type {string}
     * @memberof GetInvoices200ResponseInner
     */
    'fromDate': string;
    /**
     * End of the active period of the premium for this invoice
     * @type {string}
     * @memberof GetInvoices200ResponseInner
     */
    'toDate': string;
    /**
     * True if the invoice document is available as PDF, else false.<br> For VIP customers (Atupri Employees and some collective insured users SBB, ZBAG,...) the premium invoice isn\'t available as PDF.<br> The amount is deducted directly from the users\' contract and automatically paid.<br> Those documents can be identified by the prefix \'PV-\' of the invoiceNr. For the other type \'PF-\', the PDF is available. 
     * @type {boolean}
     * @memberof GetInvoices200ResponseInner
     */
    'isDocumentAvailable': boolean;
    /**
     * Generated Task id for the invoice when it is registered by the CRM system
     * @type {string}
     * @memberof GetInvoices200ResponseInner
     */
    'medicalTaskId': string;
    /**
     * last time an update of the invoice was recorded
     * @type {string}
     * @memberof GetInvoices200ResponseInner
     */
    'lastStatusUpdate': string;
    /**
     * Creation date and time of the invoice
     * @type {string}
     * @memberof GetInvoices200ResponseInner
     */
    'creationDateTime': string;
    /**
     * List with documentIds related to a CRM task.
     * @type {Array<string>}
     * @memberof GetInvoices200ResponseInner
     * @deprecated
     */
    'documentIds': Array<string>;
    /**
     * List with id and name of documents related to a CRM task.
     * @type {Array<MetaInvoiceDocument>}
     * @memberof GetInvoices200ResponseInner
     */
    'metaInvoiceDocuments': Array<MetaInvoiceDocument>;
    /**
     * Id for the tarmed documents created and processed by the Sumex system (external).
     * @type {string}
     * @memberof GetInvoices200ResponseInner
     */
    'sumexOriginId'?: string | null;
    /**
     * Generated Task id by the CRM system
     * @type {string}
     * @memberof GetInvoices200ResponseInner
     */
    'taskId'?: string | null;
    /**
     * The part of the benefit paid by Atupri. There is a difference between the paid value and the billed \'amount\', as depending on the pay type, InvoicePayType: PAYBACK, the \'amount\' matches \'payedByAtupri\' as negative value.
     * @type {number}
     * @memberof GetInvoices200ResponseInner
     */
    'payedByAtupri': number;
    /**
     * The part of the benefit paid by the insured. There is a difference between the paid value and the billed \'amount\', as depending on the pay type, InvoicePayType: PAYMENT, the \'amount\' matches \'payedByInsured\'.
     * @type {number}
     * @memberof GetInvoices200ResponseInner
     */
    'payedByInsured': number;
    /**
     * Name of the medical provider (doctor, pharmacy, ...) if there is one for the invoice
     * @type {string}
     * @memberof GetInvoices200ResponseInner
     */
    'medicalProviderName': string;
    /**
     * 
     * @type {TaskStatus}
     * @memberof GetInvoices200ResponseInner
     */
    'benefitStatus': TaskStatus;
    /**
     * Total costs of the benefit case
     * @type {number}
     * @memberof GetInvoices200ResponseInner
     */
    'totalCosts': number;
    /**
     * 
     * @type {InvoicePayType}
     * @memberof GetInvoices200ResponseInner
     */
    'invoicePayType': InvoicePayType;
    /**
     * The id of a task (medicalTaskId) in the former KBM
     * @type {number}
     * @memberof GetInvoices200ResponseInner
     */
    'deprecatedKbmId'?: number | null;
}
/**
 * 
 * @export
 * @interface GetMedicalInvoicesForFamily200ResponseInner
 */
export interface GetMedicalInvoicesForFamily200ResponseInner {
    /**
     * 
     * @type {InvoiceCategory}
     * @memberof GetMedicalInvoicesForFamily200ResponseInner
     */
    'invoiceCategory': InvoiceCategory;
    /**
     * invoiceDate (Rechnungsdatum), the date the invoice was written. Not to be confused with the dueDate (Faelligkeitsdatum)
     * @type {string}
     * @memberof GetMedicalInvoicesForFamily200ResponseInner
     */
    'invoiceDate': string;
    /**
     * 
     * @type {InvoiceStatus}
     * @memberof GetMedicalInvoicesForFamily200ResponseInner
     */
    'invoiceStatus': InvoiceStatus;
    /**
     * Title of the document connected to this invoice
     * @type {string}
     * @memberof GetMedicalInvoicesForFamily200ResponseInner
     */
    'documentTitle'?: string | null;
    /**
     * InsuredNr of the user, the number has a range from 2000000 to 2999999
     * @type {number}
     * @memberof GetMedicalInvoicesForFamily200ResponseInner
     */
    'insuredNr'?: number;
    /**
     * Generated Task id for the invoice when it is registered by the CRM system
     * @type {string}
     * @memberof GetMedicalInvoicesForFamily200ResponseInner
     */
    'medicalTaskId': string;
    /**
     * last time an update of the invoice was recorded
     * @type {string}
     * @memberof GetMedicalInvoicesForFamily200ResponseInner
     */
    'lastStatusUpdate': string;
    /**
     * Creation date and time of the invoice
     * @type {string}
     * @memberof GetMedicalInvoicesForFamily200ResponseInner
     */
    'creationDateTime': string;
    /**
     * List with documentIds related to a CRM task.
     * @type {Array<string>}
     * @memberof GetMedicalInvoicesForFamily200ResponseInner
     * @deprecated
     */
    'documentIds': Array<string>;
    /**
     * List with id and name of documents related to a CRM task.
     * @type {Array<MetaInvoiceDocument>}
     * @memberof GetMedicalInvoicesForFamily200ResponseInner
     */
    'metaInvoiceDocuments': Array<MetaInvoiceDocument>;
    /**
     * An unique id for the invoice; normally one or two letters defining the kind and followed by hyphenated digits of various length.
     * @type {string}
     * @memberof GetMedicalInvoicesForFamily200ResponseInner
     */
    'invoiceNr': string;
    /**
     * Id for the tarmed documents created and processed by the Sumex system (external).
     * @type {string}
     * @memberof GetMedicalInvoicesForFamily200ResponseInner
     */
    'sumexOriginId'?: string | null;
    /**
     * Generated Task id by the CRM system
     * @type {string}
     * @memberof GetMedicalInvoicesForFamily200ResponseInner
     */
    'taskId'?: string | null;
    /**
     * The total amount of the billed invoice. Can be negative, a negative amount indicates that the insured receives a payback.
     * @type {number}
     * @memberof GetMedicalInvoicesForFamily200ResponseInner
     */
    'amount': number;
    /**
     * The amount left to pay
     * @type {number}
     * @memberof GetMedicalInvoicesForFamily200ResponseInner
     */
    'amountOpen': number;
    /**
     * The amount paid, it is a negative value if the insured receives a payback.
     * @type {number}
     * @memberof GetMedicalInvoicesForFamily200ResponseInner
     */
    'amountPayed': number;
    /**
     * The part of the benefit paid by Atupri. There is a difference between the paid value and the billed \'amount\', as depending on the pay type, InvoicePayType: PAYBACK, the \'amount\' matches \'payedByAtupri\' as negative value.
     * @type {number}
     * @memberof GetMedicalInvoicesForFamily200ResponseInner
     */
    'payedByAtupri': number;
    /**
     * The part of the benefit paid by the insured. There is a difference between the paid value and the billed \'amount\', as depending on the pay type, InvoicePayType: PAYMENT, the \'amount\' matches \'payedByInsured\'.
     * @type {number}
     * @memberof GetMedicalInvoicesForFamily200ResponseInner
     */
    'payedByInsured': number;
    /**
     * Faktura-Code generated from Siddhartha for the invoice
     * @type {number}
     * @memberof GetMedicalInvoicesForFamily200ResponseInner
     */
    'customInvoiceCode': number;
    /**
     * Due date of the invoice
     * @type {string}
     * @memberof GetMedicalInvoicesForFamily200ResponseInner
     */
    'dueDate': string;
    /**
     * Name of the medical provider (doctor, pharmacy, ...) if there is one for the invoice
     * @type {string}
     * @memberof GetMedicalInvoicesForFamily200ResponseInner
     */
    'medicalProviderName': string;
    /**
     * 
     * @type {TaskStatus}
     * @memberof GetMedicalInvoicesForFamily200ResponseInner
     */
    'benefitStatus': TaskStatus;
    /**
     * Total costs of the benefit case
     * @type {number}
     * @memberof GetMedicalInvoicesForFamily200ResponseInner
     */
    'totalCosts': number;
    /**
     * 
     * @type {InvoicePayType}
     * @memberof GetMedicalInvoicesForFamily200ResponseInner
     */
    'invoicePayType': InvoicePayType;
    /**
     * The id of a task (medicalTaskId) in the former KBM
     * @type {number}
     * @memberof GetMedicalInvoicesForFamily200ResponseInner
     */
    'deprecatedKbmId'?: number | null;
}
/**
 * 
 * @export
 * @interface GetPremiumInvoiceDetail200ResponseInner
 */
export interface GetPremiumInvoiceDetail200ResponseInner {
    /**
     * 
     * @type {InvoiceCategory}
     * @memberof GetPremiumInvoiceDetail200ResponseInner
     */
    'invoiceCategory': InvoiceCategory;
    /**
     * invoiceDate (Rechnungsdatum), the date the invoice was written. Not to be confused with the dueDate (Faelligkeitsdatum)
     * @type {string}
     * @memberof GetPremiumInvoiceDetail200ResponseInner
     */
    'invoiceDate': string;
    /**
     * 
     * @type {InvoiceStatus}
     * @memberof GetPremiumInvoiceDetail200ResponseInner
     */
    'invoiceStatus': InvoiceStatus;
    /**
     * Title of the document connected to this invoice
     * @type {string}
     * @memberof GetPremiumInvoiceDetail200ResponseInner
     */
    'documentTitle'?: string | null;
    /**
     * InsuredNr of the user, the number has a range from 2000000 to 2999999
     * @type {number}
     * @memberof GetPremiumInvoiceDetail200ResponseInner
     */
    'insuredNr'?: number;
    /**
     * An unique id for the premium invoice, normally two letters defining the kind, PF- / PV- , followed by hyphenated digits of various length. - PF-0987-0987 - standard invoice, document is available as PDF - PV-4321-4321 - invoice of a VIP customer, document isn\'t available as PDF 
     * @type {string}
     * @memberof GetPremiumInvoiceDetail200ResponseInner
     */
    'invoiceNr': string;
    /**
     * The total amount of the billed invoice. Can be negative, a negative amount indicates that the insured receives a payback.
     * @type {number}
     * @memberof GetPremiumInvoiceDetail200ResponseInner
     */
    'amount': number;
    /**
     * The amount left to pay
     * @type {number}
     * @memberof GetPremiumInvoiceDetail200ResponseInner
     */
    'amountOpen': number;
    /**
     * The amount paid, it is a negative value if the insured receives a payback.
     * @type {number}
     * @memberof GetPremiumInvoiceDetail200ResponseInner
     */
    'amountPayed': number;
    /**
     * Faktura-Code generated from Siddhartha for the invoice
     * @type {number}
     * @memberof GetPremiumInvoiceDetail200ResponseInner
     */
    'customInvoiceCode': number;
    /**
     * Due date of the invoice
     * @type {string}
     * @memberof GetPremiumInvoiceDetail200ResponseInner
     */
    'dueDate': string;
    /**
     * bank account number
     * @type {string}
     * @memberof GetPremiumInvoiceDetail200ResponseInner
     */
    'paySlipAccount': string;
    /**
     * payment slip reference number
     * @type {string}
     * @memberof GetPremiumInvoiceDetail200ResponseInner
     */
    'paySlipReference': string;
    /**
     * Beginning of the active period of the premium for this invoice
     * @type {string}
     * @memberof GetPremiumInvoiceDetail200ResponseInner
     */
    'fromDate': string;
    /**
     * End of the active period of the premium for this invoice
     * @type {string}
     * @memberof GetPremiumInvoiceDetail200ResponseInner
     */
    'toDate': string;
    /**
     * True if the invoice document is available as PDF, else false.<br> For VIP customers (Atupri Employees and some collective insured users SBB, ZBAG,...) the premium invoice isn\'t available as PDF.<br> The amount is deducted directly from the users\' contract and automatically paid.<br> Those documents can be identified by the prefix \'PV-\' of the invoiceNr. For the other type \'PF-\', the PDF is available. 
     * @type {boolean}
     * @memberof GetPremiumInvoiceDetail200ResponseInner
     */
    'isDocumentAvailable': boolean;
}
/**
 * 
 * @export
 * @interface InsuranceCard
 */
export interface InsuranceCard {
    /**
     * InsuredNr of the user, the number has a range from 2000000 to 2999999
     * @type {number}
     * @memberof InsuranceCard
     */
    'insuredNr': number;
    /**
     * Insurance card number
     * @type {string}
     * @memberof InsuranceCard
     */
    'insuredCardNr': string;
    /**
     * AHV number
     * @type {string}
     * @memberof InsuranceCard
     */
    'ahvNr': string;
    /**
     * 
     * @type {Gender}
     * @memberof InsuranceCard
     */
    'gender': Gender;
    /**
     * First name
     * @type {string}
     * @memberof InsuranceCard
     */
    'firstName': string;
    /**
     * Last name
     * @type {string}
     * @memberof InsuranceCard
     */
    'lastName': string;
    /**
     * ISO 8601 date of birth
     * @type {string}
     * @memberof InsuranceCard
     */
    'birthDate': string;
    /**
     * Name of the service center the user belongs to
     * @type {string}
     * @memberof InsuranceCard
     */
    'serviceCenterName': string;
    /**
     * Telephone number of the contact center
     * @type {string}
     * @memberof InsuranceCard
     */
    'serviceCenterContactNumber': string;
    /**
     * The bag number issued to the insurance form the Swiss Bundesamt für Gesundheit(BAG)
     * @type {number}
     * @memberof InsuranceCard
     */
    'bagNumber': number;
    /**
     * Full name
     * @type {string}
     * @memberof InsuranceCard
     */
    'fullName': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const InsuranceCategory = {
    Okp: 'OKP',
    Vvg: 'VVG'
} as const;

export type InsuranceCategory = typeof InsuranceCategory[keyof typeof InsuranceCategory];


/**
 * Listing of the insured years of a family
 * @export
 * @interface InsuredYears
 */
export interface InsuredYears {
    /**
     * 
     * @type {number}
     * @memberof InsuredYears
     */
    'year'?: number;
    /**
     * The insuredNrs of the users for that year
     * @type {Array<number>}
     * @memberof InsuredYears
     */
    'insuredNrs'?: Array<number>;
}
/**
 * Category of the invoice, should be used to identify the object, as different categories contain different fields, they are to be handled differently
 * @export
 * @enum {string}
 */

export const InvoiceCategory = {
    Medical: 'MEDICAL',
    Benefit: 'BENEFIT',
    MedicalAndBenefit: 'MEDICAL_AND_BENEFIT',
    Premium: 'PREMIUM',
    All: 'ALL'
} as const;

export type InvoiceCategory = typeof InvoiceCategory[keyof typeof InvoiceCategory];


/**
 * 
 * @export
 * @enum {string}
 */

export const InvoiceGroup = {
    Year: 'YEAR',
    YearMonth: 'YEAR_MONTH'
} as const;

export type InvoiceGroup = typeof InvoiceGroup[keyof typeof InvoiceGroup];


/**
 * The pay type of the invoice, if the insured receives a payback for a benefit or if they need to pay for it
 * @export
 * @enum {string}
 */

export const InvoicePayType = {
    Payment: 'PAYMENT',
    Payback: 'PAYBACK'
} as const;

export type InvoicePayType = typeof InvoicePayType[keyof typeof InvoicePayType];


/**
 * General invoice status of the different invoices.<br> PremiumInvoices can be OPEN or DONE, depending if the invoice is paid.<br> BenefitInvoices can be OPEN or DONE, depending if the invoice is paid.<br> MergedInvoices can be OPEN or DONE, depending if the invoice is paid.<br> MedicalInvoices can be IN_PROGRESS, REJECTED or DONE.<br> The ALL value is only for filtering on the endpoints. 
 * @export
 * @enum {string}
 */

export const InvoiceStatus = {
    Open: 'OPEN',
    InProgress: 'IN_PROGRESS',
    Rejected: 'REJECTED',
    Done: 'DONE',
    All: 'ALL'
} as const;

export type InvoiceStatus = typeof InvoiceStatus[keyof typeof InvoiceStatus];


/**
 * Correspondence language of the family / account
 * @export
 * @enum {string}
 */

export const Language = {
    De: 'de',
    Fr: 'fr',
    It: 'it'
} as const;

export type Language = typeof Language[keyof typeof Language];


/**
 * Request to change / update the login information; at least one of the two values must be set for a valid request.
 * @export
 * @interface LoginInformationChangeRequest
 */
export interface LoginInformationChangeRequest {
    /**
     * The new login e-mail address
     * @type {string}
     * @memberof LoginInformationChangeRequest
     */
    'email'?: string;
    /**
     * The new mobile phone number used in the login, this number will receive the 2FA token.<br> The number must be in the E.164 format (international number without spaces).<br> The core systems require the phone number of pure numeric characters.<br> So the \'+\' should be replaced with \'00\' (the dial out code used in Switzerland).  <b>Note:</b> The interface also accepts the standard E.164 number, it will transform it into the desired format. 
     * @type {string}
     * @memberof LoginInformationChangeRequest
     */
    'mobilePhoneNumber'?: string;
}
/**
 * Marital status of the user, displayed is the value of the enum
 * @export
 * @enum {string}
 */

export const MaritalStatus = {
    M: 'M',
    S: 'S',
    D: 'D',
    T: 'T',
    W: 'W',
    X: 'X'
} as const;

export type MaritalStatus = typeof MaritalStatus[keyof typeof MaritalStatus];


/**
 * 
 * @export
 * @interface MarketingInformationResponse
 */
export interface MarketingInformationResponse {
    /**
     * InsuredNr of the user, the number has a range from 2000000 to 2999999
     * @type {number}
     * @memberof MarketingInformationResponse
     */
    'insuredNr': number;
    /**
     * FamilyNr of the user, the number has a range from 1000000 to 1999999
     * @type {number}
     * @memberof MarketingInformationResponse
     */
    'familyNr': number;
    /**
     * First name
     * @type {string}
     * @memberof MarketingInformationResponse
     */
    'firstName': string;
    /**
     * Last name
     * @type {string}
     * @memberof MarketingInformationResponse
     */
    'lastName': string;
    /**
     * 
     * @type {Gender}
     * @memberof MarketingInformationResponse
     */
    'gender': Gender;
    /**
     * Age
     * @type {number}
     * @memberof MarketingInformationResponse
     */
    'age': number;
    /**
     * 
     * @type {MaritalStatus}
     * @memberof MarketingInformationResponse
     */
    'maritalStatus': MaritalStatus;
    /**
     * 
     * @type {Language}
     * @memberof MarketingInformationResponse
     */
    'language': Language;
    /**
     * True if the user is part of a family
     * @type {boolean}
     * @memberof MarketingInformationResponse
     */
    'isFamily': boolean;
    /**
     * 
     * @type {Canton}
     * @memberof MarketingInformationResponse
     */
    'canton': Canton;
    /**
     * 
     * @type {ProductModel}
     * @memberof MarketingInformationResponse
     */
    'model': ProductModel;
    /**
     * CSV of the product-codes the user employs
     * @type {string}
     * @memberof MarketingInformationResponse
     */
    'products': string;
    /**
     * currently selected franchise for the user
     * @type {number}
     * @memberof MarketingInformationResponse
     */
    'franchise': number;
    /**
     * tariff / premium region where the user resides
     * @type {string}
     * @memberof MarketingInformationResponse
     */
    'okpPremiumRegion': string;
    /**
     * true if the user is part of a collective insurance
     * @type {boolean}
     * @memberof MarketingInformationResponse
     */
    'isCollectiveInsured': boolean;
    /**
     * true if the user has not paid his invoices at least once and reached level: \'Gesetzliche Mahnung\'
     * @type {boolean}
     * @memberof MarketingInformationResponse
     */
    'wasEverInDunning': boolean;
    /**
     * null or the number of months the user has not been in dunning, since the last dunning was reached
     * @type {number}
     * @memberof MarketingInformationResponse
     */
    'wasNotInDunningDuration'?: number | null;
}
/**
 * Medical Contact for a user, usually a doctor or other medical facility
 * @export
 * @interface MedicalContact
 */
export interface MedicalContact {
    /**
     * 
     * @type {ContactCategory}
     * @memberof MedicalContact
     */
    'category': ContactCategory;
    /**
     * URGENCY has no name, is null
     * @type {string}
     * @memberof MedicalContact
     */
    'name'?: string | null;
    /**
     * Email of the contact, URGENCY and MEDGATE have none, are null
     * @type {string}
     * @memberof MedicalContact
     */
    'email'?: string | null;
    /**
     * phone of the contact, MEDGATE has none, is null
     * @type {string}
     * @memberof MedicalContact
     */
    'phoneNumber'?: string | null;
    /**
     * web link of the contact, URGENCY, SERVICE_CENTER and MEDICAL_PROVIDER have none, are null
     * @type {string}
     * @memberof MedicalContact
     */
    'url'?: string | null;
    /**
     * Id of the medical contact, if the user has one registered
     * @type {string}
     * @memberof MedicalContact
     */
    'concordantNr': string;
    /**
     * 
     * @type {Address}
     * @memberof MedicalContact
     */
    'address': Address;
}
/**
 * 
 * @export
 * @interface MedicalContactAllOf
 */
export interface MedicalContactAllOf {
    /**
     * Id of the medical contact, if the user has one registered
     * @type {string}
     * @memberof MedicalContactAllOf
     */
    'concordantNr'?: string;
    /**
     * 
     * @type {Address}
     * @memberof MedicalContactAllOf
     */
    'address'?: Address;
}
/**
 * Contains all available contacts of the user.<br>The contacts are sorted and ordered according to the priority of the category, low -> high.<br>Users with the SmartCare (SMC) model, the MEDGATE contact has priority, it is the first contact in the list.<br>The SUPPORT, URGENCY, SERVICE_CENTER and MEDGATE are always present.<br>The MEDICAL_PROVIDER contact is optional and not every user has one registered.<br>FLC or TEL contact is only present if the user\'s model is the respective model
 * @export
 * @interface MedicalContactsResponse
 */
export interface MedicalContactsResponse {
    /**
     * InsuredNr of the user, the number has a range from 2000000 to 2999999
     * @type {number}
     * @memberof MedicalContactsResponse
     */
    'insuredNr': number;
    /**
     * List containing the contacts of a user
     * @type {Array<MedicalContactsResponseContactsInner>}
     * @memberof MedicalContactsResponse
     */
    'contacts': Array<MedicalContactsResponseContactsInner>;
}
/**
 * @type MedicalContactsResponseContactsInner
 * @export
 */
export type MedicalContactsResponseContactsInner = Contact | MedicalContact;

/**
 * 
 * @export
 * @interface MedicalInvoice
 */
export interface MedicalInvoice {
    /**
     * 
     * @type {InvoiceCategory}
     * @memberof MedicalInvoice
     */
    'invoiceCategory': InvoiceCategory;
    /**
     * invoiceDate (Rechnungsdatum), the date the invoice was written. Not to be confused with the dueDate (Faelligkeitsdatum)
     * @type {string}
     * @memberof MedicalInvoice
     */
    'invoiceDate': string;
    /**
     * 
     * @type {InvoiceStatus}
     * @memberof MedicalInvoice
     */
    'invoiceStatus': InvoiceStatus;
    /**
     * Title of the document connected to this invoice
     * @type {string}
     * @memberof MedicalInvoice
     */
    'documentTitle'?: string | null;
    /**
     * InsuredNr of the user, the number has a range from 2000000 to 2999999
     * @type {number}
     * @memberof MedicalInvoice
     */
    'insuredNr'?: number;
    /**
     * Generated Task id for the invoice when it is registered by the CRM system
     * @type {string}
     * @memberof MedicalInvoice
     */
    'medicalTaskId': string;
    /**
     * last time an update of the invoice was recorded
     * @type {string}
     * @memberof MedicalInvoice
     */
    'lastStatusUpdate': string;
    /**
     * Creation date and time of the invoice
     * @type {string}
     * @memberof MedicalInvoice
     */
    'creationDateTime': string;
    /**
     * List with documentIds related to a CRM task.
     * @type {Array<string>}
     * @memberof MedicalInvoice
     * @deprecated
     */
    'documentIds': Array<string>;
    /**
     * List with id and name of documents related to a CRM task.
     * @type {Array<MetaInvoiceDocument>}
     * @memberof MedicalInvoice
     */
    'metaInvoiceDocuments': Array<MetaInvoiceDocument>;
}
/**
 * The two invoice types MEDICAL and BENEFIT merged into one type, where the taskId (BENEFIT) matches the medicalTaskId (MEDICAL)
 * @export
 * @interface MergedInvoice
 */
export interface MergedInvoice {
    /**
     * 
     * @type {InvoiceCategory}
     * @memberof MergedInvoice
     */
    'invoiceCategory': InvoiceCategory;
    /**
     * invoiceDate (Rechnungsdatum), the date the invoice was written. Not to be confused with the dueDate (Faelligkeitsdatum)
     * @type {string}
     * @memberof MergedInvoice
     */
    'invoiceDate': string;
    /**
     * 
     * @type {InvoiceStatus}
     * @memberof MergedInvoice
     */
    'invoiceStatus': InvoiceStatus;
    /**
     * Title of the document connected to this invoice
     * @type {string}
     * @memberof MergedInvoice
     */
    'documentTitle'?: string | null;
    /**
     * InsuredNr of the user, the number has a range from 2000000 to 2999999
     * @type {number}
     * @memberof MergedInvoice
     */
    'insuredNr'?: number;
    /**
     * An unique id for the invoice; normally one or two letters defining the kind and followed by hyphenated digits of various length.
     * @type {string}
     * @memberof MergedInvoice
     */
    'invoiceNr': string;
    /**
     * Generated Task id for the invoice when it is registered by the CRM system
     * @type {string}
     * @memberof MergedInvoice
     */
    'medicalTaskId': string;
    /**
     * Id for the tarmed documents created and processed by the Sumex system (external).
     * @type {string}
     * @memberof MergedInvoice
     */
    'sumexOriginId'?: string | null;
    /**
     * Generated Task id by the CRM system
     * @type {string}
     * @memberof MergedInvoice
     */
    'taskId'?: string | null;
    /**
     * The total amount of the billed invoice. Can be negative, a negative amount indicates that the insured receives a payback.
     * @type {number}
     * @memberof MergedInvoice
     */
    'amount': number;
    /**
     * The amount left to pay
     * @type {number}
     * @memberof MergedInvoice
     */
    'amountOpen': number;
    /**
     * The amount paid, it is a negative value if the insured receives a payback.
     * @type {number}
     * @memberof MergedInvoice
     */
    'amountPayed': number;
    /**
     * The part of the benefit paid by Atupri. There is a difference between the paid value and the billed \'amount\', as depending on the pay type, InvoicePayType: PAYBACK, the \'amount\' matches \'payedByAtupri\' as negative value.
     * @type {number}
     * @memberof MergedInvoice
     */
    'payedByAtupri': number;
    /**
     * The part of the benefit paid by the insured. There is a difference between the paid value and the billed \'amount\', as depending on the pay type, InvoicePayType: PAYMENT, the \'amount\' matches \'payedByInsured\'.
     * @type {number}
     * @memberof MergedInvoice
     */
    'payedByInsured': number;
    /**
     * Faktura-Code generated from Siddhartha for the invoice
     * @type {number}
     * @memberof MergedInvoice
     */
    'customInvoiceCode': number;
    /**
     * Due date of the invoice
     * @type {string}
     * @memberof MergedInvoice
     */
    'dueDate': string;
    /**
     * Name of the medical provider (doctor, pharmacy, ...) if there is one for the invoice
     * @type {string}
     * @memberof MergedInvoice
     */
    'medicalProviderName': string;
    /**
     * 
     * @type {TaskStatus}
     * @memberof MergedInvoice
     */
    'benefitStatus': TaskStatus;
    /**
     * Creation date and time of the invoice
     * @type {string}
     * @memberof MergedInvoice
     */
    'creationDateTime': string;
    /**
     * last time an update of the invoice was recorded
     * @type {string}
     * @memberof MergedInvoice
     */
    'lastStatusUpdate': string;
    /**
     * List with documentIds related to a CRM task.
     * @type {Array<string>}
     * @memberof MergedInvoice
     * @deprecated
     */
    'documentIds': Array<string>;
    /**
     * List with id and name of documents related to a CRM task.
     * @type {Array<MetaInvoiceDocument>}
     * @memberof MergedInvoice
     */
    'metaInvoiceDocuments': Array<MetaInvoiceDocument>;
    /**
     * Total costs of the benefit case
     * @type {number}
     * @memberof MergedInvoice
     */
    'totalCosts': number;
    /**
     * 
     * @type {InvoicePayType}
     * @memberof MergedInvoice
     */
    'invoicePayType': InvoicePayType;
}
/**
 * Contains a list of messages sorted by taskId or gevoId
 * @export
 * @interface Message
 */
export interface Message {
    /**
     * Unique ID of the message \\<Code of generating system\\> & \\<ID\\>
     * @type {string}
     * @memberof Message
     */
    'messageId': string;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    'requestType': MessageRequestTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    'language'?: MessageLanguageEnum;
    /**
     * Content of the message. If a message template is defined, then the content from the template will be returned in the selected language.
     * @type {string}
     * @memberof Message
     */
    'messageContent': string;
    /**
     * Creation date and time of the message. Format: ISO 8601
     * @type {string}
     * @memberof Message
     */
    'creationDate'?: string;
    /**
     * The insured person number is also transmitted in the technical attribute.
     * @type {number}
     * @memberof Message
     */
    'insuredId'?: number;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    'generatingSystem': MessageGeneratingSystemEnum;
    /**
     * Reference to the taskobject in CRM (may have more than one GEVO)
     * @type {string}
     * @memberof Message
     */
    'taskId'?: string;
    /**
     * Reference to the Process in CRM
     * @type {string}
     * @memberof Message
     */
    'gevoId'?: string;
    /**
     * virtual team which is in lead of this message/communication
     * @type {string}
     * @memberof Message
     */
    'leadTeam'?: string;
    /**
     * Flag that indicates if the message was read
     * @type {boolean}
     * @memberof Message
     */
    'read'?: boolean;
    /**
     * Flag that indicates if the message was logically deleted
     * @type {boolean}
     * @memberof Message
     */
    'deleted'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof Message
     */
    'documents'?: Array<string>;
}

export const MessageRequestTypeEnum = {
    AccidentChange: 'ACCIDENT_CHANGE',
    Accounts: 'ACCOUNTS',
    AddressChange: 'ADDRESS_CHANGE',
    Benefit: 'BENEFIT',
    BenefitMaost: 'BENEFIT_MAOST',
    Dunning: 'DUNNING',
    FranchiseChange: 'FRANCHISE_CHANGE',
    HealthQuestions: 'HEALTH_QUESTIONS',
    InsurantModelChange: 'INSURANT_MODEL_CHANGE',
    LetterIncludeAccident: 'LETTER_INCLUDE_ACCIDENT',
    OrderInsuranceCard: 'ORDER_INSURANCE_CARD',
    Other: 'OTHER',
    PaymentFrequencyChange: 'PAYMENT_FREQUENCY_CHANGE',
    Police: 'POLICE',
    Premium: 'PREMIUM',
    PremiumMaost: 'PREMIUM_MAOST',
    ProfileDataChange: 'PROFILE_DATA_CHANGE',
    TaxCertificate: 'TAX_CERTIFICATE',
    UploadedInvoice: 'UPLOADED_INVOICE',
    VvgChange: 'VVG_CHANGE',
    MisroutedCasemanagement: 'MISROUTED_CASEMANAGEMENT'
} as const;

export type MessageRequestTypeEnum = typeof MessageRequestTypeEnum[keyof typeof MessageRequestTypeEnum];
export const MessageLanguageEnum = {
    En: 'EN',
    De: 'DE',
    It: 'IT',
    Fr: 'FR'
} as const;

export type MessageLanguageEnum = typeof MessageLanguageEnum[keyof typeof MessageLanguageEnum];
export const MessageGeneratingSystemEnum = {
    Dms: 'DMS',
    Crm: 'CRM',
    Erp: 'ERP',
    Kbm: 'KBM',
    Mya: 'MYA',
    False: 'false',
    Smx: 'SMX'
} as const;

export type MessageGeneratingSystemEnum = typeof MessageGeneratingSystemEnum[keyof typeof MessageGeneratingSystemEnum];

/**
 * contains the list with the requested entries
 * @export
 * @interface MessageGroup
 */
export interface MessageGroup {
    /**
     * 
     * @type {Message}
     * @memberof MessageGroup
     */
    'latestMessage': Message;
    /**
     * Contains a list of messages sorted by taskId or gevoId
     * @type {Array<Message>}
     * @memberof MessageGroup
     */
    'messages': Array<Message>;
}
/**
 * List with id and name of documents related to a CRM task.
 * @export
 * @interface MetaInvoiceDocument
 */
export interface MetaInvoiceDocument {
    /**
     * 
     * @type {string}
     * @memberof MetaInvoiceDocument
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof MetaInvoiceDocument
     */
    'name': string;
}
/**
 * The payment methods for the different kind of invoices
 * @export
 * @interface MethodPaymentMethod
 */
export interface MethodPaymentMethod {
    /**
     * 
     * @type {PaymentMethod}
     * @memberof MethodPaymentMethod
     */
    'benefit'?: PaymentMethod;
    /**
     * 
     * @type {PaymentMethod}
     * @memberof MethodPaymentMethod
     */
    'premium'?: PaymentMethod;
}
/**
 * The refund methods for the different kind of invoices
 * @export
 * @interface MethodRefundMethod
 */
export interface MethodRefundMethod {
    /**
     * 
     * @type {RefundMethod}
     * @memberof MethodRefundMethod
     */
    'benefit'?: RefundMethod;
    /**
     * 
     * @type {RefundMethod}
     * @memberof MethodRefundMethod
     */
    'premium'?: RefundMethod;
}
/**
 * 
 * @export
 * @interface NotificationObject
 */
export interface NotificationObject {
    /**
     * notification via mail requested
     * @type {boolean}
     * @memberof NotificationObject
     */
    'mail'?: boolean;
    /**
     * notification via push requested
     * @type {boolean}
     * @memberof NotificationObject
     */
    'push'?: boolean;
}
/**
 * 
 * @export
 * @interface NotificationSettings
 */
export interface NotificationSettings {
    /**
     * 
     * @type {NotificationObject}
     * @memberof NotificationSettings
     */
    'personalDataMutation'?: NotificationObject;
    /**
     * 
     * @type {NotificationObject}
     * @memberof NotificationSettings
     */
    'benefit'?: NotificationObject;
    /**
     * 
     * @type {NotificationObject}
     * @memberof NotificationSettings
     */
    'serviceCenterMessage'?: NotificationObject;
    /**
     * 
     * @type {NotificationObject}
     * @memberof NotificationSettings
     */
    'premium'?: NotificationObject;
    /**
     * 
     * @type {NotificationObject}
     * @memberof NotificationSettings
     */
    'taxCertificate'?: NotificationObject;
    /**
     * 
     * @type {NotificationObject}
     * @memberof NotificationSettings
     */
    'police'?: NotificationObject;
    /**
     * 
     * @type {NotificationObject}
     * @memberof NotificationSettings
     */
    'dunning'?: NotificationObject;
}
/**
 * 
 * @export
 * @interface NotificationSettingsResponse
 */
export interface NotificationSettingsResponse {
    /**
     * 
     * @type {NotificationObject}
     * @memberof NotificationSettingsResponse
     */
    'personalDataMutation'?: NotificationObject;
    /**
     * 
     * @type {NotificationObject}
     * @memberof NotificationSettingsResponse
     */
    'benefit'?: NotificationObject;
    /**
     * 
     * @type {NotificationObject}
     * @memberof NotificationSettingsResponse
     */
    'serviceCenterMessage'?: NotificationObject;
    /**
     * 
     * @type {NotificationObject}
     * @memberof NotificationSettingsResponse
     */
    'premium'?: NotificationObject;
    /**
     * 
     * @type {NotificationObject}
     * @memberof NotificationSettingsResponse
     */
    'taxCertificate'?: NotificationObject;
    /**
     * 
     * @type {NotificationObject}
     * @memberof NotificationSettingsResponse
     */
    'police'?: NotificationObject;
    /**
     * 
     * @type {NotificationObject}
     * @memberof NotificationSettingsResponse
     */
    'dunning'?: NotificationObject;
    /**
     * Unique id of the client device
     * @type {string}
     * @memberof NotificationSettingsResponse
     */
    'deviceId'?: string;
}
/**
 * 
 * @export
 * @interface PageResponseMessageGroup
 */
export interface PageResponseMessageGroup {
    /**
     * contains the list with the requested entries
     * @type {Array<MessageGroup>}
     * @memberof PageResponseMessageGroup
     */
    'content'?: Array<MessageGroup>;
    /**
     * true if the page is the first one in the series
     * @type {boolean}
     * @memberof PageResponseMessageGroup
     */
    'first'?: boolean;
    /**
     * true if the page is the last one in the series
     * @type {boolean}
     * @memberof PageResponseMessageGroup
     */
    'last'?: boolean;
    /**
     * Current total amount of pages
     * @type {number}
     * @memberof PageResponseMessageGroup
     */
    'totalPages'?: number;
    /**
     * Index of the current page. Page index always starts at 1
     * @type {number}
     * @memberof PageResponseMessageGroup
     */
    'page'?: number;
    /**
     * Total amount of entries
     * @type {number}
     * @memberof PageResponseMessageGroup
     */
    'totalEntries'?: number;
    /**
     * Amount of entries in the current page
     * @type {number}
     * @memberof PageResponseMessageGroup
     */
    'entries'?: number;
    /**
     * Possible maximum amount of entries per page
     * @type {number}
     * @memberof PageResponseMessageGroup
     */
    'size'?: number;
}
/**
 * 
 * @export
 * @interface PasswordChangeRequest
 */
export interface PasswordChangeRequest {
    /**
     * The new password
     * @type {string}
     * @memberof PasswordChangeRequest
     */
    'password': string;
    /**
     * Confirmation for the password field
     * @type {string}
     * @memberof PasswordChangeRequest
     */
    'confirmation': string;
    /**
     * Set the timestamp to zero for the one time password during first registration
     * @type {boolean}
     * @memberof PasswordChangeRequest
     */
    'performFirstRegistration'?: boolean;
}
/**
 * Returns the first name, last name and insurance number for a user, so a new password can be validated against it
 * @export
 * @interface PasswordInformationResponse
 */
export interface PasswordInformationResponse {
    /**
     * InsuredNr of the user, the number has a range from 2000000 to 2999999
     * @type {number}
     * @memberof PasswordInformationResponse
     */
    'insuredNr': number;
    /**
     * First name
     * @type {string}
     * @memberof PasswordInformationResponse
     */
    'firstName': string;
    /**
     * Last name
     * @type {string}
     * @memberof PasswordInformationResponse
     */
    'lastName': string;
}
/**
 * Error response thrown during the password complexity validation
 * @export
 * @interface PasswordValidationExceptionResponse
 */
export interface PasswordValidationExceptionResponse {
    /**
     * Error message
     * @type {string}
     * @memberof PasswordValidationExceptionResponse
     */
    'message': string;
    /**
     * Debug message, not available in production
     * @type {string}
     * @memberof PasswordValidationExceptionResponse
     */
    'debugMessage': string;
    /**
     * HTTP status code
     * @type {number}
     * @memberof PasswordValidationExceptionResponse
     */
    'status': number;
    /**
     * HTTP status text
     * @type {string}
     * @memberof PasswordValidationExceptionResponse
     */
    'code': string;
    /**
     * Request id issued by the SES, can be used to trace the request through the systems. It maybe an empty string, if the SES didn\'t provide the id or if the request didn\'t go through the SES i.g on the internal *.atuprinet.ch domains.
     * @type {string}
     * @memberof PasswordValidationExceptionResponse
     */
    'requestCorrelator': string;
    /**
     * Date and time when the error occurred
     * @type {string}
     * @memberof PasswordValidationExceptionResponse
     */
    'timestamp': string;
    /**
     * The \'errors\' map will contain the violations with static keys and an English description of the violation as value.
     * @type {{ [key: string]: string; }}
     * @memberof PasswordValidationExceptionResponse
     */
    'errors': { [key: string]: string; };
}
/**
 * Payment details of the account
 * @export
 * @interface PaymentDetailsResponse
 */
export interface PaymentDetailsResponse {
    /**
     * The iban of the family account, can be null
     * @type {string}
     * @memberof PaymentDetailsResponse
     */
    'iban': string | null;
    /**
     * The account holder of the family account, can be null
     * @type {string}
     * @memberof PaymentDetailsResponse
     */
    'accountHolder': string | null;
    /**
     * 
     * @type {PaymentFrequency}
     * @memberof PaymentDetailsResponse
     */
    'paymentFrequency': PaymentFrequency;
    /**
     * 
     * @type {MethodPaymentMethod}
     * @memberof PaymentDetailsResponse
     */
    'paymentMethods': MethodPaymentMethod;
    /**
     * 
     * @type {MethodRefundMethod}
     * @memberof PaymentDetailsResponse
     */
    'refundMethods': MethodRefundMethod;
}
/**
 * The payment frequency for the coverage invoices family/account
 * @export
 * @enum {string}
 */

export const PaymentFrequency = {
    Monthly: 'MONTHLY',
    Bimonthly: 'BIMONTHLY',
    Quarterly: 'QUARTERLY',
    HalfYearly: 'HALF_YEARLY',
    Yearly: 'YEARLY'
} as const;

export type PaymentFrequency = typeof PaymentFrequency[keyof typeof PaymentFrequency];


/**
 * Response containing the next possible start dates (YYYY-MM-DD) for the different payment frequencies. The current frequency will always be an empty list, can\'t change to the currently used one. If we allow an immediate change the list for that frequency will contain the current date. That value will be used for an immediate change of the frequency.
 * @export
 * @interface PaymentFrequencyChangeDatesResponse
 */
export interface PaymentFrequencyChangeDatesResponse {
    /**
     * possible dates for the MONTHLY frequency
     * @type {Array<string>}
     * @memberof PaymentFrequencyChangeDatesResponse
     */
    'monthly'?: Array<string>;
    /**
     * possible dates for the QUARTERLY frequency
     * @type {Array<string>}
     * @memberof PaymentFrequencyChangeDatesResponse
     */
    'quarterly'?: Array<string>;
    /**
     * possible dates for the HALF_YEARLY frequency
     * @type {Array<string>}
     * @memberof PaymentFrequencyChangeDatesResponse
     */
    'halfYearly'?: Array<string>;
    /**
     * possible dates for the YEARLY frequency
     * @type {Array<string>}
     * @memberof PaymentFrequencyChangeDatesResponse
     */
    'yearly'?: Array<string>;
}
/**
 * Request to change the family\'s payment frequency. Use the /api/account/possible-payment-frequency-change-dates endpoint to get the correct \'fromDate\' for the new frequency.
 * @export
 * @interface PaymentFrequencyChangeRequest
 */
export interface PaymentFrequencyChangeRequest {
    /**
     * The new payment frequency
     * @type {string}
     * @memberof PaymentFrequencyChangeRequest
     */
    'paymentFrequency': PaymentFrequencyChangeRequestPaymentFrequencyEnum;
    /**
     * ISO date from which the frequency change is valid.
     * @type {string}
     * @memberof PaymentFrequencyChangeRequest
     */
    'fromDate': string;
}

export const PaymentFrequencyChangeRequestPaymentFrequencyEnum = {
    Monthly: 'MONTHLY',
    Quarterly: 'QUARTERLY',
    HalfYearly: 'HALF_YEARLY',
    Yearly: 'YEARLY'
} as const;

export type PaymentFrequencyChangeRequestPaymentFrequencyEnum = typeof PaymentFrequencyChangeRequestPaymentFrequencyEnum[keyof typeof PaymentFrequencyChangeRequestPaymentFrequencyEnum];

/**
 * 
 * @export
 * @interface PaymentFrequencyChangeResponse
 */
export interface PaymentFrequencyChangeResponse {
    /**
     * 
     * @type {string}
     * @memberof PaymentFrequencyChangeResponse
     */
    'status'?: PaymentFrequencyChangeResponseStatusEnum;
}

export const PaymentFrequencyChangeResponseStatusEnum = {
    Automated: 'AUTOMATED',
    RequestCreated: 'REQUEST_CREATED'
} as const;

export type PaymentFrequencyChangeResponseStatusEnum = typeof PaymentFrequencyChangeResponseStatusEnum[keyof typeof PaymentFrequencyChangeResponseStatusEnum];

/**
 * The available payment methods for premiums and benefit statements that customers can choose. - LSV - Payment via LSV / Direct Debit - LSV_CRYPTOCURRENCY - Payment via LSV / DD  with cryptocurrency alternative - LSV_WITH_E_BILL_NOTIFICATION - Payment via LSV / DD and notification via eBill - PAYMENT_SLIP - Invoice via payment slip - PAYMENT_SLIP_CRYPTOCURRENCY - Invoice via payment slip with cryptocurrency alternative. - E_BILL - Payment via eBill of the bank or Post - E_BILL_CRYPTOCURRENCY - Payment via eBill of the bank or Post with cryptocurrency alternative. 
 * @export
 * @enum {string}
 */

export const PaymentMethod = {
    EBill: 'E_BILL',
    EBillCryptocurrency: 'E_BILL_CRYPTOCURRENCY',
    Lsv: 'LSV',
    LsvCryptocurrency: 'LSV_CRYPTOCURRENCY',
    LsvWithEBillNotification: 'LSV_WITH_E_BILL_NOTIFICATION',
    PaymentSlip: 'PAYMENT_SLIP',
    PaymentSlipCryptocurrency: 'PAYMENT_SLIP_CRYPTOCURRENCY'
} as const;

export type PaymentMethod = typeof PaymentMethod[keyof typeof PaymentMethod];


/**
 * Request payload to update the family\'s payment methods, at least one field must be set.
 * @export
 * @interface PaymentMethodChangeRequest
 */
export interface PaymentMethodChangeRequest {
    /**
     * The new premium payment method.
     * @type {string}
     * @memberof PaymentMethodChangeRequest
     */
    'premium'?: PaymentMethodChangeRequestPremiumEnum;
    /**
     * The new benefit payment method.
     * @type {string}
     * @memberof PaymentMethodChangeRequest
     */
    'benefit'?: PaymentMethodChangeRequestBenefitEnum;
}

export const PaymentMethodChangeRequestPremiumEnum = {
    EBill: 'E_BILL',
    EBillCryptocurrency: 'E_BILL_CRYPTOCURRENCY',
    Lsv: 'LSV',
    LsvCryptocurrency: 'LSV_CRYPTOCURRENCY',
    PaymentSlip: 'PAYMENT_SLIP',
    PaymentSlipCryptocurrency: 'PAYMENT_SLIP_CRYPTOCURRENCY'
} as const;

export type PaymentMethodChangeRequestPremiumEnum = typeof PaymentMethodChangeRequestPremiumEnum[keyof typeof PaymentMethodChangeRequestPremiumEnum];
export const PaymentMethodChangeRequestBenefitEnum = {
    EBill: 'E_BILL',
    EBillCryptocurrency: 'E_BILL_CRYPTOCURRENCY',
    Lsv: 'LSV',
    LsvCryptocurrency: 'LSV_CRYPTOCURRENCY',
    PaymentSlip: 'PAYMENT_SLIP',
    PaymentSlipCryptocurrency: 'PAYMENT_SLIP_CRYPTOCURRENCY'
} as const;

export type PaymentMethodChangeRequestBenefitEnum = typeof PaymentMethodChangeRequestBenefitEnum[keyof typeof PaymentMethodChangeRequestBenefitEnum];

/**
 * On a COMPLEX_CHANGE for the LSV method, a PDF will be present on the \'document\' field that the user must fill out and send back to Atupri via mail.
 * @export
 * @interface PaymentMethodChangeResponse
 */
export interface PaymentMethodChangeResponse {
    /**
     * The result status for the change request.
     * @type {string}
     * @memberof PaymentMethodChangeResponse
     */
    'status': PaymentMethodChangeResponseStatusEnum;
    /**
     * 
     * @type {Document}
     * @memberof PaymentMethodChangeResponse
     */
    'document': Document;
}

export const PaymentMethodChangeResponseStatusEnum = {
    Changed: 'CHANGED',
    ComplexChange: 'COMPLEX_CHANGE'
} as const;

export type PaymentMethodChangeResponseStatusEnum = typeof PaymentMethodChangeResponseStatusEnum[keyof typeof PaymentMethodChangeResponseStatusEnum];

/**
 * The pages as image of the simplified Tarmed pdf
 * @export
 * @interface PdfPageAsImage
 */
export interface PdfPageAsImage {
    /**
     * Name of the document, can contain the file ending
     * @type {string}
     * @memberof PdfPageAsImage
     */
    'name': string;
    /**
     * Optional field. If the mimeType field is omitted the API guesses the type from the content. Allowed types: application/pdf, image/jpeg, image/png, image/tiff, image/pjpeg
     * @type {string}
     * @memberof PdfPageAsImage
     */
    'mimeType'?: string;
    /**
     * Base64 encoded file, pdf or image
     * @type {string}
     * @memberof PdfPageAsImage
     */
    'content': string;
    /**
     * with of the image in px
     * @type {number}
     * @memberof PdfPageAsImage
     */
    'width': number;
    /**
     * height of the image in px
     * @type {number}
     * @memberof PdfPageAsImage
     */
    'height': number;
    /**
     * page of the pdf that this image represents
     * @type {number}
     * @memberof PdfPageAsImage
     */
    'page': number;
}
/**
 * A list with the open pending changes in the CRM that affect the whole family. Note that currently this list only indicates that there is a pending change open that needs to be processed by an SC employee. Once that CRM task is closed the entry for that change will vanish from the list. It doesn\'t matter if the change is active today or in one month. 
 * @export
 * @interface PendingFamilyChange
 */
export interface PendingFamilyChange {
    /**
     * 
     * @type {ChangeCategory}
     * @memberof PendingFamilyChange
     */
    'category'?: ChangeCategory;
}
/**
 * A list with the open tasks for pending changes in the CRM for that user. Note that currently this list only indicates that there is a pending change open that needs to be processed by an SC employee. Once that CRM tasks is closed the entry for that change will vanish form the list. It doesn\'t matter if the change is active today or in one month. 
 * @export
 * @interface PendingUserChange
 */
export interface PendingUserChange {
    /**
     * 
     * @type {ChangeCategory}
     * @memberof PendingUserChange
     */
    'category'?: ChangeCategory;
}
/**
 * 
 * @export
 * @interface PremiumInvoice
 */
export interface PremiumInvoice {
    /**
     * 
     * @type {InvoiceCategory}
     * @memberof PremiumInvoice
     */
    'invoiceCategory': InvoiceCategory;
    /**
     * invoiceDate (Rechnungsdatum), the date the invoice was written. Not to be confused with the dueDate (Faelligkeitsdatum)
     * @type {string}
     * @memberof PremiumInvoice
     */
    'invoiceDate': string;
    /**
     * 
     * @type {InvoiceStatus}
     * @memberof PremiumInvoice
     */
    'invoiceStatus': InvoiceStatus;
    /**
     * Title of the document connected to this invoice
     * @type {string}
     * @memberof PremiumInvoice
     */
    'documentTitle'?: string | null;
    /**
     * InsuredNr of the user, the number has a range from 2000000 to 2999999
     * @type {number}
     * @memberof PremiumInvoice
     */
    'insuredNr'?: number;
    /**
     * An unique id for the premium invoice, normally two letters defining the kind, PF- / PV- , followed by hyphenated digits of various length. - PF-0987-0987 - standard invoice, document is available as PDF - PV-4321-4321 - invoice of a VIP customer, document isn\'t available as PDF 
     * @type {string}
     * @memberof PremiumInvoice
     */
    'invoiceNr': string;
    /**
     * The total amount of the billed invoice. Can be negative, a negative amount indicates that the insured receives a payback.
     * @type {number}
     * @memberof PremiumInvoice
     */
    'amount': number;
    /**
     * The amount left to pay
     * @type {number}
     * @memberof PremiumInvoice
     */
    'amountOpen': number;
    /**
     * The amount paid, it is a negative value if the insured receives a payback.
     * @type {number}
     * @memberof PremiumInvoice
     */
    'amountPayed': number;
    /**
     * Faktura-Code generated from Siddhartha for the invoice
     * @type {number}
     * @memberof PremiumInvoice
     */
    'customInvoiceCode': number;
    /**
     * Due date of the invoice
     * @type {string}
     * @memberof PremiumInvoice
     */
    'dueDate': string;
    /**
     * bank account number
     * @type {string}
     * @memberof PremiumInvoice
     */
    'paySlipAccount': string;
    /**
     * payment slip reference number
     * @type {string}
     * @memberof PremiumInvoice
     */
    'paySlipReference': string;
    /**
     * Beginning of the active period of the premium for this invoice
     * @type {string}
     * @memberof PremiumInvoice
     */
    'fromDate': string;
    /**
     * End of the active period of the premium for this invoice
     * @type {string}
     * @memberof PremiumInvoice
     */
    'toDate': string;
    /**
     * True if the invoice document is available as PDF, else false.<br> For VIP customers (Atupri Employees and some collective insured users SBB, ZBAG,...) the premium invoice isn\'t available as PDF.<br> The amount is deducted directly from the users\' contract and automatically paid.<br> Those documents can be identified by the prefix \'PV-\' of the invoiceNr. For the other type \'PF-\', the PDF is available. 
     * @type {boolean}
     * @memberof PremiumInvoice
     */
    'isDocumentAvailable': boolean;
}
/**
 * Models as available in Siddhartha.<br>**NOTE THIS LIST IS ONLY A GUIDELINE AN CAN BE INCOMPLETE**<br> Last update 26.04.21
 * @export
 * @enum {string}
 */

export const ProductModel = {
    Alg: 'ALG',
    Amb: 'AMB',
    Bafu: 'BAFU',
    Flc: 'FLC',
    Flx: 'FLX',
    Has: 'HAS',
    Hmo: 'HMO',
    Hpv: 'HPV',
    Ipv: 'IPV',
    Mix: 'MIX',
    None: 'NONE',
    Pabs: 'PABS',
    Paus: 'PAUS',
    Pruc: 'PRUC',
    Prv: 'PRV',
    Ren: 'REN',
    Smc: 'SMC',
    Spz: 'SPZ',
    Std: 'STD',
    Tel: 'TEL',
    Tkn: 'TKN',
    Tvn: 'TVN',
    Uti: 'UTI',
    Zhn: 'ZHN'
} as const;

export type ProductModel = typeof ProductModel[keyof typeof ProductModel];


/**
 * 
 * @export
 * @interface ProductOverviewResponse
 */
export interface ProductOverviewResponse {
    /**
     * User Subscribed products list without BAFU
     * @type {Array<SubscribedProductResponse>}
     * @memberof ProductOverviewResponse
     */
    'products'?: Array<SubscribedProductResponse>;
    /**
     * Yearly total of the premium of all products OKP and VVG including BAFU
     * @type {number}
     * @memberof ProductOverviewResponse
     */
    'yearlyTotal'?: number;
    /**
     * Monthly total of the premium of all products OKP and VVG including BAFU
     * @type {number}
     * @memberof ProductOverviewResponse
     */
    'monthlyTotal'?: number;
    /**
     * Customer payment frequency
     * @type {string}
     * @memberof ProductOverviewResponse
     */
    'paymentFrequency'?: ProductOverviewResponsePaymentFrequencyEnum;
}

export const ProductOverviewResponsePaymentFrequencyEnum = {
    Monthly: 'MONTHLY',
    Bimonthly: 'BIMONTHLY',
    Quarterly: 'QUARTERLY',
    HalfYearly: 'HALF_YEARLY',
    Yearly: 'YEARLY'
} as const;

export type ProductOverviewResponsePaymentFrequencyEnum = typeof ProductOverviewResponsePaymentFrequencyEnum[keyof typeof ProductOverviewResponsePaymentFrequencyEnum];

/**
 * 
 * @export
 * @interface ProfileChangeRequest
 */
export interface ProfileChangeRequest {
    /**
     * The new first name, maximum 60 characters
     * @type {string}
     * @memberof ProfileChangeRequest
     */
    'firstName'?: string;
    /**
     * The new last name, maximum 60 characters
     * @type {string}
     * @memberof ProfileChangeRequest
     */
    'lastName'?: string;
    /**
     * The new birthdate, format YYYY-MM-DD, it must be a date in the past.
     * @type {string}
     * @memberof ProfileChangeRequest
     */
    'birthdate'?: string;
    /**
     * The date from which the change is valid, it can be today or up to 6 months in the future, format YYYY-MM-DD
     * @type {string}
     * @memberof ProfileChangeRequest
     */
    'changeValidFrom': string;
    /**
     * 
     * @type {Document}
     * @memberof ProfileChangeRequest
     */
    'identityDocument': Document;
}
/**
 * The available refund methods for premiums and benefit statements. - E_BILL - Deposit via eBill of the bank or Post - LSV - Deposit via LSV / DD - LSV_WITH_E_BILL_NOTIFICATION - Deposit via LSV / DD and notification via eBill - PAYMENT_SLIP - Deposit invoice via payment slip 
 * @export
 * @enum {string}
 */

export const RefundMethod = {
    EBill: 'E_BILL',
    Lsv: 'LSV',
    LsvWithEBillNotification: 'LSV_WITH_E_BILL_NOTIFICATION',
    PaymentSlip: 'PAYMENT_SLIP'
} as const;

export type RefundMethod = typeof RefundMethod[keyof typeof RefundMethod];


/**
 * 
 * @export
 * @interface SendFeedbackRequest
 */
export interface SendFeedbackRequest {
    /**
     * The content of the feedback message
     * @type {string}
     * @memberof SendFeedbackRequest
     */
    'feedback': string;
}
/**
 * RETURNS SUCCESS IN CASE OF CORRECT DELIVERY
 * @export
 * @interface SendMailDeliveryResponse
 */
export interface SendMailDeliveryResponse {
    /**
     * The result status of sending the email
     * @type {string}
     * @memberof SendMailDeliveryResponse
     */
    'result': SendMailDeliveryResponseResultEnum;
}

export const SendMailDeliveryResponseResultEnum = {
    Success: 'SUCCESS',
    Error: 'ERROR'
} as const;

export type SendMailDeliveryResponseResultEnum = typeof SendMailDeliveryResponseResultEnum[keyof typeof SendMailDeliveryResponseResultEnum];

/**
 * 
 * @export
 * @interface SignInsuranceCardsRequest
 */
export interface SignInsuranceCardsRequest {
    /**
     * The public key of the customer, the customer\'s device. It\'s obtained through the Vicard SDK on the device.
     * @type {string}
     * @memberof SignInsuranceCardsRequest
     */
    'publicKeyOfCustomer': string;
}
/**
 * 
 * @export
 * @interface SignedInsuranceCardResponse
 */
export interface SignedInsuranceCardResponse {
    /**
     * The insured number of the user.
     * @type {number}
     * @memberof SignedInsuranceCardResponse
     */
    'insuredNr': number;
    /**
     * The insured card number
     * @type {string}
     * @memberof SignedInsuranceCardResponse
     */
    'insuredCardNr': string | null;
    /**
     * The token with the information of the signed insured card.The field can be null if that member of the family has not yet been assigned an insurance card number.
     * @type {string}
     * @memberof SignedInsuranceCardResponse
     */
    'vekaNrClaimToken': string | null;
}
/**
 * The products the user is subscribed to. Only the base insurance product will contain the franchise
 * @export
 * @interface SubscribedProductResponse
 */
export interface SubscribedProductResponse {
    /**
     * 
     * @type {CurrencyFormat}
     * @memberof SubscribedProductResponse
     */
    'franchise'?: CurrencyFormat;
    /**
     * Code of the Product
     * @type {string}
     * @memberof SubscribedProductResponse
     */
    'code': string;
    /**
     * 
     * @type {ProductModel}
     * @memberof SubscribedProductResponse
     */
    'model'?: ProductModel;
    /**
     * Tariff Id of the Product
     * @type {string}
     * @memberof SubscribedProductResponse
     */
    'tariffId': string;
    /**
     * 
     * @type {CurrencyFormat}
     * @memberof SubscribedProductResponse
     */
    'monthlyPremium': CurrencyFormat;
    /**
     * 
     * @type {CurrencyFormat}
     * @memberof SubscribedProductResponse
     */
    'monthlyNetPremium': CurrencyFormat;
}
/**
 * 
 * @export
 * @interface SubscribedProductsResponse
 */
export interface SubscribedProductsResponse {
    /**
     * insuredNr of the user
     * @type {number}
     * @memberof SubscribedProductsResponse
     */
    'insuredNr': number;
    /**
     * The products the user is subscribed to. Only the base insurance product will contain the franchise
     * @type {Array<SubscribedProductResponse>}
     * @memberof SubscribedProductsResponse
     */
    'products': Array<SubscribedProductResponse>;
}
/**
 * 
 * @export
 * @interface TarmedResponse
 */
export interface TarmedResponse {
    /**
     * List of Pages as Images of the Tarmed PDF
     * @type {Array<PdfPageAsImage>}
     * @memberof TarmedResponse
     */
    'pages': Array<PdfPageAsImage>;
    /**
     * Name of the Tarmed PDF
     * @type {string}
     * @memberof TarmedResponse
     */
    'name': string;
    /**
     * Number of Pages of the PDF / images
     * @type {number}
     * @memberof TarmedResponse
     */
    'numberOfPages': number;
}
/**
 * The internal status of a benefit statement. Only benefits with a \'public status\' are present in the lists. As the ones with an \'internal status\' are still being processed and not ready for the customer, the API will filter those out.<br> <br><br> \'internal status\': - WORK_IN_PROGRESS - RECORDED - RELEASED <br><br> \'public status\': - SETTLED - TO_BE_CANCELED - CANCELED 
 * @export
 * @enum {string}
 */

export const TaskStatus = {
    WorkInProgress: 'WORK_IN_PROGRESS',
    Recorded: 'RECORDED',
    Released: 'RELEASED',
    Settled: 'SETTLED',
    ToBeCanceled: 'TO_BE_CANCELED',
    Canceled: 'CANCELED'
} as const;

export type TaskStatus = typeof TaskStatus[keyof typeof TaskStatus];


/**
 * 
 * @export
 * @interface TranslationResponse
 */
export interface TranslationResponse {
    /**
     * The translation itself
     * @type {string}
     * @memberof TranslationResponse
     */
    'translation': string;
}
/**
 * The different flows depending on the user or change type. - STANDARD - not logged in user flow - DOCTOR_CHANGE_ONLY - logged in user changes only the health provider - ACCIDENT_CHANGE_ONLY - logged in user changes only the accident insurance - MYATUPRI - logged in user 
 * @export
 * @enum {string}
 */

export const UserFlow = {
    Standard: 'STANDARD',
    DoctorChangeOnly: 'DOCTOR_CHANGE_ONLY',
    AccidentChangeOnly: 'ACCIDENT_CHANGE_ONLY',
    Myatupri: 'MYATUPRI'
} as const;

export type UserFlow = typeof UserFlow[keyof typeof UserFlow];


/**
 * 
 * @export
 * @interface UserResponse
 */
export interface UserResponse {
    /**
     * InsuredNr of the user, the number has a range from 2000000 to 2999999
     * @type {number}
     * @memberof UserResponse
     */
    'insuredNr': number;
    /**
     * FamilyNr of the user, the number has a range from 1000000 to 1999999
     * @type {number}
     * @memberof UserResponse
     */
    'familyNr': number;
    /**
     * First name
     * @type {string}
     * @memberof UserResponse
     */
    'firstName': string;
    /**
     * Last name
     * @type {string}
     * @memberof UserResponse
     */
    'lastName': string;
    /**
     * Customer\'s main contact number
     * @type {string}
     * @memberof UserResponse
     */
    'phoneNr1': string;
    /**
     * 
     * @type {Address}
     * @memberof UserResponse
     */
    'address': Address;
    /**
     * Age
     * @type {number}
     * @memberof UserResponse
     */
    'age': number;
    /**
     * Birthdate
     * @type {string}
     * @memberof UserResponse
     */
    'birthdate': string;
    /**
     * 
     * @type {Gender}
     * @memberof UserResponse
     */
    'gender': Gender;
    /**
     * 
     * @type {Language}
     * @memberof UserResponse
     */
    'language': Language;
    /**
     * 
     * @type {MaritalStatus}
     * @memberof UserResponse
     */
    'maritalStatus': MaritalStatus;
    /**
     * Role of the user, if they are a base customer, Atupri employee or one of the other available roles.Is the enum name like listed below: - STANDARD_CUSTOMER - SBB_EMPLOYEE - COLLECTIVE_INSURED - LOW_REVENUE - ATUPRI_EMPLOYEE - ZENTRALBAHN_AG_EMPLOYEE - THURBO_AG_EMPLOYEE - SBB_RETIREE 
     * @type {string}
     * @memberof UserResponse
     */
    'role': UserResponseRoleEnum;
    /**
     * True if the user is part of a family
     * @type {boolean}
     * @memberof UserResponse
     */
    'isFamily': boolean;
    /**
     * true if the user is part of a collective insurance
     * @type {boolean}
     * @memberof UserResponse
     */
    'isCollectiveInsured': boolean;
    /**
     * true if the user has not paid his invoices at least once and reached level: \'Gesetzliche Mahnung\'
     * @type {boolean}
     * @memberof UserResponse
     */
    'wasEverInDunning': boolean;
    /**
     * null or the number of months the user has not been in dunning, since the last dunning was reached
     * @type {number}
     * @memberof UserResponse
     */
    'wasNotInDunningDuration': number | null;
    /**
     * CSV of the product-codes the user employs
     * @type {string}
     * @memberof UserResponse
     */
    'products': string;
    /**
     * 
     * @type {ProductModel}
     * @memberof UserResponse
     */
    'model': ProductModel;
    /**
     * The user\'s access level, their permission on the platform
     * @type {string}
     * @memberof UserResponse
     */
    'access': UserResponseAccessEnum;
    /**
     * Cross system analytics id assigned to a user when registering for the customer portal, only AKUPO users have it, else it\'s null
     * @type {number}
     * @memberof UserResponse
     */
    'analyticsId': number | null;
    /**
     * If the user has terminated their contract, access == QUIT_CONTRACT, the exit date else null
     * @type {string}
     * @memberof UserResponse
     */
    'exitDate': string | null;
    /**
     * A list with the open tasks for pending changes in the CRM for that user. Note that currently this list only indicates that there is a pending change open that needs to be processed by an SC employee. Once that CRM tasks is closed the entry for that change will vanish form the list. It doesn\'t matter if the change is active today or in one month. 
     * @type {Array<PendingUserChange>}
     * @memberof UserResponse
     */
    'pendingChanges': Array<PendingUserChange>;
    /**
     * True if this user is in debt collection
     * @type {boolean}
     * @memberof UserResponse
     */
    'debtCollection': boolean;
    /**
     * True if this user is the one that is currently logged into the customer portal
     * @type {boolean}
     * @memberof UserResponse
     */
    'isLoggedIn': boolean;
}

export const UserResponseRoleEnum = {
    StandardCustomer: 'STANDARD_CUSTOMER',
    CollectiveInsured: 'COLLECTIVE_INSURED',
    LowRevenue: 'LOW_REVENUE',
    AtupriEmployee: 'ATUPRI_EMPLOYEE',
    SbbEmployee: 'SBB_EMPLOYEE',
    SbbRetiree: 'SBB_RETIREE',
    ThurboAgEmployee: 'THURBO_AG_EMPLOYEE',
    ZentralbahnAgEmployee: 'ZENTRALBAHN_AG_EMPLOYEE'
} as const;

export type UserResponseRoleEnum = typeof UserResponseRoleEnum[keyof typeof UserResponseRoleEnum];
export const UserResponseAccessEnum = {
    EarlyAccess: 'EARLY_ACCESS',
    Standard: 'STANDARD',
    QuitContract: 'QUIT_CONTRACT'
} as const;

export type UserResponseAccessEnum = typeof UserResponseAccessEnum[keyof typeof UserResponseAccessEnum];

/**
 * 
 * @export
 * @interface ValidateIBANRequest
 */
export interface ValidateIBANRequest {
    /**
     * The IBAN to validate
     * @type {string}
     * @memberof ValidateIBANRequest
     */
    'iban': string;
}
/**
 * 
 * @export
 * @interface ValidateIBANResponse
 */
export interface ValidateIBANResponse {
    /**
     * Validation result: - IBAN_IS_VALID - The IBAN is valid. The bankName and bankAddress are present, if known to the system. - IBAN_TOO_SHORT - The provided iban number is too short. - IBAN_TOO_LONG - The provided iban number is too long. - IBAN_IS_INVALID - The IBAN is invalid, incorrect number or another issue.\" + 
     * @type {string}
     * @memberof ValidateIBANResponse
     */
    'validationResult': ValidateIBANResponseValidationResultEnum;
    /**
     * The validated IBAN
     * @type {string}
     * @memberof ValidateIBANResponse
     */
    'iban': string;
    /**
     * The name of the bank, can be null if the system doesn\'t know it or if the IBAN is invalid.
     * @type {string}
     * @memberof ValidateIBANResponse
     */
    'bankName': string | null;
    /**
     * The address of the bank, can be null if the system doesn\'t know it or if the IBAN is invalid.
     * @type {string}
     * @memberof ValidateIBANResponse
     */
    'bankAddress': string | null;
}

export const ValidateIBANResponseValidationResultEnum = {
    IsValid: 'IBAN_IS_VALID',
    TooShort: 'IBAN_TOO_SHORT',
    TooLong: 'IBAN_TOO_LONG',
    IsInvalid: 'IBAN_IS_INVALID'
} as const;

export type ValidateIBANResponseValidationResultEnum = typeof ValidateIBANResponseValidationResultEnum[keyof typeof ValidateIBANResponseValidationResultEnum];

/**
 * Error response thrown during the validation of request parameters and request bodies
 * @export
 * @interface ValidationExceptionResponse
 */
export interface ValidationExceptionResponse {
    /**
     * Error message
     * @type {string}
     * @memberof ValidationExceptionResponse
     */
    'message': string;
    /**
     * Debug message, not available in production
     * @type {string}
     * @memberof ValidationExceptionResponse
     */
    'debugMessage': string;
    /**
     * HTTP status code
     * @type {number}
     * @memberof ValidationExceptionResponse
     */
    'status': number;
    /**
     * HTTP status text
     * @type {string}
     * @memberof ValidationExceptionResponse
     */
    'code': string;
    /**
     * Request id issued by the SES, can be used to trace the request through the systems. It maybe an empty string, if the SES didn\'t provide the id or if the request didn\'t go through the SES i.g on the internal *.atuprinet.ch domains.
     * @type {string}
     * @memberof ValidationExceptionResponse
     */
    'requestCorrelator': string;
    /**
     * Date and time when the error occurred
     * @type {string}
     * @memberof ValidationExceptionResponse
     */
    'timestamp': string;
    /**
     * Map containing the invalid fields(key) and the message(value) describing the validation violation
     * @type {{ [key: string]: string; }}
     * @memberof ValidationExceptionResponse
     */
    'errors': { [key: string]: string; };
}

/**
 * AccountApi - axios parameter creator
 * @export
 */
export const AccountApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * This request will change the family\'s address and/or contact details<br> The address change process is categorized into two types: - Simple address change: is a direct change that updates the address in the systems without SC agent intervention, normally when the user remains within the same premium region - Complex address change: as opposed to Simple change, this request creates a task in CRM so that an SC agent performs the change manually  Important notes: - This request creates a CRM task and does not perform any Simple change in Siddhartha. - Customers can choose to be contacted or not, if the customer chooses to be contacted, the consumer of the API should indicate contactCustomer = true - Contact details of the customer should be provided in a Contact object which will be transformed into comments field in CRM. - Change is only allowed in the future up to six months from today. - Customer contact request can only be scheduled up to six months in the future. - After each change, we evict family cache.  Possible responses:<br> ACCEPTED: Complex change request accepted by CRM<br> UNKNOWN: Unknown error or status, possible failure 
         * @summary Change address and contact details for a family using a complex change request
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {ComplexAddressChangeRequest} complexAddressChangeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeAddressComplex: async (xUserid: number, complexAddressChangeRequest: ComplexAddressChangeRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xUserid' is not null or undefined
            assertParamExists('changeAddressComplex', 'xUserid', xUserid)
            // verify required parameter 'complexAddressChangeRequest' is not null or undefined
            assertParamExists('changeAddressComplex', 'complexAddressChangeRequest', complexAddressChangeRequest)
            const localVarPath = `/api/account/complex-address-change`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xUserid !== undefined && xUserid !== null) {
                localVarHeaderParameter['x-userid'] = String(JSON.stringify(xUserid));
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(complexAddressChangeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This request will change the family\'s address and/or contact details<br> The address change process is categorized into two types: - Simple address change: is a direct change that updates the address in the systems without SC agent intervention, normally when the user remains within the same premium region - Complex address change: as opposed to Simple change, this request creates a task in CRM so that an SC agent performs the change manually  Important notes: - Simple address change is directly executed in Siddhartha with no follow ups or any actions by the service center. - The consumer of the API should perform a Simple address change first before making a Complex address change. Possible response from this operation is:     - CHANGED: the address is updated in Siddhartha and no further action is needed.     - COMPLEX_CHANGE: Means it is a Complex change, the consumer of the API should perform a Complex change that creates a CRM task for SC to manually change the address. - Change is only allowed in the future up to six months from today. - By providing a telephone number, it updates the telephone number in Kommunikation tab in Siddhartha. If the request contains the same address and only the telephone number differs, it is a contact details change - After each change, we evict family cache.  Possible responses:<br> CHANGED: (200) Simple change was executed immediately in Siddhartha<br> COMPLEX_CHANGE (200): change is a complex change, although valid, it will be processed via complex change request (we send a second request to complex change endpoint automatically) so that an SC agent performs it manually through a CRM task<br> PROCESSED_LATER (200): Address change is accepted by Siddhartha, but will be processed later (Read-Only mode) 
         * @summary Change address and/or contact details for a family using a simple change request
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {AddressChangeRequest} addressChangeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeAddressSimple: async (xUserid: number, addressChangeRequest: AddressChangeRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xUserid' is not null or undefined
            assertParamExists('changeAddressSimple', 'xUserid', xUserid)
            // verify required parameter 'addressChangeRequest' is not null or undefined
            assertParamExists('changeAddressSimple', 'addressChangeRequest', addressChangeRequest)
            const localVarPath = `/api/account/simple-address-change`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xUserid !== undefined && xUserid !== null) {
                localVarHeaderParameter['x-userid'] = String(JSON.stringify(xUserid));
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addressChangeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This request will update the family\'s bank account information.<br> In the process the account\'s IBAN will be updated and the payment / refund methods will be set to PAYMENT_SLIP or PAYMENT_SLIP_CRYPTOCURRENCY if crypto was used.<br> As payment / refunds via eBill or LSV to a new IBAN require that the user fills out a PDF requesting the eBill / granting the needed permissions for LSV. 
         * @summary Change the family\'s bank account information
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {BankAccountChangeRequest} bankAccountChangeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeBankAccount: async (xUserid: number, bankAccountChangeRequest: BankAccountChangeRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xUserid' is not null or undefined
            assertParamExists('changeBankAccount', 'xUserid', xUserid)
            // verify required parameter 'bankAccountChangeRequest' is not null or undefined
            assertParamExists('changeBankAccount', 'bankAccountChangeRequest', bankAccountChangeRequest)
            const localVarPath = `/api/account/change-bank-account`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xUserid !== undefined && xUserid !== null) {
                localVarHeaderParameter['x-userid'] = String(JSON.stringify(xUserid));
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bankAccountChangeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * The request will change the language of all the documents, localized text and more that is sent offline or online to the family. The process will update the language of each user in Siddhartha. 
         * @summary Update the family\'s correspondence language.
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {CorrespondenceLanguageRequest} correspondenceLanguageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeCorrespondenceLanguage: async (xUserid: number, correspondenceLanguageRequest: CorrespondenceLanguageRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xUserid' is not null or undefined
            assertParamExists('changeCorrespondenceLanguage', 'xUserid', xUserid)
            // verify required parameter 'correspondenceLanguageRequest' is not null or undefined
            assertParamExists('changeCorrespondenceLanguage', 'correspondenceLanguageRequest', correspondenceLanguageRequest)
            const localVarPath = `/api/account/correspondence-language`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xUserid !== undefined && xUserid !== null) {
                localVarHeaderParameter['x-userid'] = String(JSON.stringify(xUserid));
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(correspondenceLanguageRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This request will update the login information for the currently logged in user. For a successful request at least one of the values must be set.  The phone number is used for the 2FA, so it must be a mobile phone number or a number of a device that can receive the 2FA code. The phone number must be in the E.164 format, the international number format with the + replaced by 00 (international prefix used in Switzerland) e.g. 0041760001122. The interface also accepts the standard E.164 number (+41760001122), we\'ll replace the + with the 00 prefix.  The API uses the [libphonenumber](https://github.com/google/libphonenumber) to test and validate the given number for the format and the national prefixes that are used by mobile phone carriers (79, 78, ...) or generic numbers that can be used by fixed lines, mobile phones or other devices. For example, 51 is a general national prefix for the swiss railways and it\'s possible that such a number is assigned to a fix line, a mobile phone or another device that can receive calls, sms, etc.  Also note that the API will send the e-mail in all lower case to the ESB / core systems. This is a business requirement for the authentication process, it\'s to prevent login issues for customers due to case sensitivity of the email. 
         * @summary Update the login information for the authenticated user.
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {LoginInformationChangeRequest} loginInformationChangeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeLoginInformation: async (xUserid: number, loginInformationChangeRequest: LoginInformationChangeRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xUserid' is not null or undefined
            assertParamExists('changeLoginInformation', 'xUserid', xUserid)
            // verify required parameter 'loginInformationChangeRequest' is not null or undefined
            assertParamExists('changeLoginInformation', 'loginInformationChangeRequest', loginInformationChangeRequest)
            const localVarPath = `/api/account/login-information`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xUserid !== undefined && xUserid !== null) {
                localVarHeaderParameter['x-userid'] = String(JSON.stringify(xUserid));
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginInformationChangeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This request will change the password of the currently logged in user.<br>A new password must fulfill the following complexity rules:<br><br>- Have a length between 8 and 256 characters.<br>- Have at least one upper-case character.<br>- Have at least one lower-case character.<br>- Have at least two digit characters.<br>- Have at least one symbol / special character (ASCII symbols, Latin-1 symbols, Latin-1 math, Unicode symbols, Unicode currency).<br>Chars: !\"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~¡¢£¤¥¦§¨©ª«¬­®¯°±²³´µ¶·¸¹º»¼½¾¿×÷–—―‗‘’‚‛“”„†‡•…‰′″‹›‼‾⁄⁊₠₡₢₣₤₥₦₧₨₩₪₫€₭₮₯₰₱₲₳₴₵₶₷₸₹₺₻₼₽₾ <br>- Doesn\'t contain the first / last name or the insured number in the password. <br><br><br>See the 400 - Bad Request for more details about the returned validation errors.
         * @summary Change the current logged in user\'s password
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {PasswordChangeRequest} passwordChangeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePassword: async (xUserid: number, passwordChangeRequest: PasswordChangeRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xUserid' is not null or undefined
            assertParamExists('changePassword', 'xUserid', xUserid)
            // verify required parameter 'passwordChangeRequest' is not null or undefined
            assertParamExists('changePassword', 'passwordChangeRequest', passwordChangeRequest)
            const localVarPath = `/api/account/change-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xUserid !== undefined && xUserid !== null) {
                localVarHeaderParameter['x-userid'] = String(JSON.stringify(xUserid));
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(passwordChangeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This request will change the family\'s payment frequency.<br> A change can be either simple i.e. automated or complex i.e. a manual process that requires to open a CRM task and the review of an employee.<br> There is a matrix that explains what the different possible dates are for the request and what is simple and complex.<br> See: [4.2.4 Allgemeine Einstellungen: Zahlungsintervall](https://atupri.atlassian.net/wiki/x/sgfJHwQ)  The API abstracts this process: a user only needs to call this endpoint with the appropriate date that is obtained from the /api/account/possible-payment-frequency-change-dates endpoint.  Users with one of the following roles are not allowed to change their premium frequency<br> LOW_REVENUE, ATUPRI_EMPLOYEE, SBB_EMPLOYEE, SBB_RETIREE, THURBO_AG_EMPLOYEE, ZENTRALBAHN_AG_EMPLOYEE<br> They are part of a collective contract, that prevents them to change the frequency. 
         * @summary change the payment frequency for the family
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {PaymentFrequencyChangeRequest} paymentFrequencyChangeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePaymentFrequency: async (xUserid: number, paymentFrequencyChangeRequest: PaymentFrequencyChangeRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xUserid' is not null or undefined
            assertParamExists('changePaymentFrequency', 'xUserid', xUserid)
            // verify required parameter 'paymentFrequencyChangeRequest' is not null or undefined
            assertParamExists('changePaymentFrequency', 'paymentFrequencyChangeRequest', paymentFrequencyChangeRequest)
            const localVarPath = `/api/account/change-payment-frequency`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xUserid !== undefined && xUserid !== null) {
                localVarHeaderParameter['x-userid'] = String(JSON.stringify(xUserid));
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paymentFrequencyChangeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This request will change the family\'s payment methods for the benefit and/or premium invoices, the refund methods will be updated accordingly. The request can only be made for accounts that have a bank account set.  There is a complex matrix in Confluence for the change process:<br> [4.2.3 Allgemeine Einstellungen Zahlungsmethode](https://atupri.atlassian.net/wiki/x/sAfJHwQ)<br> The following is a short summary of it.  Most of the changes will be complex as they require input from the user via a filled out form or an action through their bank.<br> A change to LSV is always complex and the system will return a prefilled from for the user.<br> A change to E_BILL is not possible through the API and is always complex. That change must be done through the bank.<br> Switching to PAYMENT_SLIP / PAYMENT_SLIP_CRYPTOCURRENCY is always a simple change and can always be done.  Enabling / disabling crypto for the current payment method is always a simple change, for example<br> E_BILL <-> E_BILL_CRYPTOCURRENCY is allowed, a simple change.<br> LSV -> E_BILL_CRYPTOCURRENCY is not possible, it is a complex change and firstly the method must be changed to E_BILL.  Users with one of the following roles are not allowed to change their premium methods<br> LOW_REVENUE, ATUPRI_EMPLOYEE, SBB_EMPLOYEE, SBB_RETIREE, THURBO_AG_EMPLOYEE, ZENTRALBAHN_AG_EMPLOYEE<br> They are part of a collective contract. 
         * @summary Change the payment methods for a family
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {PaymentMethodChangeRequest} paymentMethodChangeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePaymentMethods: async (xUserid: number, paymentMethodChangeRequest: PaymentMethodChangeRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xUserid' is not null or undefined
            assertParamExists('changePaymentMethods', 'xUserid', xUserid)
            // verify required parameter 'paymentMethodChangeRequest' is not null or undefined
            assertParamExists('changePaymentMethods', 'paymentMethodChangeRequest', paymentMethodChangeRequest)
            const localVarPath = `/api/account/change-payment-methods`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xUserid !== undefined && xUserid !== null) {
                localVarHeaderParameter['x-userid'] = String(JSON.stringify(xUserid));
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paymentMethodChangeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the account details for the authenticated user / family.
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountDetails: async (xUserid: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xUserid' is not null or undefined
            assertParamExists('getAccountDetails', 'xUserid', xUserid)
            const localVarPath = `/api/account`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xUserid !== undefined && xUserid !== null) {
                localVarHeaderParameter['x-userid'] = String(JSON.stringify(xUserid));
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * The API uses ESB to retrieve community information for a specific postal code, it returns one or more communities for each postal code.Normally the range of postal codes that has communities is 1000 - 9658
         * @summary Get communities for a specific postal code
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {CommunityInformationRequest} communityInformationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommunityInformation: async (xUserid: number, communityInformationRequest: CommunityInformationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xUserid' is not null or undefined
            assertParamExists('getCommunityInformation', 'xUserid', xUserid)
            // verify required parameter 'communityInformationRequest' is not null or undefined
            assertParamExists('getCommunityInformation', 'communityInformationRequest', communityInformationRequest)
            const localVarPath = `/api/account/community-information`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xUserid !== undefined && xUserid !== null) {
                localVarHeaderParameter['x-userid'] = String(JSON.stringify(xUserid));
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(communityInformationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * With this endpoint one can get the next possible start dates of the payment frequencies for the change request.<br> The current frequency will always be an empty list, can\'t change to the currently used one.  If we allow an immediate change, the list for that frequency will contain the current date. That value is used for an immediate change of the frequency.<br> This is the case when the selectable frequency < current frequency e.g. MONTHLY < QUARTERLY.<br> So the \'monthly\' list will also contain the current date for the immediate change.  For the complete matrix see the Confluence page: [4.2.4 Allgemeine Einstellungen: Zahlungsintervall](https://atupri.atlassian.net/wiki/x/sgfJHwQ) 
         * @summary Get the possible start dates for the next payment frequency change
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {string} [calculationDate] optional date (YYYY-MM-DD) that will be used as date to calculate the possible start dates.
         * @param {PaymentFrequency} [calculationFrequency] optional current payment frequency will be used to calculate the possible start dates.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPossiblePaymentFrequencyChangeDates: async (xUserid: number, calculationDate?: string, calculationFrequency?: PaymentFrequency, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xUserid' is not null or undefined
            assertParamExists('getPossiblePaymentFrequencyChangeDates', 'xUserid', xUserid)
            const localVarPath = `/api/account/possible-payment-frequency-change-dates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (calculationDate !== undefined) {
                localVarQueryParameter['calculationDate'] = (calculationDate as any instanceof Date) ?
                    (calculationDate as any).toISOString().substr(0,10) :
                    calculationDate;
            }

            if (calculationFrequency !== undefined) {
                localVarQueryParameter['calculationFrequency'] = calculationFrequency;
            }

            if (xUserid !== undefined && xUserid !== null) {
                localVarHeaderParameter['x-userid'] = String(JSON.stringify(xUserid));
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * The API uses Siddhartha for the validation, which will validate the IBAN and return information about the bank if available.
         * @summary Validate the given iban and get the available information about the bank
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {ValidateIBANRequest} validateIBANRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateIBAN: async (xUserid: number, validateIBANRequest: ValidateIBANRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xUserid' is not null or undefined
            assertParamExists('validateIBAN', 'xUserid', xUserid)
            // verify required parameter 'validateIBANRequest' is not null or undefined
            assertParamExists('validateIBAN', 'validateIBANRequest', validateIBANRequest)
            const localVarPath = `/api/account/validate-iban`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xUserid !== undefined && xUserid !== null) {
                localVarHeaderParameter['x-userid'] = String(JSON.stringify(xUserid));
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(validateIBANRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountApi - functional programming interface
 * @export
 */
export const AccountApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccountApiAxiosParamCreator(configuration)
    return {
        /**
         * This request will change the family\'s address and/or contact details<br> The address change process is categorized into two types: - Simple address change: is a direct change that updates the address in the systems without SC agent intervention, normally when the user remains within the same premium region - Complex address change: as opposed to Simple change, this request creates a task in CRM so that an SC agent performs the change manually  Important notes: - This request creates a CRM task and does not perform any Simple change in Siddhartha. - Customers can choose to be contacted or not, if the customer chooses to be contacted, the consumer of the API should indicate contactCustomer = true - Contact details of the customer should be provided in a Contact object which will be transformed into comments field in CRM. - Change is only allowed in the future up to six months from today. - Customer contact request can only be scheduled up to six months in the future. - After each change, we evict family cache.  Possible responses:<br> ACCEPTED: Complex change request accepted by CRM<br> UNKNOWN: Unknown error or status, possible failure 
         * @summary Change address and contact details for a family using a complex change request
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {ComplexAddressChangeRequest} complexAddressChangeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeAddressComplex(xUserid: number, complexAddressChangeRequest: ComplexAddressChangeRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddressChangeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeAddressComplex(xUserid, complexAddressChangeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This request will change the family\'s address and/or contact details<br> The address change process is categorized into two types: - Simple address change: is a direct change that updates the address in the systems without SC agent intervention, normally when the user remains within the same premium region - Complex address change: as opposed to Simple change, this request creates a task in CRM so that an SC agent performs the change manually  Important notes: - Simple address change is directly executed in Siddhartha with no follow ups or any actions by the service center. - The consumer of the API should perform a Simple address change first before making a Complex address change. Possible response from this operation is:     - CHANGED: the address is updated in Siddhartha and no further action is needed.     - COMPLEX_CHANGE: Means it is a Complex change, the consumer of the API should perform a Complex change that creates a CRM task for SC to manually change the address. - Change is only allowed in the future up to six months from today. - By providing a telephone number, it updates the telephone number in Kommunikation tab in Siddhartha. If the request contains the same address and only the telephone number differs, it is a contact details change - After each change, we evict family cache.  Possible responses:<br> CHANGED: (200) Simple change was executed immediately in Siddhartha<br> COMPLEX_CHANGE (200): change is a complex change, although valid, it will be processed via complex change request (we send a second request to complex change endpoint automatically) so that an SC agent performs it manually through a CRM task<br> PROCESSED_LATER (200): Address change is accepted by Siddhartha, but will be processed later (Read-Only mode) 
         * @summary Change address and/or contact details for a family using a simple change request
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {AddressChangeRequest} addressChangeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeAddressSimple(xUserid: number, addressChangeRequest: AddressChangeRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddressChangeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeAddressSimple(xUserid, addressChangeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This request will update the family\'s bank account information.<br> In the process the account\'s IBAN will be updated and the payment / refund methods will be set to PAYMENT_SLIP or PAYMENT_SLIP_CRYPTOCURRENCY if crypto was used.<br> As payment / refunds via eBill or LSV to a new IBAN require that the user fills out a PDF requesting the eBill / granting the needed permissions for LSV. 
         * @summary Change the family\'s bank account information
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {BankAccountChangeRequest} bankAccountChangeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeBankAccount(xUserid: number, bankAccountChangeRequest: BankAccountChangeRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeBankAccount(xUserid, bankAccountChangeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * The request will change the language of all the documents, localized text and more that is sent offline or online to the family. The process will update the language of each user in Siddhartha. 
         * @summary Update the family\'s correspondence language.
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {CorrespondenceLanguageRequest} correspondenceLanguageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeCorrespondenceLanguage(xUserid: number, correspondenceLanguageRequest: CorrespondenceLanguageRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeCorrespondenceLanguage(xUserid, correspondenceLanguageRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This request will update the login information for the currently logged in user. For a successful request at least one of the values must be set.  The phone number is used for the 2FA, so it must be a mobile phone number or a number of a device that can receive the 2FA code. The phone number must be in the E.164 format, the international number format with the + replaced by 00 (international prefix used in Switzerland) e.g. 0041760001122. The interface also accepts the standard E.164 number (+41760001122), we\'ll replace the + with the 00 prefix.  The API uses the [libphonenumber](https://github.com/google/libphonenumber) to test and validate the given number for the format and the national prefixes that are used by mobile phone carriers (79, 78, ...) or generic numbers that can be used by fixed lines, mobile phones or other devices. For example, 51 is a general national prefix for the swiss railways and it\'s possible that such a number is assigned to a fix line, a mobile phone or another device that can receive calls, sms, etc.  Also note that the API will send the e-mail in all lower case to the ESB / core systems. This is a business requirement for the authentication process, it\'s to prevent login issues for customers due to case sensitivity of the email. 
         * @summary Update the login information for the authenticated user.
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {LoginInformationChangeRequest} loginInformationChangeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeLoginInformation(xUserid: number, loginInformationChangeRequest: LoginInformationChangeRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeLoginInformation(xUserid, loginInformationChangeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This request will change the password of the currently logged in user.<br>A new password must fulfill the following complexity rules:<br><br>- Have a length between 8 and 256 characters.<br>- Have at least one upper-case character.<br>- Have at least one lower-case character.<br>- Have at least two digit characters.<br>- Have at least one symbol / special character (ASCII symbols, Latin-1 symbols, Latin-1 math, Unicode symbols, Unicode currency).<br>Chars: !\"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~¡¢£¤¥¦§¨©ª«¬­®¯°±²³´µ¶·¸¹º»¼½¾¿×÷–—―‗‘’‚‛“”„†‡•…‰′″‹›‼‾⁄⁊₠₡₢₣₤₥₦₧₨₩₪₫€₭₮₯₰₱₲₳₴₵₶₷₸₹₺₻₼₽₾ <br>- Doesn\'t contain the first / last name or the insured number in the password. <br><br><br>See the 400 - Bad Request for more details about the returned validation errors.
         * @summary Change the current logged in user\'s password
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {PasswordChangeRequest} passwordChangeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changePassword(xUserid: number, passwordChangeRequest: PasswordChangeRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changePassword(xUserid, passwordChangeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This request will change the family\'s payment frequency.<br> A change can be either simple i.e. automated or complex i.e. a manual process that requires to open a CRM task and the review of an employee.<br> There is a matrix that explains what the different possible dates are for the request and what is simple and complex.<br> See: [4.2.4 Allgemeine Einstellungen: Zahlungsintervall](https://atupri.atlassian.net/wiki/x/sgfJHwQ)  The API abstracts this process: a user only needs to call this endpoint with the appropriate date that is obtained from the /api/account/possible-payment-frequency-change-dates endpoint.  Users with one of the following roles are not allowed to change their premium frequency<br> LOW_REVENUE, ATUPRI_EMPLOYEE, SBB_EMPLOYEE, SBB_RETIREE, THURBO_AG_EMPLOYEE, ZENTRALBAHN_AG_EMPLOYEE<br> They are part of a collective contract, that prevents them to change the frequency. 
         * @summary change the payment frequency for the family
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {PaymentFrequencyChangeRequest} paymentFrequencyChangeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changePaymentFrequency(xUserid: number, paymentFrequencyChangeRequest: PaymentFrequencyChangeRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentFrequencyChangeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changePaymentFrequency(xUserid, paymentFrequencyChangeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This request will change the family\'s payment methods for the benefit and/or premium invoices, the refund methods will be updated accordingly. The request can only be made for accounts that have a bank account set.  There is a complex matrix in Confluence for the change process:<br> [4.2.3 Allgemeine Einstellungen Zahlungsmethode](https://atupri.atlassian.net/wiki/x/sAfJHwQ)<br> The following is a short summary of it.  Most of the changes will be complex as they require input from the user via a filled out form or an action through their bank.<br> A change to LSV is always complex and the system will return a prefilled from for the user.<br> A change to E_BILL is not possible through the API and is always complex. That change must be done through the bank.<br> Switching to PAYMENT_SLIP / PAYMENT_SLIP_CRYPTOCURRENCY is always a simple change and can always be done.  Enabling / disabling crypto for the current payment method is always a simple change, for example<br> E_BILL <-> E_BILL_CRYPTOCURRENCY is allowed, a simple change.<br> LSV -> E_BILL_CRYPTOCURRENCY is not possible, it is a complex change and firstly the method must be changed to E_BILL.  Users with one of the following roles are not allowed to change their premium methods<br> LOW_REVENUE, ATUPRI_EMPLOYEE, SBB_EMPLOYEE, SBB_RETIREE, THURBO_AG_EMPLOYEE, ZENTRALBAHN_AG_EMPLOYEE<br> They are part of a collective contract. 
         * @summary Change the payment methods for a family
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {PaymentMethodChangeRequest} paymentMethodChangeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changePaymentMethods(xUserid: number, paymentMethodChangeRequest: PaymentMethodChangeRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentMethodChangeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changePaymentMethods(xUserid, paymentMethodChangeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the account details for the authenticated user / family.
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAccountDetails(xUserid: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAccountDetails(xUserid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * The API uses ESB to retrieve community information for a specific postal code, it returns one or more communities for each postal code.Normally the range of postal codes that has communities is 1000 - 9658
         * @summary Get communities for a specific postal code
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {CommunityInformationRequest} communityInformationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCommunityInformation(xUserid: number, communityInformationRequest: CommunityInformationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CommunityInformationResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCommunityInformation(xUserid, communityInformationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * With this endpoint one can get the next possible start dates of the payment frequencies for the change request.<br> The current frequency will always be an empty list, can\'t change to the currently used one.  If we allow an immediate change, the list for that frequency will contain the current date. That value is used for an immediate change of the frequency.<br> This is the case when the selectable frequency < current frequency e.g. MONTHLY < QUARTERLY.<br> So the \'monthly\' list will also contain the current date for the immediate change.  For the complete matrix see the Confluence page: [4.2.4 Allgemeine Einstellungen: Zahlungsintervall](https://atupri.atlassian.net/wiki/x/sgfJHwQ) 
         * @summary Get the possible start dates for the next payment frequency change
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {string} [calculationDate] optional date (YYYY-MM-DD) that will be used as date to calculate the possible start dates.
         * @param {PaymentFrequency} [calculationFrequency] optional current payment frequency will be used to calculate the possible start dates.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPossiblePaymentFrequencyChangeDates(xUserid: number, calculationDate?: string, calculationFrequency?: PaymentFrequency, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentFrequencyChangeDatesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPossiblePaymentFrequencyChangeDates(xUserid, calculationDate, calculationFrequency, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * The API uses Siddhartha for the validation, which will validate the IBAN and return information about the bank if available.
         * @summary Validate the given iban and get the available information about the bank
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {ValidateIBANRequest} validateIBANRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateIBAN(xUserid: number, validateIBANRequest: ValidateIBANRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ValidateIBANResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validateIBAN(xUserid, validateIBANRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AccountApi - factory interface
 * @export
 */
export const AccountApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccountApiFp(configuration)
    return {
        /**
         * This request will change the family\'s address and/or contact details<br> The address change process is categorized into two types: - Simple address change: is a direct change that updates the address in the systems without SC agent intervention, normally when the user remains within the same premium region - Complex address change: as opposed to Simple change, this request creates a task in CRM so that an SC agent performs the change manually  Important notes: - This request creates a CRM task and does not perform any Simple change in Siddhartha. - Customers can choose to be contacted or not, if the customer chooses to be contacted, the consumer of the API should indicate contactCustomer = true - Contact details of the customer should be provided in a Contact object which will be transformed into comments field in CRM. - Change is only allowed in the future up to six months from today. - Customer contact request can only be scheduled up to six months in the future. - After each change, we evict family cache.  Possible responses:<br> ACCEPTED: Complex change request accepted by CRM<br> UNKNOWN: Unknown error or status, possible failure 
         * @summary Change address and contact details for a family using a complex change request
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {ComplexAddressChangeRequest} complexAddressChangeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeAddressComplex(xUserid: number, complexAddressChangeRequest: ComplexAddressChangeRequest, options?: any): AxiosPromise<AddressChangeResponse> {
            return localVarFp.changeAddressComplex(xUserid, complexAddressChangeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * This request will change the family\'s address and/or contact details<br> The address change process is categorized into two types: - Simple address change: is a direct change that updates the address in the systems without SC agent intervention, normally when the user remains within the same premium region - Complex address change: as opposed to Simple change, this request creates a task in CRM so that an SC agent performs the change manually  Important notes: - Simple address change is directly executed in Siddhartha with no follow ups or any actions by the service center. - The consumer of the API should perform a Simple address change first before making a Complex address change. Possible response from this operation is:     - CHANGED: the address is updated in Siddhartha and no further action is needed.     - COMPLEX_CHANGE: Means it is a Complex change, the consumer of the API should perform a Complex change that creates a CRM task for SC to manually change the address. - Change is only allowed in the future up to six months from today. - By providing a telephone number, it updates the telephone number in Kommunikation tab in Siddhartha. If the request contains the same address and only the telephone number differs, it is a contact details change - After each change, we evict family cache.  Possible responses:<br> CHANGED: (200) Simple change was executed immediately in Siddhartha<br> COMPLEX_CHANGE (200): change is a complex change, although valid, it will be processed via complex change request (we send a second request to complex change endpoint automatically) so that an SC agent performs it manually through a CRM task<br> PROCESSED_LATER (200): Address change is accepted by Siddhartha, but will be processed later (Read-Only mode) 
         * @summary Change address and/or contact details for a family using a simple change request
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {AddressChangeRequest} addressChangeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeAddressSimple(xUserid: number, addressChangeRequest: AddressChangeRequest, options?: any): AxiosPromise<AddressChangeResponse> {
            return localVarFp.changeAddressSimple(xUserid, addressChangeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * This request will update the family\'s bank account information.<br> In the process the account\'s IBAN will be updated and the payment / refund methods will be set to PAYMENT_SLIP or PAYMENT_SLIP_CRYPTOCURRENCY if crypto was used.<br> As payment / refunds via eBill or LSV to a new IBAN require that the user fills out a PDF requesting the eBill / granting the needed permissions for LSV. 
         * @summary Change the family\'s bank account information
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {BankAccountChangeRequest} bankAccountChangeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeBankAccount(xUserid: number, bankAccountChangeRequest: BankAccountChangeRequest, options?: any): AxiosPromise<AccountResponse> {
            return localVarFp.changeBankAccount(xUserid, bankAccountChangeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * The request will change the language of all the documents, localized text and more that is sent offline or online to the family. The process will update the language of each user in Siddhartha. 
         * @summary Update the family\'s correspondence language.
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {CorrespondenceLanguageRequest} correspondenceLanguageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeCorrespondenceLanguage(xUserid: number, correspondenceLanguageRequest: CorrespondenceLanguageRequest, options?: any): AxiosPromise<AccountResponse> {
            return localVarFp.changeCorrespondenceLanguage(xUserid, correspondenceLanguageRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * This request will update the login information for the currently logged in user. For a successful request at least one of the values must be set.  The phone number is used for the 2FA, so it must be a mobile phone number or a number of a device that can receive the 2FA code. The phone number must be in the E.164 format, the international number format with the + replaced by 00 (international prefix used in Switzerland) e.g. 0041760001122. The interface also accepts the standard E.164 number (+41760001122), we\'ll replace the + with the 00 prefix.  The API uses the [libphonenumber](https://github.com/google/libphonenumber) to test and validate the given number for the format and the national prefixes that are used by mobile phone carriers (79, 78, ...) or generic numbers that can be used by fixed lines, mobile phones or other devices. For example, 51 is a general national prefix for the swiss railways and it\'s possible that such a number is assigned to a fix line, a mobile phone or another device that can receive calls, sms, etc.  Also note that the API will send the e-mail in all lower case to the ESB / core systems. This is a business requirement for the authentication process, it\'s to prevent login issues for customers due to case sensitivity of the email. 
         * @summary Update the login information for the authenticated user.
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {LoginInformationChangeRequest} loginInformationChangeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeLoginInformation(xUserid: number, loginInformationChangeRequest: LoginInformationChangeRequest, options?: any): AxiosPromise<AccountResponse> {
            return localVarFp.changeLoginInformation(xUserid, loginInformationChangeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * This request will change the password of the currently logged in user.<br>A new password must fulfill the following complexity rules:<br><br>- Have a length between 8 and 256 characters.<br>- Have at least one upper-case character.<br>- Have at least one lower-case character.<br>- Have at least two digit characters.<br>- Have at least one symbol / special character (ASCII symbols, Latin-1 symbols, Latin-1 math, Unicode symbols, Unicode currency).<br>Chars: !\"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~¡¢£¤¥¦§¨©ª«¬­®¯°±²³´µ¶·¸¹º»¼½¾¿×÷–—―‗‘’‚‛“”„†‡•…‰′″‹›‼‾⁄⁊₠₡₢₣₤₥₦₧₨₩₪₫€₭₮₯₰₱₲₳₴₵₶₷₸₹₺₻₼₽₾ <br>- Doesn\'t contain the first / last name or the insured number in the password. <br><br><br>See the 400 - Bad Request for more details about the returned validation errors.
         * @summary Change the current logged in user\'s password
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {PasswordChangeRequest} passwordChangeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePassword(xUserid: number, passwordChangeRequest: PasswordChangeRequest, options?: any): AxiosPromise<void> {
            return localVarFp.changePassword(xUserid, passwordChangeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * This request will change the family\'s payment frequency.<br> A change can be either simple i.e. automated or complex i.e. a manual process that requires to open a CRM task and the review of an employee.<br> There is a matrix that explains what the different possible dates are for the request and what is simple and complex.<br> See: [4.2.4 Allgemeine Einstellungen: Zahlungsintervall](https://atupri.atlassian.net/wiki/x/sgfJHwQ)  The API abstracts this process: a user only needs to call this endpoint with the appropriate date that is obtained from the /api/account/possible-payment-frequency-change-dates endpoint.  Users with one of the following roles are not allowed to change their premium frequency<br> LOW_REVENUE, ATUPRI_EMPLOYEE, SBB_EMPLOYEE, SBB_RETIREE, THURBO_AG_EMPLOYEE, ZENTRALBAHN_AG_EMPLOYEE<br> They are part of a collective contract, that prevents them to change the frequency. 
         * @summary change the payment frequency for the family
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {PaymentFrequencyChangeRequest} paymentFrequencyChangeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePaymentFrequency(xUserid: number, paymentFrequencyChangeRequest: PaymentFrequencyChangeRequest, options?: any): AxiosPromise<PaymentFrequencyChangeResponse> {
            return localVarFp.changePaymentFrequency(xUserid, paymentFrequencyChangeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * This request will change the family\'s payment methods for the benefit and/or premium invoices, the refund methods will be updated accordingly. The request can only be made for accounts that have a bank account set.  There is a complex matrix in Confluence for the change process:<br> [4.2.3 Allgemeine Einstellungen Zahlungsmethode](https://atupri.atlassian.net/wiki/x/sAfJHwQ)<br> The following is a short summary of it.  Most of the changes will be complex as they require input from the user via a filled out form or an action through their bank.<br> A change to LSV is always complex and the system will return a prefilled from for the user.<br> A change to E_BILL is not possible through the API and is always complex. That change must be done through the bank.<br> Switching to PAYMENT_SLIP / PAYMENT_SLIP_CRYPTOCURRENCY is always a simple change and can always be done.  Enabling / disabling crypto for the current payment method is always a simple change, for example<br> E_BILL <-> E_BILL_CRYPTOCURRENCY is allowed, a simple change.<br> LSV -> E_BILL_CRYPTOCURRENCY is not possible, it is a complex change and firstly the method must be changed to E_BILL.  Users with one of the following roles are not allowed to change their premium methods<br> LOW_REVENUE, ATUPRI_EMPLOYEE, SBB_EMPLOYEE, SBB_RETIREE, THURBO_AG_EMPLOYEE, ZENTRALBAHN_AG_EMPLOYEE<br> They are part of a collective contract. 
         * @summary Change the payment methods for a family
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {PaymentMethodChangeRequest} paymentMethodChangeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePaymentMethods(xUserid: number, paymentMethodChangeRequest: PaymentMethodChangeRequest, options?: any): AxiosPromise<PaymentMethodChangeResponse> {
            return localVarFp.changePaymentMethods(xUserid, paymentMethodChangeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the account details for the authenticated user / family.
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountDetails(xUserid: number, options?: any): AxiosPromise<AccountResponse> {
            return localVarFp.getAccountDetails(xUserid, options).then((request) => request(axios, basePath));
        },
        /**
         * The API uses ESB to retrieve community information for a specific postal code, it returns one or more communities for each postal code.Normally the range of postal codes that has communities is 1000 - 9658
         * @summary Get communities for a specific postal code
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {CommunityInformationRequest} communityInformationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommunityInformation(xUserid: number, communityInformationRequest: CommunityInformationRequest, options?: any): AxiosPromise<Array<CommunityInformationResponse>> {
            return localVarFp.getCommunityInformation(xUserid, communityInformationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * With this endpoint one can get the next possible start dates of the payment frequencies for the change request.<br> The current frequency will always be an empty list, can\'t change to the currently used one.  If we allow an immediate change, the list for that frequency will contain the current date. That value is used for an immediate change of the frequency.<br> This is the case when the selectable frequency < current frequency e.g. MONTHLY < QUARTERLY.<br> So the \'monthly\' list will also contain the current date for the immediate change.  For the complete matrix see the Confluence page: [4.2.4 Allgemeine Einstellungen: Zahlungsintervall](https://atupri.atlassian.net/wiki/x/sgfJHwQ) 
         * @summary Get the possible start dates for the next payment frequency change
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {string} [calculationDate] optional date (YYYY-MM-DD) that will be used as date to calculate the possible start dates.
         * @param {PaymentFrequency} [calculationFrequency] optional current payment frequency will be used to calculate the possible start dates.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPossiblePaymentFrequencyChangeDates(xUserid: number, calculationDate?: string, calculationFrequency?: PaymentFrequency, options?: any): AxiosPromise<PaymentFrequencyChangeDatesResponse> {
            return localVarFp.getPossiblePaymentFrequencyChangeDates(xUserid, calculationDate, calculationFrequency, options).then((request) => request(axios, basePath));
        },
        /**
         * The API uses Siddhartha for the validation, which will validate the IBAN and return information about the bank if available.
         * @summary Validate the given iban and get the available information about the bank
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {ValidateIBANRequest} validateIBANRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateIBAN(xUserid: number, validateIBANRequest: ValidateIBANRequest, options?: any): AxiosPromise<ValidateIBANResponse> {
            return localVarFp.validateIBAN(xUserid, validateIBANRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AccountApi - object-oriented interface
 * @export
 * @class AccountApi
 * @extends {BaseAPI}
 */
export class AccountApi extends BaseAPI {
    /**
     * This request will change the family\'s address and/or contact details<br> The address change process is categorized into two types: - Simple address change: is a direct change that updates the address in the systems without SC agent intervention, normally when the user remains within the same premium region - Complex address change: as opposed to Simple change, this request creates a task in CRM so that an SC agent performs the change manually  Important notes: - This request creates a CRM task and does not perform any Simple change in Siddhartha. - Customers can choose to be contacted or not, if the customer chooses to be contacted, the consumer of the API should indicate contactCustomer = true - Contact details of the customer should be provided in a Contact object which will be transformed into comments field in CRM. - Change is only allowed in the future up to six months from today. - Customer contact request can only be scheduled up to six months in the future. - After each change, we evict family cache.  Possible responses:<br> ACCEPTED: Complex change request accepted by CRM<br> UNKNOWN: Unknown error or status, possible failure 
     * @summary Change address and contact details for a family using a complex change request
     * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
     * @param {ComplexAddressChangeRequest} complexAddressChangeRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public changeAddressComplex(xUserid: number, complexAddressChangeRequest: ComplexAddressChangeRequest, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).changeAddressComplex(xUserid, complexAddressChangeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This request will change the family\'s address and/or contact details<br> The address change process is categorized into two types: - Simple address change: is a direct change that updates the address in the systems without SC agent intervention, normally when the user remains within the same premium region - Complex address change: as opposed to Simple change, this request creates a task in CRM so that an SC agent performs the change manually  Important notes: - Simple address change is directly executed in Siddhartha with no follow ups or any actions by the service center. - The consumer of the API should perform a Simple address change first before making a Complex address change. Possible response from this operation is:     - CHANGED: the address is updated in Siddhartha and no further action is needed.     - COMPLEX_CHANGE: Means it is a Complex change, the consumer of the API should perform a Complex change that creates a CRM task for SC to manually change the address. - Change is only allowed in the future up to six months from today. - By providing a telephone number, it updates the telephone number in Kommunikation tab in Siddhartha. If the request contains the same address and only the telephone number differs, it is a contact details change - After each change, we evict family cache.  Possible responses:<br> CHANGED: (200) Simple change was executed immediately in Siddhartha<br> COMPLEX_CHANGE (200): change is a complex change, although valid, it will be processed via complex change request (we send a second request to complex change endpoint automatically) so that an SC agent performs it manually through a CRM task<br> PROCESSED_LATER (200): Address change is accepted by Siddhartha, but will be processed later (Read-Only mode) 
     * @summary Change address and/or contact details for a family using a simple change request
     * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
     * @param {AddressChangeRequest} addressChangeRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public changeAddressSimple(xUserid: number, addressChangeRequest: AddressChangeRequest, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).changeAddressSimple(xUserid, addressChangeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This request will update the family\'s bank account information.<br> In the process the account\'s IBAN will be updated and the payment / refund methods will be set to PAYMENT_SLIP or PAYMENT_SLIP_CRYPTOCURRENCY if crypto was used.<br> As payment / refunds via eBill or LSV to a new IBAN require that the user fills out a PDF requesting the eBill / granting the needed permissions for LSV. 
     * @summary Change the family\'s bank account information
     * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
     * @param {BankAccountChangeRequest} bankAccountChangeRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public changeBankAccount(xUserid: number, bankAccountChangeRequest: BankAccountChangeRequest, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).changeBankAccount(xUserid, bankAccountChangeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * The request will change the language of all the documents, localized text and more that is sent offline or online to the family. The process will update the language of each user in Siddhartha. 
     * @summary Update the family\'s correspondence language.
     * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
     * @param {CorrespondenceLanguageRequest} correspondenceLanguageRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public changeCorrespondenceLanguage(xUserid: number, correspondenceLanguageRequest: CorrespondenceLanguageRequest, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).changeCorrespondenceLanguage(xUserid, correspondenceLanguageRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This request will update the login information for the currently logged in user. For a successful request at least one of the values must be set.  The phone number is used for the 2FA, so it must be a mobile phone number or a number of a device that can receive the 2FA code. The phone number must be in the E.164 format, the international number format with the + replaced by 00 (international prefix used in Switzerland) e.g. 0041760001122. The interface also accepts the standard E.164 number (+41760001122), we\'ll replace the + with the 00 prefix.  The API uses the [libphonenumber](https://github.com/google/libphonenumber) to test and validate the given number for the format and the national prefixes that are used by mobile phone carriers (79, 78, ...) or generic numbers that can be used by fixed lines, mobile phones or other devices. For example, 51 is a general national prefix for the swiss railways and it\'s possible that such a number is assigned to a fix line, a mobile phone or another device that can receive calls, sms, etc.  Also note that the API will send the e-mail in all lower case to the ESB / core systems. This is a business requirement for the authentication process, it\'s to prevent login issues for customers due to case sensitivity of the email. 
     * @summary Update the login information for the authenticated user.
     * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
     * @param {LoginInformationChangeRequest} loginInformationChangeRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public changeLoginInformation(xUserid: number, loginInformationChangeRequest: LoginInformationChangeRequest, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).changeLoginInformation(xUserid, loginInformationChangeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This request will change the password of the currently logged in user.<br>A new password must fulfill the following complexity rules:<br><br>- Have a length between 8 and 256 characters.<br>- Have at least one upper-case character.<br>- Have at least one lower-case character.<br>- Have at least two digit characters.<br>- Have at least one symbol / special character (ASCII symbols, Latin-1 symbols, Latin-1 math, Unicode symbols, Unicode currency).<br>Chars: !\"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~¡¢£¤¥¦§¨©ª«¬­®¯°±²³´µ¶·¸¹º»¼½¾¿×÷–—―‗‘’‚‛“”„†‡•…‰′″‹›‼‾⁄⁊₠₡₢₣₤₥₦₧₨₩₪₫€₭₮₯₰₱₲₳₴₵₶₷₸₹₺₻₼₽₾ <br>- Doesn\'t contain the first / last name or the insured number in the password. <br><br><br>See the 400 - Bad Request for more details about the returned validation errors.
     * @summary Change the current logged in user\'s password
     * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
     * @param {PasswordChangeRequest} passwordChangeRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public changePassword(xUserid: number, passwordChangeRequest: PasswordChangeRequest, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).changePassword(xUserid, passwordChangeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This request will change the family\'s payment frequency.<br> A change can be either simple i.e. automated or complex i.e. a manual process that requires to open a CRM task and the review of an employee.<br> There is a matrix that explains what the different possible dates are for the request and what is simple and complex.<br> See: [4.2.4 Allgemeine Einstellungen: Zahlungsintervall](https://atupri.atlassian.net/wiki/x/sgfJHwQ)  The API abstracts this process: a user only needs to call this endpoint with the appropriate date that is obtained from the /api/account/possible-payment-frequency-change-dates endpoint.  Users with one of the following roles are not allowed to change their premium frequency<br> LOW_REVENUE, ATUPRI_EMPLOYEE, SBB_EMPLOYEE, SBB_RETIREE, THURBO_AG_EMPLOYEE, ZENTRALBAHN_AG_EMPLOYEE<br> They are part of a collective contract, that prevents them to change the frequency. 
     * @summary change the payment frequency for the family
     * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
     * @param {PaymentFrequencyChangeRequest} paymentFrequencyChangeRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public changePaymentFrequency(xUserid: number, paymentFrequencyChangeRequest: PaymentFrequencyChangeRequest, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).changePaymentFrequency(xUserid, paymentFrequencyChangeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This request will change the family\'s payment methods for the benefit and/or premium invoices, the refund methods will be updated accordingly. The request can only be made for accounts that have a bank account set.  There is a complex matrix in Confluence for the change process:<br> [4.2.3 Allgemeine Einstellungen Zahlungsmethode](https://atupri.atlassian.net/wiki/x/sAfJHwQ)<br> The following is a short summary of it.  Most of the changes will be complex as they require input from the user via a filled out form or an action through their bank.<br> A change to LSV is always complex and the system will return a prefilled from for the user.<br> A change to E_BILL is not possible through the API and is always complex. That change must be done through the bank.<br> Switching to PAYMENT_SLIP / PAYMENT_SLIP_CRYPTOCURRENCY is always a simple change and can always be done.  Enabling / disabling crypto for the current payment method is always a simple change, for example<br> E_BILL <-> E_BILL_CRYPTOCURRENCY is allowed, a simple change.<br> LSV -> E_BILL_CRYPTOCURRENCY is not possible, it is a complex change and firstly the method must be changed to E_BILL.  Users with one of the following roles are not allowed to change their premium methods<br> LOW_REVENUE, ATUPRI_EMPLOYEE, SBB_EMPLOYEE, SBB_RETIREE, THURBO_AG_EMPLOYEE, ZENTRALBAHN_AG_EMPLOYEE<br> They are part of a collective contract. 
     * @summary Change the payment methods for a family
     * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
     * @param {PaymentMethodChangeRequest} paymentMethodChangeRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public changePaymentMethods(xUserid: number, paymentMethodChangeRequest: PaymentMethodChangeRequest, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).changePaymentMethods(xUserid, paymentMethodChangeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the account details for the authenticated user / family.
     * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public getAccountDetails(xUserid: number, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).getAccountDetails(xUserid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * The API uses ESB to retrieve community information for a specific postal code, it returns one or more communities for each postal code.Normally the range of postal codes that has communities is 1000 - 9658
     * @summary Get communities for a specific postal code
     * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
     * @param {CommunityInformationRequest} communityInformationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public getCommunityInformation(xUserid: number, communityInformationRequest: CommunityInformationRequest, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).getCommunityInformation(xUserid, communityInformationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * With this endpoint one can get the next possible start dates of the payment frequencies for the change request.<br> The current frequency will always be an empty list, can\'t change to the currently used one.  If we allow an immediate change, the list for that frequency will contain the current date. That value is used for an immediate change of the frequency.<br> This is the case when the selectable frequency < current frequency e.g. MONTHLY < QUARTERLY.<br> So the \'monthly\' list will also contain the current date for the immediate change.  For the complete matrix see the Confluence page: [4.2.4 Allgemeine Einstellungen: Zahlungsintervall](https://atupri.atlassian.net/wiki/x/sgfJHwQ) 
     * @summary Get the possible start dates for the next payment frequency change
     * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
     * @param {string} [calculationDate] optional date (YYYY-MM-DD) that will be used as date to calculate the possible start dates.
     * @param {PaymentFrequency} [calculationFrequency] optional current payment frequency will be used to calculate the possible start dates.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public getPossiblePaymentFrequencyChangeDates(xUserid: number, calculationDate?: string, calculationFrequency?: PaymentFrequency, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).getPossiblePaymentFrequencyChangeDates(xUserid, calculationDate, calculationFrequency, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * The API uses Siddhartha for the validation, which will validate the IBAN and return information about the bank if available.
     * @summary Validate the given iban and get the available information about the bank
     * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
     * @param {ValidateIBANRequest} validateIBANRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public validateIBAN(xUserid: number, validateIBANRequest: ValidateIBANRequest, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).validateIBAN(xUserid, validateIBANRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InvoicesApi - axios parameter creator
 * @export
 */
export const InvoicesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get the details of a Benefit, Medical or Merged invoice
         * @param {string} invoiceNr The \&#39;invoiceNr\&#39; for BENEFIT, MEDICAL_AND_BENEFIT or the \&#39;medicalTaskId\&#39; for MEDICAL
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBenefitOrTaskDetail: async (invoiceNr: string, xUserid: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invoiceNr' is not null or undefined
            assertParamExists('getBenefitOrTaskDetail', 'invoiceNr', invoiceNr)
            // verify required parameter 'xUserid' is not null or undefined
            assertParamExists('getBenefitOrTaskDetail', 'xUserid', xUserid)
            const localVarPath = `/api/invoices/detail/{invoiceNr}`
                .replace(`{${"invoiceNr"}}`, encodeURIComponent(String(invoiceNr)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xUserid !== undefined && xUserid !== null) {
                localVarHeaderParameter['x-userid'] = String(JSON.stringify(xUserid));
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use this endpoint to get a benefit statement, you\'ll need the \'invoiceNr\' of a invoice entry of the category BENEFIT or MEDICAL_AND_BENEFIT, fetched thru /api/invoices | /api/invoices/medical
         * @summary Get a benefit statement document
         * @param {string} invoiceNr The \&#39;invoiceNr\&#39; of a BENEFIT or MEDICAL_AND_BENEFIT entry
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBenefitStatementDocument: async (invoiceNr: string, xUserid: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invoiceNr' is not null or undefined
            assertParamExists('getBenefitStatementDocument', 'invoiceNr', invoiceNr)
            // verify required parameter 'xUserid' is not null or undefined
            assertParamExists('getBenefitStatementDocument', 'xUserid', xUserid)
            const localVarPath = `/api/invoices/benefit/{invoiceNr}/document`
                .replace(`{${"invoiceNr"}}`, encodeURIComponent(String(invoiceNr)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xUserid !== undefined && xUserid !== null) {
                localVarHeaderParameter['x-userid'] = String(JSON.stringify(xUserid));
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint will return for the requested Tarmed document both the original and the simplified version where each page is converted to an image as a JSON. The images will be a base64 encoded string. The main usage of this data is for the Tarmed comparison slider<br> Through content negotiation, with the accept header you can set the image format (png/jpeg) to which the PDF will be converted. 
         * @summary Get both tarmed pdf versions converted to images as json response
         * @param {string} sumexId The \&#39;sumexOriginId\&#39; of a BENEFIT or MEDICAL_AND_BENEFIT entry
         * @param {string} accept 
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {Language} [language] Language for the backend system\&#39;s generated documents and localized fields. If parameter is omitted the language of the data owner is used
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCombinedTarmedJson: async (sumexId: string, accept: string, xUserid: number, language?: Language, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sumexId' is not null or undefined
            assertParamExists('getCombinedTarmedJson', 'sumexId', sumexId)
            // verify required parameter 'accept' is not null or undefined
            assertParamExists('getCombinedTarmedJson', 'accept', accept)
            // verify required parameter 'xUserid' is not null or undefined
            assertParamExists('getCombinedTarmedJson', 'xUserid', xUserid)
            const localVarPath = `/api/invoices/tarmed/{sumexId}/combined`
                .replace(`{${"sumexId"}}`, encodeURIComponent(String(sumexId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            if (accept !== undefined && accept !== null) {
                localVarHeaderParameter['accept'] = String(accept);
            }

            if (xUserid !== undefined && xUserid !== null) {
                localVarHeaderParameter['x-userid'] = String(JSON.stringify(xUserid));
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all invoices premium and medical for a whole family. If the \'insuredNr\' parameter is set, will fetch the invoices for that user. Checkout the Parameters for filtering
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {number} [insuredNr] Optional. If set will get the invoices for the specified user, else the whole family. The valid range of the insured number lies between 2000000 and 2999999
         * @param {InvoiceCategory} [invoiceCategory] If it is set and the value is PREMIUM, return list of PREMIUM invoices, if value is BENEFIT, return list of BENEFIT, BENEFIT_AND_MEDICAL and MEDICAL invoices. If ALL, return all invoices 
         * @param {Set<number>} [year] A comma separated list of four digit years (2020) or omit the parameter for all active years of the family.
         * @param {InvoiceStatus} [status] Filter for the invoices according to the given status. Default: ALL
         * @param {Language} [language] Language for the backend system\&#39;s generated documents and localized fields. If parameter is omitted the language of the data owner is used
         * @param {Set<InvoiceGroup>} [groupBy] Grouping of the invoices according to the specified fields, csv of the allowed values.&lt;br&gt; If this parameter is set will group the invoices according to the given fields. &lt;br&gt;&lt;b&gt;IMPORTANT:&lt;/b&gt;&lt;br&gt; It will transform the result and depending on the specified fields, the response will be different.&lt;br&gt; Currently only grouping by year is available&lt;br&gt; The returned object will be an object (year : list invoices).&lt;br&gt; If for a certain year there are no invoices, the year will not be present in the map.&lt;br&gt; Example:&lt;br&gt; &#x60;&#x60;&#x60; {     \&quot;2020\&quot; : [],     \&quot;2018\&quot; : []     ... } &#x60;&#x60;&#x60; If no invoices are found, an empty object is returned &#x60;&#x60;&#x60; {} &#x60;&#x60;&#x60; 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoices: async (xUserid: number, insuredNr?: number, invoiceCategory?: InvoiceCategory, year?: Set<number>, status?: InvoiceStatus, language?: Language, groupBy?: Set<InvoiceGroup>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xUserid' is not null or undefined
            assertParamExists('getInvoices', 'xUserid', xUserid)
            const localVarPath = `/api/invoices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (insuredNr !== undefined) {
                localVarQueryParameter['insuredNr'] = insuredNr;
            }

            if (invoiceCategory !== undefined) {
                localVarQueryParameter['invoiceCategory'] = invoiceCategory;
            }

            if (year) {
                localVarQueryParameter['year'] = Array.from(year);
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            if (groupBy) {
                localVarQueryParameter['groupBy'] = Array.from(groupBy);
            }

            if (xUserid !== undefined && xUserid !== null) {
                localVarHeaderParameter['x-userid'] = String(JSON.stringify(xUserid));
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the documentId of documents uploaded by the customer before the cut over ZA20XX stage 2, which were migrated from KBM to DMS.
         * @param {number} deprecatedKbmId the id of the document in the former KBM (medicalTaskId)
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getListOfDeprecatedKbmDocumentId: async (deprecatedKbmId: number, xUserid: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deprecatedKbmId' is not null or undefined
            assertParamExists('getListOfDeprecatedKbmDocumentId', 'deprecatedKbmId', deprecatedKbmId)
            // verify required parameter 'xUserid' is not null or undefined
            assertParamExists('getListOfDeprecatedKbmDocumentId', 'xUserid', xUserid)
            const localVarPath = `/api/invoices/benefit/{deprecatedKbmId}`
                .replace(`{${"deprecatedKbmId"}}`, encodeURIComponent(String(deprecatedKbmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xUserid !== undefined && xUserid !== null) {
                localVarHeaderParameter['x-userid'] = String(JSON.stringify(xUserid));
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use this endpoint to get a medical document, you\'ll need the \'documentId\' of an invoice entry of the category MEDICAL or MEDICAL_AND_BENEFIT
         * @summary Get a medical invoice document
         * @param {string} documentId The \&#39;documentId\&#39; of a medical invoice stored in the DMS
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMedicalInvoiceDocument: async (documentId: string, xUserid: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('getMedicalInvoiceDocument', 'documentId', documentId)
            // verify required parameter 'xUserid' is not null or undefined
            assertParamExists('getMedicalInvoiceDocument', 'xUserid', xUserid)
            const localVarPath = `/api/invoices/medical/{documentId}/document`
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xUserid !== undefined && xUserid !== null) {
                localVarHeaderParameter['x-userid'] = String(JSON.stringify(xUserid));
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all medical invoices for a family. If the \'insuredNr\' parameter is set, will fetch the invoices for that user. Checkout the filter parameters.
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {number} [insuredNr] Optional. If set will get the invoices for the specified user, else the whole family. The valid range of the insured number lies between 2000000 and 2999999
         * @param {InvoiceCategory} [invoiceCategory] If it is set and the value is PREMIUM, return list of PREMIUM invoices, if value is BENEFIT, return list of BENEFIT, BENEFIT_AND_MEDICAL and MEDICAL invoices. If ALL, return all invoices 
         * @param {Set<number>} [year] A comma separated list of four digit years (2020) or omit the parameter for all active years of the family.
         * @param {InvoiceStatus} [status] Filter for the invoices according to the given status. Default: ALL
         * @param {Language} [language] Language for the backend system\&#39;s generated documents and localized fields. If parameter is omitted the language of the data owner is used
         * @param {Set<InvoiceGroup>} [groupBy] Grouping of the invoices according to the specified fields, csv of the allowed values.&lt;br&gt; If this parameter is set will group the invoices according to the given fields. &lt;br&gt;&lt;b&gt;IMPORTANT:&lt;/b&gt;&lt;br&gt; It will transform the result and depending on the specified fields, the response will be different.&lt;br&gt; Currently only grouping by year is available&lt;br&gt; The returned object will be an object (year : list invoices).&lt;br&gt; If for a certain year there are no invoices, the year will not be present in the map.&lt;br&gt; Example:&lt;br&gt; &#x60;&#x60;&#x60; {     \&quot;2020\&quot; : [],     \&quot;2018\&quot; : []     ... } &#x60;&#x60;&#x60; If no invoices are found, an empty object is returned &#x60;&#x60;&#x60; {} &#x60;&#x60;&#x60; 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMedicalInvoicesForFamily: async (xUserid: number, insuredNr?: number, invoiceCategory?: InvoiceCategory, year?: Set<number>, status?: InvoiceStatus, language?: Language, groupBy?: Set<InvoiceGroup>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xUserid' is not null or undefined
            assertParamExists('getMedicalInvoicesForFamily', 'xUserid', xUserid)
            const localVarPath = `/api/invoices/medical`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (insuredNr !== undefined) {
                localVarQueryParameter['insuredNr'] = insuredNr;
            }

            if (invoiceCategory !== undefined) {
                localVarQueryParameter['invoiceCategory'] = invoiceCategory;
            }

            if (year) {
                localVarQueryParameter['year'] = Array.from(year);
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            if (groupBy) {
                localVarQueryParameter['groupBy'] = Array.from(groupBy);
            }

            if (xUserid !== undefined && xUserid !== null) {
                localVarHeaderParameter['x-userid'] = String(JSON.stringify(xUserid));
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns a list of objects with documentId and name of documents uploaded by the customer before the cut over ZA20XX stage 2, which were migrated from KBM to DMS.
         * @param {number} deprecatedKbmId the id of the document in the former KBM (medicalTaskId)
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMetaInvoiceDocumentsOfDeprecatedKbmDocumentId: async (deprecatedKbmId: number, xUserid: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deprecatedKbmId' is not null or undefined
            assertParamExists('getMetaInvoiceDocumentsOfDeprecatedKbmDocumentId', 'deprecatedKbmId', deprecatedKbmId)
            // verify required parameter 'xUserid' is not null or undefined
            assertParamExists('getMetaInvoiceDocumentsOfDeprecatedKbmDocumentId', 'xUserid', xUserid)
            const localVarPath = `/api/invoices/benefit/meta/{deprecatedKbmId}`
                .replace(`{${"deprecatedKbmId"}}`, encodeURIComponent(String(deprecatedKbmId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xUserid !== undefined && xUserid !== null) {
                localVarHeaderParameter['x-userid'] = String(JSON.stringify(xUserid));
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a count of all open invoices for a whole family. The result is cached for 1 hour.
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpenInvoices: async (xUserid: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xUserid' is not null or undefined
            assertParamExists('getOpenInvoices', 'xUserid', xUserid)
            const localVarPath = `/api/invoices/open`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xUserid !== undefined && xUserid !== null) {
                localVarHeaderParameter['x-userid'] = String(JSON.stringify(xUserid));
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Through content negotiation, you can get the data in the desired format with the accept header.
         * @summary Get an original tarmed document, with the accept header you can control the response
         * @param {string} sumexId The \&#39;sumexOriginId\&#39; of a BENEFIT or MEDICAL_AND_BENEFIT entry
         * @param {string} accept 
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {Language} [language] Language for the backend system\&#39;s generated documents and localized fields. If parameter is omitted the language of the data owner is used
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOriginalTarmedPdfJson11: async (sumexId: string, accept: string, xUserid: number, language?: Language, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sumexId' is not null or undefined
            assertParamExists('getOriginalTarmedPdfJson11', 'sumexId', sumexId)
            // verify required parameter 'accept' is not null or undefined
            assertParamExists('getOriginalTarmedPdfJson11', 'accept', accept)
            // verify required parameter 'xUserid' is not null or undefined
            assertParamExists('getOriginalTarmedPdfJson11', 'xUserid', xUserid)
            const localVarPath = `/api/invoices/tarmed/{sumexId}/original`
                .replace(`{${"sumexId"}}`, encodeURIComponent(String(sumexId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            if (accept !== undefined && accept !== null) {
                localVarHeaderParameter['accept'] = String(accept);
            }

            if (xUserid !== undefined && xUserid !== null) {
                localVarHeaderParameter['x-userid'] = String(JSON.stringify(xUserid));
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the details of a Premium invoice
         * @param {string} invoiceNr The \&#39;invoiceNr\&#39; for a PREMIUM invoice
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPremiumInvoiceDetail: async (invoiceNr: string, xUserid: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invoiceNr' is not null or undefined
            assertParamExists('getPremiumInvoiceDetail', 'invoiceNr', invoiceNr)
            // verify required parameter 'xUserid' is not null or undefined
            assertParamExists('getPremiumInvoiceDetail', 'xUserid', xUserid)
            const localVarPath = `/api/invoices/detail/premium/{invoiceNr}`
                .replace(`{${"invoiceNr"}}`, encodeURIComponent(String(invoiceNr)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xUserid !== undefined && xUserid !== null) {
                localVarHeaderParameter['x-userid'] = String(JSON.stringify(xUserid));
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Use this endpoint to get a premium invoice pdf
         * @param {string} invoiceNr The \&#39;invoiceNr\&#39; of a PREMIUM entry
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPremiumInvoiceDocument: async (invoiceNr: string, xUserid: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invoiceNr' is not null or undefined
            assertParamExists('getPremiumInvoiceDocument', 'invoiceNr', invoiceNr)
            // verify required parameter 'xUserid' is not null or undefined
            assertParamExists('getPremiumInvoiceDocument', 'xUserid', xUserid)
            const localVarPath = `/api/invoices/premium/{invoiceNr}/document`
                .replace(`{${"invoiceNr"}}`, encodeURIComponent(String(invoiceNr)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xUserid !== undefined && xUserid !== null) {
                localVarHeaderParameter['x-userid'] = String(JSON.stringify(xUserid));
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get premium invoices for a family. Checkout the Parameters for filtering.
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {Set<number>} [year] A comma separated list of four digit years (2020) or omit the parameter for all active years of the family.
         * @param {InvoiceStatus} [status] Filter for the invoices according to the given status. Default: ALL
         * @param {Language} [language] Language for the backend system\&#39;s generated documents and localized fields. If parameter is omitted the language of the data owner is used
         * @param {Set<InvoiceGroup>} [groupBy] Grouping of the invoices according to the specified fields, csv of the allowed values.&lt;br&gt; If this parameter is set will group the invoices according to the given fields. &lt;br&gt;&lt;b&gt;IMPORTANT:&lt;/b&gt;&lt;br&gt; It will transform the result and depending on the specified fields, the response will be different.&lt;br&gt; Currently only grouping by year is available&lt;br&gt; The returned object will be an object (year : list invoices).&lt;br&gt; If for a certain year there are no invoices, the year will not be present in the map.&lt;br&gt; Example:&lt;br&gt; &#x60;&#x60;&#x60; {     \&quot;2020\&quot; : [],     \&quot;2018\&quot; : []     ... } &#x60;&#x60;&#x60; If no invoices are found, an empty object is returned &#x60;&#x60;&#x60; {} &#x60;&#x60;&#x60; 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPremiumInvoices: async (xUserid: number, year?: Set<number>, status?: InvoiceStatus, language?: Language, groupBy?: Set<InvoiceGroup>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xUserid' is not null or undefined
            assertParamExists('getPremiumInvoices', 'xUserid', xUserid)
            const localVarPath = `/api/invoices/premium`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (year) {
                localVarQueryParameter['year'] = Array.from(year);
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            if (groupBy) {
                localVarQueryParameter['groupBy'] = Array.from(groupBy);
            }

            if (xUserid !== undefined && xUserid !== null) {
                localVarHeaderParameter['x-userid'] = String(JSON.stringify(xUserid));
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Through content negotiation, you can get the data in the desired format with the accept header.
         * @summary Get a simplified tarmed document, with the accept header you can control the response
         * @param {string} sumexId The \&#39;sumexOriginId\&#39; of a BENEFIT or MEDICAL_AND_BENEFIT entry
         * @param {string} accept 
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {Language} [language] Language for the backend system\&#39;s generated documents and localized fields. If parameter is omitted the language of the data owner is used
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSimplifiedTarmedPdfJson11: async (sumexId: string, accept: string, xUserid: number, language?: Language, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sumexId' is not null or undefined
            assertParamExists('getSimplifiedTarmedPdfJson11', 'sumexId', sumexId)
            // verify required parameter 'accept' is not null or undefined
            assertParamExists('getSimplifiedTarmedPdfJson11', 'accept', accept)
            // verify required parameter 'xUserid' is not null or undefined
            assertParamExists('getSimplifiedTarmedPdfJson11', 'xUserid', xUserid)
            const localVarPath = `/api/invoices/tarmed/{sumexId}/simplified`
                .replace(`{${"sumexId"}}`, encodeURIComponent(String(sumexId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            if (accept !== undefined && accept !== null) {
                localVarHeaderParameter['accept'] = String(accept);
            }

            if (xUserid !== undefined && xUserid !== null) {
                localVarHeaderParameter['x-userid'] = String(JSON.stringify(xUserid));
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * The mimeType field for the documents is optional. When not set, the type will be guessed from the content. Check the model description for more details for the required values
         * @summary Upload a medical document. it\'s possible to combine multiple pdfs / images, those will be merged into one document.
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {DocumentUploadRequest} documentUploadRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadMedicalDocument: async (xUserid: number, documentUploadRequest: DocumentUploadRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xUserid' is not null or undefined
            assertParamExists('uploadMedicalDocument', 'xUserid', xUserid)
            // verify required parameter 'documentUploadRequest' is not null or undefined
            assertParamExists('uploadMedicalDocument', 'documentUploadRequest', documentUploadRequest)
            const localVarPath = `/api/invoices/medical/documents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xUserid !== undefined && xUserid !== null) {
                localVarHeaderParameter['x-userid'] = String(JSON.stringify(xUserid));
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(documentUploadRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InvoicesApi - functional programming interface
 * @export
 */
export const InvoicesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InvoicesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get the details of a Benefit, Medical or Merged invoice
         * @param {string} invoiceNr The \&#39;invoiceNr\&#39; for BENEFIT, MEDICAL_AND_BENEFIT or the \&#39;medicalTaskId\&#39; for MEDICAL
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBenefitOrTaskDetail(invoiceNr: string, xUserid: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetMedicalInvoicesForFamily200ResponseInner>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBenefitOrTaskDetail(invoiceNr, xUserid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use this endpoint to get a benefit statement, you\'ll need the \'invoiceNr\' of a invoice entry of the category BENEFIT or MEDICAL_AND_BENEFIT, fetched thru /api/invoices | /api/invoices/medical
         * @summary Get a benefit statement document
         * @param {string} invoiceNr The \&#39;invoiceNr\&#39; of a BENEFIT or MEDICAL_AND_BENEFIT entry
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBenefitStatementDocument(invoiceNr: string, xUserid: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBenefitStatementDocument(invoiceNr, xUserid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This endpoint will return for the requested Tarmed document both the original and the simplified version where each page is converted to an image as a JSON. The images will be a base64 encoded string. The main usage of this data is for the Tarmed comparison slider<br> Through content negotiation, with the accept header you can set the image format (png/jpeg) to which the PDF will be converted. 
         * @summary Get both tarmed pdf versions converted to images as json response
         * @param {string} sumexId The \&#39;sumexOriginId\&#39; of a BENEFIT or MEDICAL_AND_BENEFIT entry
         * @param {string} accept 
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {Language} [language] Language for the backend system\&#39;s generated documents and localized fields. If parameter is omitted the language of the data owner is used
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCombinedTarmedJson(sumexId: string, accept: string, xUserid: number, language?: Language, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CombinedTarmedResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCombinedTarmedJson(sumexId, accept, xUserid, language, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all invoices premium and medical for a whole family. If the \'insuredNr\' parameter is set, will fetch the invoices for that user. Checkout the Parameters for filtering
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {number} [insuredNr] Optional. If set will get the invoices for the specified user, else the whole family. The valid range of the insured number lies between 2000000 and 2999999
         * @param {InvoiceCategory} [invoiceCategory] If it is set and the value is PREMIUM, return list of PREMIUM invoices, if value is BENEFIT, return list of BENEFIT, BENEFIT_AND_MEDICAL and MEDICAL invoices. If ALL, return all invoices 
         * @param {Set<number>} [year] A comma separated list of four digit years (2020) or omit the parameter for all active years of the family.
         * @param {InvoiceStatus} [status] Filter for the invoices according to the given status. Default: ALL
         * @param {Language} [language] Language for the backend system\&#39;s generated documents and localized fields. If parameter is omitted the language of the data owner is used
         * @param {Set<InvoiceGroup>} [groupBy] Grouping of the invoices according to the specified fields, csv of the allowed values.&lt;br&gt; If this parameter is set will group the invoices according to the given fields. &lt;br&gt;&lt;b&gt;IMPORTANT:&lt;/b&gt;&lt;br&gt; It will transform the result and depending on the specified fields, the response will be different.&lt;br&gt; Currently only grouping by year is available&lt;br&gt; The returned object will be an object (year : list invoices).&lt;br&gt; If for a certain year there are no invoices, the year will not be present in the map.&lt;br&gt; Example:&lt;br&gt; &#x60;&#x60;&#x60; {     \&quot;2020\&quot; : [],     \&quot;2018\&quot; : []     ... } &#x60;&#x60;&#x60; If no invoices are found, an empty object is returned &#x60;&#x60;&#x60; {} &#x60;&#x60;&#x60; 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInvoices(xUserid: number, insuredNr?: number, invoiceCategory?: InvoiceCategory, year?: Set<number>, status?: InvoiceStatus, language?: Language, groupBy?: Set<InvoiceGroup>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetInvoices200ResponseInner>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInvoices(xUserid, insuredNr, invoiceCategory, year, status, language, groupBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the documentId of documents uploaded by the customer before the cut over ZA20XX stage 2, which were migrated from KBM to DMS.
         * @param {number} deprecatedKbmId the id of the document in the former KBM (medicalTaskId)
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getListOfDeprecatedKbmDocumentId(deprecatedKbmId: number, xUserid: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getListOfDeprecatedKbmDocumentId(deprecatedKbmId, xUserid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use this endpoint to get a medical document, you\'ll need the \'documentId\' of an invoice entry of the category MEDICAL or MEDICAL_AND_BENEFIT
         * @summary Get a medical invoice document
         * @param {string} documentId The \&#39;documentId\&#39; of a medical invoice stored in the DMS
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMedicalInvoiceDocument(documentId: string, xUserid: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMedicalInvoiceDocument(documentId, xUserid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all medical invoices for a family. If the \'insuredNr\' parameter is set, will fetch the invoices for that user. Checkout the filter parameters.
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {number} [insuredNr] Optional. If set will get the invoices for the specified user, else the whole family. The valid range of the insured number lies between 2000000 and 2999999
         * @param {InvoiceCategory} [invoiceCategory] If it is set and the value is PREMIUM, return list of PREMIUM invoices, if value is BENEFIT, return list of BENEFIT, BENEFIT_AND_MEDICAL and MEDICAL invoices. If ALL, return all invoices 
         * @param {Set<number>} [year] A comma separated list of four digit years (2020) or omit the parameter for all active years of the family.
         * @param {InvoiceStatus} [status] Filter for the invoices according to the given status. Default: ALL
         * @param {Language} [language] Language for the backend system\&#39;s generated documents and localized fields. If parameter is omitted the language of the data owner is used
         * @param {Set<InvoiceGroup>} [groupBy] Grouping of the invoices according to the specified fields, csv of the allowed values.&lt;br&gt; If this parameter is set will group the invoices according to the given fields. &lt;br&gt;&lt;b&gt;IMPORTANT:&lt;/b&gt;&lt;br&gt; It will transform the result and depending on the specified fields, the response will be different.&lt;br&gt; Currently only grouping by year is available&lt;br&gt; The returned object will be an object (year : list invoices).&lt;br&gt; If for a certain year there are no invoices, the year will not be present in the map.&lt;br&gt; Example:&lt;br&gt; &#x60;&#x60;&#x60; {     \&quot;2020\&quot; : [],     \&quot;2018\&quot; : []     ... } &#x60;&#x60;&#x60; If no invoices are found, an empty object is returned &#x60;&#x60;&#x60; {} &#x60;&#x60;&#x60; 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMedicalInvoicesForFamily(xUserid: number, insuredNr?: number, invoiceCategory?: InvoiceCategory, year?: Set<number>, status?: InvoiceStatus, language?: Language, groupBy?: Set<InvoiceGroup>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetMedicalInvoicesForFamily200ResponseInner>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMedicalInvoicesForFamily(xUserid, insuredNr, invoiceCategory, year, status, language, groupBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns a list of objects with documentId and name of documents uploaded by the customer before the cut over ZA20XX stage 2, which were migrated from KBM to DMS.
         * @param {number} deprecatedKbmId the id of the document in the former KBM (medicalTaskId)
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMetaInvoiceDocumentsOfDeprecatedKbmDocumentId(deprecatedKbmId: number, xUserid: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMetaInvoiceDocumentsOfDeprecatedKbmDocumentId(deprecatedKbmId, xUserid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a count of all open invoices for a whole family. The result is cached for 1 hour.
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOpenInvoices(xUserid: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOpenInvoices(xUserid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Through content negotiation, you can get the data in the desired format with the accept header.
         * @summary Get an original tarmed document, with the accept header you can control the response
         * @param {string} sumexId The \&#39;sumexOriginId\&#39; of a BENEFIT or MEDICAL_AND_BENEFIT entry
         * @param {string} accept 
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {Language} [language] Language for the backend system\&#39;s generated documents and localized fields. If parameter is omitted the language of the data owner is used
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOriginalTarmedPdfJson11(sumexId: string, accept: string, xUserid: number, language?: Language, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Document>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOriginalTarmedPdfJson11(sumexId, accept, xUserid, language, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the details of a Premium invoice
         * @param {string} invoiceNr The \&#39;invoiceNr\&#39; for a PREMIUM invoice
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPremiumInvoiceDetail(invoiceNr: string, xUserid: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetPremiumInvoiceDetail200ResponseInner>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPremiumInvoiceDetail(invoiceNr, xUserid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Use this endpoint to get a premium invoice pdf
         * @param {string} invoiceNr The \&#39;invoiceNr\&#39; of a PREMIUM entry
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPremiumInvoiceDocument(invoiceNr: string, xUserid: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPremiumInvoiceDocument(invoiceNr, xUserid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get premium invoices for a family. Checkout the Parameters for filtering.
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {Set<number>} [year] A comma separated list of four digit years (2020) or omit the parameter for all active years of the family.
         * @param {InvoiceStatus} [status] Filter for the invoices according to the given status. Default: ALL
         * @param {Language} [language] Language for the backend system\&#39;s generated documents and localized fields. If parameter is omitted the language of the data owner is used
         * @param {Set<InvoiceGroup>} [groupBy] Grouping of the invoices according to the specified fields, csv of the allowed values.&lt;br&gt; If this parameter is set will group the invoices according to the given fields. &lt;br&gt;&lt;b&gt;IMPORTANT:&lt;/b&gt;&lt;br&gt; It will transform the result and depending on the specified fields, the response will be different.&lt;br&gt; Currently only grouping by year is available&lt;br&gt; The returned object will be an object (year : list invoices).&lt;br&gt; If for a certain year there are no invoices, the year will not be present in the map.&lt;br&gt; Example:&lt;br&gt; &#x60;&#x60;&#x60; {     \&quot;2020\&quot; : [],     \&quot;2018\&quot; : []     ... } &#x60;&#x60;&#x60; If no invoices are found, an empty object is returned &#x60;&#x60;&#x60; {} &#x60;&#x60;&#x60; 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPremiumInvoices(xUserid: number, year?: Set<number>, status?: InvoiceStatus, language?: Language, groupBy?: Set<InvoiceGroup>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PremiumInvoice>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPremiumInvoices(xUserid, year, status, language, groupBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Through content negotiation, you can get the data in the desired format with the accept header.
         * @summary Get a simplified tarmed document, with the accept header you can control the response
         * @param {string} sumexId The \&#39;sumexOriginId\&#39; of a BENEFIT or MEDICAL_AND_BENEFIT entry
         * @param {string} accept 
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {Language} [language] Language for the backend system\&#39;s generated documents and localized fields. If parameter is omitted the language of the data owner is used
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSimplifiedTarmedPdfJson11(sumexId: string, accept: string, xUserid: number, language?: Language, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Document>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSimplifiedTarmedPdfJson11(sumexId, accept, xUserid, language, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * The mimeType field for the documents is optional. When not set, the type will be guessed from the content. Check the model description for more details for the required values
         * @summary Upload a medical document. it\'s possible to combine multiple pdfs / images, those will be merged into one document.
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {DocumentUploadRequest} documentUploadRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadMedicalDocument(xUserid: number, documentUploadRequest: DocumentUploadRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadMedicalDocument(xUserid, documentUploadRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InvoicesApi - factory interface
 * @export
 */
export const InvoicesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InvoicesApiFp(configuration)
    return {
        /**
         * 
         * @summary Get the details of a Benefit, Medical or Merged invoice
         * @param {string} invoiceNr The \&#39;invoiceNr\&#39; for BENEFIT, MEDICAL_AND_BENEFIT or the \&#39;medicalTaskId\&#39; for MEDICAL
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBenefitOrTaskDetail(invoiceNr: string, xUserid: number, options?: any): AxiosPromise<Array<GetMedicalInvoicesForFamily200ResponseInner>> {
            return localVarFp.getBenefitOrTaskDetail(invoiceNr, xUserid, options).then((request) => request(axios, basePath));
        },
        /**
         * Use this endpoint to get a benefit statement, you\'ll need the \'invoiceNr\' of a invoice entry of the category BENEFIT or MEDICAL_AND_BENEFIT, fetched thru /api/invoices | /api/invoices/medical
         * @summary Get a benefit statement document
         * @param {string} invoiceNr The \&#39;invoiceNr\&#39; of a BENEFIT or MEDICAL_AND_BENEFIT entry
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBenefitStatementDocument(invoiceNr: string, xUserid: number, options?: any): AxiosPromise<void> {
            return localVarFp.getBenefitStatementDocument(invoiceNr, xUserid, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint will return for the requested Tarmed document both the original and the simplified version where each page is converted to an image as a JSON. The images will be a base64 encoded string. The main usage of this data is for the Tarmed comparison slider<br> Through content negotiation, with the accept header you can set the image format (png/jpeg) to which the PDF will be converted. 
         * @summary Get both tarmed pdf versions converted to images as json response
         * @param {string} sumexId The \&#39;sumexOriginId\&#39; of a BENEFIT or MEDICAL_AND_BENEFIT entry
         * @param {string} accept 
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {Language} [language] Language for the backend system\&#39;s generated documents and localized fields. If parameter is omitted the language of the data owner is used
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCombinedTarmedJson(sumexId: string, accept: string, xUserid: number, language?: Language, options?: any): AxiosPromise<CombinedTarmedResponse> {
            return localVarFp.getCombinedTarmedJson(sumexId, accept, xUserid, language, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all invoices premium and medical for a whole family. If the \'insuredNr\' parameter is set, will fetch the invoices for that user. Checkout the Parameters for filtering
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {number} [insuredNr] Optional. If set will get the invoices for the specified user, else the whole family. The valid range of the insured number lies between 2000000 and 2999999
         * @param {InvoiceCategory} [invoiceCategory] If it is set and the value is PREMIUM, return list of PREMIUM invoices, if value is BENEFIT, return list of BENEFIT, BENEFIT_AND_MEDICAL and MEDICAL invoices. If ALL, return all invoices 
         * @param {Set<number>} [year] A comma separated list of four digit years (2020) or omit the parameter for all active years of the family.
         * @param {InvoiceStatus} [status] Filter for the invoices according to the given status. Default: ALL
         * @param {Language} [language] Language for the backend system\&#39;s generated documents and localized fields. If parameter is omitted the language of the data owner is used
         * @param {Set<InvoiceGroup>} [groupBy] Grouping of the invoices according to the specified fields, csv of the allowed values.&lt;br&gt; If this parameter is set will group the invoices according to the given fields. &lt;br&gt;&lt;b&gt;IMPORTANT:&lt;/b&gt;&lt;br&gt; It will transform the result and depending on the specified fields, the response will be different.&lt;br&gt; Currently only grouping by year is available&lt;br&gt; The returned object will be an object (year : list invoices).&lt;br&gt; If for a certain year there are no invoices, the year will not be present in the map.&lt;br&gt; Example:&lt;br&gt; &#x60;&#x60;&#x60; {     \&quot;2020\&quot; : [],     \&quot;2018\&quot; : []     ... } &#x60;&#x60;&#x60; If no invoices are found, an empty object is returned &#x60;&#x60;&#x60; {} &#x60;&#x60;&#x60; 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoices(xUserid: number, insuredNr?: number, invoiceCategory?: InvoiceCategory, year?: Set<number>, status?: InvoiceStatus, language?: Language, groupBy?: Set<InvoiceGroup>, options?: any): AxiosPromise<Array<GetInvoices200ResponseInner>> {
            return localVarFp.getInvoices(xUserid, insuredNr, invoiceCategory, year, status, language, groupBy, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the documentId of documents uploaded by the customer before the cut over ZA20XX stage 2, which were migrated from KBM to DMS.
         * @param {number} deprecatedKbmId the id of the document in the former KBM (medicalTaskId)
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getListOfDeprecatedKbmDocumentId(deprecatedKbmId: number, xUserid: number, options?: any): AxiosPromise<void> {
            return localVarFp.getListOfDeprecatedKbmDocumentId(deprecatedKbmId, xUserid, options).then((request) => request(axios, basePath));
        },
        /**
         * Use this endpoint to get a medical document, you\'ll need the \'documentId\' of an invoice entry of the category MEDICAL or MEDICAL_AND_BENEFIT
         * @summary Get a medical invoice document
         * @param {string} documentId The \&#39;documentId\&#39; of a medical invoice stored in the DMS
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMedicalInvoiceDocument(documentId: string, xUserid: number, options?: any): AxiosPromise<void> {
            return localVarFp.getMedicalInvoiceDocument(documentId, xUserid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all medical invoices for a family. If the \'insuredNr\' parameter is set, will fetch the invoices for that user. Checkout the filter parameters.
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {number} [insuredNr] Optional. If set will get the invoices for the specified user, else the whole family. The valid range of the insured number lies between 2000000 and 2999999
         * @param {InvoiceCategory} [invoiceCategory] If it is set and the value is PREMIUM, return list of PREMIUM invoices, if value is BENEFIT, return list of BENEFIT, BENEFIT_AND_MEDICAL and MEDICAL invoices. If ALL, return all invoices 
         * @param {Set<number>} [year] A comma separated list of four digit years (2020) or omit the parameter for all active years of the family.
         * @param {InvoiceStatus} [status] Filter for the invoices according to the given status. Default: ALL
         * @param {Language} [language] Language for the backend system\&#39;s generated documents and localized fields. If parameter is omitted the language of the data owner is used
         * @param {Set<InvoiceGroup>} [groupBy] Grouping of the invoices according to the specified fields, csv of the allowed values.&lt;br&gt; If this parameter is set will group the invoices according to the given fields. &lt;br&gt;&lt;b&gt;IMPORTANT:&lt;/b&gt;&lt;br&gt; It will transform the result and depending on the specified fields, the response will be different.&lt;br&gt; Currently only grouping by year is available&lt;br&gt; The returned object will be an object (year : list invoices).&lt;br&gt; If for a certain year there are no invoices, the year will not be present in the map.&lt;br&gt; Example:&lt;br&gt; &#x60;&#x60;&#x60; {     \&quot;2020\&quot; : [],     \&quot;2018\&quot; : []     ... } &#x60;&#x60;&#x60; If no invoices are found, an empty object is returned &#x60;&#x60;&#x60; {} &#x60;&#x60;&#x60; 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMedicalInvoicesForFamily(xUserid: number, insuredNr?: number, invoiceCategory?: InvoiceCategory, year?: Set<number>, status?: InvoiceStatus, language?: Language, groupBy?: Set<InvoiceGroup>, options?: any): AxiosPromise<Array<GetMedicalInvoicesForFamily200ResponseInner>> {
            return localVarFp.getMedicalInvoicesForFamily(xUserid, insuredNr, invoiceCategory, year, status, language, groupBy, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns a list of objects with documentId and name of documents uploaded by the customer before the cut over ZA20XX stage 2, which were migrated from KBM to DMS.
         * @param {number} deprecatedKbmId the id of the document in the former KBM (medicalTaskId)
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMetaInvoiceDocumentsOfDeprecatedKbmDocumentId(deprecatedKbmId: number, xUserid: number, options?: any): AxiosPromise<void> {
            return localVarFp.getMetaInvoiceDocumentsOfDeprecatedKbmDocumentId(deprecatedKbmId, xUserid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a count of all open invoices for a whole family. The result is cached for 1 hour.
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpenInvoices(xUserid: number, options?: any): AxiosPromise<number> {
            return localVarFp.getOpenInvoices(xUserid, options).then((request) => request(axios, basePath));
        },
        /**
         * Through content negotiation, you can get the data in the desired format with the accept header.
         * @summary Get an original tarmed document, with the accept header you can control the response
         * @param {string} sumexId The \&#39;sumexOriginId\&#39; of a BENEFIT or MEDICAL_AND_BENEFIT entry
         * @param {string} accept 
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {Language} [language] Language for the backend system\&#39;s generated documents and localized fields. If parameter is omitted the language of the data owner is used
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOriginalTarmedPdfJson11(sumexId: string, accept: string, xUserid: number, language?: Language, options?: any): AxiosPromise<Document> {
            return localVarFp.getOriginalTarmedPdfJson11(sumexId, accept, xUserid, language, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the details of a Premium invoice
         * @param {string} invoiceNr The \&#39;invoiceNr\&#39; for a PREMIUM invoice
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPremiumInvoiceDetail(invoiceNr: string, xUserid: number, options?: any): AxiosPromise<Array<GetPremiumInvoiceDetail200ResponseInner>> {
            return localVarFp.getPremiumInvoiceDetail(invoiceNr, xUserid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Use this endpoint to get a premium invoice pdf
         * @param {string} invoiceNr The \&#39;invoiceNr\&#39; of a PREMIUM entry
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPremiumInvoiceDocument(invoiceNr: string, xUserid: number, options?: any): AxiosPromise<void> {
            return localVarFp.getPremiumInvoiceDocument(invoiceNr, xUserid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get premium invoices for a family. Checkout the Parameters for filtering.
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {Set<number>} [year] A comma separated list of four digit years (2020) or omit the parameter for all active years of the family.
         * @param {InvoiceStatus} [status] Filter for the invoices according to the given status. Default: ALL
         * @param {Language} [language] Language for the backend system\&#39;s generated documents and localized fields. If parameter is omitted the language of the data owner is used
         * @param {Set<InvoiceGroup>} [groupBy] Grouping of the invoices according to the specified fields, csv of the allowed values.&lt;br&gt; If this parameter is set will group the invoices according to the given fields. &lt;br&gt;&lt;b&gt;IMPORTANT:&lt;/b&gt;&lt;br&gt; It will transform the result and depending on the specified fields, the response will be different.&lt;br&gt; Currently only grouping by year is available&lt;br&gt; The returned object will be an object (year : list invoices).&lt;br&gt; If for a certain year there are no invoices, the year will not be present in the map.&lt;br&gt; Example:&lt;br&gt; &#x60;&#x60;&#x60; {     \&quot;2020\&quot; : [],     \&quot;2018\&quot; : []     ... } &#x60;&#x60;&#x60; If no invoices are found, an empty object is returned &#x60;&#x60;&#x60; {} &#x60;&#x60;&#x60; 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPremiumInvoices(xUserid: number, year?: Set<number>, status?: InvoiceStatus, language?: Language, groupBy?: Set<InvoiceGroup>, options?: any): AxiosPromise<Array<PremiumInvoice>> {
            return localVarFp.getPremiumInvoices(xUserid, year, status, language, groupBy, options).then((request) => request(axios, basePath));
        },
        /**
         * Through content negotiation, you can get the data in the desired format with the accept header.
         * @summary Get a simplified tarmed document, with the accept header you can control the response
         * @param {string} sumexId The \&#39;sumexOriginId\&#39; of a BENEFIT or MEDICAL_AND_BENEFIT entry
         * @param {string} accept 
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {Language} [language] Language for the backend system\&#39;s generated documents and localized fields. If parameter is omitted the language of the data owner is used
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSimplifiedTarmedPdfJson11(sumexId: string, accept: string, xUserid: number, language?: Language, options?: any): AxiosPromise<Document> {
            return localVarFp.getSimplifiedTarmedPdfJson11(sumexId, accept, xUserid, language, options).then((request) => request(axios, basePath));
        },
        /**
         * The mimeType field for the documents is optional. When not set, the type will be guessed from the content. Check the model description for more details for the required values
         * @summary Upload a medical document. it\'s possible to combine multiple pdfs / images, those will be merged into one document.
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {DocumentUploadRequest} documentUploadRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadMedicalDocument(xUserid: number, documentUploadRequest: DocumentUploadRequest, options?: any): AxiosPromise<void> {
            return localVarFp.uploadMedicalDocument(xUserid, documentUploadRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InvoicesApi - object-oriented interface
 * @export
 * @class InvoicesApi
 * @extends {BaseAPI}
 */
export class InvoicesApi extends BaseAPI {
    /**
     * 
     * @summary Get the details of a Benefit, Medical or Merged invoice
     * @param {string} invoiceNr The \&#39;invoiceNr\&#39; for BENEFIT, MEDICAL_AND_BENEFIT or the \&#39;medicalTaskId\&#39; for MEDICAL
     * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public getBenefitOrTaskDetail(invoiceNr: string, xUserid: number, options?: AxiosRequestConfig) {
        return InvoicesApiFp(this.configuration).getBenefitOrTaskDetail(invoiceNr, xUserid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use this endpoint to get a benefit statement, you\'ll need the \'invoiceNr\' of a invoice entry of the category BENEFIT or MEDICAL_AND_BENEFIT, fetched thru /api/invoices | /api/invoices/medical
     * @summary Get a benefit statement document
     * @param {string} invoiceNr The \&#39;invoiceNr\&#39; of a BENEFIT or MEDICAL_AND_BENEFIT entry
     * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public getBenefitStatementDocument(invoiceNr: string, xUserid: number, options?: AxiosRequestConfig) {
        return InvoicesApiFp(this.configuration).getBenefitStatementDocument(invoiceNr, xUserid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint will return for the requested Tarmed document both the original and the simplified version where each page is converted to an image as a JSON. The images will be a base64 encoded string. The main usage of this data is for the Tarmed comparison slider<br> Through content negotiation, with the accept header you can set the image format (png/jpeg) to which the PDF will be converted. 
     * @summary Get both tarmed pdf versions converted to images as json response
     * @param {string} sumexId The \&#39;sumexOriginId\&#39; of a BENEFIT or MEDICAL_AND_BENEFIT entry
     * @param {string} accept 
     * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
     * @param {Language} [language] Language for the backend system\&#39;s generated documents and localized fields. If parameter is omitted the language of the data owner is used
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public getCombinedTarmedJson(sumexId: string, accept: string, xUserid: number, language?: Language, options?: AxiosRequestConfig) {
        return InvoicesApiFp(this.configuration).getCombinedTarmedJson(sumexId, accept, xUserid, language, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all invoices premium and medical for a whole family. If the \'insuredNr\' parameter is set, will fetch the invoices for that user. Checkout the Parameters for filtering
     * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
     * @param {number} [insuredNr] Optional. If set will get the invoices for the specified user, else the whole family. The valid range of the insured number lies between 2000000 and 2999999
     * @param {InvoiceCategory} [invoiceCategory] If it is set and the value is PREMIUM, return list of PREMIUM invoices, if value is BENEFIT, return list of BENEFIT, BENEFIT_AND_MEDICAL and MEDICAL invoices. If ALL, return all invoices 
     * @param {Set<number>} [year] A comma separated list of four digit years (2020) or omit the parameter for all active years of the family.
     * @param {InvoiceStatus} [status] Filter for the invoices according to the given status. Default: ALL
     * @param {Language} [language] Language for the backend system\&#39;s generated documents and localized fields. If parameter is omitted the language of the data owner is used
     * @param {Set<InvoiceGroup>} [groupBy] Grouping of the invoices according to the specified fields, csv of the allowed values.&lt;br&gt; If this parameter is set will group the invoices according to the given fields. &lt;br&gt;&lt;b&gt;IMPORTANT:&lt;/b&gt;&lt;br&gt; It will transform the result and depending on the specified fields, the response will be different.&lt;br&gt; Currently only grouping by year is available&lt;br&gt; The returned object will be an object (year : list invoices).&lt;br&gt; If for a certain year there are no invoices, the year will not be present in the map.&lt;br&gt; Example:&lt;br&gt; &#x60;&#x60;&#x60; {     \&quot;2020\&quot; : [],     \&quot;2018\&quot; : []     ... } &#x60;&#x60;&#x60; If no invoices are found, an empty object is returned &#x60;&#x60;&#x60; {} &#x60;&#x60;&#x60; 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public getInvoices(xUserid: number, insuredNr?: number, invoiceCategory?: InvoiceCategory, year?: Set<number>, status?: InvoiceStatus, language?: Language, groupBy?: Set<InvoiceGroup>, options?: AxiosRequestConfig) {
        return InvoicesApiFp(this.configuration).getInvoices(xUserid, insuredNr, invoiceCategory, year, status, language, groupBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the documentId of documents uploaded by the customer before the cut over ZA20XX stage 2, which were migrated from KBM to DMS.
     * @param {number} deprecatedKbmId the id of the document in the former KBM (medicalTaskId)
     * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public getListOfDeprecatedKbmDocumentId(deprecatedKbmId: number, xUserid: number, options?: AxiosRequestConfig) {
        return InvoicesApiFp(this.configuration).getListOfDeprecatedKbmDocumentId(deprecatedKbmId, xUserid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Use this endpoint to get a medical document, you\'ll need the \'documentId\' of an invoice entry of the category MEDICAL or MEDICAL_AND_BENEFIT
     * @summary Get a medical invoice document
     * @param {string} documentId The \&#39;documentId\&#39; of a medical invoice stored in the DMS
     * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public getMedicalInvoiceDocument(documentId: string, xUserid: number, options?: AxiosRequestConfig) {
        return InvoicesApiFp(this.configuration).getMedicalInvoiceDocument(documentId, xUserid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all medical invoices for a family. If the \'insuredNr\' parameter is set, will fetch the invoices for that user. Checkout the filter parameters.
     * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
     * @param {number} [insuredNr] Optional. If set will get the invoices for the specified user, else the whole family. The valid range of the insured number lies between 2000000 and 2999999
     * @param {InvoiceCategory} [invoiceCategory] If it is set and the value is PREMIUM, return list of PREMIUM invoices, if value is BENEFIT, return list of BENEFIT, BENEFIT_AND_MEDICAL and MEDICAL invoices. If ALL, return all invoices 
     * @param {Set<number>} [year] A comma separated list of four digit years (2020) or omit the parameter for all active years of the family.
     * @param {InvoiceStatus} [status] Filter for the invoices according to the given status. Default: ALL
     * @param {Language} [language] Language for the backend system\&#39;s generated documents and localized fields. If parameter is omitted the language of the data owner is used
     * @param {Set<InvoiceGroup>} [groupBy] Grouping of the invoices according to the specified fields, csv of the allowed values.&lt;br&gt; If this parameter is set will group the invoices according to the given fields. &lt;br&gt;&lt;b&gt;IMPORTANT:&lt;/b&gt;&lt;br&gt; It will transform the result and depending on the specified fields, the response will be different.&lt;br&gt; Currently only grouping by year is available&lt;br&gt; The returned object will be an object (year : list invoices).&lt;br&gt; If for a certain year there are no invoices, the year will not be present in the map.&lt;br&gt; Example:&lt;br&gt; &#x60;&#x60;&#x60; {     \&quot;2020\&quot; : [],     \&quot;2018\&quot; : []     ... } &#x60;&#x60;&#x60; If no invoices are found, an empty object is returned &#x60;&#x60;&#x60; {} &#x60;&#x60;&#x60; 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public getMedicalInvoicesForFamily(xUserid: number, insuredNr?: number, invoiceCategory?: InvoiceCategory, year?: Set<number>, status?: InvoiceStatus, language?: Language, groupBy?: Set<InvoiceGroup>, options?: AxiosRequestConfig) {
        return InvoicesApiFp(this.configuration).getMedicalInvoicesForFamily(xUserid, insuredNr, invoiceCategory, year, status, language, groupBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns a list of objects with documentId and name of documents uploaded by the customer before the cut over ZA20XX stage 2, which were migrated from KBM to DMS.
     * @param {number} deprecatedKbmId the id of the document in the former KBM (medicalTaskId)
     * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public getMetaInvoiceDocumentsOfDeprecatedKbmDocumentId(deprecatedKbmId: number, xUserid: number, options?: AxiosRequestConfig) {
        return InvoicesApiFp(this.configuration).getMetaInvoiceDocumentsOfDeprecatedKbmDocumentId(deprecatedKbmId, xUserid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a count of all open invoices for a whole family. The result is cached for 1 hour.
     * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public getOpenInvoices(xUserid: number, options?: AxiosRequestConfig) {
        return InvoicesApiFp(this.configuration).getOpenInvoices(xUserid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Through content negotiation, you can get the data in the desired format with the accept header.
     * @summary Get an original tarmed document, with the accept header you can control the response
     * @param {string} sumexId The \&#39;sumexOriginId\&#39; of a BENEFIT or MEDICAL_AND_BENEFIT entry
     * @param {string} accept 
     * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
     * @param {Language} [language] Language for the backend system\&#39;s generated documents and localized fields. If parameter is omitted the language of the data owner is used
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public getOriginalTarmedPdfJson11(sumexId: string, accept: string, xUserid: number, language?: Language, options?: AxiosRequestConfig) {
        return InvoicesApiFp(this.configuration).getOriginalTarmedPdfJson11(sumexId, accept, xUserid, language, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the details of a Premium invoice
     * @param {string} invoiceNr The \&#39;invoiceNr\&#39; for a PREMIUM invoice
     * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public getPremiumInvoiceDetail(invoiceNr: string, xUserid: number, options?: AxiosRequestConfig) {
        return InvoicesApiFp(this.configuration).getPremiumInvoiceDetail(invoiceNr, xUserid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Use this endpoint to get a premium invoice pdf
     * @param {string} invoiceNr The \&#39;invoiceNr\&#39; of a PREMIUM entry
     * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public getPremiumInvoiceDocument(invoiceNr: string, xUserid: number, options?: AxiosRequestConfig) {
        return InvoicesApiFp(this.configuration).getPremiumInvoiceDocument(invoiceNr, xUserid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get premium invoices for a family. Checkout the Parameters for filtering.
     * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
     * @param {Set<number>} [year] A comma separated list of four digit years (2020) or omit the parameter for all active years of the family.
     * @param {InvoiceStatus} [status] Filter for the invoices according to the given status. Default: ALL
     * @param {Language} [language] Language for the backend system\&#39;s generated documents and localized fields. If parameter is omitted the language of the data owner is used
     * @param {Set<InvoiceGroup>} [groupBy] Grouping of the invoices according to the specified fields, csv of the allowed values.&lt;br&gt; If this parameter is set will group the invoices according to the given fields. &lt;br&gt;&lt;b&gt;IMPORTANT:&lt;/b&gt;&lt;br&gt; It will transform the result and depending on the specified fields, the response will be different.&lt;br&gt; Currently only grouping by year is available&lt;br&gt; The returned object will be an object (year : list invoices).&lt;br&gt; If for a certain year there are no invoices, the year will not be present in the map.&lt;br&gt; Example:&lt;br&gt; &#x60;&#x60;&#x60; {     \&quot;2020\&quot; : [],     \&quot;2018\&quot; : []     ... } &#x60;&#x60;&#x60; If no invoices are found, an empty object is returned &#x60;&#x60;&#x60; {} &#x60;&#x60;&#x60; 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public getPremiumInvoices(xUserid: number, year?: Set<number>, status?: InvoiceStatus, language?: Language, groupBy?: Set<InvoiceGroup>, options?: AxiosRequestConfig) {
        return InvoicesApiFp(this.configuration).getPremiumInvoices(xUserid, year, status, language, groupBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Through content negotiation, you can get the data in the desired format with the accept header.
     * @summary Get a simplified tarmed document, with the accept header you can control the response
     * @param {string} sumexId The \&#39;sumexOriginId\&#39; of a BENEFIT or MEDICAL_AND_BENEFIT entry
     * @param {string} accept 
     * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
     * @param {Language} [language] Language for the backend system\&#39;s generated documents and localized fields. If parameter is omitted the language of the data owner is used
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public getSimplifiedTarmedPdfJson11(sumexId: string, accept: string, xUserid: number, language?: Language, options?: AxiosRequestConfig) {
        return InvoicesApiFp(this.configuration).getSimplifiedTarmedPdfJson11(sumexId, accept, xUserid, language, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * The mimeType field for the documents is optional. When not set, the type will be guessed from the content. Check the model description for more details for the required values
     * @summary Upload a medical document. it\'s possible to combine multiple pdfs / images, those will be merged into one document.
     * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
     * @param {DocumentUploadRequest} documentUploadRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public uploadMedicalDocument(xUserid: number, documentUploadRequest: DocumentUploadRequest, options?: AxiosRequestConfig) {
        return InvoicesApiFp(this.configuration).uploadMedicalDocument(xUserid, documentUploadRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MessagesApi - axios parameter creator
 * @export
 */
export const MessagesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a new message in the CRM system and store the optional documents in the DMS system
         * @param {number} insuredNr The valid range of the insured number lies between 2000000 and 2999999
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {CreateMessageRequest} createMessageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMessage: async (insuredNr: number, xUserid: number, createMessageRequest: CreateMessageRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'insuredNr' is not null or undefined
            assertParamExists('createMessage', 'insuredNr', insuredNr)
            // verify required parameter 'xUserid' is not null or undefined
            assertParamExists('createMessage', 'xUserid', xUserid)
            // verify required parameter 'createMessageRequest' is not null or undefined
            assertParamExists('createMessage', 'createMessageRequest', createMessageRequest)
            const localVarPath = `/api/messages/{insuredNr}`
                .replace(`{${"insuredNr"}}`, encodeURIComponent(String(insuredNr)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xUserid !== undefined && xUserid !== null) {
                localVarHeaderParameter['x-userid'] = String(JSON.stringify(xUserid));
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createMessageRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all messages of all users in the family
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {string} [fromDateCreated] Date (YYYY-MM-DD), start of desired period (2016-02-22).
         * @param {string} [toDateCreated] Date (YYYY-MM-DD), end date of the desired period (2020-12-31).
         * @param {string} [messageId] Unique ID of the message
         * @param {string} [taskId] Unique ID of the task
         * @param {string} [messageContent] Optional, has no constraints. If used, will search through the messages for the given string. The fields \&#39;content\&#39; and \&#39;attachments.name\&#39; of each message are checked for the given string.
         * @param {number} [insuredId] InsuredId of the person requesting the search. If the insuredNr is in the path, this value will be overwritten.
         * @param {boolean} [read] If true, will only return read messages.
         * @param {boolean} [deleted] If true, will only return deleted messages.
         * @param {number} [page] The number of the desired page. Index starts at 1. If not set will return the first page
         * @param {number} [size] The number of entries in the content list per page. Default: 10
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllMessagesForFamily: async (xUserid: number, fromDateCreated?: string, toDateCreated?: string, messageId?: string, taskId?: string, messageContent?: string, insuredId?: number, read?: boolean, deleted?: boolean, page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xUserid' is not null or undefined
            assertParamExists('getAllMessagesForFamily', 'xUserid', xUserid)
            const localVarPath = `/api/messages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (fromDateCreated !== undefined) {
                localVarQueryParameter['fromDateCreated'] = (fromDateCreated as any instanceof Date) ?
                    (fromDateCreated as any).toISOString().substr(0,10) :
                    fromDateCreated;
            }

            if (toDateCreated !== undefined) {
                localVarQueryParameter['toDateCreated'] = (toDateCreated as any instanceof Date) ?
                    (toDateCreated as any).toISOString().substr(0,10) :
                    toDateCreated;
            }

            if (messageId !== undefined) {
                localVarQueryParameter['messageId'] = messageId;
            }

            if (taskId !== undefined) {
                localVarQueryParameter['taskId'] = taskId;
            }

            if (messageContent !== undefined) {
                localVarQueryParameter['messageContent'] = messageContent;
            }

            if (insuredId !== undefined) {
                localVarQueryParameter['insuredId'] = insuredId;
            }

            if (read !== undefined) {
                localVarQueryParameter['read'] = read;
            }

            if (deleted !== undefined) {
                localVarQueryParameter['deleted'] = deleted;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (xUserid !== undefined && xUserid !== null) {
                localVarHeaderParameter['x-userid'] = String(JSON.stringify(xUserid));
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all messages of single user
         * @param {number} insuredNr The valid range of the insured number lies between 2000000 and 2999999
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {string} [fromDateCreated] Date (YYYY-MM-DD), start of desired period (2016-02-22).
         * @param {string} [toDateCreated] Date (YYYY-MM-DD), end date of the desired period (2020-12-31).
         * @param {string} [messageId] Unique ID of the message
         * @param {string} [taskId] Unique ID of the task
         * @param {string} [messageContent] Optional, has no constraints. If used, will search through the messages for the given string. The fields \&#39;content\&#39; and \&#39;attachments.name\&#39; of each message are checked for the given string.
         * @param {number} [insuredId] InsuredId of the person requesting the search. If the insuredNr is in the path, this value will be overwritten.
         * @param {boolean} [read] If true, will only return read messages.
         * @param {boolean} [deleted] If true, will only return deleted messages.
         * @param {number} [page] The number of the desired page. Index starts at 1. If not set will return the first page
         * @param {number} [size] The number of entries in the content list per page. Default: 10
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllMessagesForUser: async (insuredNr: number, xUserid: number, fromDateCreated?: string, toDateCreated?: string, messageId?: string, taskId?: string, messageContent?: string, insuredId?: number, read?: boolean, deleted?: boolean, page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'insuredNr' is not null or undefined
            assertParamExists('getAllMessagesForUser', 'insuredNr', insuredNr)
            // verify required parameter 'xUserid' is not null or undefined
            assertParamExists('getAllMessagesForUser', 'xUserid', xUserid)
            const localVarPath = `/api/messages/{insuredNr}`
                .replace(`{${"insuredNr"}}`, encodeURIComponent(String(insuredNr)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (fromDateCreated !== undefined) {
                localVarQueryParameter['fromDateCreated'] = (fromDateCreated as any instanceof Date) ?
                    (fromDateCreated as any).toISOString().substr(0,10) :
                    fromDateCreated;
            }

            if (toDateCreated !== undefined) {
                localVarQueryParameter['toDateCreated'] = (toDateCreated as any instanceof Date) ?
                    (toDateCreated as any).toISOString().substr(0,10) :
                    toDateCreated;
            }

            if (messageId !== undefined) {
                localVarQueryParameter['messageId'] = messageId;
            }

            if (taskId !== undefined) {
                localVarQueryParameter['taskId'] = taskId;
            }

            if (messageContent !== undefined) {
                localVarQueryParameter['messageContent'] = messageContent;
            }

            if (insuredId !== undefined) {
                localVarQueryParameter['insuredId'] = insuredId;
            }

            if (read !== undefined) {
                localVarQueryParameter['read'] = read;
            }

            if (deleted !== undefined) {
                localVarQueryParameter['deleted'] = deleted;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (xUserid !== undefined && xUserid !== null) {
                localVarHeaderParameter['x-userid'] = String(JSON.stringify(xUserid));
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get an attachment from message, requires the documentId
         * @param {string} documentId The \&#39;documentId\&#39; of a document stored in the DMS
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessageAttachment: async (documentId: string, xUserid: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('getMessageAttachment', 'documentId', documentId)
            // verify required parameter 'xUserid' is not null or undefined
            assertParamExists('getMessageAttachment', 'xUserid', xUserid)
            const localVarPath = `/api/messages/{documentId}/document`
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xUserid !== undefined && xUserid !== null) {
                localVarHeaderParameter['x-userid'] = String(JSON.stringify(xUserid));
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the message group for a given message Id from all users in the family.
         * @param {string} messageId Message Id
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {string} [fromDateCreated] Date (YYYY-MM-DD), start of desired period (2016-02-22).
         * @param {string} [toDateCreated] Date (YYYY-MM-DD), end date of the desired period (2020-12-31).
         * @param {string} [taskId] Unique ID of the task
         * @param {string} [messageContent] Optional, has no constraints. If used, will search through the messages for the given string. The fields \&#39;content\&#39; and \&#39;attachments.name\&#39; of each message are checked for the given string.
         * @param {number} [insuredId] InsuredId of the person requesting the search. If the insuredNr is in the path, this value will be overwritten.
         * @param {boolean} [read] If true, will only return read messages.
         * @param {boolean} [deleted] If true, will only return deleted messages.
         * @param {number} [page] The number of the desired page. Index starts at 1. If not set will return the first page
         * @param {number} [size] The number of entries in the content list per page. Default: 10
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessageGroupPerMessageIdForFamily: async (messageId: string, xUserid: number, fromDateCreated?: string, toDateCreated?: string, taskId?: string, messageContent?: string, insuredId?: number, read?: boolean, deleted?: boolean, page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'messageId' is not null or undefined
            assertParamExists('getMessageGroupPerMessageIdForFamily', 'messageId', messageId)
            // verify required parameter 'xUserid' is not null or undefined
            assertParamExists('getMessageGroupPerMessageIdForFamily', 'xUserid', xUserid)
            const localVarPath = `/api/messages/{messageId}/message`
                .replace(`{${"messageId"}}`, encodeURIComponent(String(messageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (fromDateCreated !== undefined) {
                localVarQueryParameter['fromDateCreated'] = (fromDateCreated as any instanceof Date) ?
                    (fromDateCreated as any).toISOString().substr(0,10) :
                    fromDateCreated;
            }

            if (toDateCreated !== undefined) {
                localVarQueryParameter['toDateCreated'] = (toDateCreated as any instanceof Date) ?
                    (toDateCreated as any).toISOString().substr(0,10) :
                    toDateCreated;
            }

            if (taskId !== undefined) {
                localVarQueryParameter['taskId'] = taskId;
            }

            if (messageContent !== undefined) {
                localVarQueryParameter['messageContent'] = messageContent;
            }

            if (insuredId !== undefined) {
                localVarQueryParameter['insuredId'] = insuredId;
            }

            if (read !== undefined) {
                localVarQueryParameter['read'] = read;
            }

            if (deleted !== undefined) {
                localVarQueryParameter['deleted'] = deleted;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (xUserid !== undefined && xUserid !== null) {
                localVarHeaderParameter['x-userid'] = String(JSON.stringify(xUserid));
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all available thread types
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRequestTypes: async (xUserid: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xUserid' is not null or undefined
            assertParamExists('getRequestTypes', 'xUserid', xUserid)
            const localVarPath = `/api/messages/requestTypes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xUserid !== undefined && xUserid !== null) {
                localVarHeaderParameter['x-userid'] = String(JSON.stringify(xUserid));
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a count of all unread messages
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnreadMessagesCount: async (xUserid: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xUserid' is not null or undefined
            assertParamExists('getUnreadMessagesCount', 'xUserid', xUserid)
            const localVarPath = `/api/messages/unread`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xUserid !== undefined && xUserid !== null) {
                localVarHeaderParameter['x-userid'] = String(JSON.stringify(xUserid));
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Mark a message as deleted. Note: will also mark it as read
         * @param {string} messageId 
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markMessageAsDeleted: async (messageId: string, xUserid: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'messageId' is not null or undefined
            assertParamExists('markMessageAsDeleted', 'messageId', messageId)
            // verify required parameter 'xUserid' is not null or undefined
            assertParamExists('markMessageAsDeleted', 'xUserid', xUserid)
            const localVarPath = `/api/messages/{messageId}`
                .replace(`{${"messageId"}}`, encodeURIComponent(String(messageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xUserid !== undefined && xUserid !== null) {
                localVarHeaderParameter['x-userid'] = String(JSON.stringify(xUserid));
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Mark the message as read
         * @param {string} messageId 
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markMessageAsRead: async (messageId: string, xUserid: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'messageId' is not null or undefined
            assertParamExists('markMessageAsRead', 'messageId', messageId)
            // verify required parameter 'xUserid' is not null or undefined
            assertParamExists('markMessageAsRead', 'xUserid', xUserid)
            const localVarPath = `/api/messages/{messageId}`
                .replace(`{${"messageId"}}`, encodeURIComponent(String(messageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xUserid !== undefined && xUserid !== null) {
                localVarHeaderParameter['x-userid'] = String(JSON.stringify(xUserid));
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MessagesApi - functional programming interface
 * @export
 */
export const MessagesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MessagesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a new message in the CRM system and store the optional documents in the DMS system
         * @param {number} insuredNr The valid range of the insured number lies between 2000000 and 2999999
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {CreateMessageRequest} createMessageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createMessage(insuredNr: number, xUserid: number, createMessageRequest: CreateMessageRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createMessage(insuredNr, xUserid, createMessageRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all messages of all users in the family
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {string} [fromDateCreated] Date (YYYY-MM-DD), start of desired period (2016-02-22).
         * @param {string} [toDateCreated] Date (YYYY-MM-DD), end date of the desired period (2020-12-31).
         * @param {string} [messageId] Unique ID of the message
         * @param {string} [taskId] Unique ID of the task
         * @param {string} [messageContent] Optional, has no constraints. If used, will search through the messages for the given string. The fields \&#39;content\&#39; and \&#39;attachments.name\&#39; of each message are checked for the given string.
         * @param {number} [insuredId] InsuredId of the person requesting the search. If the insuredNr is in the path, this value will be overwritten.
         * @param {boolean} [read] If true, will only return read messages.
         * @param {boolean} [deleted] If true, will only return deleted messages.
         * @param {number} [page] The number of the desired page. Index starts at 1. If not set will return the first page
         * @param {number} [size] The number of entries in the content list per page. Default: 10
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllMessagesForFamily(xUserid: number, fromDateCreated?: string, toDateCreated?: string, messageId?: string, taskId?: string, messageContent?: string, insuredId?: number, read?: boolean, deleted?: boolean, page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageResponseMessageGroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllMessagesForFamily(xUserid, fromDateCreated, toDateCreated, messageId, taskId, messageContent, insuredId, read, deleted, page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all messages of single user
         * @param {number} insuredNr The valid range of the insured number lies between 2000000 and 2999999
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {string} [fromDateCreated] Date (YYYY-MM-DD), start of desired period (2016-02-22).
         * @param {string} [toDateCreated] Date (YYYY-MM-DD), end date of the desired period (2020-12-31).
         * @param {string} [messageId] Unique ID of the message
         * @param {string} [taskId] Unique ID of the task
         * @param {string} [messageContent] Optional, has no constraints. If used, will search through the messages for the given string. The fields \&#39;content\&#39; and \&#39;attachments.name\&#39; of each message are checked for the given string.
         * @param {number} [insuredId] InsuredId of the person requesting the search. If the insuredNr is in the path, this value will be overwritten.
         * @param {boolean} [read] If true, will only return read messages.
         * @param {boolean} [deleted] If true, will only return deleted messages.
         * @param {number} [page] The number of the desired page. Index starts at 1. If not set will return the first page
         * @param {number} [size] The number of entries in the content list per page. Default: 10
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllMessagesForUser(insuredNr: number, xUserid: number, fromDateCreated?: string, toDateCreated?: string, messageId?: string, taskId?: string, messageContent?: string, insuredId?: number, read?: boolean, deleted?: boolean, page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageResponseMessageGroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllMessagesForUser(insuredNr, xUserid, fromDateCreated, toDateCreated, messageId, taskId, messageContent, insuredId, read, deleted, page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get an attachment from message, requires the documentId
         * @param {string} documentId The \&#39;documentId\&#39; of a document stored in the DMS
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMessageAttachment(documentId: string, xUserid: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMessageAttachment(documentId, xUserid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the message group for a given message Id from all users in the family.
         * @param {string} messageId Message Id
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {string} [fromDateCreated] Date (YYYY-MM-DD), start of desired period (2016-02-22).
         * @param {string} [toDateCreated] Date (YYYY-MM-DD), end date of the desired period (2020-12-31).
         * @param {string} [taskId] Unique ID of the task
         * @param {string} [messageContent] Optional, has no constraints. If used, will search through the messages for the given string. The fields \&#39;content\&#39; and \&#39;attachments.name\&#39; of each message are checked for the given string.
         * @param {number} [insuredId] InsuredId of the person requesting the search. If the insuredNr is in the path, this value will be overwritten.
         * @param {boolean} [read] If true, will only return read messages.
         * @param {boolean} [deleted] If true, will only return deleted messages.
         * @param {number} [page] The number of the desired page. Index starts at 1. If not set will return the first page
         * @param {number} [size] The number of entries in the content list per page. Default: 10
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMessageGroupPerMessageIdForFamily(messageId: string, xUserid: number, fromDateCreated?: string, toDateCreated?: string, taskId?: string, messageContent?: string, insuredId?: number, read?: boolean, deleted?: boolean, page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageGroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMessageGroupPerMessageIdForFamily(messageId, xUserid, fromDateCreated, toDateCreated, taskId, messageContent, insuredId, read, deleted, page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all available thread types
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRequestTypes(xUserid: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRequestTypes(xUserid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a count of all unread messages
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUnreadMessagesCount(xUserid: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUnreadMessagesCount(xUserid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Mark a message as deleted. Note: will also mark it as read
         * @param {string} messageId 
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markMessageAsDeleted(messageId: string, xUserid: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.markMessageAsDeleted(messageId, xUserid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Mark the message as read
         * @param {string} messageId 
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markMessageAsRead(messageId: string, xUserid: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.markMessageAsRead(messageId, xUserid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MessagesApi - factory interface
 * @export
 */
export const MessagesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MessagesApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a new message in the CRM system and store the optional documents in the DMS system
         * @param {number} insuredNr The valid range of the insured number lies between 2000000 and 2999999
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {CreateMessageRequest} createMessageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMessage(insuredNr: number, xUserid: number, createMessageRequest: CreateMessageRequest, options?: any): AxiosPromise<void> {
            return localVarFp.createMessage(insuredNr, xUserid, createMessageRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all messages of all users in the family
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {string} [fromDateCreated] Date (YYYY-MM-DD), start of desired period (2016-02-22).
         * @param {string} [toDateCreated] Date (YYYY-MM-DD), end date of the desired period (2020-12-31).
         * @param {string} [messageId] Unique ID of the message
         * @param {string} [taskId] Unique ID of the task
         * @param {string} [messageContent] Optional, has no constraints. If used, will search through the messages for the given string. The fields \&#39;content\&#39; and \&#39;attachments.name\&#39; of each message are checked for the given string.
         * @param {number} [insuredId] InsuredId of the person requesting the search. If the insuredNr is in the path, this value will be overwritten.
         * @param {boolean} [read] If true, will only return read messages.
         * @param {boolean} [deleted] If true, will only return deleted messages.
         * @param {number} [page] The number of the desired page. Index starts at 1. If not set will return the first page
         * @param {number} [size] The number of entries in the content list per page. Default: 10
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllMessagesForFamily(xUserid: number, fromDateCreated?: string, toDateCreated?: string, messageId?: string, taskId?: string, messageContent?: string, insuredId?: number, read?: boolean, deleted?: boolean, page?: number, size?: number, options?: any): AxiosPromise<PageResponseMessageGroup> {
            return localVarFp.getAllMessagesForFamily(xUserid, fromDateCreated, toDateCreated, messageId, taskId, messageContent, insuredId, read, deleted, page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all messages of single user
         * @param {number} insuredNr The valid range of the insured number lies between 2000000 and 2999999
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {string} [fromDateCreated] Date (YYYY-MM-DD), start of desired period (2016-02-22).
         * @param {string} [toDateCreated] Date (YYYY-MM-DD), end date of the desired period (2020-12-31).
         * @param {string} [messageId] Unique ID of the message
         * @param {string} [taskId] Unique ID of the task
         * @param {string} [messageContent] Optional, has no constraints. If used, will search through the messages for the given string. The fields \&#39;content\&#39; and \&#39;attachments.name\&#39; of each message are checked for the given string.
         * @param {number} [insuredId] InsuredId of the person requesting the search. If the insuredNr is in the path, this value will be overwritten.
         * @param {boolean} [read] If true, will only return read messages.
         * @param {boolean} [deleted] If true, will only return deleted messages.
         * @param {number} [page] The number of the desired page. Index starts at 1. If not set will return the first page
         * @param {number} [size] The number of entries in the content list per page. Default: 10
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllMessagesForUser(insuredNr: number, xUserid: number, fromDateCreated?: string, toDateCreated?: string, messageId?: string, taskId?: string, messageContent?: string, insuredId?: number, read?: boolean, deleted?: boolean, page?: number, size?: number, options?: any): AxiosPromise<PageResponseMessageGroup> {
            return localVarFp.getAllMessagesForUser(insuredNr, xUserid, fromDateCreated, toDateCreated, messageId, taskId, messageContent, insuredId, read, deleted, page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get an attachment from message, requires the documentId
         * @param {string} documentId The \&#39;documentId\&#39; of a document stored in the DMS
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessageAttachment(documentId: string, xUserid: number, options?: any): AxiosPromise<void> {
            return localVarFp.getMessageAttachment(documentId, xUserid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the message group for a given message Id from all users in the family.
         * @param {string} messageId Message Id
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {string} [fromDateCreated] Date (YYYY-MM-DD), start of desired period (2016-02-22).
         * @param {string} [toDateCreated] Date (YYYY-MM-DD), end date of the desired period (2020-12-31).
         * @param {string} [taskId] Unique ID of the task
         * @param {string} [messageContent] Optional, has no constraints. If used, will search through the messages for the given string. The fields \&#39;content\&#39; and \&#39;attachments.name\&#39; of each message are checked for the given string.
         * @param {number} [insuredId] InsuredId of the person requesting the search. If the insuredNr is in the path, this value will be overwritten.
         * @param {boolean} [read] If true, will only return read messages.
         * @param {boolean} [deleted] If true, will only return deleted messages.
         * @param {number} [page] The number of the desired page. Index starts at 1. If not set will return the first page
         * @param {number} [size] The number of entries in the content list per page. Default: 10
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessageGroupPerMessageIdForFamily(messageId: string, xUserid: number, fromDateCreated?: string, toDateCreated?: string, taskId?: string, messageContent?: string, insuredId?: number, read?: boolean, deleted?: boolean, page?: number, size?: number, options?: any): AxiosPromise<MessageGroup> {
            return localVarFp.getMessageGroupPerMessageIdForFamily(messageId, xUserid, fromDateCreated, toDateCreated, taskId, messageContent, insuredId, read, deleted, page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all available thread types
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRequestTypes(xUserid: number, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getRequestTypes(xUserid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a count of all unread messages
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnreadMessagesCount(xUserid: number, options?: any): AxiosPromise<number> {
            return localVarFp.getUnreadMessagesCount(xUserid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Mark a message as deleted. Note: will also mark it as read
         * @param {string} messageId 
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markMessageAsDeleted(messageId: string, xUserid: number, options?: any): AxiosPromise<void> {
            return localVarFp.markMessageAsDeleted(messageId, xUserid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Mark the message as read
         * @param {string} messageId 
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markMessageAsRead(messageId: string, xUserid: number, options?: any): AxiosPromise<void> {
            return localVarFp.markMessageAsRead(messageId, xUserid, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MessagesApi - object-oriented interface
 * @export
 * @class MessagesApi
 * @extends {BaseAPI}
 */
export class MessagesApi extends BaseAPI {
    /**
     * 
     * @summary Create a new message in the CRM system and store the optional documents in the DMS system
     * @param {number} insuredNr The valid range of the insured number lies between 2000000 and 2999999
     * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
     * @param {CreateMessageRequest} createMessageRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagesApi
     */
    public createMessage(insuredNr: number, xUserid: number, createMessageRequest: CreateMessageRequest, options?: AxiosRequestConfig) {
        return MessagesApiFp(this.configuration).createMessage(insuredNr, xUserid, createMessageRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all messages of all users in the family
     * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
     * @param {string} [fromDateCreated] Date (YYYY-MM-DD), start of desired period (2016-02-22).
     * @param {string} [toDateCreated] Date (YYYY-MM-DD), end date of the desired period (2020-12-31).
     * @param {string} [messageId] Unique ID of the message
     * @param {string} [taskId] Unique ID of the task
     * @param {string} [messageContent] Optional, has no constraints. If used, will search through the messages for the given string. The fields \&#39;content\&#39; and \&#39;attachments.name\&#39; of each message are checked for the given string.
     * @param {number} [insuredId] InsuredId of the person requesting the search. If the insuredNr is in the path, this value will be overwritten.
     * @param {boolean} [read] If true, will only return read messages.
     * @param {boolean} [deleted] If true, will only return deleted messages.
     * @param {number} [page] The number of the desired page. Index starts at 1. If not set will return the first page
     * @param {number} [size] The number of entries in the content list per page. Default: 10
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagesApi
     */
    public getAllMessagesForFamily(xUserid: number, fromDateCreated?: string, toDateCreated?: string, messageId?: string, taskId?: string, messageContent?: string, insuredId?: number, read?: boolean, deleted?: boolean, page?: number, size?: number, options?: AxiosRequestConfig) {
        return MessagesApiFp(this.configuration).getAllMessagesForFamily(xUserid, fromDateCreated, toDateCreated, messageId, taskId, messageContent, insuredId, read, deleted, page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all messages of single user
     * @param {number} insuredNr The valid range of the insured number lies between 2000000 and 2999999
     * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
     * @param {string} [fromDateCreated] Date (YYYY-MM-DD), start of desired period (2016-02-22).
     * @param {string} [toDateCreated] Date (YYYY-MM-DD), end date of the desired period (2020-12-31).
     * @param {string} [messageId] Unique ID of the message
     * @param {string} [taskId] Unique ID of the task
     * @param {string} [messageContent] Optional, has no constraints. If used, will search through the messages for the given string. The fields \&#39;content\&#39; and \&#39;attachments.name\&#39; of each message are checked for the given string.
     * @param {number} [insuredId] InsuredId of the person requesting the search. If the insuredNr is in the path, this value will be overwritten.
     * @param {boolean} [read] If true, will only return read messages.
     * @param {boolean} [deleted] If true, will only return deleted messages.
     * @param {number} [page] The number of the desired page. Index starts at 1. If not set will return the first page
     * @param {number} [size] The number of entries in the content list per page. Default: 10
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagesApi
     */
    public getAllMessagesForUser(insuredNr: number, xUserid: number, fromDateCreated?: string, toDateCreated?: string, messageId?: string, taskId?: string, messageContent?: string, insuredId?: number, read?: boolean, deleted?: boolean, page?: number, size?: number, options?: AxiosRequestConfig) {
        return MessagesApiFp(this.configuration).getAllMessagesForUser(insuredNr, xUserid, fromDateCreated, toDateCreated, messageId, taskId, messageContent, insuredId, read, deleted, page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get an attachment from message, requires the documentId
     * @param {string} documentId The \&#39;documentId\&#39; of a document stored in the DMS
     * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagesApi
     */
    public getMessageAttachment(documentId: string, xUserid: number, options?: AxiosRequestConfig) {
        return MessagesApiFp(this.configuration).getMessageAttachment(documentId, xUserid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the message group for a given message Id from all users in the family.
     * @param {string} messageId Message Id
     * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
     * @param {string} [fromDateCreated] Date (YYYY-MM-DD), start of desired period (2016-02-22).
     * @param {string} [toDateCreated] Date (YYYY-MM-DD), end date of the desired period (2020-12-31).
     * @param {string} [taskId] Unique ID of the task
     * @param {string} [messageContent] Optional, has no constraints. If used, will search through the messages for the given string. The fields \&#39;content\&#39; and \&#39;attachments.name\&#39; of each message are checked for the given string.
     * @param {number} [insuredId] InsuredId of the person requesting the search. If the insuredNr is in the path, this value will be overwritten.
     * @param {boolean} [read] If true, will only return read messages.
     * @param {boolean} [deleted] If true, will only return deleted messages.
     * @param {number} [page] The number of the desired page. Index starts at 1. If not set will return the first page
     * @param {number} [size] The number of entries in the content list per page. Default: 10
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagesApi
     */
    public getMessageGroupPerMessageIdForFamily(messageId: string, xUserid: number, fromDateCreated?: string, toDateCreated?: string, taskId?: string, messageContent?: string, insuredId?: number, read?: boolean, deleted?: boolean, page?: number, size?: number, options?: AxiosRequestConfig) {
        return MessagesApiFp(this.configuration).getMessageGroupPerMessageIdForFamily(messageId, xUserid, fromDateCreated, toDateCreated, taskId, messageContent, insuredId, read, deleted, page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all available thread types
     * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagesApi
     */
    public getRequestTypes(xUserid: number, options?: AxiosRequestConfig) {
        return MessagesApiFp(this.configuration).getRequestTypes(xUserid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a count of all unread messages
     * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagesApi
     */
    public getUnreadMessagesCount(xUserid: number, options?: AxiosRequestConfig) {
        return MessagesApiFp(this.configuration).getUnreadMessagesCount(xUserid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Mark a message as deleted. Note: will also mark it as read
     * @param {string} messageId 
     * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagesApi
     */
    public markMessageAsDeleted(messageId: string, xUserid: number, options?: AxiosRequestConfig) {
        return MessagesApiFp(this.configuration).markMessageAsDeleted(messageId, xUserid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Mark the message as read
     * @param {string} messageId 
     * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagesApi
     */
    public markMessageAsRead(messageId: string, xUserid: number, options?: AxiosRequestConfig) {
        return MessagesApiFp(this.configuration).markMessageAsRead(messageId, xUserid, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NotificationsApi - axios parameter creator
 * @export
 */
export const NotificationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get the notification settings for a user
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCrmNotifications: async (xUserid: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xUserid' is not null or undefined
            assertParamExists('getCrmNotifications', 'xUserid', xUserid)
            const localVarPath = `/api/notifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xUserid !== undefined && xUserid !== null) {
                localVarHeaderParameter['x-userid'] = String(JSON.stringify(xUserid));
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Post the deviceId of the user
         * @param {string} deviceId 
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDeviceId: async (deviceId: string, xUserid: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('postDeviceId', 'deviceId', deviceId)
            // verify required parameter 'xUserid' is not null or undefined
            assertParamExists('postDeviceId', 'xUserid', xUserid)
            const localVarPath = `/api/notifications/{deviceId}`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xUserid !== undefined && xUserid !== null) {
                localVarHeaderParameter['x-userid'] = String(JSON.stringify(xUserid));
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update all notification settings for the insured identified by the insuredId
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {NotificationSettings} notificationSettings 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCrmNotificationSettings: async (xUserid: number, notificationSettings: NotificationSettings, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xUserid' is not null or undefined
            assertParamExists('updateCrmNotificationSettings', 'xUserid', xUserid)
            // verify required parameter 'notificationSettings' is not null or undefined
            assertParamExists('updateCrmNotificationSettings', 'notificationSettings', notificationSettings)
            const localVarPath = `/api/notifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xUserid !== undefined && xUserid !== null) {
                localVarHeaderParameter['x-userid'] = String(JSON.stringify(xUserid));
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(notificationSettings, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationsApi - functional programming interface
 * @export
 */
export const NotificationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NotificationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get the notification settings for a user
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCrmNotifications(xUserid: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationSettingsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCrmNotifications(xUserid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Post the deviceId of the user
         * @param {string} deviceId 
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postDeviceId(deviceId: string, xUserid: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postDeviceId(deviceId, xUserid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update all notification settings for the insured identified by the insuredId
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {NotificationSettings} notificationSettings 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCrmNotificationSettings(xUserid: number, notificationSettings: NotificationSettings, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCrmNotificationSettings(xUserid, notificationSettings, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NotificationsApi - factory interface
 * @export
 */
export const NotificationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NotificationsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get the notification settings for a user
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCrmNotifications(xUserid: number, options?: any): AxiosPromise<NotificationSettingsResponse> {
            return localVarFp.getCrmNotifications(xUserid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Post the deviceId of the user
         * @param {string} deviceId 
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDeviceId(deviceId: string, xUserid: number, options?: any): AxiosPromise<void> {
            return localVarFp.postDeviceId(deviceId, xUserid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update all notification settings for the insured identified by the insuredId
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {NotificationSettings} notificationSettings 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCrmNotificationSettings(xUserid: number, notificationSettings: NotificationSettings, options?: any): AxiosPromise<void> {
            return localVarFp.updateCrmNotificationSettings(xUserid, notificationSettings, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NotificationsApi - object-oriented interface
 * @export
 * @class NotificationsApi
 * @extends {BaseAPI}
 */
export class NotificationsApi extends BaseAPI {
    /**
     * 
     * @summary Get the notification settings for a user
     * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public getCrmNotifications(xUserid: number, options?: AxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).getCrmNotifications(xUserid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Post the deviceId of the user
     * @param {string} deviceId 
     * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public postDeviceId(deviceId: string, xUserid: number, options?: AxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).postDeviceId(deviceId, xUserid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update all notification settings for the insured identified by the insuredId
     * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
     * @param {NotificationSettings} notificationSettings 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public updateCrmNotificationSettings(xUserid: number, notificationSettings: NotificationSettings, options?: AxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).updateCrmNotificationSettings(xUserid, notificationSettings, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PoliciesApi - axios parameter creator
 * @export
 */
export const PoliciesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Fetch the policy document for a user for a specific year
         * @param {number} insuredNr The valid range of the insured number lies between 2000000 and 2999999
         * @param {number} year four digit year e.g. 2020.
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {boolean} [familyAggregation] Optional parameter, if a aggregated document with an overview of the family should be returned. By default false, will return the detail policy for the requested insuredNr.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPolicyDocument: async (insuredNr: number, year: number, xUserid: number, familyAggregation?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'insuredNr' is not null or undefined
            assertParamExists('getPolicyDocument', 'insuredNr', insuredNr)
            // verify required parameter 'year' is not null or undefined
            assertParamExists('getPolicyDocument', 'year', year)
            // verify required parameter 'xUserid' is not null or undefined
            assertParamExists('getPolicyDocument', 'xUserid', xUserid)
            const localVarPath = `/api/policies/{insuredNr}/{year}`
                .replace(`{${"insuredNr"}}`, encodeURIComponent(String(insuredNr)))
                .replace(`{${"year"}}`, encodeURIComponent(String(year)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (familyAggregation !== undefined) {
                localVarQueryParameter['familyAggregation'] = familyAggregation;
            }

            if (xUserid !== undefined && xUserid !== null) {
                localVarHeaderParameter['x-userid'] = String(JSON.stringify(xUserid));
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * **Important to know!**<br>This list is filtered, the active years of a family prior to 2007 are removed, as Siddhartha doesn\'t have documents for those years <br>Use /api/users/insured-years?fullList=true to get the whole list <br><br>For EARLY_ACCESS users the list will contain the year of the new period.<br>e.g. if the current year is 2021 and for the new EA user the coverage begins in 2022,the list will contain an entry for that user and year 2022
         * @summary Fetch the list of insured years, where each year contains the insuredNrs of the users insured that year.
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPolicyYears: async (xUserid: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xUserid' is not null or undefined
            assertParamExists('getPolicyYears', 'xUserid', xUserid)
            const localVarPath = `/api/policies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xUserid !== undefined && xUserid !== null) {
                localVarHeaderParameter['x-userid'] = String(JSON.stringify(xUserid));
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PoliciesApi - functional programming interface
 * @export
 */
export const PoliciesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PoliciesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Fetch the policy document for a user for a specific year
         * @param {number} insuredNr The valid range of the insured number lies between 2000000 and 2999999
         * @param {number} year four digit year e.g. 2020.
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {boolean} [familyAggregation] Optional parameter, if a aggregated document with an overview of the family should be returned. By default false, will return the detail policy for the requested insuredNr.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPolicyDocument(insuredNr: number, year: number, xUserid: number, familyAggregation?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPolicyDocument(insuredNr, year, xUserid, familyAggregation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * **Important to know!**<br>This list is filtered, the active years of a family prior to 2007 are removed, as Siddhartha doesn\'t have documents for those years <br>Use /api/users/insured-years?fullList=true to get the whole list <br><br>For EARLY_ACCESS users the list will contain the year of the new period.<br>e.g. if the current year is 2021 and for the new EA user the coverage begins in 2022,the list will contain an entry for that user and year 2022
         * @summary Fetch the list of insured years, where each year contains the insuredNrs of the users insured that year.
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPolicyYears(xUserid: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InsuredYears>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPolicyYears(xUserid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PoliciesApi - factory interface
 * @export
 */
export const PoliciesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PoliciesApiFp(configuration)
    return {
        /**
         * 
         * @summary Fetch the policy document for a user for a specific year
         * @param {number} insuredNr The valid range of the insured number lies between 2000000 and 2999999
         * @param {number} year four digit year e.g. 2020.
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {boolean} [familyAggregation] Optional parameter, if a aggregated document with an overview of the family should be returned. By default false, will return the detail policy for the requested insuredNr.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPolicyDocument(insuredNr: number, year: number, xUserid: number, familyAggregation?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.getPolicyDocument(insuredNr, year, xUserid, familyAggregation, options).then((request) => request(axios, basePath));
        },
        /**
         * **Important to know!**<br>This list is filtered, the active years of a family prior to 2007 are removed, as Siddhartha doesn\'t have documents for those years <br>Use /api/users/insured-years?fullList=true to get the whole list <br><br>For EARLY_ACCESS users the list will contain the year of the new period.<br>e.g. if the current year is 2021 and for the new EA user the coverage begins in 2022,the list will contain an entry for that user and year 2022
         * @summary Fetch the list of insured years, where each year contains the insuredNrs of the users insured that year.
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPolicyYears(xUserid: number, options?: any): AxiosPromise<Array<InsuredYears>> {
            return localVarFp.getPolicyYears(xUserid, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PoliciesApi - object-oriented interface
 * @export
 * @class PoliciesApi
 * @extends {BaseAPI}
 */
export class PoliciesApi extends BaseAPI {
    /**
     * 
     * @summary Fetch the policy document for a user for a specific year
     * @param {number} insuredNr The valid range of the insured number lies between 2000000 and 2999999
     * @param {number} year four digit year e.g. 2020.
     * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
     * @param {boolean} [familyAggregation] Optional parameter, if a aggregated document with an overview of the family should be returned. By default false, will return the detail policy for the requested insuredNr.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PoliciesApi
     */
    public getPolicyDocument(insuredNr: number, year: number, xUserid: number, familyAggregation?: boolean, options?: AxiosRequestConfig) {
        return PoliciesApiFp(this.configuration).getPolicyDocument(insuredNr, year, xUserid, familyAggregation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * **Important to know!**<br>This list is filtered, the active years of a family prior to 2007 are removed, as Siddhartha doesn\'t have documents for those years <br>Use /api/users/insured-years?fullList=true to get the whole list <br><br>For EARLY_ACCESS users the list will contain the year of the new period.<br>e.g. if the current year is 2021 and for the new EA user the coverage begins in 2022,the list will contain an entry for that user and year 2022
     * @summary Fetch the list of insured years, where each year contains the insuredNrs of the users insured that year.
     * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PoliciesApi
     */
    public getPolicyYears(xUserid: number, options?: AxiosRequestConfig) {
        return PoliciesApiFp(this.configuration).getPolicyYears(xUserid, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TaxCertificatesApi - axios parameter creator
 * @export
 */
export const TaxCertificatesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get a tax certificate for a specific user for the given year
         * @param {number} insuredNr The valid range of the insured number lies between 2000000 and 2999999
         * @param {number} year four digit year e.g. 2020.
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaxCertificateDocument: async (insuredNr: number, year: number, xUserid: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'insuredNr' is not null or undefined
            assertParamExists('getTaxCertificateDocument', 'insuredNr', insuredNr)
            // verify required parameter 'year' is not null or undefined
            assertParamExists('getTaxCertificateDocument', 'year', year)
            // verify required parameter 'xUserid' is not null or undefined
            assertParamExists('getTaxCertificateDocument', 'xUserid', xUserid)
            const localVarPath = `/api/tax-certificates/{insuredNr}/{year}`
                .replace(`{${"insuredNr"}}`, encodeURIComponent(String(insuredNr)))
                .replace(`{${"year"}}`, encodeURIComponent(String(year)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xUserid !== undefined && xUserid !== null) {
                localVarHeaderParameter['x-userid'] = String(JSON.stringify(xUserid));
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * **Important to know!**  This list is filtered, the years prior to 2007 are removed,<br> as Siddhartha doesn\'t have tax statements for the those years.  Use `/api/users/insured-years?fullList=true` to get the whole list 
         * @summary Get the list of years for the family where tax statements are available to download.
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaxCertificateYears: async (xUserid: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xUserid' is not null or undefined
            assertParamExists('getTaxCertificateYears', 'xUserid', xUserid)
            const localVarPath = `/api/tax-certificates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xUserid !== undefined && xUserid !== null) {
                localVarHeaderParameter['x-userid'] = String(JSON.stringify(xUserid));
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TaxCertificatesApi - functional programming interface
 * @export
 */
export const TaxCertificatesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TaxCertificatesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get a tax certificate for a specific user for the given year
         * @param {number} insuredNr The valid range of the insured number lies between 2000000 and 2999999
         * @param {number} year four digit year e.g. 2020.
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTaxCertificateDocument(insuredNr: number, year: number, xUserid: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTaxCertificateDocument(insuredNr, year, xUserid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * **Important to know!**  This list is filtered, the years prior to 2007 are removed,<br> as Siddhartha doesn\'t have tax statements for the those years.  Use `/api/users/insured-years?fullList=true` to get the whole list 
         * @summary Get the list of years for the family where tax statements are available to download.
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTaxCertificateYears(xUserid: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InsuredYears>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTaxCertificateYears(xUserid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TaxCertificatesApi - factory interface
 * @export
 */
export const TaxCertificatesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TaxCertificatesApiFp(configuration)
    return {
        /**
         * 
         * @summary Get a tax certificate for a specific user for the given year
         * @param {number} insuredNr The valid range of the insured number lies between 2000000 and 2999999
         * @param {number} year four digit year e.g. 2020.
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaxCertificateDocument(insuredNr: number, year: number, xUserid: number, options?: any): AxiosPromise<void> {
            return localVarFp.getTaxCertificateDocument(insuredNr, year, xUserid, options).then((request) => request(axios, basePath));
        },
        /**
         * **Important to know!**  This list is filtered, the years prior to 2007 are removed,<br> as Siddhartha doesn\'t have tax statements for the those years.  Use `/api/users/insured-years?fullList=true` to get the whole list 
         * @summary Get the list of years for the family where tax statements are available to download.
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaxCertificateYears(xUserid: number, options?: any): AxiosPromise<Array<InsuredYears>> {
            return localVarFp.getTaxCertificateYears(xUserid, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TaxCertificatesApi - object-oriented interface
 * @export
 * @class TaxCertificatesApi
 * @extends {BaseAPI}
 */
export class TaxCertificatesApi extends BaseAPI {
    /**
     * 
     * @summary Get a tax certificate for a specific user for the given year
     * @param {number} insuredNr The valid range of the insured number lies between 2000000 and 2999999
     * @param {number} year four digit year e.g. 2020.
     * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaxCertificatesApi
     */
    public getTaxCertificateDocument(insuredNr: number, year: number, xUserid: number, options?: AxiosRequestConfig) {
        return TaxCertificatesApiFp(this.configuration).getTaxCertificateDocument(insuredNr, year, xUserid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * **Important to know!**  This list is filtered, the years prior to 2007 are removed,<br> as Siddhartha doesn\'t have tax statements for the those years.  Use `/api/users/insured-years?fullList=true` to get the whole list 
     * @summary Get the list of years for the family where tax statements are available to download.
     * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaxCertificatesApi
     */
    public getTaxCertificateYears(xUserid: number, options?: AxiosRequestConfig) {
        return TaxCertificatesApiFp(this.configuration).getTaxCertificateYears(xUserid, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TranslationApi - axios parameter creator
 * @export
 */
export const TranslationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * This interface connects WebtranslateIt and can only deliver translations from the \"Atupri Front End Application\" project. If no translation is entered in WebtranslateIt, null is returned in the translation. 
         * @summary Returns a translation from WebtranslateIt based on the translationKey and the language
         * @param {string} [translationKey] Required parameter for the correct translation
         * @param {Language} [language] Required parameter for the correct language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTranslation: async (translationKey?: string, language?: Language, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/public/api/translation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (translationKey !== undefined) {
                localVarQueryParameter['translationKey'] = translationKey;
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TranslationApi - functional programming interface
 * @export
 */
export const TranslationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TranslationApiAxiosParamCreator(configuration)
    return {
        /**
         * This interface connects WebtranslateIt and can only deliver translations from the \"Atupri Front End Application\" project. If no translation is entered in WebtranslateIt, null is returned in the translation. 
         * @summary Returns a translation from WebtranslateIt based on the translationKey and the language
         * @param {string} [translationKey] Required parameter for the correct translation
         * @param {Language} [language] Required parameter for the correct language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTranslation(translationKey?: string, language?: Language, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TranslationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTranslation(translationKey, language, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TranslationApi - factory interface
 * @export
 */
export const TranslationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TranslationApiFp(configuration)
    return {
        /**
         * This interface connects WebtranslateIt and can only deliver translations from the \"Atupri Front End Application\" project. If no translation is entered in WebtranslateIt, null is returned in the translation. 
         * @summary Returns a translation from WebtranslateIt based on the translationKey and the language
         * @param {string} [translationKey] Required parameter for the correct translation
         * @param {Language} [language] Required parameter for the correct language
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTranslation(translationKey?: string, language?: Language, options?: any): AxiosPromise<TranslationResponse> {
            return localVarFp.getTranslation(translationKey, language, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TranslationApi - object-oriented interface
 * @export
 * @class TranslationApi
 * @extends {BaseAPI}
 */
export class TranslationApi extends BaseAPI {
    /**
     * This interface connects WebtranslateIt and can only deliver translations from the \"Atupri Front End Application\" project. If no translation is entered in WebtranslateIt, null is returned in the translation. 
     * @summary Returns a translation from WebtranslateIt based on the translationKey and the language
     * @param {string} [translationKey] Required parameter for the correct translation
     * @param {Language} [language] Required parameter for the correct language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TranslationApi
     */
    public getTranslation(translationKey?: string, language?: Language, options?: AxiosRequestConfig) {
        return TranslationApiFp(this.configuration).getTranslation(translationKey, language, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * The received account deletion is sent to the defined or fallback email address.<br>The default spring-boot-starter-mail dependency is used for this purpose.
         * @summary Send email, for account deletion
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAccountDeletionMail: async (xUserid: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xUserid' is not null or undefined
            assertParamExists('createAccountDeletionMail', 'xUserid', xUserid)
            const localVarPath = `/api/users/account-deletion`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xUserid !== undefined && xUserid !== null) {
                localVarHeaderParameter['x-userid'] = String(JSON.stringify(xUserid));
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * The received feedback is sent to the defined or fallback email address.<br>The default spring-boot-starter-mail dependency is used for this purpose.
         * @summary Send email, with the feedback of the customer as content
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {SendFeedbackRequest} sendFeedbackRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFeedbackMail: async (xUserid: number, sendFeedbackRequest: SendFeedbackRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xUserid' is not null or undefined
            assertParamExists('createFeedbackMail', 'xUserid', xUserid)
            // verify required parameter 'sendFeedbackRequest' is not null or undefined
            assertParamExists('createFeedbackMail', 'sendFeedbackRequest', sendFeedbackRequest)
            const localVarPath = `/api/users/feedback`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xUserid !== undefined && xUserid !== null) {
                localVarHeaderParameter['x-userid'] = String(JSON.stringify(xUserid));
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sendFeedbackRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a request to change an attribute of the given user\'s personal information.<br> At least one of the available fields, ``firstName``, ``lastName`` or ``birthdate`` must be set for a valid request.<br> The change requires a certificate / official document proving that the provided new information is correct.  For a successful request, a task in the CRM is opened.<br> The change can take some hours or days as the request is handled by a Service Center Employee manually. 
         * @summary Create a task in CRM to update an user\'s profile / personal information
         * @param {number} insuredNr 
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {ProfileChangeRequest} profileChangeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProfileChangeRequest: async (insuredNr: number, xUserid: number, profileChangeRequest: ProfileChangeRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'insuredNr' is not null or undefined
            assertParamExists('createProfileChangeRequest', 'insuredNr', insuredNr)
            // verify required parameter 'xUserid' is not null or undefined
            assertParamExists('createProfileChangeRequest', 'xUserid', xUserid)
            // verify required parameter 'profileChangeRequest' is not null or undefined
            assertParamExists('createProfileChangeRequest', 'profileChangeRequest', profileChangeRequest)
            const localVarPath = `/api/users/{insuredNr}`
                .replace(`{${"insuredNr"}}`, encodeURIComponent(String(insuredNr)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xUserid !== undefined && xUserid !== null) {
                localVarHeaderParameter['x-userid'] = String(JSON.stringify(xUserid));
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(profileChangeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the insurance card information of each user in the family
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCardsForFamily: async (xUserid: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xUserid' is not null or undefined
            assertParamExists('getCardsForFamily', 'xUserid', xUserid)
            const localVarPath = `/api/users/insurance-cards`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xUserid !== undefined && xUserid !== null) {
                localVarHeaderParameter['x-userid'] = String(JSON.stringify(xUserid));
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * The available contacts are sorted and ordered according to the priority of the category, low -> high.<br> For users with the SmartCare (SMC) model the MEDGATE contact has priority, is the first contact in the list. 
         * @summary Get the medical contacts of each user in the family
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContactsForFamily: async (xUserid: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xUserid' is not null or undefined
            assertParamExists('getContactsForFamily', 'xUserid', xUserid)
            const localVarPath = `/api/users/medical-contacts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xUserid !== undefined && xUserid !== null) {
                localVarHeaderParameter['x-userid'] = String(JSON.stringify(xUserid));
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the deductibles for the whole family
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeductibleBalanceForFamily: async (xUserid: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xUserid' is not null or undefined
            assertParamExists('getDeductibleBalanceForFamily', 'xUserid', xUserid)
            const localVarPath = `/api/users/deductible-balance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xUserid !== undefined && xUserid !== null) {
                localVarHeaderParameter['x-userid'] = String(JSON.stringify(xUserid));
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the deductibles for a user
         * @param {number} insuredNr 
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeductibleBalanceForUser: async (insuredNr: number, xUserid: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'insuredNr' is not null or undefined
            assertParamExists('getDeductibleBalanceForUser', 'insuredNr', insuredNr)
            // verify required parameter 'xUserid' is not null or undefined
            assertParamExists('getDeductibleBalanceForUser', 'xUserid', xUserid)
            const localVarPath = `/api/users/{insuredNr}/deductible-balance`
                .replace(`{${"insuredNr"}}`, encodeURIComponent(String(insuredNr)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xUserid !== undefined && xUserid !== null) {
                localVarHeaderParameter['x-userid'] = String(JSON.stringify(xUserid));
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the earliest possible start date based on the user current situation and requested product
         * @param {number} insuredNr 
         * @param {string} newObjectId New selected product of the user
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {string} [currentObjectId] Current subscribed product of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEffectiveDate: async (insuredNr: number, newObjectId: string, xUserid: number, currentObjectId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'insuredNr' is not null or undefined
            assertParamExists('getEffectiveDate', 'insuredNr', insuredNr)
            // verify required parameter 'newObjectId' is not null or undefined
            assertParamExists('getEffectiveDate', 'newObjectId', newObjectId)
            // verify required parameter 'xUserid' is not null or undefined
            assertParamExists('getEffectiveDate', 'xUserid', xUserid)
            const localVarPath = `/api/users/{insuredNr}/effectiveDate`
                .replace(`{${"insuredNr"}}`, encodeURIComponent(String(insuredNr)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (currentObjectId !== undefined) {
                localVarQueryParameter['currentObjectId'] = currentObjectId;
            }

            if (newObjectId !== undefined) {
                localVarQueryParameter['newObjectId'] = newObjectId;
            }

            if (xUserid !== undefined && xUserid !== null) {
                localVarHeaderParameter['x-userid'] = String(JSON.stringify(xUserid));
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary  Get the eligible products for each member of the family
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEligibleProductsForFamily: async (xUserid: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xUserid' is not null or undefined
            assertParamExists('getEligibleProductsForFamily', 'xUserid', xUserid)
            const localVarPath = `/api/users/eligible-products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xUserid !== undefined && xUserid !== null) {
                localVarHeaderParameter['x-userid'] = String(JSON.stringify(xUserid));
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the prepared data of the insurance card for a user
         * @param {number} insuredNr 
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInsuranceCardForUser: async (insuredNr: number, xUserid: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'insuredNr' is not null or undefined
            assertParamExists('getInsuranceCardForUser', 'insuredNr', insuredNr)
            // verify required parameter 'xUserid' is not null or undefined
            assertParamExists('getInsuranceCardForUser', 'xUserid', xUserid)
            const localVarPath = `/api/users/{insuredNr}/insurance-card`
                .replace(`{${"insuredNr"}}`, encodeURIComponent(String(insuredNr)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xUserid !== undefined && xUserid !== null) {
                localVarHeaderParameter['x-userid'] = String(JSON.stringify(xUserid));
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint returns the active insured years of the family from the authenticated user.<br> By default the list is filtered, it doesn\'t contain the years prior to 2007, as Siddhartha doesn\'t have data & documents before 2007.  Set the parameter `fullList` to `true` to get the full unfiltered list.  There is a special case for QUIT_CONTRACT users that are within the 120 day grace period after quitting. If such users have access to the portal over the turn of the year, the new year, where they are no longer insured, is included in the list. As they are still able to receive invoices and such and we rely on this list for different filters and look-ups and without this year there would be inconsistencies. 
         * @summary Get a list of the insured years of the users in the family. By default the list is filtered and doesn\'t contain years older than 2007
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {boolean} [fullList] Boolean to disable the filtering, set to \&#39;true\&#39; if you want the full list of active years
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInsuredYearsOfFamily: async (xUserid: number, fullList?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xUserid' is not null or undefined
            assertParamExists('getInsuredYearsOfFamily', 'xUserid', xUserid)
            const localVarPath = `/api/users/insured-years`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (fullList !== undefined) {
                localVarQueryParameter['fullList'] = fullList;
            }

            if (xUserid !== undefined && xUserid !== null) {
                localVarHeaderParameter['x-userid'] = String(JSON.stringify(xUserid));
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the marketing information for a user, so it can be used for analytics services like the GTM
         * @param {number} insuredNr 
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMarketingInformationForUser: async (insuredNr: number, xUserid: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'insuredNr' is not null or undefined
            assertParamExists('getMarketingInformationForUser', 'insuredNr', insuredNr)
            // verify required parameter 'xUserid' is not null or undefined
            assertParamExists('getMarketingInformationForUser', 'xUserid', xUserid)
            const localVarPath = `/api/users/{insuredNr}/marketing-information`
                .replace(`{${"insuredNr"}}`, encodeURIComponent(String(insuredNr)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xUserid !== undefined && xUserid !== null) {
                localVarHeaderParameter['x-userid'] = String(JSON.stringify(xUserid));
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * The available contacts are sorted and ordered according to the priority of the category, low -> high.<br> For users with the SmartCare (SMC) model the MEDGATE contact has priority, is the first contact in the list. 
         * @summary Get the medical contacts for a user
         * @param {number} insuredNr 
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMedicalContactsForUser: async (insuredNr: number, xUserid: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'insuredNr' is not null or undefined
            assertParamExists('getMedicalContactsForUser', 'insuredNr', insuredNr)
            // verify required parameter 'xUserid' is not null or undefined
            assertParamExists('getMedicalContactsForUser', 'xUserid', xUserid)
            const localVarPath = `/api/users/{insuredNr}/medical-contacts`
                .replace(`{${"insuredNr"}}`, encodeURIComponent(String(insuredNr)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xUserid !== undefined && xUserid !== null) {
                localVarHeaderParameter['x-userid'] = String(JSON.stringify(xUserid));
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the first name, last name and insurance number for a user, so a new password can be validated against it
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPasswordInformation: async (xUserid: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xUserid' is not null or undefined
            assertParamExists('getPasswordInformation', 'xUserid', xUserid)
            const localVarPath = `/api/users/password-information`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xUserid !== undefined && xUserid !== null) {
                localVarHeaderParameter['x-userid'] = String(JSON.stringify(xUserid));
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the products of each user in the family, only the OKP products will also contain the model and franchise values, the others only the code
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductsForFamily: async (xUserid: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xUserid' is not null or undefined
            assertParamExists('getProductsForFamily', 'xUserid', xUserid)
            const localVarPath = `/api/users/products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xUserid !== undefined && xUserid !== null) {
                localVarHeaderParameter['x-userid'] = String(JSON.stringify(xUserid));
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the products of a user, only the OKP products will also contain the model and franchise values, the others only the code
         * @param {number} insuredNr 
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductsForUser: async (insuredNr: number, xUserid: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'insuredNr' is not null or undefined
            assertParamExists('getProductsForUser', 'insuredNr', insuredNr)
            // verify required parameter 'xUserid' is not null or undefined
            assertParamExists('getProductsForUser', 'xUserid', xUserid)
            const localVarPath = `/api/users/{insuredNr}/products`
                .replace(`{${"insuredNr"}}`, encodeURIComponent(String(insuredNr)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xUserid !== undefined && xUserid !== null) {
                localVarHeaderParameter['x-userid'] = String(JSON.stringify(xUserid));
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the user\'s personal data
         * @param {number} insuredNr 
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser: async (insuredNr: number, xUserid: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'insuredNr' is not null or undefined
            assertParamExists('getUser', 'insuredNr', insuredNr)
            // verify required parameter 'xUserid' is not null or undefined
            assertParamExists('getUser', 'xUserid', xUserid)
            const localVarPath = `/api/users/{insuredNr}`
                .replace(`{${"insuredNr"}}`, encodeURIComponent(String(insuredNr)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xUserid !== undefined && xUserid !== null) {
                localVarHeaderParameter['x-userid'] = String(JSON.stringify(xUserid));
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the personal data of each user in the family
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers: async (xUserid: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xUserid' is not null or undefined
            assertParamExists('getUsers', 'xUserid', xUserid)
            const localVarPath = `/api/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xUserid !== undefined && xUserid !== null) {
                localVarHeaderParameter['x-userid'] = String(JSON.stringify(xUserid));
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * @deprecated This endpoint will redirect to the premium calculator according to the given parameters.<br> Be aware to use the right language, the external PR uses localized domains. 
         * @summary Entrypoint for the redirect to the external premium calculator, mind the parameters.
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {Set<InsuranceCategory>} [categories] @deprecated CSV of the values. Used to toggle which product categories will be shown to the user. &lt;br&gt;Will add the \&#39;vvgEnabled\&#39; and \&#39;okpEnabled\&#39; parameters with the respective values, 0|1 to the redirect
         * @param {Language} [language] Language for the redirect to the premium calculator. If the parameter is omitted the language of authenticated user is used
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        redirectUserToExternalPremiumCalculator: async (xUserid: number, categories?: Set<InsuranceCategory>, language?: Language, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xUserid' is not null or undefined
            assertParamExists('redirectUserToExternalPremiumCalculator', 'xUserid', xUserid)
            const localVarPath = `/api/users/premium-calculator`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (categories) {
                localVarQueryParameter['categories'] = Array.from(categories);
            }

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            if (xUserid !== undefined && xUserid !== null) {
                localVarHeaderParameter['x-userid'] = String(JSON.stringify(xUserid));
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Call the system premiumcalculator and redirects
         * @param {number} insuredNr 
         * @param {UserFlow} userFlow 
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {boolean} [okpEnabled] 
         * @param {boolean} [vvgEnabled] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendSession: async (insuredNr: number, userFlow: UserFlow, xUserid: number, okpEnabled?: boolean, vvgEnabled?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'insuredNr' is not null or undefined
            assertParamExists('sendSession', 'insuredNr', insuredNr)
            // verify required parameter 'userFlow' is not null or undefined
            assertParamExists('sendSession', 'userFlow', userFlow)
            // verify required parameter 'xUserid' is not null or undefined
            assertParamExists('sendSession', 'xUserid', xUserid)
            const localVarPath = `/api/users/{insuredNr}/externalsessions`
                .replace(`{${"insuredNr"}}`, encodeURIComponent(String(insuredNr)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userFlow !== undefined) {
                localVarQueryParameter['userFlow'] = userFlow;
            }

            if (okpEnabled !== undefined) {
                localVarQueryParameter['okpEnabled'] = okpEnabled;
            }

            if (vvgEnabled !== undefined) {
                localVarQueryParameter['vvgEnabled'] = vvgEnabled;
            }

            if (xUserid !== undefined && xUserid !== null) {
                localVarHeaderParameter['x-userid'] = String(JSON.stringify(xUserid));
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This request will trigger the lookup and signing of the family\'s insurance card numbers for the Vicard.<br> For the signing we require the `publicKeyOfCustomer`. The key is issued by the Vicard SDK the apps implement and is device specific.  If members of a family don\'t have an insurance card number, the API will skip those members.<br> The response will contain an entry for those but the `insuredCardNr` and `vekaNrClaimToken` will be `null`  Example: ``` [   {    \"insuredNr\": 2000001,    \"insuredCardNr\": \"80000000000000000008\",    \"vekaNrClaimToken\": \"v2.public.ey...\"   },   {    \"insuredNr\": 2000002,    \"insuredCardNr\": null,    \"vekaNrClaimToken\": null   }   ...  ] ```  [Confluence Vicard Documentation](https://confluence.atupri.ch/x/fI5UB)  **FOR THE DEV AND TEST ENVIRONMENT THE SERVICE WILL RETURN MOCKED DATA**<br> To provide some predictability a small algorithm will, depending on the insuredNr of the user, use and return token and card numbers.  Algorithm:<br> insuredNr modulo 100 = value i.e. get the last two digits of the insured number.<br>Distribution for the mock data, value in between:<br> 00 - 49 - KVG<br> 50 - 69 - KVG_GOLD<br> 70 - 79 - CARD_EXPIRED<br> 80 - 89 - VVG<br> 90 - 99 - LICHTENSTEIN  | Card Number            | Hint / Description       | | -----------------------| -------------------------| | 80756 01234 00000 00108| KVG                      | | 80756 01234 00000 00033| KVG Gold                 | | 80756 01234 00000 00057| Expired card             | | 80756 01234 00000 02666| VVG                      | | 80756 01234 11031 01037| Fürstentum Liechtenstein |  Test data from [Dokumentation Vicard - Testdaten](https://confluence.atupri.ch/display/MVP/Dokumentation+Vicard#DokumentationVicard-Testdaten) 
         * @summary Sign and get the insurance cards of the family for the Vicard interface.
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {SignInsuranceCardsRequest} signInsuranceCardsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        singInsuranceCards: async (xUserid: number, signInsuranceCardsRequest: SignInsuranceCardsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xUserid' is not null or undefined
            assertParamExists('singInsuranceCards', 'xUserid', xUserid)
            // verify required parameter 'signInsuranceCardsRequest' is not null or undefined
            assertParamExists('singInsuranceCards', 'signInsuranceCardsRequest', signInsuranceCardsRequest)
            const localVarPath = `/api/users/sign-insurance-cards`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xUserid !== undefined && xUserid !== null) {
                localVarHeaderParameter['x-userid'] = String(JSON.stringify(xUserid));
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(signInsuranceCardsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * The received account deletion is sent to the defined or fallback email address.<br>The default spring-boot-starter-mail dependency is used for this purpose.
         * @summary Send email, for account deletion
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAccountDeletionMail(xUserid: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SendMailDeliveryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAccountDeletionMail(xUserid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * The received feedback is sent to the defined or fallback email address.<br>The default spring-boot-starter-mail dependency is used for this purpose.
         * @summary Send email, with the feedback of the customer as content
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {SendFeedbackRequest} sendFeedbackRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createFeedbackMail(xUserid: number, sendFeedbackRequest: SendFeedbackRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SendMailDeliveryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createFeedbackMail(xUserid, sendFeedbackRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a request to change an attribute of the given user\'s personal information.<br> At least one of the available fields, ``firstName``, ``lastName`` or ``birthdate`` must be set for a valid request.<br> The change requires a certificate / official document proving that the provided new information is correct.  For a successful request, a task in the CRM is opened.<br> The change can take some hours or days as the request is handled by a Service Center Employee manually. 
         * @summary Create a task in CRM to update an user\'s profile / personal information
         * @param {number} insuredNr 
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {ProfileChangeRequest} profileChangeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProfileChangeRequest(insuredNr: number, xUserid: number, profileChangeRequest: ProfileChangeRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createProfileChangeRequest(insuredNr, xUserid, profileChangeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the insurance card information of each user in the family
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCardsForFamily(xUserid: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InsuranceCard>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCardsForFamily(xUserid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * The available contacts are sorted and ordered according to the priority of the category, low -> high.<br> For users with the SmartCare (SMC) model the MEDGATE contact has priority, is the first contact in the list. 
         * @summary Get the medical contacts of each user in the family
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContactsForFamily(xUserid: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MedicalContactsResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getContactsForFamily(xUserid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the deductibles for the whole family
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDeductibleBalanceForFamily(xUserid: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DeductiblesResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDeductibleBalanceForFamily(xUserid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the deductibles for a user
         * @param {number} insuredNr 
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDeductibleBalanceForUser(insuredNr: number, xUserid: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Deductibles>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDeductibleBalanceForUser(insuredNr, xUserid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the earliest possible start date based on the user current situation and requested product
         * @param {number} insuredNr 
         * @param {string} newObjectId New selected product of the user
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {string} [currentObjectId] Current subscribed product of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEffectiveDate(insuredNr: number, newObjectId: string, xUserid: number, currentObjectId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EffectiveDate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEffectiveDate(insuredNr, newObjectId, xUserid, currentObjectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary  Get the eligible products for each member of the family
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEligibleProductsForFamily(xUserid: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EligibleProductsComposition>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEligibleProductsForFamily(xUserid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the prepared data of the insurance card for a user
         * @param {number} insuredNr 
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInsuranceCardForUser(insuredNr: number, xUserid: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InsuranceCard>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInsuranceCardForUser(insuredNr, xUserid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This endpoint returns the active insured years of the family from the authenticated user.<br> By default the list is filtered, it doesn\'t contain the years prior to 2007, as Siddhartha doesn\'t have data & documents before 2007.  Set the parameter `fullList` to `true` to get the full unfiltered list.  There is a special case for QUIT_CONTRACT users that are within the 120 day grace period after quitting. If such users have access to the portal over the turn of the year, the new year, where they are no longer insured, is included in the list. As they are still able to receive invoices and such and we rely on this list for different filters and look-ups and without this year there would be inconsistencies. 
         * @summary Get a list of the insured years of the users in the family. By default the list is filtered and doesn\'t contain years older than 2007
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {boolean} [fullList] Boolean to disable the filtering, set to \&#39;true\&#39; if you want the full list of active years
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInsuredYearsOfFamily(xUserid: number, fullList?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InsuredYears>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInsuredYearsOfFamily(xUserid, fullList, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the marketing information for a user, so it can be used for analytics services like the GTM
         * @param {number} insuredNr 
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMarketingInformationForUser(insuredNr: number, xUserid: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MarketingInformationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMarketingInformationForUser(insuredNr, xUserid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * The available contacts are sorted and ordered according to the priority of the category, low -> high.<br> For users with the SmartCare (SMC) model the MEDGATE contact has priority, is the first contact in the list. 
         * @summary Get the medical contacts for a user
         * @param {number} insuredNr 
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMedicalContactsForUser(insuredNr: number, xUserid: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MedicalContactsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMedicalContactsForUser(insuredNr, xUserid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the first name, last name and insurance number for a user, so a new password can be validated against it
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPasswordInformation(xUserid: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PasswordInformationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPasswordInformation(xUserid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the products of each user in the family, only the OKP products will also contain the model and franchise values, the others only the code
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductsForFamily(xUserid: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SubscribedProductsResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductsForFamily(xUserid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the products of a user, only the OKP products will also contain the model and franchise values, the others only the code
         * @param {number} insuredNr 
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductsForUser(insuredNr: number, xUserid: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductOverviewResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductsForUser(insuredNr, xUserid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the user\'s personal data
         * @param {number} insuredNr 
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUser(insuredNr: number, xUserid: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUser(insuredNr, xUserid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the personal data of each user in the family
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsers(xUserid: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsers(xUserid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * @deprecated This endpoint will redirect to the premium calculator according to the given parameters.<br> Be aware to use the right language, the external PR uses localized domains. 
         * @summary Entrypoint for the redirect to the external premium calculator, mind the parameters.
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {Set<InsuranceCategory>} [categories] @deprecated CSV of the values. Used to toggle which product categories will be shown to the user. &lt;br&gt;Will add the \&#39;vvgEnabled\&#39; and \&#39;okpEnabled\&#39; parameters with the respective values, 0|1 to the redirect
         * @param {Language} [language] Language for the redirect to the premium calculator. If the parameter is omitted the language of authenticated user is used
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async redirectUserToExternalPremiumCalculator(xUserid: number, categories?: Set<InsuranceCategory>, language?: Language, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.redirectUserToExternalPremiumCalculator(xUserid, categories, language, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Call the system premiumcalculator and redirects
         * @param {number} insuredNr 
         * @param {UserFlow} userFlow 
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {boolean} [okpEnabled] 
         * @param {boolean} [vvgEnabled] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendSession(insuredNr: number, userFlow: UserFlow, xUserid: number, okpEnabled?: boolean, vvgEnabled?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExternalSessionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendSession(insuredNr, userFlow, xUserid, okpEnabled, vvgEnabled, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This request will trigger the lookup and signing of the family\'s insurance card numbers for the Vicard.<br> For the signing we require the `publicKeyOfCustomer`. The key is issued by the Vicard SDK the apps implement and is device specific.  If members of a family don\'t have an insurance card number, the API will skip those members.<br> The response will contain an entry for those but the `insuredCardNr` and `vekaNrClaimToken` will be `null`  Example: ``` [   {    \"insuredNr\": 2000001,    \"insuredCardNr\": \"80000000000000000008\",    \"vekaNrClaimToken\": \"v2.public.ey...\"   },   {    \"insuredNr\": 2000002,    \"insuredCardNr\": null,    \"vekaNrClaimToken\": null   }   ...  ] ```  [Confluence Vicard Documentation](https://confluence.atupri.ch/x/fI5UB)  **FOR THE DEV AND TEST ENVIRONMENT THE SERVICE WILL RETURN MOCKED DATA**<br> To provide some predictability a small algorithm will, depending on the insuredNr of the user, use and return token and card numbers.  Algorithm:<br> insuredNr modulo 100 = value i.e. get the last two digits of the insured number.<br>Distribution for the mock data, value in between:<br> 00 - 49 - KVG<br> 50 - 69 - KVG_GOLD<br> 70 - 79 - CARD_EXPIRED<br> 80 - 89 - VVG<br> 90 - 99 - LICHTENSTEIN  | Card Number            | Hint / Description       | | -----------------------| -------------------------| | 80756 01234 00000 00108| KVG                      | | 80756 01234 00000 00033| KVG Gold                 | | 80756 01234 00000 00057| Expired card             | | 80756 01234 00000 02666| VVG                      | | 80756 01234 11031 01037| Fürstentum Liechtenstein |  Test data from [Dokumentation Vicard - Testdaten](https://confluence.atupri.ch/display/MVP/Dokumentation+Vicard#DokumentationVicard-Testdaten) 
         * @summary Sign and get the insurance cards of the family for the Vicard interface.
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {SignInsuranceCardsRequest} signInsuranceCardsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async singInsuranceCards(xUserid: number, signInsuranceCardsRequest: SignInsuranceCardsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SignedInsuranceCardResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.singInsuranceCards(xUserid, signInsuranceCardsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * The received account deletion is sent to the defined or fallback email address.<br>The default spring-boot-starter-mail dependency is used for this purpose.
         * @summary Send email, for account deletion
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAccountDeletionMail(xUserid: number, options?: any): AxiosPromise<SendMailDeliveryResponse> {
            return localVarFp.createAccountDeletionMail(xUserid, options).then((request) => request(axios, basePath));
        },
        /**
         * The received feedback is sent to the defined or fallback email address.<br>The default spring-boot-starter-mail dependency is used for this purpose.
         * @summary Send email, with the feedback of the customer as content
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {SendFeedbackRequest} sendFeedbackRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFeedbackMail(xUserid: number, sendFeedbackRequest: SendFeedbackRequest, options?: any): AxiosPromise<SendMailDeliveryResponse> {
            return localVarFp.createFeedbackMail(xUserid, sendFeedbackRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a request to change an attribute of the given user\'s personal information.<br> At least one of the available fields, ``firstName``, ``lastName`` or ``birthdate`` must be set for a valid request.<br> The change requires a certificate / official document proving that the provided new information is correct.  For a successful request, a task in the CRM is opened.<br> The change can take some hours or days as the request is handled by a Service Center Employee manually. 
         * @summary Create a task in CRM to update an user\'s profile / personal information
         * @param {number} insuredNr 
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {ProfileChangeRequest} profileChangeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProfileChangeRequest(insuredNr: number, xUserid: number, profileChangeRequest: ProfileChangeRequest, options?: any): AxiosPromise<UserResponse> {
            return localVarFp.createProfileChangeRequest(insuredNr, xUserid, profileChangeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the insurance card information of each user in the family
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCardsForFamily(xUserid: number, options?: any): AxiosPromise<Array<InsuranceCard>> {
            return localVarFp.getCardsForFamily(xUserid, options).then((request) => request(axios, basePath));
        },
        /**
         * The available contacts are sorted and ordered according to the priority of the category, low -> high.<br> For users with the SmartCare (SMC) model the MEDGATE contact has priority, is the first contact in the list. 
         * @summary Get the medical contacts of each user in the family
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContactsForFamily(xUserid: number, options?: any): AxiosPromise<Array<MedicalContactsResponse>> {
            return localVarFp.getContactsForFamily(xUserid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the deductibles for the whole family
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeductibleBalanceForFamily(xUserid: number, options?: any): AxiosPromise<Array<DeductiblesResponse>> {
            return localVarFp.getDeductibleBalanceForFamily(xUserid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the deductibles for a user
         * @param {number} insuredNr 
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeductibleBalanceForUser(insuredNr: number, xUserid: number, options?: any): AxiosPromise<Deductibles> {
            return localVarFp.getDeductibleBalanceForUser(insuredNr, xUserid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the earliest possible start date based on the user current situation and requested product
         * @param {number} insuredNr 
         * @param {string} newObjectId New selected product of the user
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {string} [currentObjectId] Current subscribed product of the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEffectiveDate(insuredNr: number, newObjectId: string, xUserid: number, currentObjectId?: string, options?: any): AxiosPromise<EffectiveDate> {
            return localVarFp.getEffectiveDate(insuredNr, newObjectId, xUserid, currentObjectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary  Get the eligible products for each member of the family
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEligibleProductsForFamily(xUserid: number, options?: any): AxiosPromise<Array<EligibleProductsComposition>> {
            return localVarFp.getEligibleProductsForFamily(xUserid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the prepared data of the insurance card for a user
         * @param {number} insuredNr 
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInsuranceCardForUser(insuredNr: number, xUserid: number, options?: any): AxiosPromise<InsuranceCard> {
            return localVarFp.getInsuranceCardForUser(insuredNr, xUserid, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint returns the active insured years of the family from the authenticated user.<br> By default the list is filtered, it doesn\'t contain the years prior to 2007, as Siddhartha doesn\'t have data & documents before 2007.  Set the parameter `fullList` to `true` to get the full unfiltered list.  There is a special case for QUIT_CONTRACT users that are within the 120 day grace period after quitting. If such users have access to the portal over the turn of the year, the new year, where they are no longer insured, is included in the list. As they are still able to receive invoices and such and we rely on this list for different filters and look-ups and without this year there would be inconsistencies. 
         * @summary Get a list of the insured years of the users in the family. By default the list is filtered and doesn\'t contain years older than 2007
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {boolean} [fullList] Boolean to disable the filtering, set to \&#39;true\&#39; if you want the full list of active years
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInsuredYearsOfFamily(xUserid: number, fullList?: boolean, options?: any): AxiosPromise<Array<InsuredYears>> {
            return localVarFp.getInsuredYearsOfFamily(xUserid, fullList, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the marketing information for a user, so it can be used for analytics services like the GTM
         * @param {number} insuredNr 
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMarketingInformationForUser(insuredNr: number, xUserid: number, options?: any): AxiosPromise<MarketingInformationResponse> {
            return localVarFp.getMarketingInformationForUser(insuredNr, xUserid, options).then((request) => request(axios, basePath));
        },
        /**
         * The available contacts are sorted and ordered according to the priority of the category, low -> high.<br> For users with the SmartCare (SMC) model the MEDGATE contact has priority, is the first contact in the list. 
         * @summary Get the medical contacts for a user
         * @param {number} insuredNr 
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMedicalContactsForUser(insuredNr: number, xUserid: number, options?: any): AxiosPromise<MedicalContactsResponse> {
            return localVarFp.getMedicalContactsForUser(insuredNr, xUserid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the first name, last name and insurance number for a user, so a new password can be validated against it
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPasswordInformation(xUserid: number, options?: any): AxiosPromise<PasswordInformationResponse> {
            return localVarFp.getPasswordInformation(xUserid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the products of each user in the family, only the OKP products will also contain the model and franchise values, the others only the code
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductsForFamily(xUserid: number, options?: any): AxiosPromise<Array<SubscribedProductsResponse>> {
            return localVarFp.getProductsForFamily(xUserid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the products of a user, only the OKP products will also contain the model and franchise values, the others only the code
         * @param {number} insuredNr 
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductsForUser(insuredNr: number, xUserid: number, options?: any): AxiosPromise<ProductOverviewResponse> {
            return localVarFp.getProductsForUser(insuredNr, xUserid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the user\'s personal data
         * @param {number} insuredNr 
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser(insuredNr: number, xUserid: number, options?: any): AxiosPromise<UserResponse> {
            return localVarFp.getUser(insuredNr, xUserid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the personal data of each user in the family
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers(xUserid: number, options?: any): AxiosPromise<Array<UserResponse>> {
            return localVarFp.getUsers(xUserid, options).then((request) => request(axios, basePath));
        },
        /**
         * @deprecated This endpoint will redirect to the premium calculator according to the given parameters.<br> Be aware to use the right language, the external PR uses localized domains. 
         * @summary Entrypoint for the redirect to the external premium calculator, mind the parameters.
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {Set<InsuranceCategory>} [categories] @deprecated CSV of the values. Used to toggle which product categories will be shown to the user. &lt;br&gt;Will add the \&#39;vvgEnabled\&#39; and \&#39;okpEnabled\&#39; parameters with the respective values, 0|1 to the redirect
         * @param {Language} [language] Language for the redirect to the premium calculator. If the parameter is omitted the language of authenticated user is used
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        redirectUserToExternalPremiumCalculator(xUserid: number, categories?: Set<InsuranceCategory>, language?: Language, options?: any): AxiosPromise<void> {
            return localVarFp.redirectUserToExternalPremiumCalculator(xUserid, categories, language, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Call the system premiumcalculator and redirects
         * @param {number} insuredNr 
         * @param {UserFlow} userFlow 
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {boolean} [okpEnabled] 
         * @param {boolean} [vvgEnabled] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendSession(insuredNr: number, userFlow: UserFlow, xUserid: number, okpEnabled?: boolean, vvgEnabled?: boolean, options?: any): AxiosPromise<ExternalSessionResponse> {
            return localVarFp.sendSession(insuredNr, userFlow, xUserid, okpEnabled, vvgEnabled, options).then((request) => request(axios, basePath));
        },
        /**
         * This request will trigger the lookup and signing of the family\'s insurance card numbers for the Vicard.<br> For the signing we require the `publicKeyOfCustomer`. The key is issued by the Vicard SDK the apps implement and is device specific.  If members of a family don\'t have an insurance card number, the API will skip those members.<br> The response will contain an entry for those but the `insuredCardNr` and `vekaNrClaimToken` will be `null`  Example: ``` [   {    \"insuredNr\": 2000001,    \"insuredCardNr\": \"80000000000000000008\",    \"vekaNrClaimToken\": \"v2.public.ey...\"   },   {    \"insuredNr\": 2000002,    \"insuredCardNr\": null,    \"vekaNrClaimToken\": null   }   ...  ] ```  [Confluence Vicard Documentation](https://confluence.atupri.ch/x/fI5UB)  **FOR THE DEV AND TEST ENVIRONMENT THE SERVICE WILL RETURN MOCKED DATA**<br> To provide some predictability a small algorithm will, depending on the insuredNr of the user, use and return token and card numbers.  Algorithm:<br> insuredNr modulo 100 = value i.e. get the last two digits of the insured number.<br>Distribution for the mock data, value in between:<br> 00 - 49 - KVG<br> 50 - 69 - KVG_GOLD<br> 70 - 79 - CARD_EXPIRED<br> 80 - 89 - VVG<br> 90 - 99 - LICHTENSTEIN  | Card Number            | Hint / Description       | | -----------------------| -------------------------| | 80756 01234 00000 00108| KVG                      | | 80756 01234 00000 00033| KVG Gold                 | | 80756 01234 00000 00057| Expired card             | | 80756 01234 00000 02666| VVG                      | | 80756 01234 11031 01037| Fürstentum Liechtenstein |  Test data from [Dokumentation Vicard - Testdaten](https://confluence.atupri.ch/display/MVP/Dokumentation+Vicard#DokumentationVicard-Testdaten) 
         * @summary Sign and get the insurance cards of the family for the Vicard interface.
         * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
         * @param {SignInsuranceCardsRequest} signInsuranceCardsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        singInsuranceCards(xUserid: number, signInsuranceCardsRequest: SignInsuranceCardsRequest, options?: any): AxiosPromise<Array<SignedInsuranceCardResponse>> {
            return localVarFp.singInsuranceCards(xUserid, signInsuranceCardsRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * The received account deletion is sent to the defined or fallback email address.<br>The default spring-boot-starter-mail dependency is used for this purpose.
     * @summary Send email, for account deletion
     * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public createAccountDeletionMail(xUserid: number, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).createAccountDeletionMail(xUserid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * The received feedback is sent to the defined or fallback email address.<br>The default spring-boot-starter-mail dependency is used for this purpose.
     * @summary Send email, with the feedback of the customer as content
     * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
     * @param {SendFeedbackRequest} sendFeedbackRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public createFeedbackMail(xUserid: number, sendFeedbackRequest: SendFeedbackRequest, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).createFeedbackMail(xUserid, sendFeedbackRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a request to change an attribute of the given user\'s personal information.<br> At least one of the available fields, ``firstName``, ``lastName`` or ``birthdate`` must be set for a valid request.<br> The change requires a certificate / official document proving that the provided new information is correct.  For a successful request, a task in the CRM is opened.<br> The change can take some hours or days as the request is handled by a Service Center Employee manually. 
     * @summary Create a task in CRM to update an user\'s profile / personal information
     * @param {number} insuredNr 
     * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
     * @param {ProfileChangeRequest} profileChangeRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public createProfileChangeRequest(insuredNr: number, xUserid: number, profileChangeRequest: ProfileChangeRequest, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).createProfileChangeRequest(insuredNr, xUserid, profileChangeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the insurance card information of each user in the family
     * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getCardsForFamily(xUserid: number, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).getCardsForFamily(xUserid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * The available contacts are sorted and ordered according to the priority of the category, low -> high.<br> For users with the SmartCare (SMC) model the MEDGATE contact has priority, is the first contact in the list. 
     * @summary Get the medical contacts of each user in the family
     * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getContactsForFamily(xUserid: number, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).getContactsForFamily(xUserid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the deductibles for the whole family
     * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getDeductibleBalanceForFamily(xUserid: number, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).getDeductibleBalanceForFamily(xUserid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the deductibles for a user
     * @param {number} insuredNr 
     * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getDeductibleBalanceForUser(insuredNr: number, xUserid: number, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).getDeductibleBalanceForUser(insuredNr, xUserid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the earliest possible start date based on the user current situation and requested product
     * @param {number} insuredNr 
     * @param {string} newObjectId New selected product of the user
     * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
     * @param {string} [currentObjectId] Current subscribed product of the user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getEffectiveDate(insuredNr: number, newObjectId: string, xUserid: number, currentObjectId?: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).getEffectiveDate(insuredNr, newObjectId, xUserid, currentObjectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary  Get the eligible products for each member of the family
     * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getEligibleProductsForFamily(xUserid: number, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).getEligibleProductsForFamily(xUserid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the prepared data of the insurance card for a user
     * @param {number} insuredNr 
     * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getInsuranceCardForUser(insuredNr: number, xUserid: number, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).getInsuranceCardForUser(insuredNr, xUserid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint returns the active insured years of the family from the authenticated user.<br> By default the list is filtered, it doesn\'t contain the years prior to 2007, as Siddhartha doesn\'t have data & documents before 2007.  Set the parameter `fullList` to `true` to get the full unfiltered list.  There is a special case for QUIT_CONTRACT users that are within the 120 day grace period after quitting. If such users have access to the portal over the turn of the year, the new year, where they are no longer insured, is included in the list. As they are still able to receive invoices and such and we rely on this list for different filters and look-ups and without this year there would be inconsistencies. 
     * @summary Get a list of the insured years of the users in the family. By default the list is filtered and doesn\'t contain years older than 2007
     * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
     * @param {boolean} [fullList] Boolean to disable the filtering, set to \&#39;true\&#39; if you want the full list of active years
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getInsuredYearsOfFamily(xUserid: number, fullList?: boolean, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).getInsuredYearsOfFamily(xUserid, fullList, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the marketing information for a user, so it can be used for analytics services like the GTM
     * @param {number} insuredNr 
     * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getMarketingInformationForUser(insuredNr: number, xUserid: number, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).getMarketingInformationForUser(insuredNr, xUserid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * The available contacts are sorted and ordered according to the priority of the category, low -> high.<br> For users with the SmartCare (SMC) model the MEDGATE contact has priority, is the first contact in the list. 
     * @summary Get the medical contacts for a user
     * @param {number} insuredNr 
     * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getMedicalContactsForUser(insuredNr: number, xUserid: number, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).getMedicalContactsForUser(insuredNr, xUserid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the first name, last name and insurance number for a user, so a new password can be validated against it
     * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getPasswordInformation(xUserid: number, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).getPasswordInformation(xUserid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the products of each user in the family, only the OKP products will also contain the model and franchise values, the others only the code
     * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getProductsForFamily(xUserid: number, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).getProductsForFamily(xUserid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the products of a user, only the OKP products will also contain the model and franchise values, the others only the code
     * @param {number} insuredNr 
     * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getProductsForUser(insuredNr: number, xUserid: number, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).getProductsForUser(insuredNr, xUserid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the user\'s personal data
     * @param {number} insuredNr 
     * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUser(insuredNr: number, xUserid: number, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).getUser(insuredNr, xUserid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the personal data of each user in the family
     * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUsers(xUserid: number, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).getUsers(xUserid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * @deprecated This endpoint will redirect to the premium calculator according to the given parameters.<br> Be aware to use the right language, the external PR uses localized domains. 
     * @summary Entrypoint for the redirect to the external premium calculator, mind the parameters.
     * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
     * @param {Set<InsuranceCategory>} [categories] @deprecated CSV of the values. Used to toggle which product categories will be shown to the user. &lt;br&gt;Will add the \&#39;vvgEnabled\&#39; and \&#39;okpEnabled\&#39; parameters with the respective values, 0|1 to the redirect
     * @param {Language} [language] Language for the redirect to the premium calculator. If the parameter is omitted the language of authenticated user is used
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public redirectUserToExternalPremiumCalculator(xUserid: number, categories?: Set<InsuranceCategory>, language?: Language, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).redirectUserToExternalPremiumCalculator(xUserid, categories, language, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Call the system premiumcalculator and redirects
     * @param {number} insuredNr 
     * @param {UserFlow} userFlow 
     * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
     * @param {boolean} [okpEnabled] 
     * @param {boolean} [vvgEnabled] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public sendSession(insuredNr: number, userFlow: UserFlow, xUserid: number, okpEnabled?: boolean, vvgEnabled?: boolean, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).sendSession(insuredNr, userFlow, xUserid, okpEnabled, vvgEnabled, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This request will trigger the lookup and signing of the family\'s insurance card numbers for the Vicard.<br> For the signing we require the `publicKeyOfCustomer`. The key is issued by the Vicard SDK the apps implement and is device specific.  If members of a family don\'t have an insurance card number, the API will skip those members.<br> The response will contain an entry for those but the `insuredCardNr` and `vekaNrClaimToken` will be `null`  Example: ``` [   {    \"insuredNr\": 2000001,    \"insuredCardNr\": \"80000000000000000008\",    \"vekaNrClaimToken\": \"v2.public.ey...\"   },   {    \"insuredNr\": 2000002,    \"insuredCardNr\": null,    \"vekaNrClaimToken\": null   }   ...  ] ```  [Confluence Vicard Documentation](https://confluence.atupri.ch/x/fI5UB)  **FOR THE DEV AND TEST ENVIRONMENT THE SERVICE WILL RETURN MOCKED DATA**<br> To provide some predictability a small algorithm will, depending on the insuredNr of the user, use and return token and card numbers.  Algorithm:<br> insuredNr modulo 100 = value i.e. get the last two digits of the insured number.<br>Distribution for the mock data, value in between:<br> 00 - 49 - KVG<br> 50 - 69 - KVG_GOLD<br> 70 - 79 - CARD_EXPIRED<br> 80 - 89 - VVG<br> 90 - 99 - LICHTENSTEIN  | Card Number            | Hint / Description       | | -----------------------| -------------------------| | 80756 01234 00000 00108| KVG                      | | 80756 01234 00000 00033| KVG Gold                 | | 80756 01234 00000 00057| Expired card             | | 80756 01234 00000 02666| VVG                      | | 80756 01234 11031 01037| Fürstentum Liechtenstein |  Test data from [Dokumentation Vicard - Testdaten](https://confluence.atupri.ch/display/MVP/Dokumentation+Vicard#DokumentationVicard-Testdaten) 
     * @summary Sign and get the insurance cards of the family for the Vicard interface.
     * @param {number} xUserid insuredNr of the authenticated user. Must be an active AKUPO user
     * @param {SignInsuranceCardsRequest} signInsuranceCardsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public singInsuranceCards(xUserid: number, signInsuranceCardsRequest: SignInsuranceCardsRequest, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).singInsuranceCards(xUserid, signInsuranceCardsRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


