import createDecorator from 'final-form-calculate';

// config
import { mappingData as getTariffMapping } from '@src/config/getTariff';

// util
import {
    findTariff as findOkpTariff,
    findCategory as findOkpCategory,
    findProduct as findOkpProduct,
} from '@src/util/tariff/okp';
import { findSelectedProducts as findSelectedVvgProducts, findTariff as findVvgTariff } from '@src/util/tariff/vvg';

export const createOkpTariffDecorator = () => {
    const updates = (value, allValues) => {
        const apiData = allValues.apiData.okp;
        const { products, categories } = apiData;
        const tariffArray = [];
        const tariff = findOkpTariff(apiData, allValues.okp);

        if (allValues.okp.enabled && tariff) {
            const { id: tariffId, type, parent, ...tariffData } = tariff;
            const practice = allValues?.okp?.practices?.[parent];
            let newTariff = {
                option: allValues.okp.option,
                franchise: findOkpCategory(categories, allValues.okp.category)?.value,
                accidentIncluded: allValues.okp.variant == getTariffMapping.okp.variants.accidentIncluded ? '1' : '0',
                product: allValues.okp.product,
                gatekeeper: findOkpProduct(products, allValues.okp.product)?.gatekeeper,
                tariff: tariffId,
                type: 'OKP',
                zsr: practice?.zsrNr || '',
                ...tariffData,
            };

            tariffArray.push(newTariff);
        }

        return tariffArray;
    };

    return createDecorator(
        {
            field: 'okp.enabled',
            updates: {
                'tariff.okp': updates,
            },
        },
        {
            field: 'okp.category',
            updates: {
                'tariff.okp': updates,
            },
        },
        {
            field: 'okp.product',
            updates: {
                'tariff.okp': updates,
            },
        },
        {
            field: 'okp.variant',
            updates: {
                'tariff.okp': updates,
            },
        },
        {
            field: 'okp.practices',
            updates: {
                'tariff.okp': updates,
            },
        },
        {
            field: 'apiData.okp',
            updates: {
                'tariff.okp': updates,
            },
        },
        {
            field: 'apiData.vvg',
            updates: {
                'tariff.okp': updates,
            },
        }
    );
};

export const createVvgTariffDecorator = () => {
    const updates = (value, allValues) => {
        const apiData = allValues.apiData.vvg;
        const additionalData = allValues.additionalData;
        const tariffArray = [];
        const products = findSelectedVvgProducts(allValues.vvg);

        if (allValues.vvg.enabled) {
            products.forEach(p => {
                const { section, product, productGroup } = p;
                const tariff = findVvgTariff(product, apiData, additionalData);

                if (tariff) {
                    const { id: tariffId, type, parent, ...tariffData } = tariff;

                    tariffArray.push({
                        section,
                        product,
                        productGroup,
                        tariff: tariffId,
                        type: 'VVG',
                        ...tariffData,
                    });
                }
            });
        }

        return tariffArray;
    };

    return createDecorator(
        {
            field: 'vvg.enabled',
            updates: {
                'tariff.vvg': updates,
            },
        },
        {
            field: 'vvg.section',
            updates: {
                'tariff.vvg': updates,
            },
        },
        {
            field: 'vvg.productGroups',
            updates: {
                'tariff.vvg': updates,
            },
        },
        {
            field: 'vvg.products',
            updates: {
                'tariff.vvg': updates,
            },
        },
        {
            field: 'apiData.vvg',
            updates: {
                'tariff.vvg': updates,
            },
        },
        {
            field: 'apiData.okp',
            updates: {
                'tariff.vvg': updates,
            },
        }
    );
};
