import { useCallback } from 'react';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';

// util
import { getBasket, isUnborn, PREVIOUS_INSURER_MOVED } from '@src/util/person';
import { getProductTrackingTitle } from '@src/util/basket';
import { isProcessWithHQ } from '@src/util/process';
import { calculatePriceWithTax } from '@src/util/tariff/okp';

export const useAnalytics = () => {
    const sendDataToGTM = useGTMDispatch();

    const isInitialized = () => !!window.dataLayer;

    const gtmSendEvent = useCallback(
        data => {
            if (isInitialized()) sendDataToGTM(data);
        },
        [sendDataToGTM]
    );

    return {
        gtmSendEvent,
    };
};

const getAntragSteller = person => {
    const isMigrant = person.previousInsurer === PREVIOUS_INSURER_MOVED;
    let antragSteller = 'schweiz';

    if (isMigrant) {
        antragSteller = 'zuzug';
    }

    if (isUnborn(person)) {
        antragSteller = 'ungeboren';
    }

    return antragSteller;
};

export const getBasketProductTrackingData = (eventName, data) => {
    const privateData = data.attributes.privateData;
    const persons = data.relationships.persons;
    const basket = data.relationships.basket;
    const anzahlPersonen = persons.length;
    const onboarding = privateData.confirmationMyAtupri ? 'true' : 'false';
    const entries = [];

    const eventCategory = getEventCategory(privateData.onlineSubmission, data);
    let hasOkp = false;
    let hasVvg = false;

    persons.map(person => {
        const personBasket = getBasket(basket, person.id);
        const hasProducts = personBasket.okp.length > 0 || personBasket.vvg.length > 0;

        if (hasProducts) {
            const antragSteller = getAntragSteller(person);

            personBasket.okp.map(product => {
                entries.push(
                    prepareProductEntries(
                        product,
                        'grundversicherung',
                        eventCategory,
                        antragSteller,
                        anzahlPersonen,
                        privateData.onlineSubmission,
                        onboarding
                    )
                );

                hasOkp = true;
            });

            personBasket.vvg.map(product => {
                entries.push(
                    prepareProductEntries(
                        product,
                        'zusatzversicherung',
                        eventCategory,
                        antragSteller,
                        anzahlPersonen,
                        privateData.onlineSubmission,
                        onboarding
                    )
                );

                hasVvg = true;
            });
        }
    });

    return {
        event: eventName,
        conclusion_type: getConclusionType(hasOkp, hasVvg),
        versicherungsModelle: entries,
    };
};

export const getEventCategory = (onlineSubmission, data) => {
    let eventCategory = 'lead offerte post';
    if (onlineSubmission) {
        eventCategory = 'onlineabschluss';

        // check if has any healthQuestion and at least one person has Vvg enabled
        if (isProcessWithHQ(data)) {
            eventCategory = 'lead online antrag';
        }
    }
    return eventCategory;
};

export const prepareProductEntries = (
    product,
    eventAction,
    eventCategory,
    antragSteller,
    anzahlPersonen,
    onlineSubmission,
    onboarding
) => {
    const eventLabel = getProductTrackingTitle(product);

    let entry = {
        eventCategory,
        eventAction,
        eventLabel,
        antragSteller,
        anzahlPersonen,
        price: calculatePriceWithTax(product),
    };

    if (product.franchise) {
        entry.franchise = product.franchise;
    }

    if (onlineSubmission) {
        entry.onboarding = onboarding;
    }

    return entry;
};

export const getPersonProductTrackingData = (eventName, currentPerson, data) => {
    const basket = data.relationships.basket;
    const persons = data.relationships.persons;
    const anzahlPersonen = persons.length;
    const eventCategory = 'produktauswahl';
    const entries = [];

    const personBasket = {
        okp: basket[currentPerson.id]?.okp || [],
        vvg: basket[currentPerson.id]?.vvg || [],
    };
    const hasProducts = personBasket.okp.length > 0 || personBasket.vvg.length > 0;

    if (hasProducts) {
        const antragSteller = getAntragSteller(currentPerson);

        personBasket.okp.map(product => {
            const eventAction = 'grundversicherung';
            const eventLabel = getProductTrackingTitle(product);

            let entry = {
                eventCategory,
                eventAction,
                eventLabel,
                antragSteller,
                anzahlPersonen,
                price: calculatePriceWithTax(product),
            };

            if (product.franchise) {
                entry.franchise = product.franchise;
            }

            entries.push(entry);
        });

        personBasket.vvg.map(product => {
            const eventAction = 'zusatzversicherung';
            const eventLabel = getProductTrackingTitle(product);

            let entry = {
                eventCategory,
                eventAction,
                eventLabel,
                antragSteller,
                anzahlPersonen,
                price: product.price,
            };

            entries.push(entry);
        });
    }

    return {
        event: eventName,
        versicherungsModelle: entries,
    };
};

export const getFormSubmitTrackingData = franchise => {
    let trackingData = { event: 'quick_calculator_submit' };
    if (franchise) {
        trackingData.franchise = franchise;
    }
    return trackingData;
};

export const getQuickCalculatorStartTrackingData = () => {
    return { event: 'quick_calculator_start' };
};

export const getFormErrorTrackingData = (eventLabel, subject) => {
    return {
        event: 'ux_error',
        eventCategory: 'ux error',
        eventAction: 'formular',
        eventLabel,
        subject,
    };
};

export const getProductSelectionTrackingData = (eventAction, eventLabel, price) => {
    return {
        event: 'praemienRechnerProduktwahl',
        versicherungsModelle: [
            {
                eventCategory: 'produktauswahl',
                eventAction: eventAction,
                eventLabel: eventLabel,
                anzahlPersonen: 1,
                price,
            },
        ],
    };
};

export const getPersonInformationErrorTrackingData = errorCount => {
    return {
        event: 'ux_error',
        eventCategory: 'ux error',
        eventAction: 'formular',
        eventLabel: errorCount,
        subject: 'gesundheitsdaten',
    };
};

export const getExpiredSessionErrorTrackingData = () => {
    return {
        event: 'ux_error',
        eventCategory: 'ux error',
        eventAction: 'session',
        subject: 'session expired',
    };
};

export const getProductImpressionTrackingData = (okp = [], vvg = []) => {
    const trackingData = {
        event: 'praemienRechnerProduktImpression',
        versicherungsModelle: [],
    };

    if (okp) {
        okp.forEach(okpProduct => {
            trackingData.versicherungsModelle.push({
                eventCategory: 'produktimpression',
                eventAction: 'grundversicherung',
                eventLabel: okpProduct.title,
                preselected: okpProduct.selected ? 'true' : 'false',
                price: okpProduct.price,
            });
        });
    }

    if (vvg) {
        vvg.forEach(vvgProduct => {
            trackingData.versicherungsModelle.push({
                eventCategory: 'produktimpression',
                eventAction: 'zusatzversicherung',
                eventLabel: vvgProduct.title,
                preselected: vvgProduct.selected ? 'true' : 'false',
                price: vvgProduct.price,
            });
        });
    }

    return trackingData;
};

export const getConclusionType = (hasOkp, hasVvg) => {
    let conclusionType = '';

    if (hasOkp && hasVvg) {
        conclusionType = 'combination';
    } else if (hasOkp) {
        conclusionType = 'grundversicherung';
    } else if (hasVvg) {
        conclusionType = 'zusatzversicherung';
    }

    return conclusionType;
};

export const getNextClickTrackingData = (healthForm = false) => {
    return {
        event: 'ux_interaction',
        eventCategory: 'ux',
        eventAction: 'button',
        eventLabel: 'weiter',
        'health-form': healthForm,
    };
};
