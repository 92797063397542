import { useState, useEffect } from 'react';
import i18n from '@src/config/i18n';

export function useI18nReady() {
    const [isReady, setIsReady] = useState(false);

    useEffect(() => {
        if (isReady || i18n.isInitialized) {
            setIsReady(true);
            return;
        }

        // if it's not already initialized, add a listener
        const handleInitialization = () => {
            setIsReady(true);
        };

        i18n.on('initialized', handleInitialization);

        return () => {
            i18n.off('initialized', handleInitialization);
        };
    }, [isReady]);

    return isReady;
}
