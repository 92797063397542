import Cookies from 'universal-cookie';
import queryString from 'query-string';
import { isLocalUrl } from '@src/config/env';
import { deepMerge } from '@src/util/data';
import { personTemplate } from '@src/data/templates/person';

export const getPerson = (submission, personId) => {
    return submission?.relationships?.persons?.find(p => {
        return p.id == personId;
    });
};

export const getPersonIndex = (submission, personId) => {
    return submission?.relationships?.persons?.findIndex(person => person.id === personId);
};

export const getHealthQuestions = (submission, personId) => {
    return submission?.relationships?.healthQuestions?.[personId];
};

export function cleanUpReferences(deletedPersonIdsArray, dataObject) {
    for (const deletedPersonId of deletedPersonIdsArray) {
        if (dataObject.data.relationships.healthQuestions[deletedPersonId]) {
            delete dataObject.data.relationships.healthQuestions[deletedPersonId];
        }
        if (dataObject.data.relationships.basket[deletedPersonId]) {
            delete dataObject.data.relationships.basket[deletedPersonId];
        }
    }

    return dataObject;
}

export function composePersonsData(personsData, UIDefaults, dataObject, autosaved) {
    if (personsData) {
        personsData.map((p, index) => {
            if (p.newPerson) {
                delete p.newPerson;

                personsData[index] = deepMerge([
                    personTemplate,
                    {
                        municipalityId: personsData[0].municipalityId,
                        municipalityInfo: personsData[0].municipalityInfo,
                        place: personsData[0].place,
                        zipText: personsData[0].zipText,
                        okpEnabled: UIDefaults.okpEnabled,
                        vvgEnabled: UIDefaults.vvgEnabled,
                    },
                    p,
                ]);

                dataObject.data.relationships.healthQuestions[p.id] = {
                    pristine: false,
                    ready: false,
                };
                dataObject.data.relationships.basket[p.id] = {
                    okp: [],
                    vvg: [],
                };
            }
        });
        dataObject.data.relationships.persons = deepMerge([dataObject.data.relationships.persons, personsData]);
    } else if (autosaved?.['personForm']?.values) {
        // only save autosaved data when it's not submitted anyway (personsData set)
        dataObject.data.relationships.persons = deepMerge([
            dataObject.data.relationships.persons,
            autosaved['personForm'].values.persons,
        ]);
    }

    return dataObject;
}

export function composeSinglePersonData(singlePersonData, submission, personId, dataObject, autosaved) {
    if (singlePersonData) {
        const personIndex = getPersonIndex(submission, personId);
        dataObject.data.relationships.persons[personIndex] = deepMerge([
            dataObject.data.relationships.persons[personIndex],
            singlePersonData,
        ]);
    }

    if (autosaved && 'personInformationForm' in autosaved) {
        for (const autosavedPersonId in autosaved['personInformationForm']) {
            const autosavedPersonData = autosaved['personInformationForm'][autosavedPersonId]?.values?.person;
            if (autosavedPersonData) {
                const personIndex = getPersonIndex(submission, autosavedPersonId);
                dataObject.data.relationships.persons[personIndex] = deepMerge([
                    dataObject.data.relationships.persons[personIndex],
                    autosavedPersonData,
                ]);
            }
        }
    }

    return dataObject;
}

export function composeHealthQuestionData(healthQuestionsData, dataObject, personId, autosaved) {
    if (healthQuestionsData) {
        dataObject.data.relationships.healthQuestions[personId] = deepMerge([
            dataObject.data.relationships.healthQuestions[personId],
            healthQuestionsData,
        ]);
    } else if (autosaved && 'personInformationForm' in autosaved) {
        // only save autosaved data when it's not submitted anyway (healthQuestionsData set)
        for (const autosavedPersonId in autosaved['personInformationForm']) {
            const autosavedPersonData = autosaved['personInformationForm'][autosavedPersonId]?.values?.healthQuestions;
            if (autosavedPersonData) {
                dataObject.data.relationships.healthQuestions[autosavedPersonId].answers = deepMerge([
                    dataObject.data.relationships.healthQuestions[autosavedPersonId].answers || {},
                    autosavedPersonData,
                ]);
            }

            // update hq form ready state if autosaved form is invalid. But don't make it valid on autosaved. we want another submit for that according to specs
            if (!autosaved['personInformationForm'][autosavedPersonId].isFormValid) {
                dataObject.data.relationships.healthQuestions[autosavedPersonId].ready = false;
            }
        }
    }

    // remove helper data from answers so it's not sent to api
    dataObject = removeHelperDataFromHealthQuestions(dataObject, personId);

    return dataObject;
}

export function removeHelperDataFromHealthQuestions(dataObject, personId) {
    if (dataObject.data.relationships.healthQuestions[personId]?.answers) {
        for (const key in dataObject.data.relationships.healthQuestions[personId].answers) {
            if (key.startsWith('_tmp_')) {
                delete dataObject.data.relationships.healthQuestions[personId].answers[key];
            }
        }
    }
    return dataObject;
}

export function composeBasketData(basketData, dataObject, submission, autosaved) {
    if (basketData) {
        dataObject.data.relationships.basket = deepMerge([submission.relationships.basket, basketData]);
    } else if (autosaved?.['productForm']) {
        for (const autosavedByPerson in autosaved['productForm']) {
            if (autosaved['productForm'][autosavedByPerson].values) {
                const tariff = autosaved['productForm'][autosavedByPerson].values.tariff;
                if (tariff) {
                    dataObject.data.relationships.basket[autosavedByPerson] = tariff;
                }
            }
        }
    }
    return dataObject;
}

export function composePrivateData(rest, dataObject, submission, autosaved) {
    if (rest) {
        dataObject.data.attributes = deepMerge([submission.attributes, { ...rest }]);
    }

    if (autosaved?.['paymentForm']?.values) {
        dataObject.data.attributes.privateData = deepMerge([
            dataObject.data.attributes.privateData,
            autosaved['paymentForm'].values,
        ]);
    }

    if (autosaved?.['confirmationForm']?.values) {
        dataObject.data.attributes.privateData = deepMerge([
            dataObject.data.attributes.privateData,
            autosaved['confirmationForm'].values,
        ]);
    }
    return dataObject;
}

export function getSubmissionId() {
    const cookies = new Cookies();

    let id = null;

    // check for submissionId in path
    const queryParams = queryString.parse(window.location.search);
    if (queryParams.id) {
        id = queryParams.id;
    }

    // check cookie
    if (!id && cookies.get('submissionId')) {
        id = cookies.get('submissionId');
    }

    if (id) {
        setSubmissionId(id);
        return { uuid: id };
    }

    return null;
}

export function setSubmissionId(id) {
    const cookies = new Cookies();
    const options = {
        path: '/',
        sameSite: 'strict',
        secure: !isLocalUrl(),
        httpOnly: false,
    };

    cookies.set('submissionId', id, options);
}

export function clearSubmissionId() {
    const cookies = new Cookies();

    cookies.remove('submissionId', { path: '/' });
}
