import React from 'react';
import { bool } from 'prop-types';
import i18next from 'i18next';
import { pathByRouteName } from '@src/util/routes';
import { Link } from 'atupri-component-library/lib/primitives';

import { StyledPersonEditContainer } from '../PersonSelect.style';

const PersonEdit = ({ isInDropdown }) => (
    <StyledPersonEditContainer isInDropdown={isInDropdown}>
        <Link
            linkText={i18next.t('PersonSelect.edit')}
            link={{
                to: pathByRouteName('PersonPage'),
            }}
            iconBefore="pen"
            color="primary"
        />
    </StyledPersonEditContainer>
);

PersonEdit.defaultProps = {
    isInDropdown: false,
};

PersonEdit.propTypes = {
    isInDropdown: bool,
};

export default PersonEdit;
