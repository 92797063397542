export const stripZip = value => {
    return value.replace(/[^\d]*/g, '').substring(0, 4);
};

export const getLocationLabel = (item, zip) => {
    let locationLabel = '';
    if (item) {
        if (item.city !== item.community) {
            locationLabel = `${item.city} / ${item.community}`;
        } else {
            locationLabel = `${item.city}`;
        }
        if (zip) {
            locationLabel = `${zip} ${locationLabel}`;
        }
    }
    return locationLabel;
};
