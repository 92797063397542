import { addAppEntities } from '@src/ducks/premcalcData.duck';
import { API_GET_SUBMISSION } from '@src/api/submission.api';
import { getSubmissionId } from '@src/util/submission';

const requestAction = actionType => params => ({ type: actionType, payload: { params } });

const successAction = actionType => result => ({ type: actionType, payload: result.data });

const errorAction = actionType => error => ({ type: actionType, payload: error, error: true });

// ================ Action types ================ //

export const UPDATE_SUBMISSION_REQUEST = 'app/ProductBasketPage/UPDATE_SUBMISSION_REQUEST';
export const UPDATE_SUBMISSION_SUCCESS = 'app/ProductBasketPage/UPDATE_SUBMISSION_SUCCESS';
export const UPDATE_SUBMISSION_ERROR = 'app/ProductBasketPage/UPDATE_SUBMISSION_ERROR';

export const SHOW_SUBMISSION_REQUEST = 'app/ProductBasketPage/SHOW_SUBMISSION_REQUEST';
export const SHOW_SUBMISSION_SUCCESS = 'app/ProductBasketPage/SHOW_SUBMISSION_SUCCESS';
export const SHOW_SUBMISSION_ERROR = 'app/ProductBasketPage/SHOW_SUBMISSION_ERROR';

// ================ Reducer ================ //

const initialState = {
    updateSubmissionError: null,
    showSubmissionError: null,
    updateInProgress: false,
};

export default function reducer(state = initialState, action = {}) {
    const { type, payload } = action;
    switch (type) {
        case SHOW_SUBMISSION_REQUEST:
            return { ...state, showSubmissionError: null };
        case SHOW_SUBMISSION_SUCCESS:
            return { ...initialState };
        case SHOW_SUBMISSION_ERROR:
            return { ...state, showSubmissionError: payload, redirectToSubmission: false };

        default:
            return state;
    }
}

// ================ Action creators ================ //

export const updateSubmission = requestAction(UPDATE_SUBMISSION_REQUEST);
export const updateSubmissionSuccess = successAction(UPDATE_SUBMISSION_SUCCESS);
export const updateSubmissionError = errorAction(UPDATE_SUBMISSION_ERROR);

export const showSubmission = requestAction(SHOW_SUBMISSION_REQUEST);
export const showSubmissionSuccess = successAction(SHOW_SUBMISSION_SUCCESS);
export const showSubmissionError = errorAction(SHOW_SUBMISSION_ERROR);

// ================ Thunk ================ //

export function requestShowSubmission(actionPayload) {
    return (dispatch, getState, endpoint) => {
        dispatch(showSubmission(actionPayload));

        return API_GET_SUBMISSION(actionPayload.id.uuid)
            .then(result => {
                dispatch(addAppEntities(result));

                // clear state.ProductBasketsPag
                dispatch(showSubmissionSuccess(result));

                return result;
            })
            .catch(e => {
                dispatch(showSubmissionError(e));
            });
    };
}

// only load data if not alerady exist in persistent redux storage
export const loadData = params => (dispatch, getState, endpoint) => {
    const { userId } = params;
    let promiseArr = [];

    const payload = {
        id: getSubmissionId(),
        userId: userId,
    };

    // check if persistent data exist and has same id, otherwise request API call
    // if(!getState().premcalcData.entities.ownSubmission) {
    promiseArr.push(dispatch(requestShowSubmission(payload)));
    // }

    return Promise.all(promiseArr)
        .then(response => {
            return response;
        })
        .catch(e => {
            throw e;
        });
};
