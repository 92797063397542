import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import i18next from 'i18next';

// api
import { API_GET_INSURER_LIST } from '@src/api/insurerList.api';

// components
import { Main, NamedRedirect, Page, SaveSession } from '@src/components';
import { Footer, Header } from 'atupri-component-library/lib/components';

// data
import { footerData, headerData } from '@src/data';

// ducks
import { getPremcalcEntities } from '@src/ducks/premcalcData.duck';
import { loadData, requestUpdateSubmission } from '@src/ducks/healthQuestions.duck';
import { requestExtendSubmission } from '@src/ducks/submission.duck';

// util
import { isAutosavedFormInvalid } from '@src/util/autosave';
import { ensureOwnSubmission } from '@src/util/data';
import { convertDateToPresentationFormat } from '@src/util/date';
import { needsToAnswerHealthQuestions, isPersonDatasetComplete } from '@src/util/person';
import { getPersonIndex, getPerson, getSubmissionId } from '@src/util/submission';
import { isMobileApp } from '@src/util/mobileAppDetection';

// style
import {
    StyledContainer,
    StyledHeader,
    StyledPersonal,
    StyledUserIcon,
    StyledUserInfo,
    StyledUserName,
    StyledState,
    StyledStateInfo,
    StyledStateIcon,
    StyledStateValue,
    StyledHealthQuestionsRoot,
} from './HealthQuestionPage.style';
import PersonInformationForm from '@src/forms/PersonInformationForm/PersonInformationForm';

export const HealthQuestionsPage = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const submissionId = getSubmissionId();

    const { page, showSubmissionError, currentProducts, submission } = useSelector(state => {
        let submission = null;
        if (typeof submissionId !== 'undefined') {
            const submissions = getPremcalcEntities(state, [{ id: submissionId, type: 'ownSubmission' }]);
            submission = submissions.length === 1 ? submissions[0] : null;
        }

        return {
            page: state.HealthQuestionsPage,
            showSubmissionError: state.EditSubmissionPage.showSubmissionError,
            currentProducts: state.apiData?.currentProducts,
            submission,
        };
    });

    const [insurerList, setInsurerList] = useState([]);

    const { showHealthQuestionsError = null, updateSubmissionSuccess = null } = page;
    const healthQuestions = page.healthQuestions;
    const currentSubmission = ensureOwnSubmission(submission);

    const person = getPerson(currentSubmission, params.userId);
    const personIndex = getPersonIndex(currentSubmission, params.userId);
    const birthdatePresentation = convertDateToPresentationFormat(person?.birthdate);

    let status = person && isPersonDatasetComplete(person.id, currentSubmission, currentProducts);
    status = status && !isAutosavedFormInvalid(submissionId, 'personInformationForm', params.userId);

    const personNeedsToAnswerHealthQuestions = person && needsToAnswerHealthQuestions(person.id, submission);

    useEffect(() => {
        let mounted = true;

        API_GET_INSURER_LIST().then(result => {
            if (mounted) {
                setInsurerList(result.data.length > 0 ? result.data.map(i => ({ value: i.bsvNr, label: i.name })) : []);
            }
        });

        return () => {
            mounted = false;
        };
    }, []);

    if (showSubmissionError) {
        return <NamedRedirect name="PersonPage" />;
    }

    if (updateSubmissionSuccess || showHealthQuestionsError) {
        return <NamedRedirect name="EditSubmissionPage" params={{ tab: 'cockpit' }} />;
    }

    let datasetComplete = !!currentSubmission && insurerList.length > 0;
    if (personNeedsToAnswerHealthQuestions) {
        datasetComplete = datasetComplete && page.healthQuestionsReady;
    }

    const getIconName = person => {
        let iconName;

        if (person.gender === 'm') {
            iconName = 'man';
        } else if (person.gender === 'f') {
            iconName = 'woman';
        } else {
            iconName = 'embryo';
        }

        return iconName;
    };

    const render = () => {
        return (
            <Page>
                <SaveSession
                    submission={currentSubmission}
                    onExtendSubmission={data => dispatch(requestExtendSubmission(data))}
                >
                    {openModal => {
                        return (
                            !isMobileApp() && (
                                <Header
                                    {...headerData()}
                                    sessionButton={{
                                        buttonText: i18next.t('SaveSession.header.buttonSave'),
                                        onClick: openModal,
                                        variant: 'primary',
                                        outlined: true,
                                        iconBefore: 'clock',
                                    }}
                                />
                            )
                        );
                    }}
                </SaveSession>
                <Main complete={datasetComplete}>
                    {datasetComplete ? (
                        <StyledContainer>
                            <StyledHeader>
                                <StyledPersonal>
                                    <StyledUserIcon iconName={getIconName(person)} />
                                    <StyledUserInfo>
                                        <StyledUserName>
                                            {!person.firstname && !person.lastname ? (
                                                <>
                                                    {i18next.t('PersonSelect.person')} {personIndex + 1} (
                                                    {birthdatePresentation})
                                                </>
                                            ) : (
                                                <>
                                                    {person.firstname} {person.lastname} ({birthdatePresentation})
                                                </>
                                            )}
                                        </StyledUserName>
                                    </StyledUserInfo>
                                </StyledPersonal>
                                <StyledState>
                                    {status ? (
                                        <StyledStateInfo>
                                            {i18next.t('HealthQuestionsPage.state')}
                                            <StyledStateValue ready={true}>
                                                <StyledStateIcon iconName="check_outlined" />
                                                {i18next.t('HealthQuestionsPage.stateReady')}
                                            </StyledStateValue>
                                        </StyledStateInfo>
                                    ) : (
                                        <StyledStateInfo>
                                            {i18next.t('HealthQuestionsPage.state')}
                                            <StyledStateValue ready={false}>
                                                <StyledStateIcon iconName="clock_arrow" />
                                                {i18next.t('HealthQuestionsPage.stateDirty')}
                                            </StyledStateValue>
                                        </StyledStateInfo>
                                    )}
                                </StyledState>
                            </StyledHeader>

                            <StyledHealthQuestionsRoot>
                                <PersonInformationForm
                                    currentSubmissionId={currentSubmission.id.uuid}
                                    healthQuestions={healthQuestions}
                                    insurerList={insurerList}
                                    ownSubmission={currentSubmission}
                                    onUpdateSubmission={(tab, values) => dispatch(requestUpdateSubmission(tab, values))}
                                    page={page}
                                    params={params}
                                />
                            </StyledHealthQuestionsRoot>
                        </StyledContainer>
                    ) : (
                        <p>{i18next.t('HealthQuestionsForm.loadingData')}</p>
                    )}
                </Main>
                {!isMobileApp() && <Footer {...footerData()} />}
            </Page>
        );
    };

    return render();
};

HealthQuestionsPage.propTypes = {};

HealthQuestionsPage.loadData = loadData;

export default HealthQuestionsPage;
