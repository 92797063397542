import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useCombobox } from 'downshift';

import { ConfigContext } from '@src/PremiumcalculatorLibraryProvider';
// api
import { API_GET_LOCATIONS } from '@src/api/zip.api';

// components
import { StyledComponent, AtriumComponent } from '@src/components/ZipAutocomplete/partials';

// util
import { stripZip, getLocationLabel } from '@src/util/zip';

const ZipAutocomplete = ({
    label,
    name,
    disabled,
    defaultValue,
    onChangeSelection,
    onChangeValue,
    onBlur,
    isRequired = true,
    variant = 'default',
    errorText = '',
    inverted = false,
}) => {
    const [value, setValue] = useState(defaultValue);
    const [entries, setEntries] = useState([]);
    const baseUrlConfig = useContext(ConfigContext);

    const {
        isOpen,
        getLabelProps,
        getMenuProps,
        getInputProps,
        getComboboxProps,
        highlightedIndex,
        getItemProps,
        openMenu,
        setInputValue,
    } = useCombobox({
        items: entries,
        itemToString: item => getLocationLabel(item, stripZip(value)),
        onInputValueChange: ({ inputValue }) => {
            setValue(inputValue);
            // when full zip is entered - fetch entries
            if (inputValue?.length === 4) {
                API_GET_LOCATIONS(inputValue, undefined, baseUrlConfig?.esbBaseUrl).then(({ data }) =>
                    setEntries(data)
                );
            }
            const zipParts = inputValue.split(' ');

            // when input is empty - reset fields and entries
            if (stripZip(inputValue).length < 4 || zipParts[0].length > 4) {
                setEntries([]);
                setTimeout(() =>
                    onChangeValue({
                        place: '',
                        municipalityId: '',
                        municipalityInfo: '',
                        zip: '',
                        zipText: stripZip(inputValue),
                    })
                );
            }
        },
        onSelectedItemChange: ({ selectedItem }) => {
            if (selectedItem) {
                onChangeSelection({
                    place: selectedItem.city,
                    municipalityId: selectedItem.communityId,
                    municipalityInfo: selectedItem.community,
                    zip: stripZip(value),
                    zipText: getLocationLabel(selectedItem, stripZip(value)),
                });
            }
        },
    });

    useEffect(() => {
        setInputValue(defaultValue);
    }, [defaultValue]);

    const AutocompleteComponent = variant === 'atrium' ? AtriumComponent : StyledComponent;

    return (
        <AutocompleteComponent
            name={name}
            label={label}
            entries={entries}
            disabled={disabled}
            isRequired={isRequired}
            onBlur={onBlur}
            isOpen={isOpen}
            comboboxProps={getComboboxProps({
                onFocus: () => {
                    if (!isOpen && entries.length > 0) {
                        openMenu();
                    }
                },
            })}
            openMenu={openMenu}
            labelProps={getLabelProps()}
            inputProps={getInputProps()}
            menuProps={getMenuProps()}
            getItemProps={getItemProps}
            highlightedIndex={highlightedIndex}
            errorText={errorText}
            inverted={inverted}
        />
    );
};

const { bool, string } = PropTypes;

ZipAutocomplete.propTypes = {
    name: string.isRequired,
    label: string.isRequired,
    disabled: bool,
    defaultValue: string,
    isRequired: bool,
    variant: string,
    errorText: string,
    inverted: bool,
};

export default ZipAutocomplete;
