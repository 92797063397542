import React from 'react';
import PropTypes from 'prop-types';
import ReactDOMServer from 'react-dom/server';
import { GTMProvider } from '@elgorditosalsero/react-gtm-hook';
import { withTranslation } from 'react-i18next';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { StaticRouter } from 'react-router-dom/server';
import { Provider } from 'react-redux';
import configureStore from './store';
import routeConfiguration from './routeConfiguration';
import Routes from './Routes';
import './config/i18n';
import config from './config';
import { gtmParams } from './config/analytics';
import { PersistGate } from 'redux-persist/integration/react';
import { useOneTrust } from './config/oneTrust';
import { useI18nReady } from '@src/hooks/useI18nReady';
import { LoadingIndicator } from '@src/components';

const { any, string } = PropTypes;

export const ClientApp = withTranslation()(props => {
    const { store, persistor } = props;
    useOneTrust();
    const isI18nReady = useI18nReady();
    if (!isI18nReady) {
        return (
            <div>
                <LoadingIndicator />
            </div>
        );
    }
    return (
        <GTMProvider state={gtmParams}>
            <Provider store={store}>
                <HelmetProvider>
                    <PersistGate loading={null} persistor={persistor}>
                        <BrowserRouter basename={config?.basename || '/'}>
                            <Routes routes={routeConfiguration()} />
                        </BrowserRouter>
                    </PersistGate>
                </HelmetProvider>
            </Provider>
        </GTMProvider>
    );
});

ClientApp.propTypes = { store: any.isRequired };

export const ServerApp = withTranslation()(props => {
    const { url, context, helmetContext, store } = props;
    HelmetProvider.canUseDOM = false;
    useOneTrust();
    return (
        <GTMProvider state={gtmParams}>
            <Provider store={store}>
                <HelmetProvider context={helmetContext}>
                    <StaticRouter location={url} context={context}>
                        <Routes routes={routeConfiguration()} />
                    </StaticRouter>
                </HelmetProvider>
            </Provider>
        </GTMProvider>
    );
});

ServerApp.propTypes = { url: string.isRequired, context: any.isRequired, store: any.isRequired };

export const renderApp = (url, serverContext, preloadedState) => {
    const store = configureStore(preloadedState).store;

    const helmetContext = {};
    const body = ReactDOMServer.renderToString(
        <ServerApp url={url} context={serverContext} helmetContext={helmetContext} store={store} />
    );
    const { helmet: head } = helmetContext;
    return { head, body };
};
