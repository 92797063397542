import React from 'react';
import i18next from 'i18next';
import { Button } from 'atupri-component-library/lib/primitives';

import {
    StyledInlineListRow,
    StyledInlineListLabels,
    StyledInlineListControls,
    StyledInlinePrimary,
    StyledInlineSecondary,
} from './InlineListRow.style';

function formatListLabels(labelIds, index, row) {
    return labelIds
        ? labelIds.map(label => (
              <span key={index + label.id}>{label.id ? row[`${label.id}`] : label.label ? label.label : ''}</span>
          ))
        : null;
}

const InlineListRow = ({ item, row, index, onEditClick, onDeleteClick }) => {
    const primaryListLabelIds = item?.listLabels?.primary || '';
    const secondaryListLabelIds = item?.listLabels?.secondary || '';

    const render = () => {
        return (
            <StyledInlineListRow className={`inline-list`} key={item.id + '_' + index}>
                <StyledInlineListLabels>
                    <div>{formatListLabels(primaryListLabelIds, index, row)}</div>
                    <div>{formatListLabels(secondaryListLabelIds, index, row)}</div>
                </StyledInlineListLabels>
                <StyledInlineListControls>
                    <StyledInlineSecondary>
                        <Button
                            type="button"
                            buttonText={i18next.t('InlineList.delete')}
                            iconBefore="trash"
                            variant="secondary"
                            isIncognito
                            onClick={() => onDeleteClick(index)}
                        />
                    </StyledInlineSecondary>
                    <StyledInlinePrimary>
                        <Button
                            type="button"
                            buttonText={i18next.t('InlineList.edit')}
                            iconBefore="pen"
                            isIncognito
                            onClick={() => onEditClick(index)}
                        />
                    </StyledInlinePrimary>
                </StyledInlineListControls>
            </StyledInlineListRow>
        );
    };

    return render();
};

export default InlineListRow;
