import React, { Component } from 'react';
import PropTypes, { object } from 'prop-types';
import { LoadingIndicator } from '@src/components';
import { StyledContainer, StyledMain, StyledMainError } from './Main.style';
import { MessageBox } from 'atupri-component-library/lib/components';
import { StyledH1, StyledTitle, StyledText } from '@src/containers/NotFoundPage/NotFoundPage.style';
import i18next from 'i18next';

class Main extends Component {
    constructor(props) {
        super(props);
        this.state = { mounted: false };
    }

    componentDidMount() {
        this.setState({ mounted: true }); // eslint-disable-line react/no-did-mount-set-state
    }

    render() {
        const { children, complete, blockInterface, hasError, dataError } = this.props;

        return (
            <StyledMain complete={complete} center={complete && !hasError ? null : !complete && hasError ? null : true}>
                <LoadingIndicator show={blockInterface} variant="overlay" />
                {complete && !hasError ? (
                    children
                ) : !complete && hasError ? null : hasError ? (
                    <Main>
                        <StyledContainer>
                            <div>
                                <StyledH1>{i18next.t('Error.title')}</StyledH1>
                                <StyledTitle>{i18next.t('Error.heading')}</StyledTitle>
                                <StyledText>{i18next.t('Error.description')}</StyledText>
                            </div>
                        </StyledContainer>
                    </Main>
                ) : (
                    <LoadingIndicator show />
                )}

                {hasError ? (
                    <StyledMainError>
                        <MessageBox {...dataError} variant="warning" />
                    </StyledMainError>
                ) : null}
            </StyledMain>
        );
    }
}

const { node, string, bool } = PropTypes;

Main.defaultProps = {
    rootClassName: null,
    className: null,
    complete: true,
    children: null,
    hasError: false,
    dataError: null,
};

Main.propTypes = {
    rootClassName: string,
    className: string,
    complete: bool,
    children: node,
    hasError: bool,
    dataError: object,
};

export default Main;
