import React from 'react';
import { bool, object } from 'prop-types';
import { Field } from 'react-final-form';
import i18next from 'i18next';
import classNames from 'classnames';
import { mappingData as getTariffMapping } from '@src/config/getTariff';
import { ButtonGroup, Icon, Slider, Tooltip } from '@atupri/atupri-component-library';

const OkpParamsForm = ({ apiData, inverted = false }) => {
    const categorySliderValues = apiData?.categories?.map(category => Number(category.value)).sort((a, b) => a - b);
    const borderClasses = classNames('relative', '-left-5', 'w-[calc(100%+3rem)]', 'border-t', {
        'border-border-inverted-disabled': inverted,
        'border-border-disabled': !inverted,
    });
    const iconClasses = classNames('text-icon-help', {
        'text-icon-subtle': !inverted,
        'text-icon-inverted-subtle': inverted,
    });

    return (
        <div className="flex flex-col gap-10">
            <div className="flex flex-col">
                <div className={borderClasses}></div>
                <Field id="category" name="category">
                    {fieldProps => (
                        <Slider
                            id={fieldProps.input.id}
                            name={fieldProps.input.name}
                            title={i18next.t(`QuickCalculatorForm.okp.category`, 'Franchise')}
                            valuePrefix="CHF"
                            valueSuffix=".-"
                            tooltipText={i18next.t('QuickCalculatorForm.okp.categoryTooltip')}
                            minLabel={i18next.t('QuickCalculatorForm.okp.categoryMin')}
                            maxLabel={i18next.t('QuickCalculatorForm.okp.categoryMax')}
                            values={categorySliderValues}
                            defaultValue={Number(fieldProps.input.value)}
                            onChange={value => {
                                fieldProps.input.onChange(value);
                            }}
                            variant={inverted ? 'inverted' : 'default'}
                        />
                    )}
                </Field>
            </div>
            <div className="flex flex-col gap-10">
                <div className={borderClasses}></div>
                <div className="flex flex-col">
                    <div className="flex flex-row justify-between text-xl font-semibold lg:text-2xl">
                        <label
                            id="variant_label"
                            htmlFor="variant"
                            className={classNames('font-Neutra', { 'text-text-inverted': inverted })}
                        >
                            {i18next.t('QuickCalculatorForm.okp.variant')}
                        </label>
                        <div>
                            <Tooltip detailText={i18next.t('QuickCalculatorForm.okp.variantTooltip')}>
                                <Icon className={iconClasses} data-testid="icon" iconName="help" />
                            </Tooltip>
                        </div>
                    </div>
                    <div className="relative -left-5">
                        <Field id="variant" name="variant">
                            {fieldProps => (
                                <ButtonGroup
                                    id={fieldProps.input.id}
                                    name={fieldProps.input.name}
                                    onChange={value => fieldProps.input.onChange(value[0])}
                                    activeButtons={[fieldProps.input.value]}
                                    variant={inverted ? 'inverted' : 'default'}
                                    buttons={{
                                        [i18next.t(
                                            `QuickCalculatorForm.okp.variants.${getTariffMapping.okp.variants.accidentNotIncluded}`
                                        )]: Number(getTariffMapping.okp.variants.accidentNotIncluded),
                                        [i18next.t(
                                            `QuickCalculatorForm.okp.variants.${getTariffMapping.okp.variants.accidentIncluded}`
                                        )]: Number(getTariffMapping.okp.variants.accidentIncluded),
                                    }}
                                    required
                                />
                            )}
                        </Field>
                    </div>
                </div>
            </div>
        </div>
    );
};

OkpParamsForm.propTypes = {
    apiData: object,
    inverted: bool,
};

export default OkpParamsForm;
