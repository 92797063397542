import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { StyledFieldset } from './Fieldset.style';

class Fieldset extends Component {
    constructor(props) {
        super(props);
        this.state = { mounted: false };
    }

    componentDidMount() {
        this.setState({ mounted: true }); // eslint-disable-line react/no-did-mount-set-state
    }

    render() {
        const { children, className, rootClassName, disabled = false, questionStyle = false } = this.props;

        const FieldsetDisabled = this.state.mounted ? disabled : true;

        const rootClass = rootClassName || 'FieldsetRoot';
        const classes = classNames(rootClass, className, {
            ['is-disabled']: FieldsetDisabled,
        });

        return (
            <StyledFieldset className={classes} questionStyle={questionStyle} disabled={FieldsetDisabled}>
                {children}
            </StyledFieldset>
        );
    }
}

const { node, string, bool } = PropTypes;

Fieldset.propTypes = {
    children: node,
    className: string,
    rootClassName: string,
    disabled: bool,
    questionStyle: bool,
};

export default Fieldset;
