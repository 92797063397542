import styled from '@emotion/styled';
import { Container } from 'atupri-component-library/lib/helpers';

export const StyledContainer = styled(Container)`
    width: 100%;
`;

export const StyledMainError = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
`;
