const dataTemplate = {
    data: {
        id: {
            uuid: null,
        },
        type: 'ownSubmission',
        attributes: {
            state: 'offer',
            sessionCategory: 'DEFAULT',
            source: 'UO',
            promotion: 'Praemienrechner',
            privateData: {
                onlineSubmission: true,
            },
        },
        relationships: {
            persons: [],
            healthQuestions: {},
            basket: {},
        },
    },
};

export { dataTemplate };
