import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { rem } from 'polished';

import { AccordionItem, AccordionItemContent, BaseAccordionItemTrigger } from 'atupri-component-library/lib/components';
import { Box, Grid, GridItem, Divider } from 'atupri-component-library/lib/helpers';
import { Icon, H2, H3, P3, EmphasizedToggle, P2 } from 'atupri-component-library/lib/primitives';
import {
    animations,
    mixins,
    text,
    colors,
    spaces,
    spacingCurves,
    fontWeights,
} from 'atupri-component-library/lib/core/styles/theme/default';
import { motion } from 'framer-motion';

export const StyledIndent = styled.div`
    ${mixins.responsiveStyles({
        'padding-left': { zero: 0, wide: 80 },
        'padding-right': { zero: 0, wide: 80 },
    })};
`;

export const StyledBox = styled(Box)`
    ${mixins.responsiveStyles({
        'padding-top': { zero: spaces.epsilon, medium: spaces.lambda },
        'padding-bottom': { zero: spaces.epsilon, medium: spaces.eta },
        'padding-left': { zero: spaces.gamma, medium: 106 },
        'padding-right': { zero: spaces.gamma, medium: 106 },
    })};
`;

export const StyledInnerBox = styled(Box)`
    ${mixins.responsiveStyles({
        'padding-left': { zero: 0, medium: spaces.theta },
        'padding-right': { zero: 0, medium: spaces.theta },
    })};
`;

export const StyledToggleBox = styled(Box)`
    position: relative;
    width: 100vw;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    border-bottom: ${rem(1)} solid ${colors.blue500};
`;

export const StyledBoxTitle = styled.div`
    padding-top: ${rem(spaces.delta)};

    > div {
        ${mixins.responsiveStyles({
            width: { zero: 272, medium: 658 },
        })};

        > div {
            ${mixins.responsiveStyles({
                padding: { zero: `${rem(18)} ${rem(37)}`, medium: `${rem(30)} ${rem(155)}` },
            })};
        }
    }

    p {
        ${text.h2};
        font-family: 'Open Sans', sans-serif;
        text-transform: none;
        padding: 0;
    }
`;

export const StyledProductTypeHeader = styled(H2)(({ isActive = false }) => {
    let styles = [];

    if (isActive) {
        styles.push(`color: ${colors.primary};`);
    }

    return styles;
});

export const StyledInfoIcon = styled(Icon)`
    display: inline-block;
    padding-left: ${rem(12)};
    color: ${colors.primary};

    ${mixins.responsiveStyles({
        'font-size': { zero: 16, medium: 20 },
    })};
`;

export const StyledGrid = styled(Grid)`
    ${mixins.responsiveStyles({
        'margin-top': { zero: -spaces.delta, medium: -spaces.epsilon },
    })}
`;

export const StyledGridItem = styled(GridItem)(({ hasBadge = false }) => {
    const defaultStyles = css`
        display: inline-block;
        align-self: flex-end;

        ${mixins.responsiveStyles({
            'margin-top': { zero: spaces.delta, medium: spaces.epsilon },
        })}

        .promotion-badge {
            border-color: ${colors.grey500};
        }

        > div {
            width: 100%;
        }
    `;

    const badgeStyles = css`
        ${mixins.responsiveStyles({
            'margin-top': { zero: spaces.eta, medium: spaces.theta },
        })}
    `;

    const styles = [defaultStyles];

    if (hasBadge) {
        styles.push(badgeStyles);
    }

    return styles;
});

export const StyledEmphasizedToggle = styled(EmphasizedToggle)(
    ({ isActive = false }) => css`
        transition: border-color ${animations.default};

        .promotion-badge {
            ${isActive ? `border-color: ${colors.primary};` : ''}
        }

        &:hover,
        &:hover .promotion-badge {
            border-color: ${colors.primary};
        }
    `
);

export const StyledAccordionItemPrice = styled(P3)`
    margin-left: auto;
    padding-top: ${rem(spaces.alpha)};
    padding-left: ${rem(spaces.gamma)};
    white-space: nowrap;

    ${mixins.responsiveStyles({
        'font-weight': { zero: fontWeights.regular, medium: fontWeights.semibold },
    })};
`;

export const StyledAccordionItemSummary = styled(P3)`
    color: ${colors.grey700};

    ${mixins.responsiveStyles({
        'margin-bottom': { zero: spaces.gamma, medium: 0 },
    })}
`;

export const StyledAccordionItem = styled(AccordionItem)(({ isActive = false, hasBadge = false }) => {
    const defaultStyles = css`
        position: relative;
        border: 1px solid ${colors.grey600};
        border-radius: ${rem(8)};
        transition: border-color ${animations.default};

        ${mixins.responsiveStyles({
            'padding-left': { zero: spaces.delta, medium: spaces.epsilon, large: spaces.zeta, wide: spaces.theta },
            'padding-right': { zero: spaces.delta, medium: spaces.epsilon, large: spaces.zeta, wide: spaces.theta },
        })}

        &:hover {
            border-color: ${colors.primary};
        }

        margin-top: ${rem(hasBadge ? spaces.theta : spaces.epsilon)};
    `;

    const activeStyles = css`
        background-color: ${colors.blue100};
        border-color: ${colors.primary};
    `;

    const styles = [defaultStyles];

    if (isActive) {
        styles.push(activeStyles);
    }

    return styles;
});

export const StyledAccordionItemIconBox = styled(Box)`
    display: flex;
    width: ${rem(64)};
    height: ${rem(64)};
    font-size: ${rem(28)};
    justify-content: center;
    align-items: center;
    margin-right: ${rem(spaces.epsilon)};

    i {
        color: ${colors.primary};
    }
`;

export const StyledAccordionItemTitle = styled(H3)(
    ({ isActive = false }) => css`
        color: ${isActive && colors.primary};
        word-break: break-word;
        flex: 1;

        ${mixins.responsiveStyles({
            'font-size': { zero: 16, medium: 20 },
        })}
    `
);

export const StyledAccordionItemContent = styled(AccordionItemContent)`
    ${mixins.responsiveStyles({
        'padding-bottom': { zero: spaces.epsilon, medium: spaces.theta },
    })}
`;

export const StyledAccordionContentText = styled.div`
    ${text.p3};

    > * {
        margin: 0;

        + * {
            margin-top: ${rem(spaces.delta)};
        }
    }
`;

export const StyledDivider = styled(Divider)(({ isActive = false }) => {
    let styles = [];

    if (isActive) {
        styles.push(`background-color: ${colors.blue500};`);
    }

    return styles;
});

export const StyledCtaBar = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    ${mixins.responsiveStyles({
        'flex-direction': {
            zero: 'column-reverse',
            small: 'row',
        },
        'padding-top': {
            zero: 48,
            medium: 64,
            wide: 80,
        },
        'margin-bottom': {
            zero: spaces.gamma * -1,
            small: 0,
        },
    })}
`;

export const StyledCtaBarCol = styled.div`
    ${mixins.responsiveStyles({
        'margin-bottom': {
            zero: spaces.gamma,
            small: 0,
        },
    })}
`;

export const StyledAccordionHeading = styled.div`
    position: relative;

    ${mixins.responsiveStyles({
        // Header Height + EditSubmissionWizardTabs Height + Inner AccordionItem Space
        'scroll-margin-top': { zero: 65 + 170 + spaces.epsilon, medium: 97 + 178 + spaces.epsilon },
    })}
`;

export const StyledAccordionToggleWrapper = styled.div(
    ({ firstLevel }) => css`
        position: absolute;
        display: flex;
        align-items: center;
        /* H3 Line-Height to match Title Height */
        height: ${rem(32)};

        ${mixins.responsiveStyles({
            top: firstLevel
                ? { zero: spaces.epsilon, medium: spaces.zeta, large: spaces.theta }
                : { zero: spaces.epsilon, medium: spaces.zeta },
        })}
    `
);

export const StyledAccordionTrigger = styled(BaseAccordionItemTrigger)`
    display: flex;
    flex-direction: column;
    width: 100%;
    cursor: pointer;

    ${mixins.responsiveStyles({
        ...spacingCurves.component.hecto('padding-top'),
        ...spacingCurves.component.hecto('padding-bottom'),
        'padding-left': { zero: 0, medium: spaces.theta },
        'padding-right': { zero: 0, medium: spaces.theta },
    })}
`;

export const StyledAccordionTriggerTitle = styled.div(
    ({ firstLevel }) => css`
        display: flex;
        justify-content: space-between;
        width: 100%;

        ${mixins.responsiveStyles({
            'padding-left': { zero: spaces.eta, medium: 0 },
            'padding-bottom': { zero: spaces.beta, medium: spaces.delta },
            'align-items': firstLevel ? { large: 'center' } : { zero: 'baseline' },
        })};
    `
);

export const StyledToggleIconWrapper = styled.div(
    ({ firstLevel }) => css`
        right: 0;
        display: flex;
        align-items: center;
        color: ${colors.primary};

        ${mixins.responsiveStyles({
            position: { zero: 'static', medium: 'absolute' },
            top: firstLevel && { medium: spaces.zeta, large: spaces.theta },
        })}
    `
);

export const StyledToggleIcon = styled(motion.div)`
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${rem(24)};
    /* H3 Line-Height to match Title Height */
    height: ${rem(32)};
`;

export const StyledToggleText = styled.div`
    ${mixins.responsiveStyles({
        display: { zero: 'block', medium: 'none' },
        'padding-left': { zero: spaces.gamma, medium: 0 },
    })}
`;

export const StyledInfoBox = styled.div(
    ({ margin = true }) => css`
        display: flex;
        background: ${colors.blue300};
        color: ${colors.primary};
        padding: ${rem(spaces.delta)};

        ${mixins.responsiveStyles({
            'font-size': { zero: 14, medium: 16 },
            'margin-left': { zero: 0, wide: margin ? spaces.lambda : 0 },
            'margin-right': { zero: 0, wide: margin ? spaces.lambda : 0 },
            'margin-top': { zero: spaces.epsilon, medium: spaces.eta },
            'flex-direction': { zero: 'column', medium: 'row' },
        })};
    `
);

export const StyledInfoBoxIcon = styled(Icon)`
    margin-top: 3px;
    margin-right: 13px;

    ${mixins.responsiveStyles({
        'font-size': { zero: 19 },
        'margin-bottom': { zero: 17, medium: 0 },
    })};
`;

export const StyledPromotionBadge = styled.div(({ isCategory = false, dark = false }) => {
    const defaultStyles = css`
        ${text.l1};
        background-color: ${dark ? colors.primary : colors.white};
        color: ${dark ? colors.white : colors.primary};

        border: 1px solid;
        border-color: inherit;
        border-top-left-radius: ${rem(8)};
        border-top-right-radius: ${rem(8)};

        padding: ${rem(spaces.alpha)} ${rem(spaces.beta)};
        margin: 0;
        ${mixins.responsiveStyles({
            'margin-left': { zero: spaces.delta, medium: spaces.epsilon, large: spaces.zeta, wide: spaces.theta },
        })}
        left: 0;

        position: absolute;
        bottom: 100%;

        max-width: fit-content;
    `;

    const categoryStyles = css`
        bottom: calc(100% - 1px);
        ${mixins.responsiveStyles({
            'margin-left': {
                zero: spaces.epsilon,
                medium: spaces.epsilon,
                large: spaces.epsilon,
                wide: spaces.epsilon,
            },
        })}
    `;

    const styles = [defaultStyles];

    if (isCategory) {
        styles.push(categoryStyles);
    }

    return styles;
});

export const StyledRebateTeaserWrapper = styled(motion.div)`
    ${mixins.responsiveStyles({
        'padding-top': { zero: spaces.epsilon, medium: spaces.eta },
        'padding-bottom': { zero: spaces.epsilon, medium: spaces.kappa },
        'padding-left': { zero: spaces.gamma, medium: 80 },
        'padding-right': { zero: spaces.gamma, medium: 80 },
    })}
`;

export const StyledIntroQuestion = styled(Box)`
    ${mixins.responsiveStyles({
        'padding-top': { zero: spaces.eta, medium: spaces.kappa },
        'padding-bottom': { zero: spaces.zeta, medium: spaces.kappa },
    })}
`;

export const StyledVvgIntroText = styled(P2)`
    font-weight: ${fontWeights.regular};
    padding-bottom: ${rem(spaces.epsilon)};

    b,
    strong {
        font-weight: ${fontWeights.semibold};
    }
`;

export const StyledVvgGrid = styled.div`
    display: flex;
    flex-flow: row wrap;
    gap: ${rem(spaces.delta)};
    width: 100%;
`;

export const StyledVvgGridItem = styled.div`
    display: flex;
    min-width: ${rem(304)};
    padding-left: ${rem(spaces.beta)};
    padding-right: ${rem(spaces.beta)};

    ${mixins.responsiveStyles({
        'padding-bottom': { zero: spaces.delta },
        flex: { zero: '0 0 100%', large: '0 0 50%', ultra: '1 1 0' },
    })}
`;

export const StyledToolTipWrapper = styled.p`
    ${text.p3};
    font-weight: ${fontWeights.regular};
`;
