import axios from 'axios';
import config from '@src/config';

import { prepareOrderJson } from '@src/util/orderPreparation';

// middleware
import { log } from '@src/middleware';

// util
import { clearAutosaved, getAutosaved } from '@src/util/autosave';
import { deepMerge } from '@src/util/data';
import {
    cleanUpReferences,
    composeBasketData,
    composeHealthQuestionData,
    composePersonsData,
    composePrivateData,
    composeSinglePersonData,
    setSubmissionId,
} from '@src/util/submission';
import { uuidv4 } from '@src/util/uuid';
import { findPaying } from '@src/util/person';

// data
import { dataTemplate } from '@src/data/templates/data';
import { personTemplate } from '@src/data/templates/person';

const ANTRAG_API = `${config.apiEsbBase}/orders/v1`;
const SESSIONS_API = `${config.apiSessionBase}/sessions`;
let axiosCancelFunction;

/**
 * @param {string} id
 */
export const API_GET_SUBMISSION = id =>
    new Promise((resolve, reject) => {
        log.info('=== EditSubmission.duck:API_GET_SUBMISSION ===');

        axios
            .get(SESSIONS_API, {
                headers: {
                    sessionId: `${id}`,
                },
                timeout: 60000,
            })
            .then(response => {
                const data = response?.data;
                if (typeof data !== 'string') {
                    resolve(response.data);
                } else {
                    log.error('Error getting state from API: ', data);
                    reject({ data: 'Error.code', code: '18' });
                }
            })
            .catch(e => {
                log.error('Error getting state from API: ', e);
                reject({ data: 'Error.API', code: '19' });
            });
    });

/**
 * @param UIDefaults
 * @param data
 * @param apiBaseUrl
 */
export const API_CREATE_SUBMISSION = (UIDefaults, data, apiBaseUrl) =>
    new Promise((resolve, reject) => {
        log.info('=== EditSubmission.duck:API_CREATE_SUBMISSION ===');

        try {
            let apiUrl = apiBaseUrl ? `${apiBaseUrl}/sessions` : SESSIONS_API;
            let dataObject = JSON.parse(JSON.stringify(dataTemplate));

            const { persons: personsData, basket: basketData, ...rest } = data;

            personsData.map(p => {
                const personId = p.id;

                delete p.newPerson;

                dataObject.data.relationships.persons.push(
                    deepMerge([
                        personTemplate,
                        {
                            municipalityId: data.persons[0].municipalityId,
                            municipalityInfo: data.persons[0].municipalityInfo,
                            place: data.persons[0].place,
                            zipText: data.persons[0].zipText,
                            okpEnabled: UIDefaults.okpEnabled,
                            vvgEnabled: UIDefaults.vvgEnabled,
                        },
                        p,
                    ])
                );

                dataObject.data.relationships.healthQuestions[personId] = {
                    pristine: false,
                    ready: false,
                };
                dataObject.data.relationships.basket[personId] = basketData?.[personId] || {
                    okp: [],
                    vvg: [],
                };
            });

            if (rest) {
                dataObject.data.attributes = deepMerge([dataObject.data.attributes, { ...rest }]);
            }

            axios
                .post(apiUrl, dataObject, {
                    headers: {
                        'Content-Type': 'application/json; charset=UTF-8',
                    },
                    timeout: 5000,
                })
                .then(response => {
                    log.debug('api create response', response);

                    setSubmissionId(response.data.data.id.uuid);
                    resolve(JSON.parse(JSON.stringify(response.data)));

                    clearAutosaved('_NEW');
                })
                .catch(err => {
                    log.error('error', err);
                    reject({ data: 'Error.code', code: '20' });
                });
        } catch (e) {
            log.error('error', e);
            reject({ data: 'Error.API', code: '20' });
        }
    });

/**
 * @param id
 * @param dataToUpdate
 * @param submission
 * @param UIDefaults
 */
export const API_UPDATE_SUBMISSION = (id, dataToUpdate, submission, UIDefaults) =>
    new Promise((resolve, reject) => {
        log.info('=== API_UPDATE_SUBMISSION ===');

        let dataObject = { data: submission };

        if (axiosCancelFunction) {
            axiosCancelFunction();
        }

        const cancelTokenSource = axios.CancelToken.source();
        axiosCancelFunction = cancelTokenSource.cancel;

        const {
            personId,
            persons: personsData,
            person: singlePersonData,
            healthQuestions: healthQuestionsData,
            basket: basketData,
            ...rest
        } = dataToUpdate;
        const autosaved = getAutosaved(id);
        const oldPersonIdsArray = submission.relationships.persons.map(p => p.id);
        let newPersonIdsArray = [];
        let deletedPersonIdsArray;

        dataObject = composePersonsData(personsData, UIDefaults, dataObject, autosaved);

        // check for deleted persons
        newPersonIdsArray = dataObject.data.relationships.persons.map(p => p.id);
        deletedPersonIdsArray = oldPersonIdsArray.filter(oldPersonId => !newPersonIdsArray.includes(oldPersonId));

        dataObject = composeSinglePersonData(singlePersonData, submission, personId, dataObject, autosaved);
        dataObject = composeHealthQuestionData(healthQuestionsData, dataObject, personId, autosaved);
        dataObject = composeBasketData(basketData, dataObject, submission, autosaved);
        dataObject = composePrivateData(rest, dataObject, submission, autosaved);

        // cleanup references to deleted persons...
        dataObject = cleanUpReferences(deletedPersonIdsArray, dataObject);

        axios
            .put(SESSIONS_API, dataObject, {
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                    sessionId: `${id}`,
                },
                timeout: 10000,
                cancelToken: cancelTokenSource.token,
            })
            .then(() => {
                clearAutosaved(id);
                clearAutosaved('_NEW');
                resolve(JSON.parse(JSON.stringify(dataObject)));
            })
            .catch(err => {
                if (axios.isCancel(err)) {
                    log.debug('Request canceled.');
                } else {
                    log.error(err);
                    reject({ data: 'Error.code', code: '20' });
                }
            });
    });
/**
 * @param {string} id
 * @param {object} data
 * @param submission
 */
export const API_PUBLISH_SUBMISSION = (id, data, submission) =>
    new Promise((resolve, reject) => {
        log.info('=== API_PUBLISH_SUBMISSION ===');

        let dataObject = { data: submission };
        dataObject.data.attributes = deepMerge([submission.attributes, data]);

        prepareOrderJson(dataObject.data)
            .then(payload => {
                const payingPerson = findPaying(submission);

                log.debug('getantrag payload: ', payload);

                const url = `${ANTRAG_API}`;

                axios
                    .post(url, payload, {
                        headers: {
                            'Content-Type': 'application/json; charset=UTF-8',
                            sessionId: `${id}`,
                        },
                        timeout: 60000,
                    })
                    .then(response => {
                        log.debug('API response: ', response);

                        if (response && typeof response.data !== 'undefined' && typeof response.data !== 'string') {
                            // change submission state in localStorage
                            dataObject.data.attributes.state = 'published';

                            dataObject.included = [
                                {
                                    id: {
                                        uuid: uuidv4(),
                                    },
                                    type: 'ownSubmissionSuccessProps',
                                    attributes: {
                                        pdfData: response.data.doc,
                                        submissionData: dataObject.data,
                                        onlineSubmission: dataObject.data.attributes.privateData.onlineSubmission,
                                        submittedBasket: dataObject.data.relationships.basket,
                                        payingPerson: payingPerson,
                                    },
                                },
                            ];

                            resolve(JSON.parse(JSON.stringify(dataObject)));
                        } else {
                            log.error(
                                'API error publishing submission: ',
                                response.data.message ? response.data.message : '23'
                            );
                            log.debug('Payload', payload);
                            reject({ data: 'Error.code', code: '23' });
                        }
                    })
                    .catch(err => {
                        log.error(err.code);
                        log.error(err.message);
                        log.debug('Payload', payload);
                        reject({ data: 'Error.API', code: '24' });
                    });
            })
            .catch(err => {
                log.error(err.code);
                log.error(err.message);
                log.debug('Data', dataObject);
                reject({ data: 'Error.code', code: '25' });
            });
    });

/**
 * @param {string} id
 */
export const API_CLEAR_SUBMISSION = id =>
    new Promise((resolve, reject) => {
        log.info('=== API_CLEAR_SUBMISSION ===');

        axios
            .delete(SESSIONS_API, {
                headers: {
                    sessionId: `${id}`,
                },
                timeout: 60000,
            })
            .then(() => {
                resolve({
                    status: 200,
                    statusText: '',
                    data: { id: { uuid: id } },
                });
            })
            .catch(err => {
                log.error({ code: err.code, error: err.message });
                reject({ data: 'Error.code', code: '26' });
            });
    });
