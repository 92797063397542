import React from 'react';
import i18next from 'i18next';
import { Field } from 'react-final-form';
import { bool } from 'prop-types';
import classNames from 'classnames';

// utils
import { convertDateToPresentationFormat } from '@src/util/date';

const Summary = ({ inverted = false }) => {
    const textClasses = classNames('text-base', 'lg:text-lg', { 'text-text-inverted': inverted });
    const titleClass = classNames('font-Neutra', 'text-xl', 'font-semibold', 'lg:text-2xl', {
        'text-text-inverted': inverted,
    });

    return (
        <div className="flex flex-col gap-3">
            <h3 className={titleClass}>{i18next.t('QuickCalculatorForm.summary.title', 'Ihre Person')}</h3>
            <div>
                <div className={textClasses}>
                    <Field name="gender">
                        {fieldProps =>
                            fieldProps.input.value &&
                            i18next.t(`QuickCalculatorForm.genderOptions.summary.${fieldProps.input.value}`)
                        }
                    </Field>
                </div>
                <div className={textClasses}>
                    <Field name="birthdate">
                        {fieldProps =>
                            fieldProps.input.value && convertDateToPresentationFormat(fieldProps.input.value)
                        }
                    </Field>
                </div>
                <div className={textClasses}>
                    <Field name="zip">{fieldProps => fieldProps.input.value?.zipText}</Field>
                </div>
            </div>
        </div>
    );
};

Summary.propTypes = {
    inverted: bool,
};
export default Summary;
