import { getAgeFromDate } from '@src/util/date';
import { needsToAnswerHealthQuestions } from '@src/util/person';
import { getLoggedInUserId } from '@src/config/myAtupriApi';

export const UserFlowEnum = {
    standard: 'standard',
    doctorChangeOnly: 'doctorChangeOnly',
    accidentChangeOnly: 'accidentChangeOnly',
    myAtupriOkp: 'myAtupriOkp',
    myAtupriVvg: 'myAtupriVvg',
    myAtupri: 'myAtupri',
    unknown: 'unknown',
};

/**
 * Returns which process is stored in the session.
 *
 * @param submission
 * @returns UserFlowEnum
 */
export const getUserFlow = submission => {
    return submission.attributes.userFlow ? submission.attributes.userFlow : UserFlowEnum.standard;
};

/**
 * Checks if the process has a logged in user being part of the process.
 *
 * @param {*} persons
 * @param {*} currentProducts
 * @returns boolean
 */
export const isInternalProcess = (persons, currentProducts) => {
    let loginUserIsInProcess = false;

    const userId = getLoggedInUserId();
    if (userId != null && currentProducts) {
        const familyMembers = [];

        // add the insurance numbers of the family to the familyMembers array
        currentProducts.forEach(product => familyMembers.push(product.insuredNr));

        // check if the logged in user is stored in the family and also in the saved session data
        loginUserIsInProcess =
            familyMembers.some(familyMember => familyMember === userId) &&
            Object.values(persons).some(person => familyMembers.includes(person.insuranceNumber));
    }

    return loginUserIsInProcess;
};

/**
 * Checks if it's a "okp and vvg" - process
 *
 * If a personId is given, the check is just for this specific person.
 * Else, the check includes the whole household.
 *
 * @param {*} basket
 * @param string|null personId
 * @returns boolean
 */
export const isOkpAndVvgProcess = (basket, personId = null) => {
    return isOkpProcess(basket, personId) && isVvgProcess(basket, personId);
};

/**
 * Checks if it's a "vvg only" - process
 *
 * If a personId is given, the check is just for this specific person.
 * Else, the check includes the whole household.
 *
 * @param {*} basket
 * @param string|null personId
 * @returns boolean
 */
export const isVvgOnlyProcess = (basket, personId = null) => {
    return !isOkpProcess(basket, personId) && isVvgProcess(basket, personId);
};

/**
 * Checks if it's a "okp only" - process
 *
 * If a personId is given, the check is just for this specific person.
 * Else, the check includes the whole household.
 *
 * @param {*} basket
 * @param string|null personId
 * @returns boolean
 */
export const isOkpOnlyProcess = (basket, personId = null) => {
    return isOkpProcess(basket, personId) && !isVvgProcess(basket, personId);
};

/**
 * Checks if it's a "okp" - process
 *
 * If a personId is given, the check is just for this specific person.
 * Else, the check includes the whole household.
 *
 * @param {*} basket
 * @param string|null personId
 * @returns boolean
 */
export const isOkpProcess = (basket, personId = null) => {
    let isOkp = false;

    for (const currentPersonId in basket) {
        if (!personId || personId === currentPersonId) {
            const currentBasket = basket[currentPersonId];
            isOkp = Array.isArray(currentBasket.okp) && currentBasket.okp.length > 0;
        }

        if (isOkp) {
            break;
        }
    }

    return isOkp;
};

/**
 * Checks if it's a "vvg" - process
 *
 * If a personId is given, the check is just for this specific person.
 * Else, the check includes the whole household.
 *
 * @param {*} basket
 * @param string|null personId
 * @returns boolean
 */
export const isVvgProcess = (basket, personId = null) => {
    let isVvg = false;

    for (const currentPersonId in basket) {
        if (!personId || personId === currentPersonId) {
            isVvg = Array.isArray(basket[currentPersonId].vvg) && basket[currentPersonId].vvg.length > 0;
        }

        if (isVvg) {
            break;
        }
    }

    return isVvg;
};

/**
 * Checks if it's a "child only" - process
 *
 * @param {*} submission
 * @returns boolean
 */
export const isChildOnlyProcess = submission => {
    let childOnly = true;
    const persons = submission.relationships.persons;
    const basket = submission.relationships.basket;
    for (const person of persons) {
        const personBasket = {
            okp: basket[person.id]?.okp || [],
            vvg: basket[person.id]?.vvg || [],
        };
        const hasProducts = personBasket.okp.length > 0 || personBasket.vvg.length > 0;
        let age = getAgeFromDate(person.birthdate);

        if (age >= 18 && hasProducts) {
            childOnly = false;
        }
    }
    return childOnly;
};

/**
 * Checks if at least one person in process has to answer healthQuestions
 *
 * @param {*} object submission
 * @returns boolean
 */
export const isProcessWithHQ = submission => {
    const { persons } = submission.relationships;

    for (const person of persons) {
        if (needsToAnswerHealthQuestions(person.id, submission)) {
            return true;
        }
    }
    return false;
};

/**
 * Returns the process lang appendix
 *
 * @returns string
 * @param basket
 */
export const getProcessLangAppendix = basket => {
    let appendix = '';

    if (isOkpOnlyProcess(basket)) {
        appendix = 'Okp';
    } else if (isVvgOnlyProcess(basket)) {
        appendix = 'Vvg';
    } else if (isOkpAndVvgProcess(basket)) {
        appendix = 'OkpAndVvg';
    }

    return appendix;
};

export const getProcessWithHQLangAppendix = submission => {
    return isProcessWithHQ(submission) ? 'Vvg' : 'Okp';
};
