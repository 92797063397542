import React, { useEffect, useState } from 'react';
import { array, bool, object, string } from 'prop-types';
import throttle from 'lodash/throttle';
import i18next from 'i18next';

// components
import { DropdownMenu } from 'atupri-component-library/lib/components';
import { NamedLink } from '@src/components';
import Total from '@src/components/PersonSelect/partials/Total';
import Person from '@src/components/PersonSelect/partials/Person';
import PersonEdit from '@src/components/PersonSelect/partials/PersonEdit';

// util
import { useWindowSize } from 'atupri-component-library/lib/core/utils/Hooks';
import { isDown, isUp } from 'atupri-component-library/lib/core/utils/Breakpoints';
import { getPersonsTotalPrice } from '@src/util/person';

// style
import {
    StyledDropdownMenuTrigger,
    StyledDropdownMenuContent,
    StyledIconWrap,
    StyledP3,
    StyledAccordionIcon,
    StyledEditWrap,
    StyledPersonList,
} from '../PersonSelect.style';

const DropdownView = ({ persons, isInternalProcess, currentOkpProduct }) => {
    const [isOpen, setIsOpen] = useState(false);
    const { width: windowWidth } = useWindowSize();
    const hasMultiplePersons = persons.length > 1;
    const activePerson = persons.find(person => person.isActive);
    const otherPersons = persons.filter(person => !person.isActive);
    const totalPrice = getPersonsTotalPrice(persons);

    const scrollCallback = () => {
        setIsOpen(false);
    };

    useEffect(() => {
        const throttleCallback = throttle(scrollCallback, 500);
        window.addEventListener('scroll', throttleCallback);

        return () => window.removeEventListener('scroll', throttleCallback);
    }, []);

    const onClickHandler = () => {
        setIsOpen(!isOpen);
    };

    const getCollapseText = () => {
        if (hasMultiplePersons && windowWidth && isUp('medium', windowWidth)) {
            return i18next.t('PersonSelect.showAll', { amount: persons.length });
        }

        return null;
    };

    const renderForInternalProcess = () =>
        windowWidth ? (
            <React.Fragment>
                {isDown('medium', windowWidth) && (
                    <React.Fragment>
                        <Total
                            totalPrice={currentOkpProduct?.monthlyNetPremium?.value || 0}
                            label={i18next.t('PersonSelect.totalOld')}
                        />
                        <Total totalPrice={totalPrice} label={i18next.t('PersonSelect.totalNew')} layout="primary" />
                    </React.Fragment>
                )}
                <Person {...activePerson} totalPrice={null} />
            </React.Fragment>
        ) : null;

    const renderForExternalProcess = () =>
        windowWidth ? (
            <React.Fragment>
                {isDown('medium', windowWidth) && (
                    <Total totalPrice={totalPrice} label={i18next.t('PersonSelect.total')} layout="primary" />
                )}
                <DropdownMenu open={isOpen}>
                    <StyledDropdownMenuTrigger onClick={onClickHandler} hasMultiplePersons={hasMultiplePersons}>
                        <Person hasMultiplePersons={hasMultiplePersons} {...activePerson} />
                        <StyledIconWrap>
                            {isUp('medium', windowWidth) && <StyledP3>{getCollapseText()}</StyledP3>}
                            <StyledAccordionIcon iconName="arrow_up" />
                        </StyledIconWrap>
                        {isUp('medium', windowWidth) && (
                            <Total totalPrice={totalPrice} label={i18next.t('PersonSelect.total')} layout="primary" />
                        )}
                    </StyledDropdownMenuTrigger>
                    <StyledDropdownMenuContent hasMultiplePersons={hasMultiplePersons}>
                        {hasMultiplePersons && (
                            <StyledPersonList>
                                {otherPersons.map(person => {
                                    return (
                                        <NamedLink
                                            key={person.id}
                                            name="EditSubmissionPage"
                                            params={{
                                                tab: 'product',
                                                personId: person.id,
                                            }}
                                        >
                                            <Person {...person} isInDropdown />
                                        </NamedLink>
                                    );
                                })}
                            </StyledPersonList>
                        )}
                        <StyledEditWrap>
                            <PersonEdit isInDropdown={hasMultiplePersons} />
                        </StyledEditWrap>
                    </StyledDropdownMenuContent>
                </DropdownMenu>
            </React.Fragment>
        ) : null;

    return isInternalProcess ? renderForInternalProcess() : renderForExternalProcess();
};

DropdownView.propTypes = {
    currentOkpProduct: object,
    isInternalProcess: bool.isRequired,
    persons: array.isRequired,
    submissionId: string.isRequired,
};

export default DropdownView;
