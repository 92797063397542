import { FUNCTIONAL_COOKIE_GROUP } from '@src/config/oneTrust';

export const checkCookieConsent = () => {
    const cookieConsentGroups = window?.OnetrustActiveGroups?.replace(/(^\s*,)|(,\s*$)/g, '').split(',');
    return cookieConsentGroups ? cookieConsentGroups.includes(FUNCTIONAL_COOKIE_GROUP) : false;
};

export const setCookieConsentChangedCallback = callback => {
    // wait for oneTrust to be loaded
    setTimeout(() => {
        window?.OneTrust?.OnConsentChanged?.(callback);
    }, 500);
};

export const openCookieConsentWindow = () => {
    window?.OneTrust?.ToggleInfoDisplay?.();
};
