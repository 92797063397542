import styled from '@emotion/styled';
import { rem } from 'polished';
import { mixins, text, spaces, colors } from 'atupri-component-library/lib/core/styles/theme/default';
import { H1, Button } from 'atupri-component-library/lib/primitives';

export const StyledButton = styled(Button)`
    margin-top: ${rem(spaces.delta)};
    margin-bottom: ${rem(spaces.zeta)};
`;

export const StyledH1 = styled(H1)`
    padding-bottom: ${rem(32)};
`;

export const StyledContainer = styled.div`
    ${mixins.responsiveStyles({
        'padding-top': { zero: rem(48), large: rem(144) },
        'padding-bottom': { zero: rem(0), large: rem(128) },
    })}
`;

export const StyledContainerAside = styled.div`
    ${mixins.responsiveStyles({
        'padding-top': { zero: rem(32), medium: rem(48), large: rem(144) },
        'padding-bottom': { zero: rem(80), large: rem(128) },
    })}

    &.hidden {
        display: none;
    }
`;

export const StyledContent = styled.div`
    max-width: ${rem(690)};
`;

export const StyledOrderedListbox = styled.div`
    padding: ${rem(24)};
    background: #ffffff;
    box-shadow: 0px 24px 48px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
`;

export const StyledOrderedList = styled.ol`
    list-style: none;
    padding: 0;
    margin: 0;

    li {
        ${text.p3}
        counter-increment: inst;
        display: flex;
        align-items: center;
        padding: 20px 0;
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        color: ${colors.grey800};

        &:first-of-type {
            border-top: 0;
        }

        &:last-of-type {
            padding-bottom: 0;
        }

        &::before {
            ${text.p1};
            content: counter(inst);
            background: #ffeceb;
            display: flex;
            width: 45px;
            height: 45px;
            align-items: center;
            border-radius: 100%;
            justify-content: center;
            margin-right: 19px;
            color: #ff3c34;
            flex: 0 0 auto;
        }
    }
`;

export const StyledP1 = styled.p`
    ${text.p1};
    padding-bottom: ${rem(spaces.delta)};
    color: ${colors.grey700};
`;

export const StyledList = styled.ul(({ horizontal, noSpace }) => {
    let styles = [
        `
    list-style: none;
    padding-left: 0;

    li {
      ${text.p1};
      padding-bottom: ${rem(spaces.delta)};
    }
  `,
    ];

    if (horizontal) {
        styles.push(`
        display: flex;
        margin: 0;

        li {
          margin-right: ${rem(spaces.delta)};
          padding-bottom: 0;
        }
      `);
    }
    return styles;
});

export const StyledListboxTitle = styled.h5`
    ${text.p3};
    color: ${colors.black};
    font-weight: 400;
`;

export const StyledAddress = styled.address(({ bold }) => {
    let styles = [
        `
    ${text.p1};
    padding-bottom: ${rem(spaces.delta)};
    font-style: normal;
    color: ${colors.grey700};
  `,
    ];

    if (bold) {
        styles.push(`
        font-weight: 700;
      `);
    }
    return styles;
});
