import React from 'react';
import i18next from 'i18next';
import { array, bool, object, string } from 'prop-types';
import { getFormFieldByAnswerType } from '@src/components/HealthQuestions/util';
import {
    regex,
    required,
    composeValidators,
    requiredFieldArrayCheckbox,
    minDate,
    maxDate,
    maxLength,
} from '@src/util/validators';
import { AnswerItem } from '@src/components';
import { conditionalRender } from './util';

import { StyledAnswer, StyledLabel } from './Answer.style';

const Answer = ({
    healthQuestions,
    block,
    question,
    answer,
    disabled,
    allowedValues,
    isMultiGroup,
    modalRefs,
    prefix = '',
}) => {
    const { type } = getFormFieldByAnswerType(answer.type);

    const getValidations = () => {
        let validations = [];

        if ('required' in answer.validation) {
            if (type === 'radio-button' || type === 'answer-multi') {
                validations.push(requiredFieldArrayCheckbox(i18next.t('HealthQuestionsForm.validationRequired')));
            } else {
                validations.push(required(i18next.t('HealthQuestionsForm.validationRequired')));
            }
        }

        if ('maxLength' in answer.validation) {
            validations.push(
                maxLength(
                    i18next.t('HealthQuestionsForm.validationMaxLength', {
                        maxLength: answer.validation.maxLength,
                    }),
                    answer.validation.maxLength
                )
            );
        }

        if ('regex' in answer.validation) {
            validations.push(regex(i18next.t('HealthQuestionsForm.validationRegex'), answer.validation.regex));
        }
        return validations;
    };

    const getAllowedValuesValidations = () => {
        const validations = [];

        if (
            'minDate' in allowedValues &&
            allowedValues['minDate'] !== null &&
            typeof allowedValues['minDate'] !== 'undefined'
        ) {
            validations.push(minDate(i18next.t('HealthQuestionsForm.validationMinDate'), allowedValues[minDate]));
        }
        if (
            'maxDate' in allowedValues &&
            allowedValues['maxDate'] !== null &&
            typeof allowedValues['maxDate'] !== 'undefined'
        ) {
            validations.push(maxDate(i18next.t('HealthQuestionsForm.validationMaxDate'), allowedValues[minDate]));
        }

        return validations;
    };

    const render = () => {
        let validations = [];

        if ('validation' in answer) {
            validations = validations.concat(getValidations());
        }
        if ('allowedValues' in answer) {
            validations = validations.concat(getAllowedValuesValidations());
        }

        const validation = validations.length > 0 ? composeValidators(...validations) : null;

        return (
            <StyledAnswer
                answerType={answer.type}
                id={`ANSWER_${answer.id}`}
                className={`ANSWER TYPE-${answer.type}`}
                key={`answer_${answer.id}`}
            >
                {answer.label && !['TOGGLE', 'CHECKBOX'].includes(answer.type) ? (
                    <StyledLabel>{answer.label}</StyledLabel>
                ) : null}
                <AnswerItem
                    key={answer.id}
                    healthQuestions={healthQuestions}
                    block={block}
                    question={question}
                    answer={answer}
                    validate={validation}
                    disabled={disabled}
                    allowedValues={allowedValues}
                    isMultiGroup={isMultiGroup}
                    modalRefs={modalRefs}
                    prefix={prefix}
                />
            </StyledAnswer>
        );
    };

    return conditionalRender(answer, render);
};

Answer.propTypes = {
    healthQuestions: array.isRequired,
    block: object.isRequired,
    question: object.isRequired,
    answer: object.isRequired,
    disabled: bool,
    allowedValues: object,
    isMultiGroup: bool,
    modalRefs: object,
    prefix: string,
};

export default Answer;
