import moment from 'moment';
import toPairs from 'lodash/toPairs';
import IBAN from 'iban';
import { parsePhoneNumberWithError } from 'libphonenumber-js/max';
import { log } from '@src/middleware';
import { GENDER_FEMALE, GENDER_MALE, GENDER_UNBORN } from '@src/util/person';
import { BACKEND_DATE_FORMAT } from '@src/util/date';

const isNonEmptyString = val => {
    return typeof val === 'string' && val.trim().length > 0;
};

const parseNum = str => {
    const num = Number.parseInt(str, 10);
    return Number.isNaN(num) ? null : num;
};

/**
 * Final Form Validators
 */

// Final Form need undefined value for a successful validation
const VALID = undefined;

export const required = message => value => {
    if (typeof value === 'undefined' || value === null) {
        // undefined or null values are invalid
        return message;
    }
    if (typeof value === 'string') {
        // string must be nonempty when trimmed
        return isNonEmptyString(value) ? VALID : message;
    }
    return VALID;
};

export const requiredFieldArrayCheckbox = message => value => {
    if (!value) {
        return message;
    }

    const entries = toPairs(value);
    const hasSelectedValues = entries.filter(e => !!e[1]).length > 0;
    return hasSelectedValues ? VALID : message;
};

export const requiredAllFieldArrayCheckbox = (message, lengthTotal) => value => {
    if (!value) {
        return message;
    }

    const entries = toPairs(value);
    const allValuesSelected = entries.filter(e => !!e[1]).length === lengthTotal;

    return allValuesSelected ? VALID : message;
};

export const maxLength = (message, maximumLength) => value => {
    if (!value) {
        return VALID;
    }
    const hasLength = value && typeof value.length === 'number';
    return hasLength && value.length <= maximumLength ? VALID : message;
};

export const minLength = (message, minimumLength) => value => {
    if (!value) {
        return VALID;
    }
    const hasLength = value && typeof value.length === 'number';
    return hasLength && value.length >= minimumLength ? VALID : message;
};

export const nonEmptyArray = message => value => {
    return value && Array.isArray(value) && value.length > 0 ? VALID : message;
};

// Source: http://www.regular-expressions.info/email.html
const EMAIL_RE = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

export const emailFormatValid = message => value => {
    return value && EMAIL_RE.test(value) ? VALID : message;
};

export const isIban = message => value => {
    return IBAN.isValid(value.replace(/\s/g, '')) ? VALID : message;
};

export const isSeconAcceptedIban = message => value => {
    if (!value) {
        return VALID;
    }

    value = value.replace(/\s/g, '');

    // The IBAN should have a length of min 15 and max 34 symbols
    const hasLengthBetween15and34 = value.length >= 15 && value.length <= 34;
    // The first two letters of the IBAN should be the country code
    const beginsWithCountryCode = /^[A-Z]{2}/.test(value);
    // Checking the checksum to prevent typos
    const checksum = createIbanChecksum(value);

    // Additional check for swiss postal IBANs
    const passedAdditionalChecksForSwissPostalIBAN = additionalChecksForSwissPostalIBAN(value);

    const valid =
        hasLengthBetween15and34 && beginsWithCountryCode && checksum && passedAdditionalChecksForSwissPostalIBAN;

    return valid ? VALID : message;
};

const createIbanChecksum = value => {
    // The first 4 characters (LL + PZ) have to be shifted to the end of the IBAN
    let transformedValue = value.substr(4) + value.substr(0, 4);
    // Alphabetical chars are converted to numbers:
    // A=10, B=11, ..., Z=35
    transformedValue = transformedValue.replace(/[A-Z]/g, function (match) {
        return parseInt(match, 36);
    });

    // Calculates the MOD 97 10 of the passed IBAN as specified in ISO7064
    // The remainder should always be 1 for correct IBANs
    return (
        (iban => {
            const max = 100;
            let iterations = 0;
            let remainder = iban,
                block;
            while (remainder.length > 2) {
                block = remainder.slice(0, 9);
                remainder = (parseInt(block, 10) % 97) + remainder.slice(block.length);
                iterations++;
                // If max number of iterations has been reached, break the attempt and return false
                if (iterations === max) {
                    return -1;
                }
            }
            return parseInt(remainder, 10) % 97;
        })(transformedValue) === 1
    );
};

const additionalChecksForSwissPostalIBAN = value => {
    const allowedCountryCodes = ['CH', 'LI'];
    const postalClearingNumber = '9000';
    const countryCodeMatches = value.match(/^[A-Z]{2}/);
    const countryCode = countryCodeMatches ? countryCodeMatches[0] : '';
    const clearingNumber = value.substr(4, 4);
    let valid = true;

    // IBAN is from Switzerland or Liechtenstein
    const hasSwissCountryCode = allowedCountryCodes.includes(countryCode);
    // IBAN has a numerical clearing number
    const hasNumericClearingNumber = /^\d+$/.test(clearingNumber);
    // IBAN is exactly 21 chars long
    const isPostalIban = value.length === 21 && clearingNumber === postalClearingNumber;
    // The IBAN is numeric, except for the first two chars
    const isNumeric = /^\d+$/.test(value.substring(2, value.length));

    // The check is only for swiss IBANs
    if (hasSwissCountryCode) {
        // If the clearing number is not numeric the IBAN is invalid
        if (!hasNumericClearingNumber) {
            valid = false;
        }
        // If it's a postal IBAN, the IBAN has to be numeric, except for the first two letters
        if (isPostalIban && !isNumeric) {
            valid = false;
        }
    }

    return valid;
};

export const ageAtLeast = (message, minYears) => value => {
    try {
        const years = moment().diff(value, 'years');
        return years >= minYears ? VALID : message;
    } catch (e) {
        log.error(e);
    }
    return VALID;
};

export const regex = (message, expression) => value => {
    try {
        const reg = new RegExp(expression.replace(/^\//, '').replace(/\/$/, ''));
        return value && reg.test(value) ? VALID : message;
    } catch (e) {
        log.error('Invalid regexp, value not validated against expression. return VALID', e);
        return VALID;
    }
};

export const minDate = (message, minDateToCheck) => value => {
    try {
        if (minDateToCheck && value) {
            return moment(minDateToCheck).isSameOrBefore(value) ? VALID : message;
        }
    } catch (e) {
        log.error(e.message);
    }
    return VALID;
};

export const maxDate = (message, maxDateToCheck) => value => {
    try {
        if (maxDateToCheck && value) {
            return moment(maxDateToCheck).isSameOrAfter(value) ? VALID : message;
        }
    } catch (e) {
        log.error(e.message);
    }
    return VALID;
};

export const onlyLetters = message => value => {
    try {
        const reg = new RegExp(/\d/, 'gu');

        return typeof value === 'undefined' || value === null || value === '' || (value && !reg.test(value))
            ? VALID
            : message;
    } catch (e) {
        log.error('Invalid regexp, value not validated against expression. return VALID', e);
        return VALID;
    }
};

export const validDate = message => value => {
    // check if it's a valid date and doesn't contain "_"
    return typeof value === 'undefined' ||
        value === null ||
        value === '' ||
        (value && moment(value, BACKEND_DATE_FORMAT, true).isValid())
        ? VALID
        : message;
};

export const validGender = message => value => {
    return value && (value === GENDER_MALE || value === GENDER_FEMALE || value === GENDER_UNBORN) ? VALID : message;
};

/**
 * Phone number validation with the following rules:
 *
 * - optional '+' at the beginning
 * - only digits
 * - min. 10 and max. 15 chars
 *
 * @param {*} message
 * @returns
 */
export const validPhoneNumber = message => value => {
    const defaultCountryCode = 'CH';

    // Which phone types are allowed for the phone validation
    const allowedPhoneTypes = [
        'MOBILE',
        'FIXED_LINE_OR_MOBILE',
        'FIXED_LINE',
        'UAN', // the allowed 51 national prefix for the sbb / railways is from the type UAN.
    ];

    if (value) {
        try {
            const phoneNumber = parsePhoneNumberWithError(value, defaultCountryCode);
            const isValidNumber = phoneNumber.isValid(); // validates phone number length and the actual phone number digits
            const isValidType = allowedPhoneTypes.includes(phoneNumber.getType());

            return isValidNumber && isValidType ? VALID : message;
        } catch (e) {
            if (e.message !== 'TOO_SHORT') {
                log.error(e);
            }
            return message;
        }
    }

    return VALID;
};

export const isTrue = message => value => {
    return value === true ? VALID : message;
};

export const composeValidators =
    (...validators) =>
    value =>
        validators.reduce((error, validator) => error || validator(value), VALID);
