import React, { useEffect, useRef, useState } from 'react';
import { array, bool, func, object, string, shape } from 'prop-types';
import i18next from 'i18next';
import classNames from 'classnames';
import log from 'loglevel';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import setFieldData from 'final-form-set-field-data';
import arrayMutators from 'final-form-arrays';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import moment from 'moment/moment';

import config from '@src/config';

// components
import { AutoSave, Form } from '@src/components';
import { Button } from '@atupri/atupri-component-library';

// util
import {
    getFormSubmitTrackingData,
    getFormErrorTrackingData,
    getProductSelectionTrackingData,
    getQuickCalculatorStartTrackingData,
} from '@src/util/analytics';
import { clearAutosaved } from '@src/util/autosave';
import {
    createBasketEntryByTariffId as createBasketEntryByOkpTariffId,
    getOkpProductsTrackingData,
    findTariff as findOkpTariff,
    findProduct as findOkpProduct,
} from '@src/util/tariff/okp';
import {
    PRODUCT_GROUP_IDENTIFIER_MIVITA,
    createBasketEntryByTariffId as createBasketEntryByVvgTariffId,
    getVvgProductsTrackingData,
    findTariff as findVvgTariff,
    findProduct as findVvgProduct,
} from '@src/util/tariff/vvg';
import { FormSpyFieldValues } from '@src/util/finalForm/FormSpyFieldValues';
import { convertDateToPresentationFormat, isValidDate } from '@src/util/date';
import { stripZip } from '@src/util/zip';

// partials
import { QuickCalculatorOkpForm, QuickCalculatorVvgForm, OkpParamsForm, BaseForm, Summary } from './partials';

// style
import { StyledFormControls } from './QuickCalculatorForm.style';

let submitTimeout = null;
const submitTimeoutDelay = 200;
let errorTrackingTimeout = null;
const errorTrackingDelay = 200;

const QuickCalculatorFormRender = props => {
    const {
        autosave,
        className,
        disabled,
        form,
        invalid,
        onDataProvided,
        onSubmit,
        products,
        pristine,
        ready,
        updated,
        updateInProgress,
        newWindowReference,
        shouldRenderProducts,
        childrenToRender,
        onResults,
        initialValues,
        inverted,
        onFirstLoad,
    } = props;

    const initialBaseValues = {
        birthdate: initialValues.birthdate,
        gender: initialValues.gender,
        zip: initialValues.zip,
    };
    const initialOkpValues = {
        category: initialValues.category,
        variant: initialValues.variant,
    };

    const isBaseFormComplete = values => {
        return !!(
            values?.gender &&
            isValidBirthdate(values?.birthdate) &&
            values?.zip?.zipText &&
            stripZip(values?.zip?.zipText).length === 4
        );
    };

    const isValidBirthdate = value => {
        return (
            value &&
            isValidDate(convertDateToPresentationFormat(value)) &&
            moment('1900-01-01').isSameOrBefore(value) &&
            moment().isSameOrAfter(value)
        );
    };

    const [apiData, setApiData] = useState();
    const [tariffs, setTariffs] = useState();
    const [baseValues, setBaseValues] = useState(initialBaseValues);
    const [okpParams, setOkpParams] = useState(initialOkpValues);
    const [selectedTariff, setSelectedTariff] = useState();
    const [isFormComplete, setIsFormComplete] = useState(isBaseFormComplete(initialBaseValues));
    const isFirstInteractionTracked = useRef(false);
    const shouldTrackFormSubmit = useRef(false);
    const classes = classNames('quickCalculatorForm', className);
    const submitReady = (updated && pristine) || ready;
    const submitDisabled = disabled || updateInProgress;
    const touchedErrorFields = Object.keys(form.getState().errors).filter(
        field => form.getFieldState(field === 'zip' ? 'zip.zipText' : field).touched
    );

    const sendDataToGTM = useGTMDispatch();

    const loadApiData = async (values, process = true) => {
        const valuesToLoad = { ...values, category: values.category ? String(values.category) : '' };
        return onDataProvided(valuesToLoad).then(data => {
            setApiData(data);

            if (process) {
                processTariffs(data, valuesToLoad);
            }

            if (shouldTrackFormSubmit.current) {
                trackFormSubmit(data?.okp?.categories?.find(category => category.recommended === true)?.value);
                shouldTrackFormSubmit.current = false;
            }
            return data;
        });
    };

    const selectionCallback = (event, values) => {
        const params = { ...baseValues, ...okpParams, ...values };
        onFormSubmit(event, params);
    };

    const processTariffs = (data, values) => {
        const sortedTariffs = {
            okp: findAndSortTariffs(data?.okp, products?.okp, 'okp', values),
            vvg: findAndSortTariffs(data, products?.vvg, 'vvg', values),
        };

        setTariffs(sortedTariffs);
        setTimeout(() => onResults?.(sortedTariffs, selectionCallback));

        return sortedTariffs;
    };

    const findAndSortTariffs = (apiData, products, type, values) => {
        if (typeof products === 'undefined' || products?.length === 0 || !apiData) {
            return [];
        }

        if (type === 'okp') {
            const selectedCategory = values.category
                ? apiData?.categories?.find(category => category.value === values.category)
                : apiData?.categories?.find(category => category.recommended === true);
            const selectedCategoryValue = selectedCategory?.value;
            const selectedVariantId =
                values.variant || apiData?.variants?.find(variant => variant.recommended === true)?.id;
            const selectedOption = values.option ?? apiData?.options?.find(option => option.recommended === true)?.id;

            // make sure form values are set correctly
            form.mutators.setValue('variant', selectedVariantId);
            form.mutators.setValue('category', selectedCategoryValue);
            if (
                (values.variant === '' && values.variant !== selectedVariantId) ||
                (values.category === '' && values.category !== selectedCategoryValue)
            ) {
                setOkpParams({ category: selectedCategoryValue, variant: selectedVariantId });
            }

            // call autosave manually as form mutators do not trigger it
            autosave({ ...values, category: selectedCategoryValue, variant: selectedVariantId });

            return (
                apiData &&
                products
                    .map(product => {
                        const tariff = findOkpTariff(apiData, {
                            product: product.toUpperCase(),
                            category: selectedCategory?.id,
                            option: selectedOption,
                            variant: selectedVariantId,
                            practices: [], // Practices is empty, as we don't have a selected DAR in the quick calculator
                        });

                        return tariff?.type
                            ? { ...tariff, price: tariff.bafu ? tariff.price + tariff.bafu : tariff.price }
                            : undefined;
                    })
                    .filter(Boolean) // remove undefined values
                    .sort((a, b) => {
                        return (
                            apiData?.products &&
                            a &&
                            b &&
                            findOkpProduct(apiData.products, a.parent).sorting -
                                findOkpProduct(apiData.products, b.parent).sorting
                        );
                    })
            );
        }
        if (type === 'vvg') {
            return (
                apiData?.vvg &&
                products
                    ?.map(product => {
                        const tariff = findVvgTariff(product.toUpperCase(), apiData.vvg, null);
                        if (tariff) {
                            if (product.startsWith('uti')) {
                                const utiInfo = apiData.uti?.find(uti => uti.tariff === tariff.id);
                                if (utiInfo) {
                                    return {
                                        ...tariff,
                                        deathCoverage: utiInfo.deathCoverage,
                                        disabilityCoverage: utiInfo.disabilityCoverage,
                                    };
                                } else {
                                    return undefined;
                                }
                            } else {
                                return tariff;
                            }
                        }
                    })
                    .filter(Boolean) // remove undefined values
                    .sort((a, b) => {
                        return (
                            apiData?.products &&
                            a &&
                            b &&
                            findVvgProduct(apiData.products, a.parent).sorting -
                                findVvgProduct(apiData.products, b.parent).sorting
                        );
                    })
            );
        }
    };

    const trackOkpProductSelection = (okpTariffId, data) => {
        if (okpTariffId) {
            const tariff = tariffs?.okp?.find(t => t?.id === okpTariffId);
            const fullProduct = findOkpProduct(data?.products, tariff?.parent);
            if (fullProduct) {
                const trackingTitle = getOkpProductsTrackingData(
                    apiData?.okp,
                    { category: tariff?.category, variant: tariff?.variant },
                    [fullProduct.id]
                )?.[0]?.title;
                if (trackingTitle) {
                    const dataLayerEventData = getProductSelectionTrackingData(
                        'grundversicherung',
                        trackingTitle,
                        tariff?.price
                    );

                    // submit dataLayer to gtm
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push(dataLayerEventData);
                }
            }
        }
    };

    const trackFormSubmit = franchise => {
        setTimeout(() => {
            sendDataToGTM(getFormSubmitTrackingData(franchise));
        });
    };

    const trackCalculatorStart = () => {
        isFirstInteractionTracked.current = true;

        setTimeout(() => {
            sendDataToGTM(getQuickCalculatorStartTrackingData());
        });
    };

    const trackFormError = field => {
        setTimeout(() => {
            sendDataToGTM(getFormErrorTrackingData('schnellrechner', field));
        });
    };

    const onFormValuesChanged = (values, form) => {
        if (
            values.birthdate !== baseValues.birthdate ||
            values.gender !== baseValues.gender ||
            values.zip?.zipText !== baseValues.zip?.zipText
        ) {
            if (isFirstInteractionTracked.current === false) {
                trackCalculatorStart();
            }

            setBaseValues(values);
            setApiData(undefined);
            setOkpParams({
                category: '',
                variant: '',
            });
            form.mutators.setValue('variant', '');
            form.mutators.setValue('category', '');
        } else {
            // no relevant values changed. Nothing to do
        }
    };

    const onShowTariffs = values => {
        clearTimeout(submitTimeout);
        submitTimeout = setTimeout(() => {
            const valid = form.getState()?.valid;
            if (valid && isBaseFormComplete(values)) {
                loadApiData(values)
                    .then(tariffs => {
                        preselectTariff(form, tariffs);
                    })
                    .catch(error => {
                        log.error(error);
                    });
            }
        }, submitTimeoutDelay);
    };

    const onTariffChange = values => {
        const isOnlyTariffChange = !!(
            (values?.okpTariffId && values.okpTariffId !== selectedTariff?.okp) ||
            (values?.vvgTariffId && values.vvgTariffId !== selectedTariff?.vvg)
        );

        if (apiData && isOnlyTariffChange) {
            setSelectedTariff({
                okp: values.okpTariffId,
                vvg: values.vvgTariffId,
            });
        }
    };

    const onOkpParamsChange = ({ category = '', variant = '' }) => {
        const categoryStr = String(category);

        if (
            apiData &&
            ((categoryStr !== '' && String(okpParams.category) !== categoryStr) ||
                (variant !== '' && okpParams.variant !== variant))
        ) {
            setOkpParams({ category: categoryStr, variant: variant });
            processTariffs(apiData, { ...baseValues, category: categoryStr, variant: variant });
        }
    };
    const onFormSubmit = (ev, values) => {
        ev.preventDefault();
        newWindowReference.current = window.open('', '_blank');

        const formValues = values || form?.getState()?.values;
        if (apiData) {
            onProductSelection(formValues, apiData);
        } else if (isBaseFormComplete(formValues)) {
            loadApiData(formValues, false).then(data => {
                onProductSelection(formValues, data);
            });
        } else {
            onProductPreSelection(formValues);
        }
    };

    const preselectTariff = (form, tariffs) => {
        if (!selectedTariff?.okp && tariffs?.okp?.[0]) {
            form.mutators.setValue('okpTariffId', tariffs.okp[0].id);
        }
        if (!selectedTariff?.vvg && tariffs?.vvg?.[0]) {
            form.mutators.setValue('vvgTariffId', tariffs.vvg[0].id);
        }
    };

    const onProductSelection = (values, data) => {
        if (!data) {
            log.error('Apidata not loaded or not in state');
            return;
        }
        const okpTariff = values?.okpTariffId ? data?.okp?.tariffs?.find(t => t.id === values.okpTariffId) : undefined;
        if (okpTariff) {
            values['okpTariff'] = createBasketEntryByOkpTariffId(
                values.okpTariffId,
                okpTariff?.option,
                okpTariff?.category,
                okpTariff?.variant,
                data?.okp
            );
            form.mutators.setValue('okpTariff', values['okpTariff']);
        }

        if (values?.vvgTariffId) {
            const vvgTariffIds = Array.isArray(values?.vvgTariffId) ? values.vvgTariffId : [values.vvgTariffId];
            let vvgTariffs = [];
            let section = 'withoutMivita';

            // find tariffs for given tariff ids
            vvgTariffIds.forEach(vvgTariffId => {
                const vvgTariff = vvgTariffId ? data?.vvg?.tariffs?.find(t => t.id === vvgTariffId) : undefined;

                if (vvgTariff) {
                    vvgTariffs.push(vvgTariffId);

                    // set section to withMivita if one of the selected tariffs is a Mivita tariff
                    if (
                        data?.vvg?.products?.find(p => p.id === vvgTariff.parent).productGroup ===
                        PRODUCT_GROUP_IDENTIFIER_MIVITA
                    ) {
                        section = 'withMivita';
                    }
                } else {
                    log.error('Selected tariff not found', vvgTariff);
                }
            });

            if (vvgTariffs.length !== 0) {
                // add tariffs to basket and track the selection
                let vvgTariffBasket = [];
                vvgTariffs.forEach(vvgTariffId => {
                    vvgTariffBasket.push(createBasketEntryByVvgTariffId(vvgTariffId, data?.vvg, section));
                });
                values['vvgTariff'] = vvgTariffBasket;
                form.mutators.setValue('vvgTariff', values['vvgTariff']);
            }
        }

        onSubmit(values);
    };

    const onProductPreSelection = values => {
        if (values?.okpProductId) {
            form.mutators.setValue('okpProductId', values.okpProductId);
        }

        if (values?.vvgProductIds) {
            form.mutators.setValue('vvgProductIds', values.vvgProductIds);
        }

        onSubmit(values);
    };

    const onResetButtonClick = form => {
        setIsFormComplete(false);
        setOkpParams({});
        setBaseValues(initialBaseValues);
        form.restart({
            birthdate: '',
            gender: '',
            zip: {},
            category: '',
            variant: '',
        });
        clearAutosaved('quickCalculatorForm');
    };

    const renderProducts = products => {
        return (
            <>
                {products?.okp?.length > 0 && (
                    <QuickCalculatorOkpForm
                        apiData={apiData?.okp}
                        products={products.okp}
                        tariffs={tariffs?.okp}
                        initialValues={initialBaseValues}
                    />
                )}
                {products?.vvg?.length > 0 && (
                    <QuickCalculatorVvgForm
                        apiData={apiData?.vvg}
                        products={products.vvg}
                        tariffs={tariffs?.vvg}
                        initialValues={initialBaseValues}
                    />
                )}
            </>
        );
    };

    /**
     * Tracking form errors
     * The delay is necessary to prevent tracking errors on every keystroke
     */
    useEffect(() => {
        if (invalid) {
            clearTimeout(errorTrackingTimeout);
            errorTrackingTimeout = setTimeout(() => {
                touchedErrorFields.forEach(field => {
                    trackFormError(field);
                });
            }, errorTrackingDelay);
        }
    }, [invalid, JSON.stringify(touchedErrorFields)]);

    /**
     * Autosave cleanup
     * Ensure that autosaved okpTariffId and vvgTariffId are removed if no products of the respective type are passed anymore
     */
    useEffect(() => {
        if (products?.okp?.length === 0) {
            form.mutators.remove('okpTariffId');
        }
        if (products?.vvg?.length === 0) {
            form.mutators.remove('vvgTariffId');
        }
    }, [products?.okp, products?.vvg]);

    useEffect(() => {
        form.mutators.remove('okpTariff');
        form.mutators.remove('vvgTariff');
    }, []);

    useEffect(() => {
        if (isFormComplete) {
            onShowTariffs(initialValues);
        }
    }, []);

    useEffect(() => {
        onFirstLoad?.(selectionCallback);
    }, []);

    const isFormCompleteAndHasTariffs =
        isFormComplete &&
        !(
            (products?.okp?.length > 0 && apiData?.okp?.tariffs?.length === 0) ||
            (products?.vvg?.length > 0 && apiData?.vvg?.tariffs?.length === 0)
        );
    return (
        <Form className={classes} onSubmit={onFormSubmit}>
            <AutoSave onSave={autosave} isFormValid={!invalid} />
            <div className="w-full flex flex-col gap-6 lg:gap-8">
                {isFormComplete ? (
                    <div className="flex flex-row justify-between">
                        <Summary inverted={inverted} />
                        <Button
                            id="editBaseDataBtn"
                            name="editBaseDataBtn"
                            disabled={submitDisabled}
                            onClick={event => {
                                event.preventDefault();
                                setIsFormComplete(false);
                            }}
                            iconBefore="pen_edit"
                            color={inverted ? 'inverted' : 'blue'}
                            variant={inverted ? 'link' : 'ghost'}
                            iconClasses={inverted ? 'text-icon-inverted' : 'text-icon-red'}
                        >
                            {i18next.t('QuickCalculatorForm.editBaseDataBtn', 'Bearbeiten')}
                        </Button>
                    </div>
                ) : (
                    <div className="flex flex-col gap-6 lg:gap-10">
                        <BaseForm initialValues={initialBaseValues} inverted={inverted} />
                        <Button
                            id="calculateTariffsBtn"
                            name="calculateTariffsBtn"
                            disabled={!isBaseFormComplete(baseValues)}
                            onClick={event => {
                                event.preventDefault();
                                setIsFormComplete(true);
                                shouldTrackFormSubmit.current = true;
                                onShowTariffs({ ...baseValues, ...okpParams });
                            }}
                        >
                            {i18next.t('QuickCalculatorForm.calculateTariffsBtn', 'Preise berechnen')}
                        </Button>
                    </div>
                )}

                {products?.okp?.length > 0 && apiData?.okp?.tariffs?.length > 0 && isFormCompleteAndHasTariffs && (
                    <OkpParamsForm apiData={apiData?.okp} inverted={inverted} />
                )}

                {isFormCompleteAndHasTariffs && shouldRenderProducts && renderProducts(products)}

                {isFormCompleteAndHasTariffs && shouldRenderProducts && apiData && (
                    <StyledFormControls>
                        <Button id="submitBtn" name="submit" disabled={submitDisabled}>
                            {i18next.t('QuickCalculatorForm.toOffer')}
                        </Button>
                        <Button id="resetBtn" type="reset" onClick={() => onResetButtonClick(form)}>
                            {i18next.t('QuickCalculatorForm.reset')}
                        </Button>
                    </StyledFormControls>
                )}

                {childrenToRender?.({ tariffs, selectionCallback })}
            </div>

            <FormSpyFieldValues fieldNames={['birthdate', 'gender', 'zip']}>
                {values => {
                    setTimeout(() => onFormValuesChanged(values, form));
                }}
            </FormSpyFieldValues>
            <FormSpyFieldValues fieldNames={['category', 'variant']}>
                {values => {
                    setTimeout(() => onOkpParamsChange(values));
                }}
            </FormSpyFieldValues>
            <FormSpyFieldValues fieldNames={['okpTariffId', 'vvgTariffId']}>
                {values => {
                    setTimeout(() => onTariffChange(values));
                }}
            </FormSpyFieldValues>

            {config.env !== 'prod' && (
                <FormSpy>
                    {props => {
                        const { values } = props;
                        return (
                            <div className="hidden">
                                <pre style={{ overflowX: 'scroll' }}>{JSON.stringify(values, 0, 2)}</pre>
                            </div>
                        );
                    }}
                </FormSpy>
            )}
        </Form>
    );
};
QuickCalculatorFormRender.propTypes = {
    autosave: func,
    className: string,
    disabled: bool,
    form: object,
    initialValues: object,
    invalid: bool,
    onDataProvided: func,
    onSubmit: func,
    products: shape({
        okp: array,
        vvg: array,
    }),
    pristine: bool,
    ready: bool,
    updated: bool,
    updateInProgress: bool,
    newWindowReference: object,
    shouldRenderProducts: bool,
    childrenToRender: func,
    onResults: func,
    inverted: bool,
};

const QuickCalculatorForm = props => {
    const { initialValues, ...rest } = props;

    return (
        <FinalForm
            {...rest}
            subscription={{ submitting: true }}
            mutators={{
                ...arrayMutators,
                setFieldData,
                setValue: ([field, value], state, { changeValue }) => {
                    changeValue(state, field, () => value);
                },
                setValueArray: ([field, value], state, { changeValue }) => {
                    changeValue(state, field, () => value);
                },
            }}
            initialValues={initialValues}
        >
            {formProps => <QuickCalculatorFormRender {...props} {...formProps} initialValues={props.initialValues} />}
        </FinalForm>
    );
};

QuickCalculatorForm.propTypes = {
    className: string,
    onSubmit: func,
    onDataProvided: func,
    autosave: func,
    currentSubmissionId: string,
    products: object,
    newWindowReference: object,
    shouldRenderProducts: bool,
    childrenToRender: func,
    onResults: func,
    initialValues: object,
    inverted: bool,
    onFirstLoad: func,
};

export default QuickCalculatorForm;
