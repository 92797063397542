import React, { useMemo, useState, useEffect, useRef, useCallback } from 'react';
import i18next from 'i18next';
import { array, bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { Form as FinalForm, Field } from 'react-final-form';
import setFieldData from 'final-form-set-field-data';
import arrayMutators from 'final-form-arrays';
import { OnChange } from 'react-final-form-listeners';
import { log } from '@src/middleware';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AnimatePresence } from 'framer-motion';
import styled from '@emotion/styled';

// config
import { staticData as getTariffStatic, utiAddOnInitialValues } from '@src/config/getTariff';

// components
import { Container, Spacer, Stack } from 'atupri-component-library/lib/helpers';
import { Button, H2, P1, P3, Toggle } from 'atupri-component-library/lib/primitives';
import { Modal, Tooltip } from 'atupri-component-library/lib/components';
import { Form, NamedLink, NamedRedirect, AbortModalContent, RebateTeaser } from '@src/components';

// partials
import Okp from './partials/OkpProductForm';
import Vvg from './partials/VvgProductForm';
import MyAtupriFlowVvgAdditionalProductForm from './partials/MyAtupriFlowVvgAdditionalProductForm';
import { getBackLinkButton, getNextLinkButton } from './partials/Controls';

// util
import { createOkpTariffDecorator, createVvgTariffDecorator } from '@src/util/finalForm/decorators/tariff';
import createScrollToFirstErrorDecorator from '@src/util/finalForm/decorators/scrollToFirstError';
import { isTrue } from '@src/util/validators';
import { validateBasket, getLowestTariffYearFromBasket } from '@src/util/basket';
import { isInternalProcess } from '@src/util/process';
import {
    getLowestTariffYear,
    findSelectedProducts as findSelectedOkpProducts,
    getOkpProductsTrackingData,
} from '@src/util/tariff/okp';
import {
    PRODUCT_GROUP_IDENTIFIER_MIVITA,
    getVvgProductsTrackingData,
    getVvgProductsForProductGroupsTrackingData,
    getVvgProductsForSectionTrackingData,
    findSelectedProducts as findSelectedVvgProducts,
    findTariff,
} from '@src/util/tariff/vvg';
import { getPersonProductTrackingData, getProductImpressionTrackingData } from '@src/util/analytics';
import {
    preselectedOkpProduct,
    preselectedOkpCategory,
    preselectedOkpVariant,
    preselectedOkpOption,
    preselectedOkpTariff,
    preselectedVvgSection,
    preselectedVvgProductGroups,
    preselectedVvgProducts,
    checkForAgeGroupChange,
} from './util';
import { sanitizeDOM } from '@src/util/sanitize';
import { findPersonById, isLastPerson, needsToAnswerHealthQuestions } from '@src/util/person';
import { getPersonIndex, clearSubmissionId } from '@src/util/submission';

// style
import {
    StyledCtaBar,
    StyledCtaBarCol,
    StyledIndent,
    StyledInfoBox,
    StyledInfoBoxIcon,
    StyledInfoIcon,
    StyledProductTypeHeader,
    StyledRebateTeaserWrapper,
    StyledToggleBox,
    StyledIntroQuestion,
} from './ProductForm.style';
import { getProductAttribute } from '@src/util/tariff';

const tariffOkpDecorator = createOkpTariffDecorator();
const tariffVvgDecorator = createVvgTariffDecorator();
const scrollToFirstErrorDecorator = createScrollToFirstErrorDecorator();

const StyledButton = styled(Button)`
    text-decoration: underline;
`;

const ProductFormComponent = ({
    onSubmit,
    submission,
    currentPerson,
    apiOkpTariffs,
    apiVvgTariffs,
    apiCurrentProducts,
    onProductTypeChange,
    onProductSelectionChange,
    backActionMsg,
    submitButtonText,
    updated,
    className,
}) => {
    const submissionId = submission.id.uuid;
    const { basket, persons } = submission.relationships;
    const personBasket = basket[currentPerson.id] || {};
    const { products: okpProducts = [] } = apiOkpTariffs;
    const { products: vvgProducts = [] } = apiVvgTariffs;

    const currentOkpProduct = apiCurrentProducts?.find(product => product.code === 'OKP');
    const currentProductsFromApi = useSelector(state => state.apiData?.currentProducts);

    const { okpEnabled, vvgEnabled, practices } = currentPerson;

    const vvgSections = getTariffStatic.vvg.sections;

    const [firstLoad, setFirstLoad] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [abortModalOpen, setAbortModalOpen] = useState(false);
    const [currentOkpProductModalOpen, setCurrentOkpProductModalOpen] = useState(false);
    const [redirectToTab, setRedirectToTab] = useState('');

    const trackedOkpProducts = useRef([]);
    const trackedVvgProducts = useRef([]);
    const sendDataToGTM = useGTMDispatch();
    const navigate = useNavigate();

    const openModal = () => {
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const toggleAbortModal = event => {
        event?.preventDefault();
        setAbortModalOpen(!abortModalOpen);
    };

    const toggleCurrentOkpModal = () => {
        setCurrentOkpProductModalOpen(!currentOkpProductModalOpen);
    };

    const openPracticeSearch = identifier => {
        const button = document.getElementById(`PracticeSearchButton_${identifier}`);
        if (button) {
            let mEvent = new MouseEvent('click', {
                bubbles: true,
                cancelable: true,
                view: window,
            });
            // Dispatch the event to the button
            button.dispatchEvent(mEvent);
        } else {
            log.error(`Element "#PracticeSearchButton_${identifier}" could not be found.`);
        }
    };

    // Check for okp product in basket...
    const selectedOkp =
        (Array.isArray(personBasket?.okp) && personBasket.okp[0]) ||
        (firstLoad && currentPerson.okpPreselectedProduct && { product: currentPerson.okpPreselectedProduct });

    // Check for vvg product in basket...
    const selectedVvg = Array.isArray(personBasket?.vvg) && personBasket.vvg;

    const currentPersonIndex = getPersonIndex(submission, currentPerson.id);

    // Defining initialValues...
    const initialValues = useMemo(() => {
        const isInternal = isInternalProcess(persons, currentProductsFromApi);
        const isAgeGroupChange = checkForAgeGroupChange(isInternal, currentPerson);
        let selectedVVGSection = selectedVvg?.[0]?.section;

        if (!selectedVVGSection && isInternal) {
            selectedVVGSection = utiAddOnInitialValues.vvg.section;
        }

        // check if one of the preselected products is a mivita product
        if (firstLoad && !selectedVVGSection && currentPerson.vvgPreselectedProducts) {
            // Split comma list of preselected products into array
            const vvgPreselectedProductsArray = currentPerson.vvgPreselectedProducts.split(',');
            let hasMivitaProduct = vvgPreselectedProductsArray.some(productId => {
                const product = apiVvgTariffs.products.find(product => product.id === productId);
                return product?.productGroup === PRODUCT_GROUP_IDENTIFIER_MIVITA;
            });

            selectedVVGSection = hasMivitaProduct ? 'withMivita' : 'withoutMivita';

            if (vvgPreselectedProductsArray) {
                vvgPreselectedProductsArray.forEach(productId => {
                    const vvgTariff = findTariff(productId, apiVvgTariffs, {
                        personId: currentPerson.id,
                        persons,
                        submission,
                    });

                    if (vvgTariff) {
                        const { id: tariffId, ...tariffData } = vvgTariff;
                        const productData = apiVvgTariffs.products.find(product => product.id === productId);

                        selectedVvg.push({
                            section: selectedVVGSection,
                            product: productId,
                            productGroup: productData?.productGroup,
                            tariff: tariffId,
                            type: 'VVG',
                            ...tariffData,
                        });
                    }
                });
            }
        }

        if (!selectedVVGSection && currentPerson.vvgSection) {
            selectedVVGSection = currentPerson.vvgSection;
        }

        let okp;
        if (isAgeGroupChange) {
            okp = {
                enabled: okpEnabled,
                product: currentPerson.okpProduct || getProductAttribute(currentOkpProduct.tariffId, 'identifier'),
                category: preselectedOkpCategory(selectedOkp, apiOkpTariffs),
                variant:
                    Object.keys(selectedOkp).length === 0
                        ? getProductAttribute(currentOkpProduct.tariffId, 'variant')
                        : preselectedOkpVariant(selectedOkp, apiOkpTariffs),
                option:
                    Object.keys(selectedOkp).length === 0
                        ? preselectedOkpOption(selectedOkp, apiOkpTariffs)
                        : getProductAttribute(currentOkpProduct.tariffId, 'option'),
                practices: practices,
            };
        } else {
            okp = {
                enabled: okpEnabled,
                product: currentPerson.okpProduct || preselectedOkpProduct(selectedOkp, apiOkpTariffs),
                category: preselectedOkpCategory(selectedOkp, apiOkpTariffs),
                variant: preselectedOkpVariant(selectedOkp, apiOkpTariffs),
                option: preselectedOkpOption(selectedOkp, apiOkpTariffs),
                practices: practices,
            };
        }

        return {
            okp: okp,
            vvg: {
                enabled: isInternal || vvgEnabled,
                section: preselectedVvgSection(
                    selectedVVGSection,
                    {
                        sections: vvgSections,
                        ...apiVvgTariffs,
                    },
                    !isInternal
                ),
                productGroups:
                    currentPerson.vvgProductGroups ||
                    preselectedVvgProductGroups(selectedVvg, { sections: vvgSections, ...apiVvgTariffs }, !isInternal),
                products:
                    currentPerson.vvgProducts ||
                    preselectedVvgProducts(
                        selectedVvg,
                        {
                            sections: vvgSections,
                            ...apiVvgTariffs,
                        },
                        !isInternal
                    ),
            },
            tariff: {
                okp: okpEnabled ? [preselectedOkpTariff(selectedOkp, apiOkpTariffs)] : [],
                vvg: vvgEnabled && (Array.isArray(selectedVvg) && selectedVvg.length) > 0 ? selectedVvg : [],
            },
            additionalData: {
                submission,
                persons,
                personId: currentPerson.id,
            },
            apiData: {
                okp: apiOkpTariffs,
                vvg: apiVvgTariffs,
                currentProducts: apiCurrentProducts,
            },
            validation: validateBasket(basket, isLastPerson(persons, currentPersonIndex) ? null : currentPerson.id),
        };
    }, [currentPerson, apiOkpTariffs, apiVvgTariffs]);

    const initiallySelectedOkpProduct = useRef(findSelectedOkpProducts(initialValues.okp)?.[0]);
    const initiallySelectedVvgProducts = useRef(findSelectedVvgProducts(initialValues.vvg));

    const onOkpItemExpand = (expandedItems, values) => {
        if (!firstLoad && !updated && expandedItems && expandedItems.length > 0) {
            let productsToTrack = getOkpProductsTrackingData(
                apiOkpTariffs,
                values.okp,
                expandedItems.filter(item => item !== initialValues.okp.product),
                initiallySelectedOkpProduct.current
            );

            productsToTrack = productsToTrack.filter(product => !trackedOkpProducts.current.includes(product.title));

            if (productsToTrack.length > 0) {
                trackProductImpressions(productsToTrack);
            }
        }
    };

    const filterTrackedProducts = (productList, trackedProducts) => {
        return productList.filter(item => !trackedProducts.current.includes(item.title));
    };

    const trackProductsInSection = (sections, context) => {
        return sections.flatMap(sectionId => {
            return filterTrackedProducts(
                getVvgProductsForSectionTrackingData(
                    context.apiVvgTariffs,
                    context.vvgProducts,
                    sectionId,
                    context.initiallySelectedVvgProducts.current,
                    context.currentPerson.id,
                    context.persons,
                    context.submission
                ),
                context.trackedVvgProducts
            );
        });
    };

    const trackProductsInGroups = (productGroups, context) => {
        return filterTrackedProducts(
            getVvgProductsForProductGroupsTrackingData(
                context.apiVvgTariffs,
                context.vvgProducts,
                productGroups,
                context.initiallySelectedVvgProducts.current,
                context.currentPerson.id,
                context.persons,
                context.submission
            ),
            context.trackedVvgProducts
        );
    };

    const trackIndividualProducts = (products, context) => {
        return filterTrackedProducts(
            getVvgProductsTrackingData(
                context.apiVvgTariffs,
                products,
                context.initiallySelectedVvgProducts.current,
                context.currentPerson.id,
                context.persons,
                context.submission
            ),
            context.trackedVvgProducts
        );
    };

    const onVvgProductImpressionChange = items => {
        if (!updated) {
            const { sections, productGroups, products } = items;
            let productsToTrack = [];

            const context = {
                apiVvgTariffs,
                vvgProducts,
                initiallySelectedVvgProducts,
                currentPerson,
                persons,
                submission,
                trackedVvgProducts,
            };

            if (sections?.length > 0) {
                productsToTrack = productsToTrack.concat(trackProductsInSection(sections, context));
            }
            if (productGroups?.length > 0) {
                productsToTrack = productsToTrack.concat(trackProductsInGroups(productGroups, context));
            }
            if (products?.length > 0) {
                productsToTrack = productsToTrack.concat(trackIndividualProducts(products, context));
            }

            if (productsToTrack.length > 0) {
                setTimeout(() => {
                    trackProductImpressions([], productsToTrack);
                }, 100);
            }
        }
    };

    const trackProductImpressions = (okp, vvg) => {
        if (okp) {
            trackedOkpProducts.current = trackedOkpProducts.current.concat(okp.map(p => p.title));
        }
        if (vvg) {
            trackedVvgProducts.current = trackedVvgProducts.current.concat(vvg.map(p => p.title));
        }

        const dataLayerEventData = getProductImpressionTrackingData(okp, vvg);

        // delay datalayer push to fire after virtual_pageview
        setTimeout(() => {
            sendDataToGTM(dataLayerEventData);
        }, 1000);
    };

    useEffect(() => {
        if (firstLoad && !updated) {
            trackProductImpressions(
                initialValues.okp.enabled &&
                    getOkpProductsTrackingData(
                        apiOkpTariffs,
                        initialValues.okp,
                        [initialValues.okp.product],
                        initialValues.okp.product
                    ),
                initialValues.vvg.enabled &&
                    getVvgProductsForProductGroupsTrackingData(
                        apiVvgTariffs,
                        vvgProducts,
                        [PRODUCT_GROUP_IDENTIFIER_MIVITA],
                        initiallySelectedVvgProducts.current,
                        currentPerson.id,
                        persons,
                        submission
                    )
            );
        }
    }, [initialValues]);

    const getErrorAndPerson = (values, persons) => {
        const personIdWithError = Object.keys(values.validation?.errors)[0];
        const errors = values.validation?.errors[personIdWithError];
        const error = Array.isArray(errors) && errors.length > 0 && errors[0];
        const person = findPersonById(personIdWithError, persons);
        return { error, person };
    };

    const renderMissingGatekeeperChoiceModal = (
        error,
        person,
        personIdWithError,
        closeModal,
        openPracticeSearch,
        values
    ) => {
        return (
            <Modal isOpen={showModal} onCloseRequest={closeModal} variant="center">
                {i18next.t(`ProductForm.validation.${error}.text`)}
                <Spacer space="hecto" />
                <Stack space="deci">
                    {currentPerson.id !== personIdWithError ? (
                        <Button
                            onClick={() => {
                                closeModal();
                                setTimeout(() => {
                                    navigate(`/dashboard/product/${personIdWithError}`);
                                }, 500);
                            }}
                        >
                            {i18next.t(`ProductForm.validation.${error}.button.changePerson`, {
                                firstname: person.firstname,
                                lastname:
                                    person.lastname ??
                                    (person.firstname
                                        ? ''
                                        : i18next.t(`EditSubmissionProductPanel.okptoggle.noName`, {
                                              number: parseInt(getPersonIndex(submission, personIdWithError)) + 1,
                                          })),
                            })}
                        </Button>
                    ) : (
                        <>
                            <Button outlined={true} onClick={closeModal}>
                                {i18next.t(`ProductForm.validation.${error}.button.secondary`)}
                            </Button>
                            <Button
                                onClick={() => {
                                    closeModal();
                                    openPracticeSearch(values.tariff.okp[0].product);
                                }}
                            >
                                {i18next.t(`ProductForm.validation.${error}.button.primary`)}
                            </Button>
                        </>
                    )}
                </Stack>
            </Modal>
        );
    };

    const renderMissingProductChoiceModal = (error, closeModal) => {
        return (
            <Modal isOpen={showModal} onCloseRequest={closeModal} variant="center">
                {i18next.t(`ProductForm.validation.${error}.text`)}
                <Spacer space="hecto" />
                <Stack space="deci">
                    <NamedLink name="PersonPage">
                        <Button outlined={true}>{i18next.t(`ProductForm.validation.${error}.button.secondary`)}</Button>
                    </NamedLink>
                    <Button onClick={closeModal}>{i18next.t(`ProductForm.validation.${error}.button.primary`)}</Button>
                </Stack>
            </Modal>
        );
    };

    const renderProductFormValidationField = values => {
        return (
            <Field id="productFormValidation" name={`validation.valid`} validate={isTrue('error')}>
                {() => {
                    let modal = null;
                    const { error, person } = getErrorAndPerson(values, persons);

                    if (error) {
                        switch (error) {
                            case 'missingGatekeeperChoice':
                                modal = renderMissingGatekeeperChoiceModal(
                                    error,
                                    person,
                                    Object.keys(values.validation?.errors)[0],
                                    closeModal,
                                    openPracticeSearch,
                                    values
                                );
                                break;
                            case 'missingProductChoice':
                                modal = renderMissingProductChoiceModal(error, closeModal);
                                break;
                            default:
                                log.error('Unknown error in ProductForm validation.', { error });
                                break;
                        }
                    }

                    return modal;
                }}
            </Field>
        );
    };

    const onTariffChange = (values, onProductSelectionChange, value, previous, isInternal) => {
        if (firstLoad || (value && previous && JSON.stringify(value) !== JSON.stringify(previous))) {
            const okpTariff = (Array.isArray(value.okp) && value.okp.length > 0 && value.okp[0]) || {};
            const previousOkpTariff = (Array.isArray(previous.okp) && previous.okp.length > 0 && previous.okp[0]) || {};
            setFirstLoad(false);
            onProductSelectionChange(submissionId, currentPerson.id, values);

            if (okpTariff?.product && okpTariff?.product !== previousOkpTariff?.product) {
                onProductTypeChange({
                    submissionId: submission.id.uuid,
                    personId: currentPerson.id,
                    okp: false,
                    vvg: true,
                    uti: !!isInternal,
                    blockInterface: true,
                });
            }
        }
    };

    const handleOnClick = useCallback(
        nextPerson => {
            return navigate(`/dashboard/product/${nextPerson.id}`);
        },
        [navigate]
    );

    const renderMainToggle = (values, name) => {
        return (
            <StyledToggleBox padding="deci" background="blue100">
                <Container>
                    <StyledIndent>
                        <Field name={`${name}.enabled`}>
                            {fieldProps => (
                                <H2>
                                    <Toggle
                                        controlType="checkbox"
                                        name={fieldProps.input.name}
                                        value={true}
                                        selectedValue={`${fieldProps.input.value}`}
                                        onChange={event => {
                                            fieldProps.input.onChange(event.target.checked);
                                            sendDataToGTM({
                                                event: 'ux_interaction',
                                                eventCategory: 'ux',
                                                eventAction: 'toggle',
                                                eventLabel: name === 'okp' ? 'grundversicherung' : 'zusatzversicherung',
                                            });
                                        }}
                                        isSwitch
                                    >
                                        <StyledProductTypeHeader isActive={values[name].enabled}>
                                            {i18next.t(`ProductForm.title.${name}`)}
                                        </StyledProductTypeHeader>
                                    </Toggle>
                                </H2>
                            )}
                        </Field>
                    </StyledIndent>
                </Container>
            </StyledToggleBox>
        );
    };

    const isInternal = isInternalProcess(persons, currentProductsFromApi);
    if (redirectToTab === 'confirmation') {
        return isInternal && needsToAnswerHealthQuestions(currentPerson.id, submission) ? (
            <NamedRedirect name="HealthQuestionPage" params={{ userId: currentPerson.id }} />
        ) : (
            <NamedRedirect name="EditSubmissionPage" params={{ tab: redirectToTab }} />
        );
    } else if (redirectToTab === 'person') {
        return <NamedRedirect name="PersonPage" />;
    }

    const hasVvg = apiCurrentProducts && !!apiCurrentProducts.find(product => product.code !== 'OKP');

    const isCurrentOkpProductSelected = values => {
        return currentOkpProduct?.tariffId === values?.tariff.okp[0].tariff;
    };

    const sendPersonProductTrackingData = () => {
        // get user / product tracking data
        const dataLayerEventData = getPersonProductTrackingData(
            'praemienRechnerProduktwahl',
            currentPerson,
            submission
        );

        // submit dataLayer to gtm
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(dataLayerEventData);
    };

    const validateBasketBeforeClick = (ev, form, values, onValid) => {
        form.mutators.setValue(
            'validation',
            validateBasket(basket, isLastPerson(persons, currentPersonIndex) ? null : currentPerson.id)
        );
        if (!values.validation.valid) {
            openModal();
        } else {
            sendPersonProductTrackingData();
            return onValid(ev);
        }
    };

    const clearSubmissionAndRedirect = () => {
        clearSubmissionId();
        setRedirectToTab('person');
    };
    const renderCtaBar = (form, formRenderProps, values) => {
        return isInternal ? (
            <React.Fragment>
                <Button buttonText={backActionMsg} outlined variant="primary" onClick={toggleAbortModal} />
                <Modal isOpen={abortModalOpen} onCloseRequest={toggleAbortModal} variant="center">
                    <AbortModalContent toggleModal={toggleAbortModal} />
                </Modal>
                <StyledCtaBarCol>
                    {isCurrentOkpProductSelected(values) ? (
                        <>
                            <Button type="button" buttonText={submitButtonText} onClick={toggleCurrentOkpModal} />
                            <Modal
                                isOpen={currentOkpProductModalOpen}
                                onCloseRequest={toggleCurrentOkpModal}
                                variant="center"
                            >
                                <P1>{i18next.t(`ProductForm.currentOkpProduct.text`)}</P1>
                                <StyledInfoBox margin={false}>
                                    <StyledInfoBoxIcon iconName="info_bubble" />
                                    <span>
                                        {i18next.t('ProductForm.currentOkpProduct.infoBox.partOne')}
                                        <StyledButton
                                            buttonText={i18next.t('ProductForm.currentOkpProduct.infoBox.partTwo')}
                                            isIncognito
                                            onClick={clearSubmissionAndRedirect}
                                        />
                                        {i18next.exists('ProductForm.currentOkpProduct.infoBox.partThree') &&
                                            i18next.t('ProductForm.currentOkpProduct.infoBox.partThree')}
                                    </span>
                                </StyledInfoBox>
                                <Spacer space="theta" />
                                <Button
                                    buttonText={i18next.t(`ProductForm.currentOkpProduct.button`)}
                                    onClick={toggleCurrentOkpModal}
                                />
                            </Modal>
                        </>
                    ) : (
                        <Button
                            buttonText={submitButtonText}
                            onClick={ev =>
                                validateBasketBeforeClick(ev, form, values, () => {
                                    if (!formRenderProps.errors?.vvg) {
                                        setRedirectToTab('confirmation');
                                    }
                                })
                            }
                        />
                    )}
                </StyledCtaBarCol>
            </React.Fragment>
        ) : (
            <React.Fragment>
                <StyledCtaBarCol>
                    {getBackLinkButton(persons, currentPerson, submission, backActionMsg)}
                </StyledCtaBarCol>
                <StyledCtaBarCol>
                    {getNextLinkButton(
                        persons,
                        currentPerson,
                        submission,
                        submitButtonText,
                        handleOnClick,
                        (ev, onValid) => {
                            validateBasketBeforeClick(ev, form, values, onValid);
                        }
                    )}
                </StyledCtaBarCol>
            </React.Fragment>
        );
    };

    const requestYear = moment(currentPerson.insuranceStart).year();
    const tariffYear = getLowestTariffYearFromBasket(personBasket) || getLowestTariffYear(apiOkpTariffs);

    return (
        <FinalForm
            onSubmit={onSubmit}
            submissionId={submissionId}
            mutators={{
                ...arrayMutators,
                setFieldData,
                setValue: ([field, value], state, { changeValue }) => {
                    changeValue(state, field, () => value);
                },
            }}
            decorators={[tariffOkpDecorator, tariffVvgDecorator, scrollToFirstErrorDecorator]}
            initialValues={initialValues}
            render={({ form, ...formRenderProps }) => {
                let { handleSubmit, values } = formRenderProps;

                const classes = classNames('productForm', className);
                return (
                    <Form id="productForm" className={classes} onSubmit={handleSubmit}>
                        <OnChange name="okp.enabled">
                            {(value, previous) => {
                                if (value !== previous) {
                                    onProductSelectionChange(submissionId, currentPerson.id, values);

                                    onProductTypeChange({
                                        submissionId: submission.id.uuid,
                                        personId: currentPerson.id,
                                        okp: false,
                                        vvg: true,
                                        blockInterface: true,
                                    });
                                }
                            }}
                        </OnChange>
                        <OnChange name="vvg.enabled">
                            {(value, previous) => {
                                if (value !== previous) {
                                    onProductSelectionChange(submissionId, currentPerson.id, values);
                                }
                            }}
                        </OnChange>
                        <OnChange name="tariff">
                            {(value, previous) =>
                                onTariffChange(values, onProductSelectionChange, value, previous, isInternal)
                            }
                        </OnChange>
                        <StyledIndent>
                            <StyledIntroQuestion>
                                <H2>
                                    {i18next.t('ProductForm.introQuestion', {
                                        name:
                                            currentPerson.firstname ??
                                            currentPerson.lastname ??
                                            i18next.t(`EditSubmissionProductPanel.okptoggle.noName`, {
                                                number: parseInt(getPersonIndex(submission, currentPerson.id)) + 1,
                                            }),
                                    })}
                                </H2>
                            </StyledIntroQuestion>
                        </StyledIndent>

                        {!isInternal && renderMainToggle(values, 'okp')}
                        {!isInternal && renderMainToggle(values, 'vvg')}

                        <AnimatePresence>
                            {(values.okp.enabled || values.vvg.enabled) && (
                                <StyledRebateTeaserWrapper
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}
                                >
                                    <RebateTeaser>
                                        <P3>
                                            {i18next.t(`ProductForm.rebateTeaser.text`)}
                                            {i18next.exists(`ProductForm.rebateTeaser.tooltip`) && (
                                                <Tooltip
                                                    tooltip={i18next.t(`ProductForm.rebateTeaser.tooltip`)}
                                                    placement="top"
                                                >
                                                    <StyledInfoIcon iconName="info_bubble" title="Info" />
                                                </Tooltip>
                                            )}
                                        </P3>
                                    </RebateTeaser>
                                </StyledRebateTeaserWrapper>
                            )}
                        </AnimatePresence>

                        <Stack space="lambda">
                            {values.okp.enabled && okpProducts.length > 0 && (
                                <Okp
                                    form={form}
                                    values={values.okp}
                                    apiData={values.apiData.okp}
                                    onOkpItemExpand={expandedItems => onOkpItemExpand(expandedItems, values)}
                                    submission={submission}
                                    persons={persons}
                                    currentPerson={currentPerson}
                                />
                            )}

                            {values.vvg.enabled && vvgProducts.length > 0 && !isInternal && (
                                <Vvg
                                    form={form}
                                    values={values.vvg}
                                    apiData={values.apiData.vvg}
                                    onProductImpressionChange={onVvgProductImpressionChange}
                                    submission={submission}
                                    persons={persons}
                                    currentPerson={currentPerson}
                                />
                            )}

                            {isInternal && hasVvg && (
                                <StyledIndent>
                                    <RebateTeaser iconName="umbrella_plus">
                                        <P1>
                                            <span
                                                dangerouslySetInnerHTML={{
                                                    __html: sanitizeDOM(i18next.t('ProductForm.vvgInfoBox')),
                                                }}
                                            />
                                        </P1>
                                    </RebateTeaser>
                                </StyledIndent>
                            )}
                            {isInternal && (
                                <MyAtupriFlowVvgAdditionalProductForm
                                    values={values}
                                    currentPerson={currentPerson}
                                    submission={submission}
                                    form={form}
                                    onProductImpressionChange={onVvgProductImpressionChange}
                                    isInternal={isInternal}
                                />
                            )}
                        </Stack>
                        {requestYear > tariffYear && (
                            <StyledInfoBox>
                                <StyledInfoBoxIcon iconName="info_bubble" />
                                <>
                                    {i18next.t('ProductForm.tarifInfoBoxWithRelease', {
                                        currentYear: tariffYear,
                                        requestYear: requestYear,
                                        tarifReleaseYear: requestYear - 1,
                                    })}
                                </>
                            </StyledInfoBox>
                        )}
                        <Field name="additionalData.submission" value={submission}>
                            {() => {
                                return null;
                            }}
                        </Field>
                        <Field name="additionalData.persons" value={persons}>
                            {() => {
                                return null;
                            }}
                        </Field>
                        <Field name="additionalData.personId" value={currentPerson.id}>
                            {() => {
                                return null;
                            }}
                        </Field>
                        <Field name="apiData.okp" value={apiOkpTariffs}>
                            {() => {
                                return null;
                            }}
                        </Field>
                        <Field name="apiData.vvg" value={apiVvgTariffs}>
                            {() => {
                                return null;
                            }}
                        </Field>
                        {renderProductFormValidationField(values)}
                        <StyledCtaBar>{renderCtaBar(form, formRenderProps, values)}</StyledCtaBar>
                    </Form>
                );
            }}
        />
    );
};

ProductFormComponent.propTypes = {
    onSubmit: func.isRequired,
    submission: object.isRequired,
    currentPerson: object.isRequired,
    apiOkpTariffs: object,
    apiVvgTariffs: object,
    apiCurrentProducts: array,
    onProductTypeChange: func,
    onProductSelectionChange: func,
    backActionMsg: string.isRequired,
    submitButtonText: string.isRequired,
    updated: bool.isRequired,
    className: string,
};

export default ProductFormComponent;
