import React, { useState } from 'react';
import { bool, func, shape, string } from 'prop-types';
import { useSelector } from 'react-redux';
import { Field, Form as FinalForm } from 'react-final-form';
import i18next from 'i18next';
import classNames from 'classnames';
import arrayMutators from 'final-form-arrays';

// components
import { Form, CockpitItem, EditSubmissionCTA, NamedRedirect } from '@src/components';
import { Spacer } from 'atupri-component-library/lib/helpers';
import { Button } from 'atupri-component-library/lib/primitives';
import { Modal } from 'atupri-component-library/lib/components';

// utils
import { isAutosavedFormInvalid, getAutosavedValues } from '@src/util/autosave';
import { propTypes } from '@src/util/types';
import { required, composeValidators } from '@src/util/validators';
import { isInternalProcess } from '@src/util/process';

const EditSubmissionCockpitForm = props => {
    const [showModal, setShowModal] = useState(false);
    const currentProductsFromApi = useSelector(state => state.apiData?.currentProducts);
    const { persons } = props;
    const isInternal = isInternalProcess(persons, currentProductsFromApi);

    return isInternal ? (
        //  If it is an internal process, after the health questions we go directly to the confirmation page and
        //  leave out the cockpit and payment page
        <NamedRedirect name="EditSubmissionPage" params={{ tab: 'confirmation' }} />
    ) : (
        <FinalForm
            {...props}
            mutators={{ ...arrayMutators }}
            render={formRenderProps => {
                let {
                    className,
                    disabled,
                    ready,
                    handleSubmit,
                    pristine,
                    saveActionMsg,
                    updated,
                    updateInProgress,
                    fetchErrors,
                    persons,
                    personDatasetComplete,
                    basket,
                    submission,
                } = formRenderProps;

                const { updateSubmissionError, createSubmissionOfferError, showSubmissionError } = fetchErrors || {};
                const errorMessageUpdateSubmission = updateSubmissionError ? (
                    <p className={'error'}>{i18next.t('EditSubmissionCockpitForm.updateFailed')}</p>
                ) : null;

                // This error happens only on first tab (of EditSubmissionWizard)
                const errorMessageCreateSubmissionOffer = createSubmissionOfferError ? (
                    <p className={'error'}>{i18next.t('EditSubmissionCockpitForm.createSubmissionOfferError')}</p>
                ) : null;

                const errorMessageShowSubmission = showSubmissionError ? (
                    <p className={'error'}>{i18next.t('EditSubmissionCockpitForm.showSubmissionFailed')}</p>
                ) : null;

                // check for unsaved invalid autosave state which is only saved locally in browser and not yet in server session

                const classes = classNames('rootCockpitForm', className);
                const submitReady = (updated && pristine) || ready;
                const submitInProgress = updateInProgress;
                const autosaveIsFormInvalid = isAutosavedFormInvalid(submission.id.uuid, 'personInformationForm');
                const submitDisabled = autosaveIsFormInvalid || disabled || submitInProgress;

                const openModal = () => {
                    setShowModal(true);
                };

                const closeModal = () => {
                    setShowModal(false);
                };

                return (
                    <Form className={classes} onSubmit={handleSubmit}>
                        {errorMessageCreateSubmissionOffer}
                        {errorMessageUpdateSubmission}
                        {errorMessageShowSubmission}

                        {persons.map(person => {
                            const autosavedPersonValues = getAutosavedValues(
                                submission.id.uuid,
                                'personInformationForm',
                                person.id
                            );
                            const personInfo = {
                                ...person,
                                ...autosavedPersonValues?.person,
                            };

                            const personBasket = {
                                okp: basket[person.id]?.okp || [],
                                vvg: basket[person.id]?.vvg || [],
                            };
                            const hasProducts = personBasket.okp.length > 0 || personBasket.vvg.length > 0;
                            return (
                                hasProducts && (
                                    <div key={person.id}>
                                        <CockpitItem
                                            submission={submission}
                                            person={personInfo}
                                            basket={basket[person.id]}
                                            personDatasetComplete={personDatasetComplete[person.id]}
                                        />
                                    </div>
                                )
                            );
                        })}

                        <Field
                            id="cockpitFormComplete"
                            name={`complete`}
                            validate={composeValidators(
                                required(i18next.t('EditSubmissionCockpitForm.cockpitFormCompleteError'))
                            )}
                        >
                            {props => {
                                return (
                                    props.meta.touched &&
                                    props.meta.error && (
                                        <Modal isOpen={showModal} onCloseRequest={closeModal} variant="center">
                                            {props.meta.error}
                                            <Spacer space="hecto" />
                                            <Button onClick={closeModal}>
                                                {i18next.t('EditSubmissionCockpitForm.cockpitFormCompleteErrorButton')}
                                            </Button>
                                        </Modal>
                                    )
                                );
                            }}
                        </Field>

                        <Spacer space="peta" />

                        <EditSubmissionCTA
                            submitInProgress={submitInProgress}
                            submitDisabled={submitDisabled}
                            submitReady={submitReady}
                            submitOnClick={openModal}
                            saveActionMsg={saveActionMsg}
                            backActionMsg={i18next.t('EditSubmissionCockpitForm.back')}
                            currentSubmissionId={submission.id.uuid}
                            tab="product"
                            backTracking={{
                                category: 'ux',
                                action: 'button',
                                label: 'weitere Produkte',
                            }}
                        />
                    </Form>
                );
            }}
        />
    );
};

EditSubmissionCockpitForm.propTypes = {
    className: string,
    onSubmit: func.isRequired,
    saveActionMsg: string.isRequired,
    disabled: bool.isRequired,
    ready: bool.isRequired,
    updated: bool.isRequired,
    updateError: propTypes.error,
    updateInProgress: bool.isRequired,
    fetchErrors: shape({
        createSubmissionOfferError: propTypes.error,
        showSubmissionError: propTypes.error,
        updateSubmissionError: propTypes.error,
    }),
};

export default EditSubmissionCockpitForm;
