import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import i18next from 'i18next';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';

// components
import { ConfirmationPanel, Main, Page } from '@src/components';
import { Footer, Header } from 'atupri-component-library/lib/components';

// data
import { footerData, headerData } from '@src/data';

// ducks
import {
    requestDownloadConfirmationPdf,
    requestMarkSubmissionConfirmed,
    requestClearSubmission,
} from '@src/ducks/submission.duck';

// util
import { getBasketProductTrackingData } from '@src/util/analytics';
import { isInternalProcess } from '@src/util/process';
import { getSubmissionId } from '@src/util/submission';
import { isMobileApp } from '@src/util/mobileAppDetection';

// style
import { StyledContainer } from './ConfirmationPage.style';

export const ConfirmationPage = () => {
    const dispatch = useDispatch();
    const sendDataToGTM = useGTMDispatch();

    const { submissionData, onlineSubmission, pdfData, submissionConfirmed, currentProducts } = useSelector(state => ({
        fetchInProgress: false,
        submissionData: state.EditSubmissionPage.publishedSubmissionProps?.submissionData,
        onlineSubmission: state.EditSubmissionPage.publishedSubmissionProps?.onlineSubmission,
        pdfData: state.EditSubmissionPage.publishedSubmissionProps?.pdfData,
        submissionConfirmed: state.EditSubmissionPage.submissionConfirmed,
        currentProducts: state.apiData?.currentProducts,
    }));

    useEffect(() => {
        const submissionId = getSubmissionId();

        if (submissionId) {
            dispatch(requestClearSubmission(submissionId));
        }
    }, []);

    const isInternal = isInternalProcess(submissionData.relationships.persons, currentProducts);

    const getVariant = () => {
        let variant = 1;
        if (onlineSubmission) {
            variant = 2;
        }
        return variant;
    };

    const variant = getVariant();
    const stepTitle = i18next.t('ConfirmationPage.stepTitle');
    const steps = [
        i18next.t('ConfirmationPage.step1.variant' + variant),
        i18next.t('ConfirmationPage.step2.variant' + variant),
        i18next.t('ConfirmationPage.step3.variant' + variant),
    ];

    useEffect(() => {
        if (submissionData && !submissionConfirmed) {
            const dataLayerEventData = getBasketProductTrackingData('praemienRechnerAbschluss', submissionData);
            // delay datalayer push to fire after virtual_pageview
            setTimeout(() => {
                sendDataToGTM(dataLayerEventData);
                dispatch(requestMarkSubmissionConfirmed());
            }, 1000);
        }
    }, [submissionData]);

    return (
        <Page>
            {!isMobileApp() && <Header {...headerData()} />}
            <Main complete={!!pdfData}>
                <StyledContainer>
                    {pdfData ? (
                        <ConfirmationPanel
                            variant={variant}
                            downloadPdf={() => dispatch(requestDownloadConfirmationPdf())}
                            steptitle={stepTitle}
                            steps={steps}
                            isInternal={isInternal}
                        />
                    ) : (
                        <div>
                            <p>...</p>
                        </div>
                    )}
                </StyledContainer>
            </Main>
            {!isMobileApp() && <Footer {...footerData()} />}
        </Page>
    );
};

ConfirmationPage.propTypes = {};

export default ConfirmationPage;
