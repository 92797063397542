import queryString from 'query-string';

const serialiseApiTypes = obj =>
    Object.keys(obj).reduce((result, key) => {
        result[key] = obj[key];
        return result;
    }, {});

export const stringify = params => {
    const serialised = serialiseApiTypes(params);
    const cleaned = Object.keys(serialised).reduce((result, key) => {
        const val = serialised[key];

        if (val !== null) {
            result[key] = val;
        }

        return result;
    }, {});
    return queryString.stringify(cleaned);
};
