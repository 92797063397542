import React, { useState, forwardRef } from 'react';
import { bool, func, node, object, string } from 'prop-types';
import { FieldArray } from 'react-final-form-arrays';
import { log } from '@src/middleware';
import { FormModal, InlineList, ValidationError } from '@src/components';

import { StyledFieldMultiple, StyledButton } from './FieldMultiple.style';

const useForceUpdate = () => {
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => ++value); // update the state to force render
};

const FieldMultiple = forwardRef(
    (
        {
            id,
            name,
            answer,
            children,
            className,
            disabled,
            buttonText = '',
            hideButtonIfMax = false,
            showTotalAmount = true,
            alternativeHeader = null,
            onChange = () => {
                // This is intentionally empty
            },
            validate,
            isMultiGroup = false,
            ...rest
        },
        ref
    ) => {
        const [modalIsOpen, setModalIsOpen] = useState(false);
        const [initialValues, setInitialValues] = useState({});
        const [editRow, setEditRow] = useState(-1);
        const forceUpdate = useForceUpdate();
        const max = answer?.max || 99;
        const buttonTextDefault = buttonText || answer?.labels?.default;
        const buttonTextMore = buttonText || answer?.labels?.more || answer?.labels?.default;

        const render = (fields, meta) => {
            disabled = disabled || fields.length >= max;

            const saveInline = values => {
                setModalIsOpen(false);

                if (editRow > -1) {
                    fields.update(editRow, values);
                } else {
                    fields.push(values);
                }
                setEditRow(-1);

                onChange([values]);
            };

            const onEditClick = index => {
                log.debug('editclick', index, fields.value);
                const row = fields.value[index] ? fields.value[index] : {};

                setInitialValues(row);
                setEditRow(index);
                setModalIsOpen(true);
            };

            const onDeleteClick = index => {
                fields.remove(index);

                forceUpdate();
                onChange(fields.value);
            };

            const openModal = () => {
                setModalIsOpen(true);
            };

            const onCancel = () => {
                setModalIsOpen(false);
                setInitialValues({});
                setEditRow(-1);
            };

            // allow refs to trigger edit and delete
            if (ref?.current) {
                const modalRefIndex = ref.current.findIndex(reference => reference.current.id == id);

                ref.current[modalRefIndex] = {
                    current: {
                        edit: index => {
                            onEditClick(index);
                        },
                        delete: index => {
                            onDeleteClick(index);
                        },
                        id: id,
                    },
                };
            }

            return (
                <StyledFieldMultiple>
                    <FormModal
                        formKey={id}
                        onCancel={onCancel}
                        onSave={saveInline}
                        modalIsOpen={modalIsOpen}
                        initialValues={initialValues}
                    >
                        {children}
                    </FormModal>

                    {!(typeof isMultiGroup !== 'undefined' && isMultiGroup) ? (
                        <InlineList
                            item={answer}
                            value={fields.value}
                            setModalIsOpen={setModalIsOpen}
                            showTotalAmount={showTotalAmount}
                            alternativeHeader={alternativeHeader}
                            onEditClick={onEditClick}
                            onDeleteClick={onDeleteClick}
                            isMultiGroup={isMultiGroup}
                        />
                    ) : (
                        ''
                    )}

                    {hideButtonIfMax && fields.length >= max ? null : (
                        <StyledButton
                            type="button"
                            isIncognito
                            outlined
                            buttonText={fields.length > 0 ? buttonTextMore : buttonTextDefault}
                            disabled={disabled}
                            iconBefore="plus_outlined"
                            onClick={openModal}
                        />
                    )}
                    <input
                        type="hidden"
                        name={name}
                        value={typeof fields.value !== 'undefined' ? fields.value : ''}
                        data-focusable="true"
                    />
                    <ValidationError fieldMeta={meta} />
                </StyledFieldMultiple>
            );
        };

        const fieldProps = {
            id,
            name,
            className: className,
            key: answer.id,
            validate,
            ...rest,
        };

        return (
            <div className={className}>
                <FieldArray {...fieldProps}>{({ fields, meta }) => render(fields, meta)}</FieldArray>
            </div>
        );
    }
);

FieldMultiple.propTypes = {
    id: string,
    name: string,
    answer: object,
    children: node,
    className: string,
    disabled: bool,
    buttonText: string,
    hideButtonIfMax: bool,
    showTotalAmount: bool,
    alternativeHeader: node,
    onChange: func,
    validate: func,
    isMultiGroup: bool,
};

FieldMultiple.displayName = 'FieldMultiple';
export default FieldMultiple;
