/**
 * Filters an array of elements by given identifier and matching value.
 * Returns a unique set of matched elements
 *
 * @param {array} elements
 * @param {string} identifier
 * @param {any} value
 *
 * @returns array|{*}|null filtered elements for multiple matches, single element for single match, null for no match.
 */
export const filterElementsByIdentifier = (elements, identifier, value) => {
    let output = elements;
    if (typeof identifier !== 'undefined') {
        output = [...new Set(elements.filter(element => element[identifier] === value))];
    }

    return output;
};

/**
 * Sorts array of elements by given property
 *
 * @param {array} elements
 * @param {string} sorting
 *
 * @returns array sorted elements
 */
export const sortElementsByProperty = (elements, sorting) => {
    let output = elements;
    if (typeof sorting !== 'undefined') {
        output = output.sort(element => element[sorting]);
    }
    return output;
};

/**
 * Determines the tree-level of a given element inside a given array of elements by a given parentIdentifier
 *
 * @param {array} elements | All elements
 * @param element | Element to determine the tree-level
 * @param {number} level | Shall not be passed. Is used as an internal storage for recursive calls.
 */
export const getTreeLevel = (elements, element, level = 0) => {
    level = level || 1;
    if (element?.parent) {
        return getTreeLevel(elements, filterElementsByIdentifier(elements, 'id', element.parent)[0], ++level);
    } else {
        return level;
    }
};

/**
 * Determines, whether a given element is empty
 *
 * @param element
 * @return boolean
 */
export const isEmpty = element => {
    return !(element && element.length > 0 && Object.keys(element[0]).length !== 0);
};

/**
 * Determines, whether two arrays are equal
 *
 * @param {array} array1
 * @param {array} array2
 */
export const array_equal = (array1, array2) => {
    if (!Array.isArray(array1) || !Array.isArray(array2)) return false;

    return array1.length === array2.length && array1.every((value, index) => value === array2[index]);
};
