import React, { useRef } from 'react';
import i18next from 'i18next';
import moment from 'moment';
import { array, bool, number, object, string } from 'prop-types';
import { FormSpy, Field, useForm } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { Tooltip } from 'atupri-component-library/lib/components';
import { Answer, MultipleGroup, ValidationError } from '@src/components';

import { required } from '@src/util/validators';
import { getTreeLevel } from '@src/util/array';
import { FormSpyFieldValues } from '@src/util/finalForm/FormSpyFieldValues';
import { conditionalRender } from './util';

import { StyledAnswerGroup, StyledFormMaskRow, StyledFormMaskCol } from './AnswerGroup.style';
import { StyledLabel, StyledIcon } from './Question.style';

import { BACKEND_DATE_FORMAT } from '@src/util/date';

const AnswerGroup = ({ index, healthQuestions, block, group, question, prefix = '' }) => {
    let answerIndex = 0;
    const treeLevel = getTreeLevel(healthQuestions, block);
    const hasAnswers = group?.answers && group.answers.length > 0 && Object.keys(group.answers[0]).length !== 0;
    const groupSize = hasAnswers ? group.answers.length : 0;
    const isRequired =
        typeof group.validation !== 'undefined' && typeof group.validation.required !== 'undefined'
            ? group.validation.required
            : false;

    // check if answerGroup has more than one multiple answer type
    const multipleGroupAnswers = hasAnswers && group.answers.filter(answer => answer.type === 'MULTIPLE');
    const groupAnswers = multipleGroupAnswers && multipleGroupAnswers.length > 0 ? group.answers : multipleGroupAnswers;
    const checkboxAnswer =
        multipleGroupAnswers && multipleGroupAnswers.length > 0
            ? group.answers.find(answer => answer.type === 'CHECKBOX')
            : null;
    const isMultiGroup = typeof multipleGroupAnswers !== 'undefined' && multipleGroupAnswers.length > 1;

    // set refs to modal action so the multiplegroup component can trigger them
    const modalRefs = useRef([]);
    if (isMultiGroup && modalRefs.current.length !== multipleGroupAnswers.length) {
        modalRefs.current = Array(multipleGroupAnswers.length)
            .fill()
            .map((_, i) => modalRefs.current[i] || { current: { id: multipleGroupAnswers[i].id } });
    }
    const form = useForm();

    const render = () => {
        return (
            <StyledAnswerGroup
                id={`ANSWERGROUP_${question.id}_${index}`}
                className="ANSWERGROUP"
                key={`answergroup_${question.id}_${index}`}
            >
                {group.label ? (
                    <StyledLabel level={treeLevel}>
                        <span>{group.label}</span>&nbsp;
                        {group.infotext ? (
                            <Tooltip tooltip={group.infotext} placement="top-start">
                                <StyledIcon iconName="question_outlined" />
                            </Tooltip>
                        ) : null}
                    </StyledLabel>
                ) : null}
                <StyledFormMaskRow id={`ANSWERGROUP_ROW_${index}`} className={`ANSWERGROUP_ROW`}>
                    {hasAnswers
                        ? group.answers.map(answer => (
                              <React.Fragment key={answer.id}>
                                  {conditionalRender(answer, () => renderCol(answer, group.answers, modalRefs))}
                              </React.Fragment>
                          ))
                        : null}

                    {isMultiGroup ? (
                        <>
                            <MultipleGroup answers={multipleGroupAnswers} modalRefs={modalRefs} />
                            {isRequired ? (
                                <Field
                                    name={`${prefix}_tmp_${question.id}_${index}`}
                                    initialValue={''}
                                    validate={required(i18next.t('HealthQuestionsForm.validationRequired'))}
                                >
                                    {({ meta, input }) => {
                                        return (
                                            <>
                                                <input
                                                    type="hidden"
                                                    {...input}
                                                    value={typeof input.value !== 'undefined' ? input.value : ''}
                                                    data-focusable="true"
                                                />
                                                <ValidationError fieldMeta={meta} />
                                                <FormSpy
                                                    subscription={{ values: true }}
                                                    onChange={({ values }) => {
                                                        let multipleCount = 0;
                                                        let allCount = 0;

                                                        multipleGroupAnswers?.map(
                                                            answer =>
                                                                (multipleCount +=
                                                                    answer.id in (values?.healthQuestions || [])
                                                                        ? values.healthQuestions[answer.id].length
                                                                        : 0)
                                                        );
                                                        groupAnswers?.map(
                                                            answer =>
                                                                (allCount +=
                                                                    answer.id in (values?.healthQuestions || [])
                                                                        ? values.healthQuestions[answer.id].length
                                                                        : 0)
                                                        );
                                                        if (values.healthQuestions) {
                                                            form.change(
                                                                `${prefix}_tmp_${question.id}_${index}`,
                                                                allCount || ''
                                                            );

                                                            if (
                                                                checkboxAnswer?.id &&
                                                                multipleCount > 0 &&
                                                                allCount > multipleCount
                                                            ) {
                                                                form.change(`healthQuestions.${checkboxAnswer.id}`, []);
                                                            }
                                                        }
                                                    }}
                                                />
                                            </>
                                        );
                                    }}
                                </Field>
                            ) : null}
                        </>
                    ) : null}
                </StyledFormMaskRow>
            </StyledAnswerGroup>
        );
    };

    const renderCol = (answer, answers, modalRefs = null) => {
        let answerReferenceId;
        let fromDateReferenceId;

        if (['DATEPICKER', 'MONTHPICKER'].includes(answer.type)) {
            // check if next field is a toggle. better solutions need changes to json api
            if (answerIndex + 1 < groupSize) {
                const nextAnswer = group.answers[answerIndex + 1];
                if (nextAnswer.type === 'TOGGLE') {
                    answerReferenceId = nextAnswer.id;
                }
            }

            // check if there was a datepicker answer before, if so implement from < to
            if (answerIndex - 1 >= 0) {
                const previousAnswer = group.answers[answerIndex - 1];
                if (['DATEPICKER', 'MONTHPICKER'].includes(previousAnswer.type)) {
                    fromDateReferenceId = previousAnswer.id;
                }
            }
        }

        answerIndex++;

        return (
            <>
                {answerReferenceId && (
                    <OnChange name={answerReferenceId}>
                        {(value, previous) => {
                            if (value !== previous && value === true) {
                                form.change(answer.id, moment().format(BACKEND_DATE_FORMAT));
                            }
                        }}
                    </OnChange>
                )}
                <StyledFormMaskCol
                    id={`ANSWERGROUP_COL_${answer.id}`}
                    className={`ANSWERGROUP_COL TYPE_${answer.type}`}
                    key={`answergroup_col_${answer.id}`}
                    answerType={answer.type}
                >
                    {answerReferenceId || fromDateReferenceId || group.max
                        ? renderAnswerWithReferencedProps(
                              answer,
                              answers,
                              answerReferenceId,
                              fromDateReferenceId,
                              group.max
                          )
                        : renderAnswer(answer, false, null, modalRefs)}
                </StyledFormMaskCol>
            </>
        );
    };

    const renderAnswerWithReferencedProps = (answer, answers, answerReferenceId, fromDateReferenceId, maxValues) => {
        const answerIds = answers?.map(a => `${prefix}${a.id}`);
        return (
            <FormSpyFieldValues fieldNames={answerIds}>
                {values => {
                    const count =
                        maxValues &&
                        Object.values(values).reduce((acc, val) => acc + (Array.isArray(val) ? val.length : 0), 0);
                    const disabled = !!(
                        (maxValues && count >= maxValues) ||
                        (answerReferenceId && answerReferenceId in values && values[answerReferenceId])
                    );
                    const allowedValues =
                        fromDateReferenceId && fromDateReferenceId in values && values[fromDateReferenceId]
                            ? values[fromDateReferenceId]
                            : null;

                    return renderAnswer(answer, disabled, allowedValues && { minDate: allowedValues }, modalRefs);
                }}
            </FormSpyFieldValues>
        );
    };

    const renderAnswer = (answer, disabled = false, allowedValues = null, modalRefs = null) => {
        return (
            <Answer
                key={answer.id}
                healthQuestions={healthQuestions}
                block={block}
                question={question}
                answer={answer}
                disabled={disabled}
                allowedValues={allowedValues}
                isMultiGroup={isMultiGroup}
                modalRefs={modalRefs}
                prefix={prefix}
            />
        );
    };

    return conditionalRender(group, render);
};

AnswerGroup.propTypes = {
    index: number.isRequired,
    healthQuestions: array.isRequired,
    block: object.isRequired,
    question: object.isRequired,
    group: object.isRequired,
    isMultiGroup: bool,
    prefix: string,
    modalRefs: object,
};
export default AnswerGroup;
