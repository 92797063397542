import React, { useState } from 'react';
import i18next from 'i18next';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import classNames from 'classnames';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { requiredFieldArrayCheckbox } from '@src/util/validators';
import { OnChange } from 'react-final-form-listeners';

import { Modal } from 'atupri-component-library/lib/components';
import { P3, Button } from 'atupri-component-library/lib/primitives';
import { Spacer } from 'atupri-component-library/lib/helpers';
import { ExternalLink, Form, FieldCheckbox } from '@src/components';
import { ensureSubmission } from '@src/util/data';
import { array_equal } from '@src/util/array';
import { getLocalizedStaticUrl } from '@src/util/staticUrl';
import { getSubmissionId } from '@src/util/submission';

import {
    StyledSaveSession,
    StyledCustomSpacer,
    StyledHeader,
    StyledButtonGroup,
    StyledDivider,
    StyledFieldTextInput,
    ModalStyles,
} from './SaveSession.style';

const SaveSession = props => {
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const submissionId = getSubmissionId();

    const url = new URL(window.location.href);
    url.searchParams.set('id', submissionId?.uuid);
    url.searchParams.set('source', 'Praemienrechner');
    url.searchParams.set('medium', 'Referral');
    url.searchParams.set('campaign', 'save_session');

    url.searchParams.set('content', 'link');
    const linkUrl = url.href;
    url.searchParams.set('content', 'e-mail');
    const emailUrl = url.href;

    const fakeHref = `https://${Array(url.href.length - 7).join('x')}`;

    const {
        submission,
        onExtendSubmission = () => {
            // This is intentionally empty
        },
        children,
    } = props;

    const currentSubmission = ensureSubmission(submission);

    const sessionCategory = currentSubmission?.attributes?.sessionCategory || 'DEFAULT';

    const openModal = () => {
        setModalIsOpen(true);
    };
    const closeModal = () => {
        setModalIsOpen(false);
    };

    const mutators = {
        ...arrayMutators,
        setValue: ([field, value], state, { changeValue }) => {
            changeValue(state, field, () => value);
        },
    };

    const initialValues = {
        extendSubmissionAgreement: sessionCategory !== 'DEFAULT' ? ['1'] : null,
        extendSubmissionHref: sessionCategory !== 'DEFAULT' ? linkUrl : fakeHref,
    };

    const templateMsg = {
        buttonSave: i18next.t('SaveSession.header.buttonSave'),
        header: i18next.t('SaveSession.template.header'),
        p1: i18next.t('SaveSession.template.p1'),
        extendSubmissionAgreementLabel: i18next.t('SaveSession.template.extendSubmissionAgreementLabel'),
        buttonCopyToClipboard: i18next.t('SaveSession.template.buttonCopyToClipboard'),
        buttonSendPerEmail: i18next.t('SaveSession.template.buttonSendPerEmail'),
        legalLabel: i18next.t('SaveSession.template.legalLabel'),
    };

    const render = () => (
        <>
            {children(openModal)}
            <StyledSaveSession>
                <Modal customStyles={ModalStyles} isOpen={modalIsOpen} onCloseRequest={closeModal}>
                    <FinalForm
                        {...props}
                        mutators={mutators}
                        initialValues={initialValues}
                        onSubmit={() => {
                            // intentionally empty
                        }}
                        render={({ className = '', handleSubmit, form, values }) => {
                            const classes = classNames('rootExtendSubmissionForm', className);
                            const agreed = !!values.extendSubmissionAgreement;
                            const href = agreed ? linkUrl : fakeHref;

                            const emailMsg = {
                                subject: i18next.t('SaveSession.email.subject'),
                                body: i18next.t('SaveSession.email.body', { url: emailUrl }),
                            };

                            return (
                                <Form className={classes} onSubmit={handleSubmit}>
                                    <StyledHeader>{templateMsg.header}</StyledHeader>
                                    <Spacer space="hecto" />
                                    <P3>{templateMsg.p1}</P3>
                                    <StyledCustomSpacer space="pika" />
                                    <FieldCheckbox
                                        id={`rootExtendSubmissionForm.extendSubmissionAgreement`}
                                        name={'extendSubmissionAgreement'}
                                        label={templateMsg.extendSubmissionAgreementLabel}
                                        value={'1'}
                                        disabled={agreed}
                                        validate={requiredFieldArrayCheckbox('required')}
                                    />
                                    <StyledDivider />
                                    <OnChange name="extendSubmissionAgreement">
                                        {(value, previous) => {
                                            if (!array_equal(value, previous)) {
                                                form.mutators.setValue('extendSubmissionHref', href);

                                                if (agreed) {
                                                    onExtendSubmission({ id: currentSubmission.id });
                                                }
                                            }
                                        }}
                                    </OnChange>
                                    <StyledFieldTextInput
                                        id={`rootExtendSubmissionForm.extendSubmissionHref`}
                                        name="extendSubmissionHref"
                                        type={'text'}
                                        disabled
                                    />
                                    <StyledCustomSpacer space="bulba" />
                                    <StyledButtonGroup>
                                        <CopyToClipboard onCopy={() => closeModal()} text={href}>
                                            <Button
                                                type="submit"
                                                outlined
                                                buttonText={templateMsg.buttonCopyToClipboard}
                                                disabled={!agreed}
                                                tracking={{
                                                    category: 'ux',
                                                    action: 'button',
                                                    label: i18next.getFixedT('de')(
                                                        'SaveSession.template.buttonCopyToClipboard'
                                                    ),
                                                }}
                                            />
                                        </CopyToClipboard>
                                        <Button
                                            onClick={() => {
                                                window.open(
                                                    `mailto:?subject=${encodeURIComponent(
                                                        emailMsg.subject
                                                    )}&body=${encodeURIComponent(emailMsg.body)}`,
                                                    '_blank'
                                                );
                                                closeModal();
                                            }}
                                            buttonText={templateMsg.buttonSendPerEmail}
                                            disabled={!agreed}
                                            tracking={{
                                                category: 'ux',
                                                action: 'button',
                                                label: i18next.getFixedT('de')(
                                                    'SaveSession.template.buttonSendPerEmail'
                                                ),
                                            }}
                                        />
                                        <ExternalLink
                                            href={getLocalizedStaticUrl('footerLegalPage')}
                                            className={'meta-link'}
                                        >
                                            {templateMsg.legalLabel}
                                        </ExternalLink>
                                    </StyledButtonGroup>
                                </Form>
                            );
                        }}
                    />
                </Modal>
            </StyledSaveSession>
        </>
    );

    return render();
};

export default SaveSession;
