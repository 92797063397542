import React, { useState, useEffect, useRef } from 'react';
import { array, func, object, string } from 'prop-types';
import i18next from 'i18next';
import { Field } from 'react-final-form';
import { useSelector } from 'react-redux';

// config
import config from '@src/config';
import { mappingData as getTariffMapping } from '@src/config/getTariff';

// components
import { PracticeSearch, RebateTeaser } from '@src/components';
import { Accordion, BlueBarTitle, Tooltip } from 'atupri-component-library/lib/components';
import { Toggle, Icon, Link, H3, P2, P3 } from 'atupri-component-library/lib/primitives';
import { Box, Divider, Hidden, Spacer, Stack } from 'atupri-component-library/lib/helpers';
import { ToggleIcon } from './Controls';

// util
import { formatAmount } from '@src/util/currency';
import { isInternalProcess, UserFlowEnum, getUserFlow } from '@src/util/process';
import { sanitizeDOM } from '@src/util/sanitize';
import { getProductIdentifier } from '@src/util/tariff';
import { calculatePriceWithTax, findTariff } from '@src/util/tariff/okp';
import { checkForAgeGroupChange, scrollAccordionIntoView, scrollIntoViewWithOffset } from '../util';

// style
import {
    StyledInfoIcon,
    StyledGrid,
    StyledGridItem,
    StyledEmphasizedToggle,
    StyledAccordionItem,
    StyledAccordionItemPrice,
    StyledAccordionItemSummary,
    StyledAccordionItemIconBox,
    StyledAccordionItemTitle,
    StyledAccordionItemContent,
    StyledAccordionContentText,
    StyledDivider,
    StyledBox,
    StyledInnerBox,
    StyledBoxTitle,
    StyledAccordionHeading,
    StyledAccordionToggleWrapper,
    StyledAccordionTrigger,
    StyledAccordionTriggerTitle,
    StyledPromotionBadge,
    StyledToolTipWrapper,
} from '../ProductForm.style';

export const OkpProductForm = ({ form, values, apiData, onOkpItemExpand, submission, persons, currentPerson }) => {
    const namePrefix = 'okp';
    const accidentChangeParentId = 'accidentChangeParentId';

    const { products, categories } = apiData;

    const currentProductsFromApi = useSelector(state => state.apiData?.currentProducts);
    const currentProductsForPerson = currentProductsFromApi?.find(
        product => product.insuredNr === currentPerson.insuranceNumber
    )?.products;
    const { franchise: currentFranchise, model: currentOkpProduct } =
        currentProductsForPerson?.find(product => product.code === 'OKP') || {};

    const okpEnabled = products && values.enabled;

    const [selectedPractices, setSelectedPractices] = useState(values.practices);
    // expand active okp product - either by user or API default
    const [expandedOkpItem, setExpandedOkpItem] = useState([values.product]);

    const accordionItemRef = useRef([]);

    const isInternal = isInternalProcess(persons, currentProductsFromApi);

    const isAgeGroupChange = checkForAgeGroupChange(isInternal, currentPerson);

    useEffect(() => {
        form.mutators.setValue(`${namePrefix}.practices`, selectedPractices);
    }, [selectedPractices, expandedOkpItem]);

    useEffect(() => {
        // Scroll the viewport to the corresponding radio buttons when changing accident insurance
        if (isInternal && getUserFlow(submission).toString() === UserFlowEnum.accidentChangeOnly.toString()) {
            scrollIntoViewWithOffset(accidentChangeParentId, 180);
        }
    }, []);

    const getPracticeSearchContent = (product, isActive = false) => {
        if (product.gatekeeper === true) {
            const params = {
                identifier: product.id,
                selectedPractice: selectedPractices[product.id] || {},
                selectedPractices: selectedPractices,
                setPracticesFunc: setSelectedPractices,
            };

            return (
                <>
                    <Divider color="blue500" />
                    <Spacer space="zeta" />
                    <PracticeSearch
                        model={product.id}
                        place={currentPerson.place}
                        municipalityId={currentPerson.municipalityId}
                        municipalityInfo={currentPerson.municipalityInfo}
                        zip={currentPerson.zip}
                        zipText={currentPerson.zipText}
                        insuranceStart={currentPerson.insuranceStart}
                        selectedPractice={params.selectedPractice}
                        selectedPractices={params.selectedPractices}
                        setSelectedPractices={params.setPracticesFunc}
                        identifier={params.identifier}
                        hasActiveStyles={isActive}
                        userFlow={getUserFlow(submission)}
                    />
                </>
            );
        }
    };

    const renderCategory = category => {
        if (
            products.find(product => product.id === values.product) &&
            products.find(product => product.id === values.product).categories.includes(category.id)
        ) {
            return (
                <StyledGridItem
                    hasBadge={category.value === String(currentFranchise?.value)}
                    key={'category' + category.id}
                    size={1}
                >
                    <Field name={`${namePrefix}.category`}>
                        {fieldProps => {
                            return (
                                <StyledEmphasizedToggle
                                    isActive={fieldProps.input.value === String(category.id)}
                                    controlType="radio"
                                    flexDirection="column"
                                    alignItems="flex-start"
                                    borderRadius={8}
                                    className="category-radio"
                                    name={fieldProps.input.name}
                                    value={`${category.id}`}
                                    selectedValue={`${fieldProps.input.value}`}
                                    onChange={event => fieldProps.input.onChange(`${event.target.value}`)}
                                    toggleTitleText={i18next.t(`ProductForm.${namePrefix}.category.franchise`, {
                                        amount: formatAmount(category.value, false),
                                    })}
                                    ToggleTitleComponent={P2}
                                >
                                    {category.value === String(currentFranchise?.value) && (
                                        <StyledPromotionBadge isCategory={true} className="promotion-badge">
                                            {i18next.t(`ProductForm.currentSelection`)}
                                        </StyledPromotionBadge>
                                    )}
                                    {isAgeGroupChange && category.recommended && (
                                        <StyledPromotionBadge isCategory={true} dark={true}>
                                            {i18next.t(`ProductForm.vvg.promotionBadge`)}
                                        </StyledPromotionBadge>
                                    )}
                                    <Spacer space="deci" />
                                    <P3>
                                        {i18next.t(`ProductForm.${namePrefix}.category.price`, {
                                            price: formatAmount(
                                                calculatePriceWithTax(
                                                    findTariff(
                                                        apiData,
                                                        {
                                                            ...values,
                                                            category: category.id,
                                                        },
                                                        { personId: currentPerson.id, persons, submission }
                                                    )
                                                )
                                            ),
                                        })}
                                    </P3>
                                </StyledEmphasizedToggle>
                            );
                        }}
                    </Field>
                </StyledGridItem>
            );
        }
    };

    const onAccordionChange = expandedItems => {
        onOkpItemExpand(expandedItems);
    };

    return (
        okpEnabled && (
            <Stack space="kilo">
                <Box shadow="ctaBlueHover">
                    <StyledBoxTitle>
                        <BlueBarTitle title={i18next.t(`ProductForm.title.${namePrefix}`)} />
                    </StyledBoxTitle>
                    <StyledBox>
                        <Stack space="peta">
                            <StyledInnerBox>
                                <H3>
                                    {i18next.t(`ProductForm.${namePrefix}.category.title`)}
                                    {i18next.exists(`ProductForm.${namePrefix}.category.tooltip`) && (
                                        <Tooltip
                                            tooltip={
                                                <StyledToolTipWrapper>
                                                    {i18next.t(`ProductForm.${namePrefix}.category.tooltip`)}
                                                </StyledToolTipWrapper>
                                            }
                                            placement="top"
                                        >
                                            <StyledInfoIcon iconName="info_bubble" title="Info" />
                                        </Tooltip>
                                    )}
                                </H3>
                                {isAgeGroupChange && (
                                    <>
                                        <Spacer space="epsilon" />
                                        <P3>{i18next.t(`ProductForm.${namePrefix}.category.ageGroupChange.text`)}</P3>
                                        <Spacer space="epsilon" />
                                        <P3>
                                            {i18next.t(
                                                `ProductForm.${namePrefix}.category.ageGroupChange.currentFranchise`
                                            )}
                                            <strong> {formatAmount(currentFranchise.value)}</strong>
                                        </P3>
                                        <Spacer space="epsilon" />
                                    </>
                                )}
                                <Spacer space="epsilon" />
                                <StyledGrid columns={{ zero: 1, medium: 2, large: 3 }}>
                                    {categories.map(category => renderCategory(category))}
                                </StyledGrid>
                            </StyledInnerBox>
                            <StyledInnerBox id={accidentChangeParentId}>
                                <H3>
                                    {i18next.t(`ProductForm.${namePrefix}.variant.title`)}
                                    {i18next.exists(`ProductForm.${namePrefix}.variant.tooltip`) && (
                                        <Tooltip
                                            tooltip={
                                                <StyledToolTipWrapper>
                                                    {i18next.t(`ProductForm.${namePrefix}.variant.tooltip`)}
                                                </StyledToolTipWrapper>
                                            }
                                            placement="top"
                                        >
                                            <StyledInfoIcon iconName="info_bubble" title="Info" />
                                        </Tooltip>
                                    )}
                                </H3>
                                <Spacer space="epsilon" />
                                <Stack direction="row">
                                    <Field name={`${namePrefix}.variant`}>
                                        {fieldProps => {
                                            return (
                                                <StyledEmphasizedToggle
                                                    name={fieldProps.input.name}
                                                    selectedValue={`${fieldProps.input.value}`}
                                                    onChange={event =>
                                                        fieldProps.input.onChange(`${event.target.value}`)
                                                    }
                                                    value={getTariffMapping.okp.variants.accidentIncluded}
                                                    controlType="radio"
                                                    hasControl={false}
                                                    icon="check"
                                                    iconSize={16}
                                                >
                                                    {i18next.t(
                                                        `ProductForm.${namePrefix}.variant.${getTariffMapping.okp.variants.accidentIncluded}`
                                                    )}
                                                </StyledEmphasizedToggle>
                                            );
                                        }}
                                    </Field>

                                    <Field name={`${namePrefix}.variant`}>
                                        {fieldProps => {
                                            return (
                                                <StyledEmphasizedToggle
                                                    name={fieldProps.input.name}
                                                    selectedValue={`${fieldProps.input.value}`}
                                                    onChange={event =>
                                                        fieldProps.input.onChange(`${event.target.value}`)
                                                    }
                                                    value={getTariffMapping.okp.variants.accidentNotIncluded}
                                                    controlType="radio"
                                                    hasControl={false}
                                                    icon="cross"
                                                    iconSize={16}
                                                >
                                                    {i18next.t(
                                                        `ProductForm.${namePrefix}.variant.${getTariffMapping.okp.variants.accidentNotIncluded}`
                                                    )}
                                                </StyledEmphasizedToggle>
                                            );
                                        }}
                                    </Field>
                                </Stack>
                            </StyledInnerBox>
                            <StyledInnerBox>
                                <H3>
                                    {i18next.t(`ProductForm.${namePrefix}.product.title`)}
                                    {i18next.exists(`ProductForm.${namePrefix}.product.tooltip`) && (
                                        <Tooltip
                                            tooltip={
                                                <StyledToolTipWrapper>
                                                    {i18next.t(`ProductForm.${namePrefix}.product.tooltip`)}
                                                </StyledToolTipWrapper>
                                            }
                                            placement="top"
                                        >
                                            <StyledInfoIcon iconName="info_bubble" title="Info" />
                                        </Tooltip>
                                    )}
                                </H3>
                                <Spacer space="epsilon" />
                                <Accordion
                                    allowMultipleExpanded
                                    allowItemCollapse
                                    expanded={expandedOkpItem}
                                    onChange={onAccordionChange}
                                >
                                    {products.map(product => {
                                        const isActive = values.product === product.id;
                                        const identifier = getProductIdentifier(product.id);

                                        return (
                                            <StyledAccordionItem
                                                key={product.id}
                                                uuid={product.id}
                                                isActive={isActive}
                                                hasBadge={
                                                    product.id === currentOkpProduct ||
                                                    i18next.exists(
                                                        `ProductForm.${namePrefix}.product.${identifier}.promotionBadge`
                                                    )
                                                }
                                                className="accordionItem"
                                            >
                                                {product.id === currentOkpProduct && (
                                                    <StyledPromotionBadge>
                                                        {i18next.t(`ProductForm.currentSelection`)}
                                                    </StyledPromotionBadge>
                                                )}
                                                {product.id !== currentOkpProduct &&
                                                    i18next.exists(
                                                        `ProductForm.${namePrefix}.product.${identifier}.promotionBadge`
                                                    ) && (
                                                        <StyledPromotionBadge dark={true}>
                                                            {i18next.t(
                                                                `ProductForm.${namePrefix}.product.${identifier}.promotionBadge`
                                                            )}
                                                        </StyledPromotionBadge>
                                                    )}
                                                <StyledAccordionHeading
                                                    ref={element => {
                                                        accordionItemRef.current[product.id] = element;
                                                    }}
                                                >
                                                    <StyledAccordionToggleWrapper firstLevel>
                                                        <Field name={`${namePrefix}.product`}>
                                                            {fieldProps => {
                                                                return (
                                                                    <Toggle
                                                                        controlType="radio"
                                                                        name={fieldProps.input.name}
                                                                        value={`${product.id}`}
                                                                        selectedValue={fieldProps.input.value}
                                                                        onChange={event => {
                                                                            fieldProps.input.onChange(
                                                                                event.target.value
                                                                            );
                                                                            setExpandedOkpItem([product.id]);
                                                                            scrollAccordionIntoView(
                                                                                accordionItemRef,
                                                                                product.id
                                                                            );
                                                                        }}
                                                                    />
                                                                );
                                                            }}
                                                        </Field>
                                                    </StyledAccordionToggleWrapper>
                                                    <StyledAccordionTrigger type="button">
                                                        <StyledAccordionTriggerTitle firstLevel>
                                                            <Hidden below="large">
                                                                <StyledAccordionItemIconBox
                                                                    background="blue300"
                                                                    borderRadius={4}
                                                                >
                                                                    <Icon
                                                                        iconName={i18next.t(
                                                                            `ProductForm.${namePrefix}.product.${identifier}.icon`
                                                                        )}
                                                                    />
                                                                </StyledAccordionItemIconBox>
                                                            </Hidden>
                                                            <Stack direction="column" space="alpha">
                                                                <StyledAccordionItemTitle isActive={isActive}>
                                                                    {i18next.t(
                                                                        `ProductForm.${namePrefix}.product.${identifier}.title`
                                                                    )}
                                                                </StyledAccordionItemTitle>
                                                                <Hidden below="large">
                                                                    <StyledAccordionItemSummary>
                                                                        {i18next.t(
                                                                            `ProductForm.${namePrefix}.product.${identifier}.summary`
                                                                        )}
                                                                    </StyledAccordionItemSummary>
                                                                </Hidden>
                                                            </Stack>
                                                            <StyledAccordionItemPrice isActive={isActive}>
                                                                {formatAmount(
                                                                    calculatePriceWithTax(
                                                                        findTariff(
                                                                            apiData,
                                                                            {
                                                                                ...values,
                                                                                product: product.id,
                                                                            },
                                                                            {
                                                                                personId: currentPerson.id,
                                                                                persons,
                                                                                submission,
                                                                            }
                                                                        )
                                                                    )
                                                                )}
                                                            </StyledAccordionItemPrice>
                                                        </StyledAccordionTriggerTitle>
                                                        <Hidden above="large">
                                                            <StyledAccordionItemSummary>
                                                                {i18next.t(
                                                                    `ProductForm.${namePrefix}.product.${identifier}.summary`
                                                                )}
                                                            </StyledAccordionItemSummary>
                                                        </Hidden>
                                                        <ToggleIcon firstLevel />
                                                    </StyledAccordionTrigger>
                                                </StyledAccordionHeading>
                                                <StyledAccordionItemContent>
                                                    <StyledDivider isActive={isActive} />
                                                    <Spacer space="hecto" />
                                                    <StyledAccordionContentText
                                                        dangerouslySetInnerHTML={{
                                                            __html: sanitizeDOM(
                                                                i18next.t(
                                                                    `ProductForm.${namePrefix}.product.${identifier}.text`
                                                                )
                                                            ),
                                                        }}
                                                    />
                                                    {i18next.exists(
                                                        `ProductForm.${namePrefix}.product.${identifier}.link.label`
                                                    ) && (
                                                        <>
                                                            <Spacer space="hecto" />
                                                            <Link
                                                                linkText={i18next.t(
                                                                    `ProductForm.${namePrefix}.product.${identifier}.link.label`
                                                                )}
                                                                link={{
                                                                    href: i18next.t(
                                                                        `ProductForm.${namePrefix}.product.${identifier}.link.url`
                                                                    ),
                                                                    title: i18next.t(
                                                                        `ProductForm.${namePrefix}.product.${identifier}.link.label`
                                                                    ),
                                                                    target: '_blank',
                                                                }}
                                                                color="primary"
                                                            />
                                                        </>
                                                    )}
                                                    {product.gatekeeper && !selectedPractices[product.id] && (
                                                        <>
                                                            <Spacer space="hecto" />
                                                            {getPracticeSearchContent(product, isActive)}
                                                        </>
                                                    )}
                                                </StyledAccordionItemContent>
                                                {selectedPractices[product.id] && (
                                                    <>
                                                        {getPracticeSearchContent(product, isActive)}
                                                        <Spacer space="theta" />
                                                    </>
                                                )}
                                            </StyledAccordionItem>
                                        );
                                    })}
                                </Accordion>
                            </StyledInnerBox>
                            {values.product &&
                                !isInternal &&
                                (productId => {
                                    const identifier = getProductIdentifier(productId);
                                    return (
                                        <RebateTeaser>
                                            <P3>
                                                {i18next.t(
                                                    `ProductForm.${namePrefix}.rebateAvmBuyVvg.${identifier}.text`
                                                )}
                                                {i18next.exists(
                                                    `ProductForm.${namePrefix}.rebateAvmBuyVvg.${identifier}.tooltip`
                                                ) && (
                                                    <Tooltip
                                                        tooltip={
                                                            <StyledToolTipWrapper>
                                                                {i18next.t(
                                                                    `ProductForm.${namePrefix}.rebateAvmBuyVvg.${identifier}.tooltip`
                                                                )}
                                                            </StyledToolTipWrapper>
                                                        }
                                                        placement="top"
                                                    >
                                                        <StyledInfoIcon iconName="info_bubble" title="Info" />
                                                    </Tooltip>
                                                )}
                                            </P3>
                                        </RebateTeaser>
                                    );
                                })(values.product)}
                        </Stack>
                    </StyledBox>
                    <Field name={`${namePrefix}.practices`}>
                        {fieldProps => <input hidden {...fieldProps.input} />}
                    </Field>
                </Box>
                {config.env !== 'prod' && (
                    <StyledBox
                        display={['test', 'int', 'int2'].includes(config.env) ? 'none' : 'block'}
                        shadow="ctaBlueHover"
                    >
                        <H3>Ausgewählter OKP-Tariff:</H3>
                        <Field name={`tariff.okp`}>
                            {fieldProps => (
                                <pre style={{ overflowX: 'scroll' }}>
                                    {JSON.stringify(fieldProps.input.value, 0, 2)}
                                </pre>
                            )}
                        </Field>
                    </StyledBox>
                )}
            </Stack>
        )
    );
};

OkpProductForm.propTypes = {
    form: object.isRequired,
    values: object.isRequired,
    apiData: object.isRequired,
    onOkpItemExpand: func,
    submission: object.isRequired,
    persons: array.isRequired,
    currentPerson: object.isRequired,
    className: string,
};

export default OkpProductForm;
