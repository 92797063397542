export const ERROR_CATEGORY_SUBMISSION = 'submission';

// ================ Action types ================ //

export const ADD_ERROR = 'app/ErrorQueue/ADD_ERROR';
export const CLEAR_ERRORS = 'app/ErrorQueue/CLEAR_ERRORS';

// ================ Reducer ================ //

const initialState = {
    errors: [],
};

export default function reducer(state = initialState, action = {}) {
    const { type, payload } = action;
    switch (type) {
        case ADD_ERROR: {
            const { message, code, category } = payload;
            const currentErrors = state.errors;
            const errors = [...currentErrors, { message, code, category }];

            return {
                ...state,
                errors,
            };
        }
        case CLEAR_ERRORS: {
            const { category } = payload;
            if (category) {
                const errors = state.errors.filter(error => error.category !== category);

                return {
                    ...state,
                    errors,
                };
            }
            return initialState;
        }
        default:
            return state;
    }
}

// ================ Action creators ================ //

export const addError = (message, code, category) => ({
    type: ADD_ERROR,
    payload: { message, code, category },
});

export const clearErrors = category => ({
    type: CLEAR_ERRORS,
    payload: { category },
});
