import sortBy from 'lodash/sortBy';

import { log } from '@src/middleware';

import { staticData as getTariffStatic } from '@src/config/getTariff';

import { isEligibleForFamilyDiscount } from '@src/util/person';
import { getProductTrackingTitle } from '@src/util/basket';

export const PRODUCT_GROUP_IDENTIFIER_MIVITA = 2;
export const PRODUCT_GROUP_IDENTIFIER_COMFORTA = 3;
export const PRODUCT_GROUP_IDENTIFIER_DENTA = 4;
export const PRODUCT_GROUP_IDENTIFIER_UTI = 5;
export const PRODUCT_GROUP_IDENTIFIER_SPITAL = 6;
export const PRODUCT_IDENTIFIER_BASIC = 'MIX_26_236';
export const PRODUCT_IDENTIFIER_SPITAL_ALLGEMEIN = 'ALG_11_60';

export const VARIANTS_WITH_ACCIDENT = ['Krankheit und Unfall', 'mit Unfall'];
export const VARIANTS_NO_ACCIDENT = ['ohne Unfall', '-'];
export const PRODUCT_CODE_OKP = 'OKP';
export const PRODUCT_CODE_MIVITA = 'MIVITA';
export const PRODUCT_CODE_COMFORTA = 'COMFORTA';
export const PRODUCT_CODE_SPITAL = 'SPITAL';
export const PRODUCT_CODE_UTI = 'UTI';
export const PRODUCT_CODE_SPITAL_KOMBI = 'KOMBI';
export const PRODUCT_CODE_SPITAL_EXTRA = 'EXTRA';
export const PRODUCT_CODE_SPITAL_DIVERSA = 'DIVERSA';
export const PRODUCT_CODE_SPITAL_HOTEL = 'HOTEL';

export const REBATE_4_NO_REBATE = '0';

/**
 * Finds the correct tariff for a given product
 *
 * @param {*} productId
 * @param {*} apiData
 * @param {*} additionalData
 * @returns
 */
export const findTariff = (productId, apiData, additionalData) => {
    // Retrieve tariffs of given product...
    let productTariffs = filterTariffsForGivenProduct(productId, apiData);

    // Retrieve tariffs with duration 'one year'...
    productTariffs = filterTariffsForGivenDuration(1, { ...apiData, tariffs: productTariffs });

    // Retrieve tariffs, with variants WITH accident coverage. If none found the current product, fallback to variants WITHOUT accident coverage...
    productTariffs = filterTariffsForVariant(
        { ...apiData, tariffs: productTariffs },
        VARIANTS_WITH_ACCIDENT,
        VARIANTS_NO_ACCIDENT
    );

    // Retrieve tariffs, with familyRebate, if current person is eligible...
    productTariffs = filterTariffsForFamilyRebate({ ...apiData, tariffs: productTariffs }, additionalData);

    // Retrieve tariffs, with combination rebate, any...
    productTariffs = filterTariffsForCombinationRebate({ ...apiData, tariffs: productTariffs });

    if (productTariffs.length > 1) {
        // filter for rebates provided by api
        productTariffs = filterTariffsForGivenRebate(
            productId,
            { ...apiData, tariffs: productTariffs },
            additionalData
        );

        if (productTariffs.length > 1) {
            log.warn(`${productTariffs.length} tariffs found for "${productId}" (first is taken):`, {
                found: productTariffs,
                apiData,
            });
        }
    } else if (productTariffs.length === 0) {
        log.error(`${productTariffs.length} tariffs found for "${productId}":`, { found: productTariffs, apiData });
    }

    return productTariffs.length ? productTariffs[0] : null;
};

export const filterTariffsForGivenProduct = (productId, apiData) => {
    const { tariffs: apiTariffs, products: apiProducts } = apiData;
    const product = apiProducts.filter(product => product.id === productId);
    const filteredTariffs = apiTariffs.filter(tariff => tariff.parent === productId);
    if (filteredTariffs.length === 0) {
        log.error('No tariff found for given product', { apiTariffs, productId, product });
    }

    return filteredTariffs;
};

export const filterTariffsForGivenDuration = (duration, apiData) => {
    const { tariffs: apiTariffs } = apiData;
    const filteredTariffs = apiTariffs.filter(tariff => tariff?.duration === duration);
    if (filteredTariffs.length === 0) {
        log.error('No tariff found for given duration', { apiTariffs, duration });
    }

    return filteredTariffs.length > 0 ? filteredTariffs : apiTariffs;
};

export const filterTariffsForVariant = (apiData, desired, fallback) => {
    const { tariffs: apiTariffs, variants: apiVariants } = apiData;
    const desiredVariants = filterEntityByValues(apiVariants, desired).map(variant => variant.id);
    const fallbackVariants = filterEntityByValues(apiVariants, fallback).map(variant => variant.id);
    const desiredFilteredTariffs = apiTariffs.filter(tariff => desiredVariants.includes(tariff?.variant));
    const fallbackFilteredTariffs = apiTariffs.filter(tariff => fallbackVariants.includes(tariff?.variant));
    const filteredTariffs = desiredFilteredTariffs.length > 0 ? desiredFilteredTariffs : fallbackFilteredTariffs;

    if (desiredFilteredTariffs.length === 0 && fallbackFilteredTariffs.length === 0) {
        log.error('No tariff found for given variants', { apiTariffs, desiredVariants, fallbackVariants });
    }

    return filteredTariffs.length > 0 ? filteredTariffs : apiTariffs;
};

export const filterTariffsForFamilyRebate = (apiData, additionalData) => {
    const { tariffs: apiTariffs } = apiData;
    let tariffsWithFamilyRebate;
    const familyDiscount = !!(
        additionalData?.personId &&
        additionalData?.submission &&
        isEligibleForFamilyDiscount(additionalData.personId, additionalData.submission)
    );
    tariffsWithFamilyRebate = apiTariffs.filter(tariff => tariff.familyRebate === familyDiscount);
    return tariffsWithFamilyRebate.length > 0 ? tariffsWithFamilyRebate : apiTariffs;
};

export const filterTariffsForCombinationRebate = apiData => {
    const { tariffs: apiTariffs } = apiData;
    const filteredTariffs = apiTariffs.filter(tariff => tariff.combinationRebate === true);
    return filteredTariffs.length > 0 ? filteredTariffs : apiTariffs;
};

/**
 * Filters tariffs for given rebate 4. If no tariff matches, the original tariffs are returned for backwards compatibility.
 * We check only the last given rebate, because other rebates are checked already separately (familyRebate, combinationRebate.)
 *
 * @param {*} productId
 * @param {*} apiData
 * @param {*} additionalData
 * @returns {*}
 */
export const filterTariffsForGivenRebate = (productId, apiData, additionalData) => {
    const { tariffs: apiTariffs } = apiData;
    let filteredTariffs = apiTariffs;
    let givenRebate4 = REBATE_4_NO_REBATE;

    if (additionalData?.personId && additionalData?.persons) {
        const person = additionalData.persons.find(person => person.id === additionalData.personId);
        if (person?.givenRebates !== undefined && productId in person.givenRebates) {
            givenRebate4 = person.givenRebates[productId][3];
        } else {
            log.debug(`No given rebate found for "${productId}"`);
        }
    } else {
        log.warn(`No person info found to get given rebate for "${productId}"`);
    }

    const filteredProductTariffs = filteredTariffs.filter(tariff => {
        const [rebate4] = tariff.id.split('_').slice(-1);

        return givenRebate4 === rebate4;
    });
    if (filteredProductTariffs.length > 0) {
        filteredTariffs = filteredProductTariffs;
    } else {
        log.debug(`No given rebate matched product "${productId}"`);
    }

    return filteredTariffs;
};

export const filterEntityByValues = (entities, values, negate = false) => {
    return entities.filter(variant => (negate ? !values.includes(variant.value) : values.includes(variant.value)));
};

/**
 * Returns the correct tariff to display for the given section
 *
 * There are two different behaviors:
 * 1) At least one product inside that section has been selected:
 *  => The returned price is the sum of the prices of all selected products inside that section
 * 2) No product has been selected inside that section...
 * a) ...and the section has implicit productGroups (static `productsOfGroup`):
 *  => The price of the cheapest product of the implicit productGroups is returned
 * b) ...and the section doesn't have implicit productGroups:
 *  => The price of the cheapest product of all productGroups inside that section is returned
 *
 * Additionally, the flag 'showPriceOfSelectedProducts' contains the information, whether products
 * inside that section were selected, or not.
 *
 * @param {*} section
 * @param {*} apiData
 * @param {*} values
 * @param {*} additionalData
 * @returns
 */
export const findTariffForSection = (section, apiData, values, additionalData) => {
    const selectedProductsOfSection = findSelectedProducts(values).filter(product => product.section === section);
    const showPriceOfSelectedProducts = section === values.section && selectedProductsOfSection.length > 0;
    let tariff = {
        price: 0.0,
        showPriceOfSelectedProducts,
    };

    // If section is selected, and products of this section are selected...
    if (showPriceOfSelectedProducts) {
        // ...sum prices of all products...
        let total = 0.0;
        selectedProductsOfSection.forEach(product => {
            total += parseFloat(findTariff(product.product, apiData, additionalData)?.price);
        });
        tariff = {
            price: total,
            showPriceOfSelectedProducts,
        };
    } else {
        // if section is not selected, or no product for this section has been selected...
        // ... fallback to price of cheapest product of this section...
        let productsToSort = [];

        const implicitProducts = getTariffStatic.vvg.sections.find(
            staticSection => staticSection.id === section
        )?.productsOfGroup;
        const productsOfSection = findProductsOfProductGroups(
            apiData.products,
            implicitProducts ? [implicitProducts] : findProductGroupsBySection(section)
        );
        productsOfSection.forEach(product => {
            const tariff = findTariff(product.id, apiData, additionalData);
            if (tariff) {
                productsToSort.push({
                    product: product.id,
                    price: tariff.price,
                });
            }
        });
        if (productsToSort.length) {
            productsToSort = sortBy(productsToSort, ['price']);
            tariff = {
                price: productsToSort[0].price,
                showPriceOfSelectedProducts,
            };
        }
    }
    return tariff;
};

export const findTariffForProductGroup = (productGroup, apiData, values, additionalData) => {
    const selectedProductsOfProductGroup = findSelectedProducts(values).filter(p => p.productGroup === productGroup);
    const checkedProductGroups = findCheckedProductGroups(values.productGroups);
    const productGroupIsChecked = checkedProductGroups.includes(productGroup);
    const showPriceOfSelectedProducts = productGroupIsChecked && selectedProductsOfProductGroup.length > 0;

    let tariff = {
        price: 0.0,
        showPriceOfSelectedProducts,
    };

    // If section is selected, and products of this section are selected...
    if (showPriceOfSelectedProducts) {
        // ...sum prices of all products...
        let total = 0.0;
        selectedProductsOfProductGroup.forEach(product => {
            total += parseFloat(findTariff(product.product, apiData, additionalData)?.price);
        });
        tariff = {
            price: total,
            showPriceOfSelectedProducts,
        };
    } else {
        // if section is not selected, or no product for this section has been selected...
        // ... fallback to price of cheapest product of this section...
        let productsToSort = [];

        const productsOfProductGroup = findProductsOfProductGroups(apiData.products, [productGroup]);
        productsOfProductGroup.forEach(product => {
            const tariff = findTariff(product.id, apiData, additionalData);
            if (tariff) {
                productsToSort.push({
                    product: product.id,
                    price: tariff.price,
                });
            }
        });
        if (productsToSort.length) {
            productsToSort = sortBy(productsToSort, ['price']);
            tariff = {
                price: productsToSort[0].price,
                showPriceOfSelectedProducts,
            };
        }
    }

    return tariff;
};

/**
 * Returns the product from the given `products` array with the given `id`
 *
 * @param {*} id
 * @param {*} products
 * @returns
 */
export const findProductById = (id, products) => {
    return products.find(product => product.id === id);
};

/**
 * Returns the tariff from the given `tariffs` array with the given `id`
 *
 * @param {string} id
 * @param {array} tariffs
 * @returns
 */
export const findTariffById = (id, tariffs) => {
    return tariffs.find(tariff => tariff.id === id);
};

/**
 * Filters final-form `values` and returns active products only
 *
 * @param {*} values
 * @returns array of objects
 */
export const findSelectedProducts = values => {
    const { enabled, section, productGroups, products } = values;
    let checkedProductGroups = findCheckedProductGroups(productGroups);
    let selectedProducts = [];

    if (enabled) {
        const productGroupToAdd = getTariffStatic.vvg.sections.find(
            staticSection => staticSection.id === section
        )?.productsOfGroup;
        productGroupToAdd && checkedProductGroups.push(parseInt(productGroupToAdd));

        for (const key in products) {
            let [pSectionString, pProductGroupString] = key.split('_');
            const pProductGroup = parseInt(pProductGroupString);

            if (pSectionString === section && !!checkedProductGroups.find(pg => pg === pProductGroup)) {
                selectedProducts.push({
                    section: pSectionString,
                    productGroup: pProductGroup,
                    product: products[key],
                });
            }
        }
    }

    return selectedProducts;
};

/**
 * Returns all product with given id
 *
 * @param {array} products
 * @param {string} productId
 * @returns object
 */
export const findProduct = (products, productId) => products.find(product => product.id === productId);

/**
 * Returns all products, belonging to given productGroup
 *
 * @param {array} products
 * @param {array} productGroups
 * @returns array
 */
export const findProductsOfProductGroups = (products, productGroups) =>
    products.filter(product => productGroups.includes(product.productGroup));

/**
 * Returns productGroups by given sectionId
 *
 * @param {string} sectionId
 * @returns array
 */
export const findProductGroupsBySection = sectionId => {
    return getTariffStatic.vvg.sections.find(staticSection => staticSection.id === sectionId)?.productGroups;
};

/**
 * Returns productsOfGroup by given sectionId
 *
 * @param {string} sectionId
 * @returns int
 */
export const findProductsOfGroupBySection = sectionId => {
    return getTariffStatic.vvg.sections.find(staticSection => staticSection.id === sectionId)?.productsOfGroup;
};

/**
 * Returns array of checked productGroups
 *
 * @param {array} productGroups
 * @returns
 */
export const findCheckedProductGroups = productGroups => {
    let checkedProductGroups = [];
    if (productGroups) {
        for (const key in productGroups) {
            if (productGroups[key] === true) {
                checkedProductGroups.push(parseInt(key.split('_')[1]));
            }
        }
    }
    return checkedProductGroups;
};

export const isSectionSelected = (section, values) => {
    if (!section || !values) {
        return false;
    }
    return values?.section === section;
};

export const isProductGroupChecked = (productGroup, values) => {
    if (!productGroup || !values) {
        return false;
    }
    const checkedProductGroups = findCheckedProductGroups(values?.productGroups);
    return checkedProductGroups.includes(parseInt(productGroup));
};

/**
 * Create a VVG basket entry by given tariffId
 *
 * @param {string} tariffId
 * @param {object} apiVvgTariffs
 * @param {string} section 'withMivita' or 'withoutMivita'
 * @returns {object|null}
 */
export const createBasketEntryByTariffId = (tariffId, apiVvgTariffs, section) => {
    if (!tariffId || !apiVvgTariffs) {
        return;
    }

    const { tariffs: apiTariffs, products: apiProducts } = apiVvgTariffs;
    const apiTariff = apiTariffs.find(apiTariff => apiTariff.id === tariffId);

    if (!apiTariff) {
        log.error('Could not find tariff with id', tariffId);
        return;
    }
    const { parent: productId, ...otherTariffData } = apiTariff;
    const apiProduct = apiProducts.find(apiProduct => apiProduct.id === productId);

    if (!apiProduct) {
        log.error('Could not find product with id', productId);
        return;
    }

    return {
        section,
        type: 'VVG',
        tariff: tariffId,
        product: productId,
        productGroup: apiProduct.productGroup,
        ...otherTariffData,
    };
};

export const getVvgProductsTrackingData = (apiData, products, selectedProducts, personId, persons, submission) => {
    return products.map(product => {
        return {
            title: getProductTrackingTitle({ type: 'vvg', productGroup: product.productGroup, product: product.id }),
            selected: Array.isArray(selectedProducts)
                ? selectedProducts.some(selected => selected.product === product.id)
                : false,
            price: findTariff(product.id, apiData, { personId, persons, submission })?.price,
        };
    });
};

export const getVvgProductsForSectionTrackingData = (
    apiData,
    products,
    sectionId,
    selectedProducts,
    personId,
    persons,
    submission
) => {
    let items = [];
    const productsOfGroupId = findProductsOfGroupBySection(sectionId);

    if (productsOfGroupId && typeof productsOfGroupId !== 'undefined') {
        items = getVvgProductsForProductGroupsTrackingData(
            apiData,
            products,
            [productsOfGroupId],
            selectedProducts,
            personId,
            persons,
            submission
        );
    }
    return items;
};

export const getVvgProductsForProductGroupsTrackingData = (
    apiData,
    products,
    productGroupIds,
    selectedProducts,
    personId,
    persons,
    submission
) => {
    const groupByTitle = function (productsData) {
        return productsData.reduce((grouped, product) => {
            const groupedProducts = grouped[product.title] || [];
            groupedProducts.push(product);
            grouped[product.title] = groupedProducts;
            return grouped;
        }, {});
    };

    const selectFromGroups = function (groupedProducts) {
        return Object.values(groupedProducts).map(products => {
            const selectedProduct = products.find(product => product.selected);
            return selectedProduct || products[0];
        });
    };

    // Step 1: Map the products
    const productsData = findProductsOfProductGroups(products, productGroupIds).map(product => {
        return {
            title: getProductTrackingTitle({ type: 'vvg', productGroup: product.productGroup, product: product.id }),
            selected: Array.isArray(selectedProducts)
                ? selectedProducts.some(selected => selected.product === product.id)
                : false,
            price: findTariff(product.id, apiData, { personId, persons, submission }).price,
        };
    });

    // Step 2: Group the products by title
    const groupedProducts = groupByTitle(productsData);

    // Step 3: Select the product with selected=true or take the first product if there's no selected one for each group
    // Note: This is just to make sure, that if there are multiple products with the same title, the selected one is taken
    return selectFromGroups(groupedProducts);
};
