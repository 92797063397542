import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpBackend from 'i18next-http-backend';
import config from '@src/config';
import { log } from '@src/middleware';
import { localizeUrl } from '@src/util/staticUrl';
import resourcesToBackend from 'i18next-resources-to-backend';
import ChainedBackend from 'i18next-chained-backend';
import { default as de } from '@src/assets/locales/de.json';
import { default as fr } from '@src/assets/locales/fr.json';
import { default as it } from '@src/assets/locales/it.json';
import { default as en } from '@src/assets/locales/en.json';

const { basename, dev, env, hosts } = config;

// Initial i18nConfig definition
const i18nConfig = {
    debug: dev,
    fallbackLng: 'de',
    supportedLngs: ['de', 'fr', 'it', 'en'], // Define supported languages here
    interpolation: { escapeValue: false },
    // Backend configuration will be defined later
    detection: {
        order: ['querystringWithRedirect', 'domain', 'localStorage', 'navigator'],
        caches: ['localStorage'],
    },
    react: { useSuspense: true },
};

const localeData = {
    de,
    fr,
    it,
    en,
};

async function loadFallbackLocales() {
    const locales = i18nConfig.supportedLngs; // Use supported languages from config
    const fallbacks = {};

    for (const locale of locales) {
        if (localeData[locale]) {
            fallbacks[locale] = localeData[locale];
        } else {
            log.debug(`Fallback file for ${locale} not found:`);
            fallbacks[locale] = {};
        }
    }

    return fallbacks;
}

async function configureI18n() {
    const fallbackLocales = await loadFallbackLocales();

    // Dynamically create bundledResources based on fallbackLocales
    const bundledResources = {};
    i18nConfig.supportedLngs.forEach(lng => {
        bundledResources[lng] = { translation: fallbackLocales[lng] };
    });

    // Now dynamically set the backend configuration
    const skipHttpBackend = process.env.SKIP_HTTP_BACKEND === 'true';
    i18nConfig.backend = {
        backends: skipHttpBackend
            ? [resourcesToBackend(bundledResources)]
            : [HttpBackend, resourcesToBackend(bundledResources)],
        backendOptions: [{ loadPath: `${basename}/premcalc-locales/{{lng}}.json` }],
    };

    const languageDetector = new LanguageDetector();

    languageDetector.addDetector({
        name: 'querystringWithRedirect',
        lookup: function lookup() {
            const url = new URL(window.location.href);
            const languageUrlParam = url.searchParams.get('lng') || undefined;
            const excludedLanguages = ['cimode', 'en'];

            if (languageUrlParam && !excludedLanguages.includes(languageUrlParam)) {
                const redirectUrl = localizeUrl(languageUrlParam);

                // Redirect to the localized domain, if it's not the same as the current domain
                if (redirectUrl && !url.href.startsWith(redirectUrl)) {
                    window.location.replace(redirectUrl);
                }
            }

            return languageUrlParam;
        },
    });

    languageDetector.addDetector({
        name: 'domain',
        lookup() {
            const host = window.location.host;
            let match;
            let found;

            if (host && hosts[env]) {
                match = hosts[env].find(langHosts => Object.values(langHosts).includes(host));

                if (match) {
                    found = Object.keys(match).find(key => match[key] === host);
                }
            }

            return found;
        },
    });

    i18n.use(initReactI18next).use(ChainedBackend).use(languageDetector);

    await i18n.init(i18nConfig);
    log.info('i18n successfully initialized.');
}

// Initiate the configuration process
configureI18n().catch(err => {
    log.error('Failed to initialize i18n.', err);
});

export const changeLanguage = (language, callback) => {
    i18n.changeLanguage(language, callback);
};

export default i18n;
