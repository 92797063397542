import { updatedEntities, denormalisedEntities } from '@src/util/data';
// ================ Action types ================ //

export const ADD_PREMCALC_ENTITIES = 'app/premcalcData/ADD_PREMCALC_ENTITIES';

// ================ Reducer ================ //

const initialState = {
    // store all fetched entities
    entities: {},
};

const merge = (state, endpointResponse) => {
    return {
        ...state,
        entities: updatedEntities({ ...state.entities }, endpointResponse),
    };
};

export default function premcalcDataReducer(state = initialState, action = {}) {
    const { type, payload } = action;
    if (type === ADD_PREMCALC_ENTITIES) {
        return merge(state, payload);
    } else {
        return state;
    }
}

// ================ Selectors ================ //

export const getPremcalcEntities = (state, entityRefs) => {
    const { entities } = state.premcalcData;
    const throwIfNotFound = false;
    return denormalisedEntities(entities, entityRefs, throwIfNotFound);
};

// ================ Action creators ================ //

export const addAppEntities = endpointResponse => ({
    type: ADD_PREMCALC_ENTITIES,
    payload: endpointResponse,
});
