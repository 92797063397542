import styled from '@emotion/styled';
import { rem } from 'polished';
import { H1, P1 } from 'atupri-component-library/lib/primitives';
import { mixins } from 'atupri-component-library/lib/core/styles/theme/default';

export const StyledWizardHeader = styled.div`
    ${mixins.responsiveStyles({
        padding: { zero: `0 ${rem(8)}`, medium: `0 ${rem(8)}`, wide: `0 ${rem(32)}` },
    })}
`;

export const StyledH1 = styled(H1)`
    padding-bottom: ${rem(32)};
`;

export const StyledP1 = styled(P1)`
    max-width: ${rem(880)};
    padding-bottom: ${rem(64)};
`;
