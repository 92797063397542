import i18next from 'i18next';
import { formatAmount } from '@src/util/currency';
import { getProductIdentifier } from '@src/util/tariff';
import { calculatePriceWithTax } from '@src/util/tariff/okp';

export function calcTotalPrice(basket) {
    let total = 0.0;
    if (basket) {
        for (const personId in basket) {
            total += calcPersonPrice(basket[personId]);
        }
    }
    return total;
}

export const calcPersonPrice = personBasket => {
    let total = 0.0;

    personBasket = {
        okp: personBasket?.okp || [],
        vvg: personBasket?.vvg || [],
    };
    personBasket.okp.map(product => {
        if (product.price) {
            total += calculatePriceWithTax(product);
        }
    });
    personBasket.vvg.map(product => {
        if (product.price) {
            total += product.price;
        }
    });

    return total;
};

/**
 * This method allows to search for specific product properties inside the whole basket
 *
 * @param {*} basket the whole product basket
 * @param {array} identifiers array of identifier strings (see: @src/util/tariff/okp or @src/util/tariff/vvg for several identifiers)
 * @param {*} property name of the property which should compared to ('product' by default)
 * @param {array} filterIdentifiers array of identifier strings to be excluded (see: @src/util/tariff/okp or @src/util/tariff/vvg for several identifiers)
 * @param {*} filterProperty name of the property which filterIdentifiers should be compared to ('product' by default)
 * @returns
 */
export const checkForProductsInBasket = (
    basket,
    identifiers,
    property = 'product',
    filterIdentifiers = null,
    filterProperty = 'product'
) => {
    let match = false;

    for (const personId in basket) {
        match = checkForProductsInPersonBasket(
            basket,
            personId,
            identifiers,
            property,
            filterIdentifiers,
            filterProperty
        );

        if (match) {
            break;
        }
    }
    return match;
};

/**
 * This method allows to search for specific product properties inside the basket of a specific person
 *
 * @param {*} basket the whole product basket
 * @param {string} personId the personId
 * @param {array} identifiers array of identifier strings (see: @src/util/tariff/okp or @src/util/tariff/vvg for several identifiers)
 * @param {*} property name of the property which should compared to ('product' by default)
 * @param {array} filterIdentifiers array of identifier strings to be excluded (see: @src/util/tariff/okp or @src/util/tariff/vvg for several identifiers)
 * @param {*} filterProperty name of the property which filterIdentifiers should be compared to ('product' by default)
 * @returns
 */
export const checkForProductsInPersonBasket = (
    basket,
    personId,
    identifiers,
    property = 'product',
    filterIdentifiers = null,
    filterProperty = 'product'
) => {
    let match = false;
    const personBasket = basket?.[personId] || { okp: [], vvg: [] };

    for (const type of ['okp', 'vvg']) {
        match = !!personBasket[type].find(product => {
            return !!identifiers.find(identifier => {
                let m = false;
                let value = product?.[property];
                if (value) {
                    value = property === 'product' ? getProductIdentifier(value, type === 'okp') : value;
                    m = value === identifier;

                    if (m && filterIdentifiers) {
                        if (product[filterProperty] && filterIdentifiers.includes(product[filterProperty])) {
                            m = false;
                        }
                    }
                }
                return m;
            });
        });

        if (match) {
            break;
        }
    }

    return match;
};

/**
 * Validates the whole basket, or the basket of a specific person, if the personId was given.
 *
 * @param {*} basket
 * @param {*} personId
 * @returns
 */
export const validateBasket = (basket, personId = null) => {
    let validation = {
        valid: true,
        errors: {},
    };

    for (const currentPersonId in basket) {
        if (!personId || personId === currentPersonId) {
            const personErrors = validatePersonBasket(basket, currentPersonId);
            if (personErrors.length > 0) {
                validation.valid = false;
                validation.errors = { ...validation.errors, [currentPersonId]: personErrors };
            }
        }
    }

    return validation;
};

export const validatePersonBasket = (basket, personId) => {
    let personErrors = [];
    // A) At least one product has to be selected for current person
    if (basket[personId].okp.length === 0 && basket[personId].vvg.length === 0) {
        personErrors.push('missingProductChoice');
    }
    // B) If an OKP product requires a gatekeepers choice, make sure a choice was made
    for (const okpProduct of basket[personId].okp) {
        if (okpProduct.gatekeeper === true && (!okpProduct.zsr || okpProduct.zsr === '')) {
            personErrors.push('missingGatekeeperChoice');
        }
    }
    return personErrors;
};

export const getProductTitle = item => {
    if (!item) return '';

    const { type, productGroup, product } = item;
    const namePrefix = type ? type.toLowerCase() : 'okp';
    const productIdentifier = getProductIdentifier(product, false);
    let title;
    let text = '';
    let additionalText = '';

    if (namePrefix === 'vvg') {
        if (i18next.exists(`ProductForm.${namePrefix}.productGroup.${productGroup}.title`)) {
            text = i18next.t(`ProductForm.${namePrefix}.productGroup.${productGroup}.title`);
        }
        if (i18next.exists(`ProductForm.${namePrefix}.product.${productIdentifier}.title`)) {
            additionalText = i18next.t(`ProductForm.${namePrefix}.product.${productIdentifier}.title`);
        }
        title = text;
        title += title ? ` - ${additionalText}` : additionalText;
    } else {
        title = i18next.t(`ProductForm.${namePrefix}.product.${productIdentifier}.title`);
    }

    return title;
};

export const getProductTrackingTitle = item => {
    if (!item) return '';

    const { type, productGroup, product } = item;
    const namePrefix = type ? type.toLowerCase() : 'okp';
    const productIdentifier = getProductIdentifier(product, false);

    if (namePrefix === 'vvg') {
        if (i18next.exists(`ProductForm.${namePrefix}.productGroup.${productGroup}.tracking`)) {
            return i18next.getFixedT('de')(`ProductForm.${namePrefix}.productGroup.${productGroup}.tracking`);
        } else {
            return i18next.getFixedT('de')(`ProductForm.${namePrefix}.product.${productIdentifier}.tracking`);
        }
    } else {
        // OKP product titles match tracking titles
        return i18next.getFixedT('de')(`ProductForm.${namePrefix}.product.${productIdentifier}.title`);
    }
};

export const getProductDescription = item => {
    if (!item) return '';

    const { type, familyRebate, combinationRebate } = item;
    const namePrefix = type ? type.toLowerCase() : 'okp';
    let description = [];

    if (namePrefix === 'vvg') {
        if (familyRebate) {
            description.push(i18next.t(`ProductForm.${namePrefix}.discount.familyRebate`));
        }

        if (combinationRebate) {
            description.push(i18next.t(`ProductForm.${namePrefix}.discount.combinationRebate`));
        }
    } else {
        description.push(
            i18next.t(`ProductBasketPage.franchiseText.text`, {
                franchise: formatAmount(item.franchise),
                accidentIncludedText: i18next.t(`ProductBasketPage.franchiseText.accident.${item.accidentIncluded}`),
            })
        );
    }

    return description.join(', ');
};

export const getLowestTariffYearFromBasket = basket => {
    const okpTariffYear = basket?.okp[0]?.year;
    const vvgTariffYear = basket?.vvg[0]?.year;

    // Filtering out non-numbers, and get the minimum value of all array values...
    let tariffYear = Math.min(...[okpTariffYear, vvgTariffYear].filter(Number));

    // as Math.min() returns 'Infinity', if no argument is given, we check for isFinite() in order to return null in that case...
    tariffYear = Number.isFinite(tariffYear) ? tariffYear : null;

    return tariffYear;
};
