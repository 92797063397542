import styled from '@emotion/styled';
import { rem } from 'polished';
import { colors, spaces } from 'atupri-component-library/lib/core/styles/theme/default';
import { EmphasizedToggle, P4 } from 'atupri-component-library/lib/primitives';
import { StyledPromotionBadge } from '@src/forms/ProductForm/ProductForm.style';

export const StyledEmphasizedToggle = styled(EmphasizedToggle)`
    input + div {
        position: relative;
        padding-right: ${rem(88)}; // 64px Badge width + 12px padding (left / right)
    }

    input:disabled + div {
        opacity: 0.5;
    }
`;

export const StyledPracticeItemWrapper = styled.div`
    > div {
        width: 100%;
    }
`;

export const StyledPracticeItem = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const StyledAddress = styled(P4)`
    line-height: ${rem(24)}; // overwrite typography
    color: ${colors.grey700};
`;

export const StyledNoRegistration = styled.div`
    color: ${colors.secondary};
    margin-top: ${rem(spaces.beta)};
`;

export const StyledBadgeDesc = styled.div(({ multiple }) => {
    return `
  font-size: ${rem(multiple ? 10 : 12)};
  line-height: 2;
  color: ${colors.primary};
  `;
});

export const StyledBadgeWrap = styled.div`
    position: absolute;
    width: ${rem(64)};
    top: ${rem(spaces.gamma)};
    right: ${rem(spaces.gamma)};
    text-align: center;
    display: flex;
    flex-direction: column;
`;

export const StyledPracticeItemPromotionBadge = styled(StyledPromotionBadge)`
    bottom: 100%;
`;
