import React, { useContext, useEffect, useRef } from 'react';
import { bool, func, string } from 'prop-types';
import queryString from 'query-string';
import i18next from 'i18next';
import classNames from 'classnames';

import { ConfigContext } from '@src/PremiumcalculatorLibraryProvider';
import { log } from '@src/middleware';

// config
import { environmentConfiguration, environments } from '@src/config/env';
import { localizationConfiguration } from '@src/config/localization';

// api
import { API_GET_OKP_TARIFFS, API_GET_UTI_TARIFFS, API_GET_VVG_TARIFFS } from '@src/api/tariff.api';
import { API_CREATE_SUBMISSION } from '@src/api/submission.api';

// components
import { Page } from '@src/components';
import { InfoText } from '@atupri/atupri-component-library';

// forms
import { QuickCalculatorForm } from '@src/forms';

// hooks
import { useErrorQueue } from '@src/hooks/errorQueue';

// util
import { getAutosaved, setAutosaved } from '@src/util/autosave';
import { deepMerge } from '@src/util/data';
import { getInsuranceStartDate } from '@src/util/date';
import { uuidv4 } from '@src/util/uuid';

const onDataProvided = (productIds, data, baseUrlConfig, isLoading = null) => {
    isLoading?.(true);

    const personId = uuidv4();
    const persons = [
        {
            id: personId,
            birthdate: data.birthdate,
            gender: data.gender,
            zip: data.zip.zip,
            place: data.zip.place,
            municipalityId: data.zip.municipalityId,
            language: i18next.language || 'de',
            insuranceStart: getInsuranceStartDate(),
        },
    ];

    const okpPromise =
        productIds.okp.length > 0
            ? API_GET_OKP_TARIFFS(personId, persons, undefined, baseUrlConfig?.esbBaseUrl)
            : Promise.resolve({ okp: undefined });
    const vvgPromise =
        productIds.vvg.length > 0
            ? API_GET_VVG_TARIFFS(personId, persons, data?.firstOkp || 'NONE', undefined, baseUrlConfig?.esbBaseUrl)
            : Promise.resolve({ vvg: undefined });
    const utiPromise =
        productIds.uti.length > 0
            ? API_GET_UTI_TARIFFS(personId, persons, undefined, baseUrlConfig?.apiBaseUrl)
            : Promise.resolve({ uti: undefined });

    return Promise.all([okpPromise, vvgPromise, utiPromise])
        .then(([okpTariffs, vvgTariffs, utiTariffs]) => {
            return {
                okp: okpTariffs.okp,
                vvg: vvgTariffs.vvg,
                uti: utiTariffs.uti,
            };
        })
        .finally(() => {
            isLoading?.(false);
        });
};

const onSubmit = (values, baseUrl) => {
    const personId = uuidv4();
    const persons = [
        {
            id: personId,
            birthdate: values.birthdate,
            gender: values.gender,
            zip: values.zip.zip,
            zipText: values.zip.zipText,
            place: values.zip.place,
            municipalityId: values.zip.municipalityId,
            municipalityInfo: values.zip.municipalityInfo,
            language: i18next.language || 'de',
            insuranceStart: getInsuranceStartDate(),
            okpPreselectedProduct: values.okpProductId || '',
            vvgPreselectedProducts: values.vvgProductId?.length > 0 ? values.vvgProductId.join(',') : '',
        },
    ];

    const basket = {};
    basket[personId] = {
        okp: values?.okpTariff ? [values?.okpTariff].filter(Boolean) : [],
        vvg: values?.vvgTariff?.filter(Boolean) ?? [],
    };

    const data = {
        persons: persons,
        basket: basket,
    };

    return API_CREATE_SUBMISSION(
        {
            okpEnabled: Boolean(values?.okpTariff || values.okpProductId),
            vvgEnabled: Boolean(values?.vvgTariff || values.vvgProductId?.length > 0),
        },
        data,
        baseUrl
    );
};

const mergeAutosavedValues = defaultValues => {
    let values = defaultValues;
    const autosaved = getAutosaved('_QUICKCALC', 'quickCalculatorForm');

    if (autosaved?.values) {
        if (defaultValues) {
            values = deepMerge([values, autosaved.values]);
        } else {
            values = autosaved.values;
        }
    }

    return values;
};

const splitAndLowerCase = str => {
    return str ? str.toLowerCase().split(',').filter(Boolean) : [];
};
const getProductIds = (okpProducts = null, vvgProducts = null) => {
    const queryParams = queryString.parse(window.location.search);
    const okpProductIds = splitAndLowerCase(queryParams?.okp ?? okpProducts ?? '');
    const vvgProductIds = splitAndLowerCase(queryParams?.vvg ?? vvgProducts ?? '');

    return {
        okp: okpProductIds,
        vvg: vvgProductIds,
        uti: vvgProductIds.filter(v => v.startsWith('uti')),
    };
};

export const QuickCalculatorPageComponent = ({
    isStandalone = false,
    okpProducts = null,
    vvgProducts = null,
    onResults = null,
    onFirstLoad = null,
    isLoading = null,
    children = null,
}) => {
    const { errors, clearErrors } = useErrorQueue();
    const newWindowReference = useRef(null);

    const productIds = getProductIds(okpProducts, vvgProducts);
    const firstOkp = productIds?.okp?.length > 0 ? productIds.okp[0].toUpperCase() : null;
    const libraryConfig = useContext(ConfigContext);

    useEffect(() => {
        if (!isStandalone) {
            import('@atupri/atupri-component-library/styles').then(module => {
                log.debug('Module loaded:', module);
            });
        }
    }, [isStandalone]);

    const initialValues = (() => {
        let defaultValues = {
            birthdate: '',
            gender: '',
            zip: {},
            category: '',
            variant: '',
        };

        // check for autosaved values
        return mergeAutosavedValues(defaultValues);
    })();

    const renderError = (error, index) => {
        return (
            <InfoText
                key={index}
                type="primary"
                variant={libraryConfig?.inverted ? 'inverted' : 'default'}
                status="warning"
                title={error.message}
                onClose={clearErrors}
                closable
            />
        );
    };

    const redirectToPremiumCalculator = submissionId => {
        const { env } = environmentConfiguration;
        const { hosts } = localizationConfiguration;
        const protocol = env === environments.local ? 'http' : 'https';

        const urlWithParams = new URL(libraryConfig?.prBaseUrl || `${protocol}://${hosts[env][0][i18next.language]}`);
        urlWithParams.searchParams.append('id', submissionId);

        if (newWindowReference.current) {
            newWindowReference.current.location = urlWithParams;
            newWindowReference.current.focus();
        } else {
            log.warn('Missing newWindowReference. Window possibly still blocked');
        }
    };

    const render = () => {
        const containerClasses = classNames('h-full', 'w-full', 'flex', 'flex-col', 'gap-6', 'lg:gap-8', {
            'bg-background-accent-blue-bold': libraryConfig?.inverted,
            'bg-background-default': !libraryConfig?.inverted,
        });
        const titleClasses = classNames('font-Arvo', 'text-2xl', 'w-full', {
            'text-text-inverted': libraryConfig?.inverted,
        });

        const mainContent = (
            <div className={containerClasses}>
                <div className="flex">
                    <div className="relative">
                        <div
                            className="mr-3 mt-1.5 aspect-square h-4 w-4 bg-icon-accent-red lg:absolute lg:-left-6 lg:-translate-x-full"
                            role="presentation"
                        ></div>
                    </div>
                    <div className="mr-6 flex grow flex-col gap-y-1.5 md:gap-y-2.5 xl:gap-y-5">
                        <h2 className={titleClasses}>{i18next.t('QuickCalculatorPage.title')}</h2>
                    </div>
                </div>
                {errors && <div className="w-full">{errors?.map(renderError)}</div>}
                <QuickCalculatorForm
                    className="form"
                    initialValues={initialValues}
                    onDataProvided={data => {
                        return onDataProvided(productIds, { firstOkp, ...data }, libraryConfig, isLoading);
                    }}
                    onSubmit={values => {
                        onSubmit(values, libraryConfig?.apiBaseUrl).then(submission => {
                            redirectToPremiumCalculator(submission.data.id.uuid);
                        });
                    }}
                    autosave={(values, isFormInvalid) => {
                        setAutosaved('_QUICKCALC', 'quickCalculatorForm', isFormInvalid, values);
                    }}
                    products={productIds}
                    newWindowReference={newWindowReference}
                    shouldRenderProducts={onResults == null && children == null}
                    onResults={onResults}
                    onFirstLoad={onFirstLoad}
                    childrenToRender={children}
                    inverted={libraryConfig?.inverted}
                />
            </div>
        );

        return isStandalone ? (
            mainContent
        ) : (
            <Page>
                <div className="w-full p-5 grid place-content-center">
                    <div className="w-full md:max-w-[504px]">{mainContent}</div>
                </div>
            </Page>
        );
    };

    return render();
};

QuickCalculatorPageComponent.propTypes = {
    isStandalone: bool,
    okpProducts: string,
    vvgProducts: string,
    onResults: func,
    onFirstLoad: func,
    isLoading: func,
    children: func,
};

const QuickCalculatorPage = props => <QuickCalculatorPageComponent {...props} />;
export default QuickCalculatorPage;

export const QuickCalculatorPageStandalone = props => <QuickCalculatorPageComponent {...props} isStandalone />;
