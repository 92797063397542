import styled from '@emotion/styled';
import { rem } from 'polished';

export const StyledMapView = styled.div`
    flex: 1 1 auto;
    min-height: ${rem(300)};
`;

export const mapStyle = {
    width: '100%',
    height: '100%',
};
