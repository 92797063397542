import React from 'react';
import i18next from 'i18next';
import { array, bool, func, object, oneOf, shape, string } from 'prop-types';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import { useNavigate } from 'react-router-dom';

import { log } from '@src/middleware';
import routeConfiguration from '@src/routeConfiguration';
import { ensureSubmission } from '@src/util/data';
import { createResourceLocatorString } from '@src/util/routes';
import { isInternalProcess } from '@src/util/process';
import {
    EditSubmissionProductPanel,
    EditSubmissionCockpitPanel,
    EditSubmissionPaymentPanel,
    EditSubmissionConfirmationPanel,
} from '@src/components';
import { getNextClickTrackingData } from '@src/util/analytics';

export const PRODUCT = 'product';
export const COCKPIT = 'cockpit';
export const PAYMENT = 'payment';
export const CONFIRMATION = 'confirmation';
export const RECOMMEND = 'recommend';

// EditSubmissionWizardTab component supports these tabs
export const SUPPORTED_TABS = [PRODUCT, COCKPIT, PAYMENT, CONFIRMATION];

const pathParamsToNextTab = (params, tab, premcalcTabs) => {
    const nextTabIndex = premcalcTabs.findIndex(s => s === tab) + 1;
    const nextTab =
        nextTabIndex < premcalcTabs.length ? premcalcTabs[nextTabIndex] : premcalcTabs[premcalcTabs.length - 1];
    return { ...params, tab: nextTab };
};

// When user has update offer submission, he should be redirected to next EditSubmissionWizardTab
const redirectAfterOfferUpdate = (params, tab, premcalcTabs, navigate) => {
    const currentPathParams = {
        ...params,
    };
    const routes = routeConfiguration();

    // Redirect to next tab
    const nextPathParams = pathParamsToNextTab(currentPathParams, tab, premcalcTabs);
    const to = createResourceLocatorString('EditSubmissionPage', routes, nextPathParams, {});
    navigate(to);
};

const EditSubmissionWizardTab = props => {
    let {
        tab,
        premcalcTabs,
        params,
        errors,
        fetchInProgress,
        newSubmissionPublished,
        submission,
        handlePublishSubmission,
        onUpdateSubmission,
        onChange,
        onProductSelectionChange,
        onProductTypeChange,
        updatedTab,
        updateInProgress,
        tariffs,
        effectiveDates,
        currentProducts,
    } = props;

    const currentSubmission = ensureSubmission(submission);
    const { persons } = currentSubmission.relationships;

    const navigate = useNavigate();
    const sendDataToGTM = useGTMDispatch();

    const onCompleteEditSubmissionWizardTab = (updateValues, passThrownErrors = false) => {
        // normalize values
        const { ...otherValues } = updateValues;
        const updateNormValues = { ...otherValues };

        sendDataToGTM(getNextClickTrackingData());

        return handleNewSubmissionFlow(updateValues, updateNormValues, passThrownErrors);
    };

    const getUpsertValues = updateNormValues => {
        let upsertValues = { ...updateNormValues, id: currentSubmission.id };
        if (tab === 'product') {
            // basket state is updated on selection change, no need to merge it again. just trigger api update
            upsertValues = { id: currentSubmission.id };
        }
        return upsertValues;
    };

    const handleNewSubmissionFlow = (updateValues, updateNormValues, passThrownErrors) => {
        if (tab === premcalcTabs[premcalcTabs.length - 1]) {
            return handlePublishSubmission(currentSubmission.id, updateValues);
        } else {
            const upsertValues = getUpsertValues(updateNormValues);

            return onUpdateSubmission(tab, upsertValues)
                .then(() => {
                    if (tab !== premcalcTabs[premcalcTabs.length - 1]) {
                        // redirect to next tab
                        redirectAfterOfferUpdate(params, tab, premcalcTabs, navigate);
                    }
                })
                .catch(e => {
                    if (passThrownErrors) {
                        throw e;
                    }
                });
        }
    };

    const panelProps = selectedTab => {
        return {
            className: 'panel',
            errors,
            submission,
            onChange,
            panelUpdated: updatedTab === selectedTab,
            updateInProgress,
            ready: newSubmissionPublished,
            fetchInProgress: fetchInProgress,
            disabled: fetchInProgress,
            effectiveDates,
            currentProducts,
        };
    };

    const isInternal = isInternalProcess(persons, currentProducts);
    switch (tab) {
        case PRODUCT: {
            return (
                (tariffs?.okp || tariffs?.vvg) && (
                    <EditSubmissionProductPanel
                        {...panelProps(PRODUCT)}
                        tariffs={tariffs}
                        submitButtonText={i18next.t('EditSubmissionWizard.saveProduct')}
                        backActionMsg={
                            isInternal
                                ? i18next.t('EditSubmissionWizard.backInternal')
                                : i18next.t('EditSubmissionWizard.back')
                        }
                        onProductSelectionChange={onProductSelectionChange}
                        onProductTypeChange={onProductTypeChange}
                        onSubmit={values => {
                            log.debug('submit products', values);
                            onCompleteEditSubmissionWizardTab(values);
                        }}
                    />
                )
            );
        }
        case COCKPIT: {
            return (
                <EditSubmissionCockpitPanel
                    {...panelProps(COCKPIT)}
                    submitButtonText={i18next.t('EditSubmissionWizard.saveCockpit')}
                    onSubmit={values => {
                        onCompleteEditSubmissionWizardTab(values);
                    }}
                />
            );
        }
        case PAYMENT: {
            return (
                <EditSubmissionPaymentPanel
                    {...panelProps(PAYMENT)}
                    submitButtonText={i18next.t('EditSubmissionWizard.savePayment')}
                    backButtonText={i18next.t('EditSubmissionWizard.backPayment')}
                    onSubmit={values => {
                        onCompleteEditSubmissionWizardTab(values);
                    }}
                />
            );
        }
        case CONFIRMATION: {
            return (
                <EditSubmissionConfirmationPanel
                    {...panelProps(CONFIRMATION)}
                    onSubmit={values => {
                        onCompleteEditSubmissionWizardTab(values);
                    }}
                />
            );
        }

        default:
            return null;
    }
};

EditSubmissionWizardTab.defaultProps = {
    submission: null,
    updatedTab: null,
};

EditSubmissionWizardTab.propTypes = {
    params: shape({
        tab: oneOf(SUPPORTED_TABS).isRequired,
    }).isRequired,
    errors: shape({
        createSubmissionOfferError: object,
        publishSubmissionError: object,
        updateSubmissionError: object,
        showSubmissionError: object,
        uploadImageError: object,
        fetchExceptionsError: object,
        addExceptionError: object,
        deleteExceptionError: object,
    }).isRequired,
    newSubmissionPublished: bool.isRequired,

    // We cannot use propTypes.submission since the submission might be an offer.
    submission: shape({
        attributes: shape({
            privateData: object,
            description: string,
            geolocation: object,
            pricing: object,
            title: string,
        }),
        images: array,
    }),
    handlePublishSubmission: func.isRequired,
    onUpdateSubmission: func.isRequired,
    onChange: func.isRequired,
    updatedTab: string,
    updateInProgress: bool.isRequired,
    tariffs: object,
    effectiveDates: array,
    currentProducts: array,
};

export default EditSubmissionWizardTab;
