import axios from 'axios';
import moment from 'moment';
import config from '@src/config';
import { log } from '@src/middleware';

const COMMUNITIES_API = `${config.apiEsbBase}/communities/v1`;

/**
 * @param {string} zip
 * @param {number} year
 * @param {string | null} apiBaseUrl
 */
export const API_GET_LOCATIONS = (zip, year = moment().year(), apiBaseUrl = null) =>
    new Promise((resolve, reject) => {
        const apiUrl = apiBaseUrl ? `${apiBaseUrl}/communities/v1` : COMMUNITIES_API;
        const url = `${apiUrl}?zip=${zip}&mode=1&year=${year}`;

        log.info('=== Zip:API_GET_LOCATIONS ===', url);

        axios
            .get(url, {
                timeout: 60000,
            })
            .then(response => {
                const data = response?.data;
                if (typeof data !== 'string') {
                    let res = {
                        status: 200,
                        statusText: '',
                        data: data,
                    };

                    resolve(res);
                } else {
                    log.error('Error getting state from API: ', data);
                    reject({ data: 'Error.code', code: '60' });
                }
            })
            .catch(e => {
                reject({ data: 'Error.API', code: '61' });
            });
    });
