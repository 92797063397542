import { log } from '@src/middleware';
import { addAppEntities } from '@src/ducks/premcalcData.duck';

import {
    API_GET_SUBMISSION,
    API_CREATE_SUBMISSION,
    API_UPDATE_SUBMISSION,
    API_PUBLISH_SUBMISSION,
    API_CLEAR_SUBMISSION,
} from '@src/api/submission.api';

import { clearRedirect } from '@src/ducks/healthQuestions.duck';
import { afterInitialDataLoaded } from '@src/ducks/apiData.duck';

// util
import { clearSubmissionId, getSubmissionId } from '@src/util/submission';
import { downloadFile } from '@src/util/file';
import { setInitialDataLoaded } from '@src/ducks/UI.duck';

const requestAction = actionType => params => ({ type: actionType, payload: { params } });

const successAction = actionType => result => ({ type: actionType, payload: result });

const errorAction = actionType => error => ({ type: actionType, payload: error, error: true });

// ================ Action types ================ //

export const MARK_TAB_UPDATED = 'app/EditSubmissionPage/MARK_TAB_UPDATED';
export const MARK_SUBMISSION_CONFIRMED = 'app/EditSubmissionPage/MARK_SUBMISSION_CONFIRMED';
export const CLEAR_UPDATED_TAB = 'app/EditSubmissionPage/CLEAR_UPDATED_TAB';
export const REDIRECT_TO_PRODUCTS_REQUEST = 'app/REDIRECT_TO_PRODUCTS_REQUEST';
export const UPDATE_BASKET_REQUEST = 'app/UPDATE_BASKET_REQUEST';

export const CREATE_SUBMISSION_OFFER_REQUEST = 'app/CREATE_SUBMISSION_OFFER_REQUEST';
export const CREATE_SUBMISSION_OFFER_SUCCESS = 'app/CREATE_SUBMISSION_OFFER_SUCCESS';
export const CREATE_SUBMISSION_OFFER_ERROR = 'app/CREATE_SUBMISSION_OFFER_ERROR';

export const PUBLISH_SUBMISSION_REQUEST = 'app/PUBLISH_SUBMISSION_REQUEST';
export const PUBLISH_SUBMISSION_SUCCESS = 'app/PUBLISH_SUBMISSION_SUCCESS';
export const PUBLISH_SUBMISSION_ERROR = 'app/PUBLISH_SUBMISSION_ERROR';

export const EXTEND_SUBMISSION_REQUEST = 'app/EXTEND_SUBMISSION_REQUEST';
export const EXTEND_SUBMISSION_SUCCESS = 'app/EXTEND_SUBMISSION_SUCCESS';
export const EXTEND_SUBMISSION_ERROR = 'app/EXTEND_SUBMISSION_ERROR';

export const CLEAR_SUBMISSION_REQUEST = 'app/CLEAR_SUBMISSION_REQUEST';
export const CLEAR_SUBMISSION_SUCCESS = 'app/CLEAR_SUBMISSION_SUCCESS';
export const CLEAR_SUBMISSION_ERROR = 'app/CLEAR_SUBMISSION_ERROR';

export const UPDATE_SUBMISSION_REQUEST = 'app/UPDATE_SUBMISSION_REQUEST';
export const UPDATE_SUBMISSION_SUCCESS = 'app/UPDATE_SUBMISSION_SUCCESS';
export const UPDATE_SUBMISSION_ERROR = 'app/UPDATE_SUBMISSION_ERROR';

export const SHOW_SUBMISSION_REQUEST = 'app/SHOW_SUBMISSION_REQUEST';
export const SHOW_SUBMISSION_SUCCESS = 'app/SHOW_SUBMISSION_SUCCESS';
export const SHOW_SUBMISSION_ERROR = 'app/SHOW_SUBMISSION_ERROR';

export const CLEAR_ERRORS_REQUEST = 'app/CLEAR_ERRORS_REQUEST';

// ================ Reducer ================ //

const initialState = {
    createSubmissionOfferError: null,
    publishingSubmission: null,
    publishSubmissionError: null,
    updateSubmissionError: null,
    showSubmissionError: null,
    createSubmissionOfferInProgress: false,
    currentSubmissionId: getSubmissionId(),
    fetchInProgress: false,
    redirectToSubmission: false,
    redirectToProducts: false,
    submissionConfirmed: false,
    updatedTab: null,
    updateInProgress: false,
    publishedSubmissionProps: null,
    clearInProgress: false,
    clearSubmissionError: null,
    clearedSubmissionId: null,
    extendSubmissionAgreement: false,
    extendInProgress: false,
    extendSubmissionError: null,
};

const EXTEND_SUBMISSION_CATEGORY = 'USER_SAVED';

export default function reducer(state = initialState, action = {}) {
    const { type, payload } = action;
    let publishedSubmissionProps = {};

    switch (type) {
        case MARK_TAB_UPDATED:
            return { ...state, updatedTab: payload };
        case CLEAR_UPDATED_TAB:
            return { ...state, updatedTab: null, updateSubmissionError: null };
        case MARK_SUBMISSION_CONFIRMED:
            return { ...state, submissionConfirmed: true };
        case CREATE_SUBMISSION_OFFER_REQUEST:
            return {
                ...state,
                createSubmissionOfferInProgress: true,
                createSubmissionOfferError: null,
                currentSubmissionId: null,
            };

        case CREATE_SUBMISSION_OFFER_SUCCESS:
            return {
                ...state,
                createSubmissionOfferInProgress: false,
                showSubmissionError: null,
                redirectToProducts: true,
                currentSubmissionId: payload.data.id,
            };
        case CREATE_SUBMISSION_OFFER_ERROR:
            return {
                ...state,
                createSubmissionOfferInProgress: false,
                createSubmissionOfferError: payload,
            };

        case PUBLISH_SUBMISSION_REQUEST:
            return {
                ...state,
                updateInProgress: true,
                publishingSubmission: payload.submissionId,
                publishSubmissionError: null,
            };
        case PUBLISH_SUBMISSION_SUCCESS:
            for (const inc of payload.included) {
                if (inc.type === 'ownSubmissionSuccessProps') {
                    publishedSubmissionProps = inc;
                }
            }

            return {
                ...state,
                redirectToSubmission: true,
                publishingSubmission: null,
                createSubmissionOfferError: null,
                updateSubmissionError: null,
                showSubmissionError: null,
                uploadImageError: null,
                createSubmissionOfferInProgress: false,
                currentSubmissionId: payload.data.id,
                updateInProgress: false,
                publishedSubmissionProps: publishedSubmissionProps.attributes,
            };
        case PUBLISH_SUBMISSION_ERROR: {
            log.error(payload);

            return {
                ...state,
                publishingSubmission: null,
                publishSubmissionError: {
                    submissionId: state.publishingSubmission,
                    error: payload,
                },
            };
        }

        case UPDATE_SUBMISSION_REQUEST:
            return {
                ...state,
                currentSubmissionId: null,
                updateInProgress: true,
                updateSubmissionError: null,
                updateSubmissionSuccess: false,
            };
        case UPDATE_SUBMISSION_SUCCESS:
            return {
                ...state,
                currentSubmissionId: payload.data.id,
                redirectToProducts: true,
                updateInProgress: false,
                updateSubmissionSuccess: true,
            };
        case UPDATE_SUBMISSION_ERROR:
            return {
                ...state,
                updateInProgress: false,
                updateSubmissionError: payload,
                updateSubmissionSuccess: false,
            };

        case SHOW_SUBMISSION_REQUEST:
            return {
                ...state,
                fetchInProgress: true,
                showSubmissionError: null,
                redirectToProducts: false,
                updateSubmissionSuccess: false,
            };
        case SHOW_SUBMISSION_SUCCESS:
            return {
                ...initialState,
                currentSubmissionId: payload.data.id,
                fetchTariffInProgress: state.fetchTariffInProgress,
                fetchOkpTariffInProgress: state.fetchOkpTariffInProgress,
                fetchVvgTariffInProgress: state.fetchVvgTariffInProgress,
                fetchOkpTariffError: state.fetchOkpTariffError,
                fetchVvgTariffError: state.fetchVvgTariffError,
            };
        case SHOW_SUBMISSION_ERROR:
            return { ...state, fetchInProgress: false, showSubmissionError: payload, redirectToSubmission: false };

        case CLEAR_SUBMISSION_REQUEST:
            return { ...state, clearInProgress: true, clearSubmissionError: null };
        case CLEAR_SUBMISSION_SUCCESS:
            return { ...state, clearedSubmissionId: payload, clearInProgress: false };
        case CLEAR_SUBMISSION_ERROR:
            return { ...state, clearInProgress: false, clearSubmissionError: payload };

        case EXTEND_SUBMISSION_REQUEST:
            return { ...state, extendInProgress: true, extendSubmissionError: null };
        case EXTEND_SUBMISSION_SUCCESS:
            return { ...state, extendSubmissionAgreement: true, extendInProgress: false };
        case EXTEND_SUBMISSION_ERROR:
            return {
                ...state,
                extendSubmissionAgreement: false,
                extendInProgress: false,
                extendSubmissionError: payload,
            };
        case REDIRECT_TO_PRODUCTS_REQUEST:
            return {
                ...state,
                redirectToProducts: true,
            };
        case CLEAR_ERRORS_REQUEST:
            return {
                ...state,
                showSubmissionError: null,
                extendSubmissionError: null,
                clearSubmissionError: null,
                updateSubmissionError: null,
            };
        default:
            return state;
    }
}

// ================ Action creators ================ //

export const markTabUpdated = tab => ({
    type: MARK_TAB_UPDATED,
    payload: tab,
});

export const clearUpdatedTab = () => ({
    type: CLEAR_UPDATED_TAB,
});

export const markSubmissionConfirmed = () => ({
    type: MARK_SUBMISSION_CONFIRMED,
});

// API method: ownSubmission.create
export const createSubmissionOffer = requestAction(CREATE_SUBMISSION_OFFER_REQUEST);
export const createSubmissionOfferSuccess = successAction(CREATE_SUBMISSION_OFFER_SUCCESS);
export const createSubmissionOfferError = errorAction(CREATE_SUBMISSION_OFFER_ERROR);

// API method: ownSubmission.publish
export const publishSubmission = requestAction(PUBLISH_SUBMISSION_REQUEST);
export const publishSubmissionSuccess = successAction(PUBLISH_SUBMISSION_SUCCESS);
export const publishSubmissionError = errorAction(PUBLISH_SUBMISSION_ERROR);

// API method: ownSubmission.clear
export const clearSubmission = requestAction(CLEAR_SUBMISSION_REQUEST);
export const clearSubmissionSuccess = successAction(CLEAR_SUBMISSION_SUCCESS);
export const clearSubmissionError = errorAction(CLEAR_SUBMISSION_ERROR);

// API method: ownSubmission.extend
export const extendSubmission = requestAction(EXTEND_SUBMISSION_REQUEST);
export const extendSubmissionSuccess = successAction(EXTEND_SUBMISSION_SUCCESS);
export const extendSubmissionError = errorAction(EXTEND_SUBMISSION_ERROR);

// API method: ownSubmission.update
export const updateSubmission = requestAction(UPDATE_SUBMISSION_REQUEST);
export const updateSubmissionSuccess = successAction(UPDATE_SUBMISSION_SUCCESS);
export const updateSubmissionError = errorAction(UPDATE_SUBMISSION_ERROR);

// API method: ownSubmission.show
export const showSubmission = requestAction(SHOW_SUBMISSION_REQUEST);
export const showSubmissionSuccess = successAction(SHOW_SUBMISSION_SUCCESS);
export const showSubmissionError = errorAction(SHOW_SUBMISSION_ERROR);

export const requestProductRedirect = requestAction(REDIRECT_TO_PRODUCTS_REQUEST);

export const clearErrors = requestAction(CLEAR_ERRORS_REQUEST);

export const faker = () => Promise.resolve({});

// ================ Thunk ================ //

export function requestShowSubmission(actionPayload) {
    return dispatch => {
        dispatch(clearRedirect());
        dispatch(showSubmission(actionPayload));

        return API_GET_SUBMISSION(actionPayload.id.uuid)
            .then(result => {
                dispatch(addAppEntities(result));
                dispatch(showSubmissionSuccess(result));
                return result;
            })
            .catch(e => {
                dispatch(showSubmissionError(e));
            });
    };
}

export function requestClearErrors() {
    return dispatch => {
        dispatch(clearErrors());

        return faker()
            .then(() => {
                return true;
            })
            .catch(e => {
                return dispatch(createSubmissionOfferError(e));
            });
    };
}

export function requestCreateSubmissionOffer(data) {
    return (dispatch, getState) => {
        const UIDefaults = getState().UI.defaults;
        dispatch(createSubmissionOffer(data));

        return API_CREATE_SUBMISSION(UIDefaults, data)
            .then(result => {
                dispatch(addAppEntities(result));
                dispatch(createSubmissionOfferSuccess(result));
                return result;
            })
            .catch(e => {
                return dispatch(createSubmissionOfferError(e));
            });
    };
}

export function requestUpdateSubmission(tab, data) {
    return (dispatch, getState) => {
        dispatch(updateSubmission(data));
        const { id } = data;
        const UIDefaults = getState().UI.defaults;

        return API_UPDATE_SUBMISSION(id.uuid, data, getState().premcalcData.entities.ownSubmission[id.uuid], UIDefaults)
            .then(response => {
                dispatch(addAppEntities(response));
                return response;
            })
            .then(response => {
                if (tab) {
                    dispatch(markTabUpdated(tab));
                }
                dispatch(updateSubmissionSuccess(response));
                return response;
            })
            .catch(e => {
                dispatch(updateSubmissionError(e));
                throw e;
            });
    };
}

export function requestMarkSubmissionConfirmed() {
    return dispatch => {
        dispatch(markSubmissionConfirmed());
    };
}

export function requestExtendSubmission(data) {
    return (dispatch, getState) => {
        const { id } = data;
        const UIDefaults = getState().UI.defaults;

        dispatch(extendSubmission(id));

        let updateData = {
            ...data,
            sessionCategory: EXTEND_SUBMISSION_CATEGORY,
        };

        return API_UPDATE_SUBMISSION(
            id.uuid,
            updateData,
            getState().premcalcData.entities.ownSubmission[id.uuid],
            UIDefaults
        )
            .then(response => {
                dispatch(addAppEntities(response));
                return response;
            })
            .then(response => {
                dispatch(extendSubmissionSuccess(response));
                return response;
            })
            .catch(e => {
                dispatch(extendSubmissionError(e));
            });
    };
}

export const requestClearSubmission = submissionId => dispatch => {
    dispatch(clearSubmission(submissionId));

    clearSubmissionId();

    API_CLEAR_SUBMISSION(submissionId.uuid)
        .then(result => {
            dispatch(clearSubmissionSuccess(result));
            return result;
        })
        .catch(e => {
            dispatch(clearSubmissionError(e));
        });
};

export const requestPublishSubmissionOffer = (submissionId, attributes) => (dispatch, getState) => {
    dispatch(publishSubmission(submissionId));

    return API_PUBLISH_SUBMISSION(
        submissionId.uuid,
        attributes,
        getState().premcalcData.entities.ownSubmission[submissionId.uuid]
    )
        .then(response => {
            dispatch(addAppEntities(response));
            dispatch(publishSubmissionSuccess(response));
            return response;
        })
        .catch(e => {
            dispatch(publishSubmissionError(e));
        });
};

export const requestBasketUpdate =
    (submissionId, personId, { tariff, okp, vvg }) =>
    (dispatch, getState) =>
        new Promise(resolve => {
            const ownSubmission = getState().premcalcData.entities.ownSubmission[submissionId];

            if (tariff) {
                const data = {
                    basket: {
                        [personId]: tariff,
                    },
                    personId: personId,
                    person: {
                        id: personId,
                        okpEnabled: okp?.enabled,
                        okpProduct: okp?.product,
                        vvgEnabled: vvg?.enabled,
                        vvgSection: vvg?.section,
                        vvgProductGroups: vvg?.productGroups || {},
                        vvgProducts: vvg?.products || {},
                        practices: okp.practices || {},
                    },
                };
                API_UPDATE_SUBMISSION(submissionId, data, ownSubmission).then(response => {
                    dispatch(addAppEntities(response));
                    resolve(response);
                });
            }
        });

export const requestDownloadConfirmationPdf = () => (dispatch, getState) => {
    const submissionSuccessProps = getState().EditSubmissionPage.publishedSubmissionProps;

    const fileName = submissionSuccessProps.pdfData.Name ?? submissionSuccessProps.submissionData.id.uuid + '.pdf';

    downloadFile(submissionSuccessProps.pdfData.Content, fileName, submissionSuccessProps.pdfData.MimeType);
};

export const loadData = params => dispatch => {
    let dispatcher = [];
    let payload = {};

    dispatcher.push(dispatch(clearUpdatedTab()));

    const id = getSubmissionId();
    if (id) {
        payload = {
            id: id,
        };

        dispatcher.push(dispatch(requestShowSubmission(payload)));
    }

    return Promise.all(dispatcher)
        .then(response => afterInitialDataLoaded(dispatch, response, params))
        .then(() => {
            dispatch(setInitialDataLoaded());
        })
        .catch(e => {
            throw e;
        });
};
