import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { StyledWizardHeader, StyledH1, StyledP1 } from './WizardHeader.style';

class WizardHeader extends Component {
    constructor(props) {
        super(props);
        this.state = { mounted: false };
    }

    componentDidMount() {
        this.setState({ mounted: true }); // eslint-disable-line react/no-did-mount-set-state
    }

    render() {
        const { className, rootClassName, panelTitle, panelText } = this.props;

        const rootClass = rootClassName || 'wizardHeader';
        const classes = classNames(rootClass, className);

        return (
            <StyledWizardHeader className={classes}>
                <StyledH1>{panelTitle}</StyledH1>
                <StyledP1>{panelText}</StyledP1>
            </StyledWizardHeader>
        );
    }
}

const { string, object } = PropTypes;

WizardHeader.defaultProps = {
    rootClassName: null,
    className: null,
    panelTitle: null,
    panelText: null,
};

WizardHeader.propTypes = {
    rootClassName: string,
    className: string,
    panelTitle: string,
    panelText: string,
};

export default WizardHeader;
