import React from 'react';
import i18next from 'i18next';
import {
    findTariffForProductGroup,
    PRODUCT_GROUP_IDENTIFIER_MIVITA,
    PRODUCT_GROUP_IDENTIFIER_SPITAL,
} from '@src/util/tariff/vvg';
import { VvgAdditionalProductCard } from '@src/components';
import { staticData as getTariffStatic } from '@src/config/getTariff';
import { getProductIdentifier } from '@src/util/tariff';

const renderAdditionalProductGroup = (
    group,
    products,
    apiData,
    values,
    currentPerson,
    submission,
    form,
    onProductImpressionChange,
    setShowProductSelectionInfo,
    isInternal = false,
    showPromotionBadge = false,
    showProductSelectionInfo = false
) => {
    const labelPrefix = `ProductForm.vvg.productGroup.${group.id}`;
    const spitalPrefix = 'ALG';
    const mivitaPrefix = 'MIX';
    const utiPrefix = 'UTI';
    const { price: productGroupPrice, showPriceOfSelectedProducts } = findTariffForProductGroup(
        group.id,
        apiData,
        values,
        { personId: currentPerson.id, persons: submission.relationships.persons, submission }
    );
    const sections = getTariffStatic.vvg.sections;
    const productGroupsInAllSections = sections
        .map(section => section.productGroups)
        .reduce((prev, curr) => prev.filter(val => curr.includes(val)));
    const currentSection = sections.find(section => section.id === values.section);

    const onAdditionalProductChange = (value, isSelected, productGroup) => {
        form.mutators.setValue(`vvg.products.${currentSection.id}_${productGroup.id}`, value);

        // make sure productgroup state is correct if a product is selected or accordion is expanded
        if (isSelected !== values['productGroups'][`${currentSection.id}_${productGroup.id}`]) {
            form.mutators.setValue(`vvg.productGroups.${currentSection.id}_${productGroup.id}`, isSelected);
        }

        // handle special cases where a product group is in both sections so user doesn't have to select the product twice
        if (productGroupsInAllSections?.includes(productGroup.id)) {
            sections
                .filter(s => s.id !== currentSection.id)
                .forEach(otherSection => {
                    form.mutators.setValue(`vvg.productGroups.${otherSection.id}_${productGroup.id}`, isSelected);
                    form.mutators.setValue(`vvg.products.${otherSection.id}_${productGroup.id}`, value);
                });
        }

        // handle internal process that only one of Mivita Basic and Spital can be selected
        if (isInternal) {
            const vvgBasket = submission?.relationships?.basket[currentPerson.id]?.vvg;
            const hasSpitalAlreadyInBasket = vvgBasket.some(obj => obj.product.startsWith(spitalPrefix));
            const hasMivitaBasicAlreadyInBasket = vvgBasket.some(obj => obj.product.startsWith(mivitaPrefix));

            if (value?.startsWith(mivitaPrefix) && hasSpitalAlreadyInBasket) {
                form.mutators.setValue(`vvg.productGroups.withoutMivita_${PRODUCT_GROUP_IDENTIFIER_SPITAL}`, false);
                form.mutators.setValue(`vvg.products.withoutMivita_${PRODUCT_GROUP_IDENTIFIER_SPITAL}`, undefined);
                setShowProductSelectionInfo(true);
            } else if (value?.startsWith(spitalPrefix) && hasMivitaBasicAlreadyInBasket) {
                form.mutators.setValue(`vvg.productGroups.withoutMivita_${PRODUCT_GROUP_IDENTIFIER_MIVITA}`, false);
                form.mutators.setValue(`vvg.products.withoutMivita_${PRODUCT_GROUP_IDENTIFIER_MIVITA}`, undefined);
                setShowProductSelectionInfo(true);
            }
        }
    };

    const getInternalDefaultValue = () => {
        const selectedUti = submission?.relationships?.basket[currentPerson.id]?.vvg.find(basketEntry =>
            basketEntry.product.startsWith('UTI')
        );
        const defaultValue = products.find(product => {
            if (product.id.startsWith(mivitaPrefix) && product.value.startsWith('Basic')) {
                return true;
            } else if (product.id.startsWith(spitalPrefix)) {
                return true;
            } else if (product.id === selectedUti?.product) {
                // if the user already selected a UTI option, we need to keep it in the basket
                return true;
            } else if (product.id.startsWith(utiPrefix)) {
                // no UTI is selected yet, and we want to display the "from" prefix before the price ("ab CHF 1.10")
                return false;
            }
        });
        return defaultValue ? defaultValue.id : undefined;
    };

    const getShowProductSelectionInfo = () => {
        // show the info alert only in the internal process and beyond the Mivita Basic accordion
        return isInternal && group.id === PRODUCT_GROUP_IDENTIFIER_MIVITA ? showProductSelectionInfo : false;
    };

    return (
        <VvgAdditionalProductCard
            key={group.id}
            groupId={group.id}
            isInternal={isInternal}
            showPromotionBadge={showPromotionBadge}
            showProductSelectionInfo={getShowProductSelectionInfo()}
            groupFieldName={
                isInternal
                    ? `vvg.products.${currentSection.id}_${group.id}`
                    : `vvg.productGroups.${currentSection.id}_${group.id}`
            }
            productFieldName={`vvg.products.${currentSection.id}_${group.id}`}
            title={
                isInternal && group.id === PRODUCT_GROUP_IDENTIFIER_MIVITA
                    ? i18next.t('ProductForm.vvg.product.card.mix_26_236.title')
                    : i18next.t(`${labelPrefix}.title`)
            }
            subtitle={i18next.t(`${labelPrefix}.text`)}
            label={
                i18next.exists(`${labelPrefix}.select`)
                    ? i18next.t(`${labelPrefix}.select`)
                    : i18next.t('ProductForm.chooseOption')
            }
            text={i18next.exists(`${labelPrefix}.description`) ? i18next.t(`${labelPrefix}.description`) : ''}
            link={
                i18next.exists(`${labelPrefix}.link.url`)
                    ? {
                          href: i18next.t(`${labelPrefix}.link.url`),
                          title: i18next.t(`${labelPrefix}.link.title`),
                      }
                    : {}
            }
            options={products
                .sort((a, b) => a.sorting - b.sorting)
                .map(product => {
                    const vvgProductIdentifier = getProductIdentifier(product.id, false);

                    return {
                        value: product.id,
                        label: i18next.t(`ProductForm.vvg.product.${vvgProductIdentifier}.title`),
                    };
                })}
            price={productGroupPrice}
            showPriceOfSelectedProducts={showPriceOfSelectedProducts}
            defaultValue={
                isInternal
                    ? getInternalDefaultValue()
                    : values['products'][`${currentSection.id}_${group.id}`] ??
                      products.find(product => product.recommended)?.id
            }
            iconName={i18next.exists(`${labelPrefix}.icon`) && i18next.t(`${labelPrefix}.icon`)}
            selected={values['productGroups'][`${currentSection.id}_${group.id}`] ?? group.recommended}
            onChange={(value, isSelected) => onAdditionalProductChange(value, isSelected, group)}
            onProductImpressionChange={onProductImpressionChange}
            validate={(val, allValues) => {
                // validate if top level toggle is checked and field has no value
                if (allValues['vvg']['productGroups'][`${currentSection.id}_${group.id}`] && !val) {
                    return i18next.t(`ProductForm.vvg.productGroup.${group.id}.required`);
                }
            }}
        />
    );
};

export default renderAdditionalProductGroup;
