import moment from 'moment';
import { log } from '@src/middleware';
import { BACKEND_DATE_FORMAT, getYearFromDate, getAgeFromDate, isGivenDateInFuture } from '@src/util/date';
import { isAutosavedFormInvalid } from '@src/util/autosave';
import { checkForProductsInPersonBasket } from '@src/util/basket';
import { isInternalProcess, isOkpProcess } from '@src/util/process';

import {
    PRODUCT_GROUP_IDENTIFIER_MIVITA,
    PRODUCT_GROUP_IDENTIFIER_COMFORTA,
    PRODUCT_GROUP_IDENTIFIER_DENTA,
    PRODUCT_GROUP_IDENTIFIER_SPITAL,
    PRODUCT_IDENTIFIER_SPITAL_ALLGEMEIN,
} from '@src/util/tariff/vvg';

export const EXTERNAL_PERSON = '999999';
export const NATIONALITY_CH = 'CH';
export const GENDER_MALE = 'm';
export const GENDER_FEMALE = 'f';
export const GENDER_UNBORN = 'n';
export const GENDER_NONE_SELECTED = 'none';
export const TITLE_MALE = 'Herr';
export const TITLE_FEMALE = 'Frau';
export const PREVIOUS_INSURER_MOVED = '9999';
export const PREVIOUS_INSURER_UNBORN = '9997';

export const findPersonById = (id, persons) => {
    return persons.find(person => person.id === id);
};

export const findPaying = submission => {
    const { persons } = submission.relationships;
    const { privateData } = submission.attributes;
    let payingPerson = null;

    if (privateData.paymentContactperson) {
        payingPerson = findPersonById(privateData.paymentContactperson, persons);
    }

    if (!payingPerson) {
        payingPerson = {
            id: EXTERNAL_PERSON,
            gender: privateData.paymentContactAdditionalPersonGender,
            firstname: privateData.paymentContactAdditionalPersonFirstname,
            lastname: privateData.paymentContactAdditionalPersonLastname,
            birthdate: privateData.paymentContactAdditionalPersonBirthdate,
            email: privateData.paymentContactAdditionalPersonEmail,
            phone: privateData.paymentContactAdditionalPersonPhone,
            street: privateData.paymentContactAdditionalPersonStreet,
            addressExtension: privateData.paymentContactAdditionalPersonAddressExtension,
            place: privateData.paymentContactAdditionalPersonLocation,
            zip: privateData.paymentContactAdditionalPersonZip,
        };
    }

    return payingPerson;
};

export const findAdults = persons => {
    let adultPersons = [];
    for (const currentPerson of persons) {
        if (isAdult(currentPerson)) {
            adultPersons.push(currentPerson);
        }
    }
    return adultPersons;
};

export const isUnborn = person => {
    return isGivenDateInFuture(person.birthdate);
};

/**
 * Person's birthdate is not older than 30 days
 *
 * @param {*} person
 */
export const isNewborn = person => {
    const { birthdate } = person;
    return moment().subtract(30, 'days').isSameOrBefore(birthdate);
};

export const isChild = person => {
    return getAgeFromDate(person.birthdate) <= 12;
};

export const isTeen = person => {
    const age = getAgeFromDate(person.birthdate);
    return age >= 13 && age <= 17;
};

export const isAdult = person => {
    return getAgeFromDate(person.birthdate) >= 18;
};

export const isNewbornWithoutPreviousInsurer = person => {
    const { birthdate, insuranceStart } = person;
    return isNewborn(person) && birthdate === insuranceStart;
};

export const getGenderString = gender => {
    let genderString;
    switch (gender) {
        case 'm':
            genderString = 'Männlich';
            break;
        case 'w':
            genderString = 'Weiblich';
            break;
        default:
            genderString = 'Neutral';
            break;
    }
    return genderString;
};

export const getGenderIconName = (gender, withCheck = false) => {
    const suffix = withCheck ? '_check' : '';

    switch (gender) {
        case 'f':
            return `woman${suffix}`;
        case 'n':
            return `embryo${suffix}`;
        case 'm':
        default:
            return `man${suffix}`;
    }
};

/**
 * check if mivitaQuestion display condition is met
 * at least one person needs to be younger than 26 when the insurance begins
 * and the given date has to be valid (prevents show/hide while entering)
 *
 * @param persons
 * @returns {boolean}
 */
export const checkMivitaQuestionRule = persons => {
    return (
        persons &&
        !!persons.find(person => {
            const { birthdate, insuranceStart } = person;

            // use moment strict mode to force correct format due to input mask
            const isBirthdateValid = !!birthdate && moment(birthdate, BACKEND_DATE_FORMAT, true).isValid();
            const isInsuranceStartValid = moment(insuranceStart, BACKEND_DATE_FORMAT, true).isValid();

            return (
                isBirthdateValid &&
                isInsuranceStartValid &&
                getYearFromDate(person.insuranceStart) - getYearFromDate(person.birthdate) < 26
            );
        })
    );
};

export const isAgedMax25AtInsuranceStartYear = person => {
    const { birthdate, insuranceStart } = person;
    return moment(insuranceStart).endOf('year').diff(birthdate, 'years') <= 25;
};

/**
 * check if person dataset is complete.
 *
 * @param personId
 * @param submission
 * @param currentProducts
 * @returns
 */
export const checkPersonDataset = (personId, submission, currentProducts) => {
    let incompleteData = [];
    const { persons, healthQuestions, basket } = submission.relationships;
    const isInternal = isInternalProcess(submission.relationships.persons, currentProducts);

    // check person attributes
    const person = findPersonById(personId, persons);
    const {
        firstname,
        lastname,
        gender,
        previousInsurer,
        nationality,
        permitType,
        dateOfEntry,
        moved,
        dataConfirmedByUser,
    } = person;

    incompleteData = checkPersonPersonalData(
        gender,
        incompleteData,
        firstname,
        lastname,
        nationality,
        moved,
        dateOfEntry,
        previousInsurer,
        person,
        basket,
        personId,
        permitType,
        isInternal
    );

    checkPersonAdditionalData(
        submission,
        personId,
        incompleteData,
        healthQuestions,
        dataConfirmedByUser,
        currentProducts,
        isInternal
    );

    if (incompleteData.length > 0) {
        log.debug(`Person ${personId} dataset incomplete:`, incompleteData);
    }

    return incompleteData;
};

export const checkPersonPersonalData = (
    gender,
    incompleteData,
    firstname,
    lastname,
    nationality,
    moved,
    dateOfEntry,
    previousInsurer,
    person,
    basket,
    personId,
    permitType,
    isInternal
) => {
    if (!gender || gender === GENDER_NONE_SELECTED) {
        incompleteData.push('gender');
    }

    if (!firstname) {
        incompleteData.push('firstname');
    }
    if (!lastname) {
        incompleteData.push('lastname');
    }
    if (!nationality && !isInternal) {
        incompleteData.push('nationality');
    }

    if (moved === 'yes') {
        if (!dateOfEntry) {
            incompleteData.push('dateOfEntry');
        }
    } else if (
        !previousInsurer &&
        gender !== GENDER_UNBORN &&
        !isNewbornWithoutPreviousInsurer(person) &&
        isOkpProcess(basket, personId)
    ) {
        incompleteData.push('previousInsurer');
    }

    if (nationality !== NATIONALITY_CH && !permitType && !isInternal) {
        incompleteData.push('permitType');
    }
    return incompleteData;
};

export const checkPersonAdditionalData = (
    submission,
    personId,
    incompleteData,
    healthQuestions,
    dataConfirmedByUser,
    currentProducts,
    isInternal
) => {
    // check for unsaved invalid autosave state which is only saved locally in browser and not yet in server session
    if (isAutosavedFormInvalid(submission.id.uuid, 'personInformationForm', personId) === true) {
        incompleteData.push('autosaveInvalid');
    }

    // check health questions
    if (needsToAnswerHealthQuestions(personId, submission) && healthQuestions[personId]?.ready !== true) {
        incompleteData.push('healthQuestions');
    }

    // If all data is valid and the user is not coming from myAtupri, they need to confirm the userInfoData by submitting the personInfoForm.
    if (!dataConfirmedByUser && !isInternal) {
        incompleteData.push('dataConfirmedByUser');
    }

    return incompleteData;
};

/**
 * Checks, whether the person is complete, or not.
 *
 * @param {string} personId
 * @param {object} submission
 * @param {array} currentProducts
 * @returns
 */
export const isPersonDatasetComplete = (personId, submission, currentProducts) => {
    return checkPersonDataset(personId, submission, currentProducts).length === 0;
};

/**
 * Checks, whether the person has to answer healthQuestions, or not.
 *
 * @param {string} personId
 * @param {object} submission
 * @returns
 */
export const needsToAnswerHealthQuestions = (personId, submission) => {
    const { basket, persons } = submission.relationships;
    const person = findPersonById(personId, persons);
    const hasNeededProductsInBasket = checkForProductsInPersonBasket(
        basket,
        personId,
        [
            PRODUCT_GROUP_IDENTIFIER_MIVITA,
            PRODUCT_GROUP_IDENTIFIER_COMFORTA,
            PRODUCT_GROUP_IDENTIFIER_DENTA,
            PRODUCT_GROUP_IDENTIFIER_SPITAL,
        ],
        'productGroup',
        [PRODUCT_IDENTIFIER_SPITAL_ALLGEMEIN]
    );

    return person && !isUnborn(person) && hasNeededProductsInBasket;
};

export const getTitle = person => {
    let title = null;

    switch (person.gender) {
        case GENDER_FEMALE:
            title = TITLE_FEMALE;
            break;
        case GENDER_MALE:
            title = TITLE_MALE;
            break;
        case GENDER_UNBORN:
            if (person.expectedGender === GENDER_FEMALE) {
                title = TITLE_FEMALE;
            } else if (person.expectedGender === GENDER_MALE) {
                title = TITLE_MALE;
            }
            break;
    }

    return title;
};

export const getGender = (person, toUpperCase = false) => {
    let gender = null;

    if (person.gender === GENDER_UNBORN) {
        if (person.expectedGender === GENDER_FEMALE) {
            gender = GENDER_FEMALE;
        } else if (person.expectedGender === GENDER_MALE) {
            gender = GENDER_MALE;
        }
    } else {
        gender = person.gender;
    }

    if (gender && toUpperCase) {
        gender = gender?.toUpperCase();
    }

    return gender;
};

export const getPreviousInsurer = person => {
    return (
        (person.gender === GENDER_UNBORN && PREVIOUS_INSURER_UNBORN) ||
        (person.moved === 'yes' && PREVIOUS_INSURER_MOVED) ||
        person.previousInsurer
    );
};

export const getBasket = (basket, personId) => {
    return {
        okp: basket[personId]?.okp || [],
        vvg: basket[personId]?.vvg || [],
    };
};

export const householdHasMivitaOrSpital = (personId, submission) => {
    const { mivitaQuestion } = submission.attributes;
    const { persons, basket } = submission.relationships;

    if (mivitaQuestion === 'yes') {
        return true;
    }

    for (const currentPerson of persons) {
        const isNotGivenPerson = currentPerson.id !== personId;
        const isAdult = getAgeFromDate(currentPerson.birthdate) >= 18;
        const hasDefinedProducts = checkForProductsInPersonBasket(
            basket,
            currentPerson.id,
            [PRODUCT_GROUP_IDENTIFIER_MIVITA, PRODUCT_GROUP_IDENTIFIER_SPITAL],
            'productGroup'
        );

        if (isNotGivenPerson && isAdult && hasDefinedProducts) {
            return true;
        }
    }

    return false;
};

export const isEligibleForFamilyDiscount = (personId, submission) => {
    const { persons } = submission.relationships;
    const person = findPersonById(personId, persons);

    if (!person) {
        log.error('The given person could not be found', { personId, persons });
        return false;
    }

    // a) at least one adult in the family already has a mivita or a spital product
    const hasMivitaOrSpital = householdHasMivitaOrSpital(personId, submission);
    // b) the given person is younger than 25, or turns 25 in the current year
    const isBelow25 = isAgedMax25AtInsuranceStartYear(person);
    // @TODO: Check if the `isNotPaying` restriction should be applied, as it leads to problems in process (payment contact is defined after product selection).
    // c) the given person is not the payer
    const isNotPaying = true; //privateData.paymentContactperson !== person.id;
    // d) place and zip of the people are identical
    const isAddressIdentical = (persons => {
        let place, zip;
        for (const currentPerson of persons) {
            place = place || currentPerson.place;
            zip = zip || currentPerson.zip;
            if (place !== currentPerson.place || zip !== currentPerson.zip) {
                return false;
            }
        }
        return true;
    })(persons);

    return !!hasMivitaOrSpital && !!isAddressIdentical && !!isBelow25 && !!isNotPaying;
};

export const getPersonsTotalPrice = persons =>
    persons.map(person => person.totalPrice).reduce((previous, next) => previous + next);

export const isLastPerson = (persons, currentPersonIndex) => {
    return persons.length === 1 || currentPersonIndex === persons.length - 1;
};
