import styled from '@emotion/styled';
import { rem } from 'polished';
import { mixins } from 'atupri-component-library/lib/core/styles/theme/default';

export const StyledContainer = styled.div(
    ({ isInternal }) => `
  ${mixins.responsiveStyles({
      'padding-top': isInternal ? { zero: rem(180), medium: rem(115) } : { zero: rem(133), medium: rem(116) },
      'padding-bottom': { zero: rem(80), medium: rem(128) },
  })}
`
);
