import React from 'react';
import { array, bool, func, object, string } from 'prop-types';
import { Block, FieldMultiple } from '@src/components';
import { filterElementsByIdentifier } from '@src/util/array';

const AnswerMultiple = ({ id, name, healthQuestions, answer, validate, disabled, isMultiGroup = false, modalRefs }) => {
    let childBlocks = filterElementsByIdentifier(healthQuestions, 'parent', answer.id);

    return (
        <FieldMultiple
            key={'fm_' + id}
            id={id}
            answer={answer}
            name={name}
            isMultiGroup={isMultiGroup}
            validate={validate}
            disabled={disabled}
            ref={modalRefs || null}
        >
            {childBlocks.map(block => {
                return (
                    <Block
                        key={block.id}
                        healthQuestions={healthQuestions}
                        block={block}
                        inlineParent={id}
                        inlineIndex="0"
                    />
                );
            })}
        </FieldMultiple>
    );
};

AnswerMultiple.propTypes = {
    id: string.isRequired,
    name: string.isRequired,
    healthQuestions: array.isRequired,
    answer: object.isRequired,
    validate: func,
    disabled: bool,
    isMultiGroup: bool,
    modalRefs: object,
};

export default AnswerMultiple;
