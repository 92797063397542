import React, { useEffect, useState } from 'react';
import i18next from 'i18next';
import { arrayOf, bool, func, number, shape, string } from 'prop-types';
import { Field } from 'react-final-form';

import { Accordion, AccordionItemContent, Alert } from 'atupri-component-library/lib/components';
import { Hidden, Spacer, Stack } from 'atupri-component-library/lib/helpers';
import { Icon, Toggle, P2, P3 } from 'atupri-component-library/lib/primitives';

import { ToggleIcon } from '@src/forms/ProductForm/partials/Controls';

import { formatAmount } from '@src/util/currency';
import { sanitizeDOM } from '@src/util/sanitize';
import { getProductIdentifier } from '@src/util/tariff';

import {
    StyledAccordionItem,
    StyledAccordionItemIconBox,
    StyledAccordionItemPrice,
    StyledAccordionItemSummary,
    StyledAccordionItemTitle,
    StyledAccordionHeading,
    StyledAccordionToggleWrapper,
    StyledAccordionTrigger,
    StyledAccordionTriggerTitle,
    StyledDivider,
    StyledAccordionContentText,
    StyledSelect,
    StyledProductToggleWrapper,
    StyledProductToggle,
    StyledProductLink,
} from './VvgAdditionalProductCard.style';
import { StyledPromotionBadge } from '@src/forms/ProductForm/ProductForm.style';

const VvgAdditionalProductCard = ({
    groupId,
    groupFieldName,
    productFieldName,
    title,
    subtitle,
    price,
    label,
    text,
    link,
    options,
    selected = false,
    defaultValue,
    iconName = 'umbrella_plus',
    showPriceOfSelectedProducts = true,
    onChange,
    onProductImpressionChange,
    onExpand,
    validate,
    isInternal,
    showPromotionBadge,
    showProductSelectionInfo,
}) => {
    const MAX_PRODUCTS_RENDERING_AS_TOGGLE = 6;
    const [expanded, setExpanded] = useState(selected ? [groupFieldName] : []);
    const [selectedProductOption, setSelectedProductOption] = useState(() => defaultValue);
    const optionsRenderMode = options.length <= MAX_PRODUCTS_RENDERING_AS_TOGGLE ? 'toggle' : 'select';

    useEffect(() => {
        if (selected) {
            setExpanded([groupFieldName]);
            onChange?.(selectedProductOption, true);
        } else {
            // close the accordion if it's deselected
            setExpanded([]);
        }
    }, [selected]);

    useEffect(() => {
        if (selected && optionsRenderMode === 'toggle' && selectedProductOption) {
            onProductImpressionChange?.({
                products: [
                    {
                        id: selectedProductOption,
                        productGroup: groupId,
                    },
                ],
            });
        }
    }, [selected, selectedProductOption]);

    useEffect(() => {
        if (selected && optionsRenderMode === 'select') {
            onProductImpressionChange?.({ productGroups: [groupId] });
        }
    }, [selected]);

    const renderProductSelect = () => {
        return (
            <>
                <Field name={productFieldName} label={label} validate={validate}>
                    {({ label, input, meta, ...rest }) => (
                        <StyledSelect
                            label={label}
                            name={input.name}
                            value={`${selected && selectedProductOption ? selectedProductOption : ''}`}
                            onChange={event => {
                                input.onChange(event);
                                setExpanded([groupFieldName]);
                                setSelectedProductOption(event.target.value);
                                onChange?.(event.target.value, selected);
                            }}
                            options={options}
                            errorText={meta.touched && meta.error}
                            key={`${input.name}__${input.value}`}
                            isRequired
                            {...rest}
                        />
                    )}
                </Field>
                {link && <Spacer space="delta" />}
            </>
        );
    };

    const renderProductToggles = () => {
        return (
            <>
                {isInternal ? null : (
                    <>
                        <P2>{label}</P2>
                        <StyledProductToggleWrapper>
                            {options.map((option, index) => {
                                const vvgProductIdentifier = getProductIdentifier(option.value, false);

                                return (
                                    <Field
                                        key={`${productFieldName}_${option.value}`}
                                        name={productFieldName}
                                        type="checkbox"
                                        label={option.label}
                                    >
                                        {({ input, meta }) => (
                                            <StyledProductToggle
                                                controlType="radio"
                                                flexDirection="column"
                                                alignItems="flex-start"
                                                borderRadius={8}
                                                value={`${option.value}`}
                                                selectedValue={`${
                                                    selected && selectedProductOption
                                                        ? selectedProductOption
                                                        : undefined
                                                }`}
                                                name={input.name}
                                                onChange={event => {
                                                    input.onChange(event);
                                                    setExpanded([groupFieldName]);
                                                    setSelectedProductOption(option.value);
                                                    onChange?.(event.target.value, selected);
                                                }}
                                                errorText={index === 0 && meta.touched && meta.error}
                                                toggleTitleText={i18next.t(
                                                    `ProductForm.vvg.product.${vvgProductIdentifier}.title`
                                                )}
                                                ToggleTitleComponent={P2}
                                                toggleBackground={['white', 'blue300']}
                                            />
                                        )}
                                    </Field>
                                );
                            })}
                        </StyledProductToggleWrapper>
                    </>
                )}
                {selected && (
                    <P3
                        dangerouslySetInnerHTML={{
                            __html: sanitizeDOM(
                                i18next.t(
                                    `ProductForm.vvg.product.${getProductIdentifier(selectedProductOption, false)}.text`
                                )
                            ),
                        }}
                    />
                )}
            </>
        );
    };

    const onAccordionChange = exp => {
        setExpanded(exp);
        if (exp.length !== 0) {
            onExpand?.(exp);
            if (!selected) {
                onChange?.(selectedProductOption, true);
            }
        }
    };

    const onToggleChange = event => {
        setExpanded(event.target.checked ? [groupFieldName] : []);
        onChange?.(event.target.checked ? selectedProductOption : undefined, event.target.checked);
    };

    return (
        <>
            <Accordion allowMultipleExpanded allowItemCollapse expanded={expanded} onChange={onAccordionChange}>
                <StyledAccordionItem uuid={groupFieldName} isActive={selected} className="accordionItem">
                    {showPromotionBadge ? (
                        <StyledPromotionBadge dark={true}>
                            {i18next.t('ProductForm.vvg.promotionBadge')}
                        </StyledPromotionBadge>
                    ) : null}
                    <StyledAccordionHeading>
                        <StyledAccordionToggleWrapper firstLevel>
                            <Field name={groupFieldName} type="checkbox">
                                {fieldProps => {
                                    return (
                                        <Toggle
                                            controlType="checkbox"
                                            name={fieldProps.input.name}
                                            value={true}
                                            checked={selected}
                                            onChange={event => {
                                                fieldProps.input.onChange(event);
                                                onToggleChange(event);
                                            }}
                                        />
                                    );
                                }}
                            </Field>
                        </StyledAccordionToggleWrapper>
                        <StyledAccordionTrigger type="button">
                            <StyledAccordionTriggerTitle>
                                <Hidden below="large">
                                    <StyledAccordionItemIconBox background="blue300" borderRadius={4}>
                                        <Icon iconName={iconName} />
                                    </StyledAccordionItemIconBox>
                                </Hidden>
                                <Stack direction="column" space="alpha">
                                    <StyledAccordionItemTitle isActive={selected}>{title}</StyledAccordionItemTitle>
                                    <Hidden below="large">
                                        <StyledAccordionItemSummary>{subtitle}</StyledAccordionItemSummary>
                                    </Hidden>
                                </Stack>
                                <StyledAccordionItemPrice>
                                    {showPriceOfSelectedProducts || (isInternal && defaultValue)
                                        ? formatAmount(price)
                                        : i18next.t(`ProductForm.priceFrom`, {
                                              price: formatAmount(price),
                                          })}
                                </StyledAccordionItemPrice>
                            </StyledAccordionTriggerTitle>
                            <Hidden above="large">
                                <StyledAccordionItemSummary>{subtitle}</StyledAccordionItemSummary>
                            </Hidden>
                            <ToggleIcon firstLevel />
                        </StyledAccordionTrigger>
                    </StyledAccordionHeading>
                    <AccordionItemContent>
                        <StyledDivider isActive={selected} />
                        <Spacer space="hecto" />
                        {text && (
                            <>
                                <StyledAccordionContentText
                                    dangerouslySetInnerHTML={{
                                        __html: sanitizeDOM(text),
                                    }}
                                />
                                <Spacer space="hecto" />
                            </>
                        )}
                        {optionsRenderMode === 'toggle' ? renderProductToggles() : renderProductSelect()}
                        {link && (
                            <StyledProductLink
                                link={{
                                    href: link.href,
                                    target: '_blank',
                                }}
                            >
                                {link.title}
                            </StyledProductLink>
                        )}
                    </AccordionItemContent>
                </StyledAccordionItem>
            </Accordion>
            {showProductSelectionInfo ? (
                <>
                    <Spacer space="epsilon" />
                    <Alert
                        variant="infobox"
                        version="info"
                        border="round"
                        text={i18next.t('ProductForm.vvg.productSelectionValidationError')}
                    />
                </>
            ) : null}
        </>
    );
};
VvgAdditionalProductCard.propTypes = {
    groupId: number.isRequired,
    groupFieldName: string.isRequired,
    productFieldName: string.isRequired,
    title: string.isRequired,
    subtitle: string.isRequired,
    price: number.isRequired,
    options: arrayOf(
        shape({
            label: string,
            value: string,
        })
    ).isRequired,
    label: string,
    text: string,
    link: shape({
        title: string,
        url: string,
    }),
    selected: bool,
    defaultValue: string,
    iconName: string,
    showPriceOfSelectedProducts: bool,
    onChange: func,
    onProductImpressionChange: func,
    onExpand: func,
    validate: func,
    className: string,
    isInternal: bool,
    showPromotionBadge: bool,
    showProductSelectionInfo: bool,
};

export default VvgAdditionalProductCard;
