export const isLocalUrl = () => {
    const localDomains = ['-local', 'lndo.site', 'localhost'];
    return localDomains.some(domain => window.location.hostname.includes(domain));
};

export const environments = {
    local: 'local',
    dev: 'dev',
    test: 'test',
    int: 'int',
    int2: 'int2',
    prod: 'prod',
};

export const getEnvironment = () => {
    return window?.__ENV__?.REACT_APP_STAGE || (isLocalUrl() ? environments.local : environments.prod);
};

const env = getEnvironment();

const dev =
    env === environments.local ||
    env === environments.dev ||
    env === environments.test ||
    env === environments.int ||
    env === environments.int2;

export const environmentConfiguration = {
    environments,
    env,
    dev,
};

export const isMyAtupriPath = () => {
    return window.location.hostname.startsWith('myatupri');
};
export const isAkupoPath = () => {
    return window.location.hostname.startsWith('akupo');
};

export const isMyAtupriOrAkupoPath = isMyAtupriPath() || isAkupoPath();
