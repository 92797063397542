import styled from '@emotion/styled';
import { mixins, spaces } from 'atupri-component-library/lib/core/styles/theme/default';

export const StyledCtaBar = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    ${mixins.responsiveStyles({
        'flex-direction': { zero: 'column-reverse', small: 'row' },
        'margin-bottom': {
            zero: spaces.gamma * -1,
            small: 0,
        },
    })}
`;

export const StyledCtaBarCol = styled.div`
    ${mixins.responsiveStyles({
        'margin-bottom': {
            zero: spaces.gamma,
            small: 0,
        },
    })}
`;
