import React, { useState, useCallback, useEffect } from 'react';
import { array, func, object, string } from 'prop-types';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import { Loader } from 'atupri-component-library/lib/components';
import { getGoogleMapsApiKey } from '@src/config/map';
import { StyledMapView, mapStyle } from './MapView.styles';

const MapView = props => {
    const { selectedModel, setTempPractice, filteredPracticeList, center, zip, city } = props;

    const [googleMapWindow, setGoogleMapWindow] = useState(null);
    const [googleMap, setGoogleMap] = useState(null);
    const [markers, setMarkers] = useState([]);
    const apiKey = getGoogleMapsApiKey();
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: apiKey,
    });

    useEffect(() => {
        const markerArr = [];

        filteredPracticeList
            .filter(practice => {
                if (practice[selectedModel]) {
                    return practice;
                }
            })
            .forEach(practice => {
                markerArr.push({
                    id: `${practice[selectedModel].zsrNr}_${[selectedModel]}`,
                    lat: parseFloat(practice[selectedModel].latitude),
                    lng: parseFloat(practice[selectedModel].longitude),
                });
            });

        setMarkers(markerArr);
    }, [filteredPracticeList]);

    useEffect(() => {
        addMarkers(googleMap, googleMapWindow, markers, zip, city);
    }, [markers, googleMapWindow, googleMap]);

    const onMapLoad = useCallback(map => {
        const bounds = new window.google.maps.LatLngBounds();
        map.fitBounds(bounds);
        setGoogleMap(map);
        setGoogleMapWindow(window.google.maps);
    }, []);

    const onMapUnmount = useCallback(() => {
        setGoogleMap(null);
    }, []);

    const onMarkerClick = id => {
        setTempPractice(id);
    };

    return (
        <StyledMapView>
            {isLoaded ? (
                <GoogleMap
                    mapContainerStyle={mapStyle}
                    center={center}
                    zoom={10}
                    onLoad={onMapLoad}
                    onUnmount={onMapUnmount}
                >
                    {markers.map(marker => {
                        return (
                            <Marker
                                key={`${marker.id}_map`}
                                onClick={() => onMarkerClick(marker.id)}
                                position={{
                                    lat: marker.lat,
                                    lng: marker.lng,
                                }}
                            />
                        );
                    })}
                </GoogleMap>
            ) : (
                <Loader color="primary" size={32} strokeWidth={3} />
            )}
        </StyledMapView>
    );
};

const addMarkers = (googleMap, googleMapWindow, markers, zip, city) => {
    if (googleMap && googleMapWindow) {
        if (markers.length > 0) {
            const bounds = new googleMapWindow.LatLngBounds();

            markers.forEach(practice => {
                bounds.extend({ lat: practice.lat, lng: practice.lng });
            });

            googleMap.fitBounds(bounds);
        } else {
            const geocoder = new googleMapWindow.Geocoder();
            geocoder.geocode(
                {
                    address: `${zip} ${city} CH`,
                },
                function (results, status) {
                    if (status === 'OK') {
                        googleMap.fitBounds(results[0].geometry.bounds);
                    }
                }
            );
        }
    }
};

MapView.defaultProps = {
    center: {
        lat: 46.79923365912685,
        lng: 8.234047885565866,
    },
    zip: '',
    city: '',
};

MapView.propTypes = {
    selectedModel: string.isRequired,
    setTempPractice: func.isRequired,
    filteredPracticeList: array.isRequired,
    center: object,
    zip: string,
    city: string,
};

export default MapView;
