import React from 'react';
import i18next from 'i18next';
import { P3 } from 'atupri-component-library/lib/primitives';
import { Spacer } from 'atupri-component-library/lib/helpers';

import { StyledInlineListContainer, StyledInlineListEnumeration } from './InlineList.style';

import { InlineListRow } from '@src/components';

const InlineList = ({
    item,
    value,
    showTotalAmount = true,
    alternativeHeader = null,
    onEditClick,
    onDeleteClick,
    renderOnlyRow,
}) => {
    const renderRow = (row, index) => {
        return (
            <InlineListRow
                key={item.id + '_' + index}
                item={item}
                row={row}
                index={index}
                onEditClick={onEditClick}
                onDeleteClick={onDeleteClick}
            />
        );
    };

    const render = () => {
        if (typeof value == 'undefined' || value.length == 0) {
            return;
        }

        if (typeof renderOnlyRow !== 'undefined' && renderOnlyRow) {
            return <div>{value.map(renderRow)}</div>;
        }

        return (
            <StyledInlineListContainer>
                {showTotalAmount ? (
                    <StyledInlineListEnumeration>
                        <span>{value.length}</span> {i18next.t('InlineList.enumeration')}
                    </StyledInlineListEnumeration>
                ) : null}
                {alternativeHeader ? (
                    <>
                        <P3>{alternativeHeader}</P3>
                        <Spacer space="zeta" />
                    </>
                ) : null}
                {value.map(renderRow)}
            </StyledInlineListContainer>
        );
    };

    return <div key={item.id}>{render()}</div>;
};

export default InlineList;
